import React from 'react';
import {FusePageSimple, FuseAnimateGroup} from '../../../../../@fuse';
import {Icon, Typography, IconButton, Tooltip, Snackbar, Button} from '@material-ui/core';
import {connect} from 'react-redux';
import ReactTable from 'react-table';
import UpdateUserDialog from './User/UpdateUserDialog';
import CreateUserDialog from './User/CreateUserDialog';
import * as actions from '../../../../store/crm/admin/actions';
import * as authActions from '../../../../store/auth/actions';
import {fetchAllGroups} from '../../../../store/auth/actions';
import {withTranslation} from 'react-i18next';
import axios from 'axios';
import { useQuery } from 'react-query';

const mapUserAccountManagers = (user) => ({
    ...user,
    accountManagers: user.accountManagers.map(a => ({
        userAzureId: user.azureId,
        accountManagerCode: a.accountManager,
        companyId: user.companyId
    }))
})

const Users = (props) => {
    const [updateDialogOpen, setUpdateDialogOpen] = React.useState(false);
    const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState({});
    const [userSaved, setUserSaved] = React.useState(false);
    const [userCreated, setUserCreated] = React.useState(false);

    const useAccountManagers = useQuery('accountManagers', 
        async () => {
            const res = await axios.get(
                "admin/GetAccountManagers"
            );
            return res.data;
        }, {
            staleTime: Infinity
        }
    )

    React.useEffect(() =>  {
        props.onFetchUsers();
        props.onFetchCompanies();
        props.onFetchGroups();
        props.onFetchRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onOpenUpdateDialog = (user) => {
        props.onFetchUserRoles(user.azureId);
        setUpdateDialogOpen(true);
        setSelectedUser(user);
    }

    const handleCloseUpdateDialog = () => setUpdateDialogOpen(false);
    const handleSaveUpdateDialog = (user) => {
        setUpdateDialogOpen(false);
        props.onUpdateUser(mapUserAccountManagers(user), user.changePassword);
    }

    const handleCloseCreateDialog = () => setCreateDialogOpen(false);
    const handleSaveCreateDialog = (user) => {
        setCreateDialogOpen(false);
        props.onCreateUser(mapUserAccountManagers(user));
    }

    const onDeleteUser = (user) => {
        props.onDeleteUser(user.azureId);
    }

    const handleUpdateSnackbarClose = () => setUserSaved(false);
    const handleCreateSnackbarClose = () => setUserCreated(false);

    const onGroupClick = (row) => {
        props.history.push(`/icrm/useraccess/${row.id}`, {user: row, companies: props.companies});
    }

    const onImportSIBClick = () => {
        const users = props.users
            .map(user => {
                return {
                    email: user.email,
                    name: user.name,
                    language: transformUserLanguage(user.language),
                }
            });
        props.onImportUsersSIB(users);
    }

    const transformUserLanguage = (lng) => {
        switch(lng) {
            case 'en': return 'English';
            case 'de': return 'German';
            case 'nl': return 'Nederlands';
            case 'fr': return 'French';
            default: return 'English';
        }
    }

    const {companies, allgroups, refreshingGroups, importedUsers, users, roles, t} = props;
    let importSIBIcon = <Icon>contact_mail</Icon>;
    if(importedUsers.didImport && !importedUsers.error) {
        importSIBIcon = <Icon style={{color: '#4caf50'}}>done</Icon>;
    } else if (!importedUsers.didImport && importedUsers.error) {
        importSIBIcon = <Icon color="error">close</Icon>;
    }
    
    return (
        <FusePageSimple
        header={
            <div className="flex flex-1 items-center justify-between p-24">
                <div className="flex flex-col">
                    <div className="flex items-center mb-16">
                        <Icon className="text-18" color="action">home</Icon>
                        <Icon className="text-16" color="action">chevron_right</Icon>
                        <Typography color="textSecondary">iCRM</Typography>
                        <Icon className="text-16" color="action">chevron_right</Icon>
                        <Typography color="textSecondary">Admin</Typography>
                        <Icon className="text-16" color="action">chevron_right</Icon>
                        <Typography color="textSecondary">{t("USERS")}</Typography>
                    </div>
                    <Typography variant="h6">{t("USERS")}</Typography>
                </div>
                    <div className="flex items-end">
                        <Tooltip title="Import users to SendinBlue">
                            <Button
                                // variant="contained"
                                className="mr-12" 
                                size="medium"
                                // color="default"
                                onClick={onImportSIBClick}
                                disabled={importedUsers.didImport || (!importedUsers.didImport && importedUsers.error)}
                            >
                                {importSIBIcon}                                
                            </Button>
                        </Tooltip>
                        <Tooltip title="Refresh debiter groups from Florisoft">
                            <Button
                                // variant="contained"
                                className="mr-12" 
                                size="medium"
                                // color="default"
                                onClick={props.onRefreshDebiterGroups}
                                disabled={refreshingGroups}
                            >
                                <Icon>group</Icon>
                                Refresh
                            </Button>
                        </Tooltip>
                        <Tooltip title="Create new user">
                            <span>
                                <Button 
                                    size="medium" 
                                    aria-label="Add" 
                                    onClick={() => setCreateDialogOpen(true)}
                                    disabled={companies.length === 0 || allgroups.length === 0 || roles.length === 0}
                                >
                                    <Icon>add</Icon>
                                </Button>
                            </span>
                        </Tooltip>
                    </div>
            </div>
        }
        content={
            <>
            {updateDialogOpen ? 
            <UpdateUserDialog dialogOpen={updateDialogOpen} onClose={handleCloseUpdateDialog} onSave={handleSaveUpdateDialog}
                user={selectedUser} companies={companies} groups={allgroups} roles={roles} userRoles={props.userRoles} accountManagers={useAccountManagers.data} /> : null }
            {createDialogOpen ? 
            <CreateUserDialog dialogOpen={createDialogOpen} onClose={handleCloseCreateDialog} onSave={handleSaveCreateDialog}
                companies={companies} groups={allgroups} roles={roles} accountManagers={useAccountManagers.data} /> : null }
            {userSaved ? 
            <Snackbar
                style={{position: 'absolute'}}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                message="User successfully updated!"
                open={userSaved}
                autoHideDuration={2000}
                onClose={handleUpdateSnackbarClose}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={handleUpdateSnackbarClose}
                    >
                        <Icon>close</Icon>
                    </IconButton>,
                    ]} />
            : null}
            {userCreated ? 
            <Snackbar
                style={{position: 'absolute'}}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                message="User successfully created!"
                open={userCreated}
                autoHideDuration={2000}
                onClose={handleCreateSnackbarClose}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={handleCreateSnackbarClose}
                    >
                        <Icon>close</Icon>
                    </IconButton>,
                    ]} />
            : null}
            <FuseAnimateGroup animation="transition.slideUpIn" delay={300}>
                <div className="flex flex-wrap w-full">
                    <ReactTable
                        className="w-full -striped -highlight border-0"
                        data={users}
                        columns={[
                            {
                                Header    : t("NAME"),
                                accessor  : "name",
                                filterable: true,
                                className : "font-bold justify-center",
                            },
                            {
                                Header    : "Email",
                                accessor  : "email",
                                filterable: true,
                            },
                            {
                                Header    : "Account Manager",
                                accessor  : "accountManagers",
                                filterable: true,
                                className: "justify-center",
                                Cell: row => row.value.map(a => a.accountManagerCode).join(' - ')
                            },
                            {
                                Header    : "Debiter Group",
                                accessor  : "debiterGroup.groupName",
                                filterable: true,
                                className: "justify-center",
                            },
                            {
                                Header    : "Company",
                                accessor  : "company.name",
                                filterable: true,
                                className: "justify-center",
                            },
                            {
                                Header    : "Groups",
                                filterable: false,
                                className: "justify-center",
                                width: 60,
                                Cell: row => (
                                <Tooltip title={row.value !== undefined ? row.value : 'Edit Groups'}>
                                    <IconButton aria-label="Edit" onClick={() => onGroupClick(row.original)}>
                                        <Icon color="secondary">group_add</Icon>
                                    </IconButton>
                                </Tooltip>
                                )
                            },
                            {
                                Header    : "Profile",
                                filterable: false,
                                className: "justify-center",
                                width: 50,
                                Cell: row => (
                                <Tooltip title={row.value !== undefined ? row.value : 'Edit'}>
                                    <IconButton aria-label="Edit" onClick={() => onOpenUpdateDialog(row.original)}>
                                        <Icon color="primary">edit</Icon>
                                    </IconButton>
                                </Tooltip>
                                )
                            },
                            {
                                Header    : t("DELETE"),
                                filterable: false,
                                className: "justify-center",
                                width: 50,
                                Cell: row => (
                                <Tooltip title={t("DELETE")}>
                                    <IconButton aria-label="delete" onClick={() => window.confirm(`Are you sure you want to delete ${row.original.name}?`) && onDeleteUser(row.original)}>
                                        <Icon style={{color: 'red'}}>delete_outline</Icon>
                                    </IconButton>
                                </Tooltip>
                                )
                            },
                        ]}
                        pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                        defaultPageSize={10}
                        noDataText="No users found"
                    />
                </div>
            </FuseAnimateGroup>
            </>
        }
        />
    )
}

const mapStateToProps = ({admin, flortalAuth}) => {
    return {
        users: admin.users,
        userRoles: admin.userRoles,
        userRolesLoaded: admin.userRolesLoaded,
        companies: admin.companies,
        userCreated: admin.userCreated,
        allgroups: flortalAuth.allgroups,
        refreshingGroups: admin.refreshingGroups,
        importedUsers: admin.importedUsers,
        roles: flortalAuth.roles,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchUsers: () => dispatch(actions.fetchUsers()),
        onFetchUserRoles: (azureId) => dispatch(actions.fetchUserRoles(azureId)),
        onFetchCompanies: () => dispatch(actions.fetchCompanies()),
        onFetchGroups: () => dispatch(fetchAllGroups()),
        onUpdateUser: (user, changedPassword) => dispatch(actions.updateUser(user, changedPassword)),
        onCreateUser: (user) => dispatch(actions.createUser(user)),
        onDeleteUser: (azureId) => dispatch(actions.deleteUser(azureId)),
        onRefreshDebiterGroups: () => dispatch(actions.refreshDebiterGroups()),
        onFetchRoles: () => dispatch(authActions.fetchRoles()),
        onImportUsersSIB: (users) => dispatch(actions.importUsersSIB(users)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Users));