import * as actionTypes from './actionTypes';
import axios from 'axios'

export const fetchAllCallListBlocksSuccess = (allBlocks) => {
    return {
        type: actionTypes.FETCH_CALL_LIST_BLOCKS_SUCCESS,
        allBlocks: allBlocks
    };
};

export const fetchAllCallListBlocks = () => {
    return dispatch => {
        axios.get('callList/GetCallListBlocks')
            .then(res => {
                dispatch(fetchAllCallListBlocksSuccess(res.data));
            })
    };
};

export const fetchCallListBlock1Success = (data) => {
    return {
        type: actionTypes.FETCH_CALL_LIST_BLOCK1_SUCCESS,
        block1: data
    };
};

export const fetchCallListBlock1 = () => {
    return dispatch => {
        axios.get('callList/GetBlock1')
            .then(res => {
                dispatch(fetchCallListBlock1Success(res.data));
            })
    };
};

export const fetchCallListBlock2Success = (data) => {
    return {
        type: actionTypes.FETCH_CALL_LIST_BLOCK2_SUCCESS,
        block2: data
    };
};

export const fetchCallListBlock2 = () => {
    return dispatch => {
        axios.get('callList/GetBlock2')
            .then(res => {
                dispatch(fetchCallListBlock2Success(res.data));
            })
    };
};

export const fetchCallListBlock3Success = (data) => {
    return {
        type: actionTypes.FETCH_CALL_LIST_BLOCK3_SUCCESS,
        block3: data
    };
};

export const fetchCallListBlock3 = () => {
    return dispatch => {
        axios.get('callList/GetBlock3')
            .then(res => {
                dispatch(fetchCallListBlock3Success(res.data));
            })
    };
};

export const fetchCallListBlock4Success = (data) => {
    return {
        type: actionTypes.FETCH_CALL_LIST_BLOCK4_SUCCESS,
        block4: data
    };
};

export const fetchCallListBlock4 = () => {
    return dispatch => {
        axios.get('callList/GetBlock4')
            .then(res => {
                dispatch(fetchCallListBlock4Success(res.data));
            })
    };
};

export const fetchCallListBlock6Success = (data) => {
    return {
        type: actionTypes.FETCH_CALL_LIST_BLOCK6_SUCCESS,
        block6: data
    };
};

export const fetchCallListBlock6 = () => {
    return dispatch => {
        axios.get('callList/GetBlock6')
            .then(res => {
                dispatch(fetchCallListBlock6Success(res.data));
            })
    };
};