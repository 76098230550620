import React, {Component} from 'react';
import {withStyles, Icon, Typography} from '@material-ui/core';
import {FusePageSimple} from '../../../../@fuse';

const styles = theme => ({
    layoutRoot: {},
});

class Tailwindcss extends Component {

    render()
    {
        const {classes} = this.props;
        return (
            <FusePageSimple
            classes={{
                root: classes.layoutRoot
            }}
            header={
                <div className="flex flex-1 items-center justify-between p-24">
                    <div className="flex flex-col">
                        <div className="flex items-center mb-16">
                            <Icon className="text-18" color="action">home</Icon>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">Documentation</Typography>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">Tailwindcss</Typography>
                        </div>
                        <Typography variant="h6">Tailwindcss</Typography>
                    </div>
                </div>
            }
            // contentToolbar={
                // <div className="px-24"><h4>Tailwindcss</h4></div>
            // }
            content={
                <div className="p-24">
                    <div className="max-w-2xl">

                        <Typography className="mb-16" component="p">
                        We use <code>tailwindcss</code> for this project :<br/>
                        </Typography>

<div className="lg:block lg:relative lg:sticky lg:top-28">
{/* <div className="mb-8"> */}
{/* <p className="mb-3 lg:mb-2 text-grey uppercase tracking-wide font-bold text-sm lg:text-xs">Introduction</p> */}
{/* <ul> */}
{/* <li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/what-is-tailwind">What is Tailwind?</a></li> */}
{/* </ul> */}
{/* </div> */}
<div className="mb-8">
<p className="mb-3 lg:mb-2 text-grey uppercase tracking-wide font-bold text-sm lg:text-xs">Getting Started</p>
<ul>
{/* <li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/installation">Installation</a></li> */}
{/* <li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/configuration">Configuration</a></li> */}
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/colors">Colors</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/responsive-design">Responsive Design</a></li>
{/* <li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/state-variants">State Variants</a></li> */}
{/* <li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/adding-new-utilities">Adding New Utilities</a></li> */}
{/* <li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/extracting-components">Extracting Components</a></li> */}
{/* <li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/functions-and-directives">Functions &amp; Directives</a></li> */}
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/controlling-file-size">Controlling File Size</a></li>
{/* <li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/plugins">Plugins</a></li> */}
</ul>
</div>
<div className="mb-8">
<p className="mb-3 lg:mb-2 text-grey uppercase tracking-wide font-bold text-sm lg:text-xs">Layout</p>
<ul>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/container">Container</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/display">Display</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/floats">Floats</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/object-fit">Object Fit</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/object-position">Object Position</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/overflow">Overflow</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/positioning">Position</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/visibility">Visibility</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/z-index">Z-Index</a></li>
</ul>
</div>
<div className="mb-8">
<p className="mb-3 lg:mb-2 text-grey uppercase tracking-wide font-bold text-sm lg:text-xs">Typography</p>
<ul>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/text-color">Color</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/fonts">Font Family</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/text-sizing">Font Size</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/font-weight">Font Weight</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/letter-spacing">Letter Spacing</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/line-height">Line Height</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/lists">Lists</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/text-style">Style &amp; Decoration</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/text-alignment">Text Alignment</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/vertical-alignment">Vertical Alignment</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/whitespace-and-wrapping">Whitespace &amp; Wrapping</a></li>
</ul>
</div>
<div className="mb-8">
<p className="mb-3 lg:mb-2 text-grey uppercase tracking-wide font-bold text-sm lg:text-xs">Backgrounds</p>
<ul>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/background-attachment">Background Attachment</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/background-color">Background Color</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/background-position">Background Position</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/background-repeat">Background Repeat</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/background-size">Background Size</a></li>
</ul>
</div>
<div className="mb-8">
<p className="mb-3 lg:mb-2 text-grey uppercase tracking-wide font-bold text-sm lg:text-xs">Borders</p>
<ul>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/border-color">Border Color</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/border-style">Border Style</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/border-width">Border Width</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/border-radius">Border Radius</a></li>
</ul>
</div>
<div className="mb-8">
<p className="mb-3 lg:mb-2 text-grey uppercase tracking-wide font-bold text-sm lg:text-xs">Flexbox</p>
<ul>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-teal-dark font-semibold" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/flexbox-display">Display</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/flexbox-direction">Flex Direction</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/flexbox-wrapping">Flex Wrapping</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/flexbox-align-items">Align Items</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/flexbox-align-content">Align Content</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/flexbox-align-self">Align Self</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/flexbox-justify-content">Justify Content</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/flexbox-flex-grow-shrink">Flex, Grow, &amp; Shrink</a></li>
</ul>
</div>
<div className="mb-8">
<p className="mb-3 lg:mb-2 text-grey uppercase tracking-wide font-bold text-sm lg:text-xs">Spacing</p>
<ul>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/spacing">Margin &amp; Padding</a></li>
</ul>
</div>
<div className="mb-8">
<p className="mb-3 lg:mb-2 text-grey uppercase tracking-wide font-bold text-sm lg:text-xs">Sizing</p>
<ul>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/width">Width</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/min-width">Min-Width</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/max-width">Max-Width</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/height">Height</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/min-height">Min-Height</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/max-height">Max-Height</a></li>
</ul>
</div>
<div className="mb-8">
<p className="mb-3 lg:mb-2 text-grey uppercase tracking-wide font-bold text-sm lg:text-xs">Tables</p>
<ul>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/border-collapse">Border Collapse</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/table-layout">Table Layout</a></li>
</ul>
</div>
<div className="mb-8">
<p className="mb-3 lg:mb-2 text-grey uppercase tracking-wide font-bold text-sm lg:text-xs">Effects</p>
<ul>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/shadows">Box Shadow</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/opacity">Opacity</a></li>
</ul>
</div>
<div className="mb-8">
<p className="mb-3 lg:mb-2 text-grey uppercase tracking-wide font-bold text-sm lg:text-xs">Interactivity</p>
<ul>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/appearance">Appearance</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/cursor">Cursor</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/outline">Outline</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/pointer-events">Pointer Events</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/resize">Resize</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/user-select">User Select</a></li>
</ul>
</div>
<div className="mb-8">
<p className="mb-3 lg:mb-2 text-grey uppercase tracking-wide font-bold text-sm lg:text-xs">SVG</p>
<ul>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/svg">Fill &amp; Stroke</a></li>
</ul>
</div>
<div className="mb-8">
<p className="mb-3 lg:mb-2 text-grey uppercase tracking-wide font-bold text-sm lg:text-xs">Component Examples</p>
<ul>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/examples/alerts">Alerts</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/examples/buttons">Buttons</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/examples/cards">Cards</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/examples/forms">Forms</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/examples/grids">Grids</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/examples/navigation">Navigation</a></li>
</ul>
</div>
</div>
                    </div>

                </div>
            }
            />
        )
    }
}

export default withStyles(styles, {withTheme: true})(Tailwindcss);
