export const FETCH_CUSTOMER_START = 'FETCH_CUSTOMER_START';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAIL = 'FETCH_CUSTOMER_FAIL';

export const FETCH_ALL_SEARCHCUSTOMERS_SUCCESS = 'FETCH_ALL_SEARCHCUSTOMERS_SUCCESS';

export const FETCH_ALL_CUSTOMERS_START = 'FETCH_ALL_CUSTOMERS_START';
export const FETCH_ALL_CUSTOMERS_SUCCESS = 'FETCH_ALL_CUSTOMERS_SUCCESS';
export const FETCH_ALL_CUSTOMERS_FAIL = 'FETCH_ALL_CUSTOMERS_FAIL';

export const FETCH_CUSTOMER_TURNOVER_SUCCESS = 'FETCH_CUSTOMER_TURNOVER_SUCCESS';

export const FETCH_CUSTOMER_DEPARTURE_SUCCESS = 'FETCH_CUSTOMER_DEPARTURE_SUCCESS';

export const FETCH_CUSTOMER_INVOICES_SUCCESS = 'FETCH_CUSTOMER_INVOICES_SUCCESS';

export const FETCH_CUSTOMER_TODAYS_TURNOVER_SUCCESS = 'FETCH_CUSTOMER_TODAYS_TURNOVER_SUCCESS';

export const FETCH_CUSTOMER_UNPAID_SUCCESS = 'FETCH_CUSTOMER_UNPAID_SUCCESS';

export const FETCH_CUSTOMER_TOP_ITEMS_START = 'FETCH_CUSTOMER_TOP_ITEMS_START';
export const FETCH_CUSTOMER_TOP_ITEMS_SUCCESS = 'FETCH_CUSTOMER_TOP_ITEMS_SUCCESS';
export const FETCH_CUSTOMER_TOP_ITEMS_FAIL = 'FETCH_CUSTOMER_TOP_ITEMS_FAIL';

export const FETCH_NEWCUSTOMERS_START = 'FETCH_NEWCUSTOMERS_START';
export const FETCH_NEWCUSTOMERS_SUCCESS = 'FETCH_NEWCUSTOMERS_SUCCESS';

// New Leads
export const FETCH_NEWLEADS_SUCCESS = 'FETCH_NEWLEADS_SUCCESS';
export const FETCH_MULTIPLE_EMAIL_NEWLEADS_SUCCESS = 'FETCH_MULTIPLE_EMAIL_NEWLEADS_SUCCESS';
export const FETCH_MULTIPLE_VAT_NEWLEADS_SUCCESS = 'FETCH_MULTIPLE_VAT_NEWLEADS_SUCCESS';


// Get Templates Transporters New Leads
export const FETCH_TEMPLATES_TRANSPORTERS_NEWLEADS_SUCCESS = 'FETCH_TEMPLATES_TRANSPORTERS_NEWLEADS_SUCCESS';
export const FETCH_ALL_TEMPLATES_TRANSPORTERS_NEWLEADS_SUCCESS = 'FETCH_ALL_TEMPLATES_TRANSPORTERS_NEWLEADS_SUCCESS';
// export const FETCH_TRANSPORTERS_NEWLEADS_SUCCESS = 'FETCH_TRANSPORTERS_NEWLEADS_SUCCESS';
export const EDIT_NEWLEAD_SUCCESS = 'EDIT_NEWLEAD_SUCCESS';
export const EDIT_NEWLEAD_FAIL = 'EDIT_NEWLEAD_FAIL';
export const CHECK_VIES_SUCCESS = 'CHECK_VIES_SUCCESS';
export const DELETE_NEWLEAD_SUCCESS = 'DELETE_NEWLEAD_SUCCESS';
export const FETCH_ALL_DEBTYPE_SUCCESS = 'FETCH_ALL_DEBTYPE_SUCCESS';

// Create New Lead
export const CREATE_NEWLEAD_SUCCESS = 'CREATE_NEWLEAD_SUCCESS';
export const CREATE_NEWLEAD_FAIL = 'CREATE_NEWLEAD_FAIL';
// Validate New Lead
export const VALIDATE_NEWLEAD_SUCCESS = 'VALIDATE_NEWLEAD_SUCCESS';

export const ACTIVATE_NEW_CUSTOMER_SUCCESS = 'ACTIVATE_NEW_CUSTOMER_SUCCESS';

export const FETCH_CUSTOMER_WEBLOGVISITS_SUCCESS = 'FETCH_CUSTOMER_WEBLOGVISITS_SUCCESS';

export const FETCH_CUSTOMER_COMMENTS_SUCCESS = 'FETCH_CUSTOMER_COMMENTS_SUCCESS';

export const SUBMIT_CUSTOMER_COMMENT_SUCCESS = 'SUBMIT_CUSTOMER_COMMENT_SUCCESS';

export const DELETE_FAV_USERS_ASSIGNNEWLEADS_SUCCESS = 'DELETE_FAV_USERS_ASSIGNNEWLEADS_SUCCESS';
export const ADD_FAV_USERS_ASSIGNNEWLEADS_SUCCESS = 'ADD_FAV_USERS_ASSIGNNEWLEADS_SUCCESS';
// Get Count New Leads Badge
export const FETCH_NAV_COUNT_NEWLEADS_SUCCESS = 'FETCH_NAV_COUNT_NEWLEADS_SUCCESS';

// Unpaid
export const FETCH_UNPAID_SUCCESS = 'FETCH_UNPAID_SUCCESS';

// Weblog
export const FETCH_WEBLOG_START = 'FETCH_WEBLOG_START';
export const FETCH_WEBLOG_SUCCESS = 'FETCH_WEBLOG_SUCCESS';

export const FETCH_WEBLOG_VISITS_SUCCESS = 'FETCH_WEBLOG_VISITS_SUCCESS';

// Enalyzer
export const FETCH_ORDERDATA_FOR_ENALYZER_SUCCESS = 'FETCH_ORDERDATA_FOR_ENALYZER_SUCCESS';

// Overview
export const FETCH_OVERVIEW_SUCCESS = 'FETCH_OVERVIEW_SUCCESS';

export const FETCH_PASTYEAR_OVERVIEW_SUCCESS = 'FETCH_PASTYEAR_OVERVIEW_SUCCESS';

export const FETCH_HBXDECO_RESERVATIONS_OVERVIEW_SUCCESS = 'FETCH_HBXDECO_RESERVATIONS_OVERVIEW_SUCCESS';

// Dashboard
export const FETCH_ORDER_TURNOVER_SUCCESS = 'FETCH_ORDER_TURNOVER_SUCCESS';

export const FETCH_TURNOVER_DISTRIBUTION_START = 'FETCH_TURNOVER_DISTRIBUTION_START';
export const FETCH_TURNOVER_DISTRIBUTION_SUCCESS = 'FETCH_TURNOVER_DISTRIBUTION_SUCCESS';

export const FETCH_DASHBOARD_BLOCKS_START = 'FETCH_DASHBOARD_BLOCKS_START';
export const FETCH_DASHBOARD_BLOCKS_SUCCESS = 'FETCH_DASHBOARD_BLOCKS_SUCCESS';

export const FETCH_DASHBOARD_TABLES_SUCCESS = 'FETCH_DASHBOARD_TABLES_SUCCESS';

export const FETCH_DASHBOARD_CHARTS_SUCCESS = 'FETCH_DASHBOARD_CHARTS_SUCCESS';

export const FETCH_DASHBOARD_CREDIT_REDEN_VERDELING_START = 'FETCH_DASHBOARD_CREDIT_REDEN_VERDELING_START';
export const FETCH_DASHBOARD_CREDIT_REDEN_VERDELING_SUCCESS = 'FETCH_DASHBOARD_CREDIT_REDEN_VERDELING_SUCCESS';
export const FETCH_DASHBOARD_CREDIT_REDEN_VERDELING_FAIL = 'FETCH_DASHBOARD_CREDIT_REDEN_VERDELING_FAIL';

// Turnover
export const FETCH_TURNOVER_SUCCESS = 'FETCH_TURNOVER_SUCCESS';

// Open or Close
export const SUBMIT_OPEN_OR_CLOSE_SUCCESS = 'SUBMIT_OPEN_OR_CLOSE_SUCCESS';

// Customer Route
export const SUBMIT_OPEN_CUSTOMER_ROUTE_SUCCESS = 'SUBMIT_OPEN_CUSTOMER_ROUTE_SUCCESS';

// Call List
export const FETCH_CALL_LIST_BLOCKS_SUCCESS = 'FETCH_CALL_LIST_BLOCKS_SUCCESS';
export const FETCH_CALL_LIST_BLOCK1_SUCCESS = 'FETCH_CALL_LIST_BLOCK1_SUCCESS';
export const FETCH_CALL_LIST_BLOCK2_SUCCESS = 'FETCH_CALL_LIST_BLOCK2_SUCCESS';
export const FETCH_CALL_LIST_BLOCK3_SUCCESS = 'FETCH_CALL_LIST_BLOCK3_SUCCESS';
export const FETCH_CALL_LIST_BLOCK4_SUCCESS = 'FETCH_CALL_LIST_BLOCK4_SUCCESS';
export const FETCH_CALL_LIST_BLOCK6_SUCCESS = 'FETCH_CALL_LIST_BLOCK6_SUCCESS';

// Get notes inbox
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';

// User
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const EDIT_USER_PASSWORD_SUCCESS = 'EDIT_USER_PASSWORD_SUCCESS';
export const EDIT_USER_PASSWORD_FAIL = 'EDIT_USER_PASSWORD_FAIL';


// Profile
export const FETCH_LOGIN_HISTORY_START = 'FETCH_LOGIN_HISTORY_START';
export const FETCH_LOGIN_HISTORY_SUCCESS = 'FETCH_LOGIN_HISTORY_SUCCESS';