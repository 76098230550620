import React from 'react';
import {Card, CardHeader, CardContent, withStyles} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

const styles = theme => ({
    floatRight: {
        float: 'right',
        marginLeft: '4px',
    },
    cardHeader: {
        backgroundColor: '#f6f8f8',
        borderBottom: '1px solid',
        borderColor: '#dee5e7 !important;'
    }
});

const PrelevementSkeleton = ({classes}) => (
    <div className="flex w-full p-12 md:w-1/2">
        <Card className="w-full">
            <CardHeader 
            className={classes.cardHeader}
            title={
                <>
                <Skeleton variant="text" width={'40%'} height={35} />
                <Skeleton variant="text" width={'10%'} height={25} />
                {/* <Typography variant="body2" color="textSecondary" component="p">
                    {prelevement.debnr}
                </Typography> */}
                </>
            } />
            <CardContent>
                <Skeleton variant="text" width={'70%'} height={30} />
                <Skeleton variant="text" width={'70%'} height={30} />
                <Skeleton variant="text" width={'70%'} height={30} />
            </CardContent>
        </Card>
    </div>
);

export default withStyles(styles)(PrelevementSkeleton);
