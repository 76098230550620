import React, {Component} from 'react';
import {TextField, Button, Dialog, DialogActions, DialogContent, Icon, Typography, Toolbar, AppBar, 
    Avatar, FormControl, OutlinedInput, Select, MenuItem} from '@material-ui/core';
import _ from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';

class UpdateUserDialog extends Component {

    //properties: accountManager, azureId, companyId, groupCode, email, groups, id, imageUrl, name
    state = {
        roles: [],
        accountManagers: [],
    };

    componentDidMount() {
        // const userRoleIds = this.props.user.roles.map(role => role.id);
        if(Object.keys(this.props.user).length !== Object.keys(this.state).length && this.props.groups && this.props.groups.length !== 0) {
            this.setState(
                {   
                    ...this.props.user, 
                    password: '',
                    groups: [...this.props.groups], 
                    roles: [],
                    accountManagers: this.props.accountManagers
                        .filter(a => this.props.user.accountManagers
                            .some(ua => ua.accountManagerCode === a.accountManager)),
                    // roles: this.props.roles.filter(r => userRoleIds.includes(r.id)),
                    changePassword: false,
                }, 
                () => this.filterGroupsByCompany(this.state.companyId)
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!_.isEqual(prevProps.userRoles, this.props.userRoles)) {
            const userRoleIds = this.props.userRoles.map(role => role.id);
            this.setState({...this.state, roles: this.props.roles.filter(r => userRoleIds.includes(r.id))})
        }
    }

    handleChange = (event) => {
        const {name, type, checked, value} = event.target;
        this.setState(_.set({...this.state}, name, type === 'checkbox' ? 
            checked : value));
        if(name === 'companyId') {
            this.filterGroupsByCompany(value);
        }
    };

    handleRolesChange = (roles) => {
        this.setState({ roles })
    }

    handleAccountManagerChange = (accountManagers) => {
        this.setState({ accountManagers })
    }

    canBeSubmitted()
    {
        const {companyId, groupCode, email, name, target, roles} = this.state;
        const groupExists = this.state.groups.map(group => group.groupCode).includes(Number(groupCode));
        if(groupExists) {
            return (
                companyId > 0 && email.length > 0 && name.length > 0 && target > 0 && roles.length > 0
            );
        } else {
            return 'Group does not exist';
        }
    }

    filterGroupsByCompany = value => {
        const groups = this.props.groups.filter(group => group.companyId === value);
        let foundGroup = groups.find(group => group.groupCode === this.props.user.groupCode);
        const firstGroupCode = groups[0].groupCode;
        this.setState({groups, groupCode: foundGroup ? foundGroup.groupCode : firstGroupCode});
    }

    handleSave = () => {
        this.props.onSave({...this.state});
    }

    handleClose = () => {
        this.props.onClose();
    }

    render()
    {
        const {dialogOpen, companies} = this.props;
        return (
            this.state.azureId ? 
                <Dialog 
                    classes={{
                        paper: "m-24"
                    }}
                    onClose={this.handleClose}
                    open={dialogOpen}
                    fullWidth
                    maxWidth="sm"
                >
                    <AppBar position="static" elevation={1}>
                        <Toolbar className="flex w-full">
                        </Toolbar>
                        <div className="flex flex-col items-center justify-center pb-24">
                            <Avatar style={{backgroundColor: '#ef6c00'}} className="w-96 h-96" alt="contact avatar">
                                {this.state.name === '' ? 'X' : this.state.name.substr(0, 1)}
                            </Avatar>
                            <Typography variant="h6" color="inherit" className="pt-8">
                                {this.state.name}
                            </Typography>
                        </div>
                    </AppBar>

                    <DialogContent classes={{root: "p-24"}}>
                        
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">title</Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label="Name"
                                autoFocus
                                id="name"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange}
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </div>

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">mail_outline</Icon>
                            </div>
                            <TextField
                                className="mb-24"
                                label="Email"
                                id="email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </div>

                        <div className="flex mb-20">
                            <span 
                                onClick={() => this.setState({changePassword: !this.state.changePassword})} 
                                style={{color: '#ef6c00', cursor: 'pointer'}}>
                                {this.state.changePassword ? 'Quit changing password' : 'Change password'}
                            </span>
                        </div>
                        {
                            this.state.changePassword ? 
                                <div className="flex">
                                    <div className="min-w-48 pt-20">
                                        <Icon color="action">vpn_key</Icon>
                                    </div>
                                    <TextField
                                        className="mb-24"
                                        label="Password (min 8 characters)"
                                        id="password"
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        InputProps={{
                                            inputProps: {
                                                autoComplete: 'new-password',
                                                form: {
                                                    autoComplete: "off"
                                                }
                                            },
                                        }}
                                    />
                                </div>
                            : null
                        }

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">cloud_queue</Icon>
                            </div>
                            <TextField
                                className="mb-24"
                                id="azureId"
                                label="Azure ID"
                                name="azureId"
                                value={this.state.azureId}
                                variant="outlined"
                                required
                                fullWidth
                                disabled
                            />
                        </div>

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">contacts</Icon>
                            </div>  
                            <Autocomplete
                                className="mb-24 w-full"
                                multiple
                                id="accountmanagers-select"
                                options={this.props.accountManagers}
                                getOptionLabel={(option) =>  option.accountManagerName + ` (${option.accountManager})`}
                                value={this.state.accountManagers}
                                onChange={(event, value) => this.handleAccountManagerChange(value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Select accountmanagers"
                                        placeholder="Start typing..."
                                    />
                                )}
                            />
                        </div>

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">business</Icon>
                            </div>
                            <FormControl variant="outlined" className="mb-24" fullWidth>
                                <Select
                                    value={this.state.companyId}
                                    onChange={this.handleChange}
                                    input={
                                    <OutlinedInput
                                        labelWidth={0}
                                        name="companyId"
                                        id="type"
                                    />
                                    }
                                    required
                                    fullWidth
                                >
                                    {companies.map(company => <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div> 
                        
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">people_outline</Icon>
                            </div>
                            <TextField  
                                className="mb-24"
                                id="groupCode"
                                label="Debiter group"
                                name="groupCode"
                                type="number"
                                value={this.state.groupCode}
                                onChange={this.handleChange}
                                variant="outlined"
                                error={typeof(this.canBeSubmitted()) === 'string'}
                                required
                                fullWidth
                            />
                        </div>
                        
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">lock</Icon>
                            </div>       
                            <Autocomplete
                                className="mb-24 w-full"
                                multiple
                                id="roles-autocomplete"
                                options={this.props.roles}
                                getOptionLabel={(option) => option.name}
                                value={this.state.roles}
                                onChange={(event, value) => this.handleRolesChange(value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="roles-input"
                                        variant="standard"
                                        label="Select roles to assign"
                                        placeholder="Roles"
                                    />
                                )}
                            />
                        </div>
                        
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">show_chart</Icon>
                            </div>
                            <TextField  
                                className="mb-24"
                                id="target"
                                label="Target"
                                name="target"
                                type="number"
                                value={this.state.target}
                                onChange={this.handleChange}
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </div>
                    </DialogContent>
                    <DialogActions className="justify-end pl-16">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleSave}
                            disabled={typeof(this.canBeSubmitted()) !== 'boolean' || !this.canBeSubmitted()}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            : null 
        );
    }
}

export default UpdateUserDialog;
