import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../store/specialoffer/actions';
import {FusePageSimple, FuseAnimateGroup} from '../../../../@fuse';
import {Icon, Typography} from '@material-ui/core';
import ReactTable from "react-table";

class SpecialOfferLog extends Component {

    state = {
        specialOffers: [],
        dialogOpen: false,
    };

    componentDidMount() {
        this.props.onFetchSpecialOfferLog();
    }
    render() {
        return (
            <FusePageSimple
                header={
                    <div className="flex flex-1 items-center justify-between p-24">
                        <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                    <Icon className="text-18" color="action">home</Icon>
                                    <Icon className="text-16" color="action">chevron_right</Icon>
                                    <Typography color="textSecondary">iCRM</Typography>
                                    <Icon className="text-16" color="action">chevron_right</Icon>
                                    <Typography color="textSecondary">Special Offer Log</Typography>
                            </div>
                            <Typography variant="h6">Special Offer Log</Typography>
                        </div>
                    </div>
                }
                content={
                    <>
                        <FuseAnimateGroup
                            className="flex flex-wrap"
                            enter={{
                                animation: "transition.slideUpBigIn"
                            }}
                        > 
                            <div className="w-full">
                                <ReactTable
                                    className="-striped -highlight border-0"
                                    data={this.props.log}
                                    columns={[
                                        {
                                            Header    : "Special Offer Title",
                                            accessor  : "specialOfferName",
                                            className : "font-bold justify-center",
                                            filterable: true,
                                        },
                                        {
                                            Header    : "Sender",
                                            accessor  : "userName",
                                            className : "justify-center",
                                            filterable: true,
                                        },
                                        {
                                            Header    : "Created Campaign",
                                            accessor  : "createdCampaign",
                                            filterable: false,
                                            className: "justify-center",
                                            Cell: row => (
                                                <div>{row.value ? 
                                                    <Icon className="text-18" style={{color: 'green'}}>check</Icon> : 
                                                    <Icon className="text-18" color="error">clear</Icon>}</div>
                                            ),
                                        },
                                        {
                                            Header    : "Sent Campaign",
                                            accessor  : "sentCampaign",
                                            filterable: false,
                                            className: "justify-center",
                                            Cell: row => (
                                                <div>{row.value ? 
                                                    <Icon className="text-18" style={{color: 'green'}}>check</Icon> : 
                                                    <Icon className="text-18" color="error">clear</Icon>}</div>
                                            ),
                                        },
                                        {
                                            Header    : "Datum",
                                            accessor  : "createDate",
                                            className : "justify-center",
                                            filterable: true,
                                        },
                                    ]}
                                    pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                                    defaultPageSize={10}
                                    noDataText="No customers found"
                                /> 
                            </div>
                        </FuseAnimateGroup>
                    </>
                }
            />
        )
    }
}
  
const mapStateToProps = ({ specialoffer }) => {
    return {
        log: specialoffer.log
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchSpecialOfferLog: () => dispatch(actions.fetchSpecialOfferLog())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SpecialOfferLog));