import React, { Component } from 'react';
import {withStyles} from '@material-ui/core';
import {FusePageSimple, FuseAnimateGroup} from '../../../../@fuse';
import {Icon, IconButton, Typography, Tooltip} from '@material-ui/core';
import ReactTable from "react-table";
import {connect} from 'react-redux';
import * as actions from '../../../store/api/actions';

const styles = theme => ({
    layoutRoot: {},
});

class Callcenter extends Component {

    componentDidMount() {
        if(this.props.apis.length === 0) {
            this.props.onFetchApis();
        }
    }

    handleDownloadClick = api => {
        window.location.href = process.env.REACT_APP_APIURL + api.link + `?type=${api.name}`;
    }

    handleSendClick = api => {
        this.props.onSendCallcenter(api.name);
    }
    
    render() {
        const {classes, apis} = this.props;
        const tempApis = apis.map(api => ({
            ...api,
            type: api.type.split(',')
        }));
        const callcenters = tempApis.filter(api => api.type.includes('send_callcenter') || api.type.includes('send_callcenter'));

        return (
            <FusePageSimple
                classes={{
                    root: classes.layoutRoot
                }}
                header={
                      <div className="flex flex-1 items-center justify-between p-24">
                         <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                  <Icon className="text-18" color="action">home</Icon>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">API's</Typography>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">Callcenter</Typography>
                            </div>
                            <Typography variant="h6">Callcenter</Typography>
                         </div>
                      </div>
                }
                content={
                        <div>
                            <FuseAnimateGroup
                                className="flex flex-wrap"
                                enter={{
                                    animation: "transition.slideUpBigIn"
                                }}
                            >
                            {callcenters.length > 0 ?
                                <div className="w-full">
                                    <ReactTable
                                        style={{border: '0px'}}
                                        className="-striped -highlight border-0"
                                        data={callcenters}
                                        columns={[
                                            {
                                                Header    : "Name",
                                                accessor  : "name",
                                                filterable: false,
                                                className: "justify-center font-bold",
                                                maxWidth: 120,
                                            },
                                            {
                                                Header    : "Description",
                                                accessor  : "description",
                                                filterable: false,
                                                className: "justify-center",
                                            },
                                            {
                                                Header    : "Company",
                                                accessor  : "companyName",
                                                filterable: false,
                                                className: "justify-center font-bold",
                                                maxWidth: 120,
                                            },
                                            {
                                                Header    : "Send",
                                                filterable: false,
                                                className: "justify-center",
                                                width: 100,
                                                Cell: row => (
                                                <Tooltip title={`Send ${row.original.name}`}>
                                                    <IconButton aria-label="Edit" onClick={() => this.handleSendClick(row.original)}>
                                                        <Icon>send</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                )
                                            },
                                            {
                                                Header    : "Download",
                                                filterable: false,
                                                className: "justify-center",
                                                width: 100,
                                                Cell: row => (
                                                <Tooltip title={`Download ${row.original.name}`}>
                                                    <IconButton aria-label="Edit" onClick={() => this.handleDownloadClick(row.original)}>
                                                        <Icon>save_alt</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                )
                                            },
                                        ]}
                                        pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                                        pageSize={callcenters.length <= 10 ? callcenters.length : 10}
                                        noDataText="No customers found"
                                    />
                                </div> 
                            : null}
                            </FuseAnimateGroup>
                        </div>
                }
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        apis: state.api.apis,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchApis: () => dispatch(actions.fetchApis()),
        onSendCallcenter: (type) => dispatch(actions.sendCallcenter(type)),
        onDownloadCallcenter: (name) => dispatch(actions.downloadCallcenter(name)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles, {withTheme: true})(Callcenter));