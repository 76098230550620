import React, { Component } from 'react';
import {
    Button, Dialog, Icon, Typography, TextField,
    IconButton, Tooltip, Switch, FormControl, Select, OutlinedInput, MenuItem, InputAdornment, Link
} from '@material-ui/core';
import Alert from '../../../../components/UI/Alert/Alert';
import AlertMessage from '@material-ui/lab/Alert';

// import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { getUsername } from '../../../Auth/Auth';
import * as actions from '../../../../store/crm/manager/actions';
import { connect } from 'react-redux';
import NewLeadsMultipleDetailsDialog from '../../Salesman/NewLeads/NewLeadsMultipleDetailsDialog';
import NewLeadsMultipleVatDetailsDialog from '../../Salesman/NewLeads/NewLeadsMultipleVatDetailsDialog';
class EditNewLeadsUserDialog extends Component {

    state = {
        // checkedDelivery: false,
        allTemplates: [],
        selectedUsers: [],
        favtemplates: [],
        userInput: '',
        LeadMultipleDetailDialogOpen: false,
        LeadMultipleVatDetailDialogOpen: false,
    }

    handleClose = () => {
        this.props.onClose();
        this.setState({ userInput: '' });
    }

    // handleInputChange = event => {
    //     console.log('setting form..')
    //     // this.setState({userInput: event.target.value});
    //     this.setState({
    //         form: {...this.state.form, [event.target.name]: event.target.value},
    //     })
    // }

    editNewLeadSuccess = (isEditedNewLead) => {
        this.setState({ isEditedNewLead: isEditedNewLead })
    }

    onSelectUser = (selectedUser) => {
        // console.log(selectedUser);
        const users = [...this.state.selectedUsers, selectedUser];
        // this.setState({selectedUsers: users, userInput: ''});
        this.setState({ selectedUsers: users });
        this.props.onSave(selectedUser);
    }

    renderSuggestion = (suggestion) => {
        return (
            <MenuItem key={suggestion.template_debnr} value={suggestion.template_debnr}>{suggestion.template_debnr} : {suggestion.template_name}</MenuItem>
        );
    }

    handleInputChange = event => {
        this.setState({ userInput: event.target.value }, this.filterUsersByInput);
    }

    filterUsersByInput = () => {
        const users = this.props.users.map(u => {
            return {
                template_debnr: u.template_debnr,
                template_name: u.template_name,
                azureId: u.azure_id
            }
        });

        const userInput = this.state.userInput.toLowerCase();
        const filteredUsers = users.filter(u => u.template_debnr.toLowerCase().includes(userInput) || u.template_name.toLowerCase().includes(userInput));

        // const filteredUsers = filterAll(users, this.state.userInput);
        return filteredUsers;

    }
    toggleUserModal = (email) => {
        this.props.onFetchMultipleEmailLeads(email);

        this.setState((state) => {
            return {
                LeadMultipleDetailDialogOpen: !state.LeadMultipleDetailDialogOpen
            }
        })
    }
    // componentDidMount() {
    //     this.props.onFetchAllDebType();
    //     // this.props.onFetchTransportersNewLeads();
    // }

    toggleVatModal = (vat)=>{
        this.props.onFetchMultipleVatLeads(vat);

        this.setState((state)=>{
            return{
                LeadMultipleVatDetailDialogOpen: !state.LeadMultipleVatDetailDialogOpen
            }
        })
    }

    handleViesCheck = () => {
        // console.log(this.props.afterCheckVies);
        this.props.onCheckVies(this.props.newLeadForm, this.props.afterCheckVies);
    }

    render() {
        const { dialogOpen, t, newLeadForm, handleChange, handleonBlur, handleSwitch, isDeleted, afterDelete, isEditedNewLead, checkedDelivery, MultipleEmailLeads, MultipleVatLeads } = this.props;
        // const allTemplates = this.props.AllTemplatesNewLeads;
        return (

            <Dialog
                classes={{
                    paper: "m-24"
                }}
                onClose={this.handleClose}
                open={dialogOpen}
                fullWidth
                maxWidth="lg"
            >
                 {MultipleEmailLeads != undefined ? (
                 <NewLeadsMultipleDetailsDialog
                 dialogOpen={this.state.LeadMultipleDetailDialogOpen}
                 onClose={this.toggleUserModal}
                 multipledataleads={this.props.MultipleEmailLeads}
             />
            ) : ('')}
                {MultipleVatLeads != undefined ? (
                <NewLeadsMultipleVatDetailsDialog
                dialogOpen={this.state.LeadMultipleVatDetailDialogOpen}
                onClose={this.toggleVatModal}
                multiplevatdataleads={this.props.MultipleVatLeads}
            />
            ) : ('')}
                {/* <AppBar position="static" elevation={1}>
                    <Toolbar className="flex w-full">
                        <Tooltip title="Go back">
                            <IconButton aria-label="Return" size="small" color="secondary" onClick={this.handleClose}>
                                <Icon>arrow_back</Icon>
                            </IconButton>
                        </Tooltip>
                        <Typography variant="subtitle1" color="inherit" className="ml-16">
                            {t('EDIT')} {t('NEWLEAD')}
                        </Typography>
                    </Toolbar>
                </AppBar> */}

                <div className="w-full">
                    <div className="flex flex-wrap">
                        <div className="flex w-full sm:w-full md:w-1/2 lg:w-1/2 p-24">
                            <div className="w-full">
                                {/* <CardContent> */}
                                {isDeleted === true ?
                                    <Alert title={t('DELETEDNEWLEADSUCCESS')} status="AlertSuccess" />
                                    :
                                    <>
                                        <Typography variant="h5" component="h3">
                                            {t('EDIT') + " " + t('NEW') + " " + t('LEADS')}
                                        </Typography>
                                        <div className="p-12">
                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title={t('EMAIL')}>
                                                        <Icon color="action">email</Icon>
                                                    </Tooltip>
                                                </div>

                                                <TextField
                                                    className="mb-24"
                                                    id="email"
                                                    name="email"
                                                    label={t('EMAIL')}
                                                    value={newLeadForm.email}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    inputProps={{ maxLength: 100 }}
                                                    required
                                                    fullWidth
                                                    disabled
                                                />
                                            </div>

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title={t('OWNER')}>
                                                        <Icon color="action">account_circle</Icon>
                                                    </Tooltip>
                                                </div>

                                                <TextField
                                                    className="mb-24"
                                                    autoFocus
                                                    id="debcont"
                                                    name="debcont"
                                                    label={t('OWNER')}
                                                    value={newLeadForm.debcont}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    inputProps={{ maxLength: 100 }}
                                                    required
                                                    fullWidth
                                                />
                                            </div>

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title={t('SHOP') + " " + t('NAME')}>
                                                        <Icon color="action">store</Icon>
                                                    </Tooltip>
                                                </div>

                                                <TextField
                                                    className="mb-24"
                                                    id="debnaam"
                                                    name="debnaam"
                                                    // placeholder={t('SHOP') + " " + t('NAME')}
                                                    label={t('SHOPNAMEFORINVOICE')}
                                                    value={newLeadForm.debnaam}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">
                                                            <Typography variant="caption" color="primary"><i>VIES</i></Typography>
                                                        </InputAdornment>,
                                                        inputProps: { maxLength: 100 }
                                                    }}
                                                />
                                            </div>

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title={t('ADDRESS')}>
                                                        <Icon color="action">list</Icon>
                                                    </Tooltip>
                                                </div>

                                                <TextField
                                                    className="mb-24"
                                                    id="debadres"
                                                    name="debadres"
                                                    label={t('ADDRESS')}
                                                    value={newLeadForm.debadres}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">
                                                            <Typography variant="caption" color="primary"><i>VIES</i></Typography>
                                                        </InputAdornment>,
                                                        inputProps: { maxLength: 100 }
                                                    }}
                                                />
                                            </div>

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title={t('ZIPCODE')}>
                                                        <Icon color="action">list</Icon>
                                                    </Tooltip>
                                                </div>

                                                <TextField
                                                    className="mb-24"
                                                    id="debpc"
                                                    name="debpc"
                                                    label={t('ZIPCODE')}
                                                    value={newLeadForm.debpc}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">
                                                            <Typography variant="caption" color="primary"><i>VIES</i></Typography>
                                                        </InputAdornment>,
                                                        inputProps: { maxLength: 8 }
                                                    }}
                                                />
                                            </div>

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title={t('CITY')}>
                                                        <Icon color="action">list</Icon>
                                                    </Tooltip>
                                                </div>

                                                <TextField
                                                    className="mb-24"
                                                    id="debplaats"
                                                    name="debplaats"
                                                    label={t('CITY')}
                                                    value={newLeadForm.debplaats}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">
                                                            <Typography variant="caption" color="primary"><i>VIES</i></Typography>
                                                        </InputAdornment>,
                                                        inputProps: { maxLength: 50 }
                                                    }}
                                                />
                                            </div>

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title={t('PHONE')}>
                                                        <Icon color="action">phone</Icon>
                                                    </Tooltip>
                                                </div>

                                                <TextField
                                                    className="mb-24"
                                                    id="debtfnr"
                                                    name="debtfnr"
                                                    label={t('PHONE')}
                                                    value={newLeadForm.debtfnr}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    inputProps={{ maxLength: 20 }}
                                                    fullWidth
                                                />
                                            </div>

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title={t('PHONE')}>
                                                        <Icon color="action">phone_iphone</Icon>
                                                    </Tooltip>
                                                </div>

                                                <TextField
                                                    className="mb-24"
                                                    id="debmobiel"
                                                    name="debmobiel"
                                                    label={t('MOBILE')}
                                                    value={newLeadForm.debmobiel}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    inputProps={{ maxLength: 20 }}
                                                    fullWidth
                                                />
                                            </div>

                                        </div>
                                    </>
                                }
                                
                                <Typography variant="h5" component="h3">
                                    {t('DELIVERY')}
                                </Typography>

                                <Switch
                                    checked={checkedDelivery}
                                    onChange={handleSwitch}
                                    color="primary"
                                    name="checkedDelivery"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />

                                {checkedDelivery === true || newLeadForm.lvadresdb !== '' || newLeadForm.lvpcdb !== '' || newLeadForm.lvplaatsdb !== '' || newLeadForm.lvnaamdb !== '' ?
                                    <div className="p-12">
                                        <div className="flex">
                                            <div className="min-w-48 pt-20">
                                                <Tooltip title={t('SHOP') + " " + t('NAME')}>
                                                    <Icon color="action">store</Icon>
                                                </Tooltip>
                                            </div>

                                            <TextField
                                                className="mb-24"
                                                id="lvnaamdb"
                                                name="lvnaamdb"
                                                label={t('SHOP') + " " + t('NAME')}
                                                value={newLeadForm.lvnaamdb}
                                                onChange={handleChange}
                                                variant="outlined"
                                                inputProps={{ maxLength: 200 }}
                                                fullWidth
                                            />
                                        </div>

                                        <div className="flex">
                                            <div className="min-w-48 pt-20">
                                                <Tooltip title={t('DELIVERY') + " " + t('CITY')}>
                                                    <Icon color="action">local_shipping</Icon>
                                                </Tooltip>
                                            </div>

                                            <TextField
                                                className="mb-24"
                                                id="lvadresdb"
                                                name="lvadresdb"
                                                label={t('DELIVERY') + " " + t('ADDRESS')}
                                                value={newLeadForm.lvadresdb}
                                                onChange={handleChange}
                                                variant="outlined"
                                                inputProps={{ maxLength: 100 }}
                                                fullWidth
                                            />
                                        </div>

                                        <div className="flex">
                                            <div className="min-w-48 pt-20">
                                                <Tooltip title={t('DELIVERY') + " " + t('ZIPCODE')}>
                                                    <Icon color="action">local_shipping</Icon>
                                                </Tooltip>
                                            </div>

                                            <TextField
                                                className="mb-24"
                                                id="lvpcdb"
                                                name="lvpcdb"
                                                label={t('DELIVERY') + " " + t('ZIPCODE')}
                                                value={newLeadForm.lvpcdb}
                                                onChange={handleChange}
                                                variant="outlined"
                                                inputProps={{ maxLength: 8 }}
                                                fullWidth
                                            />
                                        </div>

                                        <div className="flex">
                                            <div className="min-w-48 pt-20">
                                                <Tooltip title={t('DELIVERY') + " " + t('CITY')}>
                                                    <Icon color="action">local_shipping</Icon>
                                                </Tooltip>
                                            </div>

                                            <TextField
                                                className="mb-24"
                                                id="lvplaatsdb"
                                                name="lvplaatsdb"
                                                label={t('DELIVERY') + " " + t('CITY')}
                                                value={newLeadForm.lvplaatsdb}
                                                onChange={handleChange}
                                                variant="outlined"
                                                inputProps={{ maxLength: 50 }}
                                                fullWidth
                                            />
                                        </div>

                                    </div>
                                    : ""

                                }
                            </div>
                        </div>
                        <div className="flex w-full sm:w-full md:w-1/2 lg:w-1/2 p-24">
                            <div className="w-full">
                                <Typography variant="h5" component="h3">
                                    {t('COMPANY')}
                                </Typography>
                                <div className="p-12">

                                    <div className="flex">
                                        {newLeadForm.vieS_VALID ?
                                            <>
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title={t('VAT')}>
                                                        <Icon color="action" style={{ color: 'green' }}>business</Icon>
                                                    </Tooltip>
                                                </div>

                                                <TextField
                                                    className="mb-24"
                                                    id="btwnrdeb"
                                                    name="btwnrdeb"
                                                    label={t('VAT')}
                                                    value={newLeadForm.btwnrdeb}
                                                    onChange={handleChange}
                                                    placeholder={"FR12 345 678 910"}
                                                    variant="outlined"
                                                    fullWidth
                                                    onBlur={handleonBlur}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">
                                                            {newLeadForm.email === '' || newLeadForm.email === undefined || newLeadForm.email === null ?
                                                                <Tooltip title={"Email is required"}>
                                                                    <IconButton aria-label="Sync">
                                                                        <Icon style={{ color: 'red' }}>sync_disabled</Icon>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                :
                                                                <IconButton onClick={this.handleViesCheck} aria-label="Sync">
                                                                    <Tooltip title={t('CHECK') + " " + t('VAT')}>
                                                                        <Icon color="primary">sync</Icon>
                                                                        {/* <Icon onClick={() => this.props.onCheckVies(newLeadForm)} color="primary">sync</Icon> */}
                                                                    </Tooltip>
                                                                </IconButton>
                                                            }
                                                        </InputAdornment>,
                                                        inputProps: { maxLength: 20 }
                                                    }}
                                                />
                                                <div className="min-w-48 ml-20 pt-20">
                                                    <Tooltip title={t('VAT')}>
                                                        <Icon color="action" style={{ color: 'green' }}>check_circle</Icon>
                                                    </Tooltip>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title={t('VAT')}>
                                                        <Icon color="action" style={{ color: 'red' }}>business</Icon>
                                                    </Tooltip>
                                                </div>
                                                <TextField
                                                    className="mb-24"
                                                    id="btwnrdeb"
                                                    name="btwnrdeb"
                                                    label={t('VAT')}
                                                    value={newLeadForm.btwnrdeb}
                                                    onChange={handleChange}
                                                    placeholder={"FR12 345 678 910"}
                                                    variant="outlined"
                                                    fullWidth
                                                    onBlur={handleonBlur}
                                                    error
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">
                                                            {newLeadForm.email === '' || newLeadForm.email === undefined || newLeadForm.email === null ?
                                                                <Tooltip title={"Email is required"}>
                                                                    <IconButton aria-label="Sync">
                                                                        <Icon style={{ color: 'red' }}>sync_disabled</Icon>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                :
                                                                <IconButton aria-label="Sync">
                                                                    <Tooltip title={t('CHECK') + " " + t('VAT')}>
                                                                        <Icon onClick={this.handleViesCheck} color="primary">sync</Icon>
                                                                        {/* <Icon onClick={() => this.props.onEditNewLead(newLeadForm, afterDelete)} color="primary">sync</Icon> */}
                                                                    </Tooltip>
                                                                </IconButton>
                                                            }
                                                        </InputAdornment>,
                                                        inputProps: { maxLength: 20 }
                                                    }}
                                                />
                                                <div className="min-w-48 ml-20 pt-20">
                                                    <Tooltip title={t('VAT')}>
                                                        <Icon color="action" style={{ color: 'red' }}>cancel</Icon>
                                                    </Tooltip>
                                                </div>
                                            </>
                                        }
                                    </div>

                                    {newLeadForm.vieS_VALID ?
                                        <div className="flex">
                                            <div className="min-w-48 pt-20">
                                                <Tooltip title={"VIES Info"}>
                                                    <Icon color="action">business</Icon>
                                                </Tooltip>
                                            </div>

                                            <TextField
                                                className="mb-24"
                                                id="viesinfo"
                                                name="btwnrdeb"
                                                label={"VIES Information"}
                                                value={newLeadForm.vieS_NAME + "\n" + newLeadForm.vieS_ADDRESS + "\n" + newLeadForm.vieS_VAT}
                                                onChange={handleChange}
                                                variant="outlined"
                                                inputProps={{ maxLength: 200 }}
                                                fullWidth
                                                multiline
                                                disabled
                                            />
                                        </div>
                                        :
                                        <div className="flex">
                                            <div className="min-w-48 pt-20">
                                                <Tooltip title={"VIES Info"}>
                                                    <Icon color="action">business</Icon>
                                                </Tooltip>
                                            </div>

                                            <TextField
                                                className="mb-24"
                                                id="viesinfo"
                                                name="btwnrdeb"
                                                label={"VIES Information unknown"}
                                                value={newLeadForm.vieS_NAME + "\n" + newLeadForm.vieS_ADDRESS}
                                                onChange={handleChange}
                                                variant="outlined"
                                                inputProps={{ maxLength: 200 }}
                                                fullWidth
                                                multiline
                                                disabled
                                                error
                                                color="secondary"
                                            // helperText={t('VAT') + " number is false."}
                                            />
                                        </div>

                                    }

                                    <div className="flex">
                                        <div className="min-w-48 pt-20">
                                            <Tooltip title={t('KVK')}>
                                                <Icon color="action">business</Icon>
                                            </Tooltip>
                                        </div>

                                        <TextField
                                            className="mb-24"
                                            id="kvk"
                                            name="kvk"
                                            label={t('KVK')}
                                            value={newLeadForm.kvk}
                                            onChange={handleChange}
                                            variant="outlined"
                                            inputProps={{ maxLength: 20 }}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="flex">
                                        <div className="min-w-48 pt-20">
                                            <Tooltip title={t('DEBTEXTFIELD')}>
                                                <Icon color="action">comment</Icon>
                                            </Tooltip>
                                        </div>

                                        <TextField
                                            className="mb-24"
                                            id="debtext"
                                            name="debtext"
                                            row={5}
                                            // name="acctmngR_NOTE"
                                            placeholder={t('DEBTEXTPLACEHOLDER')}
                                            label={t('DEBTEXTFIELD')}
                                            value={newLeadForm.debtext}
                                            onChange={handleChange}
                                            variant="outlined"
                                            inputProps={{maxLength: 255}}
                                            fullWidth
                                            multiline={true}
                                            required
                                        />
                                    </div>
                            </div>
                                <Typography variant="h5" component="h3">
                                    Florisoft
                                </Typography>
                                <div className="p-12">

                                    <div className="flex">
                                        <div className="min-w-48 pt-20">
                                            <Tooltip title={t('CUSTOMER') + " template"}>
                                                <Icon color="action">file_copy</Icon>
                                            </Tooltip>
                                        </div>

                                        <TextField
                                            className="mb-24 mr-6"
                                            name='userInput'
                                            label={'Search for templates'}
                                            value={this.state.userInput}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <FormControl variant="outlined" className="mb-24 ml-6" fullWidth>
                                            <Select
                                                // value={this.state.type}
                                                // onChange={this.handleChange}
                                                input={
                                                    <OutlinedInput
                                                        labelWidth={0}
                                                        name="template"
                                                        id="template"
                                                        // defaultValue={"TM10 "}
                                                        value={newLeadForm.template.trim()}
                                                        onChange={handleChange}
                                                    />
                                                }
                                                required
                                                fullWidth
                                            >
                                                {this.filterUsersByInput().slice(0, 5000).map(u => this.renderSuggestion(u))}
                                                {/* {users.map(i => <MenuItem key={i.template_debnr} value={i.template_debnr}>{i.template_debnr} : {i.template_name}</MenuItem>)} */}
                                            </Select>
                                        </FormControl>

                                    </div>
                                    {/* {DebType != undefined && DebType.length > 0 ?
                                    <div className="flex">
                                        <div className="min-w-48 pt-20">
                                            <Tooltip title={t('DEBFINGRP')}>
                                                <Icon color="action">store</Icon>
                                            </Tooltip>
                                        </div>
                                        <FormControl variant="outlined" className="mb-24" fullWidth>
                                            <Tooltip title={t('DEBFINGRP')}>                                             
                                            <Select
                                                // value={this.state.type}
                                                // onChange={this.handleChange}
                                                input={
                                                <OutlinedInput
                                                    labelWidth={0}
                                                    name="debfingrp"
                                                    id="debfingrp"
                                                    // defaultValue={"TM10"}
                                                    value={newLeadForm.debfingrp}
                                                    onChange={handleChange}
                                                />
                                                }
                                                required
                                                fullWidth
                                            >
                                                {DebType.map(i => <MenuItem key={i.id} value={i.id}>{i.text}</MenuItem>)}
                                                {/* <MenuItem value="TMP001">Template 001 FR</MenuItem>
                                                <MenuItem value="TMP002">template 002 NL</MenuItem> */}
                                            {/* </Select>
                                            </Tooltip>
                                        </FormControl>
                                
                                    </div>
                                    :
                                        ''
                                    } */}
                                    {newLeadForm.template === 'ARCHIVE' ?
                                        <div className="flex">
                                            <div className="min-w-48">
                                                <Icon color="action" style={{ color: 'orange' }}>warning</Icon>
                                            </div>
                                            <FormControl variant="outlined" className="mb-24" fullWidth>
                                                <p style={{ color: 'orange' }}>{t('NEWLEADARCHIVEINFO')}</p>
                                            </FormControl>
                                        </div>
                                        : ""
                                    }

                                    {newLeadForm.template === 'ARCHIVE' ?
                                        <div className="flex">
                                            <div className="min-w-48 pt-20">
                                                <Tooltip title={t('NOTE')}>
                                                    <Icon color="action">comment</Icon>
                                                </Tooltip>
                                            </div>

                                            <TextField
                                                className="mb-24"
                                                id="acctmngR_NOTE"
                                                name="acctmngR_NOTE"
                                                // name="acctmngR_NOTE"
                                                // placeholder={t('SHOP') + " " + t('NAME')}
                                                label={t('NOTE')}
                                                value={newLeadForm.acctmngR_NOTE}
                                                onChange={handleChange}
                                                variant="outlined"
                                                inputProps={{ maxLength: 255 }}
                                                fullWidth
                                                multiline={true}
                                                required
                                            />
                                        </div>

                                        :

                                        <div className="flex">
                                            <div className="min-w-48 pt-20">
                                                <Tooltip title={t('NOTE')}>
                                                    <Icon color="action">comment</Icon>
                                                </Tooltip>
                                            </div>

                                            <TextField
                                                className="mb-24"
                                                id="acctmngR_NOTE"
                                                name="acctmngR_NOTE"
                                                // name="acctmngR_NOTE"
                                                // placeholder={t('SHOP') + " " + t('NAME')}
                                                label={t('NOTE')}
                                                value={newLeadForm.acctmngR_NOTE}
                                                onChange={handleChange}
                                                variant="outlined"
                                                inputProps={{ maxLength: 255 }}
                                                fullWidth
                                                multiline={true}
                                            />
                                        </div>

                                    }

                                    <div className="flex">
                                        <div className="min-w-48 pt-20">
                                            <Tooltip title={"DEBNR"}>
                                                <Icon color="action">account_circle</Icon>
                                            </Tooltip>
                                        </div>

                                        {/* {newLeadForm.suggesteD_DEBNR ? <div className="mr-6 w-1/2" fullWidth><Typography> debLast knownnr is :<br /><b>{newLeadForm.suggesteD_DEBNR}</b></Typography></div>
                                : null
                                } */}
                                        <TextField
                                            className="mb-24 mr-6 w-1/2"
                                            id="suggesteD_DEBNR"
                                            name="suggesteD_DEBNR"
                                            label={newLeadForm.suggesteD_DEBNR === null ? "NOT FOUND" : "LAST DEBNR"}
                                            value={newLeadForm.suggesteD_DEBNR === null ? null : newLeadForm.suggesteD_DEBNR}
                                            onChange={handleChange}
                                            variant="outlined"
                                            inputProps={{ maxLength: 6 }}
                                            disabled
                                        />

                                        <TextField
                                            className="mb-24 ml-6 w-1/2"
                                            id="debnr"
                                            name="debnr"
                                            label={"DEBNR"}
                                            value={newLeadForm.debnr}
                                            onChange={handleChange}
                                            variant="outlined"
                                            inputProps={{ maxLength: 6 }}
                                            autoFocus
                                        />
                                    </div>

                                    <div className="flex">
                                        <div className="min-w-48 pt-20">
                                            <Tooltip title={"FINANCIEL DEBNR"}>
                                                <Icon color="action">account_circle</Icon>
                                            </Tooltip>
                                        </div>

                                        <TextField
                                            className="mb-24 mr-6 w-full"
                                            id="findebnr"
                                            name="findebnr"
                                            label="FINANCIEL DEBNR"
                                            value={newLeadForm.findebnr === null ? null : newLeadForm.findebnr}
                                            onChange={handleChange}
                                            variant="outlined"
                                            inputProps={{ maxLength: 20 }}
                                        />

                                        {/* <TextField
                                    className="mb-24 ml-6 w-1/2"
                                    id="debnr"
                                    name="debnr"
                                    label={"DEBNR"}
                                    value={newLeadForm.debnr}
                                    onChange={handleChange}
                                    variant="outlined"
                                    inputProps={{maxLength: 6}}
                                    disabled
                                /> */}
                                    </div>

                                    {this.props.createNewLeadFailed ? <Typography style={{ color: 'red' }}>Error : {this.props.error.toString()}</Typography>
                                        : null
                                    }
                                    {isEditedNewLead === true ?
                                        <>
                                            <Alert title={t('NEWLEAD') + " " + t('UPDATEDSUCCESS') + " ! "} status="AlertSuccess" />
                                            <div className="w-full p-6">
                                                <Button onClick={this.handleClose} fullWidth variant="contained" color="primary">{t('CLOSE')}</Button>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {newLeadForm.countEmails > 1 ?
                                                <div className="w-full pb-12 mb-12"><AlertMessage className="w-full" severity="warning">{"(" + newLeadForm.countEmails + ") " + t("MULTIPLEEMAILS")}.
                                                    <Link LeadId={newLeadForm.id} style={{color: "#ef6c00", cursor: "pointer"}} LeadEmail={newLeadForm.email} onClick={() => this.toggleUserModal(newLeadForm.email)}> See details</Link>
                                                </AlertMessage>

                                                </div>
                                                :
                                                null
                                            }
                                            {newLeadForm.countVAT > 1 ?
                                                <div className="w-full pb-12 mb-12"><AlertMessage className="w-full" severity="warning">{"(" + newLeadForm.countVAT + ") " + t("MULTIPLEVAT")}.
                                                    <Link LeadId={newLeadForm.id} style={{color: "#ef6c00", cursor: "pointer"}} LeadVat={newLeadForm.btwnrdeb} onClick={() => this.toggleVatModal(newLeadForm.btwnrdeb)}> See details</Link>
                                                </AlertMessage>

                                                </div>
                                                :
                                                null
                                            }
                                            <div className="flex flex-wrap">
                                                <div className="sm:w-1/2 md:w-1/2 lg:w-1/2 pr-12">
                                                    <p className="float-right"><i><b>{getUsername()}</b>, {t('AREYOUSURE')}</i></p>
                                                </div>
                                                <div className="flex sm:w-1/2 md:w-1/2 lg:w-1/2 pl-12">
                                                    <div className="w-1/2 p-6">
                                                        <Button onClick={this.handleClose} fullWidth variant="contained" color="secondary">{t('CANCEL')}</Button>
                                                    </div>
                                                    <div className="w-1/2 p-6">
                                                        <Button onClick={() => this.props.onEditNewLead(newLeadForm, afterDelete)} fullWidth variant="contained" color="primary">{t('SAVE')}</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                </div>
                                {/* </CardContent> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <DialogContent classes={{root: "p-24"}}>
                </DialogContent> */}
            </Dialog>
        );
    }
}

const mapStateToProps = ({ manager }) => {
    return {
        isEditedNewLead: manager.isEditedNewLead,
        viesData: manager.viesData,
        MultipleEmailLeads: manager.MultipleEmailLeads,
        MultipleVatLeads: manager.MultipleVatLeads,
        // DebType: manager.DebType,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onEditNewLead: (newLeadForm, afterDelete) => dispatch(actions.editNewLead(newLeadForm, afterDelete, getUsername())),
        onCheckVies: (newLeadForm, afterCheckVies) => dispatch(actions.checkVies(newLeadForm, afterCheckVies)),
        onFetchMultipleEmailLeads: email => dispatch(actions.fetchDbMultipleEmailLeads(email)),
        onFetchMultipleVatLeads: vat => dispatch(actions.fetchDbMultipleVatLeads(vat)),
        // onFetchAllDebType: () => dispatch(actions.onFetchAllDebType()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditNewLeadsUserDialog));
