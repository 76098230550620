import React from 'react'
import { Card } from '@material-ui/core';
import { LinkToDebnrWithChildren } from '../../../../../components/Functions/RouteToDebnr';
import { useTranslation } from 'react-i18next';
import { numberToLocale } from '../../../../../components/Functions/NumberToLocale';

function DashboardKoersen({koersen}) {
    const { t } = useTranslation();

    return (
        <div className="flex flex-wrap">
        {
            koersen && Object.keys(koersen).map(k => (
                koersen[k].length !== 0 &&
                    <div key={k} className="sm:w-full md:w-1/2 lg:w-1/2 p-12">
                        <Card className="rounded-8 shadow-none border-1">
                            {/* <Table data={tables.ordersizeTable} title={`Top 10 ${t("AVGORDERGROOTTE")}`} /> */}
                            <table className={"simple clickable"}>
                                <thead>
                                    <tr>
                                        <th colSpan={4} style={{textAlign: 'center'}}>Top 20 {k} in gerealiseerde omzet</th>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th style={{fontWeight: 'bold'}} height={40}>{`${t('CUSTOMER')} ${t('NAME')}`}</th>
                                        <th style={{fontWeight: 'bold', textAlign: 'right'}} height={40}>{t('THISYR')}</th>
                                        <th style={{fontWeight: 'bold', textAlign: 'right'}} height={40}>{t('LASTYR')}</th>
                                        <th style={{fontWeight: 'bold', textAlign: 'right'}} height={40}>Verschil</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {koersen[k].map((row, index) => (
                                    <tr key={index}>
                                        <td><LinkToDebnrWithChildren debnr={row.debnr}>{row.debnaam}</LinkToDebnrWithChildren></td>
                                        <td  className="text-right">&euro;{numberToLocale(row.cyTotal)}</td>
                                        <td  className="text-right">&euro;{numberToLocale(row.lyTotal)}</td>
                                        <td width='100px' className="text-right">{row.changePercentage.toFixed(2)} &#37;</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </Card>
                    </div>
            ))
        }
        </div>
    )
}

export default DashboardKoersen
