import React, { Component } from 'react';
import { FusePageSimple, FuseAnimateGroup, FuseAnimate } from '../../../../../@fuse';
import { Icon, Typography, Tooltip, Card, CardContent, AppBar, Toolbar, FormControlLabel, InputAdornment, IconButton, StepConnector } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import TaskDetailHeader from './TaskDetailComponents/TaskDetailHeader';
import { connect } from 'react-redux';
import * as actions from '../../../../store/task/actions';
import * as customerActions from '../../../../store/crm/actions';
import { getAzureId, checkRole } from '../../../Auth/Auth';
import 'date-fns';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import TaskDetailFormSkeleton from './TaskDetailComponents/TaskDetailFormSkeleton';
import TaskDetailTimeLineSkeleton from './TaskDetailComponents/TaskDetailTimeLineSkeleton';
import _ from 'lodash';
import styled from 'styled-components';
import TaskDetailWeblog from './TaskDetailComponents/TaskDetailWeblog';
import FirstOrderTaskForm from './TaskDetailComponents/BI/FirstOrderTaskForm';
import FirstOrderInformation from './TaskDetailComponents/BI/FirstOrderInformation';

const FlexCol = styled.div`
    width: 50%;
    @media(max-width: 767px) {
        width: 100%;
    }
`

class OrderTaskDetail extends Component {

    state = {
        title: '',
        description: '',
        debnr: '',
        azureId: '',
        accountManager: '',
        selectedAccountManager: null,
        startDate: new Date(),
        endDate: new Date(),
        taskType: 'BI Retention',
        urgency: false,
        comment: '',
        notes: '',
        serviceOption: 5,
        serviceNote: '',
        qualityOption: 5,
        qualityNote: '',
        priceOption: 5,
        priceNote: '',
        webshopOption: 5,
        webshopNote: '',
        transportOption: 5,
        transportNote: '',
        concluded: false,
        insertedTaskData: false,
        triedToSubmit: false,
        changedAccountManager: false,
    }

    componentDidMount() {
        const isOrderTask = this.props.location.pathname.includes('ordertask');
        this.props.onFetchTask(this.props.match.params.id, isOrderTask);
        this.props.onFetchAccountManagers();
    }

    componentDidUpdate() {
        if (!this.state.insertedTaskData && this.props.currentTask && this.props.currentTask.id === Number(this.props.match.params.id)
            && this.props.accountManagers && this.props.accountManagers.length > 0) {
            const accountManager = this.props.accountManagers.find(a => a.azureId === this.props.currentTask.azureId);
            this.setState({
                ...this.props.currentTask,
                serviceOption: Number.parseInt(this.props.currentTask.serviceOption),
                qualityOption: Number.parseInt(this.props.currentTask.qualityOption),
                priceOption: Number.parseInt(this.props.currentTask.priceOption),
                webshopOption: Number.parseInt(this.props.currentTask.webshopOption),
                transportOption: Number.parseInt(this.props.currentTask.transportOption),
                insertedTaskData: true,
                selectedAccountManager: accountManager ? accountManager : null,
                azureIdFromUpdater: getAzureId(),
            });
            this.props.onFetchWeblogVisits(this.props.currentTask.debnr);
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleSliderChange = (name, value) => {
        this.setState({ [name]: value })
    }

    handleDateChange = (date, name) => {
        this.setState({ [name]: date })
    }

    handleCheckboxChange = event => {
        this.setState({ [event.target.name]: event.target.checked });
    }

    handleAutoCompleteChange = (selectedAccountManager) => {
        if (selectedAccountManager) {
            this.setState({
                selectedAccountManager,
                azureId: selectedAccountManager.azureId,
                changedAccountManager: true,
                assignedDate: new Date()
            })
        } else {
            this.setState({
                azureId: '',
                accountManager: '',
                selectedAccountManager: null
            })
        }
    }

    handleSaveTask = () => {
        const task = this.state;
        this.setState({ triedToSubmit: true });
        const options = ['service', 'price', 'quality', 'transport', 'webshop'];
        for (let option of options) {
            const optionValue = task[option + 'Option'];
            const optionReason = task[option + 'Note'];
            if (optionValue === '' || (optionValue === '1' && optionReason === '')) {
                return;
            }
        }
        this.props.onSaveOrderTask({ 
            ...this.state, 
            isAfgehandeld: true,        
            serviceOption: this.state.serviceOption.toString(),
            qualityOption: this.state.qualityOption.toString(),
            priceOption: this.state.priceOption.toString(),
            webshopOption: this.state.webshopOption.toString(),
            transportOption: this.state.transportOption.toString(),
        });
    }

    render() {
        const { t } = this.props;
        const task = this.state;
        console.log(task.isAfgehandeld, this.props.updatedTask)

        const taskForm = <FirstOrderTaskForm
            task={task}
            handleChange={this.handleChange}
            handleSliderChange={this.handleSliderChange}
            handleAutoCompleteChange={this.handleAutoCompleteChange}
            selectedAccountManager={this.state.selectedAccountManager}
            accountManagers={this.props.accountManagers}
            selectedOrderTaskRadio={this.state.selectedOrderTaskRadio}
            handleSave={this.handleSaveTask}
            finishedTask={this.props.updatedTask || task.isAfgehandeld}
            error={this.props.error}
            handleCheckboxChange={this.handleCheckboxChange}
            triedToSubmit={this.state.triedToSubmit}
        />

        let additionalTaskInformation = <TaskDetailTimeLineSkeleton />;
        if (task.taskType === 'BI First Order') {
            additionalTaskInformation = <FirstOrderInformation task={task} />;
        }

        return (
            <FusePageSimple
                header={
                    <TaskDetailHeader title={task.title} debnaam={task.debnaam} phoneNumber={task.phoneNumber} />
                }
                content={
                    <div className="w-full p-12">
                        <FuseAnimateGroup
                            className="flex flex-wrap"
                            enter={{
                                animation: "transition.slideUpBigIn"
                            }}
                        >
                            {this.props.fetchingTaskError ? <Alert severity="error">{this.props.fetchingTaskError}</Alert> : null}
                            <FlexCol className="w-full sm:w-full md:w-1/2 lg:w-1/2 p-12">
                                <div className="w-full">
                                    {/* SHOW SKELETON UNTIL DATA IS LOADED */}
                                    {task.insertedTaskData ?
                                        <>
                                            <Card className="w-full rounded-8 shadow-none border-1">
                                                <AppBar position="static" elevation={0}>
                                                    <Toolbar>
                                                        <Typography>{t("EDIT") + " " + t("TASK")}</Typography>
                                                    </Toolbar>
                                                </AppBar>
                                                <CardContent>
                                                    <div className="p-12">
                                                        {taskForm}
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </>
                                        :
                                        <TaskDetailFormSkeleton />
                                    }
                                    {/* END OF SHOW SKELETON UNTIL DATA IS LOADED */}
                                </div>
                            </FlexCol>
                            <FlexCol className="w-full sm:w-full md:w-1/2 lg:w-1/2 p-12">
                                {
                                    task.insertedTaskData ?
                                        <div className="w-full">
                                            {
                                                this.props.currentTask && additionalTaskInformation
                                            }
                                        </div>
                                        :
                                        <div className="w-full">
                                            <TaskDetailTimeLineSkeleton />
                                        </div>
                                }
                                <div className="w-full mt-20">
                                    {
                                        this.props.currentTask && <TaskDetailWeblog weblogVisits={this.props.weblogVisits} />
                                    }
                                </div>
                            </FlexCol>
                        </FuseAnimateGroup>
                    </div>
                }
            />
        );
    }
};

const mapStateToProps = ({ task, customer }) => ({
    accountManagers: task.accountManagers,
    currentTask: task.currentTask,
    updatedTask: task.updatedOrderTask,
    error: task.error,
    fetchingTaskError: task.fetchingTaskError,
    weblogVisits: customer.weblogVisits,
});

const mapDispatchToProps = (dispatch) => ({
    onFetchAccountManagers: () => dispatch(actions.fetchAccountManagers()),
    onFetchTask: (id, isOrderTask) => dispatch(actions.fetchTask(id, isOrderTask)),
    onSaveOrderTask: (task, afterSaveTask) => dispatch(actions.saveOrderTask(task, afterSaveTask)),
    onFetchTimeLine: (id) => dispatch(actions.fetchTaskTimeLine(id)),
    onFetchWeblogVisits: (debnr) => dispatch(customerActions.fetchCustomerWeblogVisits(debnr)),
});


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderTaskDetail));