import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import {Line} from 'react-chartjs-2';
import _ from 'lodash';
import connect from 'react-redux/es/connect/connect';

const styles = theme => ({
    root: {
        background     : 'linear-gradient(to right, ' + theme.palette.primary.dark + ' 0%, ' + theme.palette.primary.main + ' 100%)',
    }
});

class UserStatsMonthlyPageVisits extends Component {

    render()
    {
        // to give props: data object, dataset (line 92 for exmaple '2019'), headerText, subHeaderText
        const {dataset, data: dataRaw, theme} = this.props;
        const data = _.merge({}, dataRaw);
        if(!dataset || !data.datasets[dataset]) return null;
        const dataWithColors = data.datasets[dataset].map(obj => ({
            ...obj,
            borderColor              : theme.palette.secondary.main,
            backgroundColor          : 'rgba(239, 108, 0, .2)',
            // backgroundColor          : theme.palette.secondary.main,
            pointBackgroundColor     : theme.palette.secondary.dark,
            pointHoverBackgroundColor: '#ff8d2f',
            pointBorderColor         : theme.palette.secondary.main,
            pointHoverBorderColor    : '#ff8d2f'
        }));

        return (
            <Line
                data={{
                    labels  : data.labels,
                    datasets: dataWithColors
                }}
                options={data.options}
            />
        );
    }
}

function mapStateToProps({fuse})
{
    return {
        mainThemeDark: fuse.settings.mainThemeDark
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(UserStatsMonthlyPageVisits));

export const areaData = {
    chartType: 'line',
    datasets : {
        '2019': [{
            label: 'Visits',
            data : [17, 30, 12, 15, 13, 15, 24, 17, 33, 14, 18, 15, 16, 25, 19, 35, 12, 17, 13, 17, 24, 36, 35, 12, 17, 15, 13, 15, 24, 17, 33, 14, 18, 15, 16, 25, 19, 35, 12, 17, 13, 17, 24, 36, 35, 12, 17],
            fill : 'start'
        }]
    },
    labels   : ["March, 11", "March, 12", "March, 13", "March, 14", "March, 15", "March, 16", "March, 17", "March, 18", "March, 19", "March, 20", "March, 21", "March, 22",
     "March, 23", "March, 24", "March, 25", "March, 26", "March, 27", "March, 28", "March, 29", "March, 30", "March, 31", "April, 1", "April, 2", "April, 3","April, 4",
     "April, 5", "April, 6", "April, 7", "April, 8", "April, 9", "April, 10", "April, 11"],
    options  : {
        spanGaps           : false,
        legend             : {
            display: false
        },
        maintainAspectRatio: false,
        layout             : {
            padding: {
                top  : 32,
                left : 32,
                right: 32
            }
        },
        elements           : {
            point: {
                radius          : 4,
                borderWidth     : 2,
                hoverRadius     : 4,
                hoverBorderWidth: 2
            },
            line : {
                tension: 0.4
            }
        },
        scales             : {
            xAxes: [
                {
                    gridLines: {
                        display       : false,
                        drawBorder    : false,
                        tickMarkLength: 18
                    },
                    ticks    : {
                        fontColor: '#ffffff'
                        // fontColor: 'rgba(255, 255, 255, .9)'
                    }
                }
            ],
            yAxes: [
                {
                    display: false,
                    ticks  : {
                        min     : 0,
                        max     : 60,
                        stepSize: 2
                    }
                }
            ]
        },
        plugins            : {
            filler      : {
                propagate: false
            },
            xLabelsOnTop: {
                active: true
            }
        }
    }
}; 