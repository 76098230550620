import React from 'react';
import { useQuery } from 'react-query'
import axios from 'axios';
import ReactTable from "react-table";
import {FuseAnimate, FusePageSimple} from '../../../../../@fuse';
import RouteToDebnr from '../../../../components/Functions/RouteToDebnr';
import {withStyles, Icon, Typography, IconButton, Tooltip, Badge, LinearProgress, FormControlLabel, Switch } from '@material-ui/core';
import moment from 'moment';

const VerzendBevestigingen = () => {
    const useVerzendBevestigingen = useQuery('verzendbevestigingen', 
        async () => {
            const res = await axios.get(
                "hbxdeco/GetVerzendBevestigingen"
            );
            return res.data;
        }, {
            staleTime: 60000
        }
    )
    return (
            <FusePageSimple
            header={
                  <div className="flex flex-1 items-center justify-between p-24">
                     <div className="flex flex-col">
                        <div className="flex items-center mb-16">
                              <Icon className="text-18" color="action">home</Icon>
                              <Icon className="text-16" color="action">chevron_right</Icon>
                              <Typography color="textSecondary">iCRM</Typography>
                              <Icon className="text-16" color="action">chevron_right</Icon>
                              <Typography color="textSecondary">Verzendbevestigingen</Typography>
                        </div>
                        <Typography variant="h6">Verzendbevestigingen</Typography>
                     </div>
                  </div>
            }
            content={!useVerzendBevestigingen.isLoading ?
                <FuseAnimate animation="transition.slideUpIn" delay={300}>
                    <div className="w-full">
                    <ReactTable
                            className="-striped -highlight border-0"
                            data={useVerzendBevestigingen.data}
                            columns={[
                                {
                                    Header    : "Debiteurnummer",
                                    accessor  : "debiteurNummer",
                                    filterable: true,
                                    className : "font-bold justify-center",
                                    width     : 150,
                                    Cell: row => (<RouteToDebnr row={row} />)
                                },
                                {
                                    Header    : "Naam",
                                    accessor  : "debiteurNaam",
                                    filterable: true,
                                    className : "font-bold"
                                },
                                {
                                    Header    : "Email",
                                    accessor  : "email",
                                    filterable: true,
                                    className: "justify-center",
                                },
                                {
                                    Header    : "Verzendbevestiging",
                                    accessor  : "verzendbevestigingDatum",
                                    filterable: true,
                                    className: "justify-center",
                                    Cell: row => row.value ? moment(row.value).format("YYYY-MM-DD [om] HH:mm") : <Icon className="text-16" color="error">clear</Icon>
                                },
                                {
                                    Header    : "Review",
                                    accessor  : "verzendReviewDatum",
                                    filterable: true,
                                    className : "justify-center",
                                    Cell: row => row.value ? moment(row.value).format("YYYY-MM-DD [om] HH:mm") : <Icon className="text-16" color="error">clear</Icon>
                                },
                            ]}
                            pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                            defaultPageSize={20}
                            noDataText="No customers found"
                    /> 
                    </div>
                </FuseAnimate>
            :   <div className="flex h-full">
                    <div className="m-auto">
                        <LinearProgress />
                        <Typography>Gegevens ophalen...</Typography>
                    </div>
                </div> 
            }
            />
    );
};

export default VerzendBevestigingen;