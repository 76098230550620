import * as actionTypes from './actionTypes';
import axios from 'axios'
import {getEmail, getUsername, getUserEmail} from '../../../main/Auth/Auth';
import moment from 'moment';

//Customer Data
export const fetchDbCustomerStart = () => {
    return {
        type: actionTypes.FETCH_CUSTOMER_START,
    };
};

export const fetchDbCustomerSuccess = (dbCustomer) => {
    return {
        type: actionTypes.FETCH_CUSTOMER_SUCCESS,
        customer: dbCustomer
    };
};

export const fetchDbCustomerFail = (error) => {
    return {
        type: actionTypes.FETCH_CUSTOMER_FAIL,
        error
    };
};

export const fetchDbCustomer = (debnr) => {
    return dispatch => {
        dispatch(fetchDbCustomerStart());
        axios.get('customer/getcustomer/' + debnr)
            .then(res => {
                dispatch(fetchDbCustomerSuccess(res.data));
            })
            .catch(error => {
                console.log(JSON.stringify(error))
                dispatch(fetchDbCustomerFail(error.message));
            })
    };
};

// All Customers Data
export const fetchDbAllSearchCustomersSuccess = (allSearchableCustomers) => {
    return {
        type: actionTypes.FETCH_ALL_SEARCHCUSTOMERS_SUCCESS,
        allSearchableCustomers: allSearchableCustomers
    };
};

export const fetchDbAllSearchCustomers = (groupcode) => {
    return dispatch => {
        axios.get('customer/getallcustomerstosearch', {headers: {groupcode}})
            .then(res => {
                dispatch(fetchDbAllSearchCustomersSuccess(res.data));
            })
    };
};

// All Customers Data
export const fetchDbAllCustomersStart = () => {
    return {
        type: actionTypes.FETCH_ALL_CUSTOMERS_START
    };
};
export const fetchDbAllCustomersSuccess = (dbAllCustomers) => {
    return {
        type: actionTypes.FETCH_ALL_CUSTOMERS_SUCCESS,
        allCustomers: dbAllCustomers
    };
};
export const fetchDbAllCustomersFail = (error) => {
    return {
        type: actionTypes.FETCH_ALL_CUSTOMERS_FAIL,
        error
    };
};

export const fetchDbAllCustomers = () => {
    return dispatch => {
        dispatch(fetchDbAllCustomersStart())
        axios.get('customer/getallcustomers')
            .then(res => {
                dispatch(fetchDbAllCustomersSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchDbAllCustomersFail(error.message));
            })
    };
};

// Customer Turnover
export const fetchDbCustomerTurnoverSuccess = (dbTurnover) => {
    return {
        type: actionTypes.FETCH_CUSTOMER_TURNOVER_SUCCESS,
        turnover: dbTurnover
    };
};

export const fetchDbCustomerTurnover = (debnr) => {
    return dispatch => {
        axios.get('customer/getturnover/' + debnr)
            .then(res => {
                dispatch(fetchDbCustomerTurnoverSuccess(res.data));
            })
    };
};

//Customer Departure
export const fetchDbCustomerDepartureSuccess = (dbDeparture) => {
    return {
        type: actionTypes.FETCH_CUSTOMER_DEPARTURE_SUCCESS,
        departure: dbDeparture
    };
};


export const fetchDbCustomerDeparture = (debnr) => {
    return dispatch => {
        axios.get('customer/getdeparturehistory/' + debnr)
            .then(res => {
                dispatch(fetchDbCustomerDepartureSuccess(res.data));
            })
    };
};

//Customer Invoices
export const fetchDbCustomerInvoicesSuccess = (dbInvoices) => {
    return {
        type: actionTypes.FETCH_CUSTOMER_INVOICES_SUCCESS,
        invoices: dbInvoices
    };
};
export const fetchDbCustomerInvoices = (debnr) => {
    return dispatch => {
        axios.get('customer/getinvoices/' + debnr)
            .then(res => {
                dispatch(fetchDbCustomerInvoicesSuccess(res.data));
            })
    };
};

//Customer Todays Turnover
export const fetchDbCustomerTodaysTurnoverSuccess = (dbTodaysTurnover) => {
    return {
        type: actionTypes.FETCH_CUSTOMER_TODAYS_TURNOVER_SUCCESS,
        todaysTurnover: dbTodaysTurnover
    };
};

export const fetchDbCustomerTodaysTurnover = (debnr) => {
    return dispatch => {
        axios.get('customer/gettodaysturnover/' + debnr)
            .then(res => {
                dispatch(fetchDbCustomerTodaysTurnoverSuccess(res.data));
            })
    };
};

//Customer Unpaid
export const fetchDbCustomerUnpaidSuccess = (dbUnpaid) => {
    return {
        type: actionTypes.FETCH_CUSTOMER_UNPAID_SUCCESS,
        unpaid: dbUnpaid
    };
};

export const fetchDbCustomerUnpaid = (debnr) => {
    return dispatch => {
        axios.get('customer/getunpaid/' + debnr)
            .then(res => {
                dispatch(fetchDbCustomerUnpaidSuccess(res.data));
            })
    };
};

//New Customers
export const fetchDbNewCustomersStart = () => {
    return {
        type: actionTypes.FETCH_NEWCUSTOMERS_START,
    };
};
export const fetchDbNewCustomersSuccess = (dbnewCustomers) => {
    return {
        type: actionTypes.FETCH_NEWCUSTOMERS_SUCCESS,
        newCustomers: dbnewCustomers
    };
};

export const fetchDbNewCustomers = () => {
    return dispatch => {
        dispatch(fetchDbNewCustomersStart());
        axios.get('customer/getnewcustomers')
            .then(res => {
                dispatch(fetchDbNewCustomersSuccess(res.data));
            })
    };
};

export const activateNewCustomerSuccess = (isActivated) => {
    return {
        type: actionTypes.ACTIVATE_NEW_CUSTOMER_SUCCESS,
        isActivated
    };
};

export const activateNewCustomer = (debnr, welcomediscount) => {
    const request = {
        params: {
            welcome_discount: welcomediscount
        }
    }
    return dispatch => {
        axios.get(`customer/ActivateNewCustomer/${debnr}`, request)
            .then(res => {
                dispatch(activateNewCustomerSuccess(res.data));
                dispatch(fetchDbNewCustomers());
            })
    };
};

// Weblog
export const fetchCustomerWeblogVisitsSuccess = (weblogVisits) => {
    return {
        type: actionTypes.FETCH_CUSTOMER_WEBLOGVISITS_SUCCESS,
        weblogVisits
    };
};

export const fetchCustomerWeblogVisits = (debnr) => {
    return dispatch => {
        axios.get('customer/GetWeblogVisits/' + debnr)
            .then(res => {
                dispatch(fetchCustomerWeblogVisitsSuccess(res.data));
            })
    };
};

// Enalyzer order data
export const fetchOrderDataForEnalyzerSuccess = (orderData) => {
    return {
        type: actionTypes.FETCH_ORDERDATA_FOR_ENALYZER_SUCCESS,
        orderData
    };
};

export const fetchOrderDataForEnalyzer = (debnr) => {
    return dispatch => {
        axios.get('customer/GetOrderDataForEnalyzer/' + debnr)
            .then(res => {
                dispatch(fetchOrderDataForEnalyzerSuccess(res.data));
            })
    };
};

//Customer Comments
export const fetchDbCustomerCommentsSuccess = (dbComments) => {
    return {
        type: actionTypes.FETCH_CUSTOMER_COMMENTS_SUCCESS,
        comments: dbComments
    };
};

export const fetchDbCustomerComments = (debnr) => {
    return dispatch => {
        axios.get('customer/getcomments/' + debnr)
        .then(res => {
            dispatch(fetchDbCustomerCommentsSuccess(res.data));
        })
    };
};

//Submit Customer Comments
export const submitDbCustomerCommentSuccess = (dbSubmitted) => {
    return {
        type: actionTypes.SUBMIT_CUSTOMER_COMMENT_SUCCESS,
        submitted: dbSubmitted
    };
};

export const submitDbCustomerComment = (debnr, comment) => {
    return dispatch => {
        axios.post('customer/submitcomment/' + debnr, {...comment, debnr: debnr})
        .then(res => {
            dispatch(fetchDbCustomerComments(debnr));
            dispatch(submitDbCustomerCommentSuccess(res.data));
        })
    };
};

//Submit Customer Open or Close
export const submitOpenOrCloseCustomerSuccess = (levStop) => {
    return {
        type: actionTypes.SUBMIT_OPEN_OR_CLOSE_SUCCESS,
        levStop
    };
};

export const submitOpenOrCloseCustomer = (debnr, levStop, shouldUpdateAllInFinancialGroup, finDebnr, email, name) => {
    return dispatch => {
        axios.post(`customer/OpenAndCloseCustomer/${debnr}`,
            {debnr, levStop, shouldUpdateAllInFinancialGroup, finDebnr, email, name}, 
            {headers: {email: getEmail()}})
        .then(res => {
            dispatch(submitOpenOrCloseCustomerSuccess(res.data));
        })
    };
}

// Get New Leads
export const fetchDbNewLeadsSuccess = (dbnewLeads) => {
    return {
        type: actionTypes.FETCH_NEWLEADS_SUCCESS,
        newLeads: dbnewLeads
    };
};

export const fetchMultipleEmailDbNewLeadsSuccess = (dbmultipleemailLeads) => {
    return {
        type: actionTypes.FETCH_MULTIPLE_EMAIL_NEWLEADS_SUCCESS,
        MultipleEmailLeads: dbmultipleemailLeads
    };
};

export const fetchMultipleVatDbNewLeadsSuccess = (dbmultiplevatLeads) => {
    return {
        type: actionTypes.FETCH_MULTIPLE_VAT_NEWLEADS_SUCCESS,
        MultipleVatLeads: dbmultiplevatLeads
    };
};

export const fetchDbNewLeads = (getUserEmail) => {
    return dispatch => {
        // dispatch(fetchDbNewLeadsStart());
        axios.get('customer/getnewleads', getUserEmail)
            .then(res => {
                dispatch(fetchDbNewLeadsSuccess(res.data));
                dispatch(FetchGetNavCountNewleads());
            })
    };
};

export const fetchDbMultipleEmailLeads = (getUserEmail) => {

    return dispatch => {
        // dispatch(fetchDbNewLeadsStart());
        axios.get('customer/GetMultipleEmailLeads?email='+ getUserEmail)
            .then(res => {
                dispatch(fetchMultipleEmailDbNewLeadsSuccess(res.data));
                // dispatch(FetchGetNavCountNewleads());
            })
    };
};

export const fetchDbMultipleVatLeads = (vat) => {
        return dispatch => {
            axios.get('customer/GetMultipleVatLeads?vat='+ vat)
                .then(res => {
                    dispatch(fetchMultipleVatDbNewLeadsSuccess(res.data));
                })
        };
    };

// Edit new lead
export const editNewLeadSuccess = (updatedLead) => {
    return {
        type: actionTypes.EDIT_NEWLEAD_SUCCESS,
        updatedLead
    };
};

const editNewLeadFail = (error) => {
    return {
        type: actionTypes.EDIT_NEWLEAD_FAIL,
        error
    };
};

export const editNewLead = (row) => {
    return dispatch => {
        axios.post('customer/editNewLead', row)
            .then(res => {
                dispatch(editNewLeadSuccess(res.data));
                dispatch(fetchDbNewLeads());
                dispatch(FetchGetNavCountNewleads());
            })
            .catch(error => {
                dispatch(editNewLeadFail(error.message))
            })
    };
};

// on edit remove the success alert bar by updating in the props the var updatedLead
export const setEditedNewLeadFalse = () => {
    return dispatch => {
        dispatch(editNewLeadSuccess(false));
    }
}

// Check Vies VAT new lead
export const checkViesSuccess = (viesData) => {
    return {
        type: actionTypes.CHECK_VIES_SUCCESS,
        viesData
    };
};

export const checkVies = (row, afterCheckVies) => {
    return dispatch => {
        // axios.post('http://localhost:57002/api/apis/checkVies', row)
        // axios.post('https://api.icrm.floral.nu/api/apis/checkVies', row)
        // axios.post('https://api.vanderplas.fr/api/apis/checkVies', row)
         // axios.post('http://localhost:59163/api/checkVies', row)
         axios.post('customer/checkVies', row)
        
            .then(res => {
                afterCheckVies(res.data);
                dispatch(checkViesSuccess(res.data));
                dispatch(fetchDbNewLeads());
            })
    };
};

// export const doubleEmailCheck = (row/*, afterCheckVies*/) => {
//     return dispatch => {
//         axios.get('customer/editNewLead', row)
//             .then(res => {
//                 // afterCheckVies(res.data);
//                 dispatch(checkViesSuccess(res.data));
//                 dispatch(fetchDbNewLeads());
//             })
//     };
// };

// Delete new lead
export const deleteNewLeadSuccess = (isDeleteNewLead) => {
    return {
        type: actionTypes.DELETE_NEWLEAD_SUCCESS,
        isDeleteNewLead
    };
};

export const deleteNewLead = (row, afterDelete, username) => {
    return dispatch => {
        axios.post('customer/deleteNewLead?username=' + username, row)
            .then(res => {
                dispatch(deleteNewLeadSuccess(res.data));
                dispatch(fetchDbNewLeads());
                dispatch(FetchGetNavCountNewleads());
                afterDelete();
            })
    };
};

// set Deleted new lead
export const setDeletedFalse = () => {
    return dispatch => {
        dispatch(deleteNewLeadSuccess(false));
    }
}

// Create new lead
export const createNewLeadSuccess = (isActivated) => {
    return {
        type: actionTypes.CREATE_NEWLEAD_SUCCESS,
        isActivated
    };
};

export const createNewLeadFail = (error) => {
    return {
        type: actionTypes.CREATE_NEWLEAD_FAIL,
        error
    };
};

export const createNewLead = (row) => {
    return dispatch => {
        axios.post('customer/createNewLead', {...row, useremail: getUserEmail()})
            .then(res => {
                dispatch(createNewLeadSuccess(res.data));
                dispatch(fetchDbNewLeads());
                dispatch(FetchGetNavCountNewleads());
            })
            .catch(err => {
                console.log(err.response.data);
                dispatch(createNewLeadFail(err.response.data.error));
            })
    };
};

// Validate new lead
export const validateNewLeadSuccess = (isActivated) => {
    return {
        type: actionTypes.VALIDATE_NEWLEAD_SUCCESS,
        isActivated
    };
};

export const validateNewLead = (row) => {
    return dispatch => {
        axios.post('customer/validateNewLead', {...row, useremail: getUserEmail()})
            .then(res => {
                dispatch(validateNewLeadSuccess(res.data));
                dispatch(fetchDbNewLeads());
                dispatch(FetchGetNavCountNewleads());
            })
    };
};

// Get Templates New Leads
export const FetchdbTemplatesNewLeadsSuccess = (dbTemplatesNewLeads) => {
    return {
        type: actionTypes.FETCH_TEMPLATES_TRANSPORTERS_NEWLEADS_SUCCESS,
        TemplatesNewLeads: dbTemplatesNewLeads
    };
};

export const FetchdbTemplatesNewLeads = (getUserEmail) => {
    return dispatch => {
        axios.post('customer/GetTemplatesNewLeads', getUserEmail)
            .then(res => {
                dispatch(FetchdbTemplatesNewLeadsSuccess(res.data));
            })
    };
};
    
export const FetchDebTypeSuccess = (dbDebType) => {
    return {
        type: actionTypes.FETCH_ALL_DEBTYPE_SUCCESS,
        DebType: dbDebType
    };
};

export const onFetchAllDebType = (getUserEmail) => {
    return dispatch => {
        axios.post('customer/GetAllDebType')
            .then(res => {
                dispatch(FetchDebTypeSuccess(res.data));
            })
    };
};

// Get All Templates New Leads
export const FetchdbAllTemplatesNewLeadsSuccess = (dbAllTemplatesNewLeads) => {
    return {
        type: actionTypes.FETCH_ALL_TEMPLATES_TRANSPORTERS_NEWLEADS_SUCCESS,
        AllTemplatesNewLeads: dbAllTemplatesNewLeads
    };
};

export const FetchdbAllTemplatesNewLeads = (getUserEmail) => {
    return dispatch => {
        axios.post('customer/GetAllTemplatesNewLeads', getUserEmail)
            .then(res => {
                dispatch(FetchdbAllTemplatesNewLeadsSuccess(res.data));
            })
    };
};

// Get Transporters New Leads
// export const FetchdbTransportersNewLeadsSuccess = (dbTransportersNewLeads) => {
//     return {
//         type: actionTypes.FETCH_TRANSPORTERS_NEWLEADS_SUCCESS,
//         TransportersNewLeads: dbTransportersNewLeads
//     };
// };

// export const FetchdbTransportersNewLeads = (getUserEmail) => {
//     return dispatch => {
//         axios.post('customer/GetTransportersNewLeads', getUserEmail)
//             .then(res => {
//                 dispatch(FetchdbTransportersNewLeadsSuccess(res.data));
//             })
//     };
// };

// Delete Fav Users Assign New Leads
export const deleteFaviCRMUserSuccess = (favisDeleted) => {
    return {
        type: actionTypes.DELETE_FAV_USERS_ASSIGNNEWLEADS_SUCCESS,
        favisDeleted
    };
};

export const deleteFaviCRMUser = (row) => {
    return dispatch => {
        axios.post('customer/DeleteFaviCRMUser', row)
            .then(res => {
                dispatch(deleteFaviCRMUserSuccess(res.data));
                dispatch(FetchdbTemplatesNewLeads());
                // dispatch(fetchiCRMusersNewLeads());
            })
    };
};

// Add Fav Users Assign New Leads
export const addFaviCRMUserSuccess = (favisAdded) => {
    return {
        type: actionTypes.ADD_FAV_USERS_ASSIGNNEWLEADS_SUCCESS,
        favisAdded
    };
};

export const addFaviCRMUser = (row) => {
    return dispatch => {
        axios.post('customer/AddFaviCRMUser', row)
            .then(res => {
                dispatch(addFaviCRMUserSuccess(res.data));
                dispatch(FetchdbTemplatesNewLeads());
                // dispatch(fetchiCRMusersNewLeads());
            })
    };
};

// Get Count New Leads Badge
export const FetchGetNavCountNewleadsSuccess = (CountNewleadsBadge) => {
    return {
        type: actionTypes.FETCH_NAV_COUNT_NEWLEADS_SUCCESS,
        CountNewleadsBadge
    };
};

export const FetchGetNavCountNewleads = (getUserEmail) => {
    return dispatch => {
        axios.post('customer/getNavCountNewleads', getUserEmail)
            .then(res => {
                dispatch(FetchGetNavCountNewleadsSuccess(res.data));
            })
    };
};

//Submit Open Route
export const openCustomerRouteSuccess = (success) => {
    return {
        type: actionTypes.SUBMIT_OPEN_CUSTOMER_ROUTE_SUCCESS,
        success
    };
};

export const openCustomerRoute = (debnr, datum, callback) => {
    return dispatch => {
        axios.post(`customer/opencustomerroute`, {debnr, datum, username: getUsername()})
        .then(res => {
            callback();
            dispatch(openCustomerRouteSuccess(res.data));
        })
    };
}

export const fetchNotesInboxSuccess = (notes) => {
    return {
        type: actionTypes.FETCH_NOTES_SUCCESS,
        notes: notes
    };
};

export const fetchNotesInbox = () => {
    return dispatch => {
        axios.get('customer/GetNotesbyUser')
            .then(res => {
                dispatch(fetchNotesInboxSuccess(res.data));
            })
    };
};

export const fetchTopItemsByCustomerStart = () => {
    return {
        type: actionTypes.FETCH_CUSTOMER_TOP_ITEMS_START
    };
};

export const fetchTopItemsByCustomerSuccess = (topItems) => {
    return {
        type: actionTypes.FETCH_CUSTOMER_TOP_ITEMS_SUCCESS,
        topItems
    };
};

export const fetchTopItemsByCustomerFail = () => {
    return {
        type: actionTypes.FETCH_CUSTOMER_TOP_ITEMS_FAIL
    };
};

export const fetchTopItemsByCustomer = (debnr) => {
    return dispatch => {
        dispatch(fetchTopItemsByCustomerStart());
        axios.get('customer/GetTopSoldProductsByCustomer/' + debnr)
            .then(res => {
                dispatch(fetchTopItemsByCustomerSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchTopItemsByCustomerFail());
            })
    };
};

// Download Reservations Excel  
export const downloadReservationExcel = (debnr) => {
    return dispatch => {
        axios.post('customer/downloadReservationExcel?debnr=' + debnr, {}, {responseType: 'blob'})
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Reservations${moment(new Date()).format('YYYY-MM-DD')}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    };
}

export const mailReservationExcel = (body, mailSalesmanOnly) => {
    return dispatch => {
        axios.post('customer/MailReservationExcel?mailSalesmanOnly=' + mailSalesmanOnly, body)
            .then(response => console.log(response.data))
    };
}

// Fetch User Info
export const fetchUserSuccess = (user) => {
    return {
        type: actionTypes.FETCH_USER_SUCCESS,
        user
    };
};

export const fetchUser = () => {
    return dispatch => {
        // dispatch(fetchUserStart());
        axios.get('customer/getuser')
            .then(res => {
                dispatch(fetchUserSuccess(res.data));
                dispatch(FetchGetNavCountNewleads());
            })
    };
};

// Update Password
export const updateUserPasswordSuccess = () => {
    return {
        type: actionTypes.EDIT_USER_PASSWORD_SUCCESS
    };
};

const updateUserPasswordFail = (error) => {
    return {
        type: actionTypes.EDIT_USER_PASSWORD_FAIL,
        error
    };
};

export const updateUserPassword = (row) => {
    return dispatch => {
        axios.post('customer/updateUserPassword', row)
            .then(res => {
                dispatch(updateUserPasswordSuccess(res.data));
                // dispatch(fetchDbNewLeads());
                // dispatch(FetchGetNavCountNewleads());
            })
            .catch(error => {
                dispatch(updateUserPasswordFail(error.message))
            })
    };
};