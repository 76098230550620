import React from 'react';
import {Paper} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

const dashboardBlockSkeleton = ({classToApply}) => (
    <Paper className={classToApply + " w-full p-16 text-center rounded-8 shadow-none border-1"} elevation={1}>
            <Skeleton variant="text" width={'40%'} height={35} style={{margin: '0 auto'}} />
            <Skeleton variant="text" width={'100%'} height={25} style={{margin: '0 auto'}} />
    </Paper>
);

export default dashboardBlockSkeleton;
