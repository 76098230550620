import * as actionTypes from './actionTypes';
import axios from 'axios';
import moment from 'moment';

//Apis Data
export const fetchApisSuccess = (dbApis) => {
    return {
        type: actionTypes.FETCH_APIS_SUCCESS,
        apis: dbApis
    };
};

export const fetchApis = () => {
    let url = 'getapis';
    return dispatch => {
        axios.get(url)
            .then(res => {
                dispatch(fetchApisSuccess(res.data));
            })
    };
};

// DEXX CBS EXPORT

const generateDexxCBSExportStart = () => {
    return {
        type: actionTypes.GENERATE_DEXX_CBS_EXPORT_START
    };
};

const generateDebnrDexxCBSExportStart = () => {
    return {
        type: actionTypes.GENERATE_DEBNR_DEXX_CBS_EXPORT_START
    };
};

const generateDexxCBSExportSuccess = (generateDexxCBSExport) => {
    return {
        type: actionTypes.GENERATE_DEXX_CBS_EXPORT_SUCCESS,
        generateDexxCBSExport
    };
};

const generateDebnrDexxCBSExportSuccess = (generateDebnrDexxCBSExport) => {
    return {
        type: actionTypes.GENERATE_DEBNR_DEXX_CBS_EXPORT_SUCCESS,
        generateDebnrDexxCBSExport
    };
};

const generateDexxCBSExportFail = (error) => {
    return {
        type: actionTypes.GENERATE_DEXX_CBS_EXPORT_FAIL,
        error
    };
};

const generateDebnrDexxCBSExportFail = (errorDebnr) => {
    return {
        type: actionTypes.GENERATE_DEBNR_DEXX_CBS_EXPORT_FAIL,
        errorDebnr
    };
};

export const generateDexxCBSExport = (fromdate, todate, filterCompany) => {
    let url = 'GenerateDexxCBSExport?fromdate=' + fromdate + '&todate=' + todate+ '&company=' + filterCompany;
    return dispatch => {
        dispatch(generateDexxCBSExportStart())
        axios.get(url)
            .then(generateDexxCBSExport => {
                dispatch(generateDexxCBSExportSuccess(generateDexxCBSExport.data));
            })
            .catch(error => {
                dispatch(generateDexxCBSExportFail(error.message))
            })
    };
};

// Download Excel DEXX CBS EXPORT
export const downloadExcelDexxCBSExport = (fromdate, todate, filterCompany) => {
    return dispatch => {
        dispatch(generateDexxCBSExportStart())
        axios.get('DownloadExcelDexxCBSExport?fromdate=' + fromdate + '&todate=' + todate+ '&company=' + filterCompany, {responseType: 'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `DEXX-CBS-Export-${filterCompany}-${moment(new Date()).format('YYYY-MM-DD')}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
                // Update loader status
                dispatch(generateDexxCBSExportSuccess(res.data));
            })
            .catch(error => {
                dispatch(generateDexxCBSExportFail(error.message))
            })
    };
}

export const DownloadExcelDexxCBSExportExpedition = (fromdate, todate, debnr) => {
    return dispatch => {
        dispatch(generateDebnrDexxCBSExportStart())
        axios.get('DownloadExcelDexxCBSExportExpedition?fromdate=' + fromdate + '&todate=' + todate+ '&debnr=' + debnr, {responseType: 'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `DEXX-CBS-Export-${debnr}-${moment(new Date()).format('YYYY-MM-DD')}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
                // Update loader status
                dispatch(generateDebnrDexxCBSExportSuccess(res.data));
            })
            .catch(error => {
                dispatch(generateDebnrDexxCBSExportFail(error.message))
            })
    };
}
// DEXX C&C EXPORT

const generateDexxCnCExportStart = () => {
    return {
        type: actionTypes.GENERATE_DEXX_CnC_EXPORT_START
    };
};
const generateDexxCnCExportSuccess = (generateDexxCnCExport) => {
    return {
        type: actionTypes.GENERATE_DEXX_CnC_EXPORT_SUCCESS,
        generateDexxCnCExport
    };
};
const generateDexxCnCExportFail = (error) => {
    return {
        type: actionTypes.GENERATE_DEXX_CnC_EXPORT_FAIL,
        error
    };
};
export const generateDexxCnCExport = (fromdate, todate) => {
    let url = 'GenerateDexxCnCExport?fromdate=' + fromdate + '&todate=' + todate;
    return dispatch => {
        dispatch(generateDexxCnCExportStart())
        axios.get(url)
            .then(generateDexxCnCExport => {
                dispatch(generateDexxCnCExportSuccess(generateDexxCnCExport.data));
            })
            .catch(error => {
                dispatch(generateDexxCnCExportFail(error.message))
            })
    };
};

// Download Excel DEXX C&C EXPORT
export const downloadExcelDexxCnCExport = (data) => {
    return dispatch => {
        console.log(data)
        axios.post('DownloadExcelDexxCnCExport', data, {responseType: 'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `DEXX-CnC-Export-${moment(new Date()).format('YYYY-MM-DD')}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    };
}

// STOCK EXPORT

const generateStockExportStart = () => {
    return {
        type: actionTypes.GENERATE_STOCK_EXPORT_START
    };
};
const generateStockExportSuccess = (generateStockExport) => {
    return {
        type: actionTypes.GENERATE_STOCK_EXPORT_SUCCESS,
        generateStockExport
    };
};
const generateStockExportFail = (error) => {
    return {
        type: actionTypes.GENERATE_STOCK_EXPORT_FAIL,
        error
    };
};
export const generateStockExport = (filterCompany) => {
    let url = 'GenerateStockExport?company=' + filterCompany;
    return dispatch => {
        dispatch(generateStockExportStart())
        axios.get(url)
            .then(generateStockExport => {
                dispatch(generateStockExportSuccess(generateStockExport.data));
            })
            .catch(error => {
                dispatch(generateStockExportFail(error.message))
            })
    };
};

// Download Excel STOCK EXPORT
export const downloadExcelStockExport = (data, filterCompany) => {
    return dispatch => {
        console.log(data)
        axios.post('DownloadExcelStockExport', {data, company : filterCompany}, {responseType: 'blob'})
            .then(res => {
                // const url = window.URL.createObjectURL(new Blob([res.data]));
                // const link = document.createElement('a');
                // link.href = url;
                // link.setAttribute('download', `Stock-Export-${moment(new Date()).format('YYYY-MM-DD')}.xlsx`); //or any other extension
                // document.body.appendChild(link);
                // link.click();
                console.log("Sent by Email -> Ok");
            })
    };
}

// DEXX C&C MODULE

const getDexxCnCModuleVerkoopersStart = () => {
    return {
        type: actionTypes.GENERATE_DEXX_CnC_MODULE_START
    };
};
const getDexxCnCModuleVerkoopersSuccess = (getDexxCnCModuleVerkoopers) => {
    return {
        type: actionTypes.GENERATE_DEXX_CnC_MODULE_SUCCESS,
        getDexxCnCModuleVerkoopers
    };
};
const getDexxCnCModuleVerkoopersFail = (error) => {
    return {
        type: actionTypes.GENERATE_DEXX_CnC_MODULE_FAIL,
        error
    };
};
export const getDexxCnCModuleVerkoopers = (AzureId) => {
    let url = 'DexxCnCModuleGetVerkoopers?AzureId='+ AzureId;
    return dispatch => {
        dispatch(getDexxCnCModuleVerkoopersStart())
        axios.get(url)
            .then(getDexxCnCModuleVerkoopers => {
                dispatch(getDexxCnCModuleVerkoopersSuccess(getDexxCnCModuleVerkoopers.data));
            })
            .catch(error => {
                dispatch(getDexxCnCModuleVerkoopersFail(error.message))
            })
    };
};

// Download QUADRA EXPORT
export const downloadExcelDexxCnCModule = (data) => {
        return dispatch => {
            // console.log(data)
            axios.post('downloadQuadraExport', data, {responseType: 'blob'})
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `DEXX-CnC-Module-${moment(new Date()).format('YYYY-MM-DD')}.xlsx`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                })
        };
    }

// DEXX C&C MODULE Update IKLANT
export const dexxCnCModuleUpdateIKLANTSuccess = (updatediklant) => {
    return {
        type: actionTypes.DEXX_CnC_UPDATE_IKLANT_SUCCESS,
        updatediklant
    };
};

const dexxCnCModuleUpdateIKLANTFail = (error) => {
    return {
        type: actionTypes.DEXX_CnC_UPDATE_IKLANT_FAIL,
        error
    };
};

export const dexxCnCModuleUpdateIKLANT = (debnr, verkooper) => {
    return dispatch => {
        axios.post('DexxCnCModuleUpdateIKLANT?debnr='+ debnr +'&verkooper=' + verkooper)
            .then(res => {
                dispatch(dexxCnCModuleUpdateIKLANTSuccess(res.data));
            })
            .catch(error => {
                dispatch(dexxCnCModuleUpdateIKLANTFail(error.message))
            })
    };
};

// GENERATE QUADRA EXPORT

const generateQuadraExportPreviewStart = () => {
    return {
        type: actionTypes.GENERATE_QUADRA_EXPORT_START
    };
};
const generateQuadraExportPreviewSuccess = (generateQuadraExport) => {
    return {
        type: actionTypes.GENERATE_QUADRA_EXPORT_SUCCESS,
        generateQuadraExport
    };
};
const generateQuadraExportPreviewFail = (error) => {
    return {
        type: actionTypes.GENERATE_QUADRA_EXPORT_FAIL,
        error
    };
};
export const generateQuadraExportPreview = (filterCompany, fromdate, todate) => {
    let url = 'quadra/preview?finadmin=' + filterCompany + '&beginDatum=' + fromdate + '&eindDatum=' + todate;
    // let url = 'https://api.vanderplas.fr/api/quadra/preview?finadmin=' + filterCompany + '&beginDatum=' + fromdate + '&eindDatum=' + todate;
    return dispatch => {
        dispatch(generateQuadraExportPreviewStart())
        axios.get(url)
            .then(generateQuadraExport => {
                dispatch(generateQuadraExportPreviewSuccess(generateQuadraExport.data));
            })
            .catch(error => {
                dispatch(generateQuadraExportPreviewFail(error.message))
            })
    };
};

// GENERATE QUADRA EXPORT FAULTS

const generateQuadraExportFaultsStart = () => {
    return {
        type: actionTypes.GENERATE_QUADRA_EXPORT_FAULTS_START
    };
};
const generateQuadraExportFaultsSuccess = (generateQuadraExportFaults) => {
    return {
        type: actionTypes.GENERATE_QUADRA_EXPORT_FAULTS_SUCCESS,
        generateQuadraExportFaults
    };
};
const generateQuadraExportFaultsFail = (error) => {
    return {
        type: actionTypes.GENERATE_QUADRA_EXPORT_FAULTS_FAIL,
        error
    };
};
export const generateQuadraExportFaults = (filterCompany, fromdate, todate) => {
    let url = 'quadra/faults?finadmin=' + filterCompany + '&fromdate=' + moment(fromdate).format('YYYY-MM-DD') + '&enddate=' + moment(todate).format('YYYY-MM-DD');
    return dispatch => {
        dispatch(generateQuadraExportFaultsStart())
        axios.get(url)
            .then(generateQuadraExportFaults => {
                dispatch(generateQuadraExportFaultsSuccess(generateQuadraExportFaults.data));
            })
            .catch(error => {
                dispatch(generateQuadraExportFaultsFail(error.message))
            })
    };
};

// Download QUADRA EXPORT
export const downloadQuadraExportVentes = (filterCompany, fromdate, todate) => {
    // let url = 'https://api.vanderplas.fr/api/quadra?finadmin=' + filterCompany + '&beginDatum=' + fromdate + '&eindDatum=' + todate;
    let url = 'quadra/download-sales?finadmin=' + filterCompany + '&beginDatum=' + fromdate + '&eindDatum=' + todate;
    return dispatch => {
        axios.get(url, {responseType: 'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Quadra-Export-Ventes-${moment(new Date()).format('YYYY-MM-DD')}.ascii`); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    };
}
export const downloadQuadraExportPaiements = (filterCompany, fromdate, todate) => {
    // let url = 'https://api.vanderplas.fr/api/quadra/payment?finadmin=' + filterCompany + '&beginDatum=' + fromdate + '&eindDatum=' + todate;
    let url = 'quadra/download-payments?finadmin=' + filterCompany + '&beginDatum=' + fromdate + '&eindDatum=' + todate;
    return dispatch => {
        axios.get(url, {responseType: 'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Quadra-Export-Paiements-${moment(new Date()).format('YYYY-MM-DD')}.ascii`); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    };
}

// AD HOC EXPORT

const generateAdHocExportStart = () => {
    return {
        type: actionTypes.GENERATE_ADHOC_EXPORT_START
    };
};
const generateAdHocExportSuccess = (generateAdHocExport) => {
    return {
        type: actionTypes.GENERATE_ADHOC_EXPORT_SUCCESS,
        generateAdHocExport
    };
};
const generateAdHocExportFail = (error) => {
    return {
        type: actionTypes.GENERATE_ADHOC_EXPORT_FAIL,
        error
    };
};
export const generateAdHocExport = (fromdate, todate) => {
    let url = 'GenerateAdHocExport?fromdate=' + fromdate + '&todate=' + todate;
    return dispatch => {
        dispatch(generateAdHocExportStart())
        axios.get(url)
            .then(generateAdHocExport => {
                dispatch(generateAdHocExportSuccess(generateAdHocExport.data));
                // finishedLoading();
            })
            .catch(error => {
                dispatch(generateAdHocExportFail(error.message))
            })
    };
};

// Download Excel AD HOC EXPORT
export const downloadExcelAdHoc = (data) => {
    return dispatch => {
        console.log(data)
        axios.post('DownloadExcelAdHoc', data, {responseType: 'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Import_fatture.xls`); //or any other extension
                // link.setAttribute('download', `AdHoc-Export-${moment(new Date()).format('YYYY-MM-DD')}.xls`); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    };
}

// Download Excel Flower Place
export const downloadFlowerPlaceExportTable = () => {
    let url = 'DownloadFlowerPlaceExportTable';
    return dispatch => {
        // axios.get(url)
        axios.post('DownloadFlowerPlaceExportTable', null, {responseType: 'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Flower-Place-Export-${moment(new Date()).format('YYYY-MM-DD')}.csv`); //or any other extension
                // link.setAttribute('download', `AdHoc-Export-${moment(new Date()).format('YYYY-MM-DD')}.xls`); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    };
}

// JDF EXPORT

const generateJDFexportStart = () => {
    return {
        type: actionTypes.GENERATE_JDF_EXPORT_START
    };
};
const generateJDFexportSuccess = (generateJDFexport) => {
    return {
        type: actionTypes.GENERATE_JDF_EXPORT_SUCCESS,
        generateJDFexport
    };
};
const generateJDFexportFail = (error) => {
    return {
        type: actionTypes.GENERATE_JDF_EXPORT_FAIL,
        error
    };
};
export const generateJDFexport = (fromdate, todate) => {
    let url = 'ExportJDF?fromdate=' + fromdate + '&todate=' + todate;
    return dispatch => {
        dispatch(generateJDFexportStart())
        axios.get(url)
            .then(generateJDFexport => {
                dispatch(generateJDFexportSuccess(generateJDFexport.data));
            })
            .catch(error => {
                dispatch(generateJDFexportFail(error.message))
            })
    };
};

export const downloadExcelJDFexport = (fromdate, todate, type) => {
    let url = 'ExportJDF/download?fromdate=' + fromdate + '&todate=' + todate + '&type=' + type;
    return dispatch => {
        axios.get(url, {responseType: 'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${type} MF Export JDF ${fromdate}.txt`); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    };
}
// Download Callcenter

export const downloadCallcenter = (name) => {
    let url = 'download/callcenter?type=' + name;
    return dispatch => {
        axios.get(url)
            .then(res => {
                console.log(name);
            })
    };
};

// Send Callcenter

export const sendCallcenter = (type) => {
    let url = 'send/callcenter?type=' + type;
    return dispatch => {
        axios.get(url)
            .then(res => {
                console.log(type);
            })
    };
};

export const sendDailyOmkSuccess = (res) => {
    return {
        type: actionTypes.SEND_DAILY_OMK_SUCCESS,
        res
    }
}

export const sendDailyOmk = () => {
    return dispatch => {
        axios.get('http://my.vdplas.com/src/api/senddailyexcelomk.php')
            .then(res => {
                dispatch(sendDailyOmkSuccess(res));
            })
    }
}