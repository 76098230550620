import React, { Component } from "react";
import { Typography, Card,  CardContent, Toolbar, AppBar, Paper, Icon } from '@material-ui/core';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@material-ui/lab';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const DefaultEventPaper = ({event, eventInfo}) => (    
    <Paper elevation={1} style={{padding: '6px 16px'}}>
        <span className="text-16 font-semibold">{event.fromName}</span>
        <Typography className="text-14">{event.message}{event.eventType !== 'Task viewed' && event.toName}</Typography>
    </Paper>
)

const CommentEventPaper = ({event, eventInfo}) => (    
    <Paper variant="outlined"  style={{padding: '6px 16px', backgroundColor: '#3C4252', color: '#fff', border: '1px solid rgb(103 113 139)'}}>
        <span className="text-16 font-semibold">{event.fromName}</span> <span> schreef:</span>
        <Typography className="text-14">{event.message}</Typography>
    </Paper>
)

class TaskDetailTimeLine extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.timeLine.length !== this.props.timeLine.length;
    }

    getTimelineIconByEventType = (eventType) => {
        switch(eventType) {
            case 'Assignment': 
                return { color: 'inherit', variant: 'outlined', icon: 'assignment_ind' }
            case 'Comment':
                return { color: 'primary', variant: 'default', icon: 'comment' }
            case 'Action':
                return { color: 'inherit', variant: 'default', icon: 'check_box' }
            case 'Task update':
                return { color: 'inherit', variant: 'default', icon: 'edit' }
            default: 
                return { color: 'inherit', variant: 'outlined', icon: 'assignment_ind' }
        }
    }

    render() {
        const { timeLine, t } = this.props;

        return (
            this.props.timeLine.length > 0 ? 
            <Card className="w-full rounded-8 shadow-none border-1">
                <AppBar position="static" elevation={0}>
                    <Toolbar>
                        <Typography>{t("TIMELINE")}</Typography>
                    </Toolbar>
                </AppBar>
                <CardContent>
                    <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={600}>
                        <React.Fragment>
                            <Timeline align="alternate">
                                {timeLine.map(event => {
                                    const eventInfo = this.getTimelineIconByEventType(event.eventType);
                                    return (
                                        <TimelineItem key={event.eventDate + event.eventType}>
                                            <TimelineOppositeContent>
                                                <Typography variant="body2" color="textSecondary">
                                                    {/* {moment(event.eventDate).format("YYYY-MM-DD h:mmA")} */}
                                                    {moment(event.eventDate).format("MMMM Do, h:mm A")}
                                                </Typography>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineDot color={eventInfo.color} variant={eventInfo.variant}>
                                                    <Icon color="inherit" style={{color: eventInfo.color === 'inherit' ? 'inherit' : '#fff'}}>
                                                        {eventInfo.icon}
                                                    </Icon>
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                {
                                                    eventInfo.icon === 'comment' ? <CommentEventPaper event={event} eventInfo={eventInfo} /> : <DefaultEventPaper event={event} eventInfo={eventInfo} /> 
                                                }
                                                {/* <Paper variant={eventInfo.icon === 'comment' ? 'outlined' : 'elevation'} elevation={1} style={{padding: '6px 16px'}}>
                                                    <span className="text-16 font-semibold">{event.fromName}</span>
                                                    <Typography className="text-14">{event.message}{event.eventType !== 'Task viewed' && event.toName}</Typography>
                                                </Paper> */}
                                            </TimelineContent>
                                        </TimelineItem>
                                    )
                                })}
                            </Timeline>
                        </React.Fragment>
                    </Scrollbars>
                </CardContent>
            </Card>
            :
            <Card className="w-full rounded-8 shadow-none border-1">
            <AppBar position="static" elevation={0}>
                <Toolbar>
                    <Typography>{t("TIMELINE")}</Typography>
                </Toolbar>
            </AppBar>
            <CardContent>
                <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={600}>
                    <React.Fragment>
                        <br /><br />
                        <p className="text-center"><i>{t("NOINTERACTIONYET")}</i></p>
                    </React.Fragment>
                </Scrollbars>
            </CardContent>
            </Card>
        );
    }
};

export default withTranslation()(TaskDetailTimeLine);