import React, { Component } from 'react';
import { TextField } from '@material-ui/core';

export default class TaskDetailTextField extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.value !== this.props.value || nextProps.error !== this.props.error || nextProps.disabled !== this.props.disabled
        || nextProps.InputProps !== this.props.InputProps || nextProps.label !== this.props.label;
    }

    render() {
        return (        
            <TextField
                {...this.props}
            />
        );
    }
}
