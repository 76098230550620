import React, {Component} from 'react';
import ReactTable from "react-table";
import {FuseAnimateGroup, FusePageSimple} from '../../../../../@fuse';
import {Icon, Typography, Tooltip, IconButton, Badge, MenuItem, Select, AppBar, Toolbar} from '@material-ui/core';
import * as actions from '../../../../store/crm/actions';
import * as hbxDecoActions from '../../../../store/hbxdeco/actions';
import { connect } from 'react-redux';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import RouteToDebnr from '../../../../components/Functions/RouteToDebnr';
import './Turnover.css';
import {withTranslation} from 'react-i18next';
import _ from 'lodash';
import { getCompany } from '../../../Auth/Auth';

class Turnover extends Component {

    constructor(props) {
        super(props);
        this.reactTable = React.createRef();
        this.state = {
            selectedFinancieleGroep: "Alle groepen",
        }
    }

    componentDidMount() {
        const isHbxDeco = getCompany() === 4;
        if(isHbxDeco) {
            // this.props.onFetchTurnover();
            this.props.onFetchHbxDecoTurnover();
            this.props.onFetchHbxDecoFinancieleGroepen();
        } else {
            this.props.onFetchTurnover();
        }
    }

    downloadExcel = () => {
        const {page, pageSize} = this.reactTable.state;
        const data = this.reactTable.getResolvedState().sortedData;
        const columns = this.reactTable.state.columns
                .filter(col => col.accessor !== 'debnr' &&  col.accessor !== 'total' &&  col.accessor !== 'average' &&  col.accessor !== 'orders' &&  col.accessor !== 'debnaam')
                .map(col => col.accessor);
        const startIndex = page * pageSize;
        const turnoverData = data.slice(startIndex, startIndex + pageSize).map(({debnr, total, average, orders, ...rest}) => {
            rest = _.omit(rest, ['_original', '_index', '_subRows', '_nestingLevel', 'debnaam']);
            return {
                debnr,
                total,
                average,
                orders,
                weeks: {...rest}
            }
        });
        const dataToSend = {
            accessors: columns,
            turnovers: turnoverData,
        }

        this.props.onDownloadTurnover(dataToSend);
    }

    downloadHbxDecoExcel = () => {
        const {page, pageSize} = this.reactTable.state;
        const data = this.reactTable.getResolvedState().sortedData;
        const columns = this.reactTable.state.columns
                .filter(col => col.accessor !== 'debnr' &&  col.accessor !== 'total' &&  col.accessor !== 'totalInvoiced' &&  col.accessor !== 'totalReserved'
                 &&  col.accessor !== 'debnaam' && col.accessor !== 'totalInvoicedLastYear' && col.accessor !== 'finGroepText')
                .map(col => col.accessor);
        const startIndex = page * pageSize;
        const turnoverData = data.slice(startIndex, startIndex + pageSize).map(({debnr, total, totalInvoiced, totalReserved, ...rest}) => {
            rest = _.omit(rest, ['_original', '_index', '_subRows', '_nestingLevel', 'debnaam', 'totalInvoicedLastYear', 'finGroepText']);
            return {
                debnr,
                total,
                totalInvoiced,
                totalReserved,
                months: {...rest}
            }
        });
        const dataToSend = {
            accessors: columns,
            turnovers: turnoverData,
        }

        this.props.onDownloadHbxDecoTurnover(dataToSend);
    }

    handleFinancieleGroepChange = (event) => {
        this.setState({ selectedFinancieleGroep: event.target.value });
        console.log(event.target.value);
    }

    render()
    {
        const isHbxDeco = getCompany() === 4;
        let toRender = <Spinner />
        const {turnover, hbxDecoTurnover,  t} = this.props;
        const { selectedFinancieleGroep } = this.state;
        
        let headers = null;
        let tableData = null;
        let reservedTableData = null;

        if(isHbxDeco) {
            if(hbxDecoTurnover) {
                let turnoverData = hbxDecoTurnover.turnover;
                if (selectedFinancieleGroep !== "Alle groepen") {
                    turnoverData = hbxDecoTurnover.turnover.filter(t => t.finGroep === selectedFinancieleGroep.trim());
                }
                headers = hbxDecoTurnover.months.map(m => ({
                    Header    : "Month " + m.monthNumber,
                    accessor  : "month." + m.monthNumber,
                    filterable: false,
                    Cell: row => (
                        row.value === undefined || row.value === 0 ? <Icon className="text-18" color="error">clear</Icon> :  <span>&euro; {row.value}</span>
                    ),
                    width: 100
                }));
    
                tableData = turnoverData.map(t => {
                    const monthData = {};
                    t.rows.map(row => monthData[row.monthNumber] = row.total);
                    return {
                        debnr: t.debnr,
                        debnaam: t.debnaam,
                        finGroepText: t.finGroepText,
                        total: t.total,
                        totalInvoicedLastYear: t.totalInvoicedLastYear,
                        totalInvoiced: t.totalInvoiced,
                        totalReserved: t.totalReserved,
                        month: {...monthData}
                    }
                });
    
                reservedTableData = turnoverData.map(t => {
                    const monthData = {};
                    t.reservedRows.map(row => monthData[row.monthNumber] = row.total);
                    return {
                        debnr: t.debnr,
                        debnaam: t.debnaam,
                        finGroepText: t.finGroepText,
                        total: t.total,
                        totalInvoicedLastYear: t.totalInvoicedLastYear,
                        totalInvoiced: t.totalInvoiced,
                        totalReserved: t.totalReserved,
                        month: {...monthData}
                    }
                });
                if(tableData.length > 0) {
                    tableData.unshift(tableData.pop());
                }
                if(reservedTableData.length > 0) {
                    reservedTableData.unshift(reservedTableData.pop());
                }
            }
        } else {
            if(turnover && Object.keys(turnover).length > 0) {
                headers = turnover.weeks.map(w => {
                    return {
                        Header    : "Week " + w.weekNum,
                        accessor  : "week." + w.weekNum + w.weekYear,
                        filterable: false,
                        Cell: row => (
                            row.value === undefined ? <Icon className="text-18" color="error">clear</Icon> :  <span>&euro; {row.value}</span>
                        ),
                        width: 100
                    }
                });
    
                tableData = turnover.turnover.map(t => {
                    const weekData = {};
                    t.rows.map(row => weekData[row.weekNumber+ "" +row.weekYear] = row.total);
                    return {
                        debnr: t.debnr,
                        debnaam: t.debnaam,
                        total: t.total,
                        average: t.average,
                        orders: t.orders,
                        week: {...weekData}
                    }
                });
    
                tableData.unshift(tableData.pop());
            }
        }

        if(headers && tableData) {
            let columns = [
                {
                    Header    : "Debnr",
                    accessor  : "debnr",
                    filterable: true,
                    className : "font-bold text-center",
                    width: 100,
                    Cell: row => (<RouteToDebnr row={row} />),
                },
                {
                    Header    : t('total'),
                    accessor  : "total",
                    filterable: true,
                    width: 100,
                    Cell: row => <>&euro; {row.value.toFixed(0)}</>
                },
                {
                    Header    : "Average",
                    accessor  : "average",
                    filterable: true,
                    width: 100,
                    Cell: row => <>&euro; {row.value.toFixed(0)}</>
                },
                {
                    Header    : "Orders",
                    accessor  : "orders",
                    filterable: true,
                    width: 100,
                    Cell: row => row.value && <>{row.value.toFixed(0)}</>
                },
                ...headers,
            ];

            let reservedColumns = [];
            if(isHbxDeco) {
                columns = [
                    {
                        Header    : "Debnr",
                        accessor  : "debnr",
                        filterable: true,
                        className : "font-bold text-center",
                        width: 100,
                        Cell: row => (<RouteToDebnr row={row} />),
                    },
                    {
                        Header    : "Name",
                        accessor  : "debnaam",
                        filterable: true,
                        className : "text-center",
                        width: 200,
                    },
                    {
                        Header    : "Doelgroep",
                        accessor  : "finGroepText",
                        filterable: true,
                        className : "text-center",
                        width: 150,
                    },
                    {
                        Header    : t('LASTYR'),
                        accessor  : "totalInvoicedLastYear",
                        filterable: true,
                        width: 100,
                        Cell: row => <>&euro; {row.value > 0 ? row.value.toFixed(0) : 0}</>
                    },
                    {
                        Header    : isHbxDeco ? "Omzet" : "Average",
                        accessor  : isHbxDeco ? "totalInvoiced" : "average",
                        filterable: true,
                        width: 100,
                        Cell: row => <>&euro; {row.value.toFixed(0)}</>
                    },
                    ...headers,
                ]

                reservedColumns = [
                    {
                        Header    : "Debnr",
                        accessor  : "debnr",
                        filterable: true,
                        className : "font-bold text-center",
                        width: 100,
                        Cell: row => (<RouteToDebnr row={row} />),
                    },
                    {
                        Header    : "Name",
                        accessor  : "debnaam",
                        filterable: true,
                        className : "text-center",
                        width: 200,
                    },
                    {
                        Header    : "Doelgroep",
                        accessor  : "finGroepText",
                        filterable: true,
                        className : "text-center",
                        width: 150,
                    },
                    {
                        Header    : "Geschreven",
                        accessor  : "totalReserved",
                        filterable: true,
                        width: 100,
                        Cell: row => row.value && <>&euro; {row.value.toFixed(0)}</>
                    },
                    ...headers,
                ]
            }
            toRender = (
                <FuseAnimateGroup animation="transition.slideUpIn" delay={300}>
                    <div className="w-full">
                        <ReactTable 
                            ref={(r) => (this.reactTable = r)}
                            style={{overflow: 'hidden'}}
                            className="-striped -highlight border-0"
                            data={tableData}
                            columns={columns}
                            pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                            defaultPageSize={10}
                            noDataText="No customers found"
                        /> 
                    </div>
                    {isHbxDeco && 
                        <div className="w-full">
                            <AppBar position="static">
                                <Toolbar>
                                    <Typography variant="h6">
                                        Geschreven orders
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <ReactTable 
                                style={{overflow: 'hidden'}}
                                className="-striped -highlight border-0"
                                data={reservedTableData}
                                columns={reservedColumns}
                                pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                                defaultPageSize={10}
                                noDataText="No customers found"
                            /> 
                        </div>
                    }
                </FuseAnimateGroup>);
        }

        return (
            <FusePageSimple
            header={
                <div className="flex flex-1 items-center justify-between p-24">
                    <div className="flex flex-col">
                        <div className="flex items-center mb-16">
                            <Icon className="text-18" color="action">home</Icon>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">iCRM</Typography>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">{t('TURNOVER')}</Typography>
                        </div>
                        <Typography variant="h6">{isHbxDeco ? 'Gerealiseerde omzet' : t('TURNOVER')}</Typography>
                    </div>
                    <div className="flex flex-1 items-center justify-end px-12">
                        <Tooltip title="Generate & download excel from the filtered table">
                            <span>
                                <IconButton 
                                    disabled={(turnover.length === 0 || !turnover) && (!hbxDecoTurnover || hbxDecoTurnover.length === 0)}
                                    onClick={() => {
                                        if(isHbxDeco) {
                                            this.downloadHbxDecoExcel();
                                        } else {
                                            this.downloadExcel();
                                        }
                                    }}
                                    // onClick={() => window.location.href = process.env.REACT_APP_APIURL + `unpaid/downloadunpaid?groupCode=${groupCode}&conn=${conn}`}
                                >
                                    <Badge color="secondary" variant="dot">
                                        <Icon>save_alt</Icon>
                                    </Badge>
                                </IconButton>
                            </span>
                        </Tooltip>
                        {this.props.financieleGroepen &&
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.selectedFinancieleGroep}
                                onChange={this.handleFinancieleGroepChange}
                            >
                                {this.props.financieleGroepen.map(groep => (
                                    <MenuItem key={groep.text} value={groep.text}>{groep.text}</MenuItem>
                                ))}
                            </Select>
                        }
                    </div>
                </div>
            }
            content={toRender}
            />
        )
    }
}

const mapStateToProps = ({turnover, hbxdeco}) => {
    return {
        turnover: turnover.turnover,
        hbxDecoTurnover: hbxdeco.turnover,
        financieleGroepen: hbxdeco.financieleGroepen
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchTurnover: () => dispatch(actions.fetchDbTurnover()),
        onFetchHbxDecoTurnover: () => dispatch(hbxDecoActions.fetchHbxDecoTurnover()),
        onFetchHbxDecoFinancieleGroepen: () => dispatch(hbxDecoActions.fetchHbxDecoFinancieleGroepen()),
        onDownloadTurnover: (data) => dispatch(actions.downloadTurnover(data)),
        onDownloadHbxDecoTurnover: (data) => dispatch(hbxDecoActions.downloadHbxDecoTurnover(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Turnover));