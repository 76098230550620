const fr = {
    translations: {
        // Algemeen
        "tos_pp": "Conditions d'utilisation et politique de confidentialité",
        "tos_pp_detail": "iCRM collecte des données telles que les visites de pages afin d'améliorer l'expérience des utilisateurs",
        "login_privacy_acknowledgement": "En vous connectant, vous acceptez nos",
        "user_agreement": "conditions d'utilisation",
        "privacy_statement": "politique de confidentialité",
        "and_the": "et notre",
        "agree": "D'accord",
        "disagree": "Rejeter",
        "login": "Se connecter",
        "logout": "Déconnexion",
        "login_to_your_account": "Connectez-vous à votre compte",
        "email": "Email",
        "password": "Mot de passe",
        "confirm_password": "Confirmer le mot de passe",
        "reset_password": "Réinitialiser le mot de passe",
        "recover_your_password": "Récupérer votre mot de passe",
        "send_reset_link": "envoyer le lien de réinitialisation",
        "back_to_login": "Retour à la connexion",
        "update_your_password": "Mettre à jour votre mot de passe",
        "update_your_account": "Mettre à jour votre compte",
        "update_my_account": "Mettre à jour mon compte",
        "why_update_account": "Pourquoi dois-je mettre à jour mon compte ?",
        "why_update_account_detail": "L'iCRM met en place un nouveau système de connexion pour accorder l'accès aux utilisateurs à distance. Veuillez remplir le formulaire ci-dessous pour préparer votre compte au nouveau système de connexion.",
        "password_reset_successful": "Réinitialisation du mot de passe réussie ! Veuillez vérifier votre courrier pour définir un nouveau mot de passe",
        "min_charachter_length": "La longueur minimale des caractères est",
        "enter_valid_email": "Veuillez entrer une adresse électronique valide",
        "something_went_wrong": "Quelque chose s'est mal passé.",
        "please_try_again": "s'il vous plaît, essayez à nouveau",
        "or": "ou",
        "contact_us": "Contactez-nous",
        "wrong_credentials": "Mot de passe incorrect. Veuillez réessayer ou réinitialiser votre mot de passe.",
        "conclude": "clôturer",
        "conclude_explanation": "Signalez que la tâche est terminée. Le formulaire sera sauvegardé automatiquement.",
        "concluded": "Conclu",
        "archive": "Archives",
        "show_assigned_tasks": "Tâches que vous avez assignées à d'autres personnes",
        "reopen": "Réouvrir",
        "false_alarm": "Pas de problèmes",
        "false_alarm_explanation": "Cette tâche a été générée à partir des données de la BI. Si vous soupçonnez ou si le client vous indique que tout va bien, veuillez signaler cette tâche comme une 'pas de problèmes'. Nous pourrons ainsi continuer à améliorer le système des tâches.",
        "is_required": "est obligatoire",
        "concerning": "concernant",
        "UNKONWN": "inconnu",        
        "user_application_form": "formulaire de demande d'utilisation",

        "hide": "cacher",

        "COPY": "Copier",
        "YEAR-1": "Année -1",
        "internet_explorer_warning": "Vous visualisez iCRM dans Internet Explorer. Certaines fonctionnalités d'iCRM peuvent ne pas fonctionner comme prévu sur Internet Explorer. Veuillez envisager de passer à un autre navigateur comme Google Chrome, Microsoft Edge ou Mozilla Firefox.",
        "turnover_same_period": "Turnover in the same months as this year",
        "PAGE_STATS": "Stats pages",
        "USER_STATS": "Stats utilisateurs",
        "VISITS_PER_USER": "Visites par utilisateur",

        "task_already_concluded": "Cette tâche est déjà terminée. Rouvrez cette tâche pour procéder à la modification.",
        "days_since_first_order": "Nombre de jours depuis la première commande",
        "days_since_code_created": "Nombre de jours depuis la création du code",
        "amount_webshop_visits": "Nombre de visites de la boutique en ligne",
        "latest_webshop_visit": "Dernière visite de la boutique en ligne",
        "no_filter": "Aucun filtre",
        "radio_filter_tooltip": "Filtrer l'indication choisie par la couleur du cercle",

        // BI Info
        "reasons": "Raisons",
        "ander_klantnummer": "Autre numéro de client",
        "geen_klachten": "Aucune plainte",
        "kwaliteit": "Qualité",
        "prijs": "Prix",
        "rustig_in_de_winkel": "Calme dans le magasin",
        "service": "Service",
        "transport": "Transport",
        "quality": "Qualité",
        "webshop": "Boutique en ligne",
        "webshop_te_moeilijk": "Le webshop est trop difficile",
        "winkel_permanent_gesloten": "Magasin fermé en permanence",
        "winkel_tijdelijk_gesloten": "Le magasin est temporairement fermé",
        "geen_reden": "Pas de raison",
        "anders,_namelijk...": "Autre, à savoir...",
        "namely": "à savoir...",
        "closed_until": "Fermé jusqu'à",
        
        "notes": "Commentaires",
        "checked_more_actions_than_allowed": "Il n'est pas permis de sélectionner plus de 3 options en même temps.",
        "number_of_days_customer": "Nombre de jours client",
        "number_of_days_customer_explanation": "Le nombre de jours depuis que le client a passé sa première commande (au 01-01-2017)",
        "total_deliveries": "Nombre total de livraisons",
        "total_deliveries_explanation": "Le nombre total de livraisons à ce client",
        "days_since_last_order": "Nombre de jours depuis la dernière commande",
        "days_since_last_order_explanation": "Combien de jours se sont écoulés depuis la dernière commande de ce client",
        "weeks_regular_customer": "Nombre de semaines client régulier",
        "weeks_regular_customer_explanation": "Combien de semaines d'affilée nous considérons ce client comme un client régulier",
        "weeks_without_orders": "Nombre de semaines sans commande durant 365 jours",
        "weeks_without_orders_explanation": "Cela montre s'il est courant qu'un client ne commande pas chez nous pendant une semaine ou plusieurs semaines",
        "max_days_without_order": "Nombre maximum de jours sans commande au cours des 365 derniers jours",
        "max_days_without_order_explanation": "Ceci indique s'il y a eu une longue période au cours des 365 dernières années où le client n'a pas commandé chez nous",
        "no_indication_selected_warning": "Sélectionnez une indication pour utiliser le filtre de couleur",
        "indication": "Indication",

        "latest_import": "Dernière importation",
        "latest_invoice": "Dernière facture",

        "fetching_live_customer_data": "Récupération des données clients en temps réel",

        "total": "Total",
        "last_4_weeks": "Les 4 dernières semaines",
        "last_52_weeks": "Les 52 dernières semaines",
        "differences_alltime_last10weeks_explanation": "Nous montrons ici les différences entre le comportement général de commande du client, et son comportement de commande au cours des 10 dernières semaines",
        "days_between_orders": "Nombre de jours entre les commandes",
        "days_between_orders_explanation": "Si ce chiffre est plus élevé au cours des 10 dernières semaines, le client commande moins régulièrement que d'habitude",
        "avg_invoice_amount": "Montant moyen des factures",
        "avg_invoice_amount_explanation": "Si ce montant est inférieur au cours des 10 dernières semaines, le client commande pour un montant inférieur à ce qu'il fait normalement",
        "avg_articlegroups_per_order": "Nombre moyen de groupes d'articles par commande",
        "avg_articlegroups_per_order_explanation": "Si ce chiffre est inférieur au cours des 10 dernières semaines, le client commande en moyenne moins de groupes d'articles que ce qu'il fait normalement",
        "amount_of_credit_lines": "Nombre de lignes de crédit",
        "amount_of_credit_lines_explanation": "Il indique le nombre de lignes de crédit que le client a présentées au cours des dix dernières semaines",
        "credit_sum": "La somme du crédit",
        "credit_sum_explanation": "Il s'agit de la somme totale des crédits pour la période totale et les 10 dernières semaines",
        

        "relative_changes_in_last_10_weeks": "Changements relatifs au cours des 10 dernières semaines",
        "relative_changes_in_last_10_weeks_explanation": "Nous comparons ici les 10 dernières semaines où nous comparons la première moitié (il y a 10-5 semaines) avec la deuxième moitié (il y a 5 semaines à présent)",
        "relative_number_of_days_customer": "Nombre de commandes",
        "relative_number_of_days_customer_explanation": "Une diminution indique que le nombre de commandes a diminué au cours des 5 dernières semaines par rapport aux 5 semaines précédentes",
        "relative_total_deliveries": "Hauteur moyenne de la facture",
        "relative_total_deliveries_explanation": "Avec un pourcentage positif, la hauteur moyenne des factures des 5 dernières semaines est plus élevée que celle des 5 semaines précédentes",
        "relative_weeks_regular_customer": "Nombre moyen de groupes d'articles par commande",
        "relative_weeks_regular_customer_explanation": "En cas de pourcentage positif, le nombre de groupes d'articles par commande au cours des 5 dernières semaines est plus élevé que dans les 5 semaines précédentes",

		// Monitor translations
        "own_stats_button": "Examinez votre utilisation de l'iCRM.",
        "VISITED_LAST_MONTH": "{{amountOfPageVisits}} pages visitées le mois dernier",
        "VISITED_LAST_MONTH_OWN_STATS": "Vous avez visité {{amountOfPageVisits}} pages le mois dernier",
        "TITLECRMUSE": "Mieux comprendre l'utilisation d'iCRM",
        "SESSION_PER_DEVICE": "Sessions par appareil",
        "WHEN_ARE_USERS_ACTIVE": "Quand les utilisateurs sont-ils actifs et que regardent-ils principalement",
        "WHEN_ARE_USERS_ACTIVE_OWN_STATS": "Quand êtes-vous actif, et quelles pages consultez-vous principalement ?",
        "AVERAGE_VISITS_PER_HOUR": "Nombre moyen de visites par heure",
        "LIST_OF_SEARCHED_CUSTOMERS": "L'utilisateur a recherché ces clients",
        "LIST_OF_SEARCHED_CUSTOMERS_OWN_STATS": "Vous avez récemment recherché ces clients",
        "LIST_OF_CLICKED_CUSTOMERS": "L'utilisateur a cliqué sur ces clients",
        "LIST_OF_CLICKED_CUSTOMERS_OWN_STATS": "Vous avez récemment cliqué sur ces clients",
        "AMOUNT_VISITS": "Nombre de visites",
        "AVERAGE_TIME_PAGE": "Temps moyen sur la page",
        "NO_SEARCHED_FOUND": "Aucun terme de recherche trouvé",
        // Comparison blocks
        "tasks_concluded_user": "de tâches en plus par rapport à la moyenne",
        "tasks_concluded_user_negative": "de tâches en moins que la moyenne",
        "tasks_concluded_avg": "tâches achevées",
        "task_concluded_speed_user": "plus rapide que la moyenne dans l'accomplissement des tâches",
        "task_concluded_speed_user_negative": "des tâches effectuées moins rapidement que la moyenne",
        "task_concluded_speed_avg": "jours",
        "notes_left_user": "de plus de notes de clients postées que la moyenne",
        "notes_left_user_negative": "moins de notes de clients affichées que la moyenne",
        "notes_left_avg": "notes de clients placées",
        "leads_exported_user": "de prospects exportées en plus que la moyenne",
        "leads_exported_user_negative": "moins de prospects exportées que la moyenne",
        "leads_exported_avg": "prospects exportées",
        "leads_created_user": "de prospects créés en plus que la moyenne",
        "leads_created_user_negative": "moins de prospects créées que la moyenne",
        "leads_created_avg": "prospects créés",
        "pages_visited_user": "de pages visitées en plus que la moyenne",
        "pages_visited_user_negative": "moins de pages visitées que la moyenne",
        "pages_visited_avg": "pages visitées",
        "leads_validated_speed_user": "Traitement des prospects plus rapide que la moyenne",
        "leads_validated_speed_user_negative": "les pistes sont traitées moins rapidement que la moyenne",
        "leads_validated_speed_avg": "jours",
        "average": "Moyenne",
        "avg_comparison_title": "Comparer l'utilisation moyenne du CRM",
        "avg_comparison_title_own_stats": "Comment utilisez-vous iCRM par rapport aux autres utilisateurs ?",
		// block 1
        "PAGE_VISITED": "Pages visitées",
        "OPENED_CUSTOMERS": "Clients débloqués",
        "CLOSED_CUSTOMERS": "Clients fermés",
        "GENERATED_INCASSO": "Prélèvements générés",
        "EMAILED_OPENPOST": "Impayés envoyé",
        "OPENED_DEPARTURE_DAYS": "Jours de départ ouvert",
        "SEARCHED_CUSTOMERS": "Clients recherchés",
        "AVG_TIME_PER_PAGE": "Temps moy. par page",
		// Block 2
        "TASKS_DONE": "Tâches terminées",
        "OPEN_TASKS": "Tâches en attente",
        "TASK_CREATION_COMPLETION": "Jours entre la création et l'achèvement de la tâche",
        "NOTES_WRITTEN": "Commentaires écrit",
        "SENT_SPECIAL_OFFERS": "Offres spéciales envoyées",
        "NEWLEADS_CREATED": "Nouveaux proespects créer",
        "NEWLEADS_VALIDATED": "Nouveaux proespects validés",
        "NEWLEADS_EXPORTED": "Nouveaux prospects exportés",
        "DAYS_BET_NEWLEADS_CREATED_EXPORTED": "Jours entre la création et l'export",
        "USER_ACTIVITY_PER_HOUR": "Activité par heure",
		
        // Minor BI Data translations
        "last_year": "Année passée",
        "last_month": "Dernier mois",        
        "last_year_last_month_explanation": "Nous montrons ici les différences entre le comportement général de commande du client et son comportement de commande au cours du mois écoulé",
        "amount_of_orders": "Nombre de commandes",
        "avg_invoice_turnover": "Hauteur moyenne des factures",
        "avg_amount_of_article_groups_per_order": "Nombre moyen de groupes d'articles par commande",
        "credit_ratio": "Ratio de crédit",
        "credit_ratio_explanation": "Le ratio de crédit est le pourcentage des dépenses créditées par le client",
        "avg_orders_per_week": "Nombre moyen de commandes par semaine",
        "risk_of_leaving": "Risque de départ",
        "customer_since": "Client depuis",

        "invoice_amount": "Hauteur de la facture",
        "amount_article_groups": "Nombre de groupes d'articles",
        "amount_articles": "Nombre d'articles",
        "amount_stems": "Nombre de tiges",
        "invoice_paid": "Facture payée",

        "open_expired_tasks": "Veuillez noter qu'il y a des tâches expirées qui n'ont pas encore été achevées.",
        "go_to_tasks": "Retour aux tâches",

        "good": "Bon",
        "mediocre": "Modéré",
        "bad": "Mauvais",
        "existing_customer": "Client existant",

        // Specifiek
        "CREATED_TASK_ERROR": 'Quelque chose a mal tourné. Le numéro de client est-il correct ?',
        "NOINTERACTIONYET": "Cette tâche n'a pas encore eu d'interactions",
        "OVERDUE": "En retard",
        "ACTIONSTODOBI": "Actions",
        "ACTIONSTODOBI_TOOLTIP": "Laissez ce champs vide si aucune action de suivi n'est requise",
        "ACTIONSTODOBI_TABLE_TOOLTIP": "Actions à entreprendre",
        "CALLOVERVIEWBI": "Résumé de votre conversation avec le client",
        "BIINFORMATIONS": "Données clients de BI",
        "TIMELINE": "Historique des interactions",
        "CHOOSE": "Choisissez",
        "FILLIN": "Saisissez",
        "TITLE": "Titre",
        "TASKUPDATEDSUCCESS": "La tâche a été mise à jour avec succès",
        "TASKCONCLUDEDSUCCESS": "La tâche a été clôturer avec succès",
        "TASKREOPENEDSUCCESS": "La tâche a été rouverte avec succès",
        "TASKCREATEDSUCCESS": "La tâche a été créer avec succès. Vous allez être redirigé",
        "TASK": "Tâche",
        "TASKS": "Tâches",
        "CREATENEWTASK": "Créer une nouvelle tâche",
        "TYPE": "Type",
        "NO_TYPE": "Pas de type",
        "REPONDREEMAIL": "Répondre par Email",
        "CALLBACK": "Rappeller",
        "URGENCY": "Urgent",
        "OBJECTIVE": "Objectif",
        "OPTIONS": "Options",
        "MOREOPTIONS": "Plus d'options",
        "ACTIONS_CHECKLIST": "Liste de contrôle des actions",
        "ADD_ACTION": "Ajouter l'action",
        "FILL_NEW_ACTIONS": "Ajouter une nouvelle action",

        "financial_groups_distribution_title": "Répartition du chiffre d'affaires entre les groupes financiers",
        "financial_groups_distribution_hbxdeco_title": "Répartition du chiffre d'affaires entre les groupes financiers",
        "margin_distribution_title": "distribution des marges aux groupes cibles",

        "ADD": "Ajouter",
        "USER": "Utilisateur",
        "EMAIL": "Email",
        "NAME": "Nom",
        "ORDERS": "Commandes",
        "HAVEORDERED": "Ont commandés",
        "VISITE": "Visite",
        "LOADMORE": "Charger plus",
        "DAYS": "Jours",
        "CREATED": "Créé",
        "EXPORTED": "Exporté",
        "CREATE": "Créer",
        "CUSTOMERS": "Clients",
        "ADDRESS": "Adresse",
        "CITY": "Ville",
        "ZIPCODE": "Code postal",
        "COUNTRY": "Pays",
        "SHOP": "Magasin",
        "OWNER": "Gérant",
        "PHONE": "Tél",
        "MOBILE": "Portable",
        "VAT": "TVA",
        "DOESTHECUSTOMERHAVEAVATNUMBER": "Ce client a-t-il un numéro de TVA ?",
        "KVK": "SIREN",
        "TRANSPORTER": "Transporteur",
        "PASSWORD": "Mot de passe",
        "LOGTIME": "Connexion",
        "KOPEN": "Dernier achat",
        "DIFFERENCE": "Différence",
        "REFRESH": "Actualiser",
        "NEW": "Nouveau",
        "OLD": "ancien / ancien",
        "WEEK": "Semaine",
        "BLOCK1": "Clients créé jusqu'il y a 3 jours",
        "BLOCK2": "Dernière facture datant de -5 à -7 jours ouvrés",
        "BLOCK3": "Dernière facture datant de -10 à -14 jours ouvrés",
        "BLOCK4": "Dernière facture datant de -20 à -30 jours ouvrés",
        "BLOCK5": "Client pas atteint leur minimum",
        "BLOCK6": "Clients avec leur premiere commande",
        "TOOLTIP": "Ce bloque montre les clients où les ",
        "NEWNLOSTCUSTOMERS": "Nombre de clients nouveau et perdu",
        "ACTIVECUSTOMERS": "Clients actif",
        "ACTIVECUSTOMERSTOOLTIP": "Nous comptons le nombre de client différent",
        "QTYORDERS": "Quantité commandes",
        "QTYORDERSTOOLTIP": "Nous comptons toutes les factures positive",
        "NOTIFICATIONS": "Notifications",
        "EXECUTE": "Exécuter",
        "LASTDATE": "Date de fin",
        "DESCRIPTION": "Description",
        "AMOUNT": "Montant",
        "CUMULATED": "Cumulé",
        "VALIDATE": "Valider",
        "VALIDATED": "Validé",
        "PRELEVEMENT": "Prélèvement",
        "INVOICED": "Facturé",
        "CREDIT": "Avoir",
        "DATE": "Date",
        "LOADCAMPAIGNS": "Charger campaigne d'email",
        "DEPARTURE": "Départ",
        "TODAY": "Aujourd'hui",
        "TURNOVERPERCATEGORIES": "Répartition du chiffre d'affaire",
        "WEEKLYORDERFREQUENCY": "Fréquence d'achat par semaine",
        "WEEKLYTURNOVER": "Chiffre d'affaire par semaine",
        "STARTDATE": "Du",
        "ENDDATE": "Jusqu'au",
        "COUNTINVOICES": "Amount of invoices",
        "OPENSTAAND": "Semaine impayée",
        "AVGORDERGROOTTE": "CA moyen",
        "DYNAMICSEARCHTEXT": "Sur les champs dynamique vous pouvez utiliser des symboles tel que > (suprieur à), < (infrieur à). Ces champs sont reconnaissable avec un contour bleue foncé.",
        "DYNAMICSEARCH": "Ce champs est dynamique",
        "LAST90DAYS": "Les derniers 90 jours",
        "LAST60DAYS": "Les derniers 60 jours",
        "LAST30DAYS": "Les derniers 30 jours",
        "YESTERDAY": "Hier",
        "THISWK": "Cette semaine",
        "THISMT": "Ce mois",
        "THISYR": "Cette année",
        "LASTWK": "Semaine dernière",
        "LASTMT": "Mois dernier",
        "LASTYR": "L'année dernière",
        "DEFAULTDT": "Par défaut",
        "WKPERORD": "Semaine par commande",
        "APPLY": "Appliquer",
        "CHANGEPASS": "Changer mot de passe",
        "MONDAY": "L",
        "TUESDAY": "M",
        "WEDNESDAY": "M",
        "THURSDAY": "J",
        "FRIDAY": "V",
        "INVOICES": "Factures",
        "CALL": "Appel",
        "CALLLIST": "Liste d'appels",
        "INBOX": "Messagerie",
        "VISIT": "Passage",
        "DELIVERY": "Livraison",
        "FILLING": "Remplir",
        "ORDERFREQ": "Fréquence de d'achat",
        "STELEN": "Nombre de tiges",
        "PLANTS": "Plantes",
        "FLOWERS": "Fleurs",
        "OTHERS": "Autre",
        "AMNTCLIENTACTIVE": "Clients actifs",
        "WEBLOG": "(Nombre de visite par jour)",
        "BOUGHT": "Acheté",
        "STOCK": "Stock",
        "ORDERLIST": "Listing de commande",
        "WEBLOG30DAYS": "Nombre de page visité les 30 dernier jours",
        "TURNOVER": "Chiffre d'affaire",
        "PREVIOUSDEPARTUREDAYS": "Historique jours de départ",
        "INVOICEHISTORY": "Historique des factures",
        "INVOICE": "Facture",
        "PAID": "Payé",
        "DEPARTUREHISTORY": "Historique des départs",
        "MAXDAY": "Jours max",
        "LASTINVOICE": "Dernière  facture",
        "CLOSED": "Fermé",
        "CUSTOMERSTOVALIDATE": "Clients à valider",
        "SEND": "Envoyer",
        "SAVE": "Enregistrer",
        "PREVIEW": "Prévisualiser",
        "DOWNLOAD": "Télécharger",
        "CANCEL": "Annuler",
        "NEXT": "Suivant",
        "DELETE": "Supprimer",
        "LOGIN": "Se connecter",
        "PASSWORDFORGOTTEN": "Mot de passe oublié",
        "EDIT": "Modifier",
        "RETURN": "Renvoyer",
        "HOME": "Accueil",
        "CUSTOMER": "Client",
        "USERS": "Utilisateurs",
        "RAPPORT": "Les notes",
        "NEWCUSTOMERS": "Nouveaux clients",
        "OVERVIEW": "Sommaire",
        "UNPAID": "Impayés",
        "HEADER": "Navigation",
        "DASHBOARD": "Dashboard",
        "CALENDAR": "Calendrier",
        "USERACCESS": "Accès utilisateur",
        "UNPAIDINVOICES": "Factures impayées",
        "SPECIALOFFER": "Offre spéciales",
        "SPECIALOFFERS": "Offres spéciales",
        "MANAGE": "Gérer",
        "VISITORS": "Visiteurs",
        "VISITORSBYDAY": "Visiteurs par jour pour le mois dernier",
        "USERNAME": "Nom d'utilisateur",
        "MESSAGE": "Message",
        "ADDMESSAGE": "Ajouter un commentaire",
        "COMPANY": "Société",
        "SETTINGS": "Paramètres",
        "PAYTERM": "Délai de paiement",
        "FINANCIELTYPE": "Type financier",
        "COMMERCIALTYPE": "Type commercial",
        "DOWNLOADBACKORDERS": "Télécharger récap commandes",
        "show_backorders": "Afficher la liste des commandes en attente",
        "BUYLIMIT": "Limite d'achat",
        "CREDITLIMIT": "Limite de credit",
        "OPEN": "Ouvrir",
        "CLOSE": "Fermer",
        "NOCOMMENTS": "Auncun commentaire",
        "SEARCH": "Chercher",
        "GLOBALSEARCH": "Rechercher tout...",
        "ASSIGNNEWLEADS": "Attribuer nouveaux prospects",
        "NEWLEAD": "Nouveau prospect",
        "PROFILE": "Profil",
        "CHOOSELANGUAGE": "Choississez votre langue",
        "CHANGEPASSWORD": "Modifier votre mots de passe",
        "RESETFORM": "Réinitialiser formulaire",
        "ASSIGNED": "Attribué",
        "LEADS": "Prospects",
        "ADDTEMPLATESTOYOURLIST": "Ajoutez des templates a votre liste de favoris",
        "NEWLEADARCHIVEINFO": "Lors de l'archivage d'un nouveau prospect, il ne sera pas importé dans Florisoft",
        "DELETEDNEWLEADSUCCESS": "Le nouveau prospect a bien était supprimé",
        "ALREADYEXPORTERNEWLEAD": "Le nouveau prospect a déjà était exporté",
        "NEWCUSTOMERSDIALOGINFO": "Afin de valider ce client, tous les champs ci-dessus doivent etre remplit dans Florisoft",
        "WELCOME_DISCOUNT": "Remise de bienvenue",
        "MULTIPLEEMAILS": "Plusieurs e-mails trouvés pour ce client",
        "MULTIPLEVAT": "Plusieurs clients avec ce numéro de TVA ont été trouvés",
        "NEWLEADALREADYVALIDATED": "Ce prospect a déjà était validé",
        "NEWLEADVIESFIRSTCREATENEWLEAD": "Vérifiez les données auprès du VIES pour voir si ces données correspondent avec celle de votre client",
        "WEBSHOPACCESS": "Accés Webshop",
        "UPDATEDSUCCESS": "Modifié avec succès",
        "UPDATEDFAILED": "Une erreur s'est produite, veuillez nous contacter",
        "CREATEDSUCCESS": "Créer avec succès",
        "YOURFAVTEMPLATES": "Votre liste de templates favoris",
        "ACCOUNTMNGR": "Commercial",
        "NOTE": "Commentaire",
        "BY": "par",
        "AREYOUSURE": "êtes-vous sûr ?",
        "YES": "oui",
        "NO": "non",
        "CHECK": "Vérifier",
        "SHOPNAMEFORINVOICE": "Nom du magasin pour la facturation",
        "OPENROUTE": "Jour de départ supplémentaire ",
        "MOSTBOUGHTPRODUCTS": "Les 10 produits les plus achetés",
        "PRODUCT": "Produit",
        "TIMESBOUGHT": "Nombre d'achats",
        "TOTALSTEMS": "Nombre de tiges",
        "AVGPRICE": "Prix moyen",
        "CREDITVERDELING": "Répartition des crédits sur les motifs de crédit",
        "SHOWLEGEND": "Afficher la légende",
        "HIDELEGEND": "Cacher la légende",
        "WEBSHOP_LANGUAGE": "fr-fr",
        "MULTIPLEDATALEAD": "E-mail en double",
        "MULTIPLEVATDATALEAD": "Dupliquer la TVA", 
        "DEBTEXTFIELD"  : "Note client",
        "DEBTEXTPLACEHOLDER" : "Instructions de livraison, etc",        
        "creation_date": "Date de création",
        "CON_SUCCESS": "Connexion réussie",
        "CON_UNSUCCESS": "Connexion échouée !",
        "LOGIN_HISTORY": "Historique de connexion",
        "REGION": "Région",
        "FILTER": "Filtre",
        "FILTER_UNSUCCESSSFUL": "Afficher seulement les échecs de connexions",
        "TOO_MANY_WRONG_PASSWORDS_ACCOUNT_LOCKED": "Trop de mots de passe incorrect, le compte est verrouillé pendant 5 minutes",
        "LOGIN_ATTEMPTS": "Tentatives de connexion",
        "VISITS": "Visites",
        "DEBFINGRP" : "Type de client"
    }
};
export default fr;