import React from 'react';
import { Icon, Typography, Tooltip } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

const TableHeaderCell = props => <TableCell style={{color: '#ffffff'}} {...props}>{props.children}</TableCell>

const TableInformationRow = (props) => (
    <TableRow>
        <TableCell>
            {/* {t('TURNOVER')} */}
            {props.title}
        </TableCell>
        <TableCell align="right" style={{minWidth: 90}}>{typeof(props.amount) === 'boolean' ? <Icon style={{color: props.amount ? 'green' : 'red'}}>{props.amount ? 'check' : 'clear'}</Icon> : props.amount}</TableCell>
        <TableCell align="right" style={{minWidth: 150}}>
            {!props.hidePercentile && <React.Fragment>
                {props.percentile === '' ? <Icon>clear</Icon> : <span>Top {props.percentile}%</span>}
            </React.Fragment>
            }
        </TableCell>
    </TableRow>
)

const FirstOrderInformation = ({task}) => {
    const { t } = useTranslation();
    return ( 
        <TableContainer component={Paper} style={{borderRadius: '.8rem'}}>
            <Table>
                <TableHead style={{backgroundColor: '#3C4252', height: 64}}>
                    <TableRow style={{backgroundColor: '#3C4252', height: 64}}>
                        <TableHeaderCell colSpan={3}><Typography>{t("BIINFORMATIONS")}</Typography></TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableInformationRow title={t('invoice_amount')} amount={task.salesAmount} percentile={task.salesPercentile} />
                    <TableInformationRow title={t('amount_article_groups')} amount={task.articleGroupQuantity} percentile={task.articleGroupPercentile} />
                    <TableInformationRow title={t('amount_articles')} amount={task.articleQuantity} percentile={task.articlePercentile} />
                    <TableInformationRow title={t('amount_stems')} amount={task.stemsQuantity} percentile={task.stemsPercentile} />
                    <TableInformationRow title={t('CREDIT')} amount={task.creditAmount} hidePercentile percentile={task.stemsPercentile} />
                    <TableInformationRow title={t('invoice_paid')} amount={task.betaald} hidePercentile />
                </TableBody>
            </Table>
        </TableContainer> );
}
 
export default FirstOrderInformation;