import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { StylesProvider, createGenerateClassName, jssPreset } from "@material-ui/styles";
import {FuseAuthorization, FuseLayout, FuseTheme} from '../@fuse';
import Provider from 'react-redux/es/components/Provider';
import {Router} from 'react-router-dom';
import {create} from 'jss';
import jssExtend from "jss-plugin-extend";
import moment from 'moment';
import {historyBrowser} from '../historyexport';
import store from './store';
import AppContext from './AppContext';
import routes, { authRoutes } from './fuse-configs/routesConfig';
import * as actions from "../app/store/auth/actions";
import { createUserLog } from '../app/store/crm/admin/actions';
import { 
    QueryClient, 
    QueryClientProvider, 
  } from 'react-query'

import {getAccount, getAzureId, getRefreshToken} from './main/Auth/Auth';
import {hotjar} from 'react-hotjar';
import '../api/interceptor';
import AuthLayout from './main/Auth/AuthLayout';

const jss = create({
    ...jssPreset(),
    plugins: [...jssPreset().plugins, jssExtend()],
});

jss.options.insertionPoint = document.getElementById('jss-insertion-point');
const generateClassName = createGenerateClassName();
const queryClient = new QueryClient();
hotjar.initialize(1310192, 6);

let unlisten = null;
let lastPage = null;
let lastDateTime = new Date();

class App extends Component {
    componentDidMount() {
        // historyBrowser.listen((location, action) => {
        //     console.log(location, action)
        // })
        if(localStorage.getItem('i18n') === null) {
            localStorage.setItem('i18n', 'en');
        }
        const user = getAccount();
        if(!this.props.location.pathname.includes('/update-password')) {
            if(!user && getRefreshToken()) {
                console.log('refreshing token...');
                this.props.onRefreshToken(this.afterRefreshTokenError);
            } else if (user && user.azureId) {
                this.fillUserData(user.azureId);
                this.props.onFetchAccessableGroupcodes();
            } else {
                historyBrowser.push('/login');
            }
        }

        lastPage = this.props.location.pathname;
        unlisten = historyBrowser.listen(({ pathname }) => {
            if(lastPage !== '/login' && !lastPage.includes('/update-password')){
                const timeOnPage = moment(new Date()).diff(lastDateTime, 'seconds');
                const userLog = {
                    azureId: getAzureId(),
                    type: 'page_visit',
                    actionName: pathname,
                    fromPage: lastPage,
                    timeOnPage,
                    startDate: lastDateTime,
                    endDate: new Date()
                };
                this.props.onCreateUserLog(userLog);
                lastPage = pathname;
            }
        })
    }

    componentWillUnmount() {
        unlisten?.();
    }

    afterRefreshTokenError = () => {
        historyBrowser.push('/login');
    }

    fillUserData = (azureId) => {
        this.props.onFetchUserById(azureId);
    }
    
    render() {

        let definiteRoutes = authRoutes;
        let definiteChild = <FuseTheme>
            <AuthLayout />
        </FuseTheme>

        if(Object.keys(this.props.user).length !== 0 && this.props.user.id > 0) {
            definiteRoutes = routes;
            definiteChild = (<FuseAuthorization>
                <FuseTheme>
                    <FuseLayout/>
                </FuseTheme>
            </FuseAuthorization>);
        }

        return (
            <AppContext.Provider
                value={{
                    routes: definiteRoutes,
                }}
            >
                <StylesProvider jss={jss} generateClassName={generateClassName}>
                    <Provider store={store}>
                        <Router history={historyBrowser}>
                            <QueryClientProvider client={queryClient}>
                                {definiteChild}
                            </QueryClientProvider>
                        </Router>
                    </Provider>
                </StylesProvider>
            </AppContext.Provider>
        );
    }
};

const mapStateToProps = ({flortalAuth}) => {
    return {
        user: flortalAuth.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchAccessableGroupcodes: () => dispatch(actions.fetchAccessableGroupcodes()),
        onLocalGroupcode: groupCode => dispatch(actions.fetchLocalGroupcodeSuccess(groupCode)),
        onRefreshToken: (afterRefreshTokenError) => dispatch(actions.refreshToken(afterRefreshTokenError)),
        onFetchUserById: (azureId) => dispatch(actions.fetchUserById(azureId)),
        onCreateUserLog: (userLog) => dispatch(createUserLog(userLog))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
  