import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import { connect } from "react-redux";
import * as actions from '../../../../store/task/actions';

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 200,
  });

class SearchSalesmanAutoComplete extends React.Component {
    componentDidMount() {
        this.props.onFetchAccountManagers();
    }

    // shouldComponentUpdate(nextProps, _) {
    //     return nextProps.value !== this.props.value || nextProps.accountManagers !== this.props.accountManagers;
    // }

    render() {
        const props = this.props;
        return (
            <Autocomplete
                filterOptions={filterOptions}
                id="combo-box-account-managers"
                getOptionLabel={props.getOptionLabel}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    variant="outlined"
                    placeholder="Start met typen..."
                    error={props.error}
                    helperText={props.helperText}
                />
                )}
                // options={this.props.accountManagers}
                {..._.omit(props, ["error", "helperText", "accountManagers", "onFetchAccountManagers", "dispatch"])}

                options={props.options}
                // value={props.value}
                // onChange={props.onChange}
            />
        );
    }
}

const mapStateToProps = ({ task }) => {
    return {
        accountManagers: task.accountManagers,
    }
}

const mapDispatchToProps = (dispatch) => ({
    onFetchAccountManagers: () => dispatch(actions.fetchAccountManagers()),
});


export default connect(mapStateToProps, mapDispatchToProps)(SearchSalesmanAutoComplete);
