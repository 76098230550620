import React, { useState } from 'react';
import {Paper,Typography,List,Table,TableBody,TableCell,TableHead,TableRow,Chip,MuiThemeProvider, TableContainer, Icon, Button, TextField, Select, MenuItem} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { checkRole, getAzureId } from "../../Auth/Auth";
import { FuseAnimate } from "../../../../@fuse";
import DonutChart, { donutData as defaultDonutData } from "../../../components/Widgets/DonutChart";
import { useQuery } from "react-query";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from 'react-i18next';
import { Scrollbars } from "react-custom-scrollbars";
import UserStatsListItem from "./UserStatsComponents/UserStatsListItem";
import UserStatsVisitsPerHour, {
    chartData,
} from "./UserStatsComponents/UserStatsVisitsPerHour";
import UserStatsMonthlyPageVisits, {
    areaData,
} from "./UserStatsComponents/UserStatsMonthlyPageVisits";
import UserStatsHourHeatmap from "./UserStatsComponents/UserStatsHourHeatmap";
import _ from 'lodash';
import UserStatsComparison from "./UserStatsComponents/UserStatsComparison";
import Profile from '../../iCRM/Salesman/Profile/Profile';
import styled from 'styled-components';

const Bar = styled.div`
    border-top-right-radius: .8rem;
    border-top-left-radius: .8rem;
    ${props => props.orange ? 'background: #e97315;' : null}
    ${props => props.orangeL ? 'background: #f49a53;' : null}
    ${props => props.red ? 'background: #eb6460;' : null}
`
const StyledTypography = styled(Typography)`
    color: #fff;
    font-weight: bold;
`

const StyledTableHead = styled(TableHead)`
    background-color: rgb(30, 156, 156);
    tr > th {
        color: #fff;
        font-weight: bold;
    }
    tr > th:first-child {
        border-top-left-radius: .8rem;
    }
    tr > th:last-child {
        border-top-right-radius: .8rem;
    }    
`

const StyledTableCell = styled(TableCell)`
    background-color: rgb(30, 156, 156);
`

const useStyles = makeStyles((theme) => ({
    datePicker: {
        // minWidth: '155px',
        width: '100%',
    },
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    areaChart: {
        background: 'linear-gradient(to right, ' + theme.palette.primary.dark + ' 0%, ' + theme.palette.primary.main + ' 100%)',
    },
    blueChip: {
        //   color: 'rgba(0,229,255)',
        borderColor: "#3c4252",
        cursor: "default !important",
    },
    greenChip: {
        color: '#fff',
        borderColor: "#8bc467",
        backgroundColor: "#8bc467"
    },
    orangeChip: {
        color: '#fff',
        borderColor: "#e97315",
        backgroundColor: "#e97315"
    },
    lOrangeChip: {
        color: '#fff',
        borderColor: "#f49a53",
        backgroundColor: "#f49a53"
    }
}));

const fontColors = ["rgb(30,156,156)", "rgb(235,100,96)", "rgb(233,115,21)", "rgb(139,196,103)"];

const isManager = checkRole('iCRMManager');

const datefilterdropdown = [
    {key: 'last30days', value: 'Last 30 days'},
    {key: 'last60days', value: 'Last 60 days'},
    {key: 'last90days', value: 'Last 90 days'},
    {key: 'lastmonth', value: 'Last month'},
    {key: 'thismonth', value: 'This month'},
    {key: 'thisyear', value: 'This year'},
    {key: 'lastyear', value: 'Last year'},
]


const shouldDisplayStat = (stat) => isManager || (stat && stat > 0);
const UserStats = ({ match }) => {
    const [profileDalogOpen, setProfileDialogOpen] = React.useState(false);
    const { t } = useTranslation();
    const mainThemeDark = useTheme();
    const classes = useStyles();
    const azureId = match.params.azureId ?? getAzureId();
    const isOwnStats = match.params.azureId === getAzureId();
           
    // const [state, setState] = React.useState(handleDateButtonClick("defaultdate")); 

    const [state, setState] = React.useState({
        fromDate: new Date().getFullYear() + "-" + new Date().getMonth() + "-01",
        toDate: new Date().getFullYear() + "-12-31",
        Datefilter: "last30days",
    });
    
    const handleDateButtonClick = value => {
        let startDate = new Date();
        let endDate = new Date();
        switch(value) {
            case 'yesterday':
                startDate.setDate(startDate.getDate() - 1);
                endDate.setDate(endDate.getDate() - 1);
                break;
            case 'thisweek': 
                startDate = this.getMonday(startDate);
                break;
            case 'thismonth': 
                startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
                break;
            case 'thisyear': 
                startDate = new Date(startDate.getFullYear(), 0, 1)
                break;
            case 'lastweek': 
                startDate.setDate(startDate.getDate()-7);
                startDate = this.getMonday(startDate);
                endDate = moment(startDate).add('days', 4).toDate();
                break;
            case 'lastmonth': 
                startDate = new Date(startDate.getFullYear(), startDate.getMonth()-1, 1);
                endDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
                endDate.setHours(-4);
                break;
            case 'lastyear': 
                startDate = new Date(startDate.getFullYear() - 1, 0, 1);
                endDate = new Date(endDate.getFullYear() - 1, 11, 31);
                break;
            case 'last30days': 
                startDate.setDate(startDate.getDate() - 30);
                endDate.setDate(endDate.getDate() - 1);
                break;
            case 'last60days': 
                startDate.setDate(startDate.getDate() - 60);
                endDate.setDate(endDate.getDate() - 1);
                break;
            case 'last90days': 
                startDate.setDate(startDate.getDate() - 90);
                endDate.setDate(endDate.getDate() - 1);
                break;
            case 'defaultdate': 
                startDate.setDate(startDate.getDate() - 1);
                endDate.setDate(endDate.getDate() - 1);
                break;
            default:
                startDate = new Date(startDate.getFullYear(), 0, 1)
                endDate.setDate(endDate.getDate() - 1);
                break;
        }
        setState({
            ...state,
            fromDate: moment(startDate).format('YYYY-MM-DD'),
            toDate: moment(endDate).format('YYYY-MM-DD'),
            Datefilter: value,
        });
    }


    const { data: dailyVisits, isLoading: isLoadingDailyVisits } = useQuery(
        ["userVisitsPerDay", azureId],
        async () => {
            const res = await axios.get(
                "monitor/GetUserVisitsPerDay/" + azureId
            );
            return res.data;
        }, {
            staleTime: 30000
        }
    );
    if (dailyVisits && !isLoadingDailyVisits) {
        areaData.labels = dailyVisits.map((row) =>
            moment(row.visitDate).format("MMM Do")
        );
        areaData.datasets = {
            default: [
                {
                    label: "Visits",
                    data: dailyVisits.map((row) => row.visits),
                    fill: "start",
                },
            ],
        };
    }

    const { data: devices, isLoading: isLoadingDevices } = useQuery(
        ["deviceDistribution", azureId, state.fromDate, state.toDate],
        async () => {
            const res = await axios.get(
                "monitor/GetUserDeviceDistribution/" + azureId + "?fromdate="+ state.fromDate + "&todate="+ state.toDate
            );
            return res.data;
        }, {
            staleTime: 30000
        }
    );

    const donutData = _.cloneDeep(defaultDonutData);
    if (!isLoadingDevices && devices) {
        const labels = [];
        const dataset = [];
        devices.map((t) => labels.push(t.device) && dataset.push(t.amountOfVisits));

        donutData["mainChart"].datasets["TW"][0].data = dataset;
        donutData["mainChart"].datasets["TW"][0].hoverBackgroundColor = [
            "rgb(41,184,184)",
            "rgb(244,131,41)",
            "rgb(160,220,123)",
        ];
        donutData["mainChart"].datasets["TW"][0].backgroundColor = [
            "rgb(30,156,156)",
            "rgb(233,115,21)",
            "rgb(139,196,103)",
        ];
        donutData["mainChart"].labels = labels;
        donutData.title = "Turnover Distribution";
        donutData.mainChart.options.plugins.labels.render = "value";
    }

    const { data: pageVisits } = useQuery(
        ["pagesVisited", azureId, state.fromDate, state.toDate],
        async () => {
            const res = await axios.get(
                "monitor/GetUserPageVisits/" + azureId + "?fromdate="+ state.fromDate + "&todate="+ state.toDate
            );
            return res.data;
        }, {
            staleTime: 30000
        }
    );

    const { data: customersSearched, isLoading: isLoadingCustomersSearched } =
        useQuery(["customersSearched", azureId, state.fromDate, state.toDate], async () => {
            const res = await axios.get(
                "monitor/GetUserCustomerSearches/" + azureId + "?fromdate="+ state.fromDate + "&todate="+ state.toDate
            );
            return res.data;
        }, {
            staleTime: 30000
        }
        );

    const amountOfPagesVisitedThisMonth = dailyVisits?.reduce(
        (accumulator, currentValue) => {
            return accumulator + currentValue.visits;
        },
        0
    );

    const { data: averageVisitsPerHour, isLoading: isLoadingVisitsPerHour } = useQuery(
        ["averageUserVisitsPerHour", azureId, state.fromDate, state.toDate],
        async () => {
            const res = await axios.get(
                "monitor/GetUserVisitsAveragePerHour/" + azureId + "?fromdate="+ state.fromDate + "&todate="+ state.toDate
            );
            return res.data;
        }, {
            staleTime: 30000
        }
    );
    if (!isLoadingVisitsPerHour) {
        const dataset = [
            {
                label: "Gemiddelde bezoeken naar uur",
                data: averageVisitsPerHour.map((r) => r.amountOfVisits),
                backgroundColor: "rgb(0,229,255)",
            },
        ];
        const labels = averageVisitsPerHour.map((r) => r.hour);
        chartData.datasets["default"] = dataset;
        chartData.labels = labels;
    }

    let dailyUserVisitsPerHoursSeries = [];
    const { data: dailyUserVisitsPerHour, isLoading: isLoadingDailyUserVisitsPerHour } = useQuery(
        ["dailyUserVisitsPerHour", azureId, state.fromDate, state.toDate],
        async () => {
            const res = await axios.get(
                "monitor/GetUserVisitsPerHour/" + azureId + "?fromdate="+ state.fromDate + "&todate="+ state.toDate
            );
            return res.data;
        }, {
            staleTime: 30000
        }
    );
    if (!isLoadingDailyUserVisitsPerHour) {
        dailyUserVisitsPerHoursSeries = _.uniq(dailyUserVisitsPerHour.map(row => row.day)).map(day => {
            return {
                name: day,
                data: dailyUserVisitsPerHour.filter(visit => visit.day === day).map(visit => visit.amountOfVisits)
            }
        })
    }

    const { data: userStats, isLoading: isLoadingUserStats } = useQuery(
        ["userStats", azureId, state.fromDate, state.toDate],
        async () => {
            const res = await axios.get(
                "monitor/GetUserStatsByUser/" + azureId + "?fromdate="+ state.fromDate + "&todate="+ state.toDate
            )
            return res.data;
        }, {
            staleTime: 30000
        }
    );

    const { data: averageUserStats, isLoading: isLoadingAverageUserStats } = useQuery(
        ["averageUserStats", azureId, state.fromDate, state.toDate],
        async () => {
            const res = await axios.get(
                "monitor/GetAverageUserStats?fromdate="+ state.fromDate + "&todate="+ state.toDate
            );
            return res.data;
        }, {
            staleTime: 30000
        }
    );

    const { data: customersClicked, isLoading: isLoadingCustomersClicked } = useQuery(
        ["customersClicked", azureId, state.fromDate, state.toDate],
        async () => {
            const res = await axios.get(
                "monitor/GetUserCustomerClicks/" + azureId + "?fromdate="+ state.fromDate + "&todate="+ state.toDate
            );
            return res.data;
        }, {
            staleTime: 30000
        }
    );
    
    const getUserStatsPapersToDisplay = () => {
        const statsToDisplay = [];
        let i = 0;
        if(isLoadingAverageUserStats || isLoadingUserStats) {
            return null;
        }
        if(userStats.amountOfTasksFinished > averageUserStats.amountOfTasksFinished) {
            statsToDisplay.push(
                <UserStatsComparison
                    key={i}
                    value={(userStats.amountOfTasksFinished / averageUserStats.amountOfTasksFinished * 100 - 100).toFixed(0)} 
                    valueFontColor={fontColors[i]}
                    avgValue={averageUserStats.amountOfTasksFinished + " " +  t('tasks_concluded_avg')}
                    unit="%"
                    label={t('tasks_concluded_user')}
                />
            );
            i++;
        } else if (userStats.amountOfTasksFinished > -1 && userStats.amountOfTasksFinished < averageUserStats.amountOfTasksFinished) {            
            statsToDisplay.push(
                <UserStatsComparison
                    key={i}
                    value={(userStats.amountOfTasksFinished / averageUserStats.amountOfTasksFinished * 100 - 100).toFixed(0)} 
                    valueFontColor={fontColors[i]}
                    avgValue={averageUserStats.amountOfTasksFinished + " " +  t('tasks_concluded_avg')}
                    unit="%"
                    label={t('tasks_concluded_user_negative')}
                />
            );
            i++;
        }
        if(userStats.daysBetweenTaskCreationAndFinish > -1 && userStats.daysBetweenTaskCreationAndFinish < averageUserStats.daysBetweenTaskCreationAndFinish) {
            statsToDisplay.push(
                <UserStatsComparison
                    key={i}
                    value={(averageUserStats.daysBetweenTaskCreationAndFinish / userStats.daysBetweenTaskCreationAndFinish).toFixed(1)} 
                    valueFontColor={fontColors[i]}
                    avgValue={averageUserStats.daysBetweenTaskCreationAndFinish + " " +  t('task_concluded_speed_avg')}
                    unit="x"
                    label={t('task_concluded_speed_user')}
                />
            );
            i++;
        } else if (userStats.daysBetweenTaskCreationAndFinish > -1 && userStats.daysBetweenTaskCreationAndFinish > averageUserStats.daysBetweenTaskCreationAndFinish) {
            statsToDisplay.push(
                <UserStatsComparison
                    key={i}
                    value={(averageUserStats.daysBetweenTaskCreationAndFinish / userStats.daysBetweenTaskCreationAndFinish).toFixed(1)} 
                    valueFontColor={fontColors[i]}
                    avgValue={averageUserStats.daysBetweenTaskCreationAndFinish + " " +  t('task_concluded_speed_avg')}
                    unit="x"
                    label={t('task_concluded_speed_user_negative')}
                />
            );
            i++;
        }
        if(userStats.newLeadsCreated > averageUserStats.newLeadsCreated) {
            statsToDisplay.push(
                <UserStatsComparison
                    key={i}
                    value={(userStats.newLeadsCreated / averageUserStats.newLeadsCreated * 100 - 100).toFixed(0)} 
                    valueFontColor={fontColors[i]}
                    avgValue={averageUserStats.newLeadsCreated + " " +  t('leads_created_avg')}
                    unit="%"
                    label={t('leads_created_user')}
                />
            );
            i++;
        } else if(userStats.newLeadsCreated > -1 && userStats.newLeadsCreated < averageUserStats.newLeadsCreated) {
            statsToDisplay.push(
                <UserStatsComparison
                    key={i}
                    value={(userStats.newLeadsCreated / averageUserStats.newLeadsCreated * 100 - 100).toFixed(0)} 
                    valueFontColor={fontColors[i]}
                    avgValue={averageUserStats.newLeadsCreated + " " +  t('leads_created_avg')}
                    unit="%"
                    label={t('leads_created_user_negative')}
                />
            );
            i++;
        }
        if(userStats.commentsSubmitted > -1 && averageUserStats.commentsSubmitted > 0 && userStats.commentsSubmitted > averageUserStats.commentsSubmitted) {
            statsToDisplay.push(
                <UserStatsComparison
                    key={i}
                    value={(userStats.commentsSubmitted / averageUserStats.commentsSubmitted * 100 - 100).toFixed(0)} 
                    valueFontColor={fontColors[i]}
                    avgValue={averageUserStats.commentsSubmitted + " " +  t('notes_left_avg')}
                    unit="%"
                    label={t('notes_left_user')}
                />
            );
            i++;
        } else if(userStats.commentsSubmitted > -1 && averageUserStats.commentsSubmitted > 0 && userStats.commentsSubmitted < averageUserStats.commentsSubmitted) {
            statsToDisplay.push(
                <UserStatsComparison
                    key={i}
                    value={(userStats.commentsSubmitted / averageUserStats.commentsSubmitted * 100 - 100).toFixed(0)} 
                    valueFontColor={fontColors[i]}
                    avgValue={averageUserStats.commentsSubmitted + " " +  t('notes_left_avg')}
                    unit="%"
                    label={t('notes_left_user_negative')}
                />
            );
            i++;
        }
        if(i < 4 && userStats.pagesVisited > averageUserStats.pagesVisited) {
            statsToDisplay.push(
                <UserStatsComparison
                    key={i}
                    value={(userStats.pagesVisited / averageUserStats.pagesVisited * 100 - 100).toFixed(0)} 
                    valueFontColor={fontColors[i]}
                    avgValue={averageUserStats.pagesVisited + " " +  t('pages_visited_avg')}
                    unit="%"
                    label={t('pages_visited_user')}
                />
            );
            i++;
        }
        else if(i < 4 && userStats.pagesVisited < averageUserStats.pagesVisited) {
            statsToDisplay.push(
                <UserStatsComparison
                    key={i}
                    value={(userStats.pagesVisited / averageUserStats.pagesVisited * 100 - 100).toFixed(0)} 
                    valueFontColor={fontColors[i]}
                    avgValue={averageUserStats.pagesVisited + " " +  t('pages_visited_avg')}
                    unit="%"
                    label={t('pages_visited_user_negative')}
                />
            );
            i++;
        }
        if(i < 4 && userStats.newLeadsExported > -1 && userStats.newLeadsExported > averageUserStats.newLeadsExported) {
            statsToDisplay.push(
                <UserStatsComparison
                    key={i}
                    value={(userStats.newLeadsExported / averageUserStats.newLeadsExported * 100 - 100).toFixed(0)} 
                    valueFontColor={fontColors[i]}
                    avgValue={averageUserStats.newLeadsExported + " " +  t('leads_exported_avg')}
                    unit="%"
                    label={t('leads_exported_user')}
                />
            );
            i++;
        }
        else if(i < 4 && userStats.newLeadsExported > -1 && userStats.newLeadsExported < averageUserStats.newLeadsExported) {
            statsToDisplay.push(
                <UserStatsComparison
                    key={i}
                    value={(userStats.newLeadsExported / averageUserStats.newLeadsExported * 100 - 100).toFixed(0)} 
                    valueFontColor={fontColors[i]}
                    avgValue={averageUserStats.newLeadsExported + " " +  t('leads_exported_avg')}
                    unit="%"
                    label={t('leads_exported_user_negative')}
                />
            );
            i++;
        }
        if(i < 4 && userStats.daysBetweenNewLeadsCreatedAndExported > -1 && userStats.daysBetweenNewLeadsCreatedAndExported < averageUserStats.daysBetweenNewLeadsCreatedAndExported) {
            statsToDisplay.push(
                <UserStatsComparison
                    key={i}
                    value={(averageUserStats.daysBetweenNewLeadsCreatedAndExported / userStats.daysBetweenNewLeadsCreatedAndExported).toFixed(1)} 
                    valueFontColor={fontColors[i]}
                    avgValue={averageUserStats.daysBetweenNewLeadsCreatedAndExported + " " +  t('leads_validated_speed_avg')}
                    unit="x"
                    label={t('leads_validated_speed_user')}
                />
            );
            i++;
        }
        else if(i < 4 && userStats.daysBetweenNewLeadsCreatedAndExported > -1 && userStats.daysBetweenNewLeadsCreatedAndExported > averageUserStats.daysBetweenNewLeadsCreatedAndExported) {
            statsToDisplay.push(
                <UserStatsComparison
                    key={i}
                    value={(averageUserStats.daysBetweenNewLeadsCreatedAndExported / userStats.daysBetweenNewLeadsCreatedAndExported).toFixed(1)} 
                    valueFontColor={fontColors[i]}
                    avgValue={averageUserStats.daysBetweenNewLeadsCreatedAndExported + " " +  t('leads_validated_speed_avg')}
                    unit="x"
                    label={t('leads_validated_speed_user_negative')}
                />
            );
            i++;
        }
        // return _.take(shuffle(statsToDisplay), 4);
        return statsToDisplay;
    }

    return (
        <>
            <div className="w-full">
                <MuiThemeProvider theme={mainThemeDark}>
                    <div className={classes.areaChart}>
                        <div className="container relative p-16 sm:p-24 flex flex-row justify-between items-center">
                            <FuseAnimate delay={100}>
                                <div className="flex-col">
                                    <Typography className="h2" style={{ color: '#fff' }}>{userStats?.name}</Typography>
                                    <Typography className="h5" style={{ color: 'rgb(255 255 255 / 54%)' }}>
                                        {t(isOwnStats ? "VISITED_LAST_MONTH_OWN_STATS" : "VISITED_LAST_MONTH", { amountOfPageVisits: amountOfPagesVisitedThisMonth })}
                                    </Typography>
                                </div>
                            </FuseAnimate>
                            <div className="flex-col text-right">
                                    <Select
                                        id="land-select"
                                        value={state.Datefilter}
                                        onChange={(event) => handleDateButtonClick(event.target.value)}
                                        style={{ color: 'rgb(255 255 255 / 99%)' }}
                                        className="text-right"
                                    >
                                        {datefilterdropdown.map(row => (
                                            <MenuItem key={row.key} value={row.key}>{row.value}</MenuItem>
                                        ))}
                                    </Select>
                                    
                                    <Typography className="h2" style={{ color: 'rgb(255 255 255 / 54%)' }}>
                                    </Typography>
                                    <Typography className="h5 text-right" style={{ color: 'rgb(255 255 255 / 54%)' }}>
                                    {state.fromDate} until {state.toDate}
                                    </Typography>
                            </div>
                            {isOwnStats && 
                                <div className="flex flex-row items-center">
                                <Button
                                    startIcon={<Icon>settings</Icon>}
                                    className="py-8 px-12 text-gray-200"
                                    size="small"
                                    onClick={() => setProfileDialogOpen(true)}
                                >
                                    {t('SETTINGS')}
                                </Button>
                                </div>
                            }
                        </div>
                        <div className="container relative h-200 sm:h-256 pb-16">
                            {!isLoadingDailyVisits &&
                                <UserStatsMonthlyPageVisits
                                    data={areaData}
                                    dataset="default"
                                />
                            }
                        </div>
                    </div>
                </MuiThemeProvider>

                <Profile open={profileDalogOpen} handleClose={() => setProfileDialogOpen(false)} />
                
                <Typography className="p-16 pb-8 text-18 font-300">
                    {t(isOwnStats ? "avg_comparison_title_own_stats" : "avg_comparison_title")}
                </Typography>                
                
                <div className="flex justify-center md:flex-row sm:px-8 flex-wrap container">
                    {getUserStatsPapersToDisplay()}
                </div>

                <FuseAnimate animation="transition.slideUpIn" delay={200}>
                    <div className="flex flex-col md:flex-row sm:p-8 container">
                        <div className="flex flex-1 flex-col min-w-0">
                            {/* <FuseAnimate delay={600}> */}
                            <Typography className="p-16 pb-8 text-18 font-300">
                                {t("TITLECRMUSE")}
                                {/* Verkrijg inzicht in het gebruik van het CRM. */}
                            </Typography>
                            {/* </FuseAnimate> */}

                <div className="flex flex-col sm:flex sm:flex-row">
                    <div className="md:w-1/3 sm:w-full xs:w-full p-12">
                    <Paper className="w-full rounded-8 shadow-none border-1">
                        <List>
                        <UserStatsListItem
                            icon="web"
                            value={userStats?.pagesVisited}
                            label={t("PAGE_VISITED")}
                            shouldDisplay={shouldDisplayStat(userStats?.pagesVisited)}
                        />
                        <UserStatsListItem
                            icon="lock_open"
                            value={userStats?.customersOpened}
                            label={t("OPENED_CUSTOMERS")}
                            shouldDisplay={shouldDisplayStat(userStats?.customersOpened)}
                        />
                        <UserStatsListItem
                            icon="lock"
                            value={userStats?.customersClosed}
                            label={t("CLOSED_CUSTOMERS")}
                            shouldDisplay={shouldDisplayStat(userStats?.customersClosed)}
                        />
                        <UserStatsListItem
                            icon="autorenew"
                            value={userStats?.generatedIncassos}
                            label={t("GENERATED_INCASSO")}
                            shouldDisplay={shouldDisplayStat(userStats?.generatedIncassos)}
                        />
                        <UserStatsListItem
                            icon="send"
                            value={userStats?.mailedOpenPosten}
                            // label="Open posten gemaild"
                            label={t("EMAILED_OPENPOST")}
                            shouldDisplay={shouldDisplayStat(userStats?.mailedOpenPosten)}
                        />
                        <UserStatsListItem
                            icon="departure_board"
                            value={userStats?.routesOpened}
                            // label="Vertrekdagen open gezet"
                            label={t("OPENED_DEPARTURE_DAYS")}
                            shouldDisplay={shouldDisplayStat(userStats?.routesOpened)}
                        />
                        <UserStatsListItem
                            icon="person_search"
                            value={userStats?.customersSearched}
                            // label="Klanten gezocht"
                            label={t("SEARCHED_CUSTOMERS")}
                            shouldDisplay={shouldDisplayStat(userStats?.customersSearched)}
                        />
                        <UserStatsListItem
                            icon="timer"
                            value={
                            userStats?.avgTimeOnPage &&
                            new Date(userStats?.avgTimeOnPage * 1000)
                                .toISOString()
                                .substr(14, 5)
                            }
                            // label="Gem. tijd per pagina"
                            label={t("AVG_TIME_PER_PAGE")}
                            shouldDisplay={shouldDisplayStat(userStats?.avgTimeOnPage)}
                        />
                        <UserStatsListItem
                            icon="check_box"
                            value={userStats?.newCustomersActivated}
                            label="Nieuwe klanten geactiveerd"
                            shouldDisplay={shouldDisplayStat(userStats?.newCustomersActivated)}
                        />
                        </List>
                    </Paper>
                    </div>
                    <div className="md:w-1/3 sm:w-full xs:w-full p-12">
                    <Paper className="w-full rounded-8 shadow-none border-1">
                        <List>
                        <UserStatsListItem
                            icon="assignment_turned_in"
                            value={userStats?.amountOfTasksFinished}
                            // label="Afgeronde taken"
                            label={t("TASKS_DONE")}
                            shouldDisplay={shouldDisplayStat(userStats?.amountOfTasksFinished)}
                        />
                        <UserStatsListItem
                            icon="assignment"
                            value={userStats?.amountOfTasksOpen}
                            // label="Openstaande taken"
                            label={t("OPEN_TASKS")}
                            shouldDisplay={shouldDisplayStat(userStats?.amountOfTasksOpen)}
                        />
                        <UserStatsListItem
                            icon="access_time"
                            value={userStats?.daysBetweenTaskCreationAndFinish}
                            // label="Dagen tussen taak creatie en afhandeling"
                            label={t("TASK_CREATION_COMPLETION")}
                            shouldDisplay={shouldDisplayStat(userStats?.daysBetweenTaskCreationAndFinish)}
                        />
                        <UserStatsListItem
                            icon="comment"
                            value={userStats?.commentsSubmitted}
                            // label="Klantnotities achtergelaten"
                            label={t("NOTES_WRITTEN")}
                            shouldDisplay={shouldDisplayStat(userStats?.commentsSubmitted)}
                        />
                        <UserStatsListItem
                            icon="local_offer"
                            value={userStats?.specialOffersSent}
                            // label="Promo's verstuurd"
                            label={t("SENT_SPECIAL_OFFERS")}
                            shouldDisplay={shouldDisplayStat(userStats?.specialOffersSent)}
                        />
                        <UserStatsListItem
                            icon="person_add_alt"
                            value={userStats?.newLeadsCreated}
                            // label="New Leads gecreeërd"
                            label={t("NEWLEADS_CREATED")}
                            shouldDisplay={shouldDisplayStat(userStats?.newLeadsCreated)}
                        />
                        <UserStatsListItem
                            icon="check_circle"
                            value={userStats?.newLeadsValidated}
                            // label="New Leads gevalideerd"
                            label={t("NEWLEADS_VALIDATED")}
                            shouldDisplay={shouldDisplayStat(userStats?.newLeadsValidated)}
                        />
                        <UserStatsListItem
                            icon="cloud_upload"
                            value={userStats?.newLeadsExported}
                            // label="New Leads geëxporteerd"
                            label={t("NEWLEADS_EXPORTED")}
                            shouldDisplay={shouldDisplayStat(userStats?.newLeadsExported)}
                        />
                        <UserStatsListItem
                            icon="access_time"
                            value={userStats?.daysBetweenNewLeadsCreatedAndExported}
                            // label="Dagen tussen lead creatie en FS Export"
                            label={t("DAYS_BET_NEWLEADS_CREATED_EXPORTED")}
                            shouldDisplay={shouldDisplayStat(userStats?.daysBetweenNewLeadsCreatedAndExported)}
                        />
                        </List>
                    </Paper>
                    </div>
                    <div className="widget flex sm:w-1/3 md:w-1/3 xs:w-full p-12">
                    <Paper className="w-full rounded-8 shadow-none border-1">
                        <div className="flex items-center justify-between px-16 h-64 border-b-1">
                        <Typography className="text-16">
                            {t("SESSION_PER_DEVICE")}
                        </Typography>
                        </div>
                        <div className="h-400 w-full p-24 text-center">
                        {!isLoadingDevices ? (
                            <DonutChart data={donutData} />
                        ) : (
                            <>
                            <Skeleton
                                className="rounded m-auto"
                                variant="rect"
                                width={"60%"}
                                height={250}
                            />
                            <Skeleton
                                className="mt-12 m-auto"
                                variant="text"
                                width={240}
                            />
                            </>
                        )}
                        </div>
                    </Paper>
                    </div>
                </div>
                </div>
            </div>
            </FuseAnimate>
        </div>

            <FuseAnimate animation="transition.slideUpIn" delay={400}>
                <div className="flex flex-col md:flex-row sm:px-8 container">
                    <div className="flex flex-1 flex-col min-w-0">
                        <Typography className="px-16 pb-8 text-18 font-300">
                            {t(isOwnStats ? "WHEN_ARE_USERS_ACTIVE_OWN_STATS" : "WHEN_ARE_USERS_ACTIVE")}
                        </Typography>
                        <div className="flex flex-wrap w-full">
                            {/* <div className="widget flex w-full sm:w-1/3 p-12">
            <Block1 data={widgets.widget2} />
            </div> */}
                            <div className="md:w-2/3 sm:w-2/3 w-full p-12">
                                {!isLoadingVisitsPerHour && (
                                    <UserStatsVisitsPerHour
                                        data={chartData}
                                        dataset="default"
                                        headerText={t("AVERAGE_VISITS_PER_HOUR")}
                                    />
                                )}
                            </div>
                            <div className="md:w-1/3 sm:w-1/3 w-full p-12">
                                <Paper
                                    className="w-full rounded-8 shadow-none border-1"
                                    elevation={1}
                                >
                                    {/* <Scrollbars
                                        className="rct-scroll"
                                        autoHeight
                                        autoHeightMin={100}
                                        autoHeightMax={390}
                                    > */}
                                        {/* <div className="table-responsive"> */}
                                            <TableContainer style={{maxHeight: 390}}>
                                            <Table stickyHeader>
                                                <StyledTableHead>
                                                    <TableRow hover>
                                                        <StyledTableCell>Page</StyledTableCell>
                                                        <StyledTableCell>{t("AMOUNT_VISITS")}</StyledTableCell>
                                                        <StyledTableCell>{t("AVERAGE_TIME_PAGE")}</StyledTableCell>
                                                    </TableRow>
                                                </StyledTableHead>
                                                <TableBody>
                                                    {pageVisits &&
                                                        pageVisits.map((row) => {
                                                            return (
                                                                <TableRow hover key={row.pageName}>
                                                                    <TableCell>
                                                                        <span className="text-opacity-60">
                                                                            {row.pageName}
                                                                        </span>
                                                                    </TableCell>
                                                                    <TableCell>{row.amountOfVisits}</TableCell>
                                                                    <TableCell>
                                                                        {row.avgTimeOnPage > 0 &&
                                                                            new Date(row.avgTimeOnPage * 1000)
                                                                                .toISOString()
                                                                                .substr(14, 5)}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                            </TableContainer>
                                        {/* </div> */}
                                    {/* </Scrollbars> */}
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </FuseAnimate>

            <FuseAnimate animation="transition.slideUpIn" delay={200}>
                <div className="flex flex-col md:flex-row sm:p-8 container">
                    <div className="flex flex-1 flex-col min-w-0 md:w-1/4 sm:w-1/4 xs:w-full p-12">
                        <Paper className="w-full rounded-8 shadow-none border-1">
                            <Bar orange className="flex items-center justify-between px-16 h-64 border-b-1">
                                <StyledTypography className="text-16">
                                    {t(isOwnStats ? "LIST_OF_SEARCHED_CUSTOMERS_OWN_STATS" : "LIST_OF_SEARCHED_CUSTOMERS")}
                                </StyledTypography>
                            </Bar>
                            <div className="flex flex-wrap justify-center p-16">
                            <Scrollbars
                                className="text-center rct-scroll"
                                autoHeight
                                autoHeightMin={100}
                                autoHeightMax={390}
                            >
                                {customersSearched?.map((customer) => (
                                    <Chip
                                        key={customer}
                                        className={[classes.orangeChip, "m-8"].join(" ")}
                                        label={customer}
                                        variant="outlined"
                                    />
                                ))}
                                {
                                    (!isLoadingCustomersSearched && customersSearched && customersSearched.length === 0) && <Typography color="textSecondary">{t('NO_SEARCHED_FOUND')}</Typography>
                                }
                                </Scrollbars>
                            </div>
                        </Paper>
                    </div>
                    <div className="flex flex-1 flex-col min-w-0 md:w-1/4 sm:w-1/4 xs:w-full p-12">
                        <Paper className="w-full rounded-8 shadow-none border-1">
                            <Bar orangeL className="flex items-center justify-between px-16 h-64 border-b-1">
                                <StyledTypography className="text-16">
                                    {t(isOwnStats ? "LIST_OF_CLICKED_CUSTOMERS_OWN_STATS" : "LIST_OF_CLICKED_CUSTOMERS")}
                                </StyledTypography>
                            </Bar>
                            <div className="flex flex-wrap justify-center p-16">
                            <Scrollbars
                                className="text-center rct-scroll"
                                autoHeight
                                autoHeightMin={100}
                                autoHeightMax={390}
                            >
                                {customersClicked?.map((customer) => (
                                    <Chip
                                        key={customer}
                                        className={[classes.lOrangeChip, "m-8"].join(" ")}
                                        label={customer}
                                        variant="outlined"
                                    />
                                ))}
                                {
                                    (!isLoadingCustomersClicked && customersClicked && customersClicked.length === 0) && <Typography color="textSecondary">{t('NO_SEARCHED_FOUND')}</Typography>
                                }
                                </Scrollbars>
                            </div>
                        </Paper>
                    </div>
                    <div className="sm:w-2/4 md:w-2/4 xs:w-full p-12">
                        <Paper className="w-full rounded-8 shadow-none border-1">
                            <Bar red className="flex items-center justify-between px-16 h-64 border-b-1">
                                <StyledTypography className="text-16">
                                    {t("USER_ACTIVITY_PER_HOUR")}
                                </StyledTypography>
                            </Bar>
                            {
                                !isLoadingDailyUserVisitsPerHour && <UserStatsHourHeatmap series={dailyUserVisitsPerHoursSeries} />
                            }
                        </Paper>
                    </div>
                </div>
            </FuseAnimate>
        </>
    );
};

export default UserStats;
