import React from 'react';
import {Card, CardContent, CardHeader} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const skeleton = () => (
    
    <Card className="p-12 rounded-8 shadow-none border-1">
        <CardHeader title={
            <Skeleton variant="text" width={"35%"} height={40} />
        }>
        </CardHeader>
        <CardContent>
            <Skeleton variant="text" width={"85%"} height={25} />
            <Skeleton variant="text" width={"85%"} height={25} />
            <Skeleton variant="text" width={"85%"} height={25} />
            <Skeleton variant="text" width={"85%"} height={25} />
            <Skeleton variant="text" width={"85%"} height={25} />
            {/* <Skeleton variant="text" width={300} height={25} />
            <Skeleton variant="text" width={300} height={25} />
            <Skeleton variant="text" width={300} height={25} />
            <Skeleton variant="text" width={300} height={25} /> */}
        </CardContent>
    </Card>
)

export default skeleton;