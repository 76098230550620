import React, {Component} from 'react';
import {withStyles, Drawer, Typography, AppBar, LinearProgress} from '@material-ui/core/';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as Actions from './store/actions/index'
const styles = theme => ({
    root: {
        width  : 350,
    },
    appBar: {
        height: 66,
        color: '#fffff'
    },
});

class QuickPanel extends Component {

    render()
    {
        const {classes, state, toggleQuickPanel} = this.props;
        return (
          <Drawer
              classes={{paper: classes.root}}
              open={state}
              anchor="right"
              onClose={() => toggleQuickPanel(false)}
              className="text-center"
          >
            <AppBar position="static" className={classes.appBar}>
                <Typography className={classes.appBar + ' mt-16'} component="h4" variant="h6" style={{color: '#fff'}}>Filters</Typography>
            </AppBar>
            <div className="w-full p-24">
            <Typography component="h4" variant="h4">In de maak!</Typography>
              <LinearProgress className="mt-24" variant="query" />
              <LinearProgress className="mt-24" variant="query" />
            </div>
          </Drawer>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        toggleQuickPanel: Actions.toggleQuickPanel
    }, dispatch);
}

function mapStateToProps({quickPanel})
{
    return {
        state: quickPanel.state
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(QuickPanel));
