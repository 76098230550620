import Routing from './routing/Routing';
import Tailwindcss from './tailwindcss/Tailwindcss';
import FuseTheming from './fuse-theming/FuseTheming';

export const DocumentationConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/documentation/routing',
            component: Routing
        },
        {
            path     : '/documentation/tailwindcss',
            component: Tailwindcss
        },
        {
            path     : '/documentation/fuse-theming',
            component: FuseTheming
        },
    ]
};