import React from 'react';
import { Icon, Tooltip, IconButton, Typography, FormControl, Button, OutlinedInput, Select, MenuItem, Badge } from '@material-ui/core';
import { FuseAnimateGroup } from '../../../../@fuse';
import ReactTable from "react-table";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { historyBrowser } from '../../../../historyexport';
import { getAzureId } from '../../Auth/Auth';

const today = new Date();

const isBadgeInvisible = (lastVisit, lastUpdate, updaterAzureId, lastVisitAzureId) => {
    if(lastVisit && lastUpdate && lastVisitAzureId) {
        return lastVisitAzureId === getAzureId();
    }
    return true;
}

const TasksTable = ({ data, favoriteUsers, handleFavoriteUsersDialogOpen, assignUser, accountManagerAssignmentEnabled }) => {
    const { t } = useTranslation();
    return (
        <FuseAnimateGroup
            enter={{
                animation: "transition.slideUpBigIn"
            }}
        > 
            <div className="flex flex-wrap w-full">
                <ReactTable
                    className="w-full -striped -highlight border-0"
                    data={data}
                    // rowHeight={80} 
                    columns={[
                        {
                            Header    : t("TASK"),
                            accessor: "title",
                            className: "justify-center font-bold",
                            minWidth: 200,
                            // Cell: row => <Badge color="primary" variant="dot" invisible={isBadgeInvisible(row.original.lastVisit, row.original.lastUpdate, row.original.lastUpdaterAzureId)}>
                            //     <Link to={`/task/${row.original.id}`}>{row.value}</Link>
                            // </Badge>
                            Cell: row => {
                                const taskUrl = row.original.taskType === 'BI First Order' ? 'ordertask' : 'task';
                                return <>
                                {!isBadgeInvisible(row.original.lastVisit, row.original.lastUpdate, row.original.lastUpdaterAzureId, row.original.lastVisitAzureId)
                                 && <Tooltip title="Deze taak heeft een update gehad"><Icon style={{color: '#e3342f'}}>radio_button_checked</Icon></Tooltip>}
                                    <Link to={`/${taskUrl}/${row.original.id}`}>{row.value}</Link>
                            </>
                            }
                        },
                        {
                            Header    : t("CUSTOMER"),
                            accessor  : "debnr",
                            filterable: true,
                            className: "justify-center",
                            minWidth: 120,
                        },
                        {
                            Header    : t('CUSTOMER') + ' ' + t("NAME"),
                            accessor: "debnaam",
                            filterable: true,
                            className: "justify-center",
                            minWidth: 160,
                            // Cell: row => <Link to={`/taken/${row.original.id}`}>{row.value}</Link>
                        },
                        {
                            Header    : t("ENDDATE"),
                            accessor  : "endDate",
                            filterable: true,
                            className: "justify-center",
                            minWidth: 150,
                            // Cell: row => (
                            //     <span className={moment(row.value).isBefore(today) ? "font-bold text-orange" : ""}>{moment(row.value).format("YYYY-MM-DD")}</span>
                            //     )
                            Cell: row =>  moment(row.original.endDate).format("YYYY-MM-DD") < moment(new Date()).subtract(7, 'days').format("YYYY-MM-DD") ?
                                <><span className={moment(row.value).isBefore(today) ? "font-bold text-red" : ""}>{moment(row.value).format("YYYY-MM-DD")}</span>
                                <Tooltip title={t("OVERDUE") + " +7 days : " + moment(row.original.endDate).format("YYYY-MM-DD")}>
                                    <IconButton style={{color: 'red'}} aria-label={t("OVERDUE")}>
                                        <Icon>update</Icon>
                                    </IconButton>
                                </Tooltip></>
                                :
                                moment(row.original.endDate).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD") ?
                                <><span className={moment(row.value).isBefore(today) ? "font-bold text-orange" : ""}>{moment(row.value).format("YYYY-MM-DD")}</span>
                                <Tooltip title={t("OVERDUE") + " : " + moment(row.original.endDate).format("YYYY-MM-DD")}>
                                    <IconButton style={{color: 'orange'}} aria-label={t("OVERDUE")}>
                                        <Icon>update</Icon>
                                    </IconButton>
                                </Tooltip></>
                                :
                                moment(row.value).format("YYYY-MM-DD")
                        },
                        // {
                        //     Header    : t("ACCOUNTMNGR"),
                        //     accessor  : "accountManager",
                        //     filterable: true,
                        //     className: "justify-center",
                        //     minWidth: 200,
                        // },
                        {
                            Header    : t("ACCOUNTMNGR"),
                            accessor  : "azureId",
                            filterable: true,
                            className: "justify-center",
                            minWidth: 200,
                            filterMethod: (filter, row) => {
                                return row._original.accountManager.toLowerCase().includes(filter.value.filterValue.toLowerCase());
                            },
                            Cell: row => accountManagerAssignmentEnabled ? ((favoriteUsers && favoriteUsers.length > 0) ?
                                    <FormControl variant="outlined" fullWidth>
                                        <Select
                                            input={
                                                <OutlinedInput
                                                    labelWidth={0}
                                                />
                                            }
                                            id="accountManager"
                                            name="accountManager"
                                            value={row.value}
                                            onChange={(event) => assignUser({id: row.original.id, azureId: event.target.value, azureIdFromUpdater: getAzureId(), orderTask: row.original.taskType === 'BI First Order'})}
                                            required
                                            fullWidth
                                            defaultValue={row.azureId}
                                        >
                                            {favoriteUsers.map(i => <MenuItem key={i.favoriteAzureId} value={i.favoriteAzureId}>{i.name}</MenuItem>)}
                                            {(row.value !== '' && !favoriteUsers.some(f => f.favoriteAzureId === row.value)) && <MenuItem value={row.value}>{row.original.accountManager}</MenuItem>}
                                        </Select>
                                    </FormControl>
                                    : 
                                    <Tooltip title={"You didn't select any users, please select your favorite account managers"}>
                                        <Button className="successButton p-24" onClick={handleFavoriteUsersDialogOpen}>
                                            <Icon>star</Icon>
                                        </Button>
                                    </Tooltip>
                            )
                            :
                            <React.Fragment>
                                {(row.original.azureId !== row.original.createdBy && row.original.createdBy === getAzureId() && row.original.taskType !== 'BI Retention') && 
                                    <Tooltip title="You have created this task for someone else"><Icon color="action">supervised_user_circle</Icon></Tooltip>}
                                <Typography>{row.original.accountManager}</Typography>
                            </React.Fragment>
                        },
                        {
                            Header    : "Type",
                            accessor  : "taskType",
                            filterable: true,
                            className: "justify-center",
                            minWidth: 150,
                        },
                        {
                            Header    : t("ACTIONSTODOBI"),
                            className: "justify-center font-bold",
                            minWidth: 100,
                            Cell: row => (
                                row.original.taskType === 'BI Retention' && !row.original.concluded && row.original.taskFollowUpSummary && row.original.taskFollowUpSummary !== '' ? 
                                <Tooltip 
                                title={
                                  <React.Fragment>
                                    <em>{t('ACTIONSTODOBI_TABLE_TOOLTIP')} : </em>
                                    <Typography color="inherit">{row.original.taskFollowUpSummary}</Typography>
                                  </React.Fragment>
                                }>
                                <Icon style={{color: 'orange'}}>assignment_late</Icon>
                                </Tooltip>
                                : null
                            )
                        },
                        {
                            Header    : t("concluded"),
                            accessor  : "concluded",
                            // filterable: true,
                            className: "justify-center",
                            minWidth: 100,
                            Cell: row => !row.value ? 
                            <Tooltip title={t("concluded") + " : " + t("NO")}>
                                <Icon className="text-18" color="error">clear</Icon> 
                            </Tooltip>
                            :
                            <Tooltip title={t("concluded") + " : " + t("YES")}>
                                <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                            </Tooltip>
                        },
                        {
                            Header    : t("EDIT"),
                            className: "justify-center",
                            minWidth: 70,
                            Cell: row => {
                                const taskUrl = row.original.taskType === 'BI First Order' ? 'ordertask' : 'task';
                                return (
                                <Tooltip title={t("EDIT")}>
                                    <IconButton aria-label="Edit" onClick={() => historyBrowser.push(`/${taskUrl}/${row.original.id}`)}>
                                        <Icon>edit</Icon>
                                    </IconButton>
                                </Tooltip>
                            )}
                        },
                    ]}
                    pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                    defaultPageSize={10}
                    noDataText="No tasks assigned to you found"
                />
            </div>
        </FuseAnimateGroup>
    );
};

export default TasksTable;