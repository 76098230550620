
export const FETCH_HBXDECO_DEBITEUR_TURNOVER_START = 'FETCH_HBXDECO_DEBITEUR_TURNOVER_START';
export const FETCH_HBXDECO_DEBITEUR_TURNOVER_SUCCESS = 'FETCH_HBXDECO_DEBITEUR_TURNOVER_SUCCESS';
export const FETCH_HBXDECO_DEBITEUR_TURNOVER_FAIL = 'FETCH_HBXDECO_DEBITEUR_TURNOVER_FAIL';

export const FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_START = 'FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_START';
export const FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_SUCCESS = 'FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_SUCCESS';
export const FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_FAIL = 'FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_FAIL';

export const FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_ALL_CUSTOMERS_START = 'FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_ALL_CUSTOMERS_START';
export const FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_ALL_CUSTOMERS_SUCCESS = 'FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_ALL_CUSTOMERS_SUCCESS';
export const FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_ALL_CUSTOMERS_FAIL = 'FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_ALL_CUSTOMERS_FAIL';

export const FETCH_HBXDECO_DASHBOARD_BLOCKS_START = 'FETCH_HBXDECO_DASHBOARD_BLOCKS_START';
export const FETCH_HBXDECO_DASHBOARD_BLOCKS_SUCCESS = 'FETCH_HBXDECO_DASHBOARD_BLOCKS_SUCCESS';
export const FETCH_HBXDECO_DASHBOARD_BLOCKS_FAIL = 'FETCH_HBXDECO_DASHBOARD_BLOCKS_FAIL';

export const FETCH_HBXDECO_DASHBOARD_TABLES_START = 'FETCH_HBXDECO_DASHBOARD_TABLES_START';
export const FETCH_HBXDECO_DASHBOARD_TABLES_SUCCESS = 'FETCH_HBXDECO_DASHBOARD_TABLES_SUCCESS';
export const FETCH_HBXDECO_DASHBOARD_TABLES_FAIL = 'FETCH_HBXDECO_DASHBOARD_TABLES_FAIL';

export const FETCH_HBXDECO_DASHBOARD_KOERSEN_START = 'FETCH_HBXDECO_DASHBOARD_KOERSEN_START';
export const FETCH_HBXDECO_DASHBOARD_KOERSEN_SUCCESS = 'FETCH_HBXDECO_DASHBOARD_KOERSEN_SUCCESS';
export const FETCH_HBXDECO_DASHBOARD_KOERSEN_FAIL = 'FETCH_HBXDECO_DASHBOARD_KOERSEN_FAIL';

export const FETCH_HBXDECO_TURNOVER_START = 'FETCH_HBXDECO_TURNOVER_START';
export const FETCH_HBXDECO_TURNOVER_SUCCESS = 'FETCH_HBXDECO_TURNOVER_SUCCESS';
export const FETCH_HBXDECO_TURNOVER_FAIL = 'FETCH_HBXDECO_TURNOVER_FAIL';

export const FETCH_HBXDECO_FINANCIELE_GROEPEN_START = 'FETCH_HBXDECO_FINANCIELE_GROEPEN_START';
export const FETCH_HBXDECO_FINANCIELE_GROEPEN_SUCCESS = 'FETCH_HBXDECO_FINANCIELE_GROEPEN_SUCCESS';
export const FETCH_HBXDECO_FINANCIELE_GROEPEN_FAIL = 'FETCH_HBXDECO_FINANCIELE_GROEPEN_FAIL';

export const FETCH_HBXDECO_FINANCIELE_GROEPEN_MARGES_START = 'FETCH_HBXDECO_FINANCIELE_GROEPEN_MARGES_START';
export const FETCH_HBXDECO_FINANCIELE_GROEPEN_MARGES_SUCCESS = 'FETCH_HBXDECO_FINANCIELE_GROEPEN_MARGES_SUCCESS';
export const FETCH_HBXDECO_FINANCIELE_GROEPEN_MARGES_FAIL = 'FETCH_HBXDECO_FINANCIELE_GROEPEN_MARGES_FAIL';

export const FETCH_HBXDECO_ORDER_FREQUENCY_START = 'FETCH_HBXDECO_ORDER_FREQUENCY_START';
export const FETCH_HBXDECO_ORDER_FREQUENCY_SUCCESS = 'FETCH_HBXDECO_ORDER_FREQUENCY_SUCCESS';
export const FETCH_HBXDECO_ORDER_FREQUENCY_FAIL = 'FETCH_HBXDECO_ORDER_FREQUENCY_FAIL';

export const FETCH_HBXDECO_CREDIT_CUSTOMERS_START = 'FETCH_HBXDECO_CREDIT_CUSTOMERS_START';
export const FETCH_HBXDECO_CREDIT_CUSTOMERS_SUCCESS = 'FETCH_HBXDECO_CREDIT_CUSTOMERS_SUCCESS';
export const FETCH_HBXDECO_CREDIT_CUSTOMERS_FAIL = 'FETCH_HBXDECO_CREDIT_CUSTOMERS_FAIL';

// Overview
export const FETCH_HBXDECO_OVERVIEW_START = 'FETCH_HBXDECO_OVERVIEW_START';
export const FETCH_HBXDECO_OVERVIEW_SUCCESS = 'FETCH_HBXDECO_OVERVIEW_SUCCESS';
export const FETCH_HBXDECO_OVERVIEW_FAIL = 'FETCH_HBXDECO_OVERVIEW_FAIL';

export const FETCH_HBXDECO_PASTYEAR_OVERVIEW_START = 'FETCH_HBXDECO_PASTYEAR_OVERVIEW_START';
export const FETCH_HBXDECO_PASTYEAR_OVERVIEW_SUCCESS = 'FETCH_HBXDECO_PASTYEAR_OVERVIEW_SUCCESS';
export const FETCH_HBXDECO_PASTYEAR_OVERVIEW_FAIL = 'FETCH_HBXDECO_PASTYEAR_OVERVIEW_FAIL';

export const FETCH_HBXDECO_RESERVATIONS_OVERVIEW_START = 'FETCH_HBXDECO_RESERVATIONS_OVERVIEW_START';
export const FETCH_HBXDECO_RESERVATIONS_OVERVIEW_SUCCESS = 'FETCH_HBXDECO_RESERVATIONS_OVERVIEW_SUCCESS';
export const FETCH_HBXDECO_RESERVATIONS_OVERVIEW_FAIL = 'FETCH_HBXDECO_RESERVATIONS_OVERVIEW_FAIL';

export const FETCH_HBXDECO_TARGET_OVERVIEW_START = 'FETCH_HBXDECO_TARGET_OVERVIEW_START';
export const FETCH_HBXDECO_TARGET_OVERVIEW_SUCCESS = 'FETCH_HBXDECO_TARGET_OVERVIEW_SUCCESS';
export const FETCH_HBXDECO_TARGET_OVERVIEW_FAIL = 'FETCH_HBXDECO_TARGET_OVERVIEW_FAIL';

export const FETCH_HBXDECO_MARGES_OVERVIEW_START = 'FETCH_HBXDECO_MARGES_OVERVIEW_START';
export const FETCH_HBXDECO_MARGES_OVERVIEW_SUCCESS = 'FETCH_HBXDECO_MARGES_OVERVIEW_SUCCESS';
export const FETCH_HBXDECO_MARGES_OVERVIEW_FAIL = 'FETCH_HBXDECO_MARGES_OVERVIEW_FAIL';
