import React, { Component } from 'react';
import ReactTable from "react-table";
import {FuseAnimate, FusePageSimple} from '../../../../@fuse';
import {Icon, Typography, LinearProgress} from '@material-ui/core';
import {fetchPageStats} from '../../../store/monitor/actions'
import { connect } from 'react-redux';
import {withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';

class PageStats extends Component {
    componentDidMount() {
        this.props.onFetchPageStats();
    }

    render()
    {
        const {pageStats, t} = this.props;
        return (
            <FusePageSimple
            header={
                <div className="flex flex-1 items-center justify-between p-24">
                    <div className="flex flex-col">
                        <div className="flex items-center mb-16">
                            <Icon className="text-18" color="action">home</Icon>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">{t("PAGE_STATS")}</Typography>
                        </div>
                        <Typography variant="h6">{t("PAGE_STATS")}</Typography>
                    </div>
                </div>
            }
            content={pageStats.length > 0 ?
                <FuseAnimate animation="transition.slideUpIn" delay={300}>
                    <div className="w-full">
                        <ReactTable
                            className="-striped -highlight border-0"
                            data={pageStats}
                            columns={[
                                {
                                    Header    : t("NAME"),
                                    accessor  : "page",
                                    filterable: true,
                                    className : "font-bold justify-center",
                                    Cell: ({value}) => <Link to={{pathname: "/stats/page", state: { page: value }}}>{value}</Link>
                                },
                                {
                                    Header    : "Aantal bezoeken",
                                    accessor  : "amountOfVisits",
                                    filterable: true,
                                    className: "justify-center",
                                },
                                {
                                    Header    : "Gem. tijd op pagina",
                                    accessor  : "avgTimeOnPage",
                                    filterable: true,
                                    className: "justify-center",
                                    width     : 150,
                                    Cell: ({value}) => new Date(value * 1000).toISOString().substr(14, 5)

                                },
                            ]}
                            pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                            defaultPageSize={15}
                            noDataText="No customers found"
                        /> 
                    </div>
                </FuseAnimate>
            : <div className="flex h-full">
                <div className="m-auto">
                    <LinearProgress />
                    <Typography>Fetching statistics...</Typography>
                </div>
                </div> 
            }
            />
        )
    }
}

const mapStateToProps = ({ monitor }) => {
    return {
        pageStats: monitor.pageStats,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchPageStats: () => dispatch(fetchPageStats())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PageStats));