import * as actionTypes from './actionTypes';
import axios from 'axios'

export const fetchSpecialOffersSuccess = (dbSpecialOffers) => {
    return {
        type: actionTypes.FETCH_SPECIALOFFERS_SUCCESS,
        specialOffers: dbSpecialOffers
    };
};

export const fetchSpecialOffers = () => {
    return dispatch => {
        axios.get('specialOffer/getspecialoffers')
            .then(res => {
                dispatch(fetchSpecialOffersSuccess(res.data));
            })
    };
};

// With Products
export const fetchSpecialOffersProductsStart = () => {
    return {
        type: actionTypes.FETCH_SPECIALOFFERS_PRODUCTS_START,
    };
};

export const fetchSpecialOffersProductsSuccess = (dbSpecialOffersProducts) => {
    return {
        type: actionTypes.FETCH_SPECIALOFFERS_PRODUCTS_SUCCESS,
        specialOffersProducts: dbSpecialOffersProducts
    };
};

export const fetchSpecialOffersProducts = () => {
    return dispatch => {
        dispatch(fetchSpecialOffersProductsStart());
        axios.get('specialOffer/GetSpecialOffersWithProducts')
            .then(res => {
                dispatch(fetchSpecialOffersProductsSuccess(res.data));
            })
    };
};


// Get Products
export const fetchProductsBySpecialOfferStart = () => {
    return {
        type: actionTypes.FETCH_PRODUCTS_BY_SPECIALOFFER_START,
    };
};
export const fetchProductsBySpecialOfferSuccess = (dbProducts) => {
    return {
        type: actionTypes.FETCH_PRODUCTS_BY_SPECIALOFFER_SUCCESS,
        products: dbProducts
    };
};
export const fetchProductsBySpecialOfferFail = (errorMessage) => {
    return {
        type: actionTypes.FETCH_PRODUCTS_BY_SPECIALOFFER_FAIL,
        errorMessage
    };
};

export const fetchProductsBySpecialOffer = (specialOffer) => {
    
    let url = 'specialOffer/GetProductsBySpecialOffer';

    return dispatch => {
        dispatch(fetchProductsBySpecialOfferStart());
        axios.post(url, specialOffer)
            .then(res => {
                dispatch(fetchProductsBySpecialOfferSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchProductsBySpecialOfferFail(err))
            })
    };
};


// Get Users
export const fetchUsersBySpecialOfferSuccess = (dbUsers) => {
    return {
        type: actionTypes.FETCH_USERS_BY_SPECIALOFFER_SUCCESS,
        users: dbUsers
    };
};
export const fetchUsersBySpecialOfferStart = () => {
    return {
        type: actionTypes.FETCH_USERS_BY_SPECIALOFFER_START
    };
};

export const fetchUsersBySpecialOffer = (specialOffer) => {
    return dispatch => {
        dispatch(fetchUsersBySpecialOfferStart());
        axios.post('specialOffer/GetUsersBySpecialOffer', specialOffer)
            .then(res => {
                dispatch(fetchUsersBySpecialOfferSuccess(res.data));
            })
    };
};


export const fetchSpecialOfferLogSuccess = (log) => {
    return {
        type: actionTypes.FETCH_SPECIALOFFER_LOG_SUCCESS,
        log
    };
};
export const fetchSpecialOfferLogStart = () => {
    return {
        type: actionTypes.FETCH_SPECIALOFFER_LOG_START
    };
};

export const fetchSpecialOfferLog = (specialOffer) => {
    return dispatch => {
        dispatch(fetchSpecialOfferLogStart());
        axios.get('specialOffer/GetSpecialOfferLogs', specialOffer)
            .then(res => {
                dispatch(fetchSpecialOfferLogSuccess(res.data));
            })
    };
};


// Add Special Offer
export const insertSpecialOfferSuccess = (dbInsertedSpecialOffer) => {
    return {
        type: actionTypes.INSERT_SEPCIALOFFER_SUCCESS,
        insertedSpecialOffer: dbInsertedSpecialOffer
    };
};

export const insertSpecialOffer = (specialOffer) => {
    return dispatch => {
        axios.post('specialOffer/addSpecialOffer', specialOffer)
            .then(res => {
                dispatch(insertSpecialOfferSuccess(res.data));
                dispatch(fetchSpecialOffers());
            })
    };
};


// Delete Special Offer
export const deleteSpecialOfferSuccess = (dbDeletedSpecialOffer) => {
    return {
        type: actionTypes.DELETE_SEPCIALOFFER_SUCCESS,
        deletedSpecialOffer: dbDeletedSpecialOffer
    };
};

export const deleteSpecialOffer = (specialOffer) => {
    return dispatch => {
        axios.delete('specialOffer/deleteSpecialOffer', {data: specialOffer})
            .then(res => {
                dispatch(deleteSpecialOfferSuccess());
                dispatch(fetchSpecialOffers());
            })
    };
};


// Edit Special Offer
export const editSpecialOfferSuccess = (dbEditedSpecialOffer) => {
    return {
        type: actionTypes.EDIT_SEPCIALOFFER_SUCCESS,
        editedSpecialOffer: dbEditedSpecialOffer
    };
};

export const editSpecialOffer = (specialOffer) => {
    return dispatch => {
        axios.post('specialOffer/editSpecialOffer', specialOffer)
            .then(res => {
                dispatch(editSpecialOfferSuccess(res.data));
                axios.post('specialOffer/GetProductsBySpecialOffer', specialOffer)
                .then(res => {
                    dispatch(fetchProductsBySpecialOfferSuccess(res.data));
                })
            })
    };
};


// Change User Access
export const changeUserAccessSuccess = (dbChangedUserAccess) => {
    return {
        type: actionTypes.CHANGE_USERACCESS_SUCCESS,
        changedUserAccess: dbChangedUserAccess
    };
};

export const changeUserAccess = (specialOfferUser) => {
    return dispatch => {
        axios.post('specialOffer/handleUserAccessSpecialOffer', specialOfferUser)
            .then(res => {
                dispatch(changeUserAccessSuccess(res.data));
            })
    };
};

export const addRemoveUsersSpecialOffer = (specialOffer, afterSendSpecialOffer, onSendError) => {
    return dispatch => {
        axios.post('specialoffer/RemoveAndAddUsersToSendinblueList', specialOffer)
        .then(res => {
            console.log("Add and remove is done");
            afterSendSpecialOffer(res, specialOffer);
        })
        .catch(err => {
            onSendError(err);
        })
    };
}

export const sendCampaignSpecialOffer = (specialOffer, onSendError) => {
    return dispatch => {
        // Juste Create & Send Campaign
        axios.post('specialOffer/CreateCampaignPromo', specialOffer)
            .then(res => {
                // afterSendSpecialOffer(res, specialOffer);
                // this.setState({status: res.statusCode});
                console.log(res.data);
            })
            .catch(err => {
                console.log(err.response);
                onSendError(err.response);
            })
    };
}


export const fetchLatestSIBStatusUpdateStart = () => {
    return {
        type: actionTypes.FETCH_LATEST_SIB_STATUS_UPDATE_START
    };
};
export const fetchLatestSIBStatusUpdateSuccess = (statusUpdate) => {
    return {
        type: actionTypes.FETCH_LATEST_SIB_STATUS_UPDATE_SUCCESS,
        statusUpdate
    };
};
export const fetchLatestSIBStatusUpdateFail = (error) => {
    return {
        type: actionTypes.FETCH_LATEST_SIB_STATUS_UPDATE_FAIL,
        error
    };
};

export const fetchLatestSIBStatusUpdate = () => {
    return dispatch => {
        dispatch(fetchLatestSIBStatusUpdateStart());
        axios.get('GetLatestStatusUpdate')
            .then(res => {
                dispatch(fetchLatestSIBStatusUpdateSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchLatestSIBStatusUpdateFail(err));
            })
    };
};


export const closeInsertSnackbar = () => {
    return dispatch => {
        dispatch({type: actionTypes.CLOSE_INSERT_SNACKBAR});
    }
}
export const closeDeleteSnackbar = () => {
    return dispatch => {
        dispatch({type: actionTypes.CLOSE_DELETE_SNACKBAR});
    }
}
export const closeEditSnackbar = () => {
    return dispatch => {
        dispatch({type: actionTypes.CLOSE_EDIT_SNACKBAR});
    }
}
export const closeUserAccessSnackbar = () => {
    return dispatch => {
        dispatch({type: actionTypes.CLOSE_USERACCESS_SNACKBAR});
    }
}