import axios from 'axios';
import {getAccessToken, getAzureId} from '../app/main/Auth/Auth';

axios.interceptors.request.use(config => {
    if(config.url.indexOf('http') === -1) {
        const token = getAccessToken();
        const azureId = getAzureId();
        if (token) config.headers.Authorization = `Bearer ${token}`;
        if (azureId) config.headers.azure_id = azureId;
        config.baseURL = process.env.REACT_APP_APIURL;
        return config;
    }
    return config;
  },
  error => {
    return Promise.reject (error);
});