const en = {
    translations: {
        // Algemeen
        "tos_pp": "Terms of Service and Privacy Policy",
        "tos_pp_detail": "iCRM collects data like page visits to improve user experience",
        "login_privacy_acknowledgement": "By logging in you are agreeing to our",
        "user_agreement": "Terms of Service",
        "privacy_statement": "Privacy Policy",
        "and_the": "and our",
        "agree": "Agree",
        "disagree": "Disagree",
        "login": "Log in",
        "logout": "Log out",
        "login_to_your_account": "Log in to your account",
        "email": "Email",
        "password": "Password",
        "confirm_password": "Confirm password",
        "reset_password": "Reset password",
        "recover_your_password": "Recover your password",
        "send_reset_link": "Send reset link",
        "back_to_login": "Back to login",
        "update_your_password": "Update your password",
        "update_your_account": "Update your account",
        "update_my_account": "Update my account",
        "why_update_account": "Why do I have to update my account?",
        "why_update_account_detail": "iCRM is implementing a new login system to support the use of iCRM for external users. Please fill in this form to get your account ready for the new login system",
        "password_reset_successful": "Password reset successful! Please check your email to set up a new password",
        "min_charachter_length": "Min charachter length is",
        "enter_valid_email": "Please enter a valid email address",
        "something_went_wrong": "Something went wrong.",
        "please_try_again": "Please try again",
        "or": "or",
        "contact_us": "contact us",
        "wrong_credentials": "Wrong credentials. Please try again or reset your password.",
        "conclude": "finish",
        "conclude_explanation": "Report the task as completed. The form will be saved automatically.",
        "concluded": "Concluded",
        "archive": "Archive",
        "show_assigned_tasks": "Tasks created for someone else",
        "reopen": "Reopen",
        "false_alarm": "No issues",
        "false_alarm_explanation": "This task was generated based on data from BI. Should you suspect or should the customer indicate that nothing is wrong, please report this task as a 'No issues'. This way we can continue to improve the task system",
        "is_required": "is required",
        "concerning": "concerning",
        "UNKONWN": "unknown",
        "user_application_form": "User application form",

        "hide": "hide",

        "COPY": "Copy",
        "YEAR-1": "Year -1",
        "internet_explorer_warning": "You're viewing iCRM in Internet Explorer. Some iCRM features may not work as expected on Internet Explorer. Please consider switching to a different browser like Google Chrome, Microsoft Edge or Mozilla Firefox",
        "turnover_same_period": "Chiffre d'affaires dans les mêmes mois que cette année",
        "PAGE_STATS": "Pages stats",
        "USER_STATS": "Users stats",
        "VISITS_PER_USER": "Visits per user",

        "task_already_concluded": "This task has already been finished. Re-open this task to proceed to edit",
        "days_since_first_order": "Number of days since first order",
        "days_since_code_created": "Number of days since code creation",
        "amount_webshop_visits": "Number of webshop visits",
        "latest_webshop_visit": "Latest webshop visit",
        "no_filter": "No filter",
        "radio_filter_tooltip": "Filter the chosen indication by circle color",
        "no_indication_selected_warning": "Select an indication to use the color filter",
        "indication": "Indication",

        "latest_import": "Latest import",
        "latest_invoice": "Latest invoice",

        "fetching_live_customer_data": "Fetching live customer data",

        // BI Info
        "reasons": "Reasons",
        "ander_klantnummer": "Other customer number",
        "geen_klachten": "No complaints",
        "kwaliteit": "Quality",
        "prijs": "Price",
        "rustig_in_de_winkel": "Quiet in the store",
        "service": "Service",
        "transport": "Transport",
        "quality": "Quality",
        "webshop": "Webshop",
        "webshop_te_moeilijk": "Webshop too difficult",
        "winkel_permanent_gesloten": "Store is permanently closed",
        "winkel_tijdelijk_gesloten": "Store is temporarily closed",
        "geen_reden": "No reason",
        "anders,_namelijk...": "Other, namely...",
        "namely": "Namely...",
        "closed_until": "Closed until",

        "notes": "Notes",
        "checked_more_actions_than_allowed": "You are not allowed to choose more than 3 options at a time",
        "number_of_days_customer": "Number of days customer",
        "number_of_days_customer_explanation": "The number of days since the customer made his first order (as of 01-01-2017)",
        "total_deliveries": "Total number of deliveries",
        "total_deliveries_explanation": "The total number of deliveries to this customer",
        "days_since_last_order": "Number of days since last order",
        "days_since_last_order_explanation": "Number of days passed since this customer last ordered",
        "weeks_regular_customer": "Number of weeks regular customer",
        "weeks_regular_customer_explanation": "How many weeks in a row we consider this customer a regular customer",
        "weeks_without_orders": "Number of weeks without an order last 365 days",
        "weeks_without_orders_explanation": "This shows if it is common for a customer not to order from us for a week or several weeks",
        "max_days_without_order": "Maximum number of days without an order in the last 365 days",
        "max_days_without_order_explanation": "This indicates whether there has been a long period of time in the last 365 where the customer has not ordered from us",

        "total": "Total",
        "last_4_weeks": "Last 4 weeks",
        "last_52_weeks": "Last 52 weeks",
        "days_between_orders": "Number of days between orders",
        "differences_alltime_last10weeks_explanation": "Here we show the differences between the general ordering behavior of the customer, and his ordering behavior in the last 10 weeks",
        "days_between_orders_explanation": "If this is higher in the last 10 weeks, the customer is ordering less regularly than usual",
        "avg_invoice_amount": "Average invoice amount",
        "avg_invoice_amount_explanation": "If this is lower in last 10 weeks, the customer orders for less than what they normally do",
        "avg_articlegroups_per_order": "Average number of article groups per order",
        "avg_articlegroups_per_order_explanation": "If this is lower in last 10 weeks the customer orders for less article groups on average than what they normally do",
        "amount_of_credit_lines": "Number of credit lines",
        "amount_of_credit_lines_explanation": "This shows how many credit lines the customer has submitted in the last 10 weeks",
        "credit_sum": "The credit sum",
        "credit_sum_explanation": "This displays the total sum of credits for the total period and the last 10 weeks",
        

        "relative_changes_in_last_10_weeks": "Relative changes in the last 10 weeks",
        "relative_changes_in_last_10_weeks_explanation": "Here we compare the last 10 weeks where we compare the first half (10-5 weeks ago) with the second half (5 weeks ago to now)",
        "relative_number_of_days_customer": "Number of orders",
        "relative_number_of_days_customer_explanation": "A decrease indicates that the number of orders has decreased in the last 5 weeks compared to the previous 5 weeks",
        "relative_total_deliveries": "Average height of invoice",
        "relative_total_deliveries_explanation": "With a positive percentage, the average invoice height in the last 5 weeks is higher than in the 5 weeks before",
        "relative_weeks_regular_customer": "Average number of item groups per order",
        "relative_weeks_regular_customer_explanation": "In case of a positive percentage, the number of item groups per order in the last 5 weeks is higher than in the 5 weeks before that",

		// Monitor translations
        "own_stats_button": "Dive into your iCRM usage.",
        "VISITED_LAST_MONTH": "Visited {{amountOfPageVisits}} pages last month",
        "VISITED_LAST_MONTH_OWN_STATS": "You visited {{amountOfPageVisits}} pages last month",
        "TITLECRMUSE": "Insight into the use of iCRM",
        "SESSION_PER_DEVICE": "Sessions by device",
        "WHEN_ARE_USERS_ACTIVE": "When are users active, and what do users mainly watch",
        "WHEN_ARE_USERS_ACTIVE_OWN_STATS": "When are you active, and what pages do you primarily view?",
        "AVERAGE_VISITS_PER_HOUR": "Average number of visits per hour",
        "LIST_OF_SEARCHED_CUSTOMERS": "The user looked up these customers",
        "LIST_OF_SEARCHED_CUSTOMERS_OWN_STATS": "You've recently looked up these customers",
        "LIST_OF_CLICKED_CUSTOMERS": "The user clicked on these customers",
        "LIST_OF_CLICKED_CUSTOMERS_OWN_STATS": "You've recently clicked on these customers",
        "AMOUNT_VISITS": "Amount of visits",
        "AVERAGE_TIME_PAGE": "Average time on page",
        "NO_SEARCHED_FOUND": "No searches found",
        // Comparison blocks
        "tasks_concluded_user": "more tasks completed than average",
        "tasks_concluded_user_negative": "less tasks completed than average",
        "tasks_concluded_avg": "tasks completed",
        "task_concluded_speed_user": "faster tasks completed than average",
        "task_concluded_speed_user_negative": "less quickly tasks completed than average",
        "task_concluded_speed_avg": "days",
        "notes_left_user": "more customer notes posted than average",
        "notes_left_user_negative": "less customer notes posted than average",
        "notes_left_avg": "customer notes placed",
        "leads_exported_user": "more leads exported than average",
        "leads_exported_user_negative": "less leads exported than average",
        "leads_exported_avg": "leads exported",
        "leads_created_user": "more leads created than average",
        "leads_created_user_negative": "less leads created than average",
        "leads_created_avg": "leads created",
        "pages_visited_user": "more pages visited than average",
        "pages_visited_user_negative": "less pagina's bezocht dan gemiddeld",
        "pages_visited_avg": "pages visited",
        "leads_validated_speed_user": "faster validating leads than average",
        "leads_validated_speed_user_negative": "leads handled less quickly than average",
        "leads_validated_speed_avg": "days",
        "average": "Average",
        "avg_comparison_title": "Compare users to average CRM use",
        "avg_comparison_title_own_stats": "How do you use iCRM compared to other users?",
		// block 1
        "PAGE_VISITED": "Pages visited",
        "OPENED_CUSTOMERS": "Customers unlocked",
        "CLOSED_CUSTOMERS": "Customers closed",
        "GENERATED_INCASSO": "Direct debits generated",
        "EMAILED_OPENPOST": "Unpaid sent",
        "OPENED_DEPARTURE_DAYS": "Departure days open",
        "SEARCHED_CUSTOMERS": "Searched customers",
        "AVG_TIME_PER_PAGE": "Avg. time per page",
		// Block 2
        "TASKS_DONE": "Tasks done",
        "OPEN_TASKS": "Open tasks",
        "TASK_CREATION_COMPLETION": "Days between task creation and completion",
        "NOTES_WRITTEN": "Notes written",
        "SENT_SPECIAL_OFFERS": "Special offers sent",
        "NEWLEADS_CREATED": "New leads created",
        "NEWLEADS_VALIDATED": "New Leads validated",
        "NEWLEADS_EXPORTED": "New Leads exported",
        "DAYS_BET_NEWLEADS_CREATED_EXPORTED": "Days between creation and export",
        "USER_ACTIVITY_PER_HOUR": "Activity per hour",
		
        // Minor BI Data translations
        "last_year": "Past year",
        "last_month": "Past month",        
        "last_year_last_month_explanation": "Here we show the differences between the customer's general ordering behavior, and his ordering behavior in the past month",
        "amount_of_orders": "Number of orders",
        "avg_invoice_turnover": "Average invoice height",
        "avg_amount_of_article_groups_per_order": "Average number of item groups per order",
        "credit_ratio": "Credit ratio",
        "credit_ratio_explanation": "The credit ratio is the percentage of the expenditure that is credited by the customer",
        "avg_orders_per_week": "Average number of orders per week",
        "risk_of_leaving": "Risk of leaving",
        "customer_since": "Customer since",

        "invoice_amount": "Invoice amount",
        "amount_article_groups": "Amount of article groups",
        "amount_articles": "Amounts of articles",
        "amount_stems": "Amount of stems",
        "invoice_paid": "Invoice paid",

        "open_expired_tasks": "Note! there are expired tasks that have not yet been completed.",
        "go_to_tasks": "Return to tasks",

        "good": "Good",
        "mediocre": "Mediocre",
        "bad": "Bad",
        "existing_customer": "Existing customer",

        // Specifiek
        "CREATED_TASK_ERROR": 'Something went wrong. Are you sure the customer number is correct?',
        "NOINTERACTIONYET": "This task hasn't had any interactions yet",
        "OVERDUE": "Overdue",
        "ACTIONSTODOBI": "Actions",
        "ACTIONSTODOBI_TOOLTIP": "Leave this field blank if no follow-up action is needed",
        "ACTIONSTODOBI_TABLE_TOOLTIP": "Follow-up actions",
        "CALLOVERVIEWBI": "Customer phone call summary",
        "BIINFORMATIONS": "Customer data from BI",
        "TIMELINE": "Timeline",
        "CHOOSE": "Choose",
        "FILLIN": "Fill in a",
        "TITLE": "Title",
        "TASKUPDATEDSUCCESS": "The task has been successfully updated",
        "TASKCONCLUDEDSUCCESS": "The task has been successfully concluded",
        "TASKREOPENEDSUCCESS": "The task has been successfully reopened",
        "TASKCREATEDSUCCESS": "The task has been successfully created, you'll be redirected to the task page",
        "TASK": "Task",
        "TASKS": "Tasks",
        "CREATENEWTASK": "Create new task",
        "TYPE": "Type",
        "NO_TYPE": "No type",
        "REPONDREEMAIL": "Reply by Email",
        "CALLBACK": "Callback",
        "URGENCY": "Urgent",
        "OBJECTIVE": "Goal",
        "OPTIONS": "Options",
        "MOREOPTIONS": "More options",
        "ACTIONS_CHECKLIST": "Actions checklist",
        "ADD_ACTION": "Add action",
        "FILL_NEW_ACTIONS": "Fill in a new action",

        "financial_groups_distribution_title": "Turnover distribution among the financial groups",
        "financial_groups_distribution_hbxdeco_title": "Commercial effort among the financial groups",
        "margin_distribution_title": "distribution of margins to target groups",

        "ADD": "Add",
        "NEW": "New",
        "USER": "User",
        "EMAIL": "Email",
        "NOTIFICATIONS": "Meldingen",
        "NAME": "Name",
        "ORDERS": "Orders",
        "HAVEORDERED": "Have ordered",
        "VISITE": "Visited",
        "LOADMORE": "Load more",
        "DAYS": "Days",
        "CREATED": "Created",
        "EXPORTED": "Exported",
        "CREATE": "Create",
        "CUSTOMERS": "Customers",
        "ADDRESS": "Address",
        "CITY": "City",
        "ZIPCODE": "Zip code",
        "COUNTRY": "Country",
        "SHOP": "Shop",
        "OWNER": "Owner",
        "PHONE": "Phone",
        "MOBILE": "Mobile",
        "VAT": "VAT",
        "DOESTHECUSTOMERHAVEAVATNUMBER": "Does the customer have a VAT number ?",
        "KVK": "KvK",
        "TRANSPORTER": "Transporter",
        "PASSWORD": "Password",
        "LOGTIME": "Connection",
        "KOPEN": "Last order",
        "DIFFERENCE": "Difference",
        "REFRESH": "Refresh",
        "OLD": "old / old",
        "WEEK": "Week",
        "BLOCK1": "New customers created until 7 days ago",
        "BLOCK2": "Last invoice is from -5 to -7 week days",
        "BLOCK3": "Last invoice is from -10 to -14 week days",
        "BLOCK4": "Last invoice is from -20 and -30 week days",
        "BLOCK5": "Customers don't reach their minimum",
        "BLOCK6": "Customers that only have 1 invoice in history",
        "TOOLTIP": "This block shows all customers where ",
        "NEWNLOSTCUSTOMERS": "Amount of new and lost customers",
        "ACTIVECUSTOMERS": "Active customers",
        "ACTIVECUSTOMERSTOOLTIP": "We count all distinct customers",
        "QTYORDERS": "Quantity of orders",
        "QTYORDERSTOOLTIP": "We count all positive invoices",
        "EXECUTE": "Execute",
        "LASTDATE": "Last date",
        "DESCRIPTION": "Description",
        "AMOUNT": "Amount",
        "CUMULATED": "Cumulated",
        "VALIDATE": "Validate",
        "VALIDATED": "Validated",
        "MAILCOUNT": "Duplicate mails",
        "PRELEVEMENT": "Prelevement",
        "INVOICED": "Invoiced",
        "CREDIT": "Credit",
        "DATE": "Date",
        "LOADCAMPAIGNS": "Load campaigns",
        "DEPARTURE": "Departure",
        "TODAY": "Today",
        "TURNOVERPERCATEGORIES": "Turn over per category",
        "WEEKLYORDERFREQUENCY": "Weekly order frequency",
        "WEEKLYTURNOVER": "Weekly turn over chart",
        "STARTDATE": "Start date",
        "ENDDATE": "End date",
        "COUNTINVOICES": "Amount of invoices",
        "OPENSTAAND": "Number of outstanding weeks",
        "AVGORDERGROOTTE": "Average ordersize",
        "DYNAMICSEARCHTEXT": "With dynamics input fields you are able to search with operators like > (greater than), <(smaller than). You will recognize these fields by the blue borders.",
        "DYNAMICSEARCH": "This is a dynamic input",
        "LAST90DAYS": "Last 90 days",
        "LAST60DAYS": "Last 60 days",
        "LAST30DAYS": "Last 30 days",
        "YESTERDAY": "Yesterday",
        "THISWK": "This week",
        "THISMT": "This month",
        "THISYR": "This year",
        "LASTWK": "Last week",
        "LASTMT": "Last month",
        "LASTYR": "Last year",
        "DEFAULTDT": "Default date",
        "WKPERORD": "Weeks per order",
        "APPLY": "Apply",
        "CHANGEPASS": "Change password",
        "MONDAY": "M",
        "TUESDAY": "T",
        "WEDNESDAY": "W",
        "THURSDAY": "T",
        "FRIDAY": "F",
        "INVOICES": "Invoices",
        "CALL": "Call",
        "CALLLIST": "Call List",
        "INBOX": "Inbox",
        "VISIT": "Visit",
        "DELIVERY": "Delivery",
        "FILLING": "Filling",
        "ORDERFREQ": "Order frequency",
        "STELEN": "Number of stems",
        "PLANTS": "Plants",
        "FLOWERS": "Flowers",
        "OTHERS": "Others",
        "AMNTCLIENTACTIVE": "Active clients",
        "WEBLOG": "(Amount visited pages per day)",
        "BOUGHT": "Bought",
        "STOCK": "Stock",
        "ORDERLIST": "Order list",
        "WEBLOG30DAYS": "Amount of pages visited the last 30 days",
        "TURNOVER": "Turn over",
        "PREVIOUSDEPARTUREDAYS": "Previous departure days",
        "INVOICEHISTORY": "Invoice history",
        "INVOICE": "Invoice",
        "PAID": "Paid",
        "DEPARTUREHISTORY": "Departure history",
        "MAXDAY": "Max days",
        "LASTINVOICE": "Last invoice",
        "CLOSED": "Closed",
        "CUSTOMERSTOVALIDATE": "Customers to validate",
        "SEND": "Send",
        "SAVE": "Save",
        "PREVIEW": "Preview",
        "DOWNLOAD": "Download",
        "CANCEL": "Cancel",
        "NEXT": "Next",
        "DELETE": "Delete",
        "LOGIN": "Login",
        "PASSWORDFORGOTTEN": "Forgotten password",
        "EDIT": "Edit",
        "RETURN": "Send back",
        "HOME": "Home",
        "CUSTOMER": "Customer",
        "USERS": "Users",
        "RAPPORT": "Notes Overview",
        "NEWCUSTOMERS": "New customers",
        "OVERVIEW": "Overview",
        "UNPAID": "Unpaid",
        "HEADER": "Navigation",
        "DASHBOARD": "Dashboard",
        "CALENDAR": "Calendar",
        "USERACCESS": "User access",
        "UNPAIDINVOICES": "Unpaid invoices",
        "SPECIALOFFER": "Special offer",
        "SPECIALOFFERS": "Special offers",
        "MANAGE": "Manage",
        "VISITORS": "Visitors",
        "VISITORSBYDAY": "Visitors by day over the last month",
        "USERNAME": "Username",
        "MESSAGE": "Memo",
        "ADDMESSAGE": "Leave a message",
        "COMPANY": "Company",
        "SETTINGS": "Settings",
        "PAYTERM": "Payment term",
        "FINANCIELTYPE": "Financial type",
        "COMMERCIALTYPE": "Commercial type",
        "DOWNLOADBACKORDERS": "Download back orders",
        "show_backorders": "Show back order overview",
        "BUYLIMIT": "Buy limit",
        "CREDITLIMIT": "Credit limit",
        "OPEN": "Open",
        "CLOSE": "Close",
        "NOCOMMENTS": "No comments available",
        "SEARCH": "Search",
        "GLOBALSEARCH": "Search all...",
        "ASSIGNNEWLEADS": "Assign new leads",
        "NEWLEAD": "New lead",
        "PROFILE": "Profile",
        "CHOOSELANGUAGE": "Choose your language",
        "CHANGEPASSWORD": "Change your password",
        "RESETFORM": "Reset form",
        "ASSIGNED": "Assigned",
        "ADDTEMPLATESTOYOURLIST": "Add templates to your favorites list",
        "NEWLEADARCHIVEINFO": "When archiving a new lead it will not be imported in Florisoft",
        "DELETEDNEWLEADSUCCESS": "The new lead has been successfully deleted",
        "ALREADYEXPORTERNEWLEAD": "This lead as already been exported",
        "NEWCUSTOMERSDIALOGINFO": "In order to validate this customer, all the fields above must be completed in Florisoft",
        "WELCOME_DISCOUNT": "Welcome discount",
        "MULTIPLEEMAILS": "Multiples Emails found for this customer",
        "MULTIPLEVAT": "Multiples clients with this VAT were found",
        "NEWLEADALREADYVALIDATED": "This lead has already been validated",
        "NEWLEADVIESFIRSTCREATENEWLEAD": "First check the VIES information to see if this match with the customers details",
        "WEBSHOPACCESS": "Webshop access",
        "UPDATEDSUCCESS": "Successfully updated",
        "UPDATEDFAILED": "An error occurred please contact us",
        "CREATEDSUCCESS": "Successfully created",
        "YOURFAVTEMPLATES": "Your favorites templates",
        "LEADS": "Leads",
        "ACCOUNTMNGR": "Account Manager",
        "NOTE": "Intern note",
        "AREYOUSURE": "are you sure ?",
        "BY": "by",
        "YES": "yes",
        "NO": "no",
        "CHECK": "Check",
        "SHOPNAMEFORINVOICE": "Shop name on the invoice",
        "OPENROUTE": "Open extra departure day",
        "MOSTBOUGHTPRODUCTS": "Top 10 most bought products",
        "PRODUCT": "Product",
        "TIMESBOUGHT": "Times bought",
        "TOTALSTEMS": "Total stems",
        "AVGPRICE": "Avg. Price",
        "CREDITVERDELING": "Distribution of credits among credit reasons",
        "SHOWLEGEND": "Show Legend",
        "HIDELEGEND": "Hide Legend",
        "WEBSHOP_LANGUAGE": "en-gb",
        "MULTIPLEDATALEAD": "Duplicate Emails",
        "MULTIPLEVATDATALEAD": "Duplicate VAT",
        "DEBTEXTFIELD"  : "Debtor Note",
        "DEBTEXTPLACEHOLDER" : "separate invoice email, delivery instructions, etc",
        "creation_date": "Creation date",
        "CON_SUCCESS": "Connection successful",
        "CON_UNSUCCESS": "Connection unsuccessful !",
        "LOGIN_HISTORY": "Connection history",
        "REGION": "Region",
        "FILTER": "Filter",
        "FILTER_UNSUCCESSSFUL": "Filter Out unsuccessful",
        "TOO_MANY_WRONG_PASSWORDS_ACCOUNT_LOCKED": "Too many wrong password, account is locked for 5 minutes",
        "LOGIN_ATTEMPTS": "Login attempts",
        "VISITS": "Visits",
        "DEBFINGRP" : "Customer type"
      }
};
export default en;