import React, {Component} from 'react';
import {Doughnut} from 'react-chartjs-2';
import _ from 'lodash';

class DonutChart extends Component {
    state = {
        currentRange: this.props.data.currentRange
    };

    handleChangeSelect = (ev) => {
        this.setState({[ev.target.name]: ev.target.value});
    };

    render()
    {
        const {data: dataRaw} = this.props;
        const {currentRange} = this.state;
        const data = _.merge({}, dataRaw);

        return (
            <Doughnut
                data={{
                    labels  : data.mainChart.labels,
                    datasets: data.mainChart.datasets[currentRange]
                }}
                // style={{height: '100%', width: '100%'}}
                // width={"100%"}
                // height={"100%"}
                options={data.mainChart.options}
            />
        );
    }
}

export default DonutChart;

export const donutData = {
    'title'       : 'Task Distribution',
    'ranges'      : {
        'TW': 'This Week',
    },
    'currentRange': 'TW',
    'mainChart'   : {
        labels  : [
            'C&C',
            'Internet',
            'API',
        ],
        datasets: {
            'TW': [
                {
                    data                : [1, 1, 0],
                    backgroundColor     : [
                        // '#f44336',
                        // '#9c27b0',
                        // '#03a9f4',
                        // '#e91e63',

                        '#ff1744',

                        '#d500f9',

                        '#651fff',

                        '#00b0ff',

                        '#1de9b6',

                        '#b2ff59',

                        '#ffea00',

                        '#ff9100',

                        '#90a4ae',
                        
                        '#3f51b5',
                        
                        '#795548',
                        
                        '#00897b',
                        
                        '#bdbdbd',
                        '#390099',
                        '#9E0059',
                        '#FF0054',
                        '#FF5400',
                        '#FFBD00',
                        '#3C1642',
                        '#086375',
                        '#1DD3B0',
                        '#AFFC41',
                        '#B2FF9E',
                        '#50514F',
                        '#F25F5C',
                        '#FFE066',
                        '#247BA0',
                        '#70C1B3',
                        // '#390099',
                        // '#390099',
                        // '#390099',
                        // '#390099',
                    ],
                    hoverBackgroundColor: [
                        // '#f45a4d',
                        // '#a041b0',
                        // '#25b6f4',
                        // '#e9487f',
                        
                        '#ff616f',

                        '#ff5bff',

                        '#a255ff',

                        '#69e2ff',

                        '#6effe8',

                        '#e7ff8c',

                        '#ffff56',

                        '#ffc246',

                        '#c1d5e0',

                        '#757de8',

                        '#a1887f',

                        '#26a69a',

                        '#e0e0e0',
                        '#7c5bb6',
                        '#da8cb8',
                        '#f790b2',
                        '#fab492',
                        '#f5dc93',
                        '#955f9e',
                        '#61a8b6',
                        '#92e7d7',
                        '#e4fac5',
                        '#eeffea',
                        '#b4b4b4',
                        '#fcadac',
                        '#81b8cf',
                        '#6198af',
                        '#aee1d8',
                    ]
                }
            ],
        },
        // plugins: [ChartDataLabels],
        options : {
            spanGaps           : false,
            legend             : {
                display : true,
                position: 'bottom',
                labels  : {
                    padding      : 8,
                    usePointStyle: true,
                    boxWidth: 20
                },
                fullWidth: true,
            },
            maintainAspectRatio: false,
            tooltips: {
                callbacks: {
                  label: function(tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = parseFloat((currentValue/total*100).toFixed(1));
                    return Number(currentValue).toLocaleString('nl-NL') + ' (' + percentage + '%)';
                  },
                  title: function(tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                  }
            }
            },
            plugins: {
                labels: {
                    render: ({label, value, percentage}) => {
                        if(percentage < 5) return '';
                        return '€ ' + Number(value).toLocaleString('nl-NL');
                    },
                    // render: 'value',
                    fontColor: ['black', 'black', 'black', 'black', 'black', 'black', 'black', 'black', 'black', 'black', 'black', 'black', 'black', 'black', 'black', 'black', 'black', ]
                }
            }
        }
    },
};

export const donutColors = {
    groupBackgroundColors     : {
        'Onbekend': '#ff1744',
        'Bloemist': '#d500f9',
        'Groothandel': '#651fff',
        'Ketens': '#00b0ff',
        'Tuincentrum': '#1de9b6',
        'Verwerkingsbedrijf': '#b2ff59',
        'Woonwinkel': '#ffea00',
        'Intercompany': '#ff9100',
        // '#90a4ae',
        // '#3f51b5',
        // '#795548',
        // '#00897b',
        // '#bdbdbd',
    },
    groupHoverBackgroundColors: {
        'Onbekend': '#ff616f',
        'Bloemist': '#ff5bff',
        'Groothandel': '#a255ff',
        'Ketens': '#69e2ff',
        'Tuincentrum': '#6effe8',
        'Verwerkingsbedrijf': '#e7ff8c',
        'Woonwinkel': '#ffff56',
        'Intercompany': '#ffc246',
    },
    backgroundColor     : [

        '#ff1744',

        '#d500f9',

        '#651fff',

        '#00b0ff',

        '#1de9b6',

        '#b2ff59',

        '#ffea00',

        '#ff9100',

        '#90a4ae',

        '#3f51b5',

        '#795548',

        '#00897b',

        '#bdbdbd',
        '#390099',
    ],
    hoverBackgroundColor: [
        
        '#ff616f',

        '#ff5bff',

        '#a255ff',

        '#69e2ff',

        '#6effe8',

        '#e7ff8c',

        '#ffff56',

        '#ffc246',

        '#c1d5e0',

        '#757de8',

        '#a1887f',

        '#26a69a',

        '#e0e0e0',
        '#390099',
    ]
};
