import React from 'react';
// import {Line} from 'react-chartjs-2';
import moment from 'moment';
import themesConfig from '../../../../../fuse-configs/themesConfig';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import {
    ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
    Legend, ResponsiveContainer, LabelList
  } from 'recharts';
import { useTranslation } from 'react-i18next';

const Dot = styled.span`
    height: 15px;
    width: 15px;
    background-color: rgb(138, 255, 143);
    border-radius: 50%;
    display: inline-block;
`

const renderLabel = (prop, dataKey, data) => {
    const index = prop.index;
    const target = data[index];
    const invoice = target.invoices
  if(invoice > 0) {
      return invoice;
  }
};

function TaskDetailWeblog(props) {
    const { t } = useTranslation();
    const {weblogVisits} = props;
    if(!weblogVisits) return null;

    const data = weblogVisits.map(v => ({
        name: moment(v.date).format('MMM Do'),
        invoices: v.bought,
        visits: v.visits
    }));
    
    return (
        <Paper className="w-full">
            <div className='h-64' style={{backgroundColor: themesConfig.default.colors[0], borderRadius: '0.8rem .8rem 0rem 0rem', lineHeight: '64px', color: 'white'}}>
                <span className='m-16'>{t('WEBLOG30DAYS')}</span>
            </div>
            <div className='h-256 pt-12'>
            {/* <Line data={chartData} height={160} options={{
                maintainAspectRatio: false, 
                legend: {
                    display: false
                }}} 
            /> */}            
                <ResponsiveContainer width="99%">
                    <ComposedChart
                        width={450}
                        height={375}
                        data={data}
                        margin={{
                            top: 20, right: 20, bottom: 20, left: 20,
                        }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="name" name="Months" />
                        <YAxis yAxisId="left" dataKey="visits" name="Visits" />
                        <YAxis yAxisId="right" orientation="right" dataKey="invoices" />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="invoices" name={t('INVOICE')} barSize={12} fill="rgb(138, 255, 143)" yAxisId="right">
                            <LabelList
                                content={props => renderLabel(props, 'invoices', data)}
                                position="top"
                            />
                        </Bar>
                        <Line type="monotone" name={t('VISIT')} dataKey="visits" stroke={themesConfig.default.colors[0]} strokeWidth={2} dot={false} yAxisId="left" />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </Paper>
    )
}

export default TaskDetailWeblog;

