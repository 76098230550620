import {updateObject} from '../../utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    unpaid: [],
    loading: true,
}

// Unpaid //

const fetchDbUnpaidSuccess = ( state, action ) => {
    return updateObject( state, {unpaid: action.unpaid, loading: false} );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.FETCH_UNPAID_SUCCESS: return fetchDbUnpaidSuccess( state, action );

        default: return state;
    }
};

export default reducer;