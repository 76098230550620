import React from 'react';
import {withStyles, Card, Typography} from '@material-ui/core';
import {Bar} from 'react-chartjs-2';

const BarChart = ({data, title }) => {

    return (
        <Card style={{height: 400}} className="w-full rounded-8 shadow-none border-1">
            <div className="pt-16 pl-8 pb-0 flex flex-row flex-wrap items-end">
                <Typography component="h2">{title}</Typography>
            </div>

            <Typography style={{height: 350}} className="relative sm:h-320 sm:pb-16">
                <Bar data={data} options={data.options}/>
            </Typography>
        </Card>
    );
};

export const BarChartOnly = ({ data }) => (
        <Bar data={data} options={data.options}/>
)

export default withStyles(null, {withTheme: true})(BarChart);

export const barData = {
    conversion: {
        value   : 492,
        ofTarget: 13
    },
    chartType : 'bar',
    datasets  : [
        {
            label: 'Conversion',
            data : [221, 428, 492, 471, 413, 344, 294]
        }
    ],
    labels    : ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    // options   : {
    //     spanGaps           : false,
    //     legend             : {
    //         display: false
    //     },
    //     maintainAspectRatio: false,
    //     layout             : {
    //         padding: {
    //             top   : 24,
    //             left  : 16,
    //             right : 16,
    //             bottom: 16
    //         }
    //     },
    //     scales             : {
    //         xAxes: [
    //             {
    //                 display: false
    //             }
    //         ],
    //         yAxes: [
    //             {
    //                 display: false,
    //                 ticks  : {
    //                     min: 100,
    //                     max: 30000
    //                 }
    //             }
    //         ]
    //     }
    // }
    options   : {
        plugins: {
            labels: false
        },
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function(value, index, values) {
                    return  '€' + value.toLocaleString('nl-NL')
                }
              },
            },
          ],
        },
    }
};