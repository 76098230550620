import React, { Component } from "react";
import { Typography, Card,  CardContent, Toolbar, AppBar, Tooltip, Icon, FormControlLabel, Checkbox } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import {Skeleton} from '@material-ui/lab';

class TaskDetailTimeLineSkeleton extends Component {
    // shouldComponentUpdate(nextProps, nextState) {
    //     return nextProps.timeLine.length !== this.props.timeLine.length;
    // }

    render() {
        const { t } = this.props;

        return (<Card className="w-full rounded-8 shadow-none border-1">
        <AppBar position="static" elevation={0}>
            <Toolbar>
                <Typography>{t("EDIT") + " " + t("TASK")}</Typography>
            </Toolbar>
        </AppBar>
        <CardContent>
            <div className="p-12">
                <div className="flex">
                    <div className="min-w-48 pt-20">
                        <Tooltip title={t("FILLIN") + " " + t("TITLE")}>
                            <Icon color="action">title</Icon>
                        </Tooltip>
                    </div>
                    <Skeleton className="mb-24" variant="text" width={'100%'} height={85} style={{margin: '0 auto'}} />
                </div>

                <div className="flex">
                    <div className="min-w-48 pt-20">
                        <Tooltip title={t("FILLIN") + " " + t("DESCRIPTION")}>
                            <Icon color="action">notes</Icon>
                        </Tooltip>
                    </div>
                    <Skeleton className="mb-24" variant="text" width={'100%'} height={85} style={{margin: '0 auto'}} />
                </div>
                <div className="flex">
                    
                    <div className="flex w-full">
                        <div className="min-w-48 pt-20">
                            <Tooltip title={t("FILLIN") + " " + t("CUSTOMER")}>
                                <Icon color="action">contacts</Icon>
                            </Tooltip>
                        </div>
                        <Skeleton className="mb-24" variant="text" width={'100%'} height={85} style={{margin: '0 auto'}} />
                    </div>
                    <div className="flex w-full">
                        <div className="pt-20 md:ml-16 mr-12">
                            <Tooltip title={t("FILLIN") + " " + t("ACCOUNTMNGR")}>
                                <Icon color="action">supervisor_account</Icon>
                            </Tooltip>
                        </div>
                        <Skeleton className="mb-24" variant="text" width={'100%'} height={85} style={{margin: '0 auto'}} />
                    </div>
                </div>

                <div className="flex">
                    <div className="flex w-full">
                        <div className="min-w-48 pt-32">
                            {/* <Tooltip title="Kies een datum waarop de taak van start gaat"> */}
                                <Icon color="action">date_range</Icon>
                            {/* </Tooltip> */}
                        </div>
                        <Skeleton className="mb-24" variant="text" width={'100%'} height={85} style={{margin: '0 auto'}} />
                    </div>
                    <div className="flex w-full">
                        <div className="pt-32 md:ml-16 mr-12">
                            {/* <Tooltip title="Kies een datum waarop de taak verloopt"> */}
                                <Icon color="action">event</Icon>
                            {/* </Tooltip> */}
                        </div>
                        <Skeleton className="mb-24" variant="text" width={'100%'} height={85} style={{margin: '0 auto'}} />
                    </div>
                </div>
                <React.Fragment>                    
                    <div className="flex">
                        <div className="flex w-5/6">
                            <div className="min-w-48 pt-20">
                                <Tooltip title={t("CHOOSE") + " type"}>
                                    <Icon color="action">art_track</Icon>
                                </Tooltip>
                            </div>
                        <Skeleton className="mb-24" variant="text" width={'100%'} height={85} style={{margin: '0 auto'}} />
                        </div>
                        <div className="mt-16 ml-12">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={false}
                                        onChange={this.handleCheckboxChange}
                                        name="urgency"
                                        color="primary"
                                    />
                                }
                                style={{ fontSize: 8 }}
                                label={t("URGENCY")}
                                labelPlacement="top"
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Tooltip title={t("FILLIN") + " " + t("OBJECTIVE")}>
                                <Icon color="action">notes</Icon>
                            </Tooltip>
                        </div>
                    <Skeleton className="mb-24" variant="text" width={'100%'} height={85} style={{margin: '0 auto'}} />
                    </div>

                    <div className="pl-48">
                        <div>
                            <Typography className="text-16 font-light">
                                {t("OPTIONS")}
                            </Typography>
                        </div>
                    </div>
                    <div className="flex pt-12">
                        <div className="min-w-48 pt-20">
                            <Tooltip title={t("ADDMESSAGE")}>
                                <Icon color="action">insert_comment</Icon>
                            </Tooltip>
                        </div>
                    <Skeleton className="mb-24" variant="text" width={'100%'} height={45} style={{margin: '0 auto'}} />
                    <Skeleton className="mb-24" variant="text" width={'100%'} height={45} style={{margin: '0 auto'}} />
                    </div>
                </React.Fragment>
            </div>
        </CardContent>
    </Card>
        );
    }
};

export default withTranslation()(TaskDetailTimeLineSkeleton);