import React, { useState } from 'react';
import {Typography, FormControlLabel, Switch, Tooltip, Chip, Paper, withStyles, Button} from '@material-ui/core';
import PieChart, {pieData} from '../../../../../components/Widgets/PieChart';
import DonutChart, {donutData} from '../../../../../components/Widgets/DonutChart';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {Skeleton} from '@material-ui/lab';

const styles = (theme) => ({
    chipBlue: {
        backgroundColor: '#03a9f4',
        cursor: 'default !important'
    },
    chipBronze: {
        backgroundColor: '#ef5350',
        cursor: 'default !important'
    },
    chipSilver: {
        backgroundColor: '#c5cae9',
        cursor: 'default !important'
    },
    chipGold: {
        backgroundColor: '#ffca28',
        cursor: 'default !important'
    },
    chipPlatinum: {
        backgroundColor: '#e0e0e0',
        cursor: 'default !important'
    },
});

const DashboardDecoMiddleCharts = props => {
    const {t} = useTranslation();
    const [toggled, setToggled] = useState(true);
    // const [showFinancialGroupsLegend, setShowFinancialGroupsLegend] = useState(false);
    const [showCreditLegend, setShowCreditLegend] = useState(false);
    const { classes, orderFrequency, verdelingen } = props;    

    let redenVerdelingDonut = _.cloneDeep(donutData);
    if(verdelingen && verdelingen.length > 0) {
        const labels = [];
        const dataset = [];
        verdelingen.forEach(t => labels.push(t.reden) && dataset.push(-t.creditBedrag));
        
        redenVerdelingDonut['mainChart'].datasets['TW'][0].data = dataset;
        redenVerdelingDonut['mainChart'].labels = labels;
        // redenVerdelingDonut['mainChart'].options.respons = 'right';
        redenVerdelingDonut['mainChart'].options.legend.display = showCreditLegend;
        redenVerdelingDonut.title = 'Creditreden verdeling';
    }
    
    // let financialGroupsDonutData = null;
    // if(financialGroups) {
    //     financialGroupsDonutData = _.clone(donutData);
    //     const labels = [];
    //     const dataset = [];
    //     props.financialGroups.map(t => labels.push(t.name) && dataset.push(t.value.toFixed(0)));
        
    //     financialGroupsDonutData['mainChart'].datasets['TW'][0].data = dataset;
    //     financialGroupsDonutData['mainChart'].labels = labels;
    //     financialGroupsDonutData['mainChart'].options.legend.display = showFinancialGroupsLegend;
    //     financialGroupsDonutData.title = 'Turnover Distribution over financial groups';
    // }

    const toggledData = orderFrequency ? (toggled ? orderFrequency.online : orderFrequency.persoonlijk) : null;
    let frequencySum = null;
    let orderFrequencyList = null;
    let frequencyPieChart = null;
    if(toggledData) {
        const frequencyData = _.cloneDeep(pieData);
        orderFrequencyList = Object.keys(toggledData).map(key => toggledData[key]);
        frequencyData['title'] = 'Weekly Order Frequency';
        frequencyData['mainChart'].datasets[0].data = orderFrequencyList;
        frequencyData['mainChart'].datasets[0].backgroundColor = ['#03a9f4', '#ef5350', '#c5cae9', '#ffca28', '#e0e0e0'];
        frequencyData['mainChart'].datasets[0].hoverBackgroundColor = ['#6ff9ff', '#ff867c', '#f5f5f5', '#fffd61', '#f5f5f5'];
        frequencyPieChart = <PieChart data={frequencyData} />
        frequencySum = _.sum(orderFrequencyList);
    }

    return (        
        <div className="flex flex-wrap">
            <div className="widget flex w-full sm:w-full md:w-1/2 p-12">
                <Paper className="w-full rounded-8 shadow-none border-1">
                    <div className="flex items-center justify-between px-16 h-64 border-b-1">
                        <Typography className="text-16">{t("CREDITVERDELING")}</Typography>
                        {/* <Tooltip title="This chart doesn't change after changing dates">
                            <Icon>cloud_off</Icon>
                        </Tooltip> */}
                    </div>
                    <div className={(showCreditLegend ? "h-640" : "h-400") + " w-full p-24 text-center"}>
                        {verdelingen ? 
                            <>
                            <DonutChart data={redenVerdelingDonut} />
                            {showCreditLegend ? 
                                <Button color="primary" onClick={() => setShowCreditLegend(false)}>{t('HIDELEGEND')}</Button> :
                                <Button color="primary" onClick={() => setShowCreditLegend(true)}>{t('SHOWLEGEND')}</Button>
                            }
                            </>
                        : 
                            <>
                                <Skeleton className="rounded m-auto" variant="rect" width={'60%'} height={250} />
                                <Skeleton className="mt-12 m-auto" variant="text" width={240} />
                            </>
                        }
                    </div>
                </Paper>             
            </div>
            <div className="widget flex w-full sm:w-full md:w-1/2 p-12">
                <Paper className="w-full rounded-8 shadow-none border-1">
                    <div className="flex items-center justify-between px-16 h-64 border-b-1">
                        <Tooltip title="Turnover grouped by amount of invoices per week"><Typography className="text-16">{t("WEEKLYORDERFREQUENCY")}</Typography></Tooltip>
                        <div className="float-right">
                            <FormControlLabel
                            control={
                                <Switch
                                checked={toggled}
                                onChange={() => setToggled(!toggled)}
                                color="primary"
                                />
                            }
                            label={toggled ? 'Online' : 'Persoonlijk'}
                            />
                        </div>
                    </div>
                    <div className="h-250 w-full p-24 border-b-1">
                        {frequencyPieChart}
                    </div>
                    <div className="p-0">
                        {toggledData && orderFrequencyList && frequencySum !== 0 ? 
                        <>
                        <table className={"simple"}>
                            <tbody>
                                <tr>
                                    <td><Chip label="Blue" className={classes.chipBlue} /></td>
                                    <td>Approximately 1 order per 4 weeks</td>
                                    <td>
                                        <Typography variant="body2">
                                        {orderFrequencyList[0]}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">                                
                                        <strong>{(orderFrequencyList[0] / frequencySum * 100).toFixed(0)}%</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td><Chip label="Bronze" className={classes.chipBronze} /></td>
                                    <td>Approximately 1 order per 3 weeks</td>
                                    <td>
                                        <Typography variant="body2">
                                        {orderFrequencyList[1]}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">                                
                                        <strong>{(orderFrequencyList[1] / frequencySum * 100).toFixed(0)}%</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td><Chip label="Silver" className={classes.chipSilver} /></td>
                                    <td>Approximately 1 order per 2 weeks</td>
                                    <td>
                                        <Typography variant="body2">
                                        {orderFrequencyList[2]}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">                                
                                        <strong>{(orderFrequencyList[2] / frequencySum * 100).toFixed(0)}%</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td><Chip label="Gold" className={classes.chipGold} /></td>
                                    <td>Approximately 1 order per week</td>
                                    <td>
                                        <Typography variant="body2">
                                        {orderFrequencyList[3]}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">                                
                                        <strong>{(orderFrequencyList[3] / frequencySum * 100).toFixed(0)}%</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td><Chip label="Platinum" className={classes.chipPlatinum} /></td>
                                    <td>more than 1 order per week</td>
                                    <td>
                                        <Typography variant="body2">
                                        {orderFrequencyList[4]}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">                                
                                        <strong>{(orderFrequencyList[4] / frequencySum * 100).toFixed(0)}%</strong>
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </>
                        : null}
                    </div>
                </Paper>
            </div>
        </div>
    )
}

export default withStyles(styles)(DashboardDecoMiddleCharts);