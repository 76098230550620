import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Bar = styled.div`
    border-bottom-right-radius: .8rem;
    border-bottom-left-radius: .8rem;  
    border: 0;  
`

const StyledTypography = styled(Typography)`
    color: #fff;
`

const UserStatsComparison = (props) => {
    const { t } = useTranslation();

    return (
        <div className="md:w-1/4 sm:w-1/4 w-full">
            <Paper className="m-12 rounded-8 shadow-none border-1">
                {/* <div className="flex items-center justify-between pr-4 pl-16 pt-4">
                    <Typography className="text-16">Title</Typography>
                    <IconButton aria-label="more">
                        <Icon>more_vert</Icon>
                    </IconButton>
                </div> */}
                <div className="text-center pt-12 pb-28">
                    <Typography
                        className="text-64 leading-none font-bold" style={{color: props.valueFontColor}}>{props.value}{props.unit}</Typography>
                    <Typography className="text-16" color="textSecondary">{props.label}</Typography>
                </div>
                <Bar className="flex w-full justify-center items-center px-16 h-52 border-t-1" style={{background: props.valueFontColor }}>
                    <StyledTypography className="text-15 flex w-full justify-center items-center">
                        <span>{t('average')}</span>
                        :
                        <strong className="pl-4">{props.avgValue}</strong>
                    </StyledTypography>
                </Bar>
            </Paper>
        </div>
    )
}

export default UserStatsComparison;