import Enalyzer from './Enalyzer/Enalyzer';
import Excel from './Excel/Excel';
import Callcenter from './Callcenter/Callcenter';
import DexxCbsExport from './DexxCbsExport/DexxCbsExport';
import DexxCnCExport from './DexxCnCExport/DexxCnCExport';
import DexxStockExport from './DexxStockExport/DexxStockExport';
import DexxCnCModule from './DexxCnCModule/DexxCnCModule';
import DexxQuadraExport from './DexxQuadraExport/DexxQuadraExport';
import AdHoc from './AdHoc/AdHoc';
import FlowerPlace from './FlowerPlace/FlowerPlace';
import JDFexport from './JDFexport/JDFexport';

export const APIConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/api/excel',
            component: Excel,
            exact: true,
            auth: ['iCRMAdmin']
        },
        {
            path     : '/api/enalyzer',
            component: Enalyzer,
            exact: true,
            auth: ['iCRMAdmin']
        },
        {
            path     : '/api/callcenter',
            component: Callcenter,
            exact: true,
            auth: ['iCRMAdmin']
        },
        {
            path     : '/api/dexxexportCBS',
            component: DexxCbsExport,
            exact: true,
            auth: ['DexxFRAccountantExports']
        },
        {
            path     : '/api/dexxC&Cexport',
            component: DexxCnCExport,
            exact: true,
            auth: ['DexxCnCExports']
        },
        {
            path     : '/api/dexxC&Cmodule',
            component: DexxCnCModule,
            exact: true,
            auth: ['DexxCnCModule']
        },
        {
            path     : '/api/dexxStockExport',
            component: DexxStockExport,
            exact: true,
            auth: ['DexxCnCExports']
        },
        {
            path     : '/api/quadra-export',
            component: DexxQuadraExport,
            exact: true,
            auth: ['iCRMFinancieel']
        },
        {
            path     : '/api/adhoc',
            component: AdHoc,
            exact: true,
            auth: ['MFJDFexport']
        },
        {
            path     : '/api/flowerplace',
            component: FlowerPlace,
            exact: true,
            auth: ['iCRMAdmin']
        },
        {
            path     : '/api/JDFexport',
            component: JDFexport,
            exact: true,
            auth: ['MFJDFexport']
        },
    ]
};