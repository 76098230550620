import React, { useState } from 'react';
import {
    TextField, Button, Dialog, Tooltip, DialogContent, Icon, Typography, Toolbar, AppBar,
    IconButton, MenuItem
} from '@material-ui/core';
import ReactTable from 'react-table';
import { useFavoriteUsers, useUsersToFavorite } from './useUsersQueries';
import { useMutation } from 'react-query';
import axios from 'axios';

const renderSuggestion = (suggestion, onAddFavoriteUser) => {
    return (
      <MenuItem 
        key={suggestion.email}
        component="div"
        style={{
          fontWeight: 400,
        }}
        onClick={() => onAddFavoriteUser(suggestion.azureId)}
        // onClick={() => {console.log(suggestion)}}
      >
        {<>
        <IconButton aria-label="add" size="small" color="secondary">
            <Icon>person_add</Icon>
        </IconButton>
        &ensp;<strong>{suggestion.name}</strong>&ensp; &mdash; {suggestion.email}</>}
      </MenuItem>
    );
}

const filterUsersByInput = (users, searchTerm) => {
    return users
        .filter(u => u.email.toLowerCase().includes(searchTerm.toLowerCase()) || u.name.toLowerCase().includes(searchTerm.toLowerCase()))
}

const FavoriteUsersDialog = (props) => {
    const { data: favoriteUsers, isLoading: isLoadingFavoriteUsers, refetch: refetchFavoriteUsers } = useFavoriteUsers();
    const { data: usersToFavorite, isLoading: isLoadingUsersToFavorite, refetch: refetchUsersToFavorite } = useUsersToFavorite();
    const addFavoriteUserMutation = useMutation(favoriteAzureId => axios.post('manager/AddFavoriteUser', {azureId: favoriteAzureId}), {
        onSuccess: () => {
            refetchFavoriteUsers();
            refetchUsersToFavorite();
        }
    })
    const deleteFavoriteUserMutation = useMutation(favoriteAzureId => axios.delete('manager/DeleteFavoriteUser', {data: {azureId: favoriteAzureId}}), {
        onSuccess: () => {
            refetchFavoriteUsers();
            refetchUsersToFavorite();
        }
    })
    const [searchTerm, setSearchTerm] = useState('');
    return (
        <Dialog
            classes={{
                paper: "m-24"
            }}
            onClose={props.handleClose}
            open={true}
            fullWidth
            maxWidth="sm"
        >
            <AppBar position="static" elevation={1}>
                <Toolbar className="flex w-full">
                    <Tooltip title="Go back">
                        <IconButton aria-label="go-back" size="small" color="secondary" onClick={props.handleClose}>
                            <Icon>arrow_back</Icon>
                        </IconButton>
                    </Tooltip>
                    <Typography variant="subtitle1" color="inherit" className="ml-16">
                        Add users to your own favorite list
                    </Typography>
                </Toolbar>
            </AppBar>

            <DialogContent classes={{ root: "p-24" }}>

                <div className="flex">
                    <TextField
                        className="mb-24"
                        name='userInput'
                        label={'Search for a user'}
                        value={searchTerm}
                        onChange={(event) => setSearchTerm(event.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </div>

                {!isLoadingUsersToFavorite && filterUsersByInput(usersToFavorite, searchTerm).slice(0, 5).map(u => renderSuggestion(u, addFavoriteUserMutation.mutate))}

                <h1>Your favorite users :</h1>
                {!isLoadingFavoriteUsers &&
                    <ReactTable
                        className="w-full -striped -highlight border-0"
                        data={favoriteUsers}
                        columns={[
                            {
                                Header: "Name",
                                accessor: "name",
                                // className: "justify-center",
                                width: '35%',
                            },
                            {
                                Header: "Email",
                                accessor: "email",
                                // className: "justify-center",
                                width: '45%',
                            },
                            {
                                Header: "Delete",
                                className: "justify-center",
                                // filterable: true,
                                width: 80,
                                Cell: row =>
                                    <Button variant="outlined" color="secondary" onClick={() => deleteFavoriteUserMutation.mutate(row.original.favoriteAzureId)}>
                                        <Icon className="text-18" color="secondary">delete</Icon>
                                    </Button>,
                            },
                        ]}
                        pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                        defaultPageSize={favoriteUsers && favoriteUsers.length <= 5 ? 5 : 10}
                        noDataText="No customers found"
                    />
                }
            </DialogContent>
        </Dialog>
    )
}
export default FavoriteUsersDialog;