import React, { Component } from 'react'
import {Icon, Typography, Card, withStyles, CardContent, AppBar, Toolbar, Button, Chip, Tooltip} from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
// import i18n from '../../../../../i18n/i18n';
import {withTranslation} from 'react-i18next';
import {red, orange} from '@material-ui/core/colors';
import RouteToDebnr  from '../../../../components/Functions/RouteToDebnr';

const styles = theme => ({
    td: {
        padding: '4px 12px 4px 12px'
    },
    chip: {
        cursor: 'default !important'
    },
    red: {
        color: '#fff',
        backgroundColor: red['A700']
    },
    orange: {
        color: '#fff',
        backgroundColor: orange['A400']
    }
})

class OpenPostenTable extends Component {
    shouldComponentUpdate(nextProps) {
        if(this.props.openPosten.length === nextProps.openPosten.length && this.props.amountOfOpenPosten === nextProps.amountOfOpenPosten) {
            return false;
        }
        return true;
    }
    
    render() {
        const {classes, openPosten, t} = this.props;
        return (openPosten.length > 0 ? openPosten.map(openPost => (
            <div key={openPost.debnr} className="w-1/2 md:w-1/2 sm:w-full p-12">
                <Card className="w-full rounded-8 shadow-none border-1">
                    <AppBar position="static" elevation={0}>
                            <Toolbar className="pl-16 pr-8">
                                    <Typography variant="subtitle1" color="inherit" className="flex-1">
                                        <strong><RouteToDebnr row={{value: openPost.debnr}}>{openPost.debnr}</RouteToDebnr></strong> &mdash; {openPost.name}
                                    </Typography>
                                    <Tooltip title={t('PAYTERM')}>
                                        <Chip label={openPost.betaalTermijn} color="secondary" variant="outlined" className={classes.chip} />
                                    </Tooltip>
                                    <Tooltip title="Mail">
                                        <Button className="normal-case shadow-none px-16 ml-8" color="secondary" size="small" variant="contained" onClick={() => this.props.sendOpenPost(openPost)}>
                                            <Icon className="text-18">send</Icon>
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Print">
                                        <Button className="normal-case shadow-none px-16 ml-8" color="secondary" size="small" variant="contained" onClick={() => this.props.printOpenPost(openPost.debnr, openPost.name, openPost.betaalTermijn, t('PAYTERM'))}>
                                            <Icon className="text-18">print</Icon>
                                        </Button>
                                    </Tooltip>
                            </Toolbar>
                    </AppBar>
                    <CardContent>
                    <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={300}>
                        <div id={openPost.debnr}>
                        <table className={"simple clickable"}>
                            <thead>
                                <tr>
                                    <th className="text-center">{t('INVOICE')} nr</th>
                                    <th className="text-center">{t('DATE')}</th>
                                    <th className="text-right">{t('AMOUNT')}</th>
                                    <th className="text-right">{t('PAID')}</th>
                                    <th className="text-right">{t('CUMULATED')}</th>
                                    <th>{t('DAYS')}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {openPost.invoices.map(invoice => (
                                <tr key={invoice.number}>
                                    <td className="text-center">{invoice.number}</td>
                                    <td className="text-center">{invoice.date.substring(0, 10)}</td>
                                    <td className="text-right">{invoice.total}</td>
                                    <td className="text-right">{invoice.paid > 0 ? invoice.paid : "-"}</td>
                                    <td className="text-right">{invoice.cumulatief}</td>
                                    {(invoice.days - openPost.betaalTermijn) < 0 ? <td>{invoice.days}</td> : null }
                                    {(invoice.days - openPost.betaalTermijn) >= 0 && (invoice.days - openPost.betaalTermijn) <= 3 ? 
                                        <td>
                                            <Chip
                                                className={classes.orange}
                                                label={invoice.days}
                                            />
                                        </td> : null }
                                    {(invoice.days - openPost.betaalTermijn) > 3 ? 
                                        <td>
                                            <Chip
                                                className={classes.red}
                                                label={invoice.days}
                                            />
                                        </td> : null }
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div>
                    </Scrollbars>
                    </CardContent>
                </Card>
            
            </div>
        )) : null)
    }
}

// export default withStyles(styles)(withTranslation()(OpenPostenTable));
export default withStyles(styles)(withTranslation()(OpenPostenTable));