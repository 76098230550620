import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/crm/actions';
import {Icon, Typography, Tooltip, Dialog, AppBar, Button, FormControl, Select, TextField, OutlinedInput, MenuItem, IconButton, withStyles,
Toolbar, DialogContent} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import { getAzureId } from '../../../Auth/Auth';
import { updateUserLanguage } from '../../../../store/crm/admin/actions'

const styles = theme => ({
    layoutRoot: {},
    root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    },
    table: {
    minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});


class Profile extends Component {
    state = {
        language: localStorage.getItem('i18n'),
        changedLanguage: false,
        azureId: getAzureId(),
        password: '',
        passwordValid: false
    }

    componentDidMount() {
        this.props.onFetchUser();
    }
    
    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            passwordValid: event.target.value.length > 7
        });
    }

    handleLanguageChange = event => {
        localStorage.setItem('i18n', event.target.value);
        this.props.i18n.changeLanguage(event.target.value);
        this.setState({ language: event.target.value, changedLanguage: true })
        this.props.onUpdateUserLanguage(getAzureId(), event.target.value);
    }

    render() {
        const {t, open, handleClose} = this.props;
        console.log(this.props.updatedPassword);
        const {password, passwordValid, language} = this.state;
        return (
            <Dialog 
                classes={{
                    paper: "m-24"
                }}
                onClose={handleClose}
                open={open}
                fullWidth
                maxWidth="sm"
            >
                <AppBar position="static" elevation={1}>
                    <Toolbar className="flex w-full">
                        <Tooltip title="Close">
                            <IconButton aria-label="Delete" size="small" color="secondary" onClick={handleClose}>
                                <Icon>close</Icon>
                            </IconButton>
                        </Tooltip>
                        <Typography variant="subtitle1" color="inherit" className="ml-16">
                            Change settings
                        </Typography>
                    </Toolbar>
                </AppBar>

                <DialogContent classes={{root: "p-24"}}>
                        <div className="p-12">
                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('CHOOSELANGUAGE')}>
                                        <Icon color="action">language</Icon>
                                    </Tooltip>
                                </div>
                                <FormControl variant="outlined" className="mb-24" fullWidth>
                                    <Select
                                        input={
                                            <OutlinedInput
                                                labelWidth={0}
                                                name="language"
                                                id="language"
                                                value={language}
                                                onChange={this.handleLanguageChange}
                                            />
                                        }
                                        endAdornment={this.state.changedLanguage && <Icon className="mr-12 text-green">done</Icon>}
                                        required
                                        fullWidth
                                        // disabled
                                    >
                                        <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="fr">Français</MenuItem>
                                        <MenuItem value="nl">Nederlands</MenuItem>
                                        <MenuItem value="de">German</MenuItem>
                                    </Select>
                                </FormControl>
                                
                            </div>
                                                        
                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('CHANGEPASSWORD')}>
                                        <Icon color="action">lock</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="password"
                                    name="password"
                                    type="password"
                                    label={t('CHANGEPASSWORD')}
                                    value={password}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{maxLength: 50}}
                                    fullWidth
                                    helperText={!passwordValid ? `${t('min_charachter_length')} 8` : ''}
                                    InputProps={{
                                        endAdornment: this.props.updatedPassword && <Icon className="mr-12 text-green">done</Icon>
                                    }}
                                    // multiline={true}
                                />
                            </div>
                            
                            <div className="flex">
                                    <Button 
                                        onClick={() => this.props.onUpdateUserPassword({
                                            azureId: getAzureId(),
                                            password
                                        })} 
                                        disabled={!passwordValid} 
                                        fullWidth 
                                        variant="contained"
                                        color="primary"
                                        startIcon={<Icon>save</Icon>}
                                        >
                                            {t('SAVE')}
                                    </Button>
                            </div>
                            {/* <div className="w-full pt-24"><Alert className="w-full" severity="success">{t("UPDATEDSUCCESS")}</Alert></div> */}
                        </div>
                    </DialogContent>
                </Dialog>
        );
    }
}

const mapStateToProps = ({customer}) => {
    return {
        user: customer.user,
        updatedPassword: customer.updatedPassword,
        error: customer.error,
    }
}


const mapDispatchToProps = dispatch => {
   return {
        onFetchUser: () => dispatch(actions.fetchUser()),
        // onEditNewLead: (formData) => dispatch(actions.editNewLead(formData)),
        onUpdateUserPassword: (formData) => dispatch(actions.updateUserPassword(formData)),
        onUpdateUserLanguage: (azureId, language) => dispatch(updateUserLanguage(azureId, language))
   }
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles, {withTheme: true})(withTranslation()(Profile)));