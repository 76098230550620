import * as actionTypes from './actionTypes';
import axios from 'axios'

export const fetchDbWeblogSuccess = (weblog) => {
    return {
        type: actionTypes.FETCH_WEBLOG_SUCCESS,
        weblog: weblog
    };
};
export const fetchDbWeblogStart = () => {
    return {
        type: actionTypes.FETCH_WEBLOG_START,
    };
};
export const fetchDbWeblog = () => {
    return dispatch => {
        dispatch(fetchDbWeblogStart());
        axios.get('weblog/getweblog')
        .then(res => {
            dispatch(fetchDbWeblogSuccess(res.data));
        })
    };
};

export const fetchDbWeblogVisitsSuccess = (dbWeblogVisits) => {
    return {
        type: actionTypes.FETCH_WEBLOG_VISITS_SUCCESS,
        weblogVisits: dbWeblogVisits
    };
};
export const fetchDbWeblogVisits = () => {
    return dispatch => {
        axios.get('weblog/getweblogvisits')
        .then(res => {
            dispatch(fetchDbWeblogVisitsSuccess(res.data));
        })
    };
};