import React from 'react';
import {Card, CardContent, AppBar, Toolbar} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const skeleton = () => {
    return (
    <div className="w-1/2 md:w-1/2 sm:w-full p-12">
        <Card className="w-full rounded-8 shadow-none border-1">
            <AppBar position="static" elevation={0}>
                    <Toolbar className="pl-16 pr-8">
                    </Toolbar>
            </AppBar>
            <CardContent>
                <Skeleton variant="text" width="100%" height={40} style={{margin: '.5rem 0'}} />
                <div style={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}} />
                <Skeleton variant="text" width="100%" height={40} style={{margin: '.5rem 0'}} />
                <div style={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}} />
                <Skeleton variant="text" width="100%" height={40} style={{margin: '.5rem 0'}} />
            </CardContent>
        </Card>            
    </div>
    )
}

export default skeleton;