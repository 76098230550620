const de = {
    translations: {
        // Algemeen
        "tos_pp": "Nutzungsbedingungen und Datenschutzrichtlinie",
        "tos_pp_detail": "iCRM sammelt Daten wie Seitenbesuche, um die Benutzerfreundlichkeit zu verbessern",
        "login_privacy_acknowledgement": "Mit dem Einloggen erklären Sie sich mit unseren",
        "user_agreement": "Nutzungsbedingungen",
        "privacy_statement": "Datenschutzrichtlinie einverstanden",
        "and_the": "und unserer",
        "agree": "Zustimmen",
        "disagree": "Ablehnen",
        "login": "Einloggen",
        "logout": "Ausloggen",
        "login_to_your_account": "In Ihr Konto einloggen",
        "email": "E-Mail",
        "password": "Passwort",
        "confirm_password": "Passwort bestätigen",
        "reset_password": "Passwort zurücksetzen",
        "recover_your_password": "Ihr Passwort wiederherstellen",
        "send_reset_link": "Reset-Link senden",
        "back_to_login": "Zurück zur Anmeldung",
        "update_your_password": "Aktualisieren Sie Ihr Passwort",
        "update_your_account": "Aktualisieren Sie Ihr Konto",
        "update_my_account": "Mein Konto aktualisieren",
        "why_update_account": "Warum muss ich mein Konto aktualisieren?",
        "why_update_account_detail": "iCRM implementiert ein neues Anmeldesystem, um Remote-Benutzern Zugang zu gewähren. Bitte füllen Sie das untenstehende Formular aus, um Ihr Konto für das neue Anmeldesystem vorzubereiten.",
        "password_reset_successful": "Passwortrücksetzung erfolgreich! Bitte überprüfen Sie Ihre E-Mail, um ein neues Passwort festzulegen",
        "min_charachter_length": "Minimale Zeichenlänge ist",
        "enter_valid_email": "Bitte geben Sie eine gültige E-Mail-Adresse ein",
        "something_went_wrong": "Etwas ist schief gelaufen.",
        "please_try_again": "bitte versuchen Sie es noch einmal",
        "or": "oder",
        "contact_us": "Kontaktieren Sie uns",
        "wrong_credentials": "Falsche Beglaubigungsschreiben. Bitte versuchen Sie es erneut oder setzen Sie Ihr Passwort zurück.",
        "conclude": "abschließen",
        "conclude_explanation": "Melden Sie die Aufgabe als erledigt. Das Formular wird automatisch gespeichert.",
        "concluded": "Abgeschlossen",
        "archive": "Archiv",
        "show_assigned_tasks": "Aufgaben, die Sie anderen zugewiesen haben",
        "reopen": "Neu öffnen",
        "false_alarm": "keine Probleme",
        "false_alarm_explanation": "Diese Aufgabe wurde auf der Grundlage von Daten aus BI generiert. Sollten Sie einen Verdacht haben oder sollte der Kunde angeben, dass nichts vorliegt, melden Sie diese Aufgabe bitte als 'keine Probleme'. Auf diese Weise können wir das Aufgabensystem weiter verbessern.",
        "is_required": "ist obligatorisch",
        "concerning": "betreffend",
        "UNKNOWN": "unbekannt",
        "user_application_form": "Antragsformular für Benutzer",

        "hide": "ausblenden",

        "COPY": "Kopieren",
        "YEAR-1": "Jahr -1",
        "internet_explorer_warning": "Sie sehen iCRM im Internet Explorer. Einige iCRM-Funktionen funktionieren im Internet Explorer möglicherweise nicht wie erwartet. Bitte wechseln Sie zu einem anderen Browser wie Google Chrome, Microsoft Edge oder Mozilla Firefox",
        "turnover_same_period": "Umsatz in den gleichen Monaten wie in diesem Jahr",
        "PAGE_STATS": "Statistikseite",
        "USER_STATS": "Benutzerstatistiken",
        "VISITS_PER_USER": "Besuche pro Benutzer",

        "task_already_concluded": "Diese Aufgabe wurde bereits abgeschlossen. Öffnen Sie diese Aufgabe erneut, um mit der Bearbeitung fortzufahren",
        "days_since_first_order": "Anzahl der Tage seit der ersten Bestellung",
        "days_since_code_created": "Anzahl der Tage seit Code-Erstellung",
        "amount_webshop_visits": "Anzahl der Webshop-Besuche",
        "latest_webshop_visit": "Letzter Webshop-Besuch",
        "no_filter": "Kein Filter",
        "radio_filter_tooltip": "Die gewählte Anzeige nach Kreisfarbe filtern",
        "no_indication_selected_warning": "Wählen Sie eine Anzeige, um den Farbfilter zu verwenden",
        "indication": "Anzeige",

        "latest_import": "Letzter Import",
        "latest_invoice": "Letzte Rechnung",

        "fetching_live_customer_data": "Abrufen von Live-Kundendaten",

        // BI Info
        "reasons": "Gründe",
        "ander_klantnummer": "Andere Kundennummer",
        "geen_klachten": "Keine Beschwerden",
        "kwaliteit": "Qualität",
        "prijs": "Prejs",
        "rustig_in_de_winkel": "Ruhig im Laden",
        "service": "Dienstleistung",
        "transport": "Transport",
        "quality": "Qualität",
        "webshop": "Webshop",
        "webshop_te_moeilijk": "Webshop ist zu schwierig",
        "winkel_permanent_gesloten": "Store ist permanent geschlossen",
        "winkel_tijdelijk_gesloten": "Geschäft ist vorübergehend geschlossen",
        "geen_reden": "Kein Grund",
        "anders,_namelijk...": "Sonstiges, nämlich...",
        "namely": "Nämlich...",
        "closed_until": "Geschlossen bis",
        
        "notes": "Anmerkungen",
        "checked_more_actions_than_allowed": "Es ist nicht erlaubt, mehr als 3 Optionen gleichzeitig auszuwählen",
        "number_of_days_customer": "Anzahl der Tage Kunde",
        "number_of_days_customer_explanation": "Die Anzahl der Tage seit der ersten Bestellung des Kunden (ab 01-01-2017)",
        "total_deliveries": "Gesamtzahl der Lieferungen",
        "total_deliveries_explanation": "Die Gesamtzahl der Lieferungen an diesen Kunden",
        "days_since_last_order": "Anzahl der Tage seit der letzten Bestellung",
        "days_since_last_order_explanation": "Wie viele Tage sind seit der letzten Bestellung dieses Kunden vergangen",
        "weeks_regular_customer": "Anzahl der Wochen Stammkunde",
        "weeks_regular_customer_explanation": "Wie viele Wochen in Folge wir diesen Kunden als Stammkunden betrachten",
        "weeks_without_orders": "Anzahl der Wochen ohne Bestellung in den letzten 365 Tagen",
        "weeks_without_orders_explanation": "Hier wird angezeigt, ob es üblich ist, dass ein Kunde eine Woche oder mehrere Wochen lang nicht bei uns bestellt",
        "max_days_without_order": "Maximale Anzahl von Tagen ohne Bestellung in den letzten 365 Tagen",
        "max_days_without_order_explanation": "Dies gibt an, ob es in den letzten 365 Tagen einen längeren Zeitraum gab, in dem der Kunde nicht bei uns bestellt hat",

        "total": "Gesamt",
        "last_4_weeks": "Letzte 4 Wochen",
        "last_52_weeks": "Letzte 52 Wochen",
        "differences_alltime_last10weeks_explanation": "Hier zeigen wir die Unterschiede zwischen dem allgemeinen Bestellverhalten des Kunden und seinem Bestellverhalten in den letzten 10 Wochen",
        "days_between_orders": "Anzahl der Tage zwischen den Bestellungen",
        "days_between_orders_explanation": "Wenn dieser Wert in den letzten 10 Wochen höher ist, bestellt der Kunde weniger regelmäßig als sonst",
        "avg_invoice_amount": "Durchschnittlicher Rechnungsbetrag",
        "avg_invoice_amount_explanation": "Wenn dieser Wert in den letzten 10 Wochen niedriger ist, bestellt der Kunde für weniger als das, was er normalerweise tut",
        "avg_articlegroups_per_order": "Durchschnittliche Anzahl von Artikelgruppen pro Bestellung",
        "avg_articlegroups_per_order_explanation": "Wenn dieser Wert in den letzten 10 Wochen niedriger ist, bestellt der Kunde im Durchschnitt weniger Artikelgruppen als er es normalerweise tut",
        "amount_of_credit_lines": "Anzahl der Kreditlinien",
        "amount_of_credit_lines_explanation": "Hier wird angezeigt, wie viele Kreditlinien der Kunde in den letzten 10 Wochen eingereicht hat",
        "credit_sum": "Die Kreditsumme",
        "credit_sum_explanation": "Hier wird die Gesamtsumme der Kredite für den gesamten Zeitraum und die letzten 10 Wochen angezeigt",
        
        "relative_changes_in_last_10_weeks": "Relative Änderungen in den letzten 10 Wochen",
        "relative_changes_in_last_10_weeks_explanation": "Hier vergleichen wir die letzten 10 Wochen, wobei wir die erste Hälfte (vor 10-5 Wochen) mit der zweiten Hälfte (vor 5 Wochen bis jetzt) vergleichen",
        "relative_number_of_days_customer": "Anzahl der Aufträge",
        "relative_number_of_days_customer_explanation": "Ein Rückgang zeigt an, dass die Anzahl der Bestellungen in den letzten 5 Wochen im Vergleich zu den 5 Wochen davor abgenommen hat",
        "relative_total_deliveries": "Durchschnittliche Höhe der Rechnung",
        "relative_total_deliveries_explanation": "Bei einem positiven Prozentwert ist die durchschnittliche Rechnungshöhe in den letzten 5 Wochen höher als in den 5 Wochen davor",
        "relative_weeks_regular_customer": "Durchschnittliche Anzahl von Artikelgruppen pro Auftrag",
        "relative_weeks_regular_customer_explanation": "Bei einem positiven Prozentsatz ist die Anzahl der Artikelgruppen pro Bestellung in den letzten 5 Wochen höher als in den 5 Wochen davor",

		// Monitor translations
        "own_stats_button": "Werfen Sie einen Blick auf Ihre iCRM-Nutzung.",
        "VISITED_LAST_MONTH": "Besuchte {{amountOfPageVisits}} Seiten im letzten Monat",
        "VISITED_LAST_MONTH_OWN_STATS": "Sie haben im letzten Monat {{amountOfPageVisits}} Seiten besucht",
        "TITLECRMUSE": "Gewinnen Sie einen Einblick in die Nutzung des CRM",
        "SESSION_PER_DEVICE": "Sitzungen nach Gerät",
        "WHEN_ARE_USERS_ACTIVE": "Wann sind Nutzer aktiv und was sehen Nutzer hauptsächlich?",
        "WHEN_ARE_USERS_ACTIVE_OWN_STATS": "Wann sind Sie aktiv, und welche Seiten schauen Sie sich hauptsächlich an?",
        "AVERAGE_VISITS_PER_HOUR": "Durchschnittliche Anzahl der Besuche pro Stunde",
        "LIST_OF_SEARCHED_CUSTOMERS": "Der Benutzer hat diese Kunden nachgeschlagen",
        "LIST_OF_SEARCHED_CUSTOMERS_OWN_STATS": "Sie haben vor kurzem diese Kunden aufgesucht",
        "LIST_OF_CLICKED_CUSTOMERS": "Der Benutzer hat auf diese Kunden geklickt",
        "LIST_OF_CLICKED_CUSTOMERS_OWN_STATS": "Sie haben kürzlich auf diese Kunden geklickt",
        "AMOUNT_VISITS": "Anzahl der Besuche",
        "AVERAGE_TIME_PAGE": "Durchschnittliche Zeit auf der Seite",
        "NO_SEARCHED_FOUND": "Keine Suchbegriffe gefunden",
        // Comparison blocks
        "tasks_concluded_user": "mehr erledigte Aufgaben als der Durchschnitt",
        "tasks_concluded_user_negative": "weniger erledigte Aufgaben als der Durchschnitt",
        "tasks_concluded_avg": "Aufgaben abgeschlossen",
        "task_concluded_speed_user": "schnellere Aufgabenerledigung als der Durchschnitt",
        "task_concluded_speed_user_negative": "weniger schnell erledigte Aufgaben als der Durchschnitt",
        "task_concluded_speed_avg": "Tage",
        "notes_left_user": "mehr Kundennotizen als der Durchschnitt",
        "notes_left_user_negative": "weniger Kundenanmerkungen als der Durchschnitt",
        "notes_left_avg": "Kundenanmerkungen platziert",
        "leads_exported_user": "mehr Leads exportiert als der Durchschnitt",
        "leads_exported_user_negative": "weniger Leads exportiert als der Durchschnitt",
        "leads_exported_avg": "exportierte Leads",
        "leads_created_user": "mehr Leads als der Durchschnitt",
        "leads_created_user_negative": "weniger Leads erstellt als der Durchschnitt",
        "leads_created_avg": "Leads erstellt",
        "pages_visited_user": "mehr besuchte Seiten als der Durchschnitt",
        "pages_visited_user_negative": "weniger pagina's bezocht dan gemiddeld",
        "pages_visited_avg": "besuchte Seiten",
        "leads_validated_speed_user": "schnellere Bearbeitung von Leads als der Durchschnitt",
        "leads_validated_speed_user_negative": "weniger schnell bearbeitete Leads als der Durchschnitt",
        "leads_validated_speed_avg": "Tage",
        "average": "Durchschnitt",
        "avg_comparison_title": "Vergleichen Sie die Benutzer mit der durchschnittlichen CRM-Nutzung",
        "avg_comparison_title_own_stats": "Wie nutzen Sie iCRM im Vergleich zu anderen Nutzern?",
		// block 1
        "PAGE_VISITED": "Besuchte Seiten",
        "OPENED_CUSTOMERS": "Kunden freigeschaltet",
        "CLOSED_CUSTOMERS": "Kunden geschlossen",
        "GENERATED_INCASSO": "Lastschriften generiert",
        "EMAILED_OPENPOST": "Unbezahlt gesendet",
        "OPENED_DEPARTURE_DAYS": "Abreisetage geöffnet",
        "SEARCHED_CUSTOMERS": "Kunden gesucht",
        "AVG_TIME_PER_PAGE": "Durchschn. Zeit pro Seite",
		// Block 2
        "TASKS_DONE": "Abgeschlossene Aufgaben",
        "OPEN_TASKS": "Offene Aufgaben",
        "TASK_CREATION_COMPLETION": "Tage zwischen Aufgabenerstellung und Abschluss",
        "NOTES_WRITTEN": "Notizen geschrieben",
        "SENT_SPECIAL_OFFERS": "Sonderangebote gesendet",
        "NEWLEADS_CREATED": "Neue Leads erstellt",
        "NEWLEADS_VALIDATED": "Neue Leads validiert",
        "NEWLEADS_EXPORTED": "Neue Leads exportiert",
        "DAYS_BET_NEWLEADS_CREATED_EXPORTED": "Tage zwischen Erstellung und Export",
        "USER_ACTIVITY_PER_HOUR": "Aktivität pro Stunde",
		
        // Minor BI Data translations
        "last_year": "Vergangenes Jahr",
        "last_month": "Letzter Monat",        
        "last_year_last_month_explanation": "Hier zeigen wir die Unterschiede zwischen dem allgemeinen Bestellverhalten des Kunden und seinem Bestellverhalten im letzten Monat",
        "amount_of_orders": "Anzahl der Bestellungen",
        "avg_invoice_turnover": "Durchschnittliche Rechnungshöhe",
        "avg_amount_of_article_groups_per_order": "Durchschnittliche Anzahl von Artikelgruppen pro Bestellung",
        "credit_ratio": "Kreditquote",
        "credit_ratio_explanation": "Die Kreditquote ist der Prozentsatz der vom Kunden gutgeschriebenen Ausgaben",
        "avg_orders_per_week": "Durchschnittliche Anzahl von Bestellungen pro Woche",
        "risk_of_leaving": "Risiko des Ausscheidens",
        "customer_since": "Kunde seit",

        "invoice_amount": "Höhe der Rechnung",
        "amount_article_groups": "Anzahl der Aufgabengruppen",
        "amount_articles": "Anzahl der Artikel",
        "amount_stems": "Anzahl der Stämme",
        "invoice_paid": "Bezahlte Rechnung",

        "open_expired_tasks": "Bitte beachten Sie, dass es abgelaufene Aufgaben gibt, die noch nicht abgeschlossen sind.",
        "go_to_tasks": "Zurück zu den Aufgaben",

        "good": "Gut",
        "mediocre": "Mäßig",
        "bad": "Schlecht",
        "existing_customer": "Bestehender Kunde",

        // Specifiek
        "CREATED_TASK_ERROR": 'Es ist etwas schief gelaufen. Ist die Kundennummer korrekt?',
        "NOINTERACTIONYET": "Diese Aufgabe hatte noch keine Interaktionen",
        "OVERDUE": "überfällig",
        "ACTIONSTODOBI": "Aktionen",
        "ACTIONSTODOBI_TOOLTIP": "Lassen Sie dieses Feld leer, wenn keine Folgeaktion erforderlich ist",
        "ACTIONSTODOBI_TABLE_TOOLTIP": "Auszuführende Aktionen",
        "CALLOVERVIEWBI": "Zusammenfassung Ihres Gesprächs mit dem Kunden",
        "BIINFORMATIONS": "Kundendaten aus BI",
        "TIMELINE": "Zeitleiste",
        "CHOOSE": "Wählen",
        "FILLIN": "Füllen Sie a",
        "TITLE": "Titel",
        "TASKUPDATEDSUCCESS": "Die Aufgabe wurde erfolgreich bearbeitet",
        "TASKCONCLUDEDSUCCESS": "Die Aufgabe wurde erfolgreich abgeschlossen",
        "TASKREOPENEDSUCCESS": "Die Aufgabe wurde erfolgreich wieder geöffnet",
        "TASKCREATEDSUCCESS": "Die Aufgabe wurde erfolgreich gemacht. Sie werden zur Aufgabenübersicht weitergeleitet",
        "TASK": "Aufgabe",
        "TASKS": "Aufgaben",
        "CREATENEWTASK": "Neue Aufgabe erstellen",
        "TYPE": "Typ",
        "NO_TYPE": "Kein Typ",
        "REPONDREEMAIL": "Antwort per E-Mail",
        "CALLBACK": "Zurückrufen",
        "URGENCY": "Urgent",
        "OBJECTIVE": "Zielsetzung",
        "OPTIONS": "Optionen",
        "MOREOPTIONS": "Meer optionen",
        "ACTIONS_CHECKLIST": "Aktions-Checkliste",
        "ADD_ACTION": "Aktion hinzufügen",
        "FILL_NEW_ACTIONS": "Ausfüllen einer neuen Aktion",

        "financial_groups_distribution_title": "Verteilung des Umsatzes auf die Finanzgruppen",
        "financial_groups_distribution_hbxdeco_title": "Verteilung des Umsatzes auf die Finanzgruppen",
        "margin_distribution_title": "Verteilung der Margen auf die Zielgruppen",
        
        "ADD": "Hinzufügen",
        "USER": "User",
        "EMAIL": "Email",
        "NAME": "Name",
        "ORDERS": "Aufträge",
        "HAVEORDERED": "hat geordert",
        "VISITE": "besucht",
        "LOADMORE": "zeige mehr",
        "CREATED": "hergestellt",
        "EXPORTED": "Exportiert",
        "CREATE": "Create",
        "CUSTOMERS": "Kunden",
        "ADDRESS": "Adresse",
        "CITY": "Ort",
        "ZIPCODE": "Postleitzahl",
        "COUNTRY": "Country",
        "SHOP": "Geschäft",
        "OWNER": "Besitzer",
        "PHONE": "Telefoon",
        "MOBILE": "Handy",
        "VAT": "Mehrwertsteuer",
        "DOESTHECUSTOMERHAVEAVATNUMBER": "Heb de klant an BTW nummer ?",
        "KVK": "KvK",
        "TRANSPORTER": "Transporter",
        "PASSWORD": "Passwort",
        "LOGTIME": "Logbuch",
        "KOPEN": "letzter Ankauf",
        "DIFFERENCE": "Unterschied",
        "REFRESH": "erneuern",
        "NEW": "neu",
        "OLD": "alt / alt",
        "DAYS": "Tage",
        "WEEK": "Woche",
        "BLOCK1": "Neue Kunden in den letzten 7 Tagen",
        "BLOCK2": "letzte Faktur in den letzten -5 bis -7 Wochentagen",
        "BLOCK3": "Letzte Faktur in den letzten -10 bis -14 Wochentagen",
        "BLOCK4": "Letzte Faktur in den letzten -20 bis -30 Wochentagen",
        "BLOCK5": "Kunden die sich nicht das Mindestmaß genügen ",
        "BLOCK6": "Kunden die insgesamt nur 1 Faktur haben",
        "TOOLTIP": "Dieses Block zeigt alle Kunden die ",
        "NEWNLOSTCUSTOMERS": "Anzahl neuer und verlorener Kunden",
        "ACTIVECUSTOMERS": "Kundenzahl",
        "ACTIVECUSTOMERSTOOLTIP": "Wir zählen alle unterschiedlichen Kunden",
        "QTYORDERS": "Bestellungsmenge",
        "QTYORDERSTOOLTIP": "Wir zählen alle positiven Rechnungen",
        "NOTIFICATIONS": "Meldungen",
        "EXECUTE": "ausführen",
        "LASTDATE": "ausgeführt",
        "DESCRIPTION": "Umschreibung",
        "AMOUNT": "Summe",
        "CUMULATED": "Kumuliert",
        "VALIDATE": "validiert",
        "VALIDATED": "Validiert",
        "PRELEVEMENT": "Inkasso",
        "INVOICED": "fakturiert",
        "CREDIT": "Kredit",
        "DATE": "Datum",
        "LOADCAMPAIGNS": "lade Kampagnen",
        "DEPARTURE": "Abfahrt",
        "TODAY": "Heute",
        "TURNOVERPERCATEGORIES": "Verteilung Umsatz",
        "WEEKLYORDERFREQUENCY": "wochentliche Bestellungsfrequenz",
        "WEEKLYTURNOVER": "wochentliche Umsatzdiagramm",
        "STARTDATE": "Begindatum",
        "ENDDATE": "Enddatum",
        "COUNTINVOICES": "Fakturmenge",
        "OPENSTAAND": "Gesamtanzahl Wochen offenstehend",
        "AVGORDERGROOTTE": "durchschnittliche Bestellungsgröße",
        "DYNAMICSEARCHTEXT": "Bei dynamischen Suchfeldern kann man Optionen wie > (größer als), <(kleiner als) verwenden. Die dynamische Suchfelder haben einen blauen Rand.",
        "DYNAMICSEARCH": "Dieses Eingabefeld ist dynamisch",
        "LAST90DAYS": "Die letzten 90 Tage",
        "LAST60DAYS": "Die letzten 60 Tage",
        "LAST30DAYS": "Die letzten 30 Tage",
        "YESTERDAY": "gestern",
        "THISWK": "diese Woche",
        "THISMT": "diesen Monat",
        "THISYR": "dieses Jahr",
        "LASTWK": "letzte Woche",
        "LASTMT": "letzten maand",
        "LASTYR": "letztes Jahr",
        "DEFAULTDT": "standard Datum",
        "WKPERORD": "Wochen pro Bestellung",
        "APPLY": "Anwenden",
        "CHANGEPASS": "Passwort ändern",
        "MONDAY": "M",
        "TUESDAY": "D",
        "WEDNESDAY": "M",
        "THURSDAY": "D",
        "FRIDAY": "F",
        "INVOICES": "Rechnungen",
        "CALL": "Anruf",
        "CALLLIST": "Anrufliste",
        "INBOX": "Posteingang",
        "VISIT": "Besuch",
        "DELIVERY": "Lieferung",
        "FILLING": "Füllung",
        "STELEN": "Anzahl der Stämme",
        "PLANTS": "Pflanzen",
        "FLOWERS": "Blumen",
        "OTHERS": "Andere",
        "AMNTCLIENTACTIVE": "Aktivkunden",
        "WEBLOG": "(besuchte Seiten pro Tag)",
        "BOUGHT": "Gekauft",
        "STOCK": "Lager",
        "ORDERLIST": "Bestellliste",
        "WEBLOG30DAYS": "Anzahl der Seiten, die in den letzten 30 Tagen besucht wurden",
        "TURNOVER": "Umsatz",
        "PREVIOUSDEPARTUREDAYS": "erwartete Lieferungsdatum",
        "INVOICEHISTORY": "Fakturverzeichnis",
        "INVOICE": "Faktur",
        "PAID": "bezahlt",
        "DEPARTUREHISTORY": "Lieferungsdatumverzeichnis",
        "MAXDAY": "maximale Tagen",
        "LASTINVOICE": "letze Faktur",
        "CLOSED": "schließen",
        "CUSTOMERSTOVALIDATE": "zu validieren Kunden ",
        "SEND": "absenden",
        "SAVE": "speichern",
        "PREVIEW": "Vorschau",
        "DOWNLOAD": "Herunterladen",
        "CANCEL": "Annullieren",
        "NEXT": "nächste",
        "DELETE": "löschen",
        "LOGIN": "einloggen",
        "PASSWORDFORGOTTEN": "Passwort vergessen",
        "EDIT": "Bearbeiten",
        "RETURN": "zurück",
        "HOME": "Home",
        "CUSTOMER": "Kunden",
        "USERS": "Benutzer",
        "RAPPORT": "Hinweise",
        "NEWCUSTOMERS": "Neukunden",
        "OVERVIEW": "Übersichten",
        "UNPAID": "offenstehende Bezahlungen",
        "HEADER": "Navigation",
        "DASHBOARD": "Dashboard",
        "CALENDAR": "Kalender",
        "WIDGETS": "Widgets",
        "USERACCESS": "Benutzerzugriff",
        "UNPAIDINVOICES": "Unbezahlte Rechnungen",
        "SPECIALOFFER": "Sonderangebot",
        "SPECIALOFFERS": "Sonderangebote",
        "MANAGE": "Verwalten",
        "VISITORS": "Besucher",
        "VISITORSBYDAY": "Besucher pro Tag für den letzten Monat",
        "USERNAME": "Benutzername",
        "MESSAGE": "Nachricht",
        "ADDMESSAGE": "Hinterlassen Sie eine Nachricht",
        "COMPANY": "Gesellschaft",
        "SETTINGS": "die Einstellungen",
        "PAYTERM": "Zahlungsbedingung",
        "FINANCIELTYPE": "Finanzieller typ",
        "COMMERCIALTYPE": "Kommerzieller Typ",
        "DOWNLOADBACKORDERS": "Nachbestellungen herunterladen",
        "show_backorders": "Rückstandsübersicht anzeigen",
        "BUYLIMIT": "Kauflimit",
        "CREDITLIMIT": "Kreditlimit",
        "OPEN": "Öffnen",
        "CLOSE": "Schließen",
        "NOCOMMENTS": "Keine Kommentare vorhanden",
        "SEARCH": "Suchen",
        "GLOBALSEARCH": "Suche alle...",
        "ASSIGNNEWLEADS": "Assign new leads",
        "NEWLEAD": "Neuer Vorsprung",
        "PROFILE": "Profiel",
        "CHOOSELANGUAGE": "Wähle deine Sprache",
        "CHANGEPASSWORD": "Ändern Sie Ihr Passwort",
        "RESETFORM": "Formular leeren",
        "ASSIGNED": "Zugewiesen",
        "ADDTEMPLATESTOYOURLIST": "Fügen Sie Ihrer Favoritenliste Vorlagen hinzu",
        "NEWLEADARCHIVEINFO": "Wenn Sie einen neuen Lead archivieren, wird dieser nicht in Florisoft importiert",
        "DELETEDNEWLEADSUCCESS": "Der neue Lead wurde erfolgreich gelöscht",
        "ALREADYEXPORTERNEWLEAD": "Dieser Vorsprung wurde bereits exportiert",
        "NEWCUSTOMERSDIALOGINFO": "Um diesen Kunden zu validieren, müssen alle oben stehenden Felder ausgefüllt werden in Florisoft",
        "WELCOME_DISCOUNT": "Willkommensrabatt",
        "MULTIPLEEMAILS": "Mehrere E-Mails für diesen Kunden gefunden",
        "MULTIPLEVAT": "Es wurden mehrere Kunden mit dieser Mehrwertsteuer gefunden",
        "NEWLEADALREADYVALIDATED": "Dieser Hinweis wurde bereits validiert",
        "NEWLEADVIESFIRSTCREATENEWLEAD": "Überprüfen Sie zunächst die VIES-Informationen, um festzustellen, ob diese mit den Kundendaten übereinstimmen",
        "WEBSHOPACCESS": "Zugang zum Webshop",
        "UPDATEDSUCCESS": "Erfolgreich aktualisiert",
        "UPDATEDFAILED": "Ein Fehler ist aufgetreten, bitte kontaktieren Sie uns",
        "CREATEDSUCCESS": "Erfolgreich erstellt",
        "YOURFAVTEMPLATES": "Ihre Lieblingsvorlagen",
        "LEADS": "Leads",
        "ACCOUNTMNGR": "Buchhalter",
        "NOTE": "Hinweis",
        "AREYOUSURE": "bist du sicher ?",
        "BY": "by",
        "YES": "ja",
        "NO": "nein",
        "CHECK": "Prüfen",
        "SHOPNAMEFORINVOICE": "Geschäftsname auf der Rechnung",
        "OPENROUTE": "Extra Abreisetag öffnen",
        "MOSTBOUGHTPRODUCTS": "Top 10 der meistgekauften Produkte",
        "PRODUCT": "Produkt",
        "TIMESBOUGHT": "Anzahl der Käufe",
        "TOTALSTEMS": "Gesamtzahl der Stiele",
        "AVGPRICE": "Durchschn. Preis",
        "CREDITVERDELING": "Verteilung der Kredite auf Kreditgründe",
        "SHOWLEGEND": "Legende anzeigen",
        "HIDELEGEND": "Legende ausblenden",
        "WEBSHOP_LANGUAGE": "de-de",
        "MULTIPLEDATALEAD": "Doppelte E-Mails",
        "MULTIPLEVATDATALEAD": "Mehrwertsteuer doppelt",
        "DEBTEXTFIELD"  : "Debiteur nota",
        "DEBTEXTPLACEHOLDER" : "separate invoice email, delivery instructions, etc",        
        "creation_date": "Datum der Erstellung",
        "CON_SUCCESS": "Verbindung erfolgreich",
        "CON_UNSUCCESS": "Verbindung fehlgeschlagen !",
        "LOGIN_HISTORY": "Verbindungsverlauf",
        "REGION": "Region",
        "FILTER": "Filter",
        "FILTER_UNSUCCESSSFUL": "Ausfiltern nicht erfolgreich",
        "TOO_MANY_WRONG_PASSWORDS_ACCOUNT_LOCKED": "Zu viele falsche Passwörter, Konto ist für 5 Minuten gesperrt",
        "LOGIN_ATTEMPTS": "Anmeldeversuche",
        "VISITS": "Besuche",
        "DEBFINGRP" : "Kundentyp"

    }
};
export default de;