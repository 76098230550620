import {updateObject} from '../../utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    overview: null,
    pastYearOverview: null,
    reservationsOverview: null,
}

// Overview //
const fetchDbOverviewSuccess = ( state, action ) => {
    return updateObject( state, {overview: action.overview} );
};

const fetchDbPastYearOverviewSuccess = ( state, action ) => {
    return updateObject( state, {pastYearOverview: action.pastYearOverview} );
};

const fetchHbxDecoReservationsSuccess = ( state, action ) => {
    return updateObject( state, {reservationsOverview: action.reservationsOverview} );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.FETCH_OVERVIEW_SUCCESS: return fetchDbOverviewSuccess( state, action );

        case actionTypes.FETCH_PASTYEAR_OVERVIEW_SUCCESS: return fetchDbPastYearOverviewSuccess( state, action );

        case actionTypes.FETCH_HBXDECO_RESERVATIONS_OVERVIEW_SUCCESS: return fetchHbxDecoReservationsSuccess( state, action );

        default: return state;
    }
};

export default reducer;