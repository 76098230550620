import {combineReducers} from 'redux';
import fuse from './fuse';
import auth from '../../../app/auth/store/reducers';
import quickPanel from '../../../app/fuse-layouts/shared-components/quickPanel/store/reducers';
import flortalAuth from '../auth/reducers/auth';
import customer from '../crm/reducers/customer';
import unpaid from '../crm/reducers/unpaid';
import weblog from '../crm/reducers/weblog';
import overview from '../crm/reducers/overview';
import crmDashboard from '../crm/reducers/dashboard';
import turnover from '../crm/reducers/turnover';
import specialoffer from '../specialoffer/reducers/specialoffer';
import api from '../api/reducers/api';
import manager from '../crm/manager/reducers/manager';
import admin from '../crm/admin/reducers/admin';
import callList from '../crm/reducers/calllist';
import wholesale from '../wholesale/reducers/wholesale';
import hbxdeco from '../hbxdeco/reducers/hbxdeco';
import task from '../task/reducers/task';
import monitor from '../monitor/reducers/monitor'
import profile from '../crm/reducers/profile';

const createReducer = (asyncReducers) =>
    combineReducers({
        auth,
        fuse,
        quickPanel,
        flortalAuth,
        customer,
        unpaid,
        weblog,
        overview,
        turnover,
        crmDashboard,
        specialoffer,
        api,
        manager,
        admin,
        callList,
        wholesale,
        hbxdeco,
        task,
        monitor,
        profile,
        ...asyncReducers
    });

export default createReducer;
