import * as actionTypes from './actionTypes';
import axios from 'axios'
import moment from 'moment';

//Customer Data
export const fetchDbUnpaidSuccess = (dbUnpaid) => {
    return {
        type: actionTypes.FETCH_UNPAID_SUCCESS,
        unpaid: dbUnpaid
    };
};

export const fetchDbUnpaid = () => {
    return dispatch => {
        axios.get('unpaid/getunpaid')
            .then(res => {
                dispatch(fetchDbUnpaidSuccess(res.data));
            })
    };
};

export const downloadUnpaid = (info) => {
    return dispatch => {
        console.log(info)
        axios.post('unpaid/downloadUnpaid', info, {responseType: 'blob'})
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Unpaid_${moment(new Date()).format('YYYY-MM-DD')}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    };
}