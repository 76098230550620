import React, {Component} from 'react';
import {Typography, Card} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {FuseAnimateGroup} from '../../../../../@fuse';
import {connect} from 'react-redux';
import * as actions from '../../../../store/crm/actions';
import * as hbxDecoActions from '../../../../store/hbxdeco/actions';
import OverviewWidget, {lineData} from '../../../../components/Widgets/LineChart';
import {barData, BarChartOnly} from '../../../../components/Widgets/BarChart';
import _ from 'lodash';
import { withTranslation } from "react-i18next";
import { getCompany } from '../../../Auth/Auth';

const styles = theme => ({
    layoutRoot: {},
});

const colors = {
    lightBlue: {
        backgroundColor: 'rgba(0,229,255, .3)',
        borderColor: 'rgb(0,229,255)',
    }, 
    orange: {
        backgroundColor: 'rgba(255,196,0, .3)',
        borderColor: 'rgb(255,196,0)'
    }, 
    purple: {
        backgroundColor: 'rgba(206, 147, 216, .3)',
        borderColor: 'rgb(206, 147, 216)'
    }, 
    red: {
        backgroundColor: 'rgba(245, 0, 87, .3)',
        borderColor: 'rgb(245, 0, 87)'
    }, 
    green: {
        backgroundColor: 'rgba(0, 230, 118, .3)',
        borderColor: 'rgb(0, 230, 118)'
    },
    greyBlue: {
        backgroundColor: 'rgba(96, 125, 140, .3)',
        borderColor: 'rgb(96, 125, 140)',
    }
};

const currentYear = new Date().getFullYear();
const lastYear = new Date().getFullYear() - 1;

class DecoOverview extends Component {
    
    componentDidMount(){
        this.props.onFetchHbxDecoOverview();
        this.props.onFetchHbxDecoPastYearOverview();
        this.props.onFetchHbxDecoTargetOverview();
        this.props.onFetchHbxDecoReservationsOverview();
    }

    render()
    {
        const {overview: fetchedOverview, pastYearOverview: fetchedPastYearOverview, 
            hbxDecoOverview, hbxDecoPastYearOverview, hbxDecoTargetOverview, reservationsOverview, t} = this.props;
        let overview = null;
        let pastYearOverview = null;
        const isHbxDeco = getCompany() === 4;
        const defaultHeaderLabels = [lastYear, currentYear, 'Target']
        if(!isHbxDeco) {
            overview = fetchedOverview;
            pastYearOverview = fetchedPastYearOverview;
        } else {
            overview = hbxDecoOverview;
            pastYearOverview = hbxDecoPastYearOverview;
        }
        const loadedAllOverviews = (!isHbxDeco && overview && pastYearOverview) || (isHbxDeco && overview && pastYearOverview && reservationsOverview && hbxDecoTargetOverview);
        if(loadedAllOverviews) {
            // lineData.labels = pastYearOverview.labels;
            lineData.labels = Object.keys(pastYearOverview.labels).length >= Object.keys(overview.labels).length ? pastYearOverview.labels : overview.labels;
        }

        let turnoverChart = null;
        if(loadedAllOverviews && !isHbxDeco) {
            const lineChartData = _.cloneDeep(lineData);
            lineChartData.options.scales.yAxes[0].ticks.stepSize = 50000;
            lineChartData.options.scales.yAxes[0].ticks.beginAtZero = true;
            lineChartData.datasets['turnover'] = [
                {
                    label: (currentYear),
                    data : overview.turnover,
                    fill : 'start',
                },
                {
                    label: (lastYear),
                    data : pastYearOverview.turnover,
                    fill : 'start'
                },
                {
                    label: 'Target',
                    data : pastYearOverview.turnover.map(v => (v * 1.05).toFixed(0)),
                    fill : 'start'
                }
            ]
            let lineChartColors = [colors.orange, colors.lightBlue, colors.red];
            turnoverChart = <OverviewWidget data={lineChartData} dataset="turnover" overviewChart headerText={t('TURNOVER')} type="euro"
                currentValue={overview.headerBlocks.totalTurnover} targetValue={pastYearOverview.headerBlocks.totalTurnover} colors={lineChartColors} 
                headerLabels={defaultHeaderLabels} gerealiseerdeOrdersTarget />
        }

        let activeCustomersPercentChart = null;
        if(loadedAllOverviews) {
            const lineChartData = _.cloneDeep(lineData);
            lineChartData.options.scales.yAxes[0].ticks.stepSize = 1;
            const percentages = overview.numOfClients.map(a => ((a / overview.totalAmountOfClients) * 100).toFixed(2));
            const pastYearPercentages = pastYearOverview.numOfClients.map(a => ((a / overview.totalAmountOfClients) * 100).toFixed(2));
            let lineChartColors = [colors.orange, colors.lightBlue, colors.red];
            lineChartData.datasets['activeCustomersPercent'] = [
                {
                    label: (currentYear),
                    data : percentages,
                    fill : 'start'
                },
                {
                    label: (lastYear),
                    data : pastYearPercentages,
                    fill : 'start'
                },
                {
                    label: 'Target',
                    data : pastYearPercentages.map(v => (v * 1.05).toFixed(2)),
                    fill : 'start'
                }
            ];
            activeCustomersPercentChart = <OverviewWidget data={lineChartData} dataset="activeCustomersPercent" overviewChart headerText={'% ' + t("AMNTCLIENTACTIVE")} 
                headerTextTooltip={t("QTYORDERSTOOLTIP")} type="percentage" 
                currentValue={overview.headerBlocks.totalClientsPercentage.toFixed(2)} 
                targetValue={pastYearOverview.headerBlocks.totalClientsPercentage.toFixed(2)}
                headerLabels={defaultHeaderLabels}
                colors={lineChartColors} />
        }

        let amountOfOrdersChart = null;
        if(loadedAllOverviews) {
            const lineChartData = _.cloneDeep(lineData);
            lineChartData.options.scales.yAxes[0].ticks.stepSize = 50;
            let lineChartColors = [colors.orange, colors.lightBlue, colors.red];
            lineChartData.datasets['numOfInvoices'] = [
                {
                    label: (currentYear),
                    data : overview.numOfInvoices,
                    fill : 'start'
                },
                {
                    label: (lastYear),
                    data : pastYearOverview.numOfInvoices,
                    fill : 'start'
                },
                {
                    label: 'Target',
                    data : pastYearOverview.numOfInvoices.map(v => (v * 1.05).toFixed(0)),
                    fill : 'start'
                }
            ];
            amountOfOrdersChart = <OverviewWidget data={lineChartData} dataset="numOfInvoices" overviewChart headerText={t("QTYORDERS")} headerTextTooltip={t("QTYORDERSTOOLTIP")}
                currentValue={overview.headerBlocks.totalInvoices} targetValue={pastYearOverview.headerBlocks.totalInvoices}
                headerLabels={defaultHeaderLabels}
                colors={lineChartColors} />
        }

        let amountOfActiveCustomersChart = null;
        if(loadedAllOverviews) {
            const lineChartData = _.cloneDeep(lineData);
            lineChartData.options.scales.yAxes[0].ticks.stepSize = 50;
            let lineChartColors = [colors.orange, colors.lightBlue, colors.red];
            lineChartData.datasets['numOfClients'] = [
                {
                    label: (currentYear),
                    data : overview.numOfClients,
                    fill : 'start'
                },
                {
                    label: (lastYear),
                    data : pastYearOverview.numOfClients,
                    fill : 'start'
                },
                {
                    label: 'Target',
                    data : pastYearOverview.numOfClients.map(v => (v * 1.05).toFixed(0)),
                    fill : 'start'
                }
            ];
            amountOfActiveCustomersChart = <OverviewWidget data={lineChartData} dataset="numOfClients" overviewChart headerText={t("ACTIVECUSTOMERS")}
            headerTextTooltip={t("ACTIVECUSTOMERSTOOLTIP")}
            currentValue={overview.headerBlocks.totalClients} targetValue={pastYearOverview.headerBlocks.totalClients}
            headerLabels={defaultHeaderLabels}
            colors={lineChartColors} />
        }

        let geschrevenOmzetBarChart = null;
        let geschrevenOmzetCumulatiefBarChart = null;
        if(loadedAllOverviews && isHbxDeco) {
            const barChartData = _.cloneDeep(barData);
            const barChartCumulatiefData = _.cloneDeep(barData);
            let huidigJaarGeschreven = [...reservationsOverview.huidigJaarGeschreven.turnover];
            let vorigJaarGeschreven = [...reservationsOverview.vorigJaarGeschreven.turnover];
            for(let i = huidigJaarGeschreven.length; i < 12; i++) {
                huidigJaarGeschreven.push(0);
            }
            barChartData.datasets = [
                {
                    label: 'Geschreven order begroot',
                    data: hbxDecoTargetOverview.geschrevenOrdersTarget,
                    backgroundColor: 'rgb(0,229,255)'
                },
                {
                    label: 'Geschreven orders in huidig jaar',
                    data: huidigJaarGeschreven,
                    backgroundColor: 'rgb(255,196,0)'
                },
                {
                    label: 'Geschreven orders in vorig jaar',
                    data: vorigJaarGeschreven,
                    backgroundColor: 'rgb(245, 0, 87)'
                }
            ];
            barChartCumulatiefData.datasets = [
                {
                    label: 'Geschreven order begroot',
                    data: [hbxDecoTargetOverview.headerBlocks.geschrevenOrdersTargetTotaal],
                    backgroundColor: 'rgb(0,229,255)'
                },
                {
                    label: 'Geschreven orders in huidig jaar',
                    data: [reservationsOverview.huidigJaarGeschreven.headerBlocks.huidigJaarGeschrevenTotaal],
                    backgroundColor: 'rgb(255,196,0)'
                },
                {
                    label: 'Geschreven orders in vorig jaar',
                    data: [reservationsOverview.vorigJaarGeschreven.headerBlocks.vorigJaarGeschrevenTotaal],
                    backgroundColor: 'rgb(245, 0, 87)'
                }
            ];
            
            barChartData.labels = pastYearOverview.labels;
            barChartCumulatiefData.labels = ['Cumulatief'];
            barChartCumulatiefData.options.legend = {display: false};
            geschrevenOmzetBarChart = <BarChartOnly data={barChartData} />
            geschrevenOmzetCumulatiefBarChart = <BarChartOnly data={barChartCumulatiefData} />
        }

        let gerealiseerdeOmzetBarChart = null;
        let gerealiseerdeOmzetCumulatiefBarChart = null;
        if(loadedAllOverviews && isHbxDeco) {
            const barChartData = _.cloneDeep(barData);
            const barChartCumulatiefData = _.cloneDeep(barData);
            let huidigJaarGerealiseerd = [...overview.turnover];
            let vorigJaarGerealiseerd = [...pastYearOverview.turnover];
            for(let i = huidigJaarGerealiseerd.length; i < 12; i++) {
                huidigJaarGerealiseerd.push(0);
            }
            barChartData.datasets = [
                {
                    label: 'Gerealiseerde omzet begroot',
                    data: hbxDecoTargetOverview.gerealiseerdeOrdersTarget,
                    backgroundColor: 'rgb(0,229,255)'
                },
                {
                    label: 'Gerealiseerde omzet in huidig jaar',
                    data: huidigJaarGerealiseerd,
                    backgroundColor: 'rgb(255,196,0)'
                },
                {
                    label: 'Gerealiseerde omzet in vorig jaar',
                    data: vorigJaarGerealiseerd,
                    backgroundColor: 'rgb(245, 0, 87)'
                }
            ];
            barChartCumulatiefData.datasets = [
                {
                    label: 'Gerealiseerde omzet begroot',
                    data: [hbxDecoTargetOverview.headerBlocks.gerealiseerdeOrdersTargetTotaal],
                    backgroundColor: 'rgb(0,229,255)'
                },
                {
                    label: 'Gerealiseerde omzet in huidig jaar',
                    data: [overview.headerBlocks.totalTurnover],
                    backgroundColor: 'rgb(255,196,0)'
                },
                {
                    label: 'Gerealiseerde omzet in vorig jaar',
                    data: [pastYearOverview.headerBlocks.totalTurnover],
                    backgroundColor: 'rgb(245, 0, 87)'
                }
            ];
            barChartData.labels = pastYearOverview.labels;
            barChartCumulatiefData.labels = ['Cumulatief'];
            barChartCumulatiefData.options.legend = {display: false};
            gerealiseerdeOmzetBarChart = <BarChartOnly label="Gerealiseerde omzet" data={barChartData} />
            gerealiseerdeOmzetCumulatiefBarChart = <BarChartOnly label="Gerealiseerde omzet" data={barChartCumulatiefData} />
        }
                

        return (
            <div className="w-full p-12">
            {/* <DemoContent /> */}
                {overview && pastYearOverview ? 
                <FuseAnimateGroup
                    enter={{
                        animation: "transition.slideUpBigIn"
                    }}
                >
                    {
                        !isHbxDeco ? 
                        <div className="flex flex-col md:flex-row p-12">
    
                            <div className="flex flex-1 flex-col min-w-0">
                                <div className="widget w-full">
                                    {turnoverChart}
                                </div>
                            </div>
                        </div> 
                        :
                        <React.Fragment>
                            <div className="flex flex-col md:flex-row p-12">
                                <div className="flex flex-1 flex-col min-w-0">
                                    <div className="widget w-full">
                                        
                                        <Card style={{height: 400}} className="w-full rounded-8 shadow-none border-1">
                                            <div className="pt-16 pl-8 pb-0 flex flex-row flex-wrap items-end">
                                                <Typography component="h2">Geschreven orders</Typography>
                                            </div>
                                            <div className="flex w-full">
                                                <div className="flex" style={{height: 300, width: '78%'}}>
                                                    {geschrevenOmzetBarChart}
                                                </div>
                                                <div className="flex" style={{height: 300, width: '22%'}}>
                                                    {geschrevenOmzetCumulatiefBarChart}
                                                </div>
                                            </div>
                                        </Card>
                                        {/* {geschrevenOmzetBarChart} */}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row p-12">
                                <div className="flex flex-1 flex-col min-w-0">
                                    <div className="widget w-full">
                                        
                                        <Card style={{height: 400}} className="w-full rounded-8 shadow-none border-1">
                                            <div className="pt-16 pl-8 pb-0 flex flex-row flex-wrap items-end">
                                                <Typography component="h2">Gerealiseerde omzet</Typography>
                                            </div>
                                            <div className="flex w-full">
                                                <div className="flex" style={{height: 330, width: '78%'}}>
                                                    {gerealiseerdeOmzetBarChart}
                                                </div>
                                                <div className="flex" style={{height: 330, width: '22%'}}>
                                                    {gerealiseerdeOmzetCumulatiefBarChart}
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    <div className="flex flex-col md:flex-row p-12">

                        <div className="flex flex-1 flex-col min-w-0">
                            <div className="widget w-full">
                                {activeCustomersPercentChart}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row p-12">

                        <div className="flex flex-1 flex-col min-w-0">
                            <div className="widget w-full">
                                {amountOfOrdersChart}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row p-12">

                        <div className="flex flex-1 flex-col min-w-0">
                            <div className="widget w-full">
                                {amountOfActiveCustomersChart}
                            </div>
                        </div>
                    </div>
                </FuseAnimateGroup> : null}
            </div>
        )
    }
}

const mapStateToProps = ({overview, hbxdeco}) => {
    return {
        overview: overview.overview,
        pastYearOverview: overview.pastYearOverview,
        reservationsOverview: hbxdeco.reservationsOverview,
        hbxDecoOverview: hbxdeco.overview,
        hbxDecoPastYearOverview: hbxdeco.pastYearOverview,
        hbxDecoTargetOverview: hbxdeco.targetOverview,
        hbxDecoMargesOverview: hbxdeco.margesOverview
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchOverview: () => dispatch(actions.fetchDbOverview()),
        onFetchPastYearOverview: () => dispatch(actions.fetchDbPastYearOverview()),
        onFetchHbxDecoOverview: () => dispatch(hbxDecoActions.fetchHbxDecoOverview()),
        onFetchHbxDecoPastYearOverview: () => dispatch(hbxDecoActions.fetchHbxDecoPastYearOverview()),
        onFetchHbxDecoTargetOverview: () => dispatch(hbxDecoActions.fetchHbxDecoTargetOverview()),
        onFetchHbxDecoReservationsOverview: () => dispatch(hbxDecoActions.fetchHbxDecoReservationsOverview()),
        onFetchHbxDecoMargesOverview: () => dispatch(hbxDecoActions.fetchHbxDecoMargesOverview()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(withTranslation()(DecoOverview)));