import React, {Component} from 'react';
import {withStyles, Icon, Typography} from '@material-ui/core';
import {FuseHighlight, FusePageSimple} from '../../../../@fuse';

const styles = theme => ({
    layoutRoot: {},
});

class FuseTheming extends Component {

    render()
    {
        const {classes} = this.props;
        return (
            <FusePageSimple
            classes={{
                root: classes.layoutRoot
            }}
            header={
                <div className="flex flex-1 items-center justify-between p-24">
                    <div className="flex flex-col">
                        <div className="flex items-center mb-16">
                            <Icon className="text-18" color="action">home</Icon>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">Documentation</Typography>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">Fuse Theming</Typography>
                        </div>
                        <Typography variant="h6">Fuse Theming</Typography>
                    </div>
                </div>
            }
            content={
                <div className="p-24 max-w-2xl">
                
<div className="mb-16">
                    <Typography className="mb-8" variant="h5">Configuration</Typography>
<ul>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="http://react-material.fusetheme.com/documentation/working-with-fuse-react/theming">Theming</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="http://react-material.fusetheme.com/documentation/working-with-fuse-react/theme-layouts">Theme Layout</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="http://react-material.fusetheme.com/documentation/working-with-fuse-react/page-layouts">Page Layout</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="http://react-material.fusetheme.com/documentation/working-with-fuse-react/settings">Settings</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="http://react-material.fusetheme.com/documentation/working-with-fuse-react/project-structure">Project Structure</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="http://react-material.fusetheme.com/ui/icons">Icons</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="http://react-material.fusetheme.com/ui/typography">Typography</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="http://react-material.fusetheme.com/components/material-ui/app-bar">Material UI</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="http://react-material.fusetheme.com/components/material-ui/text-fields">Text Field</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="http://react-material.fusetheme.com/components/material-ui/tooltips">Tooltips</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="http://react-material.fusetheme.com/components/fuse/fuse-chip-select">Chip Select</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="http://react-material.fusetheme.com/components/fuse/fuse-animate">Animations</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="http://react-material.fusetheme.com/components/fuse/fuse-animate-group">List Animations</a></li>
<li className="mb-3 lg:mb-2"><a className="hover:underline text-grey-darkest" target="_blank" rel="noopener noreferrer" href="http://react-material.fusetheme.com/components/fuse/fuse-message">Message Snackbar</a></li>
</ul>
</div>
                    <Typography className="mb-8" variant="h5">For Dirk Jan</Typography>
                    <Typography className="mb-16" component="p">
                        The Fuse React uses material-ui's theming by default.
                        You can can change the colors, the typography with defining theme configuration objects.
                    </Typography>


                    <Typography className="mb-16" component="p">
                        For the configuration options checkout
                        <a href="https://material-ui-next.com/customization/themes/" target="_blank" rel="noopener noreferrer" className="mx-4 font-bold">
                            Material UI's theme configuration options.
                        </a>
                    </Typography>
                    <Typography className="mb-16" component="p">
                        Theme configurations are located at <code>src/app/fuse-configs/themesConfig.js</code> file.
                    </Typography>

                    <FuseHighlight component="pre" className="language-jsx">
                        {/* {require('!raw-loader!app/fuse-configs/themesConfig.js')} */}

                        {`
import {fuseDark} from '@fuse/fuse-colors';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';

const themesConfig = {
    default    : {
        palette: {
            type     : 'light',
            primary  : fuseDark,
            secondary: {
                light: lightBlue[400],
                main : lightBlue[600],
                dark : lightBlue[700]
            },
            error    : red
        },
        status : {
            danger: 'orange'
        }
    },
    sunset     : {
        palette: {
            type     : 'light',
            primary  : {
                light: '#ff908b',
                main : '#d0605e',
                dark : '#9b3134'
            },
            secondary: {
                light       : '#c76a1d',
                main        : '#ff994c',
                dark        : '#ffca7b',
                contrastText: '#fff'
            },
            error    : red
        },
        status : {
            danger: 'orange'
        }
        ...
    }
};

export default themesConfig;

        `}
                    </FuseHighlight>

                </div>
            }
            />
        )
    }
}

export default withStyles(styles, {withTheme: true})(FuseTheming);
