import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
    debiteurTurnover: {},
    financialGroups: null,
    financialGroupsAllCustomers: null,
    financialGroupsMarges: null,
    blocks: null,
    tables: null,
    creditCustomers: null,
    turnover: null,
    financieleGroepen: null,
    koersen: null,

    overview: null,
    pastYearOverview: null,
    targetOverview: null,
    margesOverview: null,

    orderFrequency: null,
    
};

export const fetchHbxDecoDebiteurTurnoverStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const fetchHbxDecoDebiteurTurnoverSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, debiteurTurnover: action.debiteurTurnover});
}
export const fetchHbxDecoDebiteurTurnoverFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

export const fetchHbxDecoTurnoverFinancialGroupsStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const fetchHbxDecoTurnoverFinancialGroupsSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, financialGroups: action.financialGroups});
}
export const fetchHbxDecoTurnoverFinancialGroupsFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

export const fetchHbxDecoTurnoverFinancialGroupsAllCustomersStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const fetchHbxDecoTurnoverFinancialGroupsAllCustomersSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, financialGroupsAllCustomers: action.financialGroupsAllCustomers});
}
export const fetchHbxDecoTurnoverFinancialGroupsAllCustomersFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

export const fetchHbxDecoBlocksStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const fetchHbxDecoBlocksSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, blocks: action.blocksData});
}
export const fetchHbxDecoBlocksFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

export const fetchHbxDecoTablesStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const fetchHbxDecoTablesSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, tables: action.tablesData});
}
export const fetchHbxDecoTablesFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

export const fetchHbxDecoKoersenStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const fetchHbxDecoKoersenSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, koersen: action.koersen});
}
export const fetchHbxDecoKoersenFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

export const fetchHbxDecoTurnoverStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const fetchHbxDecoTurnoverSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, turnover: action.turnover});
}
export const fetchHbxDecoTurnoverFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

export const fetchHbxDecoFinancieleGroepenStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const fetchHbxDecoFinancieleGroepenSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, financieleGroepen: action.financieleGroepen});
}
export const fetchHbxDecoFinancieleGroepenFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

export const fetchHbxDecoFinancieleGroepenMargesStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const fetchHbxDecoFinancieleGroepenMargesSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, financialGroupsMarges: action.financialGroupsMarges});
}
export const fetchHbxDecoFinancieleGroepenMargesFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

export const fetchHbxDecoOrderFrequencyStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const fetchHbxDecoOrderFrequencySuccess = ( state, action ) => {
    return updateObject(state, {loading: false, orderFrequency: action.orderFrequency});
}
export const fetchHbxDecoOrderFrequencyFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

export const fetchHbxDecoCreditCustomersStart = ( state, action ) => {
    return updateObject(state, {loading: true, creditCustomers: []});
}
export const fetchHbxDecoCreditCustomersSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, creditCustomers: action.creditCustomers});
}
export const fetchHbxDecoCreditCustomersFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

// Overview
export const fetchHbxDecoOverviewStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const fetchHbxDecoOverviewSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, overview: action.overview});
}
export const fetchHbxDecoOverviewFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

export const fetchHbxDecoPastYearOverviewStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const fetchHbxDecoPastYearOverviewSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, pastYearOverview: action.pastYearOverview});
}
export const fetchHbxDecoPastYearOverviewFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

export const fetchHbxDecoReservationsOverviewStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const fetchHbxDecoReservationsOverviewSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, reservationsOverview: action.reservationsOverview});
}
export const fetchHbxDecoReservationsOverviewFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

export const fetchHbxDecoTargetOverviewStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const fetchHbxDecoTargetOverviewSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, targetOverview: action.targetOverview});
}
export const fetchHbxDecoTargetOverviewFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

export const fetchHbxDecoMargesOverviewStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const fetchHbxDecoMargesOverviewSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, margesOverview: action.margesOverview});
}
export const fetchHbxDecoMargesOverviewFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_HBXDECO_DEBITEUR_TURNOVER_START: return fetchHbxDecoDebiteurTurnoverStart( state, action );
        case actionTypes.FETCH_HBXDECO_DEBITEUR_TURNOVER_SUCCESS: return fetchHbxDecoDebiteurTurnoverSuccess( state, action );
        case actionTypes.FETCH_HBXDECO_DEBITEUR_TURNOVER_FAIL: return fetchHbxDecoDebiteurTurnoverFail( state, action );

        case actionTypes.FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_START: return fetchHbxDecoTurnoverFinancialGroupsStart( state, action );
        case actionTypes.FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_SUCCESS: return fetchHbxDecoTurnoverFinancialGroupsSuccess( state, action );
        case actionTypes.FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_FAIL: return fetchHbxDecoTurnoverFinancialGroupsFail( state, action );

        case actionTypes.FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_ALL_CUSTOMERS_START: return fetchHbxDecoTurnoverFinancialGroupsAllCustomersStart( state, action );
        case actionTypes.FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_ALL_CUSTOMERS_SUCCESS: return fetchHbxDecoTurnoverFinancialGroupsAllCustomersSuccess( state, action );
        case actionTypes.FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_ALL_CUSTOMERS_FAIL: return fetchHbxDecoTurnoverFinancialGroupsAllCustomersFail( state, action );

        case actionTypes.FETCH_HBXDECO_FINANCIELE_GROEPEN_MARGES_START: return fetchHbxDecoFinancieleGroepenMargesStart( state, action );
        case actionTypes.FETCH_HBXDECO_FINANCIELE_GROEPEN_MARGES_SUCCESS: return fetchHbxDecoFinancieleGroepenMargesSuccess( state, action );
        case actionTypes.FETCH_HBXDECO_FINANCIELE_GROEPEN_MARGES_FAIL: return fetchHbxDecoFinancieleGroepenMargesFail( state, action );

        case actionTypes.FETCH_HBXDECO_DASHBOARD_BLOCKS_START: return fetchHbxDecoBlocksStart( state, action );
        case actionTypes.FETCH_HBXDECO_DASHBOARD_BLOCKS_SUCCESS: return fetchHbxDecoBlocksSuccess( state, action );
        case actionTypes.FETCH_HBXDECO_DASHBOARD_BLOCKS_FAIL: return fetchHbxDecoBlocksFail( state, action );

        case actionTypes.FETCH_HBXDECO_DASHBOARD_TABLES_START: return fetchHbxDecoTablesStart( state, action );
        case actionTypes.FETCH_HBXDECO_DASHBOARD_TABLES_SUCCESS: return fetchHbxDecoTablesSuccess( state, action );
        case actionTypes.FETCH_HBXDECO_DASHBOARD_TABLES_FAIL: return fetchHbxDecoTablesFail( state, action );

        case actionTypes.FETCH_HBXDECO_DASHBOARD_KOERSEN_START: return fetchHbxDecoKoersenStart( state, action );
        case actionTypes.FETCH_HBXDECO_DASHBOARD_KOERSEN_SUCCESS: return fetchHbxDecoKoersenSuccess( state, action );
        case actionTypes.FETCH_HBXDECO_DASHBOARD_KOERSEN_FAIL: return fetchHbxDecoKoersenFail( state, action );

        case actionTypes.FETCH_HBXDECO_TURNOVER_START: return fetchHbxDecoTurnoverStart( state, action );
        case actionTypes.FETCH_HBXDECO_TURNOVER_SUCCESS: return fetchHbxDecoTurnoverSuccess( state, action );
        case actionTypes.FETCH_HBXDECO_TURNOVER_FAIL: return fetchHbxDecoTurnoverFail( state, action );

        case actionTypes.FETCH_HBXDECO_FINANCIELE_GROEPEN_START: return fetchHbxDecoFinancieleGroepenStart( state, action );
        case actionTypes.FETCH_HBXDECO_FINANCIELE_GROEPEN_SUCCESS: return fetchHbxDecoFinancieleGroepenSuccess( state, action );
        case actionTypes.FETCH_HBXDECO_FINANCIELE_GROEPEN_FAIL: return fetchHbxDecoFinancieleGroepenFail( state, action );

        case actionTypes.FETCH_HBXDECO_ORDER_FREQUENCY_START: return fetchHbxDecoOrderFrequencyStart( state, action );
        case actionTypes.FETCH_HBXDECO_ORDER_FREQUENCY_SUCCESS: return fetchHbxDecoOrderFrequencySuccess( state, action );
        case actionTypes.FETCH_HBXDECO_ORDER_FREQUENCY_FAIL: return fetchHbxDecoOrderFrequencyFail( state, action );

        case actionTypes.FETCH_HBXDECO_CREDIT_CUSTOMERS_START: return fetchHbxDecoCreditCustomersStart( state, action );
        case actionTypes.FETCH_HBXDECO_CREDIT_CUSTOMERS_SUCCESS: return fetchHbxDecoCreditCustomersSuccess( state, action );
        case actionTypes.FETCH_HBXDECO_CREDIT_CUSTOMERS_FAIL: return fetchHbxDecoCreditCustomersFail( state, action );

        // Overview
        case actionTypes.FETCH_HBXDECO_OVERVIEW_START: return fetchHbxDecoOverviewStart( state, action );
        case actionTypes.FETCH_HBXDECO_OVERVIEW_SUCCESS: return fetchHbxDecoOverviewSuccess( state, action );
        case actionTypes.FETCH_HBXDECO_OVERVIEW_FAIL: return fetchHbxDecoOverviewFail( state, action );

        case actionTypes.FETCH_HBXDECO_PASTYEAR_OVERVIEW_START: return fetchHbxDecoPastYearOverviewStart( state, action );
        case actionTypes.FETCH_HBXDECO_PASTYEAR_OVERVIEW_SUCCESS: return fetchHbxDecoPastYearOverviewSuccess( state, action );
        case actionTypes.FETCH_HBXDECO_PASTYEAR_OVERVIEW_FAIL: return fetchHbxDecoPastYearOverviewFail( state, action );

        case actionTypes.FETCH_HBXDECO_RESERVATIONS_OVERVIEW_START: return fetchHbxDecoReservationsOverviewStart( state, action );
        case actionTypes.FETCH_HBXDECO_RESERVATIONS_OVERVIEW_SUCCESS: return fetchHbxDecoReservationsOverviewSuccess( state, action );
        case actionTypes.FETCH_HBXDECO_RESERVATIONS_OVERVIEW_FAIL: return fetchHbxDecoReservationsOverviewFail( state, action );

        case actionTypes.FETCH_HBXDECO_TARGET_OVERVIEW_START: return fetchHbxDecoTargetOverviewStart( state, action );
        case actionTypes.FETCH_HBXDECO_TARGET_OVERVIEW_SUCCESS: return fetchHbxDecoTargetOverviewSuccess( state, action );
        case actionTypes.FETCH_HBXDECO_TARGET_OVERVIEW_FAIL: return fetchHbxDecoTargetOverviewFail( state, action );

        case actionTypes.FETCH_HBXDECO_MARGES_OVERVIEW_START: return fetchHbxDecoMargesOverviewStart( state, action );
        case actionTypes.FETCH_HBXDECO_MARGES_OVERVIEW_SUCCESS: return fetchHbxDecoMargesOverviewSuccess( state, action );
        case actionTypes.FETCH_HBXDECO_MARGES_OVERVIEW_FAIL: return fetchHbxDecoMargesOverviewFail( state, action );

        default:
            return state;
    }
};

export default reducer;