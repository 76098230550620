import React, {Component} from 'react';
import {Button, Dialog, Icon, Typography, Toolbar, AppBar, TextField,
    IconButton, Tooltip, Card, CardHeader, Divider, CardContent} from '@material-ui/core';
import Alert from '../../../../components/UI/Alert/Alert';
// import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import {getUsername} from '../../../Auth/Auth';
import * as actions from '../../../../store/crm/actions';
import { connect } from 'react-redux';

class ReturnNewLeadsUserDialog extends Component {

    handleClose = () => {
        this.props.onClose();
        this.setState({userInput: ''});
    }

    handleInputChange = event => {
        // this.setState({userInput: event.target.value});
        this.setState({
            form: {...this.state.form, [event.target.name]: event.target.value},
        })
    }

    deleteNewLeadSuccess = (isDeleteNewLead) => {
        this.setState({isDeleted: isDeleteNewLead})
    }

    render()
    {
        const {dialogOpen, t, newLeadForm, handleChange, isDeleted, afterDelete} = this.props;
        // var isDeleted = false;
        
        // console.log(isDeleted);

        return (
            
                <Dialog 
                    classes={{
                        paper: "m-24"
                    }}
                    onClose={this.handleClose}
                    open={dialogOpen}
                    fullWidth
                    maxWidth="sm"
                >
                <AppBar position="static" elevation={1}>
                    <Toolbar className="flex w-full">
                        <Tooltip title="Go back">
                            <IconButton aria-label="Return" size="small" color="secondary" onClick={this.handleClose}>
                                <Icon>arrow_back</Icon>
                            </IconButton>
                        </Tooltip>
                        <Typography variant="subtitle1" color="inherit" className="ml-16">
                            {t('RETURN')} {t('NEWLEAD')}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Card className="w-full">
                    {isDeleted === true ? null :
                    <>
                    <CardHeader
                        // style={{backgroundColor: '#3C4252'}}
                        title={newLeadForm.debnaam}
                        subheader={newLeadForm.email}
                    /> 
                    <Divider />
                    </>
                    }
                    <CardContent className="p-24">
                    {isDeleted === true ? 
                    <Alert title={t('DELETEDNEWLEADSUCCESS')} status="AlertSuccess" />
                    :
                    <>
                        <p>{newLeadForm.debadres}<br/>
                        {newLeadForm.debpc} - {newLeadForm.debplaats}</p>
                        <br/><br/>
                        <TextField
                            className="mb-24"
                            id="acctmngR_NOTE"
                            name="acctmngR_NOTE"
                            label={t('NOTE')}
                            value={newLeadForm.acctmngR_NOTE}
                            onChange={handleChange}
                            variant="outlined"
                            required
                            fullWidth
                        />
                        <br />
                    <div className="flex flex-wrap">
                        <div className="sm:w-1/2 md:w-1/2 lg:w-1/2 p-12">
                            <p className="float-right"><i><b>{getUsername()}</b>, {t('AREYOUSURE')}</i></p>
                        </div>
                        <div className="flex sm:w-1/2 md:w-1/2 lg:w-1/2 p-12">
                            <div className="w-1/2 p-6">
                                <Button onClick={this.handleClose} fullWidth variant="contained" color="secondary">{t('NO')}</Button>
                                </div>
                            <div className="w-1/2 p-6">
                                <Button onClick={() => this.props.onDeleteNewLead(newLeadForm, afterDelete)} fullWidth variant="contained" color="primary">{t('YES')}</Button>
                            </div>
                        </div>
                    </div>
                    </>
                    }
                    </CardContent>
                </Card>
                {/* <DialogContent classes={{root: "p-24"}}>
                </DialogContent> */}
                </Dialog>
        );
    }
}

const mapStateToProps = ({customer}) => {
    return {
        isDeleted: customer.isDeleted
    }
}

const mapDispatchToProps = dispatch => {
    return {
         onDeleteNewLead: (newLeadForm, afterDelete) => dispatch(actions.deleteNewLead(newLeadForm, afterDelete, getUsername()))
    }
 }
 
 export default connect(mapStateToProps, mapDispatchToProps) (withTranslation()(ReturnNewLeadsUserDialog));
