import React from 'react';
import {FusePageSimple} from '../../../../@fuse';
import { Icon, Typography, Tooltip, Fab, FormControlLabel, Switch } from '@material-ui/core';
import { connect } from 'react-redux';
import * as actions from '../../../store/task/actions';
import TasksTable from './TasksTable';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { getAzureId } from '../../Auth/Auth';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Tasks = ({ tasks, onFetchTasks }) => {
    React.useEffect(() => {
        onFetchTasks((tasks) => {
            if(tasks.some(t => !t.concluded && moment(t.endDate).isBefore(moment(new Date())))) {
                setOpen(true);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const { t } = useTranslation();

    const [filterConcluded, setFilterConcluded] = React.useState(false);
    const [showAssignedTasks, setShowAssignedTasks] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const filterOutConcludeisTrue = () => {
        setFilterConcluded(!filterConcluded);
    }

    const getFinalData = () => {
        let filteredTasks = [];
        if(!showAssignedTasks) {
            filteredTasks = tasks.filter(task => task.azureId === getAzureId());
        } else {
            filteredTasks = tasks.filter(task => task.azureId !== getAzureId() && (task.createdBy !== task.azureId && task.createdBy !== 'BI Retention'))
        }
        if(filterConcluded) {
            filteredTasks = filteredTasks.filter(task => task.concluded || task.isArchive);
        } else {
            filteredTasks = filteredTasks.filter(task => !task.concluded);
        }
        return filteredTasks;
        // return tasks.filter(task => ((!showAssignedTasks && (task.createdBy === task.azureId || task.createdBy === 'BI Retention')) 
        // || (showAssignedTasks && task.createdBy !== task.azureId && task.createdBy !== 'BI Retention')) 
        // && ((!filterConcluded && !task.concluded)|| (filterConcluded && (task.concluded || task.isArchive))));
    }

    return (
        <>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
                {t('open_expired_tasks')}
            </Alert>
        </Snackbar>
        <FusePageSimple
                header={
                      <div className="flex flex-1 items-center justify-between p-24">
                         <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                  <Icon className="text-18" color="action">home</Icon>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">iCRM</Typography>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">{t("TASKS")}</Typography>
                            </div>
                            <Typography variant="h6">{t("TASKS")}</Typography>
                         </div>
                         
                        <div className="flex items-end">
                            <Tooltip title={'Filter ' + t("show_assigned_tasks")}>
                                <FormControlLabel
                                value="top"
                                control={
                                    <Switch
                                    checked={showAssignedTasks}
                                    onChange={() => setShowAssignedTasks(!showAssignedTasks)}
                                    color="secondary"
                                    />}
                                label={t("show_assigned_tasks")}
                                labelPlacement="top"
                                />
                            </Tooltip>
                            <Tooltip title={'Filter ' + t("concluded")}>
                                <FormControlLabel
                                value="top"
                                control={
                                    <Switch
                                    checked={filterConcluded}
                                    onChange={filterOutConcludeisTrue}
                                    color="secondary"
                                    />}
                                label={t("archive")}
                                labelPlacement="top"
                                />
                            </Tooltip>
                            <Link to={`/task/new`}>
                                <Tooltip title={t("CREATENEWTASK")}>
                                    <Fab aria-label="Add">
                                        <Icon>add_task</Icon>
                                    </Fab>
                                </Tooltip>
                            </Link>
                        </div>
                    </div>
                }
                content={
                    <TasksTable data={getFinalData()} />
                }
            />
        </>
    );
};

const mapStateToProps = ({ task }) => ({
    tasks: task.tasks
});

const mapDispatchToProps = (dispatch) => ({
    onFetchTasks: (afterFetchTasksSuccess) => dispatch(actions.fetchTasks(afterFetchTasksSuccess))
})

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);