import React, {Component} from 'react';
import {TextField, Button, Dialog, DialogActions, DialogContent, Icon, Typography, Toolbar, AppBar, 
    Avatar, FormControl, OutlinedInput, Select, MenuItem, IconButton, Tooltip} from '@material-ui/core';
import _ from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';

const user = {
    // azureId: '',
    name: '',
    email: '',
    password: '',
    accountManager: '',
    accountManagers: [],
    groupCode: 0,
    companyId: 1,
    target: 5,
    roles: [],
}

class CreateUserDialog extends Component {

    //properties: accountManager, azureId, companyId, groupCode, email, groups, id, imageUrl, name
    state = {
        ...user,
        groups: []
    };

    componentDidMount() {
        this.setState({ companyId: this.props.companies[0].id, roles: [this.props.roles[0]] }
            , () => this.filterGroupsByCompany(this.state.companyId)
            );
    }

    handleChange = (event) => {
        const {name, type, checked, value} = event.target;
        this.setState(_.set({...this.state}, name, type === 'checkbox' ? 
            checked : value));
        if(name === 'companyId') {
            this.filterGroupsByCompany(value);
        }
    };

    handleRolesChange = (roles) => {
        this.setState({ roles })
    }

    handleAccountManagerChange = (accountManagers) => {
        this.setState({ accountManagers })
    }

    canBeSubmitted()
    {
        const {companyId, groupCode, email, name, target, password} = this.state;
        const groupExists = this.state.groups.map(group => group.groupCode).includes(Number(groupCode));
        if(groupExists) {
            return (
                companyId > 0 && email.length > 0 && name.length > 0 && target > 0 && password.length > 7
            );
        } else {
            return 'Group does not exist';
        }
    }

    filterGroupsByCompany = value => {
        const groups = this.props.groups.filter(group => group.companyId === value);
        this.setState({groups});
    }

    handleSave = () => {
        this.props.onSave({...this.state});
    }

    handleClose = () => {
        this.props.onClose();
    }

    render()
    {
        const {dialogOpen, companies} = this.props;

        return (
            Object.keys(this.state).length > 0 ? 
                <Dialog 
                    classes={{
                        paper: "m-24"
                    }}
                    onClose={this.handleClose}
                    open={dialogOpen}
                    fullWidth
                    maxWidth="sm"
                >
                    <AppBar position="static" elevation={1}>
                        <Toolbar className="flex w-full">
                            <Tooltip title="Close">
                                <IconButton aria-label="Delete" size="small" color="secondary" onClick={this.handleClose}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </Tooltip>
                            <Typography variant="subtitle1" color="inherit" className="ml-16">
                                Create User
                            </Typography>
                        </Toolbar>
                        <div className="flex flex-col items-center justify-center pb-24">
                            <Avatar style={{backgroundColor: '#ef6c00'}} className="w-96 h-96" alt="contact avatar">
                                {this.state.name === '' ? 'X' : this.state.name.substr(0, 1)}
                            </Avatar>
                            <Typography variant="h6" color="inherit" className="pt-8">
                                {this.state.name}
                            </Typography>
                        </div>
                    </AppBar>

                    <DialogContent classes={{root: "p-24"}}>
                        <>
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">title</Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label="Name"
                                autoFocus
                                id="name"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange}
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </div>

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">mail_outline</Icon>
                            </div>
                            <TextField
                                className="mb-24"
                                label="Email"
                                id="email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </div>

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">vpn_key</Icon>
                            </div>
                            <TextField
                                className="mb-24"
                                label="Password (min 8 characters)"
                                id="password"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                variant="outlined"
                                required
                                fullWidth
                                InputProps={{
                                    inputProps: {
                                        autoComplete: 'new-password',
                                        form: {
                                            autoComplete: "off"
                                        }
                                    },
                                }}
                            />
                        </div>

                        {/* <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">cloud_queue</Icon>
                            </div>
                            <TextField
                                className="mb-24"
                                id="azureId"
                                label="Azure ID"
                                name="azureId"
                                value={this.state.azureId}
                                onChange={this.handleChange}
                                variant="outlined"
                                disabled
                                required
                                fullWidth
                            />
                        </div> */}

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">contacts</Icon>
                            </div>  
                            <Autocomplete
                                className="mb-24 w-full"
                                multiple
                                id="accountmanagers-select"
                                options={this.props.accountManagers}
                                getOptionLabel={(option) =>  option.accountManagerName + ` (${option.accountManager})`}
                                value={this.state.accountManagers}
                                onChange={(event, value) => this.handleAccountManagerChange(value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Select accountmanagers"
                                        placeholder="Start typing..."
                                    />
                                )}
                            />
                        </div>

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">business</Icon>
                            </div>
                            <FormControl variant="outlined" className="mb-24" fullWidth>
                                <Select
                                    value={this.state.companyId}
                                    onChange={this.handleChange}
                                    input={
                                    <OutlinedInput
                                        labelWidth={0}
                                        name="companyId"
                                        id="type"
                                    />
                                    }
                                    required
                                    fullWidth
                                >
                                    {companies.map(company => <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div> 
                        
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">people_outline</Icon>
                            </div>
                            <TextField  
                                className="mb-24"
                                id="groupCode"
                                label="Debiter group"
                                name="groupCode"
                                type="number"
                                value={this.state.groupCode}
                                onChange={this.handleChange}
                                variant="outlined"
                                error={typeof(this.canBeSubmitted()) === 'string'}
                                required
                                fullWidth
                            />
                        </div>
                        
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">lock</Icon>
                            </div>       
                            <Autocomplete
                                className="mb-24 w-full"
                                multiple
                                id="tags-standard"
                                options={this.props.roles}
                                getOptionLabel={(option) => option.name}
                                value={this.state.roles}
                                onChange={(event, value) => this.handleRolesChange(value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Select roles to assign"
                                        placeholder="Start typing..."
                                    />
                                )}
                            />
                        </div>

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">show_chart</Icon>
                            </div>
                            <TextField  
                                className="mb-24"
                                id="target"
                                label="Target"
                                name="target"
                                type="number"
                                value={this.state.target}
                                onChange={this.handleChange}
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </div>
                        </> 
                    </DialogContent>
                    <DialogActions className="justify-end pl-16">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleSave}
                            disabled={typeof(this.canBeSubmitted()) !== 'boolean' || !this.canBeSubmitted()}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            : null 
        );
    }
}

export default CreateUserDialog;
