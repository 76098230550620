import React from 'react';
import DefaultTaskOverview from './DefaultTaskOverview';
import { useTranslation } from 'react-i18next';

const TasksOverview = () => {  
    const { t } = useTranslation();

    return (
        <DefaultTaskOverview isArchive={false} title={t("TASKS") + " " + t("OVERVIEW")} />
    );
};

export default TasksOverview;