import React, { Component } from 'react';
import {FuseAnimateGroup, FusePageSimple} from '../../../../../@fuse';
import {Button, Icon, Typography} from '@material-ui/core';
import * as actions from '../../../../store/crm/actions';
import * as hbxDecoActions from '../../../../store/hbxdeco/actions';
import {connect} from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DashboardCharts from './DashboardComponents/DashboardCharts';
import DashboardBlocks from './DashboardComponents/DashboardBlocks';
import DashboardFrequencies from './DashboardComponents/DashboardFrequencies';
import DashboardTables from './DashboardComponents/DashboardTables';
import './Dashboard.css';
import {withTranslation} from 'react-i18next';
import DashboardDateButtons from './DashboardComponents/DashboardDateButtons';
import moment from 'moment';
import { getCompany } from '../../../Auth/Auth';
import DashboardKoersen from './DashboardComponents/DashboardKoersen';
import DashboardDecoMiddleCharts from './DashboardComponents/DashboardDecoMiddleCharts';
import DashboardDecoCharts from './DashboardComponents/DashboardDecoCharts';
import DashboardDecoTables from './DashboardComponents/DashboardDecoTables';

class Dashboard extends Component {
    state = {
        startDate: new Date(),
        endDate: new Date(),
        checkedWeeklyTurnover: true,
        checkedWeeklyFrequency: true,
    };

    componentDidMount() {
        let date = new Date();
        const isHbxDeco = getCompany() === 4;
        const isGreenflor = getCompany() === 3;
        if(isHbxDeco || isGreenflor) {
            date = moment().startOf('year');
        } else {
            date = moment(date).add(-365, 'days');
        }
        this.setState({startDate: date.toDate()});
        const startDate = date.format('YYYY-MM-DD');
        const endDate = moment(this.state.endDate).format('YYYY-MM-DD');
        this.fetchData(startDate, endDate, false)
    }

    fetchData = (startDate, endDate, shouldRefreshDistribution) => {
        const isHbxDeco = getCompany() === 4;
        const isGreenflor = getCompany() === 3;
        if(isHbxDeco) {
            this.props.onFetchHbxDecoTurnoverFinancialGroups();
            this.props.onFetchHbxDecoTurnoverFinancialGroupsAllCustomers();
            this.props.onFetchHbxDecoCreditCustomers(false);
            // this.props.onFetchHbxDecoFinancieleGroepenMarges();
            this.props.onFetchHbxDecoDashboardBlocks(startDate, endDate);
            this.props.onFetchHbxDecoDashboardTables(startDate, endDate);
            this.props.onFetchHbxDecoOrderFrequency(startDate, endDate);
            this.props.onFetchHbxDecoDashboardKoersen();
        } else if (isGreenflor) {
            this.props.onFetchHbxDecoTurnoverFinancialGroups();
            this.props.onFetchHbxDecoOrderFrequency(startDate, endDate);
            this.props.onFetchDashboardCharts(startDate, endDate);
            this.props.onFetchDashboardBlocks(startDate, endDate);
        } else {
            this.props.onFetchDashboardCharts(startDate, endDate);
            this.props.onFetchDashboardBlocks(startDate, endDate);
        }
        this.props.onFetchTurnoverDistribution(startDate, endDate, shouldRefreshDistribution);
        this.props.onFetchDashboardTables(startDate, endDate);
        this.props.onFetchOrderTurnover();
        this.props.onFetchDashboardCreditRedenVerdeling(startDate, endDate);
    }

    handleCreditToggleChange = (newValue) => {
        this.props.onFetchHbxDecoCreditCustomers(newValue);
    }

    handleStartDateChange = date => {
      this.setState({ startDate: date });
    };

    handleEndDateChange = date => {
      this.setState({ endDate: date });
    };

    handleSwitchChange = key => event => {
        this.setState({[key]: event.target.checked})
    }

    applyDates = () => {
        const startDate = this.state.startDate.toISOString().substring(0, 10);
        const endDate = this.state.endDate.toISOString().substring(0, 10);
        // this.props.onFetchTurnoverDistribution(startDate, endDate, true);
        // this.props.onFetchDashboardBlocks(startDate, endDate);
        // this.props.onFetchDashboardTables(startDate, endDate);
        // this.props.onFetchDashboardCharts(startDate, endDate);
        //this.props.onFetchOrderTurnover();
        this.fetchData(startDate, endDate, true);
    }

    handleDateButtonClick = buttonClicked => {
        let startDate = new Date();
        let endDate = new Date();
        switch(buttonClicked) {
            case 'yesterday':
                startDate.setDate(startDate.getDate() - 1);
                break;
            case 'thisweek': 
                startDate = this.getMonday(startDate);
                break;
            case 'thismonth': 
                startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
                break;
            case 'thisyear': 
                startDate = new Date(startDate.getFullYear(), 0, 1)
                break;
            case 'lastweek': 
                startDate.setDate(startDate.getDate()-7);
                startDate = this.getMonday(startDate);
                endDate = moment(startDate).add('days', 4).toDate();
                break;
            case 'lastmonth': 
                startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
                startDate.setHours(-1);
                startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
                startDate.setHours(-24);
                endDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
                break;
            case 'lastyear': 
                startDate = new Date(startDate.getFullYear() - 1, 0, 1);
                endDate = new Date(endDate.getFullYear() - 1, 11, 31);
                break;
            case 'defaultdate': 
                startDate.setDate(startDate.getDate() - 365);
                break;
            default: break;
        }
        this.setState({startDate, endDate}, this.applyDates);        
    }

    getMonday = date => {
        let day = date.getDay() || 7;  
        if( day !== 1 ) 
            date.setHours(-24 * (day - 1)); 
        return date;
    }

    render() {
        const isHbxDeco = getCompany() === 4;
        const isGreenflor = getCompany() === 3;
        const {orderTurnover, turnoverDistribution, dashboardBlocks, t} = this.props;
        const {startDate, endDate} = this.state;
        return (
            <FusePageSimple
                header={
                    <div className="flex flex-1 items-center justify-between p-24">
                        <div className="flex flex-wrap w-full">
                        {isHbxDeco ?
                        <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                <Icon className="text-18" color="action">home</Icon>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">iCRM</Typography>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">{t('DASHBOARD')}</Typography>
                            </div>
                            <Typography variant="h6">{t('DASHBOARD')}</Typography>
                            <Typography color="textSecondary" className="text-12">Van {new Date(startDate.getFullYear(), 0, 2).toISOString().substring(0, 10)} tot {this.state.endDate.toISOString().substring(0, 10)}</Typography>
                        </div>
                        :
                        <>
                            <div className="flex w-1/6 m-12">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        margin="normal"
                                        label="Start Date"
                                        minDate={new Date(startDate.getFullYear(), startDate.getMonth()-2, 1)}
                                        maxDate={endDate}
                                        value={startDate}
                                        format="MMM d, yyyy"
                                        onChange={this.handleStartDateChange}
                                        okLabel={<span style={{color: '#fff'}}>Ok</span>}
                                        cancelLabel={<span style={{color: '#fff'}}>Cancel</span>}
                                        disabled={isHbxDeco}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="flex w-1/6 m-12">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        margin="normal"
                                        disableFuture
                                        minDate={startDate+1}
                                        label="End Date"
                                        value={endDate}
                                        format="MMM d, yyyy"
                                        onChange={this.handleEndDateChange}
                                        okLabel={<span style={{color: '#fff'}}>Ok</span>}
                                        cancelLabel={<span style={{color: '#fff'}}>Cancel</span>}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="flex w-1/12 m-12">
                                <Button variant="contained" color="primary" className="m-16" onClick={this.applyDates}>
                                    {t("APPLY")}
                                </Button>
                            </div>
                        </>
                        }
                        </div>
                    </div>
                }
                content={
                <FuseAnimateGroup
                    enter={{
                        animation: "transition.slideUpBigIn"
                    }}
                >
                    <div className="p-12">
                        {
                            isHbxDeco ? 
                            <DashboardDecoCharts 
                                financialGroups={this.props.financialGroups}
                                financialGroupsAllCustomers={this.props.financialGroupsAllCustomers}
                                financialGroupsMarges={this.props.financialGroupsMarges}
                            />
                            :
                            <DashboardCharts 
                                turnoverDistribution={turnoverDistribution} 
                                orderTurnover={orderTurnover} 
                                loading={this.props.loadingTurnoverDistribution}
                                verdelingen={this.props.verdelingen}
                                financialGroups={this.props.financialGroups}
                                financialGroupsAllCustomers={this.props.financialGroupsAllCustomers}
                                isHbxDeco={isHbxDeco}
                                isGreenflor={isGreenflor}
                            /> 
                        }
                        
                        {isHbxDeco ? null : 
                        <div className="p-12">
                        <DashboardDateButtons t={t} handleDateButtonClick={this.handleDateButtonClick} />
                        </div>}
                        <DashboardBlocks blocksData={dashboardBlocks} hbxDecoBlocks={this.props.hbxDecoBlocks} marges={this.props.financialGroups} />
                        {(Object.keys(dashboardBlocks).length === 0 || Object.keys(this.props.dashboardCharts).length === 0) 
                            && turnoverDistribution.length === 0 
                            && Object.keys(orderTurnover).length === 0 ? 
                            null :
                            <>
                            {!isHbxDeco ? 
                                <DashboardFrequencies chartsData={this.props.dashboardCharts} 
                                    checkedWeeklyTurnover={this.state.checkedWeeklyTurnover} 
                                    checkedWeeklyFrequency={this.state.checkedWeeklyFrequency}
                                    handleSwitchChange={this.handleSwitchChange} />
                                :
                                <DashboardDecoMiddleCharts 
                                    financialGroups={this.props.financialGroups}
                                    orderFrequency={this.props.orderFrequency}
                                    verdelingen={this.props.verdelingen}
                                />
                            }
                            {!isHbxDeco ?
                                <DashboardTables tables={this.props.dashboardTables} />
                                :
                                <DashboardDecoTables tables={this.props.dashboardTables} hbxDecoTables={this.props.hbxDecoTables} creditCustomers={this.props.creditCustomers}
                                    handleCreditToggleChange={this.handleCreditToggleChange} />
                            }
                            </> 
                        }
                        {isHbxDeco && <DashboardKoersen koersen={this.props.koersen} />}
                    </div>
                </FuseAnimateGroup> 
                }
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        orderTurnover: state.crmDashboard.orderTurnover,
        turnoverDistribution: state.crmDashboard.turnoverDistribution,
        dashboardBlocks: state.crmDashboard.dashboardBlocks,
        dashboardTables: state.crmDashboard.dashboardTables,
        dashboardCharts: state.crmDashboard.dashboardCharts,
        loadingTurnoverDistribution: state.crmDashboard.loadingTurnoverDistribution,
        verdelingen: state.crmDashboard.verdelingen,
        hbxDecoBlocks: state.hbxdeco.blocks,
        hbxDecoTables: state.hbxdeco.tables,
        koersen: state.hbxdeco.koersen,
        financialGroups: state.hbxdeco.financialGroups,
        financialGroupsMarges: state.hbxdeco.financialGroupsMarges,
        financialGroupsAllCustomers: state.hbxdeco.financialGroupsAllCustomers,
        orderFrequency: state.hbxdeco.orderFrequency,
        creditCustomers: state.hbxdeco.creditCustomers
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchOrderTurnover: () => dispatch(actions.fetchDbOrderTurnover()),
        onFetchTurnoverDistribution: (startDate, endDate, refreshed) => dispatch(actions.fetchDbTurnoverDistribution(startDate, endDate, refreshed)),
        onFetchDashboardBlocks: (startDate, endDate) => dispatch(actions.fetchDbDashboardBlocks(startDate, endDate)),
        onFetchDashboardTables: (startDate, endDate) => dispatch(actions.fetchDbDashboardTables(startDate, endDate)),
        onFetchDashboardCharts: (startDate, endDate) => dispatch(actions.fetchDbDashboardCharts(startDate, endDate)),
        onFetchDashboardCreditRedenVerdeling: (startDate, endDate) => dispatch(actions.fetchDashboardCreditRedenVerdeling(startDate, endDate)),
        onFetchHbxDecoTurnoverFinancialGroups: (startDate, endDate) => dispatch(hbxDecoActions.fetchHbxDecoTurnoverFinancialGroups(startDate, endDate)),
        onFetchHbxDecoTurnoverFinancialGroupsAllCustomers: (startDate, endDate) => dispatch(hbxDecoActions.fetchHbxDecoTurnoverFinancialGroupsAllCustomers(startDate, endDate)),
        onFetchHbxDecoDashboardBlocks: (startDate, endDate) => dispatch(hbxDecoActions.fetchHbxDecoDashboardBlocks(startDate, endDate)),
        onFetchHbxDecoDashboardTables: (startDate, endDate) => dispatch(hbxDecoActions.fetchHbxDecoDashboardTables(startDate, endDate)),
        onFetchHbxDecoOrderFrequency: (startDate, endDate) => dispatch(hbxDecoActions.fetchHbxDecoOrderFrequency(startDate, endDate)),
        onFetchHbxDecoCreditCustomers: (sortByCreditValue) => dispatch(hbxDecoActions.fetchHbxDecoCreditCustomers(sortByCreditValue)),
        onFetchHbxDecoDashboardKoersen: () => dispatch(hbxDecoActions.fetchHbxDecoDashboardKoersen()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dashboard));