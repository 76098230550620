import React from 'react';
import LineChart from '../../../../../components/UI/Charts/RctJSCharts/LineChart/LineChart';
import moment from 'moment'

const customerWeblogVisits = props => {
    const {themeColors, weblogVisits} = props;
    const visits = weblogVisits.map(row => row.visits);
    let dataset = [];
    if(weblogVisits) {
        dataset = [{
            label: 'Weblog Visits',
            fill: false,
            lineTension: 0.5,
            backgroundColor: themeColors[1],
            borderColor: themeColors[1],
            pointRadius: 4,
            pointHoverRadius: 5,
            data: visits
        }];
    }
    const labels = weblogVisits.map(row => moment(row.date).format('MMM Do'));
    return (
        <LineChart labels={labels} datasets={dataset} height={330} maintainAspectRatio={false} />
    )
}

export default customerWeblogVisits;