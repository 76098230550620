import React, { Component } from "react";
import { Typography, Card,  CardContent, Toolbar, AppBar } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import {Skeleton} from '@material-ui/lab';
import { Scrollbars } from 'react-custom-scrollbars';

class TaskDetailFormSkeleton extends Component {
    // shouldComponentUpdate(nextProps, nextState) {
    //     return nextProps.timeLine.length !== this.props.timeLine.length;
    // }

    render() {
        const { t } = this.props;

        return (
            <Card className="w-full rounded-8 shadow-none border-1">
                <AppBar position="static" elevation={0}>
                    <Toolbar>
                        <Typography>{t("TIMELINE")}</Typography>
                    </Toolbar>
                </AppBar>
                <CardContent>
                    <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={600}>
                        <React.Fragment>
                            <br /><br />
                            <Skeleton className="mb-24" variant="text" width={'50%'} height={65} style={{margin: '0 auto'}} />
                            <Skeleton className="mb-24" variant="text" width={'50%'} height={65} style={{margin: '0 auto'}} />
                        </React.Fragment>
                    </Scrollbars>
                </CardContent>
            </Card>
        );
    }
};

export default withTranslation()(TaskDetailFormSkeleton);