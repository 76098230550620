import React, { Component } from 'react';
import ReactTable from "react-table";
import {FuseAnimate, FusePageSimple} from '../../../../@fuse';
import {Icon, Typography, LinearProgress, Tooltip, Button} from '@material-ui/core';
import {fetchUserLogsByPage} from '../../../store/monitor/actions'
import { connect } from 'react-redux';
import {withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import UserStatsManagerAddUsers from '../UserStats/UserStatsManagerAddUsers';
import Alert from '../../../components/UI/Alert/Alert'

class UserLogsByPage extends Component {
    state = {
        dialogOpen: false
    }

    componentDidMount() {
        console.log(this.props);
        if(!this.props.location.state?.page) {
            this.props.history.push('/stats/pages');
        }
        this.props.onFetchUserLogsByPage(this.props.location.state?.page);
    }

    render()
    {
        const {userLogsByPage, loading, t} = this.props;
        return (
            <>
            {this.state.dialogOpen && <UserStatsManagerAddUsers handleClose={() => this.setState({ dialogOpen: false })} />}
            <FusePageSimple
            header={
                <div className="flex flex-1 items-center justify-between p-24">
                    <div className="flex flex-col">
                        <div className="flex items-center mb-16">
                            <Icon className="text-18" color="action">home</Icon>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">{t("PAGE_STATS")}</Typography>
                            {/* <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">{t("VISITS_PER_USER")}</Typography> */}
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">{this.props.location.state?.page}</Typography>
                        </div>
                        <Typography variant="h6">{t("VISITS_PER_USER")}</Typography>
                    </div>
                        <div className="flex items-end">
                            <Tooltip title={t("EDIT") + " Favorite users"}>
                                <Button className="successButton p-24" onClick={() => this.setState({ dialogOpen: true })}>
                                    <Icon>star</Icon>
                                </Button>
                            </Tooltip>
                        </div>
                </div>
            }
            content={!loading && userLogsByPage.length === 0 ?
                <Alert status="AlertWarning" title="Je hebt nog geen favoriete gebruikers toegewezen. Klik op het sterretje rechtsbovenin de pagina om te selecteren welke gebruikers je wil zien."></Alert>
                : !loading ? 
                <FuseAnimate animation="transition.slideUpIn" delay={300}>
                    <div className="w-full">
                        <ReactTable
                            className="-striped -highlight border-0"
                            data={userLogsByPage}
                            columns={[
                                {
                                    Header    : t("NAME"),
                                    accessor  : "userName",
                                    filterable: true,
                                    className : "font-bold justify-center",
                                    Cell: (row) => <Link to={"/stats/user/" + row.original.azureId}>{row.value}</Link>
                                },
                                {
                                    Header    : "Aantal bezoeken",
                                    accessor  : "amountOfVisits",
                                    filterable: true,
                                    className: "justify-center",
                                },
                                {
                                    Header    : "Gem. tijd op pagina",
                                    accessor  : "avgTimeOnPage",
                                    filterable: true,
                                    className: "justify-center",
                                    width     : 150,
                                    Cell: ({value}) => new Date(value * 1000).toISOString().substr(14, 5)

                                },
                            ]}
                            pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                            defaultPageSize={15}
                            noDataText="No customers found"
                        /> 
                    </div>
                </FuseAnimate>
            : <div className="flex h-full">
                <div className="m-auto">
                    <LinearProgress />
                    <Typography>Fetching statistics...</Typography>
                </div>
                </div> 
            }
            />
            </>
        )
    }
}

const mapStateToProps = ({ monitor }) => {
    return {
        userLogsByPage: monitor.userLogsByPage,
        loading: monitor.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchUserLogsByPage: (page) => dispatch(fetchUserLogsByPage(page))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserLogsByPage));