import React, { Component } from 'react';
import {FusePageSimple, FuseAnimate} from '../../../../../@fuse'
import {Icon, Typography, Tooltip, Switch, FormControlLabel} from '@material-ui/core';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import ReactTable from "react-table";
import * as actions from '../../../../store/crm/actions';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import moment from 'moment';

class LoginHistory extends Component {

    state = {
        filterUnsuccessful: false
    }
    
    filterData = () => {
    if(this.state.filterUnsuccessful) {
       return this.props.data.filter(u => u.extraInfo === 'unsuccessfull' );
        }
        return this.props.data;
    }

    componentDidMount(){
        this.props.onFetchLoginHistory();
        // this.props.onFetchWeblogVisits();
    }

    render() {
        const {data, loading, t} = this.props;
        return (
            <FusePageSimple
                header={
                    <div className="flex flex-1 items-center justify-between p-24">
                        <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                <Icon className="text-18" color="action">home</Icon>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">iCRM</Typography>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">{t("SETTINGS")}</Typography>
                            </div>
                            <Typography variant="h6">{t("LOGIN_HISTORY")}</Typography>
                        </div>
                  <div className="flex flex-1 items-center justify-end px-12">
                     <Tooltip title={t('FILTER_UNSUCCESSSFUL')}>
                        <FormControlLabel
                        value="top"
                        control={
                           <Switch
                           checked={this.state.filterUnsuccessful}
                           onChange={() => {this.setState({filterUnsuccessful: !this.state.filterUnsuccessful})}}
                           color="secondary"
                           />}
                        label={t('FILTER')}
                        labelPlacement="top"
                        />
                     </Tooltip>
                  </div>
                    </div>
                }
                content={
                <>
                {loading ? <Spinner /> : null}
                {data.length > 0 && !loading ?
                    <FuseAnimate animation="transition.slideUpIn" delay={300}>
                        <div className="w-full">
                            <ReactTable
                                className="-striped -highlight border-0"
                                // data={data}
                                data={this.filterData()}
                                columns={[
                                    {
                                        // Header    : t("LOGTIME"),
                                        accessor  : "extraInfo",
                                        // filterable: true,
                                        width: 50,
                                        className: "justify-center",
                                        Cell: row => row.value === 'unsuccessful' ? 
                                        <Tooltip title={t("CON_UNSUCCESS")}><Icon className="text-18" color="error">close</Icon></Tooltip>
                                        :
                                        <Tooltip title={t("CON_SUCCESS")}><Icon className="text-18" style={{color: 'green'}}>check</Icon></Tooltip>,
                                    },
                                    {
                                        Header    : "IP",
                                        accessor  : "ip",
                                        filterable: true,
                                        // width: 150,
                                        className : "font-bold",
                                        Cell: row => row.value === "" ? "empty" : row.value,
                                        // Cell: row => row.value === "" ? "row.value" : <Tooltip title={t('own_stats_button')}>{row.value}</Tooltip>,
                                    },
                                    {
                                        Header    : "Date",
                                        accessor  : "startDate",
                                        filterable: true,
                                        // width: 200,
                                        // className : "font-bold",
                                        Cell: row => (moment(row.value).format('YYYY-MM-DD @ HH:mm'))
                                    },
                                    {
                                        Header    : t("CITY"),
                                        accessor  : "city",
                                        filterable: true,
                                        // width: 180,
                                        Cell: row => (
                                            <a href={row.original.gpsLoc} target={'_blank'}>{row.original.city} ({row.original.zipcode})</a>
                                        )
                                    },
                                    {
                                        Header    : t("REGION"),
                                        accessor  : "region",
                                        filterable: true,
                                        // width: 250,
                                        // className: "justify-center"
                                    },
                                    {
                                        // Header    : t("COUNTRY"),
                                        accessor  : "country",
                                        filterable: true,
                                        width: 50,
                                        // className: "justify-center"
                                    },
                                ]}
                                defaultPageSize={20}
                                noDataText="No rows found"
                            /> 
                        </div>
                    </FuseAnimate>
                : null }
                </>
                }
            />
        );
    }
}


const mapStateToProps = state => {
    return {
        loading: state.profile.loading,
        data: state.profile.weblog
        // weblogVisits: state.weblog.weblogVisits
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchLoginHistory: () => dispatch(actions.fetchLoginHistory()),
        // onFetchWeblogVisits: () => dispatch(actions.fetchDbWeblogVisits())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginHistory));
// export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginHistory));
// export default withTranslation()(LoginHistory);