import React, {Component} from 'react';
import ReactTable from "react-table";
import {FuseAnimate, FusePageSimple} from '../../../../../@fuse';
import {withStyles, Icon, Typography, IconButton, Tooltip, Badge, LinearProgress, FormControlLabel, Switch } from '@material-ui/core';
import {fetchDbUnpaid, downloadUnpaid} from '../../../../store/crm/actions'
import { connect } from 'react-redux';
import RouteToDebnr from '../../../../components/Functions/RouteToDebnr';
import {withTranslation} from 'react-i18next';
import _ from 'lodash';

const styles = theme => ({
    layoutRoot: {},
    root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    },
    table: {
    minWidth: 700,
    },
    row: {
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
    },
    },
});

class Unpaid extends Component {

   state = {
      filterIncasso: false
   }

   constructor(props) {
      super(props);
      this.reactTable = React.createRef();
   }

   componentDidMount() {
      this.props.onFetchUnpaid();
   }

   downloadUnpaid = () => {
      const {page, pageSize} = this.reactTable.state;
      const data = this.reactTable.getResolvedState().sortedData;
      const startIndex = page * pageSize;
      const filteredColumns = data.slice(startIndex, startIndex + pageSize).map(row => _.pick(row, ['levStop', 'debnr', 'name', 'unpaidTotal', 'lastUnpaid', 'closesIn', 'maxDaysToPay']));
      console.log(filteredColumns);

      this.props.onDownloadUnpaid(filteredColumns);
   }

   filterUnpaid = () => {
      if(this.state.filterIncasso) {
         return this.props.unpaid.filter(u => !u.isIncasso);
      }
      return this.props.unpaid;
   }

   render()
   {
      const {classes, loading, unpaid, t} = this.props;
      return (
         <FusePageSimple
         classes={{
               root: classes.layoutRoot
         }}
         header={
               <div className="flex flex-1 items-center justify-between p-24">
                  <div className="flex flex-col">
                     <div className="flex items-center mb-16">
                           <Icon className="text-18" color="action">home</Icon>
                           <Icon className="text-16" color="action">chevron_right</Icon>
                           <Typography color="textSecondary">iCRM</Typography>
                           <Icon className="text-16" color="action">chevron_right</Icon>
                           <Typography color="textSecondary">{t("UNPAID")}</Typography>
                     </div>
                     <Typography variant="h6">{t("UNPAID")}</Typography>
                  </div>
                  <div className="flex flex-1 items-center justify-end px-12">
                     <Tooltip title={'Filter out incasso customers'}>
                        <FormControlLabel
                        value="top"
                        control={
                           <Switch
                           checked={this.state.filterIncasso}
                           onChange={() => {this.setState({filterIncasso: !this.state.filterIncasso})}}
                           color="secondary"
                           />}
                        label={`${t('hide')} ${t('PRELEVEMENT')}`}
                        labelPlacement="top"
                        />
                     </Tooltip>
                     <Tooltip title="Generate & download excel from the filtered table">
                        <span>
                           <IconButton 
                              disabled={unpaid.length === 0 || !unpaid}
                              // onClick={() => console.log(this.reactTable.getResolvedState().sortedData)}
                              onClick={() => this.downloadUnpaid()}
                           >
                              <Badge color="secondary" variant="dot">
                                 <Icon>save_alt</Icon>
                              </Badge>
                           </IconButton>
                        </span>
                     </Tooltip>
                  </div>
               </div>
         }
         content={(unpaid.length > 0 && unpaid) || !loading ?
               <FuseAnimate animation="transition.slideUpIn" delay={300}>
                  <div className="w-full">
                     <ReactTable
                           ref={(r) => (this.reactTable = r)}
                           className="-striped -highlight border-0"
                           data={this.filterUnpaid()}
                           columns={[
                              {
                                  Header    : <Icon>lock</Icon>,
                                  id  : "levStop",
                                  accessor: a => a.levStop.toString(),
                                  filterable: true,
                                  className : "justify-center",
                                  width     : 100,
                                  Cell: row => (row.value === 'true' ? <Icon style={{color: 'red'}}>lock</Icon> : <Icon style={{color: 'green'}}>shopping_cart</Icon>)
                              },
                              {
                                 Header    : "Debnr",
                                 accessor  : "debnr",
                                 filterable: true,
                                 className : "font-bold justify-center",
                                 width     : 150,
                                 Cell: row => (<RouteToDebnr row={row} />)
                              },
                              {
                                 Header    : t("NAME"),
                                 accessor  : "name",
                                 filterable: true,
                                 className : "font-bold"
                              },
                              {
                                 Header    : t("MAXDAY"),
                                 accessor  : "maxDaysToPay",
                                 filterable: true,
                                 className: "justify-center",
                                 width     : 150
                              },
                              {
                                 Header    : t("LASTINVOICE"),
                                 accessor  : "lastUnpaid",
                                 filterable: true,
                                 className: "justify-center",
                                 width     : 150
                              },
                              {
                                 Header    : t("UNPAID"),
                                 accessor  : "unpaidTotal",
                                 filterable: true,
                                 className : "justify-center",
                                 width: 100
                              },
                              {
                                 Header    : t("CLOSED"),
                                 accessor  : "closesIn",
                                 filterable: true,
                                 className: "justify-center",
                                 width: 150
                              },
                              {
                                 Header    : "Incasso",
                                 accessor  : "isIncasso",
                                 className: "justify-center",
                                 width: 80,
                                 Cell: row => (!row.value ? <Icon>close</Icon> : <Icon style={{color: 'green'}}>done</Icon>)
                              }
                           ]}
                           pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                           defaultPageSize={20}
                           noDataText="No customers found"
                     /> 
                  </div>
               </FuseAnimate>
         : <div className="flex h-full">
               <div className="m-auto">
                  <LinearProgress />
                  <Typography>{t('fetching_live_customer_data')}...</Typography>
               </div>
            </div> 
         }
         />
      )
   }
}

const mapStateToProps = state => {
   return {
      unpaid: state.unpaid.unpaid,
      loading: state.unpaid.loading,
   }
}

const mapDispatchToProps = dispatch => {
   return {
      onFetchUnpaid: () => dispatch(fetchDbUnpaid()),
      onDownloadUnpaid: (info) => dispatch(downloadUnpaid(info)),
   }
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles, {withTheme: true})(withTranslation()(Unpaid)));