import React, {Component} from 'react';
import {matchRoutes} from 'react-router-config';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import * as actions from "../../../app/store/auth/actions";
import { fetchDbAllSearchCustomers } from '../../../app/store/crm/actions/customer';
import { getGroupcode, getRoles } from '../../../app/main/Auth/Auth';
import {setDefaultSettings} from '../../../app/store/actions';

import AppContext from '../../../app/AppContext';

class FuseAuthorization extends Component {

    constructor(props, context)
    {
        super(props);
        const {routes} = context;
        this.state = {
            accessGranted: true,
            routes
        };
    }

    componentDidMount()
    {
        this.props.onFetchSearchableCustomers();
        if(this.props.accessableGroupcodes.length === 0) {
            this.props.onFetchAccessableGroupcodes();
        }
        if ( !this.state.accessGranted )
        {
            this.redirectRoute(this.props);
        }
    }

    componentDidUpdate()
    {
        if ( !this.state.accessGranted )
        {
            this.redirectRoute(this.props);
        }
        this.props.onSetDefault();
    }

    static getDerivedStateFromProps(props, state)
    {
        const {location} = props;
        const {pathname} = location;
        const userRoles = getRoles();

        const matched = matchRoutes(state.routes, pathname)[0];
        const accessGranted = (matched && matched.route.auth) ? userRoles && userRoles.length > 0 && userRoles.some(role => matched.route.auth.includes(role)) : true;
        return {
            accessGranted
        };
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        return nextState.accessGranted !== this.state.accessGranted || Object.keys(nextProps.user).length !== Object.keys(this.props.user).length;
    }

    redirectRoute(props)
    {
        const {location, history} = props;
        const {state} = location;
        /*
        User is guest
        Redirect to Login Page
        */
        const roles = getRoles();
        let redirectUrl = '';
        if(state && state.redirectUrl) { redirectUrl = state.redirectUrl; }
        else if((!state || !state.redirectUrl) && roles.includes('iCRMBasis')){ redirectUrl = '/iCRM/Inbox'; }
        else if((!state || !state.redirectUrl) && !roles.includes('iCRMBasis') && roles.includes('iCRMFinancieel')){ redirectUrl = '/iCRM/Unpaid'; }
        else if((!state || !state.redirectUrl) && !roles.includes('iCRMBasis') && !roles.includes('iCRMFinancieel') && roles.includes('iCRMWholesale')){ redirectUrl = '/wholesale/customers'; }

        if(location.pathname !== redirectUrl) {
            history.push({
                pathname: redirectUrl
            });
        }
    }

    render()
    {
        const {children, user} = this.props;
        const {accessGranted} = this.state;
        
        return accessGranted && Object.keys(user).length !== 0 ? <React.Fragment>{children}</React.Fragment> : null;
    }
}

const mapStateToProps = state => {
    return {
        user: state.flortalAuth.user,
        accessableGroupcodes: state.flortalAuth.accessableGroupcodes,
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      onFetchAccessableGroupcodes: () => dispatch(actions.fetchAccessableGroupcodes()),
      onFetchSearchableCustomers: () => dispatch(fetchDbAllSearchCustomers(getGroupcode())),
      onLocalGroupcode: groupCode => dispatch(actions.fetchLocalGroupcodeSuccess(groupCode)),
      onSetDefault: () => dispatch(setDefaultSettings())
    };
  };

FuseAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FuseAuthorization));
