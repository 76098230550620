import {useQuery} from 'react-query'
import axios from 'axios';

const useDefaultQuery = (queryKey, url, options) => {
    return useQuery(
        queryKey,
        async () => {
            const res = await axios.get(url);
            return res.data;
        }, {
            staleTime: Infinity,
            ...(options ? options : {})
        }
    );
}

export const downloadBlob = (url, fileName) => {    
    axios.get(url, {responseType: 'blob'})
    .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    })
    .catch(error => {
        console.log(error);
    })
}

export default useDefaultQuery;