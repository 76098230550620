import * as actionTypes from './actionTypes';
import axios from 'axios'

//OrderTurnover Data
export const fetchDbOrderTurnoverSuccess = (dbOrderTurnover) => {
    return {
        type: actionTypes.FETCH_ORDER_TURNOVER_SUCCESS,
        orderTurnover: dbOrderTurnover
    };
};

export const fetchDbOrderTurnover = () => {
    let baseUrl = 'crm/dashboard/';
    let url = baseUrl + 'getturnoverorders';
    return dispatch => {
        axios.get(url)
            .then(res => {
                dispatch(fetchDbOrderTurnoverSuccess(res.data));
            })
    };
};

// Turnover Distribution Data
export const fetchDbTurnoverDistributionStart = () => {
    return {
        type: actionTypes.FETCH_TURNOVER_DISTRIBUTION_START
    };
};

export const fetchDbTurnoverDistributionSuccess = (dbTurnoverDistribution) => {
    return {
        type: actionTypes.FETCH_TURNOVER_DISTRIBUTION_SUCCESS,
        turnoverDistribution: dbTurnoverDistribution
    };
};

export const fetchDbTurnoverDistribution = (startDate, endDate, refreshed) => {
    let baseUrl = 'crm/dashboard/';
    let url = baseUrl + `GetTurnoverDistribution?startDateString=${startDate}&endDateString=${endDate}&refresh=${refreshed}`;
    return dispatch => {
        dispatch(fetchDbTurnoverDistributionStart());
        axios.get(url)
            .then(res => {
                dispatch(fetchDbTurnoverDistributionSuccess(res.data));
            })
    };
};

// Dashboard blocks Data
export const fetchDbDashboardBlocksStart = () => {
    return {
        type: actionTypes.FETCH_DASHBOARD_BLOCKS_START,
    };
};
export const fetchDbDashboardBlocksSuccess = (dbDashboardBlocks) => {
    return {
        type: actionTypes.FETCH_DASHBOARD_BLOCKS_SUCCESS,
        dashboardBlocks: dbDashboardBlocks
    };
};

export const fetchDbDashboardBlocks = (startDate, endDate) => {    
    let baseUrl = 'crm/dashboard/';
    let url = baseUrl + `getblocks?startDateString=${startDate}&endDateString=${endDate}`;
    return dispatch => {
        dispatch(fetchDbDashboardBlocksStart());
        axios.get(url)
            .then(res => {
                dispatch(fetchDbDashboardBlocksSuccess(res.data));
            })
    };
};

// Dashboard Tables Data
export const fetchDbDashboardTablesSuccess = (dbDashboardTables) => {
    return {
        type: actionTypes.FETCH_DASHBOARD_TABLES_SUCCESS,
        dashboardTables: dbDashboardTables
    };
};

export const fetchDbDashboardTables = (startDate, endDate) => {    
    let baseUrl = 'crm/dashboard/';
    let url = baseUrl + `gettables?startDateString=${startDate}&endDateString=${endDate}`;
    return dispatch => {
        axios.get(url)
            .then(res => {
                dispatch(fetchDbDashboardTablesSuccess(res.data));
            })
    };
};

// Dashboard Piecharts Data
export const fetchDbDashboardChartsSuccess = (dbDashboardCharts) => {
    return {
        type: actionTypes.FETCH_DASHBOARD_CHARTS_SUCCESS,
        dashboardCharts: dbDashboardCharts
    };
};

export const fetchDbDashboardCharts = (startDate, endDate) => {    
    let baseUrl = 'crm/dashboard/';
    let url = baseUrl + `getcharts?startDateString=${startDate}&endDateString=${endDate}`;
    return dispatch => {
        axios.get(url)
            .then(res => {
                dispatch(fetchDbDashboardChartsSuccess(res.data));
            })
    };
};

// Dashboard Piecharts Data
export const fetchDashboardCreditRedenVerdelingStart = () => {
    return {
        type: actionTypes.FETCH_DASHBOARD_CREDIT_REDEN_VERDELING_START,
    };
};

export const fetchDashboardCreditRedenVerdelingSuccess = (verdelingen) => {
    return {
        type: actionTypes.FETCH_DASHBOARD_CREDIT_REDEN_VERDELING_SUCCESS,
        verdelingen
    };
};

export const fetchDashboardCreditRedenVerdelingFail = (error) => {
    return {
        type: actionTypes.FETCH_DASHBOARD_CREDIT_REDEN_VERDELING_FAIL,
        error
    };
};

export const fetchDashboardCreditRedenVerdeling = (startDate, endDate) => {    
    let baseUrl = 'crm/dashboard/';
    let url = baseUrl + `GetCreditRedenVerdeling?startDateString=${startDate}&endDateString=${endDate}`;
    return dispatch => {
        dispatch(fetchDashboardCreditRedenVerdelingStart());
        axios.get(url)
            .then(res => {
                dispatch(fetchDashboardCreditRedenVerdelingSuccess(res.data));
            }).catch(error => {
                dispatch(fetchDashboardCreditRedenVerdelingFail(error));
            })
    };
};