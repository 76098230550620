import React, { Component } from 'react';
import {connect} from 'react-redux';
import ReactTable from "react-table";
import {FuseAnimateGroup, FusePageSimple} from '../../../../../@fuse';
import {withStyles, Icon, Typography, Tooltip, IconButton, TextField} from '@material-ui/core';
import * as actions from '../../../../store/crm/actions'
import RouteToDebnr from '../../../../components/Functions/RouteToDebnr';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import {withTranslation} from 'react-i18next';
import {filterAll} from '../../../../components/Functions/FilterAll';
import {getCompany} from '../../../Auth/Auth';

const styles = theme => ({
    layoutRoot: {},
    root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    },
    table: {
    minWidth: 700,
    },
    row: {
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
    },
    },
});

class Customers extends Component {
    state = {
        filterValue: '',
        filteredCustomers: [],
        insertedFilteredCustomers: false
    }

    componentDidMount() {
        this.props.onFetchCustomers();
    }

    componentDidUpdate(prevProps, prevState) {
        if(!this.state.insertedFilteredCustomers && this.props.customers.length > 0) {
            this.setState({...this.state, filteredCustomers: this.props.customers, insertedFilteredCustomers: true});
        }
    }

    handleGlobalFilterChange = event => {
        this.setState({ ...this.state, filterValue: event.target.value, filteredCustomers: filterAll(this.props.customers, event.target.value) });
    };
    
    render() {
        const {classes, customers, t} = this.props;
        return (
                <FusePageSimple
                classes={{
                    root: classes.layoutRoot
                }}
                header={
                    <div className="flex flex-1 items-center justify-between p-24">
                        <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                <Icon className="text-18" color="action">home</Icon>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">iCRM</Typography>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">{t("CUSTOMERS")}</Typography>
                            </div>
                            <Typography variant="h6">{t("CUSTOMERS")}</Typography>
                        </div>
                        <div className="flex items-end">
                            <TextField
                                label={t("GLOBALSEARCH")}
                                className={classes.textField}
                                value={this.state.filterValue}
                                onChange={this.handleGlobalFilterChange}
                                margin="normal"
                            />
                        </div>
                    </div>
                }
                content={customers && customers.length > 0 ?
                    <FuseAnimateGroup animation="transition.slideUpIn" delay={300}>
                        <div className="w-full">
                            <ReactTable
                                className="-striped -highlight border-0"
                                data={this.state.filteredCustomers}
                                columns={[
                                    {
                                        Header    : <Icon>lock</Icon>,
                                        accessor  : "levStop",
                                        className : "justify-center",
                                        width     : 50,
                                        Cell: row => (row.value ? <Icon style={{color: 'red'}}>lock</Icon> : <Icon style={{color: 'green'}}>shopping_cart</Icon>)
                                    },
                                    {
                                        Header    : "Debnr",
                                        accessor  : "debnr",
                                        filterable: true,
                                        className : "font-bold justify-center",
                                        width     : 100,
                                        Cell: row => (<RouteToDebnr row={row} />)
                                    },
                                    {
                                        Header    : t("NAME"),
                                        accessor  : "name",
                                        filterable: true,
                                        className : "font-bold",
                                    },
                                    {
                                        Header    : getCompany() === 3 || getCompany() === 4 ? t("FINANCIELTYPE") : t("ADDRESS"),
                                        accessor  : getCompany() === 3 || getCompany() === 4 ? "financielType" : "address",
                                        filterable: true,
                                        className: "justify-center",
                                    },
                                    {
                                        Header    : getCompany() === 3 || getCompany() === 4 ? t("COMMERCIALTYPE") : t("ZIPCODE"),
                                        accessor  : getCompany() === 3 || getCompany() === 4 ? "commercialType" : "zipcode",
                                        filterable: true,
                                        className: "justify-center",
                                        width     : getCompany() === 3 || getCompany() === 4 ? 200 : 100,
                                    },
                                    {
                                        Header    : t("CITY"),
                                        accessor  : "city",
                                        filterable: true,
                                        className : "justify-center",
                                        maxWidth  : 200
                                    },
                                    {
                                        Header    : t("PHONE"),
                                        accessor  : "phoneNumber",
                                        className: "justify-center",
                                        width       : 50,
                                        Cell: row => {
                                            return (
                                            <span>
                                            {(row.value !== undefined && row.value.toString().trim() !== '') || (row.original.mobileNumber !== undefined && row.original.mobileNumber.toString().trim() !== '') ? 
                                                
                                                <Tooltip title={row.value !== undefined ? row.value: null + row.value !== undefined && row.original.mobileNumber !== undefined ?  + ' - ' : null + row.original.mobileNumber !== undefined ? row.original.mobileNumber: null}>
                                                <IconButton aria-label="Delete">
                                                    <Icon>phone_iphone</Icon>
                                                </IconButton>
                                                </Tooltip>
                                            : <Icon className="text-18" color="error">clear</Icon> 
                                            }
                                            </span>
                                            )
                                        },
                                    },
                                    {
                                        Header    : "Pack",
                                        accessor  : "branche",
                                        filterable: true,
                                        className: "justify-center",
                                        width: 220,
                                    },
                                    {
                                        Header    : "Email",
                                        accessor  : "email",
                                        filterable: true,
                                        className: "justify-center",
                                        width: 50,
                                        Cell: row => row.value === '' ? null : (
                                        <Tooltip title={row.value}>
                                            <IconButton aria-label="email">
                                                <Icon>mail_outline</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        )
                                    },
                                ]}
                                pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                                defaultPageSize={20}
                                noDataText="No customers found"
                            /> 
                        </div>
                    </FuseAnimateGroup>
                : <Spinner /> }
                />
        )
    }
}

const mapStateToProps = state => {
    return {
        customers: state.customer.allCustomers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchCustomers: () => dispatch(actions.fetchDbAllCustomers()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(withTranslation()(Customers)));