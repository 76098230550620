import React from 'react'
import {Tooltip} from '@material-ui/core';

const LanguageFlags = ({handleLanguageChange}) => (    
    <div className="flex mt-12">
        <Tooltip title="Français">
            <img onClick={() => handleLanguageChange('fr')} className="w-24 m-4 cursor-pointer" src="assets/flags/france.svg" alt="flag_fr"/>
        </Tooltip>
        <Tooltip title="Deutsch">
            <img onClick={() => handleLanguageChange('de')} className="w-24 m-4 cursor-pointer" src="assets/flags/germany.svg" alt="flag_de"/>
        </Tooltip>
        <Tooltip title="Nederlands">
            <img onClick={() => handleLanguageChange('nl')} className="w-24 m-4 cursor-pointer" src="assets/flags/netherlands.svg" alt="flag_nl"/>
        </Tooltip>
        <Tooltip title="English">
            <img onClick={() => handleLanguageChange('en')} className="w-24 m-4 cursor-pointer" src="assets/flags/united-kingdom.svg" alt="flag_uk"/>
        </Tooltip>
    </div>
);

export default LanguageFlags;