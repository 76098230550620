import React from 'react';
import {FusePageSimple} from '../../../../../@fuse';
import { connect } from 'react-redux';
// import * as actions from '../../../store/task/actions';
import * as actions from '../../../../store/task/actions';
import TasksTable from '../../Tasks/TasksTable';
// import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import TaskHeader from '../../Tasks/TaskHeader';
import _ from 'lodash';
import FavoriteUsersDialog from '../../Admin/Users/FavoriteUsersDialog';
import { useFavoriteUsers } from '../../Admin/Users/useUsersQueries';
import axios from 'axios';
import { useMutation } from 'react-query';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const countries = [
    {key: 'all', value: 'Alle klanten'},
    {key: 'fr', value: 'Franse klanten'},
    {key: 'de', value: 'Duitse klanten'},
    {key: 'it', value: 'Italiaanse klanten'},
    {key: 'uk', value: 'UK klanten'},
]
  
const DefaultTaskOverview = ({ tasksoverview, onFetchTasksOverview, isArchive, title }) => {

    React.useEffect(() => {
        onFetchTasksOverview((tasks) => {
            if(tasks.some(t => !t.concluded && moment(t.endDate).isBefore(moment(new Date())))) {
                setOpen(true);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { t } = useTranslation();
    
    const [selectedCountry, setSelectedCountry] = React.useState('all');
    const [selectedBusinessUnit, setSelectedBusinessUnit] = React.useState('all');
    const [open, setOpen] = React.useState(false);
    const [favoriteUsersDialogOpen, setFavoriteUsersDialogOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const getFinalData = () => {
        let tasks = getTasksFilteredByCountry(selectedCountry);
        if(selectedBusinessUnit !== 'all') {
            tasks = tasks.filter(task => task.businessUnit === selectedBusinessUnit);
        }
        return tasks.filter(task => {
            if(isArchive) {
                return task.concluded || task.isArchive;
            } else {
                return !task.concluded && !task.isArchive;
            }
        });
    }

    const getTasksFilteredByCountry = (country) => {
        const tasks = tasksoverview;
        if(country === 'all') {
            return tasks;
        }
        else if(country === 'uk') {
            return tasks.filter(t => {
                    if(!t.debland) return false;
                    const currentCountry = t.debland.toLowerCase();
                    return currentCountry.includes('united kingdom') || currentCountry.includes('engeland') || currentCountry.includes('ireland')
                }
            );
        }
        else if(country === 'fr') {
            return tasks.filter(t => {
                if(!t.debland) return false;
                    const currentCountry = t.debland.toLowerCase();
                    return currentCountry.includes('frankrijk') || currentCountry.includes('france') || currentCountry.includes('frans')
                }
            );
        }
        else if(country === 'de') {
            return tasks.filter(t => {
                if(!t.debland) return false;
                    const currentCountry = t.debland.toLowerCase();
                    return currentCountry.includes('deutschland') || currentCountry.includes('duitsland')
                }
            );
        }
        else if(country === 'it') {
            return tasks.filter(t => {
                if(!t.debland) return false;
                    const currentCountry = t.debland.toLowerCase();
                    return currentCountry.includes('italie') || currentCountry.includes('italia')
                }
            );
        }
    }

    const handleSelectedCountryChange = (country) => {
        setSelectedCountry(country);
        if(!getBusinessUnits(country).map(b => b.key).includes(selectedBusinessUnit)) {
            setSelectedBusinessUnit('all');
        }
    }

    const getBusinessUnits = (country) => {
       
        return [
            {key: 'all', value: 'Alle units'},
            ..._.uniq(getTasksFilteredByCountry(country).map(b => b.businessUnit)).filter(bUnit => bUnit).map(bUnit => ({
                key: bUnit,
                value: bUnit
            })
        )];
    }

    const favoriteUsers = useFavoriteUsers();
    const assignUserMutation = useMutation(task => axios.post(task.orderTask ? 'task/UpdateOrderTaskAccountManager' : 'task/UpdateTaskAccountManager', task), {
        onSuccess: onFetchTasksOverview
    });

    return (
        <>
        {favoriteUsersDialogOpen && <FavoriteUsersDialog handleClose={() => setFavoriteUsersDialogOpen(false)} />}
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
                {t('open_expired_tasks')}
            </Alert>
        </Snackbar>
        <FusePageSimple
                header={
                    <TaskHeader 
                        headerTitle={title} 
                        setSelectedCountry={handleSelectedCountryChange} 
                        countries={countries} 
                        selectedCountry={selectedCountry} 
                        setSelectedBusinessUnit={setSelectedBusinessUnit} 
                        businessUnits={getBusinessUnits(selectedCountry)} 
                        selectedBusinessUnit={selectedBusinessUnit}
                        setFavoriteUsersDialogOpen={() => setFavoriteUsersDialogOpen(true)}
                    />
                }
                content={
                    <TasksTable 
                        data={getFinalData()} 
                        favoriteUsers={favoriteUsers.data} 
                        handleFavoriteUsersDialogOpen={() => setFavoriteUsersDialogOpen(true)}
                        assignUser={assignUserMutation.mutate}
                        accountManagerAssignmentEnabled={!isArchive}
                        />
                }
            />
        </>
    );
};

const mapStateToProps = ({ task }) => ({
    tasksoverview: task.tasksoverview
});

const mapDispatchToProps = (dispatch) => ({
    onFetchTasksOverview: (afterFetchTasksSuccess) => dispatch(actions.fetchTasksOverview(afterFetchTasksSuccess))
})

export default connect(mapStateToProps, mapDispatchToProps)(DefaultTaskOverview);