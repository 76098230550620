import SpecialOffers from './SpecialOffers/SpecialOffers';
import ManageSpecialOffers from './ManageSpecialOffers/ManageSpecialOffers';
import EditSpecialOffer from './ManageSpecialOffers/EditSpecialOffer/EditSpecialOffer';
import SpecialOfferLog from './SpecialOfferLog/SpecialOfferLog';

export const SpecialOfferConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/specialoffers',
            component: SpecialOffers,
            exact: true,
            auth: ['iCRMSpecialOfferSend']
        },
        {
            path     : '/specialoffers/manage',
            component: ManageSpecialOffers,
            exact: true,
            auth: ['iCRMSpecialOfferEdit']
        },
        {
            path     : '/specialoffer/manage/:id',
            component: EditSpecialOffer,
            exact: true,
            auth: ['iCRMSpecialOfferEdit']
        },
        {
            path     : '/specialoffers/logs',
            component: SpecialOfferLog,
            exact: true,
            auth: ['iCRMAdmin']
        },
    ]
};