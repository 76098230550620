export const FETCH_TASKS_START = 'FETCH_TASKS_START';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_FAIL = 'FETCH_TASKS_FAIL';

export const FETCH_TASKSOVERVIEW_START = 'FETCH_TASKSOVERVIEW_START';
export const FETCH_TASKSOVERVIEW_SUCCESS = 'FETCH_TASKSOVERVIEW_SUCCESS';
export const FETCH_TASKSOVERVIEW_FAIL = 'FETCH_TASKSOVERVIEW_FAIL';

export const FETCH_TASK_START = 'FETCH_TASK_START';
export const FETCH_TASK_SUCCESS = 'FETCH_TASK_SUCCESS';
export const FETCH_TASK_FAIL = 'FETCH_TASK_FAIL';

export const FETCH_TASK_TIMELINE_START = 'FETCH_TASK_TIMELINE_START';
export const FETCH_TASK_TIMELINE_SUCCESS = 'FETCH_TASK_TIMELINE_SUCCESS';
export const FETCH_TASK_TIMELINE_FAIL = 'FETCH_TASK_TIMELINE_FAIL';

export const FETCH_ACCOUNTMANAGERS_START = 'FETCH_ACCOUNTMANAGERS_START';
export const FETCH_ACCOUNTMANAGERS_SUCCESS = 'FETCH_ACCOUNTMANAGERS_SUCCESS';
export const FETCH_ACCOUNTMANAGERS_FAIL = 'FETCH_ACCOUNTMANAGERS_FAIL';

export const CREATE_TASK_START = 'CREATE_TASK_START';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAIL = 'CREATE_TASK_FAIL';
export const CREATE_TASK_RESET = 'CREATE_TASK_RESET';

export const SAVE_TASK_START = 'SAVE_TASK_START';
export const SAVE_TASK_SUCCESS = 'SAVE_TASK_SUCCESS';
export const SAVE_TASK_FAIL = 'SAVE_TASK_FAIL';

export const SAVE_ORDERTASK_START = 'SAVE_ORDERTASK_START';
export const SAVE_ORDERTASK_SUCCESS = 'SAVE_ORDERTASK_SUCCESS';
export const SAVE_ORDERTASK_FAIL = 'SAVE_ORDERTASK_FAIL';

export const CONCLUDE_TASK_START = 'CONCLUDE_TASK_START';
export const CONCLUDE_TASK_SUCCESS = 'CONCLUDE_TASK_SUCCESS';
export const CONCLUDE_TASK_FAIL = 'CONCLUDE_TASK_FAIL';

export const SET_TASK_FALSEALARM_START = 'SET_TASK_FALSEALARM_START';
export const SET_TASK_FALSEALARM_SUCCESS = 'SET_TASK_FALSEALARM_SUCCESS';
export const SET_TASK_FALSEALARM_FAIL = 'SET_TASK_FALSEALARM_FAIL';

export const ADD_TASK_COMMENT_START = 'ADD_TASK_COMMENT_START';
export const ADD_TASK_COMMENT_SUCCESS = 'ADD_TASK_COMMENT_SUCCESS';
export const ADD_TASK_COMMENT_FAIL = 'ADD_TASK_COMMENT_FAIL';