import React, { useEffect } from 'react';
import { FuseNavigation } from '../../../@fuse';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actions from '../../store/crm/actions';
import { fetchLatestImport } from '../../store/crm/admin/actions';
import { getCompany, getUserEmail } from '../../main/Auth/Auth';
// import * as actions from '../../store/crm/manager/actions';

// new Navigation, changes translations on language change
const Navigation = ({ layout, dense, className, CountNewleadsBadge, onFetchGetNavCountNewleads, onFetchLatestImport, latestImport }) => {
    useEffect(() => {
        onFetchGetNavCountNewleads();
        onFetchLatestImport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { t } = useTranslation();
    const companyId = getCompany();
    const navigationConfig = [
        {
            'id': 'icrm',
            'title': 'iCRM',
            'type': 'collapse',
            'icon': 'business-center',
            'auth': ['iCRMBasis', 'iCRMFinancieel'],
            'children': [
                {
                    'id': 'inbox',
                    'title': t('INBOX'),
                    'type': 'item',
                    'url': '/icrm/inbox',
                    'auth': ['iCRMBasis']
                },
                {
                    'id': 'dashboard',
                    'title': t('DASHBOARD'),
                    'type': 'item',
                    'url': '/icrm/dashboard',
                    'auth': ['iCRMBasis']
                },
                {
                    'id': 'calllist',
                    'title': t('CALLLIST'),
                    'type': 'item',
                    'url': '/icrm/call',
                    'auth': ['iCRMBasis']
                },
                {
                    'id': 'overview',
                    'title': t('OVERVIEW'),
                    'type': 'item',
                    'url': '/icrm/overview',
                    'auth': ['iCRMBasis']
                },
                {
                    'id': 'turnover',
                    'title': t('TURNOVER'),
                    'type': 'item',
                    'url': '/icrm/turnover',
                    'auth': ['iCRMBasis']
                },
                {
                    'id': 'divideBasis',
                    'type': 'divider',
                },
                {
                    'id': 'weblog',
                    'title': 'Weblog',
                    'type': 'item',
                    'url': '/icrm/weblog',
                    'auth': ['iCRMBasis']
                },
                {
                    'id': 'unpaid',
                    'title': t('UNPAID'),
                    'type': 'item',
                    'url': '/icrm/unpaid',
                    'auth': ['iCRMBasis', 'iCRMFinancieel']
                },
                {
                    'id': 'customers',
                    'title': t('CUSTOMERS'),
                    'type': 'item',
                    'url': '/icrm/customers',
                    'auth': ['iCRMBasis']
                },
                {
                    'id': 'newcustomers',
                    'title': t('NEWCUSTOMERS'),
                    ...(CountNewleadsBadge.length !== 0 && CountNewleadsBadge[0].count_newcustomers > 0 ?
                        {
                            'badge': {
                                'title': CountNewleadsBadge.length !== 0 ? CountNewleadsBadge[0].count_newcustomers : null,
                                'bg': '#03A9F4',
                                'fg': '#ffffff'
                            },
                        }
                        :
                        null),
                    'type': 'item',
                    'url': '/icrm/newcustomers',
                    'auth': ['iCRMBasis']
                },
                {
                    'id': 'newleads',
                    'title': t('NEW') + " " + t('LEADS'),
                    ...(CountNewleadsBadge.length !== 0 && CountNewleadsBadge[0].count_newleads > 0 ?
                        {
                            'badge': {
                                'title': CountNewleadsBadge.length !== 0 ? CountNewleadsBadge[0].count_newleads : null,
                                'bg': '#03A9F4',
                                'fg': '#ffffff'
                            },
                        }
                        :
                        null),
                    'type': 'item',
                    'url': '/icrm/newleads',
                    'auth': ['iCRMBasis']
                },
                {
                    'id': 'openposten',
                    'title': t('UNPAIDINVOICES'),
                    'type': 'item',
                    'url': '/icrm/openposten',
                    'auth': ['iCRMBasis', 'iCRMFinancieel']
                },
            ]
        },
        {
            'id': 'manager',
            'title': 'iManager',
            'type': 'collapse',
            'auth': ['iCRMManager', 'iTasksManager'],
            'icon': 'business-center',
            'children': [
                {
                    'id': 'credits',
                    'title': t('CREDIT'),
                    'type': 'item',
                    'url': '/icrm/credits',
                    'auth': ['iCRMManager']
                },
                {
                    'id': 'prelevement',
                    'title': t('PRELEVEMENT'),
                    'type': 'item',
                    'url': '/icrm/prelevement',
                    'auth': ['iCRMManager']
                },
                {
                    'id': 'AssignNewLeads',
                    'title': t('ASSIGNNEWLEADS'),
                    ...(CountNewleadsBadge.length !== 0 && CountNewleadsBadge[0].count_assignnewleads > 0 ?
                        {
                            'badge': {
                                'title': CountNewleadsBadge.length !== 0 ? CountNewleadsBadge[0].count_assignnewleads : null,
                                'bg': '#03A9F4',
                                'fg': '#ffffff'
                            },
                        }
                        :
                        null),
                    'type': 'item',
                    'url': '/icrm/AssignNewLeads',
                    'auth': ['iCRMManager']
                },
                {
                    'id': 'OverviewNewLeads',
                    'title': t("LEADS") + ' ' + t("OVERVIEW"),
                    'type': 'item',
                    'url': '/icrm/OverviewNewLeads',
                    'auth': ['iCRMManager']
                },
                {
                    'id': 'ExportNewLeads',
                    'title': 'Export New Leads',
                    ...(CountNewleadsBadge.length !== 0 && CountNewleadsBadge[0].count_exportnewleads > 0 ?
                        {
                            'badge': {
                                'title': CountNewleadsBadge.length !== 0 ? CountNewleadsBadge[0].count_exportnewleads : null,
                                'bg': '#03A9F4',
                                'fg': '#ffffff'
                            },
                        }
                        :
                        null),
                    'type': 'item',
                    'url': '/icrm/ExportNewLeads',
                    'auth': ['iCRMManager']
                },
                {
                    'id': 'taskarchive',
                    'title': t("TASKS") + ' ' + t("archive"),
                    'type': 'item',
                    'url': '/icrm/taskarchive',
                    'auth': ['iTasksManager']
                },
                {
                    'id': 'page-stats',
                    'title': t("PAGE_STATS"),
                    'type': 'item',
                    'url': '/stats/pages',
                    'auth': ['iCRMManager']
                },
                {
                    'id': 'user-stats',
                    'title': t("USER_STATS"),
                    'type': 'item',
                    'url': '/stats/users',
                    'auth': ['iCRMManager']
                },
            ],
        },
        {
            'id': 'admin',
            'title': 'iNdbaas',
            'type': 'collapse',
            'auth': ['iCRMAdmin'],
            'icon': 'business-center',
            'children': [
                {
                    'id': 'users',
                    'title': t('USERS'),
                    'type': 'item',
                    'url': '/icrm/users',
                    'auth': ['iCRMAdmin']
                },
            ],
        },
        // {
        //     'id'      : 'export-new-leads',
        //     'title'   : 'iCRM - Export ' + t('NEW') + ' ' + t('LEADS'),
        //     'type'    : 'item',
        //     'url'     : '/icrm/Export-new-leads',
        //     'exact'   : true,
        //     'auth'    : ['iCRMAdmin'],
        //     'icon'    : 'business-center',
        // },
        {
            'id': 'specialoffer',
            'title': 'iPromos',
            'type': 'collapse',
            'icon': 'local_offer',
            'auth': 'iCRMSpecialOfferSend',
            'children': [
                {
                    'id': 'specialoffers',
                    'title': t('SPECIALOFFERS'),
                    'type': 'item',
                    'url': '/specialoffers',
                    'exact': true,
                    'auth': 'iCRMSpecialOfferSend'
                },
                {
                    'id': 'manage-special-offers',
                    'title': `${t('MANAGE')} ${t('SPECIALOFFERS')}`,
                    'type': 'item',
                    'url': '/specialoffers/manage',
                    'exact': true,
                    'auth': 'iCRMSpecialOfferEdit'
                },
                {
                    'id': 'special-offer-log',
                    'title': `Special Offer Logs`,
                    'type': 'item',
                    'url': '/specialoffers/logs',
                    'exact': true,
                    'auth': 'iCRMAdmin'
                },
            ]
        },
        {
            'id': 'visits',
            'title': 'iVisits',
            // 'type'    : 'collapse',
            'type': 'item',
            'icon': 'store',
            'auth': 'iVisits',
            'url': '/visits',
            // 'children': [
            //     {
            //         'id'   : 'visits',
            //         'title': t('VISITS'),
            //         'type' : 'item',
            //         'url'  : '/visits',
            //         'auth' : ['iVisits']
            //     },   
            // ]
        },
        {
            'id': 'itasks',
            'title': 'iTasks',
            'type': 'collapse',
            // 'type'    : 'item',
            'icon': 'store',
            'auth': 'iTasks',
            // 'url'  : '/visits',
            'children': [
                {
                    'id': 'taken',
                    'title': t("TASKS"),
                    'type': 'item',
                    'url': '/tasks',
                    'exact': true,
                    'auth': ['iCRMBasis']
                },
                {
                    'id': 'tasksoverview',
                    'title': t("TASKS") + ' ' + t("OVERVIEW"),
                    'type': 'item',
                    'url': '/icrm/tasksoverview',
                    'auth': ['iTasksManager']
                },
            ]
        },
        {
            'id': 'apis',
            'title': 'APIs',
            'type': 'collapse',
            'icon': 'code',
            'auth': ['iCRMAdmin', 'MFJDFexport', 'DexxFRAccountantExports', 'DexxCnCExports', 'DexxCnCModule'],
            'children': [
                {
                    'id': 'excels',
                    'title': 'Excels',
                    'type': 'item',
                    'url': '/api/excel',
                    'exact': true,
                    'auth': ['iCRMAdmin']
                },
                {
                    'id': 'enalyzer',
                    'title': 'Enalyzer',
                    'type': 'item',
                    'url': '/api/enalyzer',
                    'exact': true,
                    'auth': ['iCRMAdmin']
                },
                {
                    'id': 'callcenter',
                    'title': 'Callcenter',
                    'type': 'item',
                    'url': '/api/callcenter',
                    'exact': true,
                    'auth': ['iCRMAdmin']
                },
                {
                    'id': 'dexxcbsexport',
                    'title': 'Export DEB',
                    'type': 'item',
                    'url': '/api/dexxexportCBS',
                    'exact': true,
                    'auth': ['DexxFRAccountantExports']
                },
                {
                    'id': 'dexxC&Cexport',
                    'title': 'C&C Export CA',
                    'type': 'item',
                    'url': '/api/dexxC&Cexport',
                    'exact': true,
                    'auth': 'DexxCnCExports'
                },
                {
                    'id': 'dexxC&Cmodule',
                    'title': 'C&C Module',
                    'type': 'item',
                    'url': '/api/dexxC&Cmodule',
                    'exact': true,
                    'auth': 'DexxCnCModule'
                },
                {
                    'id': 'dexxStockExport',
                    'title': 'Stock Export',
                    'type': 'item',
                    'url': '/api/dexxStockExport',
                    'exact': true,
                    'auth': 'DexxCnCExports'
                },
                {
                    'id': 'dexxquadraexport',
                    'title': 'Quadra Export',
                    'type': 'item',
                    'url': '/api/quadra-export',
                    'exact': true,
                    'auth': 'iCRMFinancieel'
                },
                {
                    'id': 'adhoc',
                    'title': 'Ad Hoc',
                    'type': 'item',
                    'url': '/api/adhoc',
                    'exact': true,
                    'auth': ['MFJDFexport']
                },
                {
                    'id': 'flowerplace',
                    'title': 'Flower Place',
                    'type': 'item',
                    'url': '/api/flowerplace',
                    'exact': true,
                    'auth': ['iCRMAdmin']
                },
                {
                    'id': 'JDFexport',
                    'title': 'JDF Export',
                    'type': 'item',
                    'url': '/api/JDFexport',
                    'exact': true,
                    'auth': 'MFJDFexport'
                },
            ]
        },
        {
            'id': 'wholesale',
            'title': 'Wholesale',
            'type': 'collapse',
            'icon': 'store',
            'auth': ['iCRMWholesale'],
            'children': [
                {
                    'id': 'wholesalecustomers',
                    'title': t('CUSTOMERS'),
                    'type': 'item',
                    'url': '/wholesale/customers',
                    'auth': ['iCRMWholesale']
                },
            ]
        },
        {
            'id': 'CC',
            'title': 'C&C',
            'type': 'collapse',
            'icon': 'store',
            'auth': ['DexxCnCModule'],
            'children': [
                {
                    'id': 'CCDashboard',
                    'title': 'C&C Dashboard',
                    'type': 'item',
                    'url': '/CC/CCDashboard',
                    'auth': ['DexxCnCModule']
                },
            ]
        },
    ];
    if (companyId === 8) {
        navigationConfig.push({
            'id': 'icrm-premium',
            'title': 'Customer Identities',
            'type': 'item',
            'icon': 'store',
            'auth': 'iCRMBasis',
            'url': '/customeridentities',
        });
    }
    return (
        <FuseNavigation className={classNames("navigation", className)} navigation={navigationConfig} layout={layout} dense={dense} latestImport={latestImport} />
    );
};

Navigation.defaultProps = {
    layout: "vertical"
};

const mapStateToProps = ({ customer, admin }) => {
    return {
        CountNewleadsBadge: customer.CountNewleadsBadge,
        latestImport: admin.latestImport,
        // assignnewleads: customer.assignnewleads
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchGetNavCountNewleads: () => dispatch(actions.FetchGetNavCountNewleads()),
        onFetchLatestImport: () => dispatch(fetchLatestImport()),

        // onFetchAssignNewLeads: () => dispatch(actions.fetchAssignNewLeads()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navigation));
