import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/crm/actions';
import {FuseAnimateGroup, FusePageSimple} from '../../../../../@fuse';
import {Icon, Typography, Tooltip, Card, CardContent, Button, FormControl, Select, TextField, OutlinedInput, MenuItem, IconButton, withStyles,
    Badge, Switch, InputAdornment, Link} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
// import RouteToDebnr from '../../../../components/Functions/RouteToDebnr';
// import Spinner from '../../../../components/UI/Spinner/Spinner';
import {withTranslation} from 'react-i18next';
import { getAzureId } from '../../../Auth/Auth';
import NewLeadsUserDialog from './NewLeadsUserDialog';
import ReturnNewLeadsUserDialog from './ReturnNewLeadsUserDialog';
import NewLeadsTable from './NewLeadsTable';
import NewLeadsMultipleDetailsDialog from './NewLeadsMultipleDetailsDialog';
import NewLeadsMultipleVatDetailsDialog from './NewLeadsMultipleVatDetailsDialog';
const styles = theme => ({
    layoutRoot: {},
    root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    },
    table: {
    minWidth: 700,
    },
    row: {
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
    },
    },
});

// var templates = [
//     {"id":"TP12","name":"Template Jo - 350"},
//     {"id":"TP44","name":"Template Jo - 500"},
//     {"id":"TP19","name":"Template Jo - 750"},
//     {"id":"TP20","name":"Template Jo - 1500"},
//     {"id":"TM03","name":"Template Jo - Marionette"}
// ];

const initialForm = {
    id: '',
    email: '',
    debnr: '',
    debnaam: '',
    debadres: '',
    debpc: '',
    debplaats: '',
    debcont: '',
    debtfnr: '',
    debmobiel: '',
    email2: '',
    lvnaamdb: '',
    lvadresdb: '',
    lvpcdb: '',
    lvplaatsdb: '',
    password: '',
    btwnrdeb: '',
    // acctmngr: '',
    // btwok: '1',
    transcod: '',
    template: '',
    findebnr: '',
    kvk: '',
    azure_id: getAzureId(),
    acctmngR_NOTE: '',
    vieS_VALID: false,
    vieS_NAME: '',
    vieS_ADDRESS: '',
    vieS_VAT: '',
    acctmngR_VALIDATED: '',
    fS_EXPORTED: '',
    countEmails: '',
    countVAT: '',
    debtext: '',
    forM_SOURCE: '',
    debfingrp: 0,
};


class NewLeads extends Component {
    state = {
        form: {
            ...initialForm
        },
        favoritesDialogOpen: false,
        returnDialogOpen: false,
        isCreating: false,
        // updatedLead: false,
        checkedDelivery: false,
        LeadMultipleDetailDialogOpen: false,
        LeadMultipleVatDetailDialogOpen: false,
    }

    toggleUserModal = (email)=>{
        this.props.onFetchMultipleEmailLeads(email);

        this.setState((state)=>{
            return{
                LeadMultipleDetailDialogOpen: !state.LeadMultipleDetailDialogOpen
            }
        })
    }
    
    toggleVatModal = (vat)=>{
        this.props.onFetchMultipleVatLeads(vat);

        this.setState((state)=>{
            return{
                LeadMultipleVatDetailDialogOpen: !state.LeadMultipleVatDetailDialogOpen
            }
        })
    }
  
    componentDidMount() {
        this.props.onFetchNewLeads();
        this.props.onFetchTemplatesNewLeads();
        this.props.onFetchAllTemplatesNewLeads();
        this.props.onFetchAllDebType();
        this.setState({ form: {...this.state.form, azure_id: getAzureId()} });
        // this.props.onFetchTransportersNewLeads();
    }

    componentDidUpdate(prevProps) {
        if((this.props.newLeads.length !== 0 && this.state.editing)
            && (JSON.stringify(this.props.newLeads) !== JSON.stringify(prevProps.newLeads))
            ) {
                if(this.state.editing) {
                    this.setState({
                        newLeads: this.props.newLeads,
                        form: {...initialForm, ...this.props.newLeads.find(deb => deb.email === this.state.form.email)}
                    })
                } else {
                    this.setState({newLeads: this.props.newLeads});
                }
        }
    }

    onCreateNewLead = () => {
        this.props.onCreateNewLead(this.state.form);
        this.setState({isCreating: false});
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     return nextProps.newLeads.length !== this.props.newLeads.length;
    // }


    handleEdit = id => {
        this.setState({editing: true });
        const debiteur = this.props.newLeads.find(deb => deb.id === id);
        this.setState({form: {...initialForm, ...debiteur, 
            // btwok: debiteur.btwok       ? debiteur.btwok : '',
            kvk: debiteur.kvk           ? debiteur.kvk : '',
            btwnrdeb: debiteur.btwnrdeb ? debiteur.btwnrdeb.replace(/[^A-Z0-9]/ig, "") : '',
            // transcod: debiteur.transcod ? debiteur.transcod : '',
            template: debiteur.template ? debiteur.template : '',
            debtext: debiteur.debtext ? 'iCRM Note : ' + debiteur.debtext : '',
            forM_SOURCE: debiteur.forM_SOURCE ? 'Form source : ' + debiteur.forM_SOURCE + '\n' : '',
            azure_id: getAzureId()
        }});
        this.props.onSetDeleteFalse();
        this.props.onsetEditedNewLeadFalse();
    }

    handleCreate = () => {
        this.setState({editing: false, form: {...initialForm, azure_id: getAzureId()}, checkedDelivery: false, isCreating: true});
    }

    handleChange = event => {
        this.setState({
        form: {...this.state.form, [event.target.name]: event.target.value},
        })
    }

    handleSwitch = event => {
        // console.log(event.target.checked);
        this.setState({ checkedDelivery: event.target.checked })
    }

    handleonBlur = event => {
        if(event.target.name === 'btwnrdeb'){
            this.setState({
                form: {...this.state.form, [event.target.name]: event.target.value.replace(/[^A-Z0-9]/ig, "")},
            })
        }
    }

    canBeSubmitted(row)
    {
        return row.icrmuser && row.icrmuser.length > 0
    }

    canBeCreated()
    {
        const canBeCreated = this.state.form.email.length 
            && this.state.form.debnaam.length 
            && this.state.form.debadres.length 
            && this.state.form.debpc.length 
            && this.state.form.debplaats.length 
            // && (this.state.form.debmobiel.length || this.state.form.debtfnr.length)
            // && this.state.form.kvk.length 
            && this.state.form.template.length
            // && this.state.form.transcod.length 
            > 0
        return canBeCreated;
    }

    canBeReturned()
    {
        const canBeReturned = this.state.form.email.length 
            && this.state.form.debnaam.length 
            && this.state.form.debadres.length 
            && this.state.form.debpc.length 
            && this.state.form.debplaats.length
            && this.state.form.acctmngR_NOTE.length
            > 0
        return canBeReturned;
    }

    handleFavoritesDialogOpen = event => {
        this.setState({favoritesDialogOpen: true});
    }
    handleFavoritesDialogClose = () => {
        this.setState({favoritesDialogOpen: false});
    }

    handleReturnDialogOpen = event => {
        this.setState({returnDialogOpen: true});
    }

    handleReturnDialogClose = () => {
        this.setState({returnDialogOpen: false});
    }

    onSaveDialog = user => {
        // console.log({azureId: getAzureId(), TemplateDebnr: user.template_debnr});
        this.props.onAddFavUser({
            azureId: getAzureId(), 
            TemplateName: user.template_name,
            TemplateDebnr: user.template_debnr
        });
    }
    
    onDeleteDialog = user => {
        // console.log(user);
        this.props.onDeleteFavUser({
            azureId: getAzureId(),
            TemplateDebnr: user.template_debnr
        });
    }
    
    canBeValidated(row)
    {
        const canBeValidated = row.email.length > 0
            && row.debnaam.length  > 0
            && row.debadres.length  > 0
            && row.debpc.length  > 0
            && row.debplaats.length  > 0
            // && (row.debmobiel.length > 0 || row.debtfnr.length > 0)
            // && row.kvk === null ? row.kvk > 0 : row.kvk.length > 0
            && row.template === null ? row.template > 0 : row.template.length > 0
            && row.acctmngR_VALIDATED === 0
            // && row.transcod.length > 0;
        return canBeValidated;
    }
    
    handleViesCheck = () => {
        const afterCheckVies = ( viesData ) => {
            this.setState({ form: {
                ...this.state.form,
                vieS_VALID: viesData.valid,
                vieS_NAME: viesData.debnaam,
                vieS_ADDRESS: viesData.debAddress,
                vieS_VAT: viesData.vat,
            } })
        }
        this.props.onCheckVies(this.state.form, afterCheckVies);
    }

    // handleDoubleEmailCheck = () => {
    //     const afterDoubleEmailCheck = ( viesData ) => {
    //         this.setState({ form: {
    //             ...this.state.form,
    //             vieS_VALID: viesData.viesIsValid,
    //             vieS_NAME: viesData.viesName,
    //             vieS_ADDRESS: viesData.viesAddress,
    //             vieS_VAT: viesData.btwnrdeb, 
    //         } })
    //     }
    //     this.props.onDoubleEmailCheck(this.state.form, afterDoubleEmailCheck);
    // }

    render() {
        const {classes, newLeads, t, TemplatesNewLeads, AllTemplatesNewLeads, MultipleEmailLeads, MultipleVatLeads, DebType} = this.props;
        const {form} = this.state;
        // console.log(form)
        return (
            <>
            <NewLeadsUserDialog
                dialogOpen={this.state.favoritesDialogOpen}
                onClose={this.handleFavoritesDialogClose}
                onSave={this.onSaveDialog}
                onDelete={this.onDeleteDialog}
                users={AllTemplatesNewLeads}
                favtemplates={TemplatesNewLeads}
                // isDeleted = {false}
            />
            <ReturnNewLeadsUserDialog
                dialogOpen={this.state.returnDialogOpen}
                onClose={this.handleReturnDialogClose}
                onSave={this.onSaveDialog}
                onDelete={this.onDeleteDialog}
                users={AllTemplatesNewLeads}
                newLeadForm={form}
                favtemplates={TemplatesNewLeads}
                handleChange={this.handleChange}
                afterDelete={this.handleCreate}
            />
            {MultipleEmailLeads != undefined ? (
                 <NewLeadsMultipleDetailsDialog
                 dialogOpen={this.state.LeadMultipleDetailDialogOpen}
                 onClose={this.toggleUserModal}
                 multipledataleads={this.props.MultipleEmailLeads}
             />
            ) : ('')}
           
            {MultipleVatLeads != undefined ? (
                <NewLeadsMultipleVatDetailsDialog
                dialogOpen={this.state.LeadMultipleVatDetailDialogOpen}
                onClose={this.toggleVatModal}
                multiplevatdataleads={this.props.MultipleVatLeads}
            />
            ) : ('')}
            
        <FusePageSimple
        classes={{
            root: classes.layoutRoot
        }}
        header={
            <div className="flex flex-1 items-center justify-between p-24">
                <div className="flex flex-col">
                    <div className="flex items-center mb-16">
                        <Icon className="text-18" color="action">home</Icon>
                        <Icon className="text-16" color="action">chevron_right</Icon>
                        <Typography color="textSecondary">iCRM</Typography>
                        <Icon className="text-16" color="action">chevron_right</Icon>
                        <Typography color="textSecondary">{t('NEW') + " " + t('LEADS')}</Typography>
                    </div>
                    <Typography variant="h6">{t('NEW') + " " + t('LEADS')}</Typography>
                </div>
                <div className="flex flex-1 items-center justify-end px-12">
                    <Tooltip title={t("EDIT") + " Templates"}>
                        <Button className="successButton" onClick={this.handleFavoritesDialogOpen}>
                            <Icon>star</Icon>
                        </Button>
                    </Tooltip>
                    <Tooltip title={t('CREATE') + " " + t('NEW') + " " + t('LEADS')}>
                        <IconButton onClick={this.handleCreate}>
                        {/* <IconButton onClick={() => this.setState({editing: false }) && this.setState({form: {...this.state.form.initialForm}})}> */}
                            <Badge color="secondary">
                                <Icon>person_add</Icon>
                            </Badge>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        }
        content={
        <>
        <div className="w-full">
        <FuseAnimateGroup 
            className="flex flex-wrap"
            enter={{
                animation: "transition.slideUpBigIn"
            }}>
            <div className="flex w-full">
                <div className="w-full">
                    <NewLeadsTable
                        canBeValidated={this.canBeValidated}
                        onValidateNewLead={this.props.onValidateNewLead}
                        handleEdit={this.handleEdit}
                        newLeads={newLeads}
                    />
                </div>
            </div>
            
            <div className="flex w-full sm:w-full md:w-1/2 lg:w-1/2 p-24">
                <Card className="w-full">
                    <CardContent>
                        <Typography variant="h5" component="h3">
                            {this.state.editing === true ?
                                t('EDIT') + " " + t('NEW') + " " + t('LEADS')
                            :
                                t('CREATE') + " " + t('NEW') + " " + t('LEADS')
                            }
                        </Typography>
                        
                        <div className="p-12">
                         
                        {/* ZEEUW */}
                        {/* || this.props.viesData.viesIsValid === "true"  */}
                        <div className="flex">
                                {form.vieS_VALID ?
                                <>
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('VAT')}>
                                        <Icon color="action" style={{color: 'green'}}>business</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="btwnrdeb"
                                    name="btwnrdeb"
                                    label={t('VAT')}
                                    value={form.btwnrdeb}
                                    onChange={this.handleChange}
                                    placeholder={"FR12 345 678 910"}
                                    variant="outlined"
                                    fullWidth
                                    success
                                    onBlur={this.handleonBlur}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                              {form.email === '' || form.email === undefined || form.email === null ?
                                              <Tooltip title={"Email is required"}>
                                              <IconButton aria-label="Sync">
                                                <Icon style={{color: 'red'}}>sync_disabled</Icon>
                                              </IconButton>
                                              </Tooltip>
                                              :
                                              <IconButton aria-label="Sync">
                                              <Tooltip title={t('CHECK') + " " + t('VAT')}>
                                                  <Icon onClick={this.handleViesCheck} disabled={!this.canBeCreated(this.state.form)} color="primary">sync</Icon>
                                              </Tooltip>
                                              </IconButton>
                                              }
                                          </InputAdornment>,
                                        inputProps: {maxLength: 20}
                                    }}
                                />
                                <div className="min-w-48 ml-20 pt-20">
                                    <Tooltip title={t('VAT')}>
                                        <Icon color="action" style={{color: 'green'}}>check_circle</Icon>
                                    </Tooltip>
                                </div>
                                </>
                                :
                                <>
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('VAT')}>
                                    {form.btwnrdeb === '' || form.btwnrdeb === undefined || form.btwnrdeb === null ?
                                        <Icon color="action">business</Icon>
                                        :
                                        <Icon color="action" style={{color: 'red'}}>business</Icon>
                                    }
                                    </Tooltip>
                                </div>
                                <TextField
                                    className="mb-24"
                                    id="btwnrdeb"
                                    name="btwnrdeb"
                                    label={t('VAT')}
                                    value={form.btwnrdeb}
                                    onChange={this.handleChange}
                                    placeholder={"FR12 345 678 910"}
                                    variant="outlined"
                                    fullWidth
                                    error
                                    onBlur={this.handleonBlur}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                              {(form.btwnrdeb === '' || form.btwnrdeb === undefined || form.btwnrdeb === null) ?
                                              <Tooltip title={t('NEWLEADVIESFIRSTCREATENEWLEAD')}>
                                              <IconButton aria-label="Sync">
                                                <Icon style={{color: 'red'}}>sync_disabled</Icon>
                                              </IconButton>
                                              </Tooltip>
                                              :
                                            <IconButton aria-label="Sync" disabled={!form.btwnrdeb}>
                                                <Tooltip title={t('CHECK') + " " + t('VAT')}>
                                                    <Icon onClick={this.handleViesCheck} color="primary">sync</Icon>
                                                </Tooltip>
                                            </IconButton>
                                              }
                                          </InputAdornment>,
                                        inputProps: {maxLength: 20}
                                    }}
                                />
                                {/* Show red cancel icon when email and id are not empty */}
                                {(form.email === '' || form.email === undefined || form.email === null) && (form.id === '' || form.id === undefined || form.id === null) ? null :
                                <div className="min-w-48 ml-20 pt-20">
                                    <Tooltip title={t('VAT')}>
                                        <Icon color="action" style={{color: 'red'}}>cancel</Icon> 
                                    </Tooltip>
                                </div>
                                }
                                </>
                                }
                            </div>
                            
                            {form.vieS_VALID ?
                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={"VIES Info"}>
                                        <Icon color="action">business</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="viesinfo"
                                    name="btwnrdeb"
                                    label={"VIES Information " + t('VALIDATED')}
                                    value={form.vieS_NAME + "\n" + form.vieS_ADDRESS + "\n" + form.vieS_VAT}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{maxLength: 200}}
                                    fullWidth
                                    multiline
                                    disabled
                                />
                            </div>
                            :
                            <>
                            {form.vieS_VALID === '' ? null :
                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={"VIES Info"}>
                                        <Icon color="action">business</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="viesinfo"
                                    name="btwnrdeb"
                                    label={"VIES Information " + t('UNKONWN')}
                                    value={form.vieS_NAME + "\n" + form.vieS_ADDRESS}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{maxLength: 200}}
                                    fullWidth
                                    multiline
                                    disabled
                                    error
                                    color="secondary"
                                    // helperText={t('VAT') + " number is false."}
                                />
                            </div>
                            }
                            </>
                            }

                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('EMAIL')}>
                                        <Icon color="action">email</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="email"
                                    name="email"
                                    label={t('EMAIL')}
                                    value={form.email}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{maxLength: 100}}
                                    required
                                    fullWidth
                                    autoFocus
                                    disabled={this.state.editing === true ? true : false}
                                />
                            </div>

                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('OWNER')}>
                                        <Icon color="action">account_circle</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="debcont"
                                    name="debcont"
                                    label={t('OWNER')}
                                    value={form.debcont}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{maxLength: 100}}
                                    required
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('SHOP') + " " + t('NAME')}>
                                        <Icon color="action">store</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="debnaam"
                                    name="debnaam"
                                    // placeholder={t('SHOP') + " " + t('NAME')}
                                    label={t('SHOPNAMEFORINVOICE')}
                                    value={form.debnaam}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                                <Typography variant="caption" color="primary"><i>VIES</i></Typography>
                                            </InputAdornment>,
                                        inputProps: {maxLength: 100}
                                    }}
                                />
                            </div>

                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('ADDRESS')}>
                                        <Icon color="action">list</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="debadres"
                                    name="debadres"
                                    label={t('ADDRESS')}
                                    value={form.debadres}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    InputProps={{
                                      endAdornment: <InputAdornment position="end">
                                            <Typography variant="caption" color="primary"><i>VIES</i></Typography>
                                          </InputAdornment>,
                                        inputProps: {maxLength: 100}
                                    }}
                                />
                            </div>

                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('ZIPCODE')}>
                                        <Icon color="action">list</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="debpc"
                                    name="debpc"
                                    label={t('ZIPCODE')}
                                    value={form.debpc}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <Typography variant="caption" color="primary"><i>VIES</i></Typography>
                                          </InputAdornment>,
                                        inputProps: {maxLength: 8}
                                    }}
                                />
                            </div>

                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('CITY')}>
                                        <Icon color="action">list</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="debplaats"
                                    name="debplaats"
                                    label={t('CITY')}
                                    value={form.debplaats}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                                <Typography variant="caption" color="primary"><i>VIES</i></Typography>
                                            </InputAdornment>,
                                        inputProps: {maxLength: 50}
                                    }}
                                />
                            </div>

                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('PHONE')}>
                                        <Icon color="action">phone</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="debtfnr"
                                    name="debtfnr"
                                    label={t('PHONE')}
                                    value={form.debtfnr}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{maxLength: 20}}
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('PHONE')}>
                                        <Icon color="action">phone_iphone</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="debmobiel"
                                    name="debmobiel"
                                    label={t('MOBILE')}
                                    value={form.debmobiel}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{maxLength: 20}}
                                    fullWidth
                                />
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
            <div className="flex w-full sm:w-full md:w-1/2 lg:w-1/2 p-24">
                <Card className="w-full">
                    <CardContent>
                        <Typography variant="h5" component="h3">
                            {t('DELIVERY')}
                        </Typography>

                        <Switch
                            checked={this.state.checkedDelivery}
                            onChange={this.handleSwitch}
                            color="primary"
                            name="checkedDelivery"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />

                        {this.state.checkedDelivery === true || form.lvadresdb !== '' || form.lvpcdb !== '' || form.lvplaatsdb !== '' || form.lvnaamdb !== '' ?
                        <div className="p-12">
                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('SHOP') + " " + t('NAME')}>
                                        <Icon color="action">store</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="lvnaamdb"
                                    name="lvnaamdb"
                                    label={t('SHOP') + " " + t('NAME')}
                                    value={form.lvnaamdb}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{maxLength: 100}}
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('DELIVERY') + " " + t('CITY')}>
                                        <Icon color="action">local_shipping</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="lvadresdb"
                                    name="lvadresdb"
                                    label={t('DELIVERY') + " " + t('ADDRESS')}
                                    value={form.lvadresdb}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{maxLength: 100}}
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('DELIVERY') + " " + t('ZIPCODE')}>
                                        <Icon color="action">local_shipping</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="lvpcdb"
                                    name="lvpcdb"
                                    label={t('DELIVERY') + " " + t('ZIPCODE')}
                                    value={form.lvpcdb}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{maxLength: 8}}
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('DELIVERY') + " " + t('CITY')}>
                                        <Icon color="action">local_shipping</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="lvplaatsdb"
                                    name="lvplaatsdb"
                                    label={t('DELIVERY') + " " + t('CITY')}
                                    value={form.lvplaatsdb}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{maxLength: 50}}
                                    fullWidth
                                />
                            </div>

                        </div>
                        : ""

                        }
                        <Typography variant="h5" component="h3">
                            {t('COMPANY')}
                        </Typography>
                        <div className="p-12">
                            
                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('KVK')}>
                                        <Icon color="action">business</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="kvk"
                                    name="kvk"
                                    label={t('KVK')}
                                    value={form.kvk}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{maxLength: 20}}
                                    fullWidth
                                />
                            </div>
                          
                        </div>
                        <div class="p-12">
                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('DEBTEXTFIELD')}>
                                        <Icon color="action">comment</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="debtext"
                                    name="debtext"
                                    row={5}
                                    // name="acctmngR_NOTE"
                                    placeholder={t('DEBTEXTPLACEHOLDER')}
                                    label={t('DEBTEXTFIELD')}
                                    value={form.forM_SOURCE + form.debtext}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{maxLength: 255}}
                                    fullWidth
                                    multiline={true}
                                />
                            </div>
                            </div>
                       
                        <Typography variant="h5" component="h3">
                            Florisoft
                        </Typography>
                        <div className="p-12">
                            {/* <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('TRANSPORTER')}>
                                        <Icon color="action">local_shipping</Icon>
                                    </Tooltip>
                                </div>
                                <FormControl variant="outlined" className="mb-24" fullWidth>
                                    <Select
                                        // value={this.state.type}
                                        onChange={this.handleChange}
                                        value={form.transcod}
                                        input={
                                        <OutlinedInput
                                            labelWidth={0}
                                            name="transcod"
                                            id="transcod"
                                        />
                                        }
                                        required
                                        fullWidth
                                    >
                                    {TransportersNewLeads.length > 0 ? 
                                        TransportersNewLeads.map(i => <MenuItem key={i.transptr} value={i.transptr}>{i.transtxt}</MenuItem>)
                                    : null }
                                        // <MenuItem value="TMP001">Breweel</MenuItem>
                                        // <MenuItem value="TMP002">Time trans</MenuItem>
                                    </Select>
                                </FormControl>
                            </div> */}

                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('CUSTOMER') + " template"}>
                                        <Icon color="action">file_copy</Icon>
                                    </Tooltip>
                                </div>
                                <FormControl variant="outlined" className="mb-24" fullWidth>
                                    {TemplatesNewLeads.length > 0 ? 
                                    <Tooltip title={t('CUSTOMER') + " template"}>
                                    <Select
                                        // value={this.state.type}
                                        // onChange={this.handleChange}
                                        input={
                                        <OutlinedInput
                                            labelWidth={0}
                                            name="template"
                                            id="template"
                                            // defaultValue={"TM10"}
                                            value={form.template}
                                            onChange={this.handleChange}
                                        />
                                        }
                                        required
                                        fullWidth
                                    >
                                        {TemplatesNewLeads.map(i => <MenuItem key={i.template_debnr} value={i.template_debnr}>{i.template_name}</MenuItem>)}
                                        {/* <MenuItem value="TMP001">Template 001 FR</MenuItem>
                                        <MenuItem value="TMP002">template 002 NL</MenuItem> */}
                                    </Select>
                                    </Tooltip>
                                    :
                                        // <MenuItem key={i.template_debnr} value={i.template_debnr}>{i.template_name}</MenuItem>
                                        <Tooltip title={t("EDIT") + " Templates"}>
                                            <Button className="successButton" onClick={this.handleFavoritesDialogOpen}>
                                                <Icon>star</Icon>
                                                {t("EDIT") + " " + t("YOURFAVTEMPLATES")}
                                            </Button>
                                        </Tooltip>
                                    }
                                </FormControl>
                                
                            </div>
                            {DebType != undefined && DebType.length > 0 ? 
                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('DEBFINGRP')}>
                                        <Icon color="action">store</Icon>
                                    </Tooltip>
                                </div>
                                <FormControl variant="outlined" className="mb-24" fullWidth>
                                    <Tooltip title={t('DEBFINGRP')}>
                                    <Select
                                        // value={this.state.type}
                                        // onChange={this.handleChange}
                                    label={t('NOTE')}
                                        input={
                                        <OutlinedInput
                                            labelWidth={0}
                                            name="debfingrp"
                                            id="debfingrp"
                                            // defaultValue={"TM10"}
                                            value={form.debfingrp}
                                            onChange={this.handleChange}
                                        />
                                        }
                                        required
                                        fullWidth
                                    >
                                        {DebType.map(i => <MenuItem key={i.id} value={i.id}>{i.text}</MenuItem>)}
                                        {/* <MenuItem value="TMP001">Template 001 FR</MenuItem>
                                        <MenuItem value="TMP002">template 002 NL</MenuItem> */}
                                    </Select>
                                    </Tooltip>
                                </FormControl>
                                
                            </div>
                            :
                            ''
                            }
                            {this.state.form.template === 'ARCHIVE' ? 
                                <div className="flex">
                                    <div className="min-w-48">
                                        <Icon color="action" style={{color: 'orange'}}>warning</Icon>
                                    </div>
                                    <FormControl variant="outlined" className="mb-24" fullWidth>
                                        <p style={{color: 'orange'}}>{t('NEWLEADARCHIVEINFO')}</p>
                                    </FormControl>
                                </div>
                            : ""
                            }
                            {/* 
                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('SHOP') + " type"}>
                                        <Icon color="action">store</Icon>
                                    </Tooltip>
                                </div>
                                <FormControl variant="outlined" className="mb-24" fullWidth>
                                    <Select
                                        // value={this.state.type}
                                        // onChange={this.handleChange}
                                        input={
                                        <OutlinedInput
                                            labelWidth={0}
                                            id="typeofstore"
                                            name="typeofstore"
                                            label="Track Event"
                                            defaultValue="1"
                                            onChange={this.handleChange}
                                        />
                                        }
                                        required
                                        fullWidth
                                    >
                                        <MenuItem value="1">Florist</MenuItem>
                                        <MenuItem value="2">Retail</MenuItem>
                                    </Select>
                                </FormControl>
                            </div> */}
                            
                            {this.state.form.template === 'ARCHIVE' ? 
                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('NOTE')}>
                                        <Icon color="action">comment</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="acctmngR_NOTE"
                                    name="acctmngR_NOTE"
                                    // name="acctmngR_NOTE"
                                    // placeholder={t('SHOP') + " " + t('NAME')}
                                    label={t('NOTE')}
                                    value={form.acctmngR_NOTE}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{maxLength: 255}}
                                    fullWidth
                                    multiline={true}
                                    required
                                />
                            </div>
                            
                            :
                            
                            <div className="flex">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t('NOTE')}>
                                        <Icon color="action">comment</Icon>
                                    </Tooltip>
                                </div>

                                <TextField
                                    className="mb-24"
                                    id="acctmngR_NOTE"
                                    name="acctmngR_NOTE"
                                    // name="acctmngR_NOTE"
                                    // placeholder={t('SHOP') + " " + t('NAME')}
                                    label={t('NOTE')}
                                    value={form.acctmngR_NOTE}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    inputProps={{maxLength: 255}}
                                    fullWidth
                                    multiline={true}
                                />
                            </div>
                            
                            }
                            
                            {this.props.createNewLeadFailed ? <Typography style={{color: 'red'}}>Error : {this.props.error.toString()}</Typography> 
                            : null
                            }
                            
                            {this.props.isActivated === true && !this.state.isCreating ? 
                            <div className="w-full pt-24"><Alert className="w-full" severity="success">{t("CREATEDSUCCESS")}</Alert></div>
                            :
                            null}

                            {this.state.editing === true ?
                            <>
                            {form.acctmngR_VALIDATED === 1 ? 
                                <div className="w-full pb-24"><Alert className="w-full" severity="info">{t("NEWLEADALREADYVALIDATED")}</Alert></div>
                                :
                                null
                            }
    
                            {form.fS_EXPORTED === 1 ? 
                                <div className="w-full pb-12 mb-12"><Alert className="w-full" severity="error">{t("ALREADYEXPORTERNEWLEAD")}</Alert></div>
                                :
                                null
                            }
                               
                            {form.countEmails > 1 ? 
                                <div className="w-full pb-12 mb-12"><Alert className="w-full" severity="warning">{"(" + form.countEmails + ") " + t("MULTIPLEEMAILS")}.
                                     <span><Link LeadId={form.id} style={{color: "#ef6c00", cursor: "pointer"}} LeadEmail={form.email} onClick={()=>this.toggleUserModal(form.email)}> See details</Link> </span>
                                </Alert> 
                                
                                </div>
                                :
                                null
                            }

                            {form.countVAT > 1 ? 
                                <div className="w-full pb-12 mb-12"><Alert className="w-full" severity="warning">{"(" + form.countVAT + ") " + t("MULTIPLEVAT")}.
                                <Link LeadId={form.id} LeadVat={form.btwnrdeb} style={{color: "#ef6c00", cursor: "pointer"}} onClick={()=>this.toggleVatModal(form.btwnrdeb)}> See details</Link> 
                                </Alert>
                                     
                                </div>
                                :
                                null
                            }
                            <div className="flex items-end">
                                <div className="p-6">
                                    <Button onClick={this.handleReturnDialogOpen} disabled={!this.canBeReturned(this.state.form)} fullWidth variant="contained">{t('RETURN')}</Button>
                                </div>
                                <div className="p-6">
                                    <Button onClick={this.handleCreate} fullWidth variant="contained" color="secondary">{t('CANCEL')}</Button>
                                    </div>
                                <div className="p-6">
                                    <Button onClick={() => this.props.onEditNewLead(this.state.form)} disabled={!this.canBeCreated(this.state.form)} 
                                        fullWidth variant="contained" color="primary"
                                        startIcon={<Icon>save</Icon>} >{t('SAVE')}</Button>
                                </div>
                            </div>
                            {this.props.updatedLead === true ? 
                            <div className="w-full pt-24"><Alert className="w-full" severity="success">{t("UPDATEDSUCCESS")}</Alert></div>
                            :
                            null}
                            </>
                            :
                            <div className="flex items-end">
                            {this.props.isActivated === true && !this.state.isCreating ? 
                                <div className="p-6">
                                    <Button onClick={this.handleCreate} fullWidth variant="contained" color="secondary">{t('RESETFORM')}</Button>
                                </div>
                            : 
                            <>
                                <div className="p-6">
                                    <Button onClick={this.handleCreate} fullWidth variant="contained" color="secondary">{t('CANCEL')}</Button>
                                </div>
                                <div className="p-6">
                                    <Button onClick={this.onCreateNewLead} disabled={!this.canBeCreated(this.state.form)} fullWidth variant="contained" color="primary">{t('CREATE')}</Button>
                                </div>
                            </>
                            }
                            </div>
                            }
                        </div>
                    </CardContent>
                </Card>
            </div>
        </FuseAnimateGroup>
        </div>
        </>
        }
        />
        </>
        );
    }
}

const mapStateToProps = ({customer}) => {
   return {
      newLeads: customer.newLeads,
      TemplatesNewLeads: customer.TemplatesNewLeads,
      AllTemplatesNewLeads: customer.AllTemplatesNewLeads,
      createNewLeadFailed: customer.createNewLeadFailed,
      isActivated: customer.isActivated,
      viesData: customer.viesData,
      updatedLead: customer.updatedLead,
      error: customer.error,
      MultipleEmailLeads: customer.MultipleEmailLeads,
      MultipleVatLeads: customer.MultipleVatLeads,
      DebType: customer.DebType,
    //   TransportersNewLeads: customer.TransportersNewLeads,
   }
}


const mapDispatchToProps = dispatch => {
   return {
        onFetchNewLeads: () => dispatch(actions.fetchDbNewLeads()),
        onEditNewLead: (formData) => dispatch(actions.editNewLead(formData)),
        onCreateNewLead: (formData) => dispatch(actions.createNewLead(formData)),
        onFetchTemplatesNewLeads: () => dispatch(actions.FetchdbTemplatesNewLeads()),
        onFetchAllTemplatesNewLeads: () => dispatch(actions.FetchdbAllTemplatesNewLeads()),
        onFetchAllDebType: () => dispatch(actions.onFetchAllDebType()),
        // onFetchTransportersNewLeads: () => dispatch(actions.FetchdbTransportersNewLeads()),
        onValidateNewLead: email => dispatch(actions.validateNewLead(email)),
        onCheckVies: (formData, afterCheckVies) => dispatch(actions.checkVies(formData, afterCheckVies)),
        // onDoubleEmailCheck: (formData, afterDoubleEmailCheck) => dispatch(actions.doubleEmailCheck(formData, afterDoubleEmailCheck)),
        onDeleteFavUser: user => dispatch(actions.deleteFaviCRMUser(user)),
        onAddFavUser: user => dispatch(actions.addFaviCRMUser(user)),
        onSetDeleteFalse: () => dispatch(actions.setDeletedFalse()),
        onsetEditedNewLeadFalse: () => dispatch(actions.setEditedNewLeadFalse()),
        onFetchMultipleEmailLeads: email => dispatch(actions.fetchDbMultipleEmailLeads(email)),
        onFetchMultipleVatLeads: vat => dispatch(actions.fetchDbMultipleVatLeads(vat)),

   }
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles, {withTheme: true})(withTranslation()(NewLeads)));