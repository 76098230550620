import React, {Component} from 'react';
import {withStyles, Card, Typography} from '@material-ui/core';
import {Line} from 'react-chartjs-2';
import _ from 'lodash';

class UserStatsVisitsPerHour extends Component {
    render()
    {
        // to give props: data, dataset (is datasetname. see line 68: 'months'), headerText, transparentColors, 
        const { data: dataRaw, dataset} = this.props;
        const data = _.merge({}, dataRaw);
        const dataWithColors = data.datasets[dataset].map((obj, index) => {
            // let color = {
            //     backgroundColor: 'rgba(0,229,255, .3)',
            //     borderColor: 'rgb(0,229,255)',
            // }
            // let color = theme.colors[index] : theme.chartColors[index];
            return {
                ...obj,
                // borderColor              : obj.label === '2019' ? 'rgb(255, 255, 255, 0.0)' : color,
                borderColor              : 'rgb(25, 125, 125)',
                // backgroundColor          : obj.label === '2019' || obj.label === 'Target' ? 'rgb(255, 255, 255, 0.0)' : color,
                backgroundColor          : 'rgb(30, 156, 156, 0.7)',
                borderWidth: obj.label === 'Target' ? 1 : 3,
                pointBackgroundColor     : 'rgb(30, 156, 156)',
                pointHoverBackgroundColor: 'rgb(25, 125, 125)',
                pointBorderColor         : 'rgb(25, 125, 125)',
                pointHoverBorderColor    : 'rgb(30, 156, 156)',
                pointRadius: obj.label === 'Target' ? 3 : 4,
                pointBorderWidth: obj.label === 'Target' ? 1 : 2,
            }
        });

        return (
            <Card className="w-full rounded-8 shadow-none border-1">

                <div className="relative p-24 flex flex-row items-center justify-between">
                    <div className="flex flex-col text-16">{this.props.headerText} </div>
                </div>

                <Typography className="relative h-200 sm:h-320 sm:pb-16">
                    <Line
                        data={{
                            labels  : data.labels,
                            datasets: dataWithColors
                        }}
                        options={data.options}
                    />
                </Typography>
            </Card>
        );
    }
}

export default withStyles(null, {withTheme: true}) (UserStatsVisitsPerHour);

export const chartData = {
    chartType: 'line',
    datasets : {
        'months': [
            {
                label: "Invoices",
                fill: "start",
                data: [1542, 1022, 1166, 1134, 1106, 1421, 1072, 1370, 1135, 1377, 1034, 1010],
            },
        ]
    },
    labels   : ["5", "6", "7", "8", "9", "10", "11", "12", "1", "2", "3", "4"],
    options  : {
        spanGaps           : false,
        legend             : {
            display: false
        },
        maintainAspectRatio: false,
        tooltips           : {
            position : 'nearest',
            mode     : 'index',
            intersect: false,
            // callbacks: {
            //     label: (tooltipItem, data) => {
            //         var dataset = data.datasets[tooltipItem.datasetIndex];
            //         var currentValue = dataset.data[tooltipItem.index];
            //         return dataset.label + ': ' + Number(currentValue).toLocaleString('nl-NL');
            //     },
            // }
        },
        layout             : {
            padding: {
                left : 24,
                right: 32
            }
        },
        scales             : {
            xAxes: [
                {
                    gridLines: {
                        display: false
                    },
                    ticks    : {
                        fontColor: 'rgba(0,0,0,0.54)'
                    }
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        tickMarkLength: 16
                    },
                    ticks    : {
                        beginAtZero: true,
                        stepSize: 5
                    }
                }
            ]
        },
        plugins            : {
            filler: {
                propagate: false
            }
        }
    }
};