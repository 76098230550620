import React, { PureComponent } from 'react'
import {Card, CardContent, Typography, Chip, withStyles, CardHeader, Tooltip} from '@material-ui/core';
import PrelevementInvoices from './PrelevementInvoices';

const styles = theme => ({
    floatRight: {
        float: 'right',
        marginLeft: '4px',
    },
    cardHeader: {
        backgroundColor: '#f6f8f8',
        borderBottom: '1px solid',
        borderColor: '#dee5e7 !important;'
    }
});

class PrevelementCard extends PureComponent {
    render() {
        const {prelevements, classes} = this.props;
        return (
            <div className="flex flex-wrap w-full">
                {prelevements.map(prelevement => (
                    <div key={prelevement.debnr} className="flex w-full p-12 md:w-1/2">
                        <Card className="w-full">
                            <CardHeader 
                            className={classes.cardHeader}
                            title={
                                <>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {prelevement.name}
                                    <Tooltip title="Betaal termijn">
                                        <Chip
                                            className={classes.floatRight}
                                            label={prelevement.betaalTermijn}
                                            color="secondary"
                                            variant="outlined"
                                            clickable={false}
                                        />
                                    </Tooltip>
                                    {prelevement.bic.length > 0 ? 
                                    <Chip
                                        className={classes.floatRight}
                                        label="BIC"
                                        color="primary"
                                        variant="outlined"
                                        clickable={false}
                                    /> : null}
                                    {prelevement.iban.length > 0 ? 
                                    <Chip
                                        className={classes.floatRight}
                                        label="IBAN"
                                        color="primary"
                                        variant="outlined"
                                        clickable={false}
                                    /> : null}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {prelevement.debnr}
                                </Typography>
                                </>
                            } />
                            <CardContent>
                                <PrelevementInvoices invoices={prelevement.invoices} checkedInvoices={this.props.checkedInvoices}
                                    isInvoiceChecked={this.props.isInvoiceChecked} handleInvoiceCheck={this.props.handleInvoiceCheck}/>
                            </CardContent>
                        </Card>
                    </div>
                ))}
            </div>
        )
    }
}

export default withStyles(styles)(PrevelementCard);