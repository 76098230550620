import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../../store/specialoffer/actions';
import {FusePageSimple, FuseAnimateGroup} from '../../../../../@fuse';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import ReactTable from "react-table";
import {Icon, Typography, Tooltip, Card, CardContent, Button, FormControl, Select, TextField, Checkbox, OutlinedInput, MenuItem,
    List, ListItem, ListItemText, Snackbar, IconButton, InputAdornment} from '@material-ui/core';
import {fetchCompanies} from '../../../../store/crm/admin/actions';
import {withTranslation} from 'react-i18next';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import Alert from '@material-ui/lab/Alert';
import Axios from 'axios';
class EditSpecialOffer extends Component {

    state = {};

    componentDidMount() {
        this.props.onFetchCompanies();
        this.props.onFetchUsers(this.props.location.state);
        if(this.props.location.state.title !== this.state.title) {
            this.props.onFetchProducts(this.props.location.state);
            this.setState({...this.props.location.state, products: this.props.products, users: this.props.users});
        }
    }

    componentDidUpdate() {
        if(this.props.users.length !== this.state.users.length) {
            this.setState({users: this.props.users});
            Axios.get(
                "specialoffer/getpartijmarkeringen", {
                    params: {
                      Id: this.state.id
                    }
                }
            ).then(res => {
                // console.log(res.data);
                this.setState({
                markeringen: res.data
            })});
        } else if(this.props.products.length !== this.state.products.length) {
            this.setState({products: this.props.products});
        }
    }

    handleChange = (event) => {
        this.setState(_.set({...this.state}, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
    };

    handleOfferSave = () => {
        this.props.onSaveSpecialOffer(this.state);
    }

    handleUserAccess = (azureId) => {
        const users = _.cloneDeep(this.state.users);
        const index = users.findIndex(user => user.id === azureId);
        const user = users.find(user => user.id === azureId);
        users[index].specialOfferId = user.specialOfferId === 0 ? this.state.id : 0;
        this.setState({users: users});
        this.props.onChangeUserAccess({specialOfferId: this.state.id, azureId: user.id, canAccess: user.specialOfferId === 0 ? false : true});
    }

    canBeSubmitted()
    {
        const {title, type, devisie, productType, listId, senderEmail, emailSubject} = this.state;
        return (
            title.length > 0 && type.length > 0 && devisie.length > 0 && productType.length > 0 && (listId.length > 0 || listId > 0) 
            && senderEmail.length > 0 && emailSubject.length > 0
        );
    }
    
    handleSnackbarClose = () => {
        this.props.onCloseEditSnackbar();
        this.props.onCloseUserAccessSnackbar();
    }

    render() {
        const {users, products} = this.state;
        const {companies, t} = this.props;
        return (
            <FusePageSimple
                header={
                    <>
                    <div className="flex flex-1 items-center justify-between p-24">
                        <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                    <Icon className="text-18" color="action">home</Icon>
                                    <Icon className="text-16" color="action">chevron_right</Icon>
                                    <Typography color="textSecondary">iCRM</Typography>
                                    <Icon className="text-16" color="action">chevron_right</Icon>
                                    <Typography color="textSecondary">Manage {t("SPECIALOFFER")}</Typography>
                            </div>
                            <Typography variant="h6">{this.state.title}</Typography>
                        </div>
                    </div>
                    <Snackbar
                        style={{position: 'absolute'}}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.props.editedSpecialOffer}
                        autoHideDuration={3000}
                        onClose={this.handleSnackbarClose}
                        message={<span id="message-id">Special Offer has been successfully <strong>saved</strong>!</span>}
                        action={[
                            <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.handleSnackbarClose}
                            >
                                <Icon>close</Icon>
                            </IconButton>,
                        ]}
                    />
                    </>
                }
                content={
                    <>
                    <div className="w-full p-12">
                    {Object.keys(this.state).length > 0 ? 
                        <FuseAnimateGroup
                            className="flex flex-wrap"
                            enter={{
                                animation: "transition.slideUpBigIn"
                            }}
                        > 
                            <div className="flex w-full sm:w-full md:w-1/2 lg:w-1/3 p-12">
                                <Card className="w-full">
                                    <CardContent>
                                        <Typography variant="h5" component="h3">
                                        {t("EDIT")} {t("SPECIALOFFER")}
                                        </Typography>
                                        <div className="p-12">
                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title={t("SPECIALOFFER") + " " + t("NAME")}>
                                                        <Icon color="action">title</Icon>
                                                    </Tooltip>
                                                </div>

                                                <TextField
                                                    className="mb-24"
                                                    autoFocus
                                                    id="title"
                                                    label={"iCRM " + t("NAME")}
                                                    name="title"
                                                    value={this.state.title}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </div>

                                            <div className="flex">
                                                <Typography className="text-16 pb-20">
                                                    Florisoft Settings
                                                </Typography>
                                            </div>
                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                <Tooltip title="Connection">
                                                    <Icon color="action">business</Icon>
                                                </Tooltip>
                                                </div>
                                                <FormControl variant="outlined" className="mb-24" fullWidth>
                                                    <Select
                                                        value={this.state.connectionType.trim()}
                                                        label={t("COMPANY")}
                                                        onChange={this.handleChange}
                                                        input={
                                                        <OutlinedInput
                                                            labelWidth={0}
                                                            name="connectionType"
                                                            id="connectionType"
                                                        />
                                                        }
                                                        required
                                                        fullWidth
                                                    >
                                                    <MenuItem value="SQL">SQL</MenuItem>
                                                   <MenuItem value="SOAP">SOAP</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {this.state.connectionType == "SOAP" ? (<>
                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title="Soap Debnr">
                                                        <Icon color="action">title</Icon>
                                                    </Tooltip>
                                                </div>
                                                <TextField
                                                    className="mb-24"
                                                    id="soapDebnr"
                                                    label="Soap Debnr"
                                                    name="soapDebnr"
                                                    value={this.state.soapDebnr}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title="Soap Password">
                                                        <Icon color="action">title</Icon>
                                                    </Tooltip>
                                                </div>
                                                <TextField
                                                    className="mb-24"
                                                    id="soapPassword"
                                                    label="Soap Password"
                                                    name="soapPassword"
                                                    value={this.state.soapPassword}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </div>
                                            </>) : ('')}
                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                <Tooltip title={t("COMPANY")}>
                                                    <Icon color="action">business</Icon>
                                                </Tooltip>
                                                </div>
                                                <FormControl variant="outlined" className="mb-24" fullWidth>
                                                    <Select
                                                        value={this.state.companyId}
                                                        label={t("COMPANY")}
                                                        onChange={this.handleChange}
                                                        input={
                                                        <OutlinedInput
                                                            labelWidth={0}
                                                            name="companyId"
                                                            id="companyId"
                                                        />
                                                        }
                                                        required
                                                        fullWidth
                                                    >
                                                    {companies.length > 0 ? 
                                                        companies.map(company => <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>)
                                                    : null }
                                                    </Select>
                                                </FormControl>
                                            </div>

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title="Group Code">
                                                        <Icon color="action">people_outline</Icon>
                                                    </Tooltip>
                                                </div>
                                                <TextField
                                                    className="mb-24"
                                                    id="groupCode"
                                                    name="groupCode"
                                                    value={this.state.groupCode}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </div>

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title="Lijst type">
                                                        <Icon color="action">list_alt</Icon>
                                                    </Tooltip>
                                                </div>
                                                <FormControl variant="outlined" className="mb-24" fullWidth>
                                                    <Select
                                                        value={this.state.type}
                                                        onChange={this.handleChange}
                                                        input={
                                                        <OutlinedInput
                                                            labelWidth={0}
                                                            name="type"
                                                            id="type"
                                                        />
                                                        }
                                                        required
                                                        fullWidth
                                                    >
                                                        <MenuItem value="BESTEL">Bestellijst</MenuItem>
                                                        <MenuItem value="PARTIJ">Voorraad</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title="Vooraad code / bestel code">
                                                        <Icon color="action">list_alt</Icon>
                                                    </Tooltip>
                                                </div>
                                                <TextField
                                                    className="mb-24"
                                                    id="devisie"
                                                    label="Vooraad code / bestel code"
                                                    name="devisie"
                                                    value={this.state.devisie}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </div>
                                            {this.state.type === 'BESTEL' ?
                                            (<>
                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title="Sublijst">
                                                        <Icon color="action">subject</Icon>
                                                    </Tooltip>
                                                </div>
                                                <TextField
                                                    className="mb-24"
                                                    id="subLijst"
                                                    label="Sublijst"
                                                    name="subLijst"
                                                    value={this.state.subLijst}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </div>
                                            {this.state.connectionType != "SOAP" ? (
                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title="Instellingen Debiteur">
                                                        <Icon color="action">settings</Icon>
                                                    </Tooltip>
                                                </div>
                                                <TextField
                                                    className="mb-24"
                                                    id="bestelDebiteur"
                                                    label="Bestel Debiteur"
                                                    name="bestelDebiteur"
                                                    value={this.state.bestelDebiteur}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </div>) : null}
                                            </>)
                                            : null }

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title="Set here a product limit to display in the Email">
                                                        <Icon color="action">subject</Icon>
                                                    </Tooltip>
                                                </div>
                                                <TextField
                                                    className="mb-24"
                                                    id="maxProducts"
                                                    label="Max Products"
                                                    name="maxProducts"
                                                    type="number"
                                                    value={this.state.maxProducts}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </div>
                                            
                                            {this.state.type === 'BESTEL' && this.state.connectionType != "SOAP" ?
                                            <div className="flex">
                                                <div className="min-w-48 pt-16">
                                                <Checkbox
                                                    id="sublijstProducts"
                                                    label="Markering in sublijst"
                                                    name="sublijstProducts"
                                                    checked={this.state.sublijstProducts}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                                </div>
                                                <div className="min-w-48 pt-16">
                                                    <p className="p-16">Markering in sublijst</p>
                                                </div>
                                            </div> : null
                                            }
                                            {this.state.type === 'BESTEL' && this.state.connectionType != "SOAP" ?
                                            <div className="flex mb-20">
                                                <div className="min-w-48 pt-16">
                                                    <Checkbox
                                                        id="s_vink"
                                                        label="Debiter S Vink"
                                                        name="s_vink"
                                                        checked={this.state.s_vink}
                                                        onChange={this.handleChange}
                                                        required
                                                    />                                                    
                                                </div>
                                                <div className="min-w-48 pt-16">
                                                    <p className="p-16">Debiter S Vink</p>
                                                </div>
                                            </div> : null
                                            }

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                <Tooltip title="Markering : Aanbieding, Superdeal, Interessant, Exclusief, Angebot, Plant World...">
                                                    <Icon color="action">art_track</Icon>
                                                </Tooltip>
                                                </div>
                                                    <FormControl variant="outlined" className="mb-24" fullWidth>
                                                        <div className="flex">
                                                            <Select
                                                                value={this.state.productType}
                                                                onChange={this.handleChange}
                                                                input={
                                                                <OutlinedInput
                                                                    labelWidth={0}
                                                                    name="productType"
                                                                    id="productType"
                                                                />
                                                                }
                                                                required
                                                                fullWidth
                                                            >
                                                            {this.state.connectionType === "SOAP" ? 
                                                                [
                                                                    <MenuItem key="A" value="Angebot">Aanbieding</MenuItem>,
                                                                    <MenuItem key="E" value="EXKLUSIV">Exclusive</MenuItem>,
                                                                    <MenuItem key="I" value="Interessant">Interessant</MenuItem>,
                                                                    <MenuItem key="S" value="Superdeal">Superdeal</MenuItem>,
                                                                    <MenuItem key="P" value="Plant World">Plant World</MenuItem>
                                                                ]
                                                            :
                                                                this.state.type === "PARTIJ" ? 
                                                                // IF VOORRAAD
                                                                this.state.markeringen?.map(i => <MenuItem key={i.voorraad_id} value={i.voorraad_id}>{i.name} ({i.voorraad_id})</MenuItem>)
                                                                    // [
                                                                    //     <MenuItem key="J" value="J">Aanbieding</MenuItem>,
                                                                    //     <MenuItem key="N" value="N">Exclusive</MenuItem>,
                                                                    //     <MenuItem key="K" value="K">Interessant</MenuItem>,
                                                                    //     <MenuItem key="S" value="S">Superdeal</MenuItem>,
                                                                    //     <MenuItem key="P" value="P">Plant World</MenuItem>
                                                                    // ]
                                                                :
                                                                // IF BESTEL
                                                                this.state.markeringen?.map(i => <MenuItem key={i.bestel_id} value={i.bestel_id}>{i.name} ({i.bestel_id})</MenuItem>)
                                                                    // [
                                                                    //     <MenuItem key="0" value="0">Normal</MenuItem>,
                                                                    //     <MenuItem key="1" value="1">Sale</MenuItem>,
                                                                    //     <MenuItem key="2" value="2">Exclusive</MenuItem>,
                                                                    //     <MenuItem key="3" value="3">Interessant</MenuItem>,
                                                                    //     <MenuItem key="4" value="4">Superdeal</MenuItem>,
                                                                    //     <MenuItem key="80" value="80">Plant World</MenuItem>
                                                                    // ]
                                                            }
                                                            </Select>
                                                            <TextField
                                                                className="ml-12"
                                                                id="productType"
                                                                label="Product Markeren"
                                                                name="productType"
                                                                placeholder="Aanbieding, Superdeal, Interessant, Exclusief..."
                                                                value={this.state.productType}
                                                                onChange={this.handleChange}
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">
                                                                        <IconButton aria-label="Info">
                                                                        {this.state.connectionType == "SOAP" ? 
                                                                            <Tooltip title={"Check the Dutch Webshop markeren to find the exact word"}>
                                                                                <Icon color="primary">info</Icon>
                                                                            </Tooltip>
                                                                            :                                                                            
                                                                            <Tooltip title={"Check the  Webshop markeren id 87 ex : /Voorraad/B1?markering=87"}>
                                                                                <Icon color="primary">info</Icon>
                                                                            </Tooltip>}
                                                                        
                                                                        </IconButton>
                                                                    </InputAdornment>,
                                                                    inputProps: { maxLength: 20 }
                                                                }}
                                                            /> 
                                                        </div>
                                                    </FormControl>
                                            </div>

                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                            <div className="flex w-full sm:w-full md:w-1/2 lg:w-1/3 p-12">
                                <Card className="w-full">
                                    <CardContent>
                                        <div className="p-12">

                                            <div className="flex">
                                                <Typography className="text-16 pb-20">
                                                    Brevo Settings
                                                </Typography>
                                            </div>

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                <Tooltip title={"Brevo's account"}>
                                                    <Icon color="action">business</Icon>
                                                </Tooltip>
                                                </div>
                                                <FormControl variant="outlined" className="mb-24" fullWidth>
                                                    <Select
                                                        value={this.state.brevoId}
                                                        label={"Brevo account"}
                                                        onChange={this.handleChange}
                                                        input={
                                                        <OutlinedInput
                                                            labelWidth={0}
                                                            name="brevoId"
                                                            id="brevoId"
                                                        />
                                                        }
                                                        required
                                                        fullWidth
                                                    >
                                                    {companies.length > 0 ? 
                                                        companies.map(company => <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>)
                                                    : null }
                                                    </Select>
                                                </FormControl>
                                            </div>

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title="Brevo Email's template">
                                                        <Icon color="action">local_post_office</Icon>
                                                    </Tooltip>
                                                </div>

                                                <TextField
                                                    className="mb-24"
                                                    label="Template ID"
                                                    id="templateId"
                                                    name="templateId"
                                                    value={this.state.templateId}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </div>

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title="Brevo List ID">
                                                        <Icon color="action">list</Icon>
                                                    </Tooltip>
                                                </div>
                                                <TextField
                                                    className="mb-24"
                                                    id="listId"
                                                    label="Brevo List ID"
                                                    name="listId"
                                                    value={this.state.listId}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </div>

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title="Brevo Sender's Email">
                                                        <Icon color="action">local_post_office</Icon>
                                                    </Tooltip>
                                                </div>
                                                <TextField
                                                    className="mb-24"
                                                    id="senderEmail"
                                                    label="Sender's Email"
                                                    name="senderEmail"
                                                    value={this.state.senderEmail}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </div>

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title="Brevo Sender's Name">
                                                        <Icon color="action">title</Icon>
                                                    </Tooltip>
                                                </div>
                                                <TextField
                                                    className="mb-24"
                                                    id="senderName"
                                                    label="Sender's name"
                                                    name="senderName"
                                                    value={this.state.senderName}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </div>

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title="Email subject">
                                                        <Icon color="action">local_post_office</Icon>
                                                    </Tooltip>
                                                </div>
                                                <TextField
                                                    className="mb-24"
                                                    id="emailSubject"
                                                    label="Email Subject"
                                                    name="emailSubject"
                                                    placeholder="You can use SIB {{contact.DEBNAAM}} variables"
                                                    value={this.state.emailSubject}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </div>

                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                <Checkbox
                                                    id="abTesting"
                                                    label="A/B testing"
                                                    name="abTesting"
                                                    checked={this.state.abTesting}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                                </div>
                                                <div className="min-w-48 mb-24 pt-20">
                                                    {/* <Tooltip title="In gebruik?"> */}
                                                        <p className="p-16">A/B testing for subject</p>
                                                    {/* </Tooltip> */}
                                                </div>
                                            </div>

                                            {this.state.abTesting === true ?
                                            <>
                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title="Email subject B">
                                                        <Icon color="action">local_post_office</Icon>
                                                    </Tooltip>
                                                </div>
                                                <TextField
                                                    className="mb-24"
                                                    id="emailSubjectB"
                                                    label="Email Subject B"
                                                    name="emailSubjectB"
                                                    placeholder="You can use BREVO's {{contact.DEBNAAM}} variables"
                                                    value={this.state.emailSubjectB}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title="Add the size of your test groups. We'll send version A and B to a random sample of recipients, and then the winning version to everyone else">
                                                        <Icon color="action">local_post_office</Icon>
                                                    </Tooltip>
                                                </div>
                                                <TextField
                                                    className="mb-24"
                                                    id="absplitRule"
                                                    label="Split Rule"
                                                    name="absplitRule"
                                                    placeholder="Add the size of your test groups in 20%"
                                                    value={this.state.absplitRule}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title="Choose the duration of the test in hours. Maximum is 7 days, pass 24*7 = 168 hours. The winning version will be sent at the end of the test.">
                                                        <Icon color="action">local_post_office</Icon>
                                                    </Tooltip>
                                                </div>
                                                <TextField
                                                    className="mb-24"
                                                    id="abwinnerDelay"
                                                    label="Winner Delay"
                                                    name="abwinnerDelay"
                                                    placeholder="Choose the duration of the test in hours"
                                                    value={this.state.abwinnerDelay}
                                                    onChange={this.handleChange}
                                                    inputProps={{ maxLength: 3 }}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </div>
                                            </>
                                            : ""

                                            }

                                            <div className="flex">
                                                <div className="min-w-48 pt-16">
                                                <Checkbox
                                                    id="enabled"
                                                    label="Enabled"
                                                    name="enabled"
                                                    checked={this.state.enabled}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                                </div>
                                                <div className="min-w-48 pt-16">
                                                    <Tooltip title="In gebruik?">
                                                        <p className="p-16">Enabled</p>
                                                    </Tooltip>
                                                </div>
                                            </div>

                                            <div className="flex items-end">
                                                <Button fullWidth onClick={this.handleOfferSave} variant="contained" color="primary" disabled={!this.canBeSubmitted()}>{t("SAVE")}</Button>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                            <div className="flex w-full sm:w-full md:w-1/2 lg:w-1/3 p-12">
                                <Card className="w-full">
                                    <CardContent>
                                        {this.props.productsLoading ? <Spinner /> : 
                                        <Scrollbars autoHeight autoHeightMin={50} autoHeightMax={860}>
                                            <List className="w-full m-w-360">
                                            {products && products.length > 0 ? products.map(product => (
                                                <ListItem key={product.productNumber + product.partijNumber} alignItems="flex-start">
                                                    <img
                                                        src={product.photoUrl}
                                                        onError={(e)=> { 
                                                            // debugger;
                                                            if (e.target.src !== product.artikelPhoto) {
                                                                e.target.src = product.artikelPhoto;
                                                            }
                                                        }} 
                                                        width="50" 
                                                        height="50" 
                                                        alt={product.text}  />
                                                    <ListItemText
                                                    primary={product.text + " — € " + product.price}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography component="span" className="inline" color="textPrimary">
                                                            Stock: {product.amount} 
                                                            </Typography>
                                                            &nbsp;— S01: {product.productLength}
                                                        </React.Fragment>
                                                    }
                                                    />
                                                </ListItem>
                                            )) : "0 products were found"}
                                            </List>
                                        </Scrollbars>
                                        }
                                        {this.props.productsLoading === false && this.props.productsError != undefined && products.length === 0 ? 
                                        <div className="w-full"><Alert className="w-full text-center" severity="warning">{this.props.productsError && <span>ERROR!</span>}</Alert></div>
                                        :
                                        null}
                                        
                                    </CardContent>
                                </Card>
                            </div>
                            <div className="w-full p-12">
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    open={this.props.changedUserAccess}
                                    autoHideDuration={1500}
                                    onClose={this.handleSnackbarClose}
                                    message={<span id="message-id">User access has been successfully <strong>saved</strong>!</span>}
                                    action={[
                                        <IconButton
                                        key="close"
                                        aria-label="Close"
                                        color="inherit"
                                        onClick={this.handleSnackbarClose}
                                        >
                                            <Icon>close</Icon>
                                        </IconButton>,
                                    ]}
                                />
                            {this.state.users.length > 0 && this.props.fetchedUsers ? 
                                <ReactTable
                                    className="-striped -highlight border-0"
                                    data={users}
                                    columns={[
                                        {
                                            Header    : t("USERACCESS"),
                                            accessor  : "specialOfferId",
                                            filterable: false,
                                            className: "justify-center",
                                            Cell: row => (
                                                <div>{row.value === this.state.id ? 
                                                    <Icon className="text-18" style={{color: 'green'}}>check</Icon> : 
                                                    <Icon className="text-18" color="error">clear</Icon>}</div>
                                            ),
                                            width: 120
                                        },
                                        {
                                            Header    : t("NAME"),
                                            accessor  : "name",
                                            filterable: true,
                                            className : "font-bold justify-center",
                                            width     : 300,
                                        },
                                        {
                                            Header    : "Email",
                                            accessor  : "email",
                                            filterable: false,
                                            className : "font-bold justify-center",
                                            width     : 300,
                                        },
                                        {
                                            Header    : "Give Access",
                                            filterable: false,
                                            className: "justify-center",
                                            Cell: row => (
                                                <Checkbox
                                                    id="enabled"
                                                    label="Enabled"
                                                    name="enabled"
                                                    checked={row.original.specialOfferId === this.state.id}
                                                    onChange={() => this.handleUserAccess(row.original.id)}
                                                    required
                                                />
                                            )
                                        },
                                    ]}
                                    pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                                    defaultPageSize={users.length <= 10 ? users.length : 10}
                                    noDataText="No customers found"
                                /> : null }
                            </div>
                        </FuseAnimateGroup>
                    : null }
                    </div>
                    </>
                }
            />
        )
    }
}
  
const mapStateToProps = ({specialoffer, admin}) => {
    return {
        products: specialoffer.products,
        productsError: specialoffer.productsError,
        productsLoading: specialoffer.productsLoading,
        users: specialoffer.users,
        fetchedUsers: specialoffer.fetchedUsers,
        editedSpecialOffer: specialoffer.editedSpecialOffer,
        changedUserAccess: specialoffer.changedUserAccess,
        companies: admin.companies
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchProducts: (specialOffer) => dispatch(actions.fetchProductsBySpecialOffer(specialOffer)),
        onFetchUsers: (specialOffer) => dispatch(actions.fetchUsersBySpecialOffer(specialOffer)),
        onSaveSpecialOffer: (specialOffer) => dispatch(actions.editSpecialOffer(specialOffer)),
        onChangeUserAccess: (userAccessDto) => dispatch(actions.changeUserAccess(userAccessDto)),
        onCloseEditSnackbar: () => dispatch(actions.closeEditSnackbar()),
        onCloseUserAccessSnackbar: () => dispatch(actions.closeUserAccessSnackbar()),
        onFetchCompanies: () => dispatch(fetchCompanies()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(EditSpecialOffer)));