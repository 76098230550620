import React, {Component} from 'react'
import { connect } from 'react-redux';
import {withStyles, Paper, Typography, Button, TextField, InputAdornment, Icon} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import * as actions from '../../../store/auth/actions';
import {darken} from '@material-ui/core/styles/colorManipulator';
import {withRouter} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import loginClasses from './FuseLogin.module.css';
import Responsive from 'react-responsive';
import {FuseAnimate} from '../../../../@fuse';
import LanguageFlags from './LanguageFlags';
import AgreementDialog from './AgreementDialog';
import axios from 'axios';
import GebruikersOvereenkomst from '../../../../pdfs/Gebruiksvoorwaarden_iCRM_28-10.pdf'
import PrivacyStatement from '../../../../pdfs/Privacy_statement_iCRM_28-10.pdf'

const styles = theme => ({
    root: {
        background: 'linear-gradient(to right, ' + theme.palette.primary.dark + ' 0%, ' + darken(theme.palette.primary.dark, 0.5) + ' 100%)',
        color     : theme.palette.primary.contrastText,
        // overflow: 'auto'
    }
});

// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class SimpleLogin extends Component {

    state = {
        forgotPassword: false,
        email: '',
        password: '',
        IP: '',
        emailValid: true,
        passwordValid: true,
        agreementDialogOpen: false, 
        agreementDialogFunction: null, 
        // loginAttempts: 0,
    };

    componentDidMount() {
        axios.get('https://geolocation-db.com/json/')
            .then(res => {
             // console.log(res.data.IPv4);
                this.setState({ IP: res.data.IPv4})
            })
            .catch(err => {
                console.log(err);
            })
    }

    disableButton = () => {
        this.setState({canSubmit: false});
    };

    enableButton = () => {
        this.setState({canSubmit: true});
    };

    handleChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        if(name === 'email') {
            const emailValid = this.validateEmail(value);
            this.setState({ email: value, emailValid})
        }
        if(name === 'password') {
            const passwordValid = value.length > 7;
            this.setState({ password: value, passwordValid })
        }
    }

    validateEmail = (email) => {
        return emailRegex.test(String(email).toLowerCase());
    }

    onSubmitLogin = () => {
        // this.setState({ loginAttempts: this.state.loginAttempts + 1}
        //     // , () => {console.log(this.state.loginAttempts)}
        // )
        const {email, password, IP} = this.state;
        if(email.length > 1 && password.length > 7) {
            const afterLoginSuccess = (user) => {
                // console.log(user.tosAgreement);
                if(user.tosAgreement) {
                    this.props.history.push('/iCRM/Inbox');
                } else {
                    const handleAgreeClick = async () => {
                        await axios.post('auth/SetTosAgreement', { id: user.id });
                        this.props.onSendLoginUserInfoAfterAgreement(user);
                    }
                    this.setState({ agreementDialogOpen: true, agreementDialogFunction: handleAgreeClick});
                }
            }
            this.props.onSubmitLogin({email, password, IP}, afterLoginSuccess);
        }
    };

    setForgotPassword = (forgotPassword) => {
        this.setState({ forgotPassword });
    }

    onSubmitForgotPassword = () => {
        this.props.onResetPassword({email: this.state.email}, (resetted) => this.setForgotPassword(!resetted));
    };

    handleLanguageChange = (lng) => {
        localStorage.setItem('i18n', lng);
        i18next.changeLanguage(lng);
    }

    render() {
        const { t } = this.props;
        const { emailValid, passwordValid } = this.state;

        let loginScreen = null;
        let forgotPasswordScreen = null;

        const agreementText = (<>{t("login_privacy_acknowledgement")}&nbsp;
        <a className={loginClasses.ResetPassword} href={GebruikersOvereenkomst} target="_blank" rel="noreferrer" color="secondary">{t('user_agreement')}</a>
        &nbsp;{t('and_the')} <a className={loginClasses.ResetPassword} href={PrivacyStatement} target="_blank" rel="noreferrer" color="secondary">{t('privacy_statement')}</a></>)
        if(!this.state.forgotPassword) {
            loginScreen = (
                <React.Fragment>
                    <TextField
                        className="mb-16 w-full"
                        type="text"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                this.onSubmitLogin();
                            }}
                        }
                        label={t('email')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">email</Icon></InputAdornment>
                        }}
                        variant="outlined"
                        required
                        error={!emailValid}
                        helperText={!emailValid ? `${t('enter_valid_email')}` : ''}
                    />
                    <TextField
                        className="mb-16 w-full"
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                this.onSubmitLogin();
                            }}
                        }
                        label={t('password')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">vpn_key</Icon></InputAdornment>
                        }}
                        variant="outlined"
                        required
                        error={!passwordValid}
                        helperText={!passwordValid ? `${t('min_charachter_length')} 8` : ''}
                    />
    
                    {this.props.error ? <Typography variant="body2" color="error">{t('wrong_credentials')}</Typography> : null}
                    {this.props.error && this.props.loginAttempts > 0 ? <Typography variant="body2" color="error">{t('LOGIN_ATTEMPTS')} {this.props.loginAttempts}/3</Typography> : null}
                    {this.props.error && this.props.loginAttempts >= 3 ? <Typography variant="body2" color="error">{t('TOO_MANY_WRONG_PASSWORDS_ACCOUNT_LOCKED')}</Typography> : null}
    
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="w-5/6 m-12 normal-case"
                        aria-label="LOG IN"
                        disabled={!emailValid || !passwordValid || this.props.loginAttempts >= 3}
                        // disabled={!emailValid || !passwordValid && !this.state.loginAttempts < 4}
                        onClick={this.onSubmitLogin}
                    >
                        {t('login').toUpperCase()}
                    </Button>
                    <span className={loginClasses.ResetPassword} onClick={() => this.setForgotPassword(true)}>{t('reset_password')}</span>
                </React.Fragment>
            )
        } else {
            forgotPasswordScreen = (
                <React.Fragment>
                    <TextField
                            className="mb-16 w-full"
                            type="text"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            label={t('email')}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">email</Icon></InputAdornment>
                            }}
                            variant="outlined"
                            required
                        />
    
                        {this.props.error && <Typography variant="body2" color="error">{t('wrong_credentials')}</Typography>}
    
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="w-5/6 m-12 normal-case"
                            aria-label="REQUEST NEW PASSWORD"
                            disabled={!emailValid}
                            value="legacy"
                            onClick={this.onSubmitForgotPassword}
                        >
                            {t('send_reset_link').toUpperCase()}
                        </Button>
                        <span className={loginClasses.ResetPassword} onClick={() => this.setForgotPassword(false)}>{t('back_to_login')}</span>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <AgreementDialog 
                    agreementText={agreementText} 
                    open={this.state.agreementDialogOpen}
                    handleClose={() => this.setState({ agreementDialogOpen: false })}
                    handleAgree={this.state.agreementDialogFunction}
                />
                <div className={loginClasses.Container} >
                    <Paper className={loginClasses.Paper} elevation={3}>
                        <Responsive minHeight={600}>
                            <FuseAnimate animation="transition.expandIn">
                                <img className="w-128 mb-32" src="assets/images/logos/iCRM-blue.svg" alt="logo"/>
                            </FuseAnimate>
                        </Responsive>
                        {
                            this.props.resetted && !this.props.resetPasswordError ? 
                            <Alert className="mb-24" severity="success">{t('password_reset_successful')}.</Alert>
                            : null
                        }
                        {
                            this.props.resetted && this.props.resetPasswordError ? 
                            <Alert className="mb-24" severity="error" >{t('something_went_wrong')} {t('please_try_again')} {t('or')} <a href="mailto:icrm@floraltradegroup.com">{t('contact_us')}</a></Alert>
                            : null
                        }

                        {!this.state.forgotPassword ? loginScreen : forgotPasswordScreen}
                        <LanguageFlags handleLanguageChange={this.handleLanguageChange} />
                        <div className="w-full text-center">
                            <Typography color="textSecondary" style={{fontSize: 12}}>
                                {agreementText}
                            </Typography>
                        </div>
                    </Paper>
                </div>
            </React.Fragment>
        )
    }
}


function mapStateToProps({ flortalAuth }) {
    return {
        resetted: flortalAuth.resetted,
        resetPasswordError: flortalAuth.resetPasswordError,
        error: flortalAuth.error,
        loginAttempts: flortalAuth.loginAttempts,
    }
}

function mapDispatchToProps(dispatch) 
{
    return {
        onResetPassword: (model, afterResetSuccess) => dispatch(actions.resetPassword(model, afterResetSuccess)),
        onSubmitLogin: (loginModel, afterLoginSuccess) => dispatch(actions.sendLogin(loginModel, afterLoginSuccess)),
        onSendLoginUserInfoAfterAgreement: (user) => dispatch(actions.sendLoginUserInfoAfterAgreement(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(withTranslation()(withRouter(SimpleLogin))));