import {
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import styled from 'styled-components';

const StyledIcon = styled(Icon)`
  color: #e97315;
`

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 40px;
`

const UserStatsListItem = ({ icon, value, label, shouldDisplay }) => {

  if(!shouldDisplay) return null;
  return <ListItem>
    <StyledListItemIcon>
      <StyledIcon>{icon}</StyledIcon>
    </StyledListItemIcon>
    <ListItemText
      primary={
        <span /*className="float-right"*/>
            <strong style={{color: '#e97315'}}>{value}</strong> 
            {" " + label}
        </span>
      }
    />
  </ListItem>
};

export default UserStatsListItem;