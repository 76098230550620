import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../store/specialoffer/actions';
import {FusePageSimple, FuseAnimateGroup} from '../../../../@fuse';
import {Icon, Typography, IconButton, Tooltip, Fab, Snackbar} from '@material-ui/core';
import ReactTable from "react-table";
import AddSpecialOffer from './AddSpecialOffer/AddSpecialOffer';

class ManageSpecialOffers extends Component {

    state = {
        specialOffers: [],
        dialogOpen: false,
    };

    componentDidMount() {
        this.props.onFetchSpecialOffers();
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.specialOffers.length !== this.props.specialOffers.length || this.state.specialOffers.length !== nextProps.specialOffers.length) {
            this.setState({specialOffers: nextProps.specialOffers});
        }
    }

    handlePromoClick = (id, values) => {
        this.props.history.push(`/specialoffer/manage/${id}`, {...values})
    }

    handleOpen = () => {
        this.setState({dialogOpen: !this.state.dialogOpen});
    }

    handleClose = () => {
      this.setState({ dialogOpen: false });
    };

    handleSnackbarClose = () => {
        this.props.onCloseInsertSnackbar();
        this.props.onCloseDeleteSnackbar();
    }

    handleSave = value => {
        const newSpecialOffers = [...this.state.specialOffers, value];
        this.setState({ specialOffers: newSpecialOffers, dialogOpen: false });
        const newSpecialOffer = {...value, listId: parseInt(value.listId)};
        this.props.onInsertSpecialOffer(newSpecialOffer);
    }

    render() {
        const tableData = [...this.state.specialOffers];
        return (
            <>
            <Snackbar
                style={{position: 'absolute'}}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.props.insertedSpecialOffer || this.props.deletedSpecialOffer}
                autoHideDuration={3000}
                onClose={this.handleSnackbarClose}
                message={<span id="message-id">Special Offer has been successfully {this.props.insertedSpecialOffer ? <strong>added</strong> : <strong>deleted</strong>}!</span>}
                action={[
                    <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={this.handleSnackbarClose}
                    >
                        <Icon>close</Icon>
                    </IconButton>,
                ]}
            />
            <FusePageSimple
                header={
                    <div className="flex flex-1 items-center justify-between p-24">
                        <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                    <Icon className="text-18" color="action">home</Icon>
                                    <Icon className="text-16" color="action">chevron_right</Icon>
                                    <Typography color="textSecondary">iCRM</Typography>
                                    <Icon className="text-16" color="action">chevron_right</Icon>
                                    <Typography color="textSecondary">Manage Special Offers</Typography>
                            </div>
                            <Typography variant="h6">Manage Special Offers</Typography>
                        </div>
                        <div className="flex items-end">
                            <Fab aria-label="Add" onClick={this.handleOpen}>
                                <Icon>add</Icon>
                            </Fab>
                        </div>
                    </div>
                }
                content={
                    <>
                        <AddSpecialOffer dialogOpen={this.state.dialogOpen} onClose={this.handleClose} onSave={this.handleSave} />
                        <div className="w-full">
                        { tableData.length > 0 ?
                        <FuseAnimateGroup
                            className="flex flex-wrap"
                            enter={{
                                animation: "transition.slideUpBigIn"
                            }}
                        > 
                            <div className="w-full">
                                <ReactTable
                                    className="-striped -highlight border-0"
                                    data={tableData}
                                    filterable={false}
                                    columns={[
                                        {
                                            Header    : "Enabled",
                                            accessor  : "enabled",
                                            filterable: false,
                                            className: "justify-center",
                                            Cell: row => (
                                                <div>{row.value ? 
                                                    <Icon className="text-18" style={{color: 'green'}}>check</Icon> : 
                                                    <Icon className="text-18" color="error">clear</Icon>}</div>
                                            ),
                                            width: 60
                                        },
                                        // {
                                        //     Header    : "S Vink",
                                        //     accessor  : "s_vink",
                                        //     filterable: false,
                                        //     className: "justify-center",
                                        //     Cell: row => (
                                        //         <div>{row.value ? 
                                        //             <Icon className="text-18" style={{color: 'green'}}>check</Icon> : 
                                        //             <Icon className="text-18" color="error">clear</Icon>}</div>
                                        //     ),
                                        //     width: 80
                                        // },
                                        // {
                                        //     Header    : "id",
                                        //     accessor  : "id",
                                        //     className : "justify-center",
                                        //     width     : 70,
                                        //     //Cell: row => (<span onClick={() => this.handlePromoClick(row.original.id)} >{row.value}</span>)
                                        // },
                                        {
                                            Header    : "Name",
                                            accessor  : "title",
                                            className : "font-bold justify-center",
                                            filterable: true,
                                            // width     : 240,
                                            Cell: row => (<span style={{cursor: 'pointer'}} onClick={() => this.handlePromoClick(row.original.id, row.original)} >{row.value}</span>)
                                        },
                                        {
                                            Header    : "type",
                                            accessor  : "type",
                                            className : "justify-center",
                                            filterable: true,
                                            width: 100,
                                        },
                                        {
                                            Header    : "Devisie",
                                            accessor  : "devisie",
                                            className: "justify-center",
                                            filterable: true,
                                            width: 100,
                                        },
                                        {
                                            Header    : "Sublijst",
                                            accessor  : "subLijst",
                                            className: "justify-center",
                                            filterable: true,
                                            width: 100,
                                        },
                                        {
                                            Header    : "BestelDeb",
                                            accessor  : "bestelDebiteur",
                                            className: "justify-center",
                                            filterable: true,
                                            width: 100,
                                        },
                                        // {
                                        //     Header    : "Product type",
                                        //     accessor  : "productType",
                                        //     className: "justify-center",
                                        //     width: 100,
                                        // },
                                        {
                                            Header    : "SIB list id",
                                            accessor  : "listId",
                                            className: "justify-center",
                                            filterable: true,
                                            width: 100,
                                        },
                                        {
                                            Header    : "Template",
                                            accessor  : "templateId",
                                            className: "justify-center",
                                            filterable: true,
                                            width: 100,
                                        },
                                        {
                                            Header    : "Group Code",
                                            accessor  : "groupCode",
                                            className: "justify-center",
                                            filterable: true,
                                            width: 100,
                                        },
                                        {
                                            Header    : "Max",
                                            accessor  : "maxProducts",
                                            className: "justify-center",
                                            width: 50,
                                        },
                                        {
                                            Header    : "Sender",
                                            accessor  : "senderEmail",
                                            filterable: true,
                                            className: "justify-center",
                                            Cell: row => (
                                                <div>{row.value ?                                                    
                                                <Tooltip title={row.value}>
                                                    <Icon className="text-18" style={{color: 'green'}}>check</Icon> 
                                                </Tooltip>
                                                    : 
                                                <Tooltip title={row.value}>
                                                    <Icon className="text-18" color="error">clear</Icon>
                                                </Tooltip>
                                                    }</div>
                                            ),
                                            width: 60
                                        },
                                        {
                                            Header    : "Subject",
                                            accessor  : "emailSubject",
                                            filterable: true,
                                            className: "justify-center",
                                            Cell: row => (
                                                <div>{row.value ?                                                    
                                                <Tooltip title={row.value}>
                                                    <Icon className="text-18" style={{color: 'green'}}>check</Icon> 
                                                </Tooltip>
                                                    : 
                                                    <Icon className="text-18" color="error">clear</Icon>}</div>
                                            ),
                                            width: 60
                                        },
                                        {
                                            Header    : "AB test",
                                            accessor  : "abTesting",
                                            filterable: false,
                                            className: "justify-center",
                                            Cell: row => (
                                                <div>{row.value ? 
                                                    <Icon className="text-18" style={{color: 'green'}}>check</Icon> : 
                                                    <Icon className="text-18" color="error">clear</Icon>}</div>
                                            ),
                                            width: 60
                                        },
                                        {
                                            Header    : "Edit",
                                            className: "justify-center",
                                            width: 60,
                                            Cell: row => (
                                            <Tooltip title={row.value !== undefined ? row.value : 'Edit'}>
                                                <IconButton aria-label="Edit" onClick={() => this.handlePromoClick(row.original.id, row.original)}>
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                            </Tooltip>
                                            )
                                        },
                                        {
                                            Header    : "Delete",
                                            filterable: false,
                                            className: "justify-center",
                                            width: 60,
                                            Cell: row => (
                                            <Tooltip title={row.value !== undefined ? row.value : 'Delete'}>
                                                <IconButton aria-label="Delete" style={{color: 'red'}} onClick={() => window.confirm("Are you sure?") && this.props.onDeleteSpecialOffer(row.original)}>
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            </Tooltip>
                                            )
                                        },
                                    ]}
                                    pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                                    defaultPageSize={10}
                                    noDataText="No customers found"
                                /> 
                            </div>
                        </FuseAnimateGroup> : null }
                        </div>
                    </>
                }
            />
        </>
        )
    }
}
  
const mapStateToProps = state => {
    return {
        specialOffers: state.specialoffer.specialOffers,
        insertedSpecialOffer: state.specialoffer.insertedSpecialOffer,
        deletedSpecialOffer: state.specialoffer.deletedSpecialOffer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchSpecialOffers: () => dispatch(actions.fetchSpecialOffers()),
        onInsertSpecialOffer: (specialOffer) => dispatch(actions.insertSpecialOffer(specialOffer)),
        onDeleteSpecialOffer: (specialOffer) => dispatch(actions.deleteSpecialOffer(specialOffer)),
        onCloseInsertSnackbar: () => dispatch(actions.closeInsertSnackbar()),
        onCloseDeleteSnackbar: () => dispatch(actions.closeDeleteSnackbar()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageSpecialOffers));