const nl = {
    translations: {
        // Algemeen
        "tos_pp": "Gebruikersovereenkomst en Privacy Statement",
        "tos_pp_detail": "iCRM verzamelt data als paginabezoeken om uw gebruikerservaring te verbeteren",
        "login_privacy_acknowledgement": "Door in te loggen ga je akkoord met de",
        "user_agreement": "gebruikersovereenkomst",
        "privacy_statement": "privacy statement",
        "and_the": "en het",
        "agree": "Akkoord",
        "disagree": "Afwijzen",
        "login": "Inloggen",
        "logout": "Uitloggen",
        "login_to_your_account": "Log in op uw account",
        "email": "Email",
        "password": "Wachtwoord",
        "confirm_password": "Wachtwoord bevestigen",
        "reset_password": "Wachtwoord resetten",
        "recover_your_password": "Herstel uw wachtwoord",
        "send_reset_link": "Verstuur reset link",
        "back_to_login": "Terug naar inloggen",
        "update_your_password": "Update uw wachtwoord",
        "update_your_account": "Update uw account",
        "update_my_account": "Update mijn account",
        "why_update_account": "Waarom moet ik mijn account bijwerken?",
        "why_update_account_detail": "iCRM is bezig met het implementeren van een nieuw login systeem om toegang te verlenen aan externe gebruikers. Vul alstublieft het onderstaande formulier in om uw account gereed te maken voor het nieuwe login systeem.",
        "password_reset_successful": "Wachtwoord reset succesvol! Bekijk s.v.p. uw mail om een nieuw wachtwoord in te stellen",
        "min_charachter_length": "Minimale karakter lengte is",
        "enter_valid_email": "Voer alstublieft een geldig emailadres in",
        "something_went_wrong": "Er ging iets fout.",
        "please_try_again": "Probeer het opnieuw alstublieft opnieuw",
        "or": "of",
        "contact_us": "neem contact met ons op",
        "wrong_credentials": "Verkeerde gegevens. Probeer het opnieuw of stel uw wachtwoord opnieuw in.",
        "conclude": "afhandelen",
        "conclude_explanation": "Meld de taak als afgehandeld. Het formulier wordt automatisch opgeslagen.",
        "concluded": "Afgehandeld",
        "archive": "Archief",
        "show_assigned_tasks": "Taken die je aan anderen hebt toegewezen",
        "reopen": "Heropen",
        "false_alarm": "Geen problemen",
        "false_alarm_explanation": "Deze taak is gegenereerd op basis van gegevens van BI. Mocht je vermoeden of mocht de klant aangeven dat er niets aan de hand is, meld deze taak dan als 'geen problemen'. Zo kunnen we het takensysteem blijven verbeteren.",
        "is_required": "is verplicht",
        "concerning": "betreffende",
        "COPY": "Kopiëren",
        "YEAR-1": "Jaar -1",
        "internet_explorer_warning": "U bekijkt iCRM in Internet Explorer. Sommige iCRM-functies werken mogelijk niet zoals verwacht in Internet Explorer. Overweeg over te schakelen naar een andere browser zoals Google Chrome, Microsoft Edge of Mozilla Firefox.",
        "turnover_same_period": "Omzet in dezelfde maanden als dit jaar",
        "PAGE_STATS": "Paginas statistieken",
        "USER_STATS": "Gebruikers statistieken",
        "VISITS_PER_USER": "Bezoeken per gebruiker",
        
        "user_application_form": "Gebruiker aanvraagformulier",

        "task_already_concluded": "Deze taak is al voltooid. Heropen deze taak om verder te gaan met bewerken",
        "days_since_first_order": "Aantal dagen sinds eerste bestelling",
        "days_since_code_created": "Aantal dagen sinds aanmaken code",
        "amount_webshop_visits": "Aantal webshop bezoeken",
        "latest_webshop_visit": "Laatste webshop bezoek",
        "no_filter": "Geen filter",
        "radio_filter_tooltip": "Filter de gekozen indicatie op kleur",
        "no_indication_selected_warning": "Selecteer een indicatie om het kleurenfilter te gebruiken",
        "indication": "Indicatie",

        "latest_import": "Recentste import",
        "latest_invoice": "Recentste factuur",

        "fetching_live_customer_data": "Live klantgegevens ophalen",

        // BI Info
        "reasons": "Redenen",
        "ander_klantnummer": "Ander klantnummer",
        "geen_klachten": "Geen klachten",
        "kwaliteit": "Kwaliteit",
        "prijs": "Prijs",
        "rustig_in_de_winkel": "Rustig in de winkel",
        "service": "Service",
        "transport": "Transport",
        "quality": "Kwaliteit",
        "webshop": "Webshop",
        "webshop_te_moeilijk": "Webshop te moeilijk",
        "winkel_permanent_gesloten": "Winkel permanent gesloten",
        "winkel_tijdelijk_gesloten": "Winkel tijdelijk gesloten",
        "geen_reden": "Geen reden",
        "anders,_namelijk...": "Anders, namelijk...",
        "namely": "Namelijk...",
        "closed_until": "Gesloten tot",

        "notes": "Aanvullingen",
        "checked_more_actions_than_allowed": "Het is niet toegestaan om meer dan 3 opties tegelijk te kiezen",
        "number_of_days_customer": "Aantal dagen klant",
        "number_of_days_customer_explanation": "Het aantal dagen sinds de klant zijn eerste bestelling heeft gedaan (vanaf 01-01-2017)",
        "total_deliveries": "Totaal aantal leveringen",
        "total_deliveries_explanation": "Het totaal aantal leveringen aan deze klant",
        "days_since_last_order": "Aantal dagen sinds laatste bestelling",
        "days_since_last_order_explanation": "Hoeveel dagen er zijn verstreken sinds deze klant voor het laatst heeft besteld",
        "weeks_regular_customer": "Aantal weken vaste klant",
        "weeks_regular_customer_explanation": "Hoeveel weken op rij we deze klant als vaste klant beschouwen",
        "weeks_without_orders": "Aantal weken zonder bestelling laatste 365 dagen",
        "weeks_without_orders_explanation": "Dit laat zien of het voor een klant vaak voor komt dat hij een week of meerdere weken niet bij ons besteld",
        "max_days_without_order": "Maximaal aantal dagen zonder bestelling in de laatste 365 dagen",
        "max_days_without_order_explanation": "Dit geeft aan of er in de laatste 365 een lange periode is geweest waarin de klant niet bij ons besteld heeft",

        "total": "Totaal",
        "last_4_weeks": "Laatste 4 weken",
        "last_52_weeks": "Laatste 52 weken",
        "differences_alltime_last10weeks_explanation": "Hier laten we de verschillen tussen het algemene bestel gedrag van de klant, en zijn bestel gedrag in de laatste 10 weken zien",
        "days_between_orders": "Aantal dagen tussen bestellingen",
        "days_between_orders_explanation": "Als dit hoger ligt in de laatste 10 weken, bestelt de klant minder regelmatig dan normaal",
        "avg_invoice_amount": "Gemiddelde hoogte van de factuur",
        "avg_invoice_amount_explanation": "Als dit lager ligt  in laatste 10 weken bestelt de klant voor minder dan wat hij normaal doet",
        "avg_articlegroups_per_order": "Gemiddelde aantal artikelgroepen per bestelling",
        "avg_articlegroups_per_order_explanation": "Als dit lager ligt  in laatste 10 weken bestelt de klant gemiddeld minder artikelgroepen dan wat hij normaal doet",
        "amount_of_credit_lines": "Aantal creditregels",
        "amount_of_credit_lines_explanation": "Dit geeft aan hoe veel creditregels de klant in de laatste 10 weken heeft ingediend",
        "credit_sum": "De creditsom",
        "credit_sum_explanation": "Dit geeft de totale som aan credits voor de totale periode en de laatste 10 weken weer",
        

        "relative_changes_in_last_10_weeks": "Relatieve veranderingen in de laatste 10 weken",
        "relative_changes_in_last_10_weeks_explanation": "Hier vergelijken we de laatste 10 weken waarbij we de eerste helft (10-5 weken gelden) met de tweede helft (5 weken geleden tot nu) vergelijken",
        "relative_number_of_days_customer": "Aantal bestellingen",
        "relative_number_of_days_customer_explanation": "Een daling geeft aan dat het aantal bestellingen gedaald is in de laatste 5 weken t.o.v. de 5 weken daarvoor",
        "relative_total_deliveries": "Gemiddelde hoogte van de factuur ",
        "relative_total_deliveries_explanation": "Bij een positief percentage is de gemiddelde factuurhoogte in de laatste 5 weken hoger dan in de 5 weken daarvoor",
        "relative_weeks_regular_customer": "Gemiddelde aantal artikelgroepen per bestelling",
        "relative_weeks_regular_customer_explanation": "Bij een positief percentage is het aantal artikelgroepen per bestelling in de laatste 5 weken hoger dan in de 5 weken daarvoor",
        "UNKONWN": "onbekend",

        "hide": "Verberg",

		// Monitor translations
        "own_stats_button": "Neem een kijkje in jouw iCRM gebruik.",
        "VISITED_LAST_MONTH": "Bezocht afgelopen maand {{amountOfPageVisits}} pagina's",
        "VISITED_LAST_MONTH_OWN_STATS": "Je hebt de afgelopen maand {{amountOfPageVisits}} pagina's bezocht",
        "TITLECRMUSE": "Verkrijg inzicht in het gebruik van het CRM",
        "SESSION_PER_DEVICE": "Sessies per device",
        "WHEN_ARE_USERS_ACTIVE": "Wanneer zijn gebruikers actief, en wat kijken de gebruikers voornamelijk",
        "WHEN_ARE_USERS_ACTIVE_OWN_STATS": "Wanneer ben je actief, en welke pagina's bekijk je voornamelijk?",
        "AVERAGE_VISITS_PER_HOUR": "Gemiddeld aantal bezoeken naar uur",
        "LIST_OF_SEARCHED_CUSTOMERS": "De gebruiker heeft deze klanten opgezocht",
        "LIST_OF_SEARCHED_CUSTOMERS_OWN_STATS": "Je hebt onlangs deze klanten opgezocht",
        "LIST_OF_CLICKED_CUSTOMERS": "De gebruiker heeft op deze klanten geklikt",
        "LIST_OF_CLICKED_CUSTOMERS_OWN_STATS": "je hebt onlangs op deze klanten geklikt",
        "AMOUNT_VISITS": "Aantal bezoeken",
        "AVERAGE_TIME_PAGE": "Gemiddelde tijd op pagina",
        "NO_SEARCHED_FOUND": "Geen zoektermen gevonden",
        // Comparison blocks
        "tasks_concluded_user": "meer taken afgerond dan gemiddeld",
        "tasks_concluded_user_negative": "minder taken afgerond dan gemiddeld",
        "tasks_concluded_avg": "taken afgerond",
        "task_concluded_speed_user": "sneller taken afgehandeld dan gemiddeld",
        "task_concluded_speed_user_negative": "minder snel taken afgehandeld dan gemiddeld",
        "task_concluded_speed_avg": "dagen",
        "notes_left_user": "meer klantnotities geplaatst dan gemiddeld",
        "notes_left_user_negative": "minder klantnotities geplaatst dan gemiddeld",
        "notes_left_avg": "klantnotities geplaatst",
        "leads_exported_user": "meer leads geëxporteerd dan gemiddeld",
        "leads_exported_user_negative": "minder leads geëxporteerd dan gemiddeld",
        "leads_exported_avg": "leads geëxporteerd",
        "leads_created_user": "meer leads gecreëerd dan gemiddeld",
        "leads_created_user_negative": "minder leads gecreëerd dan gemiddeld",
        "leads_created_avg": "leads gecreëerd",
        "pages_visited_user": "meer pagina's bezocht dan gemiddeld",
        "pages_visited_user_negative": "minder pagina's bezocht dan gemiddeld",
        "pages_visited_avg": "bezochte pagina's",
        "leads_validated_speed_user": "sneller leads afgehandeld dan gemiddeld",
        "leads_validated_speed_user_negative": "minder snel leads afgehandeld dan gemiddeld",
        "leads_validated_speed_avg": "dagen",
        "average": "Gemiddeld",
        "avg_comparison_title": "Vergelijk gebruikers met gemiddeld CRM-gebruik",
        "avg_comparison_title_own_stats": "Hoe gebruik jij het iCRM in vergelijking met andere gebruikers?",

		// Block 1
        "PAGE_VISITED": "Pagina's bezocht",
        "OPENED_CUSTOMERS": "Klanten open gezet",
        "CLOSED_CUSTOMERS": "Klanten dicht gezet",
        "GENERATED_INCASSO": "Incasso's gegenereerd",
        "EMAILED_OPENPOST": "Open posten gemaild",
        "OPENED_DEPARTURE_DAYS": "Vertrekdagen open gezet",
        "SEARCHED_CUSTOMERS": "Klanten gezocht",
        "AVG_TIME_PER_PAGE": "Gem. tijd per pagina",
		// Block 2
        "TASKS_DONE": "Afgeronde taken",
        "OPEN_TASKS": "Openstaande taken",
        "TASK_CREATION_COMPLETION": "Dagen tussen taak creatie en afhandeling",
        "NOTES_WRITTEN": "Klantnotities achtergelaten",
        "SENT_SPECIAL_OFFERS": "Promo's verstuurd",
        "NEWLEADS_CREATED": "New leads gecreeërd",
        "NEWLEADS_VALIDATED": "New Leads gevalideerd",
        "NEWLEADS_EXPORTED": "New Leads geëxporteerd",
        "DAYS_BET_NEWLEADS_CREATED_EXPORTED": "Dagen tussen lead creatie en FS Export",
        "USER_ACTIVITY_PER_HOUR": "Activiteit per uur",
		
        // Minor BI Data translations
        "last_year": "Afgelopen jaar",
        "last_month": "Afgelopen maand",        
        "last_year_last_month_explanation": "Hier laten we de verschillen tussen het algemene bestel gedrag van de klant, en zijn bestel gedrag in de afgelopen maand zien",
        "amount_of_orders": "Aantal bestellingen",
        "avg_invoice_turnover": "Gemiddelde factuurhoogte",
        "avg_amount_of_article_groups_per_order": "Gemiddeld aantal artikelgroepen per bestelling",
        "credit_ratio": "Credit ratio",
        "credit_ratio_explanation": "Het credit ratio is het percentage van de uitgaven dat door de klant gecrediteerd is",
        "avg_orders_per_week": "Gemiddeld aantal bestellingen per week",
        "risk_of_leaving": "Risico van vertrekken",
        "customer_since": "Klant sinds",

        "invoice_amount": "Factuurhoogte",
        "amount_article_groups": "Aantal artikelgroepen",
        "amount_articles": "Aantal artikelen",
        "amount_stems": "Aantal stelen",
        "invoice_paid": "Factuur betaald",

        "open_expired_tasks": "Let op! er zijn verlopen taken die nog niet zijn afgehandeld.",
        "go_to_tasks": "Terug naar taken",

        "good": "Goed",
        "mediocre": "Middelmatig",
        "bad": "Slecht",
        "existing_customer": "Bestaande klant",

        // Specifiek
        "CREATED_TASK_ERROR": 'Er is iets misgegaan. Klopt het klantnummer wel?',
        "NOINTERACTIONYET": "Deze taak heeft nog geen interacties gehad",
        "OVERDUE": "Te laat",
        "ACTIONSTODOBI": "Acties",
        "ACTIONSTODOBI_TOOLTIP": "Laat dit veld leeg als er geen vervolgactie nodig is",
        "ACTIONSTODOBI_TABLE_TOOLTIP": "Vervolgacties",
        "CALLOVERVIEWBI": "Samenvatting van telefoongesprek met de klant",
        "BIINFORMATIONS": "Verkoopgegevens uit BI",
        "TIMELINE": "Tijdlijn",
        "CHOOSE": "Kies",
        "FILLIN": "Vul een",
        "TITLE": "Titel",
        "TASKUPDATEDSUCCESS": "De taak is succesvol opgeslagen",
        "TASKCONCLUDEDSUCCESS": "De taak is succesvol afgerond",
        "TASKREOPENEDSUCCESS": "De taak is succesvol heropend",
        "TASKCREATEDSUCCESS": "De taak is succesvol aangemaakt. Je wordt doorgestuurd naar het takenoverzicht",
        "TASK": "Taak",
        "TASKS": "Taken",
        "CREATENEWTASK": "Maak een nieuwe taak",
        "TYPE": "Type",
        "NO_TYPE": "Geen type",
        "REPONDREEMAIL": "Reageer per e-mail",
        "CALLBACK": "Terug bellen",
        "URGENCY": "Urgent",
        "OBJECTIVE": "Doelstelling",
        "OPTIONS": "Opties",
        "MOREOPTIONS": "Meer opties",
        "ACTIONS_CHECKLIST": "Acties checklist",
        "ADD_ACTION": "Voeg actie toe",
        "FILL_NEW_ACTIONS": "Vul een nieuwe actie in",

        "financial_groups_distribution_title": "Omzetverdeling doelgroep",
        "financial_groups_distribution_hbxdeco_title": "Commerciële inspanning verdeeld over doelgroepen",
        "margin_distribution_title": "Verdeling van marges over doelgroepen",

        "ADD": "Voeg toe",
        "NEW": "Nieuw",
        "USER": "Gebruikers",
        "EMAIL": "Email",
        "NOTIFICATIONS": "Meldingen",
        "NAME": "Naam",
        "ORDERS": "Bestellingen",
        "HAVEORDERED": "Hebben besteld",
        "VISITE": "Bezoeken",
        "LOADMORE": "Meer weergeven",
        "DAYS": "Dagen",
        "CREATED": "Aangemaakt",
        "EXPORTED": "Geëxporteerd",
        "CREATE": "Maak",
        "CUSTOMERS": "Klanten",
        "ADDRESS": "Adres",
        "CITY": "Plaats",
        "ZIPCODE": "Postcode",
        "COUNTRY": "Land",
        "SHOP": "Winkel",
        "OWNER": "Eigenaar",
        "PHONE": "Telefoon",
        "MOBILE": "Mobiele",
        "VAT": "BTW",
        "DOESTHECUSTOMERHAVEAVATNUMBER": "Heeft de klant een BTW nummer ?",
        "KVK": "KvK",
        "TRANSPORTER": "Transporteur",
        "PASSWORD": "Wachtwoord",
        "LOGTIME": "Verbinding",
        "KOPEN": "Laatste order",
        "DIFFERENCE": "Verschil",
        "REFRESH": "Vernieuwen",
        "OLD": "oud / oud",
        "WEEK": "Week",
        "BLOCK1": "Nieuwe klanten aangemaakt laatste 7 dagen",
        "BLOCK2": "Laatste faktuur is van -5 to -7 dagen",
        "BLOCK3": "Laatste faktuur is van -10 to -14 dagen",
        "BLOCK4": "Laatste faktuur is van -20 and -30 dagen",
        "BLOCK5": "Klanten die niet aan het minimum voldoen",
        "BLOCK6": "Klanten die maar 1 faktuur hebben in de geschiedenis",
        "TOOLTIP": "Dit blok laat alle klanten zien die ",
        "NEWNLOSTCUSTOMERS": "Aantal nieuwe en verloren klanten",
        "ACTIVECUSTOMERS": "Aantal actieve klanten",
        "ACTIVECUSTOMERSTOOLTIP": "We tellen alle verschillende klanten",
        "QTYORDERS": "Aantal orders",
        "QTYORDERSTOOLTIP": "We tellen alle positieve facturen",
        "EXECUTE": "Uitvoeren",
        "LASTDATE": "Uitgevoerd",
        "DESCRIPTION": "Omschrijving",
        "AMOUNT": "Bedrag",
        "CUMULATED": "Gecumuleerd",
        "VALIDATE": "Valideer",
        "VALIDATED": "Gevalideerd",
        "PRELEVEMENT": "Incasso",
        "INVOICED": "Gefactureerd",
        "CREDIT": "Krediet",
        "DATE": "Datum",
        "LOADCAMPAIGNS": "Laad campagnes",
        "DEPARTURE": "Vertrek",
        "TODAY": "Vandaag",
        "TURNOVERPERCATEGORIES": "Omzetverdeling",
        "WEEKLYORDERFREQUENCY": "Wekelijkse Bestelfrequentie",
        "WEEKLYTURNOVER": "Wekelijkse omzet",
        "STARTDATE": "Begindatum",
        "ENDDATE": "Einddatum",
        "COUNTINVOICES": "Hoeveelheid facturen",
        "OPENSTAAND": "Aantal weken openstaand",
        "AVGORDERGROOTTE": "Gemiddelde ordergrootte",
        "DYNAMICSEARCHTEXT": "Bij dynamische zoekvelden kun je operators als > (groter dan), <(kleiner dan) gebruiken. Dynamische zoekvelden herken je a.d.h.v. de blauwe rand om het veld heen.",
        "DYNAMICSEARCH": "Dit input veld is dynamisch",
        "LAST90DAYS": "Afgelopen 90 dagen",
        "LAST60DAYS": "Afgelopen 60 dagen",
        "LAST30DAYS": "Afgelopen 30 dagen",
        "YESTERDAY": "Gisteren",
        "THISWK": "Deze week",
        "THISMT": "Deze maand",
        "THISYR": "Dit jaar",
        "LASTWK": "Vorige week",
        "LASTMT": "Vorige maand",
        "LASTYR": "Vorig jaar",
        "DEFAULTDT": "Standaard datum",
        "WKPERORD": "Weken per order",
        "APPLY": "Toepassen",
        "CHANGEPASS": "Wachtwoord veranderen",
        "MONDAY": "M",
        "TUESDAY": "D",
        "WEDNESDAY": "W",
        "THURSDAY": "D",
        "FRIDAY": "V",
        "INVOICES": "Facturen",
        "CALL": "Telefoon",
        "CALLLIST": "Bel Lijst",
        "INBOX": "Postvak-in",
        "VISIT": "Bezoek",
        "DELIVERY": "Levering",
        "FILLING": "Volmaken",
        "ORDERFREQ": "Order frequentie",
        "STELEN": "Stelen",
        "PLANTS": "Planten",
        "FLOWERS": "Bloemen",
        "OTHERS": "Overig",
        "AMNTCLIENTACTIVE": "Klanten actief",
        "WEBLOG": "(Aantal bezochte blz per dag)",
        "BOUGHT": "Gekocht",
        "STOCK": "Voorraad",
        "ORDERLIST": "Bestel lijst",
        "WEBLOG30DAYS": "Aantal pagina's bezocht de afgelopen 30 dagen",
        "TURNOVER": "Omzet",
        "PREVIOUSDEPARTUREDAYS": "Verwachte vertrekdagen",
        "INVOICEHISTORY": "Factuurhistorie",
        "INVOICE": "Factuur",
        "PAID": "Betaald",
        "DEPARTUREHISTORY": "Vertrek historie",
        "MAXDAY": "Blokkade termijn",
        "LASTINVOICE": "Recentste factuur",
        "CLOSED": "Blokkade verschil",
        "CUSTOMERSTOVALIDATE": "Te valideren klanten",
        "SEND": "Verzenden",
        "SAVE": "Opslaan",
        "PREVIEW": "Preview",
        "DOWNLOAD": "Downloaden",
        "CANCEL": "Annuleren",
        "NEXT": "Volgende",
        "DELETE": "Verwijderen",
        "LOGIN": "Inloggen",
        "PASSWORDFORGOTTEN": "Wachtwoord vergeten",
        "EDIT": "Bewerk",
        "RETURN": "Terug",
        // Navbar
        "HOME": "Home",
        "CUSTOMER": "Klant",
        "USERS": "Gebruiker",
        "RAPPORT": "Notities",
        "NEWCUSTOMERS": "Nieuwe klanten",
        "OVERVIEW": "Overzichten",
        "UNPAID": "Open posten",
        "HEADER": "Navigation",
        "DASHBOARD": "Dashboard",
        "CALENDAR": "Kalender",
        "USERACCESS": "Gebruiker toegang",
        "UNPAIDINVOICES": "Open posten per factuur",
        "SPECIALOFFER": "Speciale aanbiedingen",
        "SPECIALOFFERS": "Speciale aanbiedingen",
        "MANAGE": "Beheer",
        "VISITORS": "Bezoekers",
        "VISITORSBYDAY": "Bezoekers per dag over de afgelopen maand",
        "USERNAME": "Gebruikersnaam",
        "MESSAGE": "Bericht",
        "ADDMESSAGE": "Laat een bericht achter",
        "COMPANY": "Bedrijf",
        "SETTINGS": "Instellingen",
        "PAYTERM": "Betaaltermijn",
        "FINANCIELTYPE": "Financieel type",
        "COMMERCIALTYPE": "Commercieel type",
        "DOWNLOADBACKORDERS": "Download nabestellingen",
        "show_backorders": "Toon backorder overzicht",
        "BUYLIMIT": "Koop limit",
        "CREDITLIMIT": "Kredit limit",
        "OPEN": "Openen",
        "CLOSE": "Blokkeren",
        "NOCOMMENTS": "Geen comments beschikbaar",
        "SEARCH": "Zoeken",
        "GLOBALSEARCH": "Alles doorzoeken...",
        "ASSIGNNEWLEADS": "Assign new leads",
        "NEWLEAD": "Nieuwe lead",
        "PROFILE": "Profiel",
        "CHOOSELANGUAGE": "Kies je taal",
        "CHANGEPASSWORD": "Wijzig uw wachtwoord",
        "RESETFORM": "Formulier resetten",
        "ASSIGNED": "Assigned",
        "ADDTEMPLATESTOYOURLIST": "Voeg templates toe aan je favorietenlijst",
        "NEWLEADARCHIVEINFO": "Bij het archiveren van een nieuwe lead wordt deze niet geïmporteerd in Florisoft",
        "DELETEDNEWLEADSUCCESS": "De nieuwe lead is succesvol verwijderd",
        "ALREADYEXPORTERNEWLEAD": "Deze lead is al geëxporteerd",
        "NEWCUSTOMERSDIALOGINFO": "Om deze klant te valideren, moeten alle bovenstaande velden worden ingevuld in Florisoft",
        "WELCOME_DISCOUNT": "Welkom korting",
        "MULTIPLEEMAILS": "Meerdere e-mails gevonden voor deze klant",
        "MULTIPLEVAT": "Meerdere BTW nummers gevonden voor deze klant",
        "NEWLEADALREADYVALIDATED": "Deze lead is al gevalideerd",
        "NEWLEADVIESFIRSTCREATENEWLEAD": "Controleer eerst de VIES-informatie om te zien of deze overeenkomt met de klantgegevens",
        "WEBSHOPACCESS": "Webshop toegang",
        "UPDATEDSUCCESS": "Succesvol bijgewerkt",
        "UPDATEDFAILED": "Er is een fout opgetreden, neem contact met ons op",
        "CREATEDSUCCESS": "Succesvol aangemaakt",
        "YOURFAVTEMPLATES": "Je favoriete templates",
        "LEADS": "Leads",
        "ACCOUNTMNGR": "Account Manager",
        "NOTE": "Notitie",
        "AREYOUSURE": "weet je zeker dat ?",
        "BY": "voor",
        "YES": "ja",
        "NO": "nee",
        "CHECK": "Controleren",
        "SHOPNAMEFORINVOICE": "Winkelnaam op de factuur",
        "OPENROUTE": "Extra vertrekdag openzetten",
        "MOSTBOUGHTPRODUCTS": "Top 10 meest gekochte producten",
        "PRODUCT": "Product",
        "TIMESBOUGHT": "Keren gekocht",
        "TOTALSTEMS": "Aantal stelen",
        "AVGPRICE": "Gem. Prijs",
        "CREDITVERDELING": "Verdeling van credits over creditredenen",
        "SHOWLEGEND": "Toon legenda",
        "HIDELEGEND": "Sluit legenda",
        "WEBSHOP_LANGUAGE": "nl-nl",
        "MULTIPLEDATALEAD": "Dubbele e-mail",
        "MULTIPLEVATDATALEAD": "Dubbele btw",        
        "creation_date": "Creatiedatum",
        "CON_SUCCESS": "Verbinding succesvol",
        "CON_UNSUCCESS": "Verbinding mislukt !",
        "LOGIN_HISTORY": "Verbindingsgeschiedenis",
        "REGION": "Regio",
        "FILTER": "Filter",
        "FILTER_UNSUCCESSSFUL": "Uitfilteren mislukt",
        "TOO_MANY_WRONG_PASSWORDS_ACCOUNT_LOCKED": "Te veel verkeerde wachtwoorden, account is 5 minuten geblokkeerd",
        "LOGIN_ATTEMPTS": "Inlogpogingen",
        "VISITS": "Bezoekers",
        "DEBFINGRP" : "Klant type"
    }
};
export default nl;