import './styles/index.css';
import 'typeface-muli';
import './react-table-defaults';
import './react-chartjs-2-defaults';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './app/App';
import './i18n/i18n';
import Store from './app/store/index';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

const app = (
    <Provider store={Store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
)

const render = () => {
    ReactDOM.render(
        app,
        document.getElementById('root')
    );
};

window.onload = () => {
    render();
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
