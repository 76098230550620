import {updateObject} from '../../utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    allBlocks: [],
    block1: {data: [], loading: true},
    block2: {data: [], loading: true},
    block3: {data: [], loading: true},
    block4: {data: [], loading: true},
    block6: {data: [], loading: true},
}

// Overview //
const fetchAllCallListBlocksSuccess = ( state, action ) => {
    return updateObject( state, {allBlocks: action.allBlocks} );
};

const fetchCallListBlocks1Success = ( state, action ) => {
    return updateObject( state, {block1: {data: action.block1, loading: false}} );
};
const fetchCallListBlocks2Success = ( state, action ) => {
    return updateObject( state, {block2: {data: action.block2, loading: false}} );
};
const fetchCallListBlocks3Success = ( state, action ) => {
    return updateObject( state, {block3: {data: action.block3, loading: false}} );
};
const fetchCallListBlocks4Success = ( state, action ) => {
    return updateObject( state, {block4: {data: action.block4, loading: false}} );
};
const fetchCallListBlocks6Success = ( state, action ) => {
    return updateObject( state, {block6: {data: action.block6, loading: false}} );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.FETCH_CALL_LIST_BLOCKS_SUCCESS: return fetchAllCallListBlocksSuccess( state, action );

        case actionTypes.FETCH_CALL_LIST_BLOCK1_SUCCESS: return fetchCallListBlocks1Success( state, action );
        case actionTypes.FETCH_CALL_LIST_BLOCK2_SUCCESS: return fetchCallListBlocks2Success( state, action );
        case actionTypes.FETCH_CALL_LIST_BLOCK3_SUCCESS: return fetchCallListBlocks3Success( state, action );
        case actionTypes.FETCH_CALL_LIST_BLOCK4_SUCCESS: return fetchCallListBlocks4Success( state, action );
        case actionTypes.FETCH_CALL_LIST_BLOCK6_SUCCESS: return fetchCallListBlocks6Success( state, action );

        default: return state;
    }
};

export default reducer;