import React from 'react';
import {renderRoutes} from 'react-router-config';
import AppContext from '../../AppContext';

const AuthLayout = () => {
    return (
        <AppContext.Consumer>
            {({routes}) => (
                renderRoutes(routes)
            )}
        </AppContext.Consumer>
    );
};

export default AuthLayout;