import React, { Component } from 'react';
import {FusePageSimple, FuseAnimateGroup} from '../../../../../@fuse';
import { Icon, Typography, Tooltip, Card, CardContent, Button, FormControl, InputLabel, OutlinedInput, MenuItem, Checkbox, AppBar, Toolbar, FormControlLabel, IconButton } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import TaskDetailTextField from './TaskDetailComponents/TaskDetailTextField';
import TaskDetailSelect from './TaskDetailComponents/TaskDetailSelect';
import TaskDetailHeader from './TaskDetailComponents/TaskDetailHeader';
import TaskDetailDatePicker from './TaskDetailComponents/TaskDetailDatePicker';
import 'date-fns';
import { getAzureId } from '../../../Auth/Auth';
import { connect } from 'react-redux';
import * as actions from '../../../../store/task/actions';
import TaskDetailAutoComplete from './TaskDetailComponents/TaskDetailAutoComplete';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import { historyBrowser } from '../../../../../historyexport';

class TaskDetailCreate extends Component {

    state = {
        azureId: '',
        title: '',
        description: '',
        debnr: '',
        selectedCustomer: null,
        accountManager: '',
        selectedAccountManager: null,
        createdBy: getAzureId(),
        startDate: new Date(),
        endDate: moment(new Date()).add(7, 'days').toDate(),
        taskType: 'geen_type',
        urgency: false,
        realContext: ``,
        taskResultSummary: '',
        taskFollowUpSummary: '',
        comment: '',
        actions: [
            // {
            //     taskId: 0,
            //     name: 'prijsgerelateerd',
            //     title: 'Prijsgerelateerd, vraag je coordinator om...',
            //     checked: false,
            //     createdDate: new Date(),
            //     checkedDate: new Date(),
            //     checkedAzureId: getAzureId(),
            // },
            // {
            //     taskId: 0,
            //     name: 'Reclame',
            //     title: 'Stuur reclame naar de klant...',
            //     checked: false,
            //     createdDate: new Date(),
            //     checkedDate: new Date(),
            //     checkedAzureId: getAzureId(),
            // },
        ],
        azureIdFromUpdater: getAzureId(),
        addingAction: false,
        addingActionInput: '',
        triedToSubmit: false
        
    }

    componentDidMount() {
        this.props.onFetchAccountManagers();
    }

    componentWillUnmount() {
        this.props.onResetCreateTask();
    }
    
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleDateChange = (date, name) => {
        this.setState({ [name]: date })
    }

    handleCheckboxChange = event => {
        this.setState({ [event.target.name]: event.target.checked });
    }

    handleActionsCheckboxChange = event => {
        const actions = [...this.state.actions];
        const actionIndex = actions.findIndex(o => o.name === event.target.name);
        if(actionIndex > -1) {
            const action = {...actions[actionIndex], checked: event.target.checked, checkedDate: new Date(), checkedAzureId: getAzureId()}
            actions[actionIndex] = action;
            this.setState({ actions });
        }
    }
    
    handleAddAction = () => {
        const { addingActionInput } = this.state;
        const newAction = {
            taskId: 0,
            name: addingActionInput.replace( /\s/g, '_').toLowerCase(),
            title: addingActionInput,
            checked: false,
            createdDate: new Date(),
            checkedDate: new Date(),
            checkedAzureId: getAzureId()
        }
        const actions = [...this.state.actions, newAction];
        this.setState({actions, addingAction: false, addingActionInput: ''});
    }

    handleAddActionClick = () => {
        this.setState({ addingAction: true })
    }

    handleCancelAddActionClick = () => {
        this.setState({ addingAction: false, addingActionInput: '' })
    }

    handleCustomerAutoCompleteChange = (selectedCustomer) => {
        if(selectedCustomer) {
            this.setState({ 
                selectedCustomer, 
                debnr: selectedCustomer.debnr, 
                debnaam: selectedCustomer.name,
            });
        } else {
            this.setState({ 
                debnr: '',
                debnaam: '',
                selectedCustomer: null
            });
        }
    }

    handleAccountManagerAutoCompleteChange = (selectedAccountManager) => {
        if(selectedAccountManager) {
            this.setState({ 
                selectedAccountManager, 
                azureId: selectedAccountManager.azureId
            })
        } else {
            this.setState({ 
                azureId: '',
                accountManager: '',
                selectedAccountManager: null
            })
        }
    }

    handleCreateTask = () => {
        const task = this.state;
        this.setState({ triedToSubmit: true });

        const afterCreateTaskSuccess = () => {
            setTimeout(() => {
                historyBrowser.push('/tasks');
            }, 3000);
        }
        if (task.taskType !== 'BI Retention' && task.title.length !== 0 && task.description.length !== 0 && task.debnr.length !== 0 && task.realContext.length !== 0) {
            this.props.onCreateTask({id: 0, ...this.state, concluded: false, toBeFollowedUp: false}, afterCreateTaskSuccess);
        }
    }

    render() {
        const task = this.state;
        const { t } = this.props;

        return (
            <FusePageSimple
                    header={
                        <TaskDetailHeader title={t("CREATENEWTASK")} />
                    }
                    content={
                        <div className="w-full p-12">
                            <FuseAnimateGroup
                                className="flex flex-wrap justify-center"
                                enter={{
                                    animation: "transition.slideUpBigIn"
                                }}
                            > 
                                <div className="w-full sm:w-full md:w-2/3 lg:w-2/3 p-12">
                                    <Card className="w-full">
                                        <AppBar position="static">
                                            <Toolbar>
                                                <Typography>{t("CREATENEWTASK")}</Typography>
                                            </Toolbar>
                                        </AppBar>
                                        <CardContent>
                                            <div className="p-12">
                                                <div className="flex">
                                                    <div className="min-w-48 pt-20">
                                                        <Tooltip title={t("FILLIN") + " " + t("TITLE")}>
                                                            <Icon color="action">title</Icon>
                                                        </Tooltip>
                                                    </div>

                                                    <TaskDetailTextField
                                                        className="mb-24"
                                                        autoFocus
                                                        id="title"
                                                        label={t("TITLE")}
                                                        name="title"
                                                        value={this.state.title}
                                                        onChange={this.handleChange}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        error={task.triedToSubmit && task.title.length === 0}
                                                        helperText={task.triedToSubmit && task.title.length === 0 ? "Titel is verplicht" : ""}
                                                    />
                                                </div>

                                                <div className="flex">
                                                    <div className="min-w-48 pt-20">
                                                        <Tooltip title={t("FILLIN") + " " + t("DESCRIPTION")}>
                                                            <Icon color="action">notes</Icon>
                                                        </Tooltip>
                                                    </div>
                                                    <TaskDetailTextField
                                                        className="mb-24"
                                                        id="description"
                                                        label={t("DESCRIPTION")}
                                                        name="description"
                                                        value={this.state.description}
                                                        onChange={this.handleChange}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        multiline
                                                        maxRows={3}
                                                        error={task.triedToSubmit && task.description.length === 0}
                                                        helperText={task.triedToSubmit && task.description.length === 0 ? "Beschrijving is verplicht" : ""}
                                                    />
                                                </div>
                                                <div className="w-full flex flex-wrap">

                                                    <div className="w-full mb-12 flex xs:w-full sm:w-1/2 md:w-full lg:w-1/2">
                                                        <div className="min-w-48 pt-20">
                                                        <Tooltip title={t("FILLIN") + " " + t("CUSTOMER")}>
                                                                <Icon color="action">contacts</Icon>
                                                            </Tooltip>
                                                        </div>
                                                        {/* <TaskDetailTextField
                                                            className="mb-24"
                                                            id="debnr"
                                                            label={t("CUSTOMER")}
                                                            name="debnr"
                                                            value={this.state.debnr}
                                                            onChange={this.handleChange}
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                            error={task.triedToSubmit && task.debnr.length === 0}
                                                            helperText={task.triedToSubmit && task.debnr.length === 0 ? "Klantnummer is verplicht" : ""}
                                                        /> */}
                                                        <TaskDetailAutoComplete
                                                            options={this.props.searchableCustomers}
                                                            value={this.state.selectedCustomer}
                                                            onChange={(event, value) => this.handleCustomerAutoCompleteChange(value)}
                                                            fullWidth
                                                            error={task.triedToSubmit && task.debnr.length === 0}
                                                            helperText={task.triedToSubmit && task.debnr.length === 0 ? "Klantnummer is verplicht" : ""}
                                                            placeholder={t('SEARCH') + ' ' + t('CUSTOMERS')}
                                                            label={t('CUSTOMER')}
                                                            // optionLabel="debnr"
                                                            getOptionLabel={option => `${option.name} (${option.debnr})`}
                                                        />
                                                    </div>
                                                    <div className="w-full flex xs:w-full sm:w-1/2 md:w-full lg:w-1/2">
                                                        <div className="min-w-48 pt-20">
                                                        <Tooltip title={t("FILLIN") + " " + t("ACCOUNTMNGR")}>
                                                                <Icon color="action">supervisor_account</Icon>
                                                            </Tooltip>
                                                        </div>

                                                        <TaskDetailAutoComplete
                                                            label={t("ACCOUNTMNGR")}
                                                            options={this.props.accountManagers}
                                                            value={this.state.selectedAccountManager}
                                                            onChange={(event, value) => this.handleAccountManagerAutoCompleteChange(value)}
                                                            fullWidth
                                                            error={task.triedToSubmit && task.azureId.length === 0}
                                                            helperText={task.triedToSubmit && task.azureId.length === 0 ? "Account manager is verplicht" : ""}
                                                            getOptionLabel={option => option.name}
                                                            // placeholder={t('SEARCH') + ' ' + t('ACCOUNTMNGR')}
                                                            // optionLabel="name"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="w-full flex flex-wrap">
                                                    <div className="w-full flex xs:w-full sm:w-1/2 md:w-full lg:w-1/2">
                                                        <div className="min-w-48 pt-32">
                                                            {/* <Tooltip title="Kies een datum waarop de taak van start gaat"> */}
                                                                <Icon color="action">date_range</Icon>
                                                            {/* </Tooltip> */}
                                                        </div>
                                                        <TaskDetailDatePicker
                                                            fullWidth
                                                            inputVariant="outlined"
                                                            disableToolbar
                                                            variant="inline"
                                                            format="yyyy-MM-dd"
                                                            margin="normal"
                                                            label={t("STARTDATE")}
                                                            name="startDate"
                                                            value={this.state.startDate}
                                                            onChange={date => this.handleDateChange(date, 'startDate')}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            minDate={new Date()}
                                                        />
                                                    </div>
                                                    <div className="w-full flex xs:w-full sm:w-1/2 md:w-full lg:w-1/2">
                                                        <div className="min-w-48 pt-20">
                                                            {/* <Tooltip title="Kies een datum waarop de taak verloopt"> */}
                                                                <Icon color="action">event</Icon>
                                                            {/* </Tooltip> */}
                                                        </div>
                                                        <TaskDetailDatePicker
                                                            fullWidth
                                                            inputVariant="outlined"
                                                            disableToolbar
                                                            variant="inline"
                                                            format="yyyy-MM-dd"
                                                            margin="normal"
                                                            label={t("ENDDATE")}
                                                            name="endDate"
                                                            value={this.state.endDate}
                                                            onChange={date => this.handleDateChange(date, 'endDate')}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            minDate={this.state.startDate}
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div className="flex">
                                                    <div className="flex w-5/6">
                                                        <div className="min-w-48 pt-20">
                                                        <Tooltip title={t("CHOOSE") + ' ' + t("TYPE")}>
                                                                <Icon color="action">art_track</Icon>
                                                            </Tooltip>
                                                        </div>
                                                        <FormControl variant="outlined" className="mb-24 mt-24" fullWidth>
                                                            <InputLabel htmlFor="type-select-label">{t("TYPE")}</InputLabel>
                                                            <TaskDetailSelect
                                                                value={this.state.taskType}
                                                                onChange={this.handleChange}
                                                                input={
                                                                    <OutlinedInput
                                                                        id="type-select-label"
                                                                        labelWidth={36}
                                                                        name="taskType"
                                                                    />
                                                                }
                                                                required
                                                                fullWidth
                                                            >
                                                                    <MenuItem value="geen_type">{t("NO_TYPE")}</MenuItem>
                                                                    <MenuItem value="belafspraak">{t("CALLBACK")}</MenuItem>
                                                                    <MenuItem value="terugmailen">{t("REPONDREEMAIL")}</MenuItem>
                                                            </TaskDetailSelect>
                                                        </FormControl>
                                                    </div>
                                                    <div className="mt-16 ml-12">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={this.state.urgency}
                                                                    onChange={this.handleCheckboxChange}
                                                                    name="urgency"
                                                                    color="primary"
                                                                />
                                                            }
                                                            style={{ fontSize: 8 }}
                                                            label={t("URGENCY")}
                                                            labelPlacement="top"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="flex">
                                                    <div className="min-w-48 pt-20">
                                                        <Tooltip title={t("FILLIN") + " " + t("OBJECTIVE")}>
                                                            <Icon color="action">notes</Icon>
                                                        </Tooltip>
                                                    </div>
                                                    <TaskDetailTextField
                                                        className="mb-24"
                                                        id="realContext"
                                                        label={t("OBJECTIVE")}
                                                        name="realContext"
                                                        value={this.state.realContext}
                                                        onChange={this.handleChange}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        multiline
                                                        maxRows={6}
                                                        error={task.triedToSubmit && task.realContext.length === 0}
                                                        helperText={task.triedToSubmit && task.realContext.length === 0 ? "Doel is verplicht" : ""}
                                                    />
                                                </div>

                                                <div className="pl-48">
                                                    <div>
                                                        <Typography className="text-16 font-light">
                                                            {t("ACTIONS_CHECKLIST")}
                                                        </Typography>
                                                    </div>
                                                    {
                                                        this.state.actions.map(action => (
                                                            <div key={action.name}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={action.checked}
                                                                            onChange={this.handleActionsCheckboxChange}
                                                                            name={action.name}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    style={{ fontSize: 8 }}
                                                                    label={action.title}
                                                                />
                                                            </div>
                                                        ))
                                                    }
                                                    {
                                                        this.state.addingAction ? 
                                                        <>
                                                            <div className="flex pt-12">
                                                                <TaskDetailTextField
                                                                    // className="mb-24"
                                                                    id="addingActionInput"
                                                                    label={t("FILL_NEW_ACTIONS")}
                                                                    name="addingActionInput"
                                                                    value={this.state.addingActionInput}
                                                                    onChange={this.handleChange}
                                                                    variant="outlined"
                                                                    required
                                                                    fullWidth
                                                                    multiline
                                                                    maxRows={2}
                                                                />
                                                            </div>
                                                            <Button onClick={this.handleAddAction} variant="contained" color="primary">{t("ADD_ACTION")}</Button>
                                                            <IconButton onClick={this.handleCancelAddActionClick}>
                                                                <Icon>close</Icon>
                                                            </IconButton>
                                                        </>
                                                            : 
                                                        <div className="flex">
                                                            <Tooltip title={t("ADD_ACTION")}>
                                                                <IconButton variant="contained" color="primary" onClick={this.handleAddActionClick}>
                                                                    <Icon>add_task</Icon>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="flex">
                                                    <div className="min-w-48 pt-20">
                                                        <Tooltip title={t("ADDMESSAGE")}>
                                                            <Icon color="action">insert_comment</Icon>
                                                        </Tooltip>
                                                    </div>
                                                    <TaskDetailTextField
                                                        className="mb-12"
                                                        id="comment"
                                                        label={t("MESSAGE")}
                                                        name="comment"
                                                        value={this.state.comment}
                                                        onChange={this.handleChange}
                                                        variant="outlined"
                                                        fullWidth
                                                        multiline
                                                        maxRows={4}
                                                    />
                                                </div>
                                                
                                                {
                                                    this.props.createdTask ? 
                                                        <div className="flex justify-center">
                                                                <Alert className="mb-12 mt-12" severity="success">{t("TASKCREATEDSUCCESS")}</Alert>
                                                        </div>
                                                    : null
                                                }
                                                {
                                                    this.props.error ? 
                                                    
                                                    <div className="flex justify-center">
                                                        <Alert 
                                                            className="mb-12 w-full" 
                                                            severity="error" >{t('CREATED_TASK_ERROR')}</Alert>
                                                    </div>
                                                            : null
                                                }

                                                <div className="w-full flex flex-wrap">
                                                    <div className="flex w-full p-12 justify-center">
                                                        <Button className="flex w-2/3 xs:w-full" startIcon={<Icon>save</Icon>} disabled={this.props.createdTask} variant="contained" color="primary" onClick={this.handleCreateTask}>
                                                            {t("SAVE")}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                                {/* <div className="w-full sm:w-full md:w-1/2 lg:w-1/2 p-12">
                                    <TaskDetailTable />
                                </div> */}
                            </FuseAnimateGroup>
                        </div>
                    }
                />
        );
    }
};

const mapStateToProps = ({ task, customer }) => ({
    accountManagers: task.accountManagers,
    searchableCustomers: customer.allSearchableCustomers,
    createdTask: task.createdTask,
    error: task.error
});

const mapDispatchToProps = (dispatch) => ({
    onFetchAccountManagers: () => dispatch(actions.fetchAccountManagers()),
    onCreateTask: (task, afterCreateTaskSuccess) => dispatch(actions.createTask(task, afterCreateTaskSuccess)),
    onResetCreateTask: () => dispatch(actions.resetCreateTask())
});



export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TaskDetailCreate));