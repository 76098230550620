import React from 'react';
import {useTranslation} from 'react-i18next';
import { Card, Typography } from '@material-ui/core';
import { LinkToDebnrWithChildren } from '../../../../../components/Functions/RouteToDebnr';

const ClickableRow = ({row}) => {
    return (
        <tr>
                <td><LinkToDebnrWithChildren debnr={row.debnr}>{row.debnr}</LinkToDebnrWithChildren></td>
                <td><LinkToDebnrWithChildren debnr={row.debnr}>{row.name}</LinkToDebnrWithChildren></td>
                <td width='100px' className="text-right"><LinkToDebnrWithChildren debnr={row.debnr}>&euro;{row.turnover.toLocaleString('nl-NL', { maximumFractionDigits: 0 })}</LinkToDebnrWithChildren></td>
        </tr>
    )
}

const DashboardTables = props => {
    const {t} = useTranslation();
    const tables = props.tables;
    const loaded = Object.keys(tables).length > 0;
    

    const firstTable = loaded && (
        <div key="firstTable" className="sm:w-full md:w-1/2 lg:w-1/3 p-12">
            <Card className="rounded-8 shadow-none border-1">
                {/* <Table data={tables.turnoverTable} title={`Top 10 ${t("TURNOVER")}`} /> */}
                <table className={"simple clickable"}>
                    <thead>
                        <tr>
                            <th colSpan={3} height={65}>
                                <div className="flex items-center justify-between">
                                    <Typography className="text-16">Top 10 {t("TURNOVER") ?? 'hoi'}</Typography>
                                    
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {tables.turnoverTable.map((row, index) => (
                        <ClickableRow key={index} row={row} />
                        // <tr key={index}>
                        //     <td><LinkToDebnr debnr={row.debnr} /></td>
                        //     <td>{row.name}</td>
                        //     <td>{row.turnover.toLocaleString('nl-NL')}</td>
                        // </tr>
                    ))}
                    </tbody>
                </table>
            </Card>
        </div>
    );

    const secondTable = (
        loaded &&
        <div key="secondTable" className="sm:w-full md:w-1/2 lg:w-1/3 p-12">
            <Card className="rounded-8 shadow-none border-1">
                {/* <Table data={tables.ordersizeTable} title={`Top 10 ${t("AVGORDERGROOTTE")}`} /> */}
                <table className={"simple clickable"}>
                    <thead>
                        <tr>
                            <th colSpan={3} style={{textAlign: 'center'}} height={65}>Top 10 {t("AVGORDERGROOTTE")}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {tables.ordersizeTable.map((row, index) => (
                        <tr key={index}>
                            <td><LinkToDebnrWithChildren debnr={row.debnr}>{row.debnr}</LinkToDebnrWithChildren></td>
                            <td><LinkToDebnrWithChildren debnr={row.debnr}>{row.name}</LinkToDebnrWithChildren></td>
                            <td width='100px' className="text-right">&euro;<LinkToDebnrWithChildren debnr={row.debnr}>{row.orderSize.toLocaleString('nl-NL')}</LinkToDebnrWithChildren></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Card>
        </div>
    )

    const thirdTable = (
        loaded &&
        <div key="thirdTable" className="sm:w-full md:w-1/2 lg:w-1/3 p-12">
            <Card className="rounded-8 shadow-none border-1">
                {/* <Table data={tables.unpaidTable} title={`Top 10 ${t("UNPAID")}`} /> */}
                <table className={"simple clickable"}>
                    <thead>
                        <tr>
                            <th colSpan={3} style={{textAlign: 'center'}} height={65}>Top 10 {t("UNPAID")}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {tables.unpaidTable.map((row, index) => (
                        <tr key={index}>                                        
                            <td><LinkToDebnrWithChildren debnr={row.debnr}>{row.debnr}</LinkToDebnrWithChildren></td>
                            <td><LinkToDebnrWithChildren debnr={row.debnr}>{row.name}</LinkToDebnrWithChildren></td>
                            <td width='100px' className="text-right">&euro;<LinkToDebnrWithChildren debnr={row.debnr}>{row.unpaid.toLocaleString('nl-NL', { maximumFractionDigits: 0 })}</LinkToDebnrWithChildren></td>
                            {/* <td><LinkToDebnr debnr={row.debnr} /></td> */}
                            {/* <td>{row.name}</td>
                            <td>{row.unpaid.toLocaleString('nl-NL')}</td> */}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Card>
        </div>
    );

    return (
        <div className="flex flex-wrap">
            {
                loaded &&
                [firstTable, secondTable, thirdTable]
            }
        </div>
    )
};
export default DashboardTables;