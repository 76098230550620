import React, {Component} from 'react';
import { connect }from 'react-redux';
import { withStyles, Button, Typography, InputAdornment, Icon, TextField, Paper, IconButton } from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {darken} from '@material-ui/core/styles/colorManipulator';
import * as actions from '../../../store/auth/actions';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import loginClasses from '../Login/FuseLogin.module.css';
import Responsive from 'react-responsive';
import {FuseAnimate} from '../../../../@fuse';
import LanguageFlags from '../Login/LanguageFlags';

const styles = theme => ({
    root: {
        background: 'radial-gradient(' + darken(theme.palette.primary.dark, 0.5) + ' 0%, ' + theme.palette.primary.dark + ' 80%)',
        color     : theme.palette.primary.contrastText
    }
});

class SimpleUpdatePassword extends Component {

    state = {
        password: '',
        confirmPassword: '',
        showPassword   : false,
        passwordValid: true,
        confirmPasswordValid: true,
    };

    onSubmit = () => {
        if(this.state.password.length > 7) {
            const afterUpdateSuccess = () => {
                this.props.history.push('/iCRM/Inbox')
            }
            const user = {
                resetPasswordToken: this.props.match.params.token,
                password: this.state.password,
            };
            this.props.onUpdatePassword(user, afterUpdateSuccess);
        }
    };

    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword});
    }

    handleFormChange = event => {
        const name = event.target.name;
        if(name === 'password') {
            const password = event.target.value;
            const { confirmPassword } = this.state;
            this.handleFormValidation(password, confirmPassword);
        } else if (name === 'confirmPassword') {
            const { password } = this.state;
            const confirmPassword = event.target.value;
            this.handleFormValidation(password, confirmPassword);
        }
    }

    handleFormValidation = (password, confirmPassword) => {
        if(password && confirmPassword && password.length > 7 && password === confirmPassword) {
            this.setState({ passwordValid: true, confirmPasswordValid: true, password, confirmPassword });
        } else if (password && password.length > 7) {
            this.setState({ passwordValid: true, confirmPasswordValid: false, password, confirmPassword });
        } else if (confirmPassword && confirmPassword.length > 7) {
            this.setState({ passwordValid: false, confirmPasswordValid: true, password, confirmPassword });
        } else {
            this.setState({ passwordValid: false, confirmPasswordValid: false, password, confirmPassword });
        }
    } 

    handleOpenLanguageMenu = (event) => {
        this.setState({ languageMenuAnchorEl: event.currentTarget })
    }

    handleLanguageChange = (lng) => {
        i18next.changeLanguage(lng);
        this.handleCloseLanguageMenu();
    }

    handleCloseLanguageMenu = () => {
        this.setState({ languageMenuAnchorEl: null })
    }

    render()
    {
        const { t } = this.props;
        const { password, passwordValid, confirmPassword, confirmPasswordValid } = this.state;

        const InputProps = {
            inputProps: {
                autoComplete: 'new-password',
                form: {
                    autoComplete: "off"
                }
            },
            endAdornment: (
                <InputAdornment position="end">
                      <IconButton 
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          tabIndex="-1"
                      >
                      {this.state.showPassword ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
                      </IconButton>
                </InputAdornment>
            )
        }

        return (
            
            <div className={loginClasses.Container} >
                <Paper className={loginClasses.Paper} elevation={3}>
                    <Responsive minHeight={600}>
                        <FuseAnimate animation="transition.expandIn">
                            <img className="w-128 mb-32" src="assets/images/logos/iCRM-blue.svg" alt="logo"/>
                        </FuseAnimate>
                    </Responsive>
                    <Typography className="mb-12 text-center">{t('update_your_password').toUpperCase()}</Typography>
                    {
                        this.props.resetted && !this.props.resetPasswordError ? 
                        <Alert className="mb-16" severity="success">{t('password_reset_successful')}.</Alert>
                        : null
                    }
                    {
                        this.props.resetted && this.props.resetPasswordError ? 
                        <Alert className="mb-16" severity="error" >{t('something_went_wrong')} {t('please_try_again')} {t('or')} <a href="mailto:icrm@floraltradegroup.com">{t('contact_us')}</a></Alert>
                        : null
                    }
                    <React.Fragment>
                        <TextField
                            className="mb-16 w-full"
                            type={this.state.showPassword ? "text" : "password"}
                            name="password"
                            value={password}
                            onChange={this.handleFormChange}
                            label={t('password')}
                            InputProps={InputProps}
                            variant="outlined"
                            required
                            error={!passwordValid}
                            helperText={!passwordValid ? `${t('min_charachter_length')} 8` : ''}
                        />
                        <TextField
                            className="mb-16 w-full"
                            type={this.state.showPassword ? "text" : "password"}
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={this.handleFormChange}
                            label={t('confirm_password')}
                            InputProps={InputProps}
                            variant="outlined"
                            required
                            error={!confirmPasswordValid}
                            helperText={!confirmPasswordValid ? `${t('min_charachter_length')} 8` : ''}
                        />
        
                        {this.props.error ? <Typography variant="body2" color="error">{t('wrong_credentials')}</Typography> : null}
        
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="w-5/6 m-12 normal-case"
                            aria-label="UPDATE_PASSWORD"
                            disabled={!passwordValid || !confirmPasswordValid}
                            onClick={this.onSubmit}
                        >
                            {t('update_your_password').toUpperCase()}
                        </Button>
                    </React.Fragment>
                    <LanguageFlags handleLanguageChange={this.handleLanguageChange} />
                </Paper>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    onUpdatePassword: (user, afterUpdateSuccess) => dispatch(actions.updatePassword(user, afterUpdateSuccess)),
})

export default connect(null, mapDispatchToProps)(withStyles(styles, {withTheme: true})(withTranslation()(SimpleUpdatePassword)));
