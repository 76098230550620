import React, { Component } from 'react';
import { FusePageSimple, FuseAnimateGroup, FuseAnimate } from '../../../../../@fuse';
import { Icon, Typography, Tooltip, Card, CardContent, Button, FormControl, InputLabel, OutlinedInput, MenuItem, Checkbox, AppBar, Toolbar, FormControlLabel, InputAdornment, IconButton, StepConnector } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import TaskDetailTextField from './TaskDetailComponents/TaskDetailTextField';
import TaskDetailSelect from './TaskDetailComponents/TaskDetailSelect';
import TaskDetailHeader from './TaskDetailComponents/TaskDetailHeader';
import TaskDetailDatePicker from './TaskDetailComponents/TaskDetailDatePicker';
import TaskDetailTableMinor from './TaskDetailComponents/TaskDetailTableMinor';
import { connect } from 'react-redux';
import * as actions from '../../../../store/task/actions';
import * as customerActions from '../../../../store/crm/actions';
import { getAzureId, checkRole } from '../../../Auth/Auth';
import 'date-fns';
import { Alert, AlertTitle } from '@material-ui/lab';
import TaskDetailTimeLine from './TaskDetailComponents/TaskDetailTimeLine';
import TaskDetailAutoComplete from './TaskDetailComponents/TaskDetailAutoComplete';
import { Link } from 'react-router-dom';
import TaskDetailFormSkeleton from './TaskDetailComponents/TaskDetailFormSkeleton';
import TaskDetailTimeLineSkeleton from './TaskDetailComponents/TaskDetailTimeLineSkeleton';
import _ from 'lodash';
import styled from 'styled-components';
import TaskDetailWeblog from './TaskDetailComponents/TaskDetailWeblog';
import FirstOrderTaskForm from './TaskDetailComponents/BI/FirstOrderTaskForm';
import FirstOrderInformation from './TaskDetailComponents/BI/FirstOrderInformation';
import TaskDetailRetentionData from './TaskDetailComponents/TaskDetailRetentionData';

const FlexCol = styled.div`
    width: 50%;
    @media(max-width: 767px) {
        width: 100%;
    }
`

class TaskDetail extends Component {

    state = {
        title: '',
        description: '',
        debnr: '',
        azureId: '',
        accountManager: '',
        selectedAccountManager: null,
        startDate: new Date(),
        endDate: new Date(),
        taskType: 'BI Retention',
        urgency: false,
        realContext: ``,
        taskResultSummary: '',
        taskFollowUpSummary: '',
        comment: '',
        actions: [],
        concluded: false,
        azureIdFromUpdater: getAzureId(),
        addingAction: false,
        addingActionInput: '',
        insertedTaskData: false,
        triedToSubmit: false,
        checkingMoreActionsThanAllowed: false,
        biId: null,
        closedUntil: null,
        assignedDate: null,
        changedAccountManager: false,
        weerBesteld: false,
        lastUpdaterAzureId: null,
        selectedOrderTaskRadio: "",
        startRiskOfLeaving: 0,
    }

    componentDidMount() {
        const isOrderTask = this.props.location.pathname.includes('ordertask');
        this.props.onFetchTask(this.props.match.params.id, isOrderTask);
        this.props.onFetchAccountManagers();
        if (!isOrderTask) {
            this.props.onFetchTimeLine(this.props.match.params.id);
        }
    }

    componentDidUpdate() {
        if (!this.state.insertedTaskData && this.props.currentTask && this.props.currentTask.id === Number(this.props.match.params.id)
            && this.props.accountManagers && this.props.accountManagers.length > 0) {
            const accountManager = this.props.accountManagers.find(a => a.azureId === this.props.currentTask.azureId);
            this.setState({
                ...this.props.currentTask,
                insertedTaskData: true,
                actions: this.props.currentTask.actions.map(action => ({
                    ...action,
                    name: action.title.replace(/\s/g, '_').toLowerCase(),
                })),
                selectedAccountManager: accountManager ? accountManager : null,
                azureIdFromUpdater: getAzureId(),
            });
            this.props.onFetchWeblogVisits(this.props.currentTask.debnr);
        }
    }

    componentWillUnmount() {
        this.props.onResetConcludeTask();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleDateChange = (date, name) => {
        this.setState({ [name]: date })
    }

    handleCheckboxChange = event => {
        this.setState({ [event.target.name]: event.target.checked });
    }

    handleActionsCheckboxChange = event => {
        const actions = [...this.state.actions];
        if (this.state.taskType === 'BI Retention' && event.target.checked && actions.filter(action => action.checked).length === 3) {
            this.setState({ checkingMoreActionsThanAllowed: true });
            return;
        }
        const actionIndex = actions.findIndex(o => o.id.toString() === event.target.name);
        if (actionIndex > -1) {
            const action = { ...actions[actionIndex], checked: event.target.checked, checkedDate: new Date(), checkedAzureId: getAzureId() }
            actions[actionIndex] = action;
            this.setState({ actions, checkingMoreActionsThanAllowed: false });
        }
    }

    handleAddAction = () => {
        const { id, addingActionInput, actions: oldActions } = this.state;
        const oldAction = oldActions[oldActions.length - 1];
        const newId = oldAction?.id ? oldAction.id + 11 : 0;
        const newAction = {
            id: newId,
            taskId: id,
            name: addingActionInput.replace(/\s/g, '_').toLowerCase(),
            title: addingActionInput,
            checked: false,
            createdDate: new Date(),
            checkedDate: new Date(),
            checkedAzureId: '',
        }
        const actions = [...this.state.actions, newAction];
        this.setState({ actions, addingAction: false, addingActionInput: '' });
    }

    handleDeleteAction = (actionName) => {
        const actions = [...this.state.actions];
        const actionIndex = actions.findIndex(action => action.name === actionName);
        if (actionIndex > -1) {
            actions.splice(actionIndex, 1);
        }
        this.setState({ actions });
    }

    handleAddActionClick = () => {
        this.setState({ addingAction: true })
    }

    handleCancelAddActionClick = () => {
        this.setState({ addingAction: false, addingActionInput: '' })
    }

    handleAutoCompleteChange = (selectedAccountManager) => {
        if (selectedAccountManager) {
            this.setState({
                selectedAccountManager,
                azureId: selectedAccountManager.azureId,
                changedAccountManager: true,
                assignedDate: new Date()
            })
        } else {
            this.setState({
                azureId: '',
                accountManager: '',
                selectedAccountManager: null
            })
        }
    }

    handleSaveTask = () => {
        const task = this.state;
        this.setState({ triedToSubmit: true, checkingMoreActionsThanAllowed: false });
        if (task.taskType === 'BI Retention'
            && task.title.length !== 0
            && task.debnr.length !== 0
            && task.startDate && task.endDate && task.taskType.length !== 0
            && (task.changedAccountManager || task.actions.some(t => t.checked))
            && (!task.actions.find(t => t.name === 'anders,_namelijk...').checked || task.realContext.length !== 0)
            && (!task.actions.find(t => t.name === 'winkel_tijdelijk_gesloten') || !task.actions.find(t => t.name === 'winkel_tijdelijk_gesloten').checked || task.closedUntil)
            && task.azureIdFromUpdater.length !== 0 && task.azureId.length !== 0) {
            this.props.onSaveTask({ ...this.state });
            this.setState({ triedToSubmit: false, changedAccountManager: false });
        }
        else if (task.taskType !== 'BI Retention' && task.title.length !== 0 && task.description.length !== 0
            && task.azureIdFromUpdater.length !== 0 && task.realContext.length !== 0 && task.azureId.length !== 0) {
            const afterSaveTask = (actions) => {
                this.setState({
                    changedAccountManager: false,
                    actions: actions.map(action => ({
                        ...action,
                        name: action.title.replace(/\s/g, '_').toLowerCase(),
                    }))
                });
            }
            this.props.onSaveTask({ ...this.state }, afterSaveTask);
        }
    }

    handleConcludeTask = () => {
        // const afterConcludeTaskSuccess = () => {
        //     this.setState({ [name]: date });
        // }
        this.setState({ concluded: !this.state.concluded }, () => {
            this.props.onConcludeTask({ ...this.state, concluded: this.state.concluded });
        });
    }

    handleAddComment = () => {
        const comment = { taskId: this.state.id, comment: this.state.comment, azureId: getAzureId() };
        this.props.onAddComment(comment, () => this.props.onFetchTimeLine(this.state.id));
        this.setState({ comment: '' });
    }

    handleSetTaskFalseAlarm = () => {
        this.setState({ concluded: !this.state.concluded, taskResultSummary: 'False Alarm', taskFollowUpSummary: 'False Alarm' }, () => {
            this.props.onSetTaskFalseAlarm({ id: this.state.id, azureIdFromUpdater: getAzureId(), taskType: this.state.taskType, biId: this.state.biId });
        });
    }

    render() {
        const isOrderTask = this.props.location.pathname.includes('ordertask');
        const { t } = this.props;
        const task = this.state;

        const LinkToTasks = <Link style={{ color: 'rgb(30, 70, 32)', textDecoration: 'underline' }} to={checkRole('iTasksManager') ? "/icrm/tasksoverview" : "/tasks"}>{t('go_to_tasks')}</Link>;

        const taskForm = isOrderTask ? <FirstOrderTaskForm
            task={task}
            handleChange={this.handleChange}
            handleAutoCompleteChange={this.handleAutoCompleteChange}
            selectedAccountManager={this.state.selectedAccountManager}
            accountManagers={this.props.accountManagers}
            selectedOrderTaskRadio={this.state.selectedOrderTaskRadio}
        /> : <React.Fragment>{
            task.concluded &&
            <div className="flex justify-center">
                <Alert className="mb-24 w-full" severity="success">{t('task_already_concluded')}</Alert>
            </div>
        }
            <div className="flex">
                <div className="min-w-48 pt-20">
                    <Tooltip title={t("FILLIN") + " " + t("TITLE")}>
                        <Icon color="action">title</Icon>
                    </Tooltip>
                </div>

                <TaskDetailTextField
                    className="mb-24"
                    autoFocus
                    id="title"
                    label={t("TITLE")}
                    name="title"
                    value={task.title}
                    onChange={this.handleChange}
                    variant="outlined"
                    required
                    fullWidth
                    disabled={task.concluded}
                    error={task.triedToSubmit && task.title.length === 0}
                    helperText={task.triedToSubmit && task.title.length === 0 ? t("TITLE") + ' ' + t('is_required') : ""}
                />
            </div>
            {task.taskType !== 'BI Retention' &&
                <div className="flex">
                    <div className="min-w-48 pt-20">
                        <Tooltip title={t("FILLIN") + " " + t("DESCRIPTION")}>
                            <Icon color="action">notes</Icon>
                        </Tooltip>
                    </div>
                    <TaskDetailTextField
                        className="mb-24"
                        id="description"
                        label={t("DESCRIPTION")}
                        name="description"
                        value={task.description}
                        onChange={this.handleChange}
                        variant="outlined"
                        required
                        fullWidth
                        multiline
                        maxRows={5}
                        disabled={task.concluded}
                        error={task.taskType !== 'BI Retention' && task.triedToSubmit && task.description.length === 0}
                        helperText={task.taskType !== 'BI Retention' && task.triedToSubmit && task.description.length === 0 ? t("DESCRIPTION") + ' ' + t('is_required') : ""}
                    />
                </div>
            }
            <div className="w-full flex flex-wrap">

                {/* <div className="flex w-full"> */}
                <div className="w-full flex xs:w-full sm:w-1/2 md:w-full lg:w-1/2">
                    <div className="min-w-48 pt-20">
                        <Tooltip title={t("FILLIN") + " " + t("CUSTOMER")}>
                            <Icon color="action">contacts</Icon>
                        </Tooltip>
                    </div>
                    <TaskDetailTextField
                        className="mb-12"
                        id="debnr"
                        label={t("CUSTOMER")}
                        name="debnr"
                        value={task.debnr}
                        onChange={this.handleChange}
                        variant="outlined"
                        required
                        fullWidth
                        disabled
                    />
                </div>
                {/* <div className="flex w-full"> */}
                <div className="w-full flex xs:w-full sm:w-1/2 md:w-full lg:w-1/2">
                    <div className="min-w-48 pt-20 lg:ml-16">
                        <Tooltip title={t("FILLIN") + " " + t("ACCOUNTMNGR")}>
                            <Icon color="action">supervisor_account</Icon>
                        </Tooltip>
                    </div>

                    <TaskDetailAutoComplete
                        options={this.props.accountManagers}
                        value={this.state.selectedAccountManager}
                        onChange={(event, value) => this.handleAutoCompleteChange(value)}
                        fullWidth
                        disabled={task.concluded}
                        error={task.triedToSubmit && (task.azureId.length === 0 || !this.state.selectedAccountManager)}
                        helperText={task.triedToSubmit && task.azureId.length === 0 ? t("ACCOUNTMNGR") + ' ' + t('is_required') : ""}
                        getOptionLabel={option => option.name}
                    />
                </div>
            </div>

            <div className="w-full flex flex-wrap">

                {/* <div className="flex w-full"> */}
                <div className="w-full flex xs:w-full sm:w-1/2 md:w-full lg:w-1/2">
                    <div className="min-w-48 pt-32">
                        {/* <Tooltip title="Kies een datum waarop de taak van start gaat"> */}
                        <Icon color="action">date_range</Icon>
                        {/* </Tooltip> */}
                    </div>
                    <TaskDetailDatePicker
                        fullWidth
                        inputVariant="outlined"
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        label={t("STARTDATE")}
                        name="startDate"
                        value={task.startDate}
                        onChange={date => this.handleDateChange(date, 'startDate')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        disabled={task.concluded}
                    />
                </div>
                {/* <div className="flex w-full"> */}
                <div className="w-full flex xs:w-full sm:w-1/2 md:w-full lg:w-1/2">
                    <div className="min-w-48 pt-32 lg:ml-16">
                        {/* <Tooltip title="Kies een datum waarop de taak verloopt"> */}
                        <Icon color="action">event</Icon>
                        {/* </Tooltip> */}
                    </div>
                    <TaskDetailDatePicker
                        fullWidth
                        inputVariant="outlined"
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        label={t("ENDDATE")}
                        name="endDate"
                        value={task.endDate}
                        onChange={date => this.handleDateChange(date, 'endDate')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        minDate={task.startDate}
                        disabled={task.concluded}
                    />
                </div>
            </div>

            {
                task.taskType !== 'BI Retention' ?
                    <React.Fragment>
                        <div className="flex">
                            <div className="flex w-5/6">
                                <div className="min-w-48 pt-20">
                                    <Tooltip title={t("CHOOSE") + ' ' + t("TYPE")}>
                                        <Icon color="action">art_track</Icon>
                                    </Tooltip>
                                </div>
                                <FormControl variant="outlined" className="mb-24 mt-24" fullWidth>
                                    <InputLabel htmlFor="type-select-label">{t('TYPE')}</InputLabel>
                                    <TaskDetailSelect
                                        value={task.taskType}
                                        onChange={this.handleChange}
                                        input={
                                            <OutlinedInput
                                                id="type-select-label"
                                                labelWidth={36}
                                                name="taskType"
                                            />
                                        }
                                        required
                                        fullWidth
                                        disabled={task.concluded}
                                    >
                                        <MenuItem value="geen_type">{t("NO_TYPE")}</MenuItem>
                                        <MenuItem value="belafspraak">{t("CALLBACK")}</MenuItem>
                                        <MenuItem value="terugmailen">{t("REPONDREEMAIL")}</MenuItem>
                                    </TaskDetailSelect>
                                </FormControl>
                            </div>
                            <div className="mt-16 ml-12">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={task.urgency}
                                            onChange={this.handleCheckboxChange}
                                            name="urgency"
                                            color="primary"
                                        />
                                    }
                                    style={{ fontSize: 8 }}
                                    label={t("URGENCY")}
                                    labelPlacement="top"
                                    disabled={task.concluded}
                                />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Tooltip title={t("FILLIN") + " " + t("OBJECTIVE")}>
                                    <Icon color="action">notes</Icon>
                                </Tooltip>
                            </div>
                            <TaskDetailTextField
                                className="mb-24"
                                id="realContext"
                                label={t("OBJECTIVE")}
                                name="realContext"
                                value={task.realContext}
                                onChange={this.handleChange}
                                variant="outlined"
                                required
                                fullWidth
                                multiline
                                maxRows={6}
                                error={task.triedToSubmit && task.realContext.length === 0}
                                helperText={task.triedToSubmit && task.realContext.length === 0 ? t("OBJECTIVE") + ' ' + t('is_required') : ""}
                                disabled={task.concluded}
                            />
                        </div>

                        <div className="pl-48">
                            <div>
                                <Typography className="text-16 font-light">
                                    {t("OPTIONS")}
                                </Typography>
                            </div>
                            {
                                task.actions.map(action => (
                                    <div key={action.name}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={action.checked}
                                                    onChange={this.handleActionsCheckboxChange}
                                                    name={action.id.toString()}
                                                    color="primary"
                                                />
                                            }
                                            style={{ fontSize: 8 }}
                                            label={action.title}
                                            disabled={task.concluded}
                                        />
                                        <IconButton className="float-right" onClick={() => this.handleDeleteAction(action.name)}>
                                            <Icon>cancel</Icon>
                                        </IconButton>
                                    </div>
                                ))
                            }
                            {
                                task.addingAction ?
                                    <>
                                        <div className="flex pt-12">
                                            <TaskDetailTextField
                                                // className="mb-24"
                                                id="addingActionInput"
                                                label={t("MOREOPTIONS")}
                                                name="addingActionInput"
                                                value={task.addingActionInput}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                required
                                                fullWidth
                                                multiline
                                                maxRows={2}
                                            />
                                        </div>
                                        <Button onClick={this.handleAddAction} color="primary">{t("ADD") + " " + t("OPTIONS")}</Button>
                                        <IconButton onClick={this.handleCancelAddActionClick}>
                                            <Icon>close</Icon>
                                        </IconButton>
                                    </>
                                    :
                                    <div className="flex justify-end">
                                        <Tooltip title={t("ADD") + " " + t("OPTIONS")}>
                                            <IconButton onClick={this.handleAddActionClick}>
                                                <Icon>add_task</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                            }
                        </div>

                        {
                            this.props.addedComment ?
                                <div className="flex justify-center">
                                    <Alert className="mb-12 w-full" severity="success" onClose={() => this.props.onResetAddComment()}>Your comment has been saved!</Alert>
                                </div>
                                : null
                        }

                        <div className="flex pt-12">
                            <div className="min-w-48 pt-20">
                                <Tooltip title={t("ADDMESSAGE")}>
                                    <Icon color="action">insert_comment</Icon>
                                </Tooltip>
                            </div>
                            <TaskDetailTextField
                                className="mb-24"
                                id="comment"
                                label={t("MESSAGE")}
                                name="comment"
                                value={task.comment}
                                onChange={this.handleChange}
                                variant="outlined"
                                required
                                fullWidth
                                multiline
                                minRows={2}
                                maxRows={4}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <Tooltip title={t('SEND')}>
                                            <IconButton disabled={!task.id} onClick={this.handleAddComment}>
                                                <Icon>send</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                }}
                                disabled={task.concluded}
                            />
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {
                            this.state.checkingMoreActionsThanAllowed &&
                            <Alert
                                className="mb-12 mt-12 w-full"
                                severity="warning">{t('checked_more_actions_than_allowed')}</Alert>
                        }

                        <div className="pl-48 w-full">
                            <div>
                                <Typography className="text-16 font-light">
                                    {this.state.taskType === 'BI Retention' ? t('reasons') : t("OPTIONS")}
                                </Typography>
                                {
                                    task.triedToSubmit && !task.changedAccountManager && !task.actions.some(t => t.checked) ?
                                        <Typography color="error">Pick at least 1 option</Typography> : null
                                }
                            </div>
                            {
                                _.chunk(task.actions, 2).map(chunk => {
                                    return <div key={chunk[0].id} className="flex w-full flex-col sm:flex-col md:flex-row lg:flex-row">
                                        {chunk.map(action => (
                                            <div key={action.id} className="w-full sm:w-full md:w-1/2 lg:w-1/2">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={action.checked}
                                                            onChange={this.handleActionsCheckboxChange}
                                                            name={action.id.toString()}
                                                            color="primary"
                                                        />
                                                    }
                                                    style={{ fontSize: 8 }}
                                                    label={t(action.name)}
                                                    disabled={task.concluded}
                                                />
                                            </div>
                                        ))
                                        }
                                    </div>
                                })
                            }
                            {
                                task.actions.some(a => a.title === 'Anders, namelijk...' && a.checked) &&
                                <FuseAnimate
                                    enter={{
                                        animation: "transition.expandIn"
                                    }} delay={100}>
                                    <TaskDetailTextField
                                        autoFocus={task.realContext === ''}
                                        className="mt-4"
                                        id="realContext"
                                        placeholder={t('namely')}
                                        name="realContext"
                                        value={task.realContext}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        multiline
                                        maxRows={6}
                                        error={task.triedToSubmit && task.realContext.length === 0}
                                        helperText={task.triedToSubmit && task.realContext.length === 0 ? t("reasons") + ' ' + t('is_required') : ""}
                                        disabled={task.concluded}
                                    />
                                </FuseAnimate>
                            }
                            {
                                task.actions.some(a => a.title === 'Winkel tijdelijk gesloten' && a.checked) &&
                                <FuseAnimate
                                    enter={{
                                        animation: "transition.expandIn"
                                    }} delay={100}>
                                    <TaskDetailDatePicker
                                        fullWidth
                                        inputVariant="outlined"
                                        disableToolbar
                                        variant="inline"
                                        format="yyyy-MM-dd"
                                        margin="normal"
                                        label={t("closed_until")}
                                        name="closedUntil"
                                        value={task.closedUntil}
                                        onChange={date => this.handleDateChange(date, 'closedUntil')}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        disabled={task.concluded}
                                    />
                                </FuseAnimate>
                            }
                        </div>
                        <div className="flex pt-12">
                            <div className="min-w-48 pt-20">
                                <Tooltip title={t("notes")}>
                                    <Icon color="action">assignment</Icon>
                                </Tooltip>
                            </div>
                            <TaskDetailTextField
                                className="mb-12 mt-12"
                                label={t("notes")}
                                name="notes"
                                value={task.notes}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                multiline
                                minRows={2}
                                maxRows={4}
                                disabled={task.concluded}
                            />
                        </div>

                    </React.Fragment>
            }

            {
                this.props.concludedTask ?
                    <div className="flex justify-center">
                        <Alert className="mb-12 w-full" severity="success"
                            action={LinkToTasks}
                        >
                            {this.state.concluded ? t("TASKCONCLUDEDSUCCESS") : t('TASKREOPENEDSUCCESS')}
                        </Alert>
                    </div>
                    : null
            }

            {
                this.props.updatedTask ?
                    <div className="flex justify-center">
                        <Alert className="mb-12 w-full" severity="success"
                            action={LinkToTasks}
                        >{t("TASKUPDATEDSUCCESS")}</Alert>
                    </div>
                    : null
            }

            {
                this.props.error ?
                    <Alert
                        className="mb-12 w-full"
                        severity="error"
                        action={
                            <a style={{ textDecoration: 'underline', color: 'rgb(97, 26, 21)' }} href="mailto:icrm@floraltradegroup.com">
                                notify us.
                            </a>
                        }>{this.props.error}</Alert>
                    : null
            }

            <div className="w-full flex flex-wrap">
                <div className="w-full xs:w-full sm:w-1/3 md:w-full lg:w-1/3 p-12 justify-start">
                    {
                        !task.concluded ?
                            <Tooltip title={t('conclude_explanation')}>
                                <Button
                                    disableElevation
                                    className="w-full"
                                    style={{ color: '#ffffff', backgroundColor: 'rgb(76, 175, 80)' }}
                                    variant="contained"
                                    startIcon={<Icon style={{ color: '#ffffff' }}>check_circle_outline</Icon>}
                                    onClick={this.handleConcludeTask}>
                                    {t("conclude")}
                                </Button>
                            </Tooltip>
                            :
                            <Button startIcon={<Icon>restore</Icon>} variant="outlined" color="secondary" onClick={this.handleConcludeTask}>{t('reopen')}</Button>

                    }
                </div>
                <div className="w-full xs:w-full sm:w-1/3 md:w-full lg:w-1/3 p-12 justify-end">
                    {
                        !task.concluded ?
                            <Button
                                className="w-full"
                                variant="contained"
                                disableElevation
                                color="primary"
                                startIcon={<Icon>save</Icon>}
                                onClick={this.handleSaveTask}>
                                {t("SAVE")}
                            </Button>
                            : null
                    }
                </div>
            </div>
        </React.Fragment>

        let additionalTaskInformation = <TaskDetailTimeLineSkeleton />;
        if(task.taskType === 'BI Retention') {
            additionalTaskInformation = <TaskDetailRetentionData info={this.props.currentTask?.biDataMinor} />;
        } else if (task.taskType === 'BI First Order') {
            additionalTaskInformation = <FirstOrderInformation task={task} />;
        } else {
            additionalTaskInformation = <TaskDetailTimeLine timeLine={this.props.timeLine} />;
        }

        return (
            <FusePageSimple
                header={
                    <TaskDetailHeader title={task.title} debnaam={task.debnaam} phoneNumber={task.phoneNumber} />
                }
                content={
                    <div className="w-full p-12">
                        {(task.weerBesteld && !task.lastUpdaterAzureId) && <div className="w-full p-12">
                            <Alert severity="warning" variant="filled">
                                <AlertTitle>Let op!</AlertTitle>
                                Deze klant heeft na het genereren van de taak alweer besteld, waardoor de taak automatisch is afgehandeld. Het is niet nodig de taak opnieuw te openen.
                            </Alert>
                        </div>}
                        <FuseAnimateGroup
                            className="flex flex-wrap"
                            enter={{
                                animation: "transition.slideUpBigIn"
                            }}
                        >
                            {this.props.fetchingTaskError ? <Alert severity="error">{this.props.fetchingTaskError}</Alert> : null}
                            <FlexCol className="w-full sm:w-full md:w-1/2 lg:w-1/2 p-12">
                                <div className="w-full">
                                    {/* SHOW SKELETON UNTIL DATA IS LOADED */}
                                    {task.insertedTaskData ?
                                        <>
                                            <Card className="w-full rounded-8 shadow-none border-1">
                                                <AppBar position="static" elevation={0}>
                                                    <Toolbar>
                                                        <Typography>{t("EDIT") + " " + t("TASK")}</Typography>
                                                    </Toolbar>
                                                </AppBar>
                                                <CardContent>
                                                    <div className="p-12">
                                                        {taskForm}
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </>
                                        :
                                        <TaskDetailFormSkeleton />
                                    }
                                    {/* END OF SHOW SKELETON UNTIL DATA IS LOADED */}
                                </div>
                            </FlexCol>
                            <FlexCol className="w-full sm:w-full md:w-1/2 lg:w-1/2 p-12">
                                {
                                    task.insertedTaskData ?
                                            <div className="w-full">
                                                {
                                                    this.props.currentTask && additionalTaskInformation
                                                }
                                            </div>
                                        :
                                        <div className="w-full">
                                            <TaskDetailTimeLineSkeleton />
                                        </div>
                                }
                                <div className="w-full mt-20">
                                    {
                                        this.props.currentTask && <TaskDetailWeblog weblogVisits={this.props.weblogVisits} />
                                    }
                                </div>
                            </FlexCol>
                        </FuseAnimateGroup>
                    </div>
                }
            />
        );
    }
};

const mapStateToProps = ({ task, customer }) => ({
    timeLine: task.timeLine,
    accountManagers: task.accountManagers,
    currentTask: task.currentTask,
    updatedTask: task.updatedTask,
    concludedTask: task.concludedTask,
    addedComment: task.addedComment,
    error: task.error,
    fetchingTaskError: task.fetchingTaskError,
    weblogVisits: customer.weblogVisits,
});

const mapDispatchToProps = (dispatch) => ({
    onFetchAccountManagers: () => dispatch(actions.fetchAccountManagers()),
    onFetchTask: (id, isOrderTask) => dispatch(actions.fetchTask(id, isOrderTask)),
    onSaveTask: (task, afterSaveTask) => dispatch(actions.saveTask(task, afterSaveTask)),
    onConcludeTask: (task) => dispatch(actions.concludeTask(task)),
    onResetConcludeTask: () => dispatch(actions.resetConcludeTask()),
    onFetchTimeLine: (id) => dispatch(actions.fetchTaskTimeLine(id)),
    onAddComment: (comment, afterSendCommentSuccess) => dispatch(actions.addComment(comment, afterSendCommentSuccess)),
    onResetAddComment: () => dispatch(actions.resetAddComment()),
    onSetTaskFalseAlarm: (task) => dispatch(actions.setTaskFalseAlarm(task)),
    onFetchWeblogVisits: (debnr) => dispatch(customerActions.fetchCustomerWeblogVisits(debnr)),
});


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TaskDetail));