import jwt from 'jsonwebtoken';
import jwt_decode from 'jwt-decode';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
};

export const signGroup = (group) => {
    const token = jwt.sign(group, 'tGhfsd226');
    return token;
}

export const decodeToken = (group) => {
    return jwt_decode(group);
}