import PageStats from './PageStats/PageStats';
import UserLogsByPage from './PageStats/UserLogsByPage';
import UserStats from './UserStats/UserStats';
import UserStatsTable from './UserStats/UserStatsTable';

export const MonitorConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/stats/pages',
            component: PageStats,
            auth: ['iCRMManager'],
            exact: true,
        },
        {
            path     : '/stats/page',
            component: UserLogsByPage,
            auth: ['iCRMManager'],
            exact: true
        },
        {
            path     : '/stats/users',
            component: UserStatsTable,
            auth: ['iCRMManager'],
            exact: true
        },
        {
            path     : '/stats/user/:azureId',
            component: UserStats,
            auth: ['iCRMBasis', 'iCRMFinancieel'],
            exact: true
        }
    ]
}