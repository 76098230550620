import React, {Component} from 'react';
import { withRouter } from 'react-router-dom'
import {Button, Icon, ListItemIcon, ListItemText, Popover, MenuItem, Tooltip, Divider} from '@material-ui/core';
import {connect} from 'react-redux';
import { getAzureId } from '../../main/Auth/Auth';
import { withTranslation } from "react-i18next";
import styled from 'styled-components';

const StyledIcon = styled(Icon)`
    transition: transform .6s ease-in-out;
    transform: rotate(0deg);
    &:hover {
        transform: rotate(180deg);
    }
`

class SettingMenu extends Component {

    state = {
        userMenu: null
    };

    // componentDidMount() {
    //     this.props.fetchGroupCodes();
    // }

    userMenuClick = event => {
        this.setState({userMenu: event.currentTarget});
    };

    userMenuClose = () => {
        this.setState({userMenu: null});
    };

    handleClick = (url) => {
        this.userMenuClose();
        this.props.history.push(url);
    }

    render()
    {
        const {t} = this.props;
        const {userMenu} = this.state;

        return (
            <React.Fragment>
                <>
                <Tooltip title={t('SETTINGS')}>
                    <Button className="h-64" onClick={this.userMenuClick}>
                        <StyledIcon className="text-24 sm:flex md:flex lg:flex" variant="action" color="action">settings</StyledIcon>
                    </Button>
                </Tooltip>

                <Popover
                    open={Boolean(userMenu)}
                    anchorEl={userMenu}
                    onClose={this.userMenuClose}
                    anchorOrigin={{
                        vertical  : 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical  : 'top',
                        horizontal: 'center'
                    }}
                    classes={{
                        paper: "py-8"
                    }}
                >
                    <React.Fragment>
                        {/* <Tooltip title={t('own_stats_button')}> */}
                            <MenuItem key="signout" onClick={() => this.handleClick("/profile/loginhistory")}>
                                <ListItemIcon>
                                    <Icon>security</Icon>
                                </ListItemIcon>
                                <ListItemText className="pl-0" primary="Login history" />
                            </MenuItem>
                        {/* </Tooltip> */}
                        <Divider />
                        <Tooltip title={t('own_stats_button')}>
                            <MenuItem key="usage" onClick={() => this.handleClick("/stats/user/" + getAzureId())}>
                                <ListItemIcon>
                                    <Icon>account_circle</Icon>
                                </ListItemIcon>
                                <ListItemText className="pl-0" primary="iCRM Usage" />
                            </MenuItem>
                        </Tooltip>
                    </React.Fragment>
                </Popover>
                </>
            </React.Fragment>
        );
    }
}

// function mapDispatchToProps(dispatch)
// {
//     return {
//         // fetchGroupCodes: () => dispatch(groupCodeActions.fetchAccessableGroupcodes),
//         // updateCurrentGroupcode: (newGroupCode) => dispatch(groupCodeActions.updateCurrentGroupcode(newGroupCode)),
//         // updateReduxUserObject: (user) => dispatch(groupCodeActions.setUser(user)),
//     }
// }

// function mapStateToProps({flortalAuth})
// {
//     return {
//         groupcodes: flortalAuth.accessableGroupcodes,
//         user: flortalAuth.user,
//     }
// }

export default withRouter(connect()(withTranslation()(SettingMenu)));
