import React, {Component} from 'react';
import { withRouter } from 'react-router-dom'
import {Avatar, Button, Icon, ListItemIcon, ListItemText, Popover, MenuItem, Typography, Tooltip, Divider} from '@material-ui/core';
import {connect} from 'react-redux';
import * as groupCodeActions from '../../store/auth/actions';
import {getUsername} from '../../main/Auth/Auth';
import {signOut} from '../../main/Auth/Auth';
import _ from 'lodash';

class UserMenu extends Component {

    state = {
        userMenu: null
    };

    componentDidMount() {
        this.props.fetchGroupCodes();
    }

    userMenuClick = event => {
        this.setState({userMenu: event.currentTarget});
    };

    userMenuClose = () => {
        this.setState({userMenu: null});
    };

    changeCurrentGroup = (group) => {
        this.userMenuClose();
        this.props.updateCurrentGroupcode(group);
    }

    handleSignout = () => {
        signOut();
        this.props.updateReduxUserObject({});
        this.props.history.push('/login');
    }

    render()
    {
        const {groupcodes, user} = this.props;
        const {userMenu} = this.state;
        const username = getUsername();

        const splittedGroups = _.groupBy(groupcodes, 'companyName');

        return (
            <React.Fragment>
                {username !== '' ? 
                <>
                <Tooltip title="Switch to a different group or Log Out">
                    <Button className="h-64" onClick={this.userMenuClick}>
                        <Avatar style={{backgroundColor: '#ef6c00'}} className="">
                            {username[0]}
                        </Avatar>

                        <div className="hidden md:flex flex-col ml-12 items-start">
                            <Typography component="span" className="normal-case font-600 flex">
                                {username}
                            </Typography>
                            {/* <Typography className="text-11 capitalize" color="textSecondary">
                                {groupcode.userName}
                            </Typography> */}
                            <Typography className="text-11" color="textSecondary">
                                {user.debiterGroup.groupName}
                            </Typography>
                        </div>

                        <Icon className="text-16 ml-12 hidden sm:flex" variant="action">keyboard_arrow_down</Icon>
                    </Button>
                </Tooltip>

                <Popover
                    open={Boolean(userMenu)}
                    anchorEl={userMenu}
                    onClose={this.userMenuClose}
                    anchorOrigin={{
                        vertical  : 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical  : 'top',
                        horizontal: 'center'
                    }}
                    classes={{
                        paper: "py-8"
                    }}
                >
                    <React.Fragment>
                        {/* {groupcodes.length > 0 && groupcodes.map((group, i) => (
                            <MenuItem key={i} onClick={() => this.changeCurrentGroup(group)}>
                                <ListItemIcon>
                                    <Icon>group</Icon>
                                </ListItemIcon>
                                <ListItemText className="pl-0" primary={group.groupName} />
                            </MenuItem>
                        ))} */}

                        {Object.keys(splittedGroups).map((key, i) => (                            
                            <React.Fragment key={i}>
                            <MenuItem key={i} style={{backgroundColor: '#eee', cursor: 'default'}}>
                                <ListItemIcon>
                                    <Icon>business</Icon>
                                </ListItemIcon>
                                <ListItemText className="pl-0" primary={<strong>{key}</strong>} />
                            </MenuItem>
                            {
                            splittedGroups[key].map((group, i) => (
                                <MenuItem key={i} onClick={() => this.changeCurrentGroup(group)}>
                                    <ListItemIcon>
                                        <Icon>group</Icon>
                                    </ListItemIcon>
                                    <ListItemText className="pl-0" primary={group.groupName} />
                                </MenuItem>
                            ))
                            }
                            <Divider />
                            </React.Fragment>
                        ))}
                        <MenuItem key="signout" onClick={this.handleSignout}>
                            <ListItemIcon>
                                <Icon>cancel</Icon>
                            </ListItemIcon>
                            <ListItemText className="pl-0" primary="Logout" />
                        </MenuItem>
                    </React.Fragment>
                </Popover>
                </>
                : null }
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return {
        fetchGroupCodes: () => dispatch(groupCodeActions.fetchAccessableGroupcodes),
        updateCurrentGroupcode: (newGroupCode) => dispatch(groupCodeActions.updateCurrentGroupcode(newGroupCode)),
        updateReduxUserObject: (user) => dispatch(groupCodeActions.setUser(user)),
    }
}

function mapStateToProps({flortalAuth})
{
    return {
        groupcodes: flortalAuth.accessableGroupcodes,
        user: flortalAuth.user,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserMenu));
