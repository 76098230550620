import React from 'react';
import { Icon, Typography, Tooltip, Radio, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import TaskDetailTextField from '../TaskDetailTextField';
import TaskDetailAutoComplete from '../TaskDetailAutoComplete';
import TaskDetailDatePicker from '../TaskDetailDatePicker';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import Slider from '@material-ui/core/Slider';

const CharacteristicOptions = ({ handleChange, selectedOption, optionNote, characteristic, title, disabled, triedToSubmit }) => {
    const { t } = useTranslation();
    const radioName = characteristic + 'Option';
    const noteName = characteristic + 'Note';
    return <React.Fragment>
        <div className="flex pt-12 w-full">
            <div className=" pt-12" style={{ width: '30%' }}>
                <Typography variant='subtitle1'>{title}</Typography>
            </div>
            <div component="fieldset" className="flex w-full justify-between" style={{ margin: '0px 40px', width: '70%' }}>

                <Slider
                    name={radioName}
                    defaultValue={5}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={1}
                    max={10}
                    value={selectedOption}
                    onChange={(_, value) => handleChange(radioName, value)}
                />
                {/* <FormControlLabel name={radioName} onChange={handleChange} checked={selectedOption === "3"} value="3" control={<Radio disabled={disabled} />} />
                <FormControlLabel name={radioName} onChange={handleChange} checked={selectedOption === "2"} value="2" control={<Radio disabled={disabled} />} />
                <FormControlLabel name={radioName} onChange={handleChange} checked={selectedOption === "1"} value="1" control={<Radio disabled={disabled} />} /> */}
            </div>
        </div>
        {
            selectedOption < 5 && <div className="flex pt-12 w-full">
                <TaskDetailTextField
                    className="mb-12 mt-12"
                    label={t("reasons")}
                    name={noteName}
                    value={optionNote}
                    onChange={(event) => handleChange(event.target.name, event.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                    disabled={disabled}
                    error={triedToSubmit && optionNote.length === 0}
                    helperText={triedToSubmit && optionNote.length === 0 ? t("reasons") + ' ' + t('is_required') : ""}
                />
            </div>
        }
    </React.Fragment>
}


const FirstOrderTaskForm = ({ task, handleChange, handleDateChange, handleAutoCompleteChange, handleCheckboxChange, handleSliderChange,
    selectedAccountManager, accountManagers, handleSave, finishedTask, error, triedToSubmit }) => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <div className="flex">
                <div className="flex w-2/3">
                    <div className="min-w-48 pt-20">
                        <Tooltip title={t("FILLIN") + " " + t("TITLE")}>
                            <Icon color="action">title</Icon>
                        </Tooltip>
                    </div>

                    <TaskDetailTextField
                        className="mb-24"
                        id="title"
                        label={t("TITLE")}
                        name="title"
                        value={task.title}
                        onChange={handleChange}
                        variant="outlined"
                        disabled
                        fullWidth
                    />
                </div>
                <div className="flex w-1/3 justify-center">
                    <FormControlLabel
                        control={<Checkbox name="existingCustomer" checked={task.existingCustomer} onChange={handleCheckboxChange} />}
                        label={t('existing_customer')}
                    />
                </div>

            </div>
            <div className="w-full flex flex-wrap">

                {/* <div className="flex w-full"> */}
                <div className="w-full flex xs:w-full sm:w-1/2 md:w-full lg:w-1/2">
                    <div className="min-w-48 pt-20">
                        <Tooltip title={t("FILLIN") + " " + t("CUSTOMER")}>
                            <Icon color="action">contacts</Icon>
                        </Tooltip>
                    </div>
                    <TaskDetailTextField
                        className="mb-12"
                        id="debnr"
                        label={t("CUSTOMER")}
                        name="debnr"
                        value={task.debnr}
                        onChange={handleChange}
                        variant="outlined"
                        required
                        fullWidth
                        disabled
                    />
                </div>
                {/* <div className="flex w-full"> */}
                <div className="w-full flex xs:w-full sm:w-1/2 md:w-full lg:w-1/2">
                    <div className="min-w-48 pt-20 lg:ml-16">
                        <Tooltip title={t("FILLIN") + " " + t("ACCOUNTMNGR")}>
                            <Icon color="action">supervisor_account</Icon>
                        </Tooltip>
                    </div>

                    <TaskDetailAutoComplete
                        options={accountManagers}
                        value={selectedAccountManager}
                        onChange={(event, value) => handleAutoCompleteChange(value)}
                        fullWidth
                        disabled={finishedTask}
                        error={task.triedToSubmit && (task.azureId.length === 0 || !selectedAccountManager)}
                        helperText={task.triedToSubmit && task.azureId.length === 0 ? t("ACCOUNTMNGR") + ' ' + t('is_required') : ""}
                        getOptionLabel={option => option.name}
                    />
                </div>
            </div>
            <div className="w-full flex flex-wrap">

                {/* <div className="flex w-full"> */}
                <div className="w-full flex xs:w-full sm:w-1/2 md:w-full lg:w-1/2">
                    <div className="min-w-48 pt-32">
                        {/* <Tooltip title="Kies een datum waarop de taak van start gaat"> */}
                        <Icon color="action">date_range</Icon>
                        {/* </Tooltip> */}
                    </div>
                    <TaskDetailDatePicker
                        fullWidth
                        inputVariant="outlined"
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        label={t("STARTDATE")}
                        name="startDate"
                        value={task.startDate}
                        onChange={date => handleDateChange(date, 'startDate')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        disabled={finishedTask}
                    />
                </div>
                {/* <div className="flex w-full"> */}
                <div className="w-full flex xs:w-full sm:w-1/2 md:w-full lg:w-1/2">
                    <div className="min-w-48 pt-32 lg:ml-16">
                        {/* <Tooltip title="Kies een datum waarop de taak verloopt"> */}
                        <Icon color="action">event</Icon>
                        {/* </Tooltip> */}
                    </div>
                    <TaskDetailDatePicker
                        fullWidth
                        inputVariant="outlined"
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        label={t("ENDDATE")}
                        name="endDate"
                        value={task.endDate}
                        onChange={date => handleDateChange(date, 'endDate')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        minDate={task.startDate}
                        disabled={finishedTask}
                    />
                </div>
                <div className='flex w-full'>
                    <div className="flex w-full justify-between">
                        <div style={{ width: '30%' }}></div>
                        <div className="flex justify-between" style={{ margin: '0px 40px', width: '70%' }}>
                            <div>
                                <Typography>1</Typography>
                            </div>
                            <div>
                                <Typography>10</Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <CharacteristicOptions
                    handleChange={handleSliderChange}
                    characteristic="service"
                    optionNote={task.serviceNote}
                    selectedOption={task.serviceOption}
                    title={t('service')}
                    disabled={finishedTask}
                    triedToSubmit={triedToSubmit}
                />
                <CharacteristicOptions
                    handleChange={handleSliderChange}
                    characteristic="quality"
                    optionNote={task.qualityNote}
                    selectedOption={task.qualityOption}
                    title={t('quality')}
                    disabled={finishedTask}
                    triedToSubmit={triedToSubmit}
                />
                <CharacteristicOptions
                    handleChange={handleSliderChange}
                    characteristic="transport"
                    optionNote={task.transportNote}
                    selectedOption={task.transportOption}
                    title={t('transport')}
                    disabled={finishedTask}
                    triedToSubmit={triedToSubmit}
                />
                <CharacteristicOptions
                    handleChange={handleSliderChange}
                    characteristic="webshop"
                    optionNote={task.webshopNote}
                    selectedOption={task.webshopOption}
                    title={t('webshop')}
                    disabled={finishedTask}
                    triedToSubmit={triedToSubmit}
                />
                <CharacteristicOptions
                    handleChange={handleSliderChange}
                    characteristic="price"
                    optionNote={task.priceNote}
                    selectedOption={task.priceOption}
                    title={t('prijs')}
                    disabled={finishedTask}
                    triedToSubmit={triedToSubmit}
                />

                <div className="flex pt-12 w-full">
                    <div className="min-w-48 pt-20">
                        <Tooltip title={t("notes")}>
                            <Icon color="action">assignment</Icon>
                        </Tooltip>
                    </div>
                    <TaskDetailTextField
                        className="mb-12 mt-12"
                        label={t("notes")}
                        name="notes"
                        value={task.notes}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={2}
                        maxRows={4}
                        disabled={finishedTask}
                    />
                </div>

                <div className="flex w-full justify-end">
                    <Button
                        // className="w-full"
                        variant="contained"
                        // disableElevation
                        color="primary"
                        startIcon={<Icon>save</Icon>}
                        onClick={handleSave}
                        disabled={finishedTask}
                    >
                        {t("conclude")}
                    </Button>
                </div>

                {
                    finishedTask && <div className="flex justify-center w-full mt-12">
                        <Alert className="mb-12 w-full" severity="success">De taak is afgehandeld!</Alert>
                    </div>
                }
                {
                    error && <div className="flex justify-center w-full mt-12">
                        <Alert className="mb-12 w-full" severity="error">Er is een fout opgetreden. Contacteer <a href="mailto:d.denheijer@floraltradegroup.com">Dion den Heijer</a></Alert>
                    </div>
                }

            </div>
        </React.Fragment>
    );
}

export default FirstOrderTaskForm;