import React from 'react';
import {Card, CardContent, Icon} from '@material-ui/core';
import {CallListTableHeader} from './CallListTableHeader';
import {LinkToDebnr} from '../../../../../components/Functions/RouteToDebnr';
import Spinner from '../../../../../components/UI/Spinner/Spinner';

const CallListBlock6 = props => {
    return (
        <Card className={"p-12 rounded-8 shadow-none border-1 overflow-auto"}>
            <CallListTableHeader onShowAllClick={props.onShowAllClick} showAll={props.showAll} block="block6" title={props.title} />
            <CardContent className={"p-0"}>
                <table className={"simple clickable"}>
                    <thead>
                        <tr>
                            <th>Debnr</th>
                            <th>Name</th>
                            <th>TVA</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!props.data.loading ? props.data.data.slice(0, props.showAll ? props.data.data.length + 1 : 5).map((row, index) => (
                            <tr key={index}>
                                <td><LinkToDebnr debnr={row.debnr}>{row.debnr}</LinkToDebnr></td>
                                <td>{row.name}</td>
                                <td>{row.btwOk ? <Icon style={{color: 'green'}}>check</Icon> : <Icon style={{color: 'red'}}>close</Icon>}</td>
                                <td className="text-center">{row.fctdat}</td>
                            </tr>
                        ))
                            : <tr>
                                <td colSpan={4}><Spinner size={60} /></td>
                            </tr>
                        }
                        {!props.data.loading && props.data.data.length === 0 ? 
                            <tr>
                                <td colSpan={4} className="text-center">No data found</td>
                            </tr>
                        : null}
                    </tbody>
                </table>
            </CardContent>
        </Card>
    )
}

export {CallListBlock6};