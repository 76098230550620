export const FETCH_APIS_SUCCESS = 'FETCH_APIS_SUCCESS';

export const GENERATE_DEXX_CBS_EXPORT_START = 'GENERATE_DEXX_CBS_EXPORT_START';
export const GENERATE_DEXX_CBS_EXPORT_SUCCESS = 'GENERATE_DEXX_CBS_EXPORT_SUCCESS';
export const GENERATE_DEXX_CBS_EXPORT_FAIL = 'GENERATE_DEXX_CBS_EXPORT_FAIL';

export const GENERATE_DEXX_CnC_EXPORT_START = 'GENERATE_DEXX_CnC_EXPORT_START';
export const GENERATE_DEXX_CnC_EXPORT_SUCCESS = 'GENERATE_DEXX_CnC_EXPORT_SUCCESS';
export const GENERATE_DEXX_CnC_EXPORT_FAIL = 'GENERATE_DEXX_CnC_EXPORT_FAIL';


export const GENERATE_STOCK_EXPORT_START = 'GENERATE_STOCK_EXPORT_START';
export const GENERATE_STOCK_EXPORT_SUCCESS = 'GENERATE_STOCK_EXPORT_SUCCESS';
export const GENERATE_STOCK_EXPORT_FAIL = 'GENERATE_STOCK_EXPORT_FAIL';

export const GENERATE_DEXX_CnC_MODULE_START = 'GENERATE_DEXX_CnC_MODULE_START';
export const GENERATE_DEXX_CnC_MODULE_SUCCESS = 'GENERATE_DEXX_CnC_MODULE_SUCCESS';
export const GENERATE_DEXX_CnC_MODULE_FAIL = 'GENERATE_DEXX_CnC_MODULE_FAIL';

export const DEXX_CnC_UPDATE_IKLANT_SUCCESS = 'DEXX_CnC_UPDATE_IKLANT_SUCCESS';
export const DEXX_CnC_UPDATE_IKLANT_FAIL = 'DEXX_CnC_UPDATE_IKLANT_FAIL';

export const GENERATE_QUADRA_EXPORT_START = 'GENERATE_QUADRA_EXPORT_START';
export const GENERATE_QUADRA_EXPORT_SUCCESS = 'GENERATE_QUADRA_EXPORT_SUCCESS';
export const GENERATE_QUADRA_EXPORT_FAIL = 'GENERATE_QUADRA_EXPORT_FAIL';

export const GENERATE_QUADRA_EXPORT_FAULTS_START = 'GENERATE_QUADRA_EXPORT_FAULTS_START';
export const GENERATE_QUADRA_EXPORT_FAULTS_SUCCESS = 'GENERATE_QUADRA_EXPORT_FAULTS_SUCCESS';
export const GENERATE_QUADRA_EXPORT_FAULTS_FAIL = 'GENERATE_QUADRA_EXPORT_FAULTS_FAIL';

export const GENERATE_ADHOC_EXPORT_START = 'GENERATE_ADHOC_EXPORT_START';
export const GENERATE_ADHOC_EXPORT_SUCCESS = 'GENERATE_ADHOC_EXPORT_SUCCESS';
export const GENERATE_ADHOC_EXPORT_FAIL = 'GENERATE_ADHOC_EXPORT_FAIL';

export const SEND_DAILY_OMK_SUCCESS = 'SEND_DAILY_OMK_SUCCESS';

export const GENERATE_DEBNR_DEXX_CBS_EXPORT_START = 'GENERATE_DEBNR_DEXX_CBS_EXPORT_START';
export const GENERATE_DEBNR_DEXX_CBS_EXPORT_SUCCESS = 'GENERATE_DEBNR_DEXX_CBS_EXPORT_SUCCESS';
export const GENERATE_DEBNR_DEXX_CBS_EXPORT_FAIL = 'GENERATE_DEBNR_DEXX_CBS_EXPORT_FAIL';

export const GENERATE_JDF_EXPORT_START = 'GENERATE_JDF_EXPORT_START';
export const GENERATE_JDF_EXPORT_SUCCESS = 'GENERATE_JDF_EXPORT_SUCCESS';
export const GENERATE_JDF_EXPORT_FAIL = 'GENERATE_JDF_EXPORT_FAIL';