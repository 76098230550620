import React, {Component} from 'react';
import {withStyles, Card, Typography, Icon, Chip, Tooltip } from '@material-ui/core';
import {Line} from 'react-chartjs-2';
import _ from 'lodash';
import yellow from '@material-ui/core/colors/yellow';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    warningIcon: {
        color: yellow[700],
    },
    successIcon: {
        color: '#27c24c'
    },
    blueChip: {
        color: 'rgba(0,229,255)',
        borderColor: 'rgba(0,229,255)',
        cursor: 'default !important'
    },
    orangeChip: {
        color: 'rgb(255,196,0)',
        borderColor: 'rgb(255,196,0)',
        cursor: 'default !important'
    },
    pinkChip: {
        color: 'rgb(233,30,99)',
        borderColor: 'rgb(233,30,99)',
        cursor: 'default !important'
    },
    purpleChip: {
        color: 'rgb(206, 147, 216)',
        borderColor: 'rgb(206, 147, 216)',
        cursor: 'default !important'
    },
    greenChip: {
        color: 'rgb(0, 230, 118)',
        borderColor: 'rgb(0, 230, 118)',
        cursor: 'default !important'
    },
    greyBlueChip: {
        color: 'rgb(96, 125, 140)',
        borderColor: 'rgb(96, 125, 140)',
        cursor: 'default !important'
    }
});

const currentYear = new Date().getFullYear();
const lastYear = new Date().getFullYear() - 1;

class LineChart extends Component {

    state = {
        dataset: 'turnover'
    };

    setDataSet = (dataset) => {
        this.setState({dataset});
    };

    getStatus = (current, target) => {
        const compared = ((current - target) / target) * 100;
        if(compared < -5) {return 0;}
        else if(compared > 5) {return 1;}
        else {return 2;}
    }

    getChipClassByLabel = label => {
        switch(label) {
            case currentYear: 
                return 'orangeChip';
            case lastYear: 
                return 'blueChip';
            case 'Target':
                return 'pinkChip';
            case 'Gerealiseerde Orders Target':
                return 'pinkChip';
            case 'Geschreven Orders Target':
                return 'pinkChip';
            case 'Reserveringen op factureringsdatum':
                return 'greenChip';
            case 'Reserveringen op boekdatum':
                return 'greenChip';
            case 'Reservations':
                return 'greenChip';
            case 'Marges':
                return 'greyBlueChip';
            default: 
                return 'blueChip';
        };
    }

    render()
    {
        // to give props: data, dataset (is datasetname. see line 68: 'months'), headerText, transparentColors, 
        const { data: dataRaw, dataset, theme, currentValue, targetValue, type, classes, overviewChart, colors, headerLabels, additionalInformation, hideChips, t } = this.props;
        const data = _.merge({}, dataRaw);
        const dataWithColors = data.datasets[dataset].map((obj, index) => {
            const palette = theme.palette[index === 0 ? 'primary' : 'secondary'];
            let color = colors[index];
            // let color = chartColors !== undefined ? theme.colors[index] : theme.chartColors[index];
            // color = this.props.transparentColors ? color.slice(0, -1)+ ', 0.7)' : color;
            return {
                ...obj,
                // borderColor              : obj.label === '2019' ? 'rgb(255, 255, 255, 0.0)' : color,
                borderColor              : color.borderColor,
                // backgroundColor          : obj.label === '2019' || obj.label === 'Target' ? 'rgb(255, 255, 255, 0.0)' : color,
                backgroundColor          : color.backgroundColor,
                borderWidth: obj.label === 'Target' ? 1 : 3,
                pointBackgroundColor     : color.borderColor,
                pointHoverBackgroundColor: color.borderColor,
                pointBorderColor         : palette.contrastText,
                pointHoverBorderColor    : palette.contrastText,
                pointRadius: obj.label === 'Target' ? 3 : 4,
                pointBorderWidth: obj.label === 'Target' ? 1 : 2,
            }
        });

        const status = this.getStatus(currentValue, targetValue);
        let statusIcon = <Icon color="error" fontSize="small">sentiment_dissatisfied</Icon>;
        if(status === 1){ statusIcon = <Icon className={classes.successIcon} fontSize="small">sentiment_very_satisfied</Icon> }
        else if(status === 2){ statusIcon = <Icon className={classes.warningIcon} fontSize="small">sentiment_satisfied</Icon> }

        let symbol = null;
        if(type === "euro") {symbol = <span>&euro;</span>}
        else if(type === "percentage") {symbol = <span>&#37;</span>}

        return (
            <Card className="w-full rounded-8 shadow-none border-1">

                <div className="relative p-24 flex flex-row items-center justify-between">

                    <div className="flex flex-col">
                    <Tooltip 
                    title={
                        <React.Fragment>
                        <Typography color="inherit" className="text-14">{this.props.headerTextTooltip}</Typography>
                        <Typography color="inherit" className="text-14">{t("THISYR")} : {currentValue} & {t("LASTYR")} : {targetValue}</Typography>
                        </React.Fragment>
                    }>
                    {/* <Tooltip title={`${this.props.headerTextTooltip} (this year : ${currentValue} & last year : ${targetValue})`}> */}
                        <Typography className="h3 sm:h2">{currentValue !== undefined ? statusIcon : null} {this.props.headerText} : 
                        {currentValue !== undefined ? <> {currentValue.toLocaleString()} / {targetValue.toLocaleString()} {symbol}</> : null}</Typography>
                    </Tooltip>
                    
                    </div>

                    <div className="flex flex-row items-center">
                        {additionalInformation}
                        {!hideChips && overviewChart ? 
                        <>
                        {
                            headerLabels.map(label => (
                                <Chip key={label} label={label} className={classes[this.getChipClassByLabel(label)]} variant="outlined" />
                            ))
                        }
                        </>
                        : null }
                    </div>
                </div>

                <Typography className="relative h-200 sm:h-320 sm:pb-16">
                    <Line
                        data={{
                            labels  : data.labels,
                            datasets: dataWithColors
                        }}
                        options={data.options}
                    />
                </Typography>
            </Card>
        );
    }
}

export default withStyles(styles, {withTheme: true})((withTranslation()(LineChart)));

export const lineData = {
    chartType: 'line',
    datasets : {
        'months': [
            {
                label: "Invoices",
                fill: "start",
                data: [1542, 1022, 1166, 1134, 1106, 1421, 1072, 1370, 1135, 1377, 1034, 1010],
            },
            {
                label: "Turnover (x100)",
                fill: "start",
                data: [12474, 6617, 6974, 7478, 6523, 8561, 6683, 9437, 7060, 10220, 6019, 6590],
            }
        ]
    },
    labels   : ["5", "6", "7", "8", "9", "10", "11", "12", "1", "2", "3", "4"],
    options  : {
        spanGaps           : false,
        legend             : {
            display: false
        },
        maintainAspectRatio: false,
        tooltips           : {
            position : 'nearest',
            mode     : 'index',
            intersect: false,
            callbacks: {
                label: (tooltipItem, data) => {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var currentValue = dataset.data[tooltipItem.index];
                    return dataset.label + ': ' + Number(currentValue).toLocaleString('nl-NL');
                },
            }
        },
        layout             : {
            padding: {
                left : 24,
                right: 32
            }
        },
        scales             : {
            xAxes: [
                {
                    gridLines: {
                        display: false
                    },
                    ticks    : {
                        fontColor: 'rgba(0,0,0,0.54)'
                    }
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        tickMarkLength: 16
                    },
                    ticks    : {
                        beginAtZero: true,
                        stepSize: 50000
                    }
                }
            ]
        },
        plugins            : {
            filler: {
                propagate: false
            }
        }
    }
};