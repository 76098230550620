import React from 'react';

const LeftSideLayout2 = () => {
    return (
        <React.Fragment>

        </React.Fragment>
    );
};

export default LeftSideLayout2;
