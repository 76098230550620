import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import { createTheme, Tooltip, Icon } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import {connect} from 'react-redux';
import styled from 'styled-components';

const styles = theme => ({
    input: {
      color: "#fff",
      borderBottom: '1px solid #fff'
    },
    label: {
        color: '#fff',
        // backgroundColor: '#fff'
    }
});

const FlexWrap = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #333333;
    margin-right: 1rem;
    position: relative;
    align-items: center;
`

const InputWrap = styled.div`
    display: flex;
    flex-direction: column;
`

const Label = styled.label`
    font-size: 90%;
`

const StyledInput = styled.input`
    /*width: 100%;*/
    border: 0;
    height: 1.1875em;
    margin: 0;
    display: block;
    padding: 6px 7px 7px;
    min-width: 0;
    /*background: #dfdfdf;*/
    box-sizing: content-box;    
    color: #333333;
    cursor: pointer;
    /*max-width: 200px;*/
`

const StyledIcon = styled(Icon)`
    color: ${props => props.$submittedRoute ? '#00e676' : 'rgba(0, 0, 0, 0.87)'};
    margin-right: 7px;
    cursor: pointer;
    font-size: 2.3rem;
    border-radius: 50%;
    transition: color 0.5s ease, background 0.5s ease;
    position: relative;
    overflow: unset;
    z-index: 1;
    &::before {
        content: "";
        background-color: rgba(245, 245, 245, 0.16);
        padding: 1.9rem;
        display: block;
        top: -8px;
        position: absolute;
        border-radius: 50%;
        right: -8px;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.5s ease;
    }
    &:hover {
        ::before {
            opacity: 1;
        }
    }
`

const CustomerRouteCalendar = (props) => {
    const maxDate = moment(new Date()).add(5, 'days').toDate();
    
    const {classes} = props;
    const materialTheme = createTheme({
        ...props.mainTheme,
    });

    const InputField = props => {    
        return(
            <FlexWrap>
                <InputWrap className={"w-5/6"}>
                    <Label>{props.label}</Label>
                    <StyledInput value={props.value} onClick={props.onClick} readOnly className={"w-full shadow-none"} />
                </InputWrap>
                <Tooltip title="Open an extra departure day for the chosen date" >
                    {props.submittedRoute ? 
                        <StyledIcon $submittedRoute>check</StyledIcon>
                        : <StyledIcon onClick={props.onSubmitOpenCustomerRoute}>save</StyledIcon>
                    }
                </Tooltip>
            </FlexWrap>
        );
    }
    
    // <TextField
    //     value={props.value}
    //     onClick={props.onClick}
    //     InputProps={{
    //         endAdornment: (
    //             <InputAdornment position="end">
    //                 <IconButton onClick={() => console.log('clicked')}>
    //                     <Icon className="text-16">save</Icon>
    //                 </IconButton>
    //             </InputAdornment>
    //         )
    //     }}
    // />

    return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider theme={materialTheme}>
                    <DatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label={props.label}
                        format="dd/MM/yyyy"
                        disablePast
                        maxDate={maxDate}
                        shouldDisableDate={(date) => date.getDay() === 0 || date.getDay() === 6}
                        value={props.routeDate}
                        onChange={props.handleRouteDateChange}
                        // okLabel={<span style={{color: '#fff'}}>Ok</span>}
                        // cancelLabel={<span style={{color: '#fff'}}>Cancel</span>}
                        InputProps={{ className: classes.input }}
                        InputLabelProps={{ className: classes.label }}
                        color="primary"
                        TextFieldComponent={textFieldProps => <InputField {...textFieldProps} submittedRoute={props.submittedRoute} onSubmitOpenCustomerRoute={props.onSubmitOpenCustomerRoute} />}
                    />
                </ThemeProvider>
            </MuiPickersUtilsProvider>
    )
}

function mapStateToProps({fuse})
{
    return {
        mainTheme: fuse.settings.mainTheme
    }
}

export default connect(mapStateToProps)(withStyles(styles)(CustomerRouteCalendar));