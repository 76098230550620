import React, { useState } from 'react';
import {Icon, Typography, withStyles, Card, CardHeader, Avatar, Paper, CardContent, CardActions, Chip, TextField, Divider, Tooltip} from '@material-ui/core';
import { FusePageSimple, FuseAnimateGroup } from '../../../../../@fuse'
import orange from '@material-ui/core/colors/orange';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import {withTranslation} from 'react-i18next';
import {LinkToDebnr, LinkToDebnrWithChildren} from '../../../../components/Functions/RouteToDebnr';
import useDefaultQuery from '../../../../../api/useDefaultQuery';

const styles = theme => ({
    actions: {
      display: 'flex',
      padding: '12px',
    },
    avatar: {
      backgroundColor: orange[800],
    },
    chip: {
        cursor: 'default !important',
    }
});

const Inbox = (props) => {
    const [searchValues, setSearchValues] = useState({
        username: '',
        debnr: '',
        placeDate: '',
        memo: '',            
    });
    const {classes, t} = props;
    const notes = useDefaultQuery('notes', 'customer/GetNotesbyUser')

    const updateSearch = (event) => {
        const search = {
            ...searchValues,
            [event.target.name]: event.target.value
        };
        setSearchValues(search);
    }

    const getFilteredNotes = (search) => {
        let filteredNotes = [...notes.data];
        for(const searchKey of Object.keys(searchValues)) {
            if(searchValues[searchKey] !== '') {
                filteredNotes = filteredNotes.filter(note => note[searchKey].toLowerCase().includes(searchValues[searchKey].toLowerCase()));
            }
        }
        return filteredNotes;
    }
        
    return (
    <FusePageSimple
        header={
            <div className="flex flex-1 items-center justify-between p-24">
                <div className="flex flex-col">
                    <div className="flex items-center mb-16">
                        <Icon className="text-18" color="action">home</Icon>
                        <Icon className="text-16" color="action">chevron_right</Icon>
                        <Typography color="textSecondary">iCRM</Typography>
                        <Icon className="text-16" color="action">chevron_right</Icon>
                        <Typography color="textSecondary">{t("INBOX")}</Typography>
                    </div>
                    <Typography variant="h6">{t("INBOX")}</Typography>
                </div>
            </div>
        }
        content={
            <FuseAnimateGroup animation="transition.slideUpIn" delay={300}>
            <div className="flex flex-wrap w-full">
                <Paper elevation={1} className="flex w-full">
                    <div className="flex w-full sm:w-full md:w-1/4">
                        <TextField
                            className="m-12 w-full"
                            label={t("USERNAME")}
                            type="text"
                            name="username"
                            variant="outlined"
                            value={searchValues.username}
                            onChange={updateSearch}
                        />
                    </div>
                    <div className="flex w-full sm:w-full md:w-1/4">
                        <TextField
                            className="m-12 w-full"
                            label={"Debnr"}
                            type="text"
                            name="debnr"
                            variant="outlined"
                            value={searchValues.debnr}
                            onChange={updateSearch}
                        />
                    </div>
                    <div className="flex w-full sm:w-full md:w-1/4">
                        <TextField
                            className="m-12 w-full"
                            label={t("DATE")}
                            type="text"
                            name="placeDate"
                            variant="outlined"
                            value={searchValues.placeDate}
                            onChange={updateSearch}
                        />
                    </div>
                    <div className="flex w-full sm:w-full md:w-1/4">
                        <TextField
                            className="m-12 w-full"
                            label={t("MESSAGE")}
                            type="text"
                            name="memo"
                            variant="outlined"
                            value={searchValues.memo}
                            onChange={updateSearch}
                        />
                    </div>
                </Paper>
            </div>
                <div className="flex flex-wrap w-full p-12">
                    {!notes.isLoading ?
                        getFilteredNotes().slice(0, 76).map((note, index) => (
                            <div key={index} className="flex w-full sm:w-full md:w-1/2 p-12">
                                <Card className="w-full">
                                    <CardHeader
                                    avatar={
                                        <Tooltip title={note.username}>
                                            <Avatar aria-label="Recipe" className={classes.avatar}>
                                            {note.username[0]}
                                            </Avatar>
                                        </Tooltip>
                                    }
                                    title={<React.Fragment>
                                        <Chip label={<LinkToDebnr debnr={note.debnr} />} className={classes.chip} /> 
                                        <span style={{marginLeft: 4}}>
                                            <LinkToDebnrWithChildren debnr={note.debnr}>{note.debnaam}</LinkToDebnrWithChildren>
                                        </span>
                                    </React.Fragment>}
                                    subheader={<>{note.placeDate}</>}
                                    // subheader={note.placeDate}
                                    />
                                    <Divider />
                                    <CardContent>
                                        <Typography component="p">
                                            {note.memo}
                                        </Typography>
                                    </CardContent>
                                    <CardActions className={classes.actions} disableSpacing>
                                        <i>
                                            <Typography component="p" className="font-bold">
                                                {note.username}
                                            </Typography>
                                        </i>
                                    </CardActions>
                                </Card>
                            </div>
                        ))
                    : <Spinner />
                    }
                </div>
            </FuseAnimateGroup>
        }
    />
    )
}

export default withStyles(styles)(withTranslation()(Inbox));