import * as actionTypes from './actionTypes';
import axios from 'axios'
import moment from 'moment';

//Customer Data
export const fetchDbTurnoverSuccess = (dbTurnover) => {
    return {
        type: actionTypes.FETCH_TURNOVER_SUCCESS,
        turnover: dbTurnover
    };
};

export const fetchDbTurnover = () => {
    return dispatch => {
        axios.get('turnover/getallturnover')
            .then(res => {
                dispatch(fetchDbTurnoverSuccess(res.data));
            })
    };
};


export const downloadTurnover = (info) => {
    return dispatch => {
        console.log(info)
        axios.post('turnover/DownloadTurnover', info, {responseType: 'blob'})
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Turnover${moment(new Date()).format('YYYY-MM-DD')}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    };
}