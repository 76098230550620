import SimpleLogin from './Login/SimpleLogin';
import SimpleUpdatePassword from './UpdatePassword/SimpleUpdatePassword';

export const AuthConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/login',
            component: SimpleLogin,
            // component: FuseLogin,
            exact: true,
        },
        {
            path     : '/update-password/:token',
            component: SimpleUpdatePassword,
            // component: UpdatePassword,
            exact: true,
        },
    ]
};