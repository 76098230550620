import React from 'react';
import {Button, Paper} from '@material-ui/core';

const DashboardDateButtons = props => {
    const {handleDateButtonClick, t} = props;
    return (
        <Paper className="w-full rounded-8 shadow-none border-1">
        <div className="flex flex-wrap w-full justify-center" elevation={1}>
            <Button variant="contained" color="primary" className="m-12" onClick={() => handleDateButtonClick('yesterday')}>
                {t("YESTERDAY")}
            </Button>
            <Button variant="contained" color="primary" className="m-12" onClick={() => handleDateButtonClick('thisweek')}>
                {t("THISWK")}
            </Button>
            <Button variant="contained" color="primary" className="m-12" onClick={() => handleDateButtonClick('thismonth')}>
                {t("THISMT")}
            </Button>
            <Button variant="contained" color="primary" className="m-12" onClick={() => handleDateButtonClick('thisyear')}>
                {t("THISYR")}
            </Button>
            <Button variant="contained" color="primary" className="m-12" onClick={() => handleDateButtonClick('lastweek')}>
                {t("LASTWK")}
            </Button>
            <Button variant="contained" color="primary" className="m-12" onClick={() => handleDateButtonClick('lastmonth')}>
                {t("LASTMT")}
            </Button>
            <Button variant="contained" color="primary" className="m-12" onClick={() => handleDateButtonClick('lastyear')}>
                {t("LASTYR")}
            </Button>
            <Button variant="contained" color="primary" className="m-12" onClick={() => handleDateButtonClick('defaultdate')}>
                {t("DEFAULTDT")}
            </Button>
        </div>
        </Paper>
    );
};

export default DashboardDateButtons;