import React, {Component} from 'react';
import ReactTable from "react-table";
import {Icon, Tooltip, IconButton, Link} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';

class NewLeadsTable extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate = !_.isEqual(nextProps.newLeads, this.props.newLeads);
        // console.log(shouldUpdate);
        return shouldUpdate;
    }

    render() {
        const { canBeValidated, onValidateNewLead, handleEdit, newLeads, t } = this.props;

        return (
            <ReactTable
                className="-striped -highlight border-0"
                data={newLeads}
                columns={[
                    {
                        Header    : t("VALIDATE"),
                        className: "justify-center",
                        width: 50,
                        Cell: row => canBeValidated(row.original) === true ?
                        (
                        <Tooltip title={t("VALIDATE")}>
                            <IconButton aria-label="Edit" onClick={() => onValidateNewLead(row.original)} disabled={!canBeValidated(row.original)}>
                                <Icon>send</Icon>
                            </IconButton>
                        </Tooltip>
                        )
                        :
                        row.original.acctmngR_VALIDATED === 0 ?
                        <IconButton aria-label="Edit" onClick={() => onValidateNewLead(row.original)} disabled={!canBeValidated(row.original)}>
                            <Icon>send</Icon>
                        </IconButton>
                        :
                        <Tooltip title={t("VALIDATE") + " : " + moment(row.original.validateD_DATE).format('DD-MM-YYYY @ HH:mm')}>
                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                        </Tooltip>
                    },
                    {
                        Header    : t("EDIT"),
                        className: "justify-center",
                        width: 50,
                        Cell: row => row.original.fS_EXPORTED === 1 ? 
                        <Tooltip title={"Already exported, can't be edited anymore"}>
                            <IconButton aria-label="Edit" disabled>
                                <Icon>edit</Icon>
                            </IconButton>
                        </Tooltip> 
                        : 
                        <Tooltip title={t("EDIT")}>
                            <IconButton aria-label="Edit" onClick={() => handleEdit(row.original.id)}>
                                <Icon>edit</Icon>
                            </IconButton>
                        </Tooltip>
                    },
                    {
                        Header    : t("CREATED"),
                        accessor  : "icrM_CREATED",
                        filterable: true,
                        className : "font-bold",
                        width: 180,
                        Cell: row => moment(row.value).format('DD-MM-YYYY @ HH:mm'),
                    },
                    {
                        Header    : t("SHOP") + " " + t("NAME"),
                        accessor  : "debnaam",
                        filterable: true,
                        className : "font-bold",
                        // width: 250,
                        Cell: row => (
                            <Link onClick={() => handleEdit(row.original.id)} style={{color: "#ef6c00", cursor: "pointer"}}>
                                {row.original.debnaam}
                            </Link>
                        )
                    },
                    {
                        Header    : t("ADDRESS"),
                        accessor  : "debadres",
                        className: "justify-center",
                        width     : 70,
                        Cell: row => row.value === "" || row.value === null ? 
                        <Tooltip title={'No ' + t("ADDRESS")}>
                            <Icon className="text-18" color="error">clear</Icon> 
                        </Tooltip>
                        :
                        <Tooltip title={row.value}>
                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                        </Tooltip>
                    },
                    {
                        Header    : t("ZIPCODE"),
                        accessor  : "debpc",
                        filterable: true,
                        className: "justify-center",
                        width     : 90,
                        Cell: row => row.value === "" || row.value === null ?
                        <Tooltip title={'No ' + t("ZIPCODE")}>
                            <Icon className="text-18" color="error">clear</Icon> 
                        </Tooltip>
                        :
                        <Tooltip title={row.value}>
                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                        </Tooltip>
                    },
                    {
                        Header    : t("CITY"),
                        accessor  : "debplaats",
                        filterable: true,
                        className: "justify-center",
                        width     : 70,
                        Cell: row => row.value === "" || row.value === null ? 
                        <Tooltip title={'No ' + t("CITY")}>
                            <Icon className="text-18" color="error">clear</Icon> 
                        </Tooltip>
                        :
                        <Tooltip title={row.value}>
                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                        </Tooltip>
                    },
                    {
                        Header    : "Email",
                        accessor  : "email",
                        // className: "left",
                        width: 70,
                        filterable: true,
                        Cell: row => row.value === "" || row.value === null ? 
                        <Tooltip title={'Email'}>
                            <Icon className="text-18" color="error">clear</Icon> 
                        </Tooltip>
                        :
                        <><Tooltip title={row.value}>
                        <a style={{cursor: 'pointer'}} href={`mailto:${row.value}`}>
                            <IconButton aria-label="Email">
                                <Icon>email</Icon>
                            </IconButton>
                        </a>
                        </Tooltip>
                        {row.original.countEmails > 1 ? <span style={{color: 'orange'}}>{row.original.countEmails}</span> : null}
                        </>
                    },
                    /**{
                        Header    : t("ACCOUNTMNGR"),
                        accessor  : "acctmngr",
                        className: "justify-center",
                        width     : 100,
                        Cell: row => row.value === "" || row.value === null ? 
                        <Tooltip title={'No ' + t("ACCOUNTMNGR")}>
                            <Icon className="text-18" color="error">clear</Icon> 
                        </Tooltip>
                        :
                        <Tooltip title={row.value}>
                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                        </Tooltip>
                    },**/
                    {
                        Header    : t("MOBILE"),
                        accessor  : "debmobiel",
                        className: "justify-center",
                        width: 70,
                        Cell: row => row.value === "" || row.value === null ? 
                        <Tooltip title={'No ' + t("PHONE")}>
                            <Icon className="text-18" color="error">clear</Icon> 
                        </Tooltip>
                        :
                        <Tooltip title={row.value}>
                        <a style={{cursor: 'pointer'}} href={`tel:${row.value}`}>
                            <IconButton aria-label="Phone">
                                <Icon>phone_iphone</Icon>
                            </IconButton>
                        </a>
                        </Tooltip>
                    },
                    {
                        Header    : t("PHONE"),
                        accessor  : "debtfnr",
                        className: "justify-center",
                        width: 70,
                        Cell: row => row.value === "" || row.value === null ? 
                        <Tooltip title={'No ' + t("PHONE")}>
                            <Icon className="text-18" color="error">clear</Icon> 
                        </Tooltip>
                        :
                        <Tooltip title={row.value}>
                        <a style={{cursor: 'pointer'}} href={`tel:${row.value}`}>
                            <IconButton aria-label="Phone">
                                <Icon>phone_iphone</Icon>
                            </IconButton>
                        </a>
                        </Tooltip>
                    },
                    {
                        Header    : "N " + t("VAT"),
                        accessor  : "btwnrdeb",
                        filterable: true,
                        className: "justify-center",
                        width: 70,
                        Cell: row => row.value === "" || row.value === null ? 
                        <Tooltip title={'No ' + t("VAT")}>
                            <Icon className="text-18" color="error">clear</Icon> 
                        </Tooltip>
                        :
                        <><Tooltip title={t("VAT") + " : " + row.value}>
                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                        </Tooltip>                        
                        {row.original.countVAT > 1 ? <span style={{color: 'orange'}}>{row.original.countVAT}</span> : null}</>
                    },
                    {
                        Header    : "VIES",
                        accessor  : "vieS_VALID",
                        filterable: true,
                        className: "justify-center",
                        width: 70,
                        Cell: row => row.value === "" || row.value === null || row.value === "False" ? 
                        <Tooltip title={'VIES Information not '+ t("VALIDATED")}>
                            <Icon className="text-18" color="error">cancel</Icon>
                            {/* <Icon onClick={() => this.props.onCheckVies(this.state.form)} color="primary">sync</Icon> */}
                        </Tooltip>
                        :
                        <Tooltip title={t("VAT") + " " + t("VALIDATED")  + " : " + row.value}>
                            <Icon className="text-18" style={{color: 'green'}}>check_circle</Icon>
                        </Tooltip>
                    },
                    // {
                    //     Header    : t("TRANSPORTER"),
                    //     accessor  : "acctmngR_NOTE",
                    //     className: "justify-center",
                    //     width: 100,
                    //     Cell: row => row.value === "" || row.value === null ? 
                    //     <Tooltip title={t('TRANSPORTER')}>
                    //         <Icon className="text-18" color="error">clear</Icon> 
                    //     </Tooltip>
                    //     :
                    //     <Tooltip title={t('TRANSPORTER') + " : " + row.value}>
                    //         <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                    //     </Tooltip>
                    // },
                    {
                        Header    : "Template",
                        accessor  : "template",
                        filterable: true,
                        className: "justify-center",
                        width: 70,
                        Cell: row => row.value === "" || row.value === null ? 
                        <Tooltip title={t('CUSTOMER') + " template"}>
                            <Icon className="text-18" color="error">clear</Icon> 
                        </Tooltip>
                        :                                
                        row.value === "ARCHIVE" ? 
                        <Tooltip title={t('NEWLEADARCHIVEINFO')}>
                            <Icon className="text-18" style={{color: 'orange'}}>warning</Icon>
                        </Tooltip>
                        :
                        <Tooltip title={"Template : " + row.value}>
                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                        </Tooltip>
                    },
                    {
                        Header    : t("NOTE"),
                        accessor  : "acctmngR_NOTE",
                        filterable: true,
                        className: "justify-center",
                        width: 70,
                        Cell: row => row.original.acctmngR_NOTE === '' ? 
                        null 
                        : 
                        (
                        <Tooltip title={row.value}>
                            <IconButton aria-label="Comment">
                                <Icon>comment</Icon>
                            </IconButton>
                        </Tooltip>
                        )
                    },
                    {
                        Header    : "Source",
                        accessor  : "forM_SOURCE",
                        filterable: true,
                        className: "justify-center",
                        width: 70,
                        Cell: row => row.original.forM_SOURCE === '' || row.original.forM_SOURCE === null || row.original.forM_SOURCE === undefined ? 
                        null 
                        : 
                        (
                        <Tooltip title={'Source form : ' + row.value}>
                            <IconButton aria-label="Comment">
                                <Icon>comment</Icon>
                            </IconButton>
                        </Tooltip>
                        )
                    },
                    {
                        Header    : t("EXPORTED"),
                        accessor  : "fS_EXPORTED",
                        className: "justify-center",
                        width: 70,
                        Cell: row => row.value === 0 ?
                            (row.original.acctmngR_VALIDATED === 1 ?
                            <Tooltip title={'Not exported yet, ask your financiel department'}>
                                <Icon className="text-18" color="error">clear</Icon> 
                            </Tooltip>
                            :
                            <Tooltip title={"Not validated yet, so can't be exported yet"}>
                                <Icon className="text-18">clear</Icon> 
                            </Tooltip>)
                        :
                        <Tooltip title={t("EXPORTED") + " : " + moment(row.original.fS_EXPORTED_DATE).format('DD-MM-YYYY @ HH:mm')}>
                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                        </Tooltip>
                    },
                ]}
                pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                defaultPageSize={5}
                noDataText="No customers found"
            />
        )
    }
}

export default withTranslation()(NewLeadsTable);