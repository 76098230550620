import axios from 'axios'
import * as actionTypes from './actionTypes';
import {getUserEmail, getAzureId} from '../../../../main/Auth/Auth';
import moment from 'moment';

// export const fetchNotesOverviewSuccess = (notes) => {
//     return {
//         type: actionTypes.FETCH_NOTES_SUCCESS,
//         notes: notes
//     };
// };

// export const fetchNotesOverview = () => {
//     return dispatch => {
//         axios.get('manager/getnotes')
//             .then(res => {
//                 dispatch(fetchNotesOverviewSuccess(res.data));
//             })
//     };
// };
export const fetchMultipleEmailDbNewLeadsSuccess = (dbmultipleemailLeads) => {
    return {
        type: actionTypes.FETCH_MULTIPLE_EMAIL_NEWLEADS_SUCCESS,
        MultipleEmailLeads: dbmultipleemailLeads
    };
};

export const fetchDbMultipleEmailLeads = (getUserEmail) => {

    return dispatch => {
        // dispatch(fetchDbNewLeadsStart());
        axios.get('customer/GetMultipleEmailLeads?email='+ getUserEmail)
            .then(res => {
                dispatch(fetchMultipleEmailDbNewLeadsSuccess(res.data));
                // dispatch(FetchGetNavCountNewleads());
            })
    };
};

// export const FetchDebTypeSuccess = (dbDebType) => {
//     return {
//         type: actionTypes.FETCH_ALL_DEBTYPE_SUCCESS,
//         DebType: dbDebType
//     };
// };

// export const onFetchAllDebType = (getUserEmail) => {
//     return dispatch => {
//         axios.post('customer/GetAllDebType')
//             .then(res => {
//                 dispatch(FetchDebTypeSuccess(res.data));
//             })
//     };
// };

export const fetchMultipleVatDbNewLeadsSuccess = (dbmultiplevatLeads) => {
    return {
        type: actionTypes.FETCH_MULTIPLE_VAT_NEWLEADS_SUCCESS,
        MultipleVatLeads: dbmultiplevatLeads
    };
};

export const fetchDbMultipleVatLeads = (vat) => {
    return dispatch => {
        axios.get('customer/GetMultipleVatLeads?vat='+ vat)
            .then(res => {
                dispatch(fetchMultipleVatDbNewLeadsSuccess(res.data));
            })
    };
};

export const fetchCreditsSuccess = (credits) => {
    return {
        type: actionTypes.FETCH_CREDITS_SUCCESS,
        credits: credits
    };
};

export const fetchCredits = () => {
    return dispatch => {
        axios.get('manager/getcredits')
            .then(res => {
                dispatch(fetchCreditsSuccess(res.data));
            })
    };
};

export const fetchPrelevementSuccess = (prelevements, dto) => {
    return {
        type: actionTypes.FETCH_PRELEVEMENTS_SUCCESS,
        prelevements: prelevements,
        dto: dto
    }
}

export const fetchPrelevementStart = () => {
    return {
        type: actionTypes.FETCH_PRELEVEMENTS_START,
    }
}

export const fetchPrelevement = (infoForPrelevement) => {
    return dispatch => {
        dispatch(fetchPrelevementStart());
        axios.post('prelevement/getprelevement', infoForPrelevement)
            .then(res => {
                dispatch(fetchPrelevementSuccess(res.data, infoForPrelevement));
            })
    }
}

export const generatePrelevementSuccess = () => {
    return {
        type: actionTypes.GENERATE_PRELEVEMENT_SUCCESS,
    }
}

export const generateAndDownloadPrelevementExcel = (infoForPrelevement) => {
    return dispatch => {
        dispatch(LoaderStart());
        axios.post('prelevement/GeneratePrelevementExcel', infoForPrelevement, {responseType: 'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Prelevement-${moment(new Date()).format('YYYY-MM-DD')}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
                dispatch(generatePrelevementSuccess(res.data));
            })
    }
}
// GENERATE RABOBANK XML //
export const generateRabobankPrelevementSuccess = (generated) => {
    return {
        type: actionTypes.GENERATE_RABOBANK_PRELEVEMENT_SUCCESS,
    }
}

export const generateAndDownloadPrelevementXml = (infoForPrelevement) => {
    return dispatch => {
        dispatch(LoaderStart());
        axios.post('prelevement/GeneratePrelevementXml', infoForPrelevement, {responseType: 'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `PAIEMENT-${moment(new Date()).format('DD-MM-YYYY')}.xml`); //or any other extension
                document.body.appendChild(link);
                link.click();
                dispatch(generatePrelevementSuccess(res.data));
            })
    }
}

export const sendPrelevementSuccess = (returnValue) => {
    return {
        type: actionTypes.SEND_PRELEVEMENT_SUCCESS,
        sentPrelevements: returnValue
    }
}

export const sendPrelevement = (infoForPrelevement) => {
    return dispatch => {
        dispatch(LoaderStart());
        // axios.post('https://norway.vdplas.com/src/api/sendPrelevement.php', JSON.stringify(infoForPrelevement))
        axios.post('/prelevement/SendPrelevement', infoForPrelevement)
            .then(res => {
                dispatch(sendPrelevementSuccess(res.data));
            })
    }
}

// Start Loader
export const LoaderStart = () => {
    return {
        type: actionTypes.PRELEVEMENT_START_LOADER,        
    }
}

// Reset state on company switch in prelevement page
export const CompanySwitchinPrelevement = () => {
    return {
        type: actionTypes.PRELEVEMENT_SWITCH_COMPANY,        
    }
}

// Get Assign New Leads
export const fetchAssignNewLeadsStart = () => {
    return {
        type: actionTypes.FETCH_ASSIGNNEWLEADS_START,
    };
};

export const fetchAssignNewLeadsSuccess = (assignnewleads) => {
    return {
        type: actionTypes.FETCH_ASSIGNNEWLEADS_SUCCESS,
        assignnewleads: assignnewleads
    };
};

export const fetchAssignNewLeads = () => {
    return dispatch => {
        dispatch(fetchAssignNewLeadsStart());
        axios.get('manager/getassignnewleads')
            .then(res => {
                // console.log(res.data.contacts);
                var contacts = res.data.contacts
                .filter(contact => !contact.attributes.DEBNR)
                .map(contact => {
                    return {
                        email: contact.email,
                        debnr: contact.attributes.debnr,
                        debnaam: contact.attributes.debnaam,
                        debadres: contact.attributes.address,
                        debpc: contact.attributes.zipcode,
                        debplaats: contact.attributes.city,
                        deblandcod: contact.attributes.country,
                        debcont: contact.attributes.nom,
                        debtfnr: contact.attributes.phone,
                        debmobiel: contact.attributes.sms,
                        btwnrdeb: contact.attributes.vat,
                        acctmngr: contact.attributes.accounT_MANAGER,
                        createdat: contact.createdAt,
                        companyid: contact.companyId,
                        listids: contact.listIds,
                        btwok: 1,
                        debtext: contact.attributes.comment,
                        forM_SOURCE: contact.attributes.forM_SOURCE,
                    }
                });
                dispatch(fetchAssignNewLeadsSuccess(contacts));
            })
    };
};

// Get Returned New Leads
export const fetchReturnedNewLeadsSuccess = (dbreturnednewLeads) => {
    return {
        type: actionTypes.FETCH_RETURNED_NEWLEADS_SUCCESS,
        returnednewLeads: dbreturnednewLeads
    };
};

export const fetchReturnedNewLeads = (getAzureId) => {
    return dispatch => {
        axios.get('manager/GetReturnedNewLeads', getAzureId)
            .then(res => {
                dispatch(fetchReturnedNewLeadsSuccess(res.data));
            })
    };
};

// Activate New Leads
export const activateNewLeadSuccess = (isActivated) => {
    return {
        type: actionTypes.ACTIVATE_NEW_LEAD_SUCCESS,
        isActivated
    };
};

export const activateNewLead = (row, callback) => {
    return dispatch => {
        axios.post('manager/ActivateNewLead', {...row, azure_id: getAzureId()})
            .then(res => {
                dispatch(activateNewLeadSuccess(res.data));
                callback(row, res.data);
                // dispatch(fetchAssignNewLeads());
            })
    };
};

// Add Fav Users Assign New Leads
export const addFaviCRMUserSuccess = (favisAdded) => {
    return {
        type: actionTypes.ADD_FAV_USERS_ASSIGNNEWLEADS_SUCCESS,
        favisAdded
    };
};

export const addFaviCRMUser = (row) => {
    return dispatch => {
        axios.post('manager/AddFaviCRMUser', row)
            .then(res => {
                dispatch(addFaviCRMUserSuccess(res.data));
                dispatch(fetchFaviCRMusersNewLeads());
                dispatch(fetchiCRMusersNewLeads());
            })
    };
};

// Delete Fav Users Assign New Leads
export const deleteFaviCRMUserSuccess = (favisDeleted) => {
    return {
        type: actionTypes.DELETE_FAV_USERS_ASSIGNNEWLEADS_SUCCESS,
        favisDeleted
    };
};

export const deleteFaviCRMUser = (row) => {
    return dispatch => {
        axios.post('manager/DeleteFaviCRMUser', row)
            .then(res => {
                dispatch(deleteFaviCRMUserSuccess(res.data));
                dispatch(fetchFaviCRMusersNewLeads());
                dispatch(fetchiCRMusersNewLeads());
            })
    };
};

// Get iCRM users for Assign New Leads
export const fetchiCRMusersNewLeadsSuccess = (dbicrmusersnewleads) => {
    return {
        type: actionTypes.FETCH_ICRMUSERS_ASSIGNNEWLEADS_SUCCESS,
        icrmusersnewleads: dbicrmusersnewleads
    };
};

export const fetchiCRMusersNewLeads = () => {
    return dispatch => {
        axios.get('manager/GetiCRMusersNewLeads', getUserEmail())
            .then(res => {
                dispatch(fetchiCRMusersNewLeadsSuccess(res.data));
            })
    };
};

// Get Favorite iCRM users for Assign New Leads
export const fetchFaviCRMusersNewLeadsSuccess = (dbfavicrmusersnewleads) => {
    return {
        type: actionTypes.FETCH_FAV_ICRMUSERS_ASSIGNNEWLEADS_SUCCESS,
        favicrmusersnewleads: dbfavicrmusersnewleads
    };
};

export const fetchFaviCRMusersNewLeads = () => {
    return dispatch => {
        axios.get('manager/GetFaviCRMusersNewLeads', getUserEmail())
            .then(res => {
                dispatch(fetchFaviCRMusersNewLeadsSuccess(res.data));
            })
    };
};

// Get Export New Leads
export const fetchExportNewLeadsSuccess = (exportnewleads) => {
    return {
        type: actionTypes.FETCH_EXPORTNEWLEADS_SUCCESS,
        exportnewleads: exportnewleads
    };
};

export const fetchExportNewLeads = () => {
    return dispatch => {
        axios.get('manager/getexportnewleads')
            .then(res => {
                dispatch(fetchExportNewLeadsSuccess(res.data));
                // dispatch(FetchGetNavCountNewleads());
            })
    };
};
// Get overview New Leads
export const fetchOverviewNewLeadsSuccess = (overviewnewleads) => {
    return {
        type: actionTypes.FETCH_OVERVIEWNEWLEADS_SUCCESS,
        overviewnewleads: overviewnewleads
    };
};

export const fetchOverviewNewLeads = () => {
    return dispatch => {
        axios.get('manager/getoverviewnewleads')
            .then(res => {
                dispatch(fetchOverviewNewLeadsSuccess(res.data));
            })
    };
};

// Generate Exported New Leads to Excel
export const GenerateExportNewLeadSuccess = (returnValue) => {
    return {
        type: actionTypes.GENERATE_EXPORT_NEWLEADS_SUCCESS,
        generatedExportnewleads: returnValue
    }
}

export const GenerateExportNewLead = (filteredLeads) => {
    return dispatch => {
        switch(filteredLeads[0].companY_ID) {
          case 2: // DEXX
          case 5: // G&B
          case 7: // VDP APS
            console.log("Make an Excel copy for the FP Florisoft");
            axios.post('manager/generateexportnewleadscopyfp', filteredLeads, {responseType: 'blob'})
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `NewLeads-FP-${moment(new Date()).format('YYYY-MM-DD')}.xlsx`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                })
        }
        axios.post('manager/generateexportnewleads', filteredLeads, {responseType: 'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `NewLeads-${moment(new Date()).format('YYYY-MM-DD')}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
                dispatch(GenerateExportNewLeadSuccess(true));
                dispatch(fetchExportNewLeads());
            })
    }
}

// Generate Exact Exported New Leads to Excel
export const GenerateExactExportNewLeadSuccess = (returnValue) => {
    return {
        type: actionTypes.GENERATE_EXACT_EXPORT_NEWLEADS_SUCCESS,
        generatedExportnewleads: returnValue
    }
}

export const GenerateExactExportNewLead = (filteredLeads) => {
    return dispatch => {
        axios.post('manager/generateexactexportnewleads', filteredLeads, {responseType: 'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Exact-Export-${moment(new Date()).format('YYYY-MM-DD')}.csv`); //or any other extension
                document.body.appendChild(link);
                link.click();
                dispatch(GenerateExactExportNewLeadSuccess(true));
                // dispatch(fetchExportNewLeads());
            })
    }
}
// Edit new lead
export const editNewLeadSuccess = (isEditedNewLead) => {
    return {
        type: actionTypes.EDIT_NEWLEAD_SUCCESS,
        isEditedNewLead
    };
};

export const editNewLead = (row, afterDelete) => {
    return dispatch => {
        axios.post('manager/editNewLead', row)
            .then(res => {
                dispatch(editNewLeadSuccess(res.data));
                dispatch(fetchExportNewLeads());
                // dispatch(FetchGetNavCountNewleads());
                afterDelete();
            })
    };
};

// set Edit new lead
export const setEditNewLeadFalse = () => {
    return dispatch => {
        dispatch(editNewLeadSuccess(false));
    }
}

// Check Vies VAT new lead
export const checkViesSuccess = (viesData) => {
    return {
        type: actionTypes.CHECK_VIES_SUCCESS,
        viesData
    };
};

export const checkVies = (row, afterCheckVies) => {
    return dispatch => {
        // axios.post('https://api.icrm.floral.nu/api/apis/checkVies', row)
        // axios.post('https://api.vanderplas.fr/api/apis/checkVies', row)
        axios.post('customer/checkVies', row)
            .then(res => {
                afterCheckVies(res.data);
                dispatch(checkViesSuccess(res.data));
                dispatch(fetchExportNewLeads());
            })
    };
};
