import React from 'react';
import { getCompany } from '../../../Auth/Auth';
import DecoOverview from './DecoOverview';
import Overview from './Overview';

const CommonOverview = () => {
    const companyId = getCompany();
    if(companyId === 4) {
        return <DecoOverview />
    } else {
        return <Overview />
    }
}
export default CommonOverview;