import React, {Component} from 'react';
import ReactTable from "react-table";
import {FuseAnimate} from '../../../../../@fuse';
import DarkAreaChart, {areaData} from '../../../../components/Widgets/DarkAreaChart';
import {connect} from 'react-redux';
import * as actions from '../../../../store/crm/actions';
import RouteToDebnr from '../../../../components/Functions/RouteToDebnr';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import {Icon, Tooltip} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import {getCompany} from '../../../Auth/Auth';

class Weblog extends Component {

    componentDidMount(){
        this.props.onFetchWeblog();
        this.props.onFetchWeblogVisits();
    }

    render()
    {
        const {weblog, weblogVisits, loading, t} = this.props;
        const isGreenflor = getCompany() === 3;
        const isHbxDeco = getCompany() === 4;
        if(Object.keys(weblogVisits).length > 0) {
            areaData.datasets['2019'] = [{
                label: 'Visits',
                data : weblogVisits.visits,
                fill : 'start'
            }];
            areaData.labels = weblogVisits.labels;
            areaData.options.scales.yAxes[0].ticks.max = Math.max.apply(null, weblogVisits.visits) * 1.1;
        }
        return (
            <>
                <div className="w-full">
                    {Object.keys(weblogVisits).length > 0 ? <DarkAreaChart data={areaData} dataset="2019" headerText={t("VISITORS")} subHeaderText={t("VISITORSBYDAY")} /> : null}
                </div>
            {loading ? <Spinner /> : null}
            {weblog.length > 0 && !loading ?
                <FuseAnimate animation="transition.slideUpIn" delay={300}>
                    <div className="w-full">
                        <ReactTable
                            className="-striped -highlight border-0"
                            data={weblog}
                            columns={[
                                {
                                    Header    : "Debnr",
                                    accessor  : "debnr",
                                    filterable: true,
                                    width: 100,
                                    className : "font-bold justify-center",
                                    Cell: row => (<RouteToDebnr row={row} />)
                                },
                                {
                                    Header    : t("NAME"),
                                    accessor  : "name",
                                    filterable: true,
                                    className : "font-bold justify-center"
                                },
                                {
                                    Header    : t("LOGTIME"),
                                    accessor  : "logtime",
                                    filterable: true,
                                    width: 200,
                                    className: "justify-center"
                                },
                                {
                                    Header    : t("KOPEN"),
                                    accessor  : "lastOrderTime",
                                    filterable: true,
                                    width: 200,
                                    className: "justify-center",
                                    Cell: row => row.value === '0' ? <Icon className="text-18" color="error">close</Icon> : row.value
                                },
                                {
                                    Header    : t("DIFFERENCE"),
                                    accessor  : "difference",
                                    filterable: true,
                                    width: 100,
                                    className: "justify-center",
                                    Cell: row => {
                                        if(row.value === 999) {
                                            return <Icon className="text-18" color="error">close</Icon>;
                                        } else if (row.value === 0) {
                                            return <Icon className="text-18" style={{color: 'green'}}>check</Icon>;
                                        } else {
                                            return row.value;
                                        }
                                    }
                                },
                                {
                                    Header    : isHbxDeco || isGreenflor ? t("COMMERCIALTYPE") : t("VISITE"),
                                    accessor  : isHbxDeco || isGreenflor ? "commercialType" : "pagesVisited",
                                    filterable: true,
                                    width: 200,
                                    className: "justify-center",
                                    Cell: row => <>
                                    {isHbxDeco || isGreenflor ? row.value : <><Tooltip title={t("WEBLOG30DAYS")}><span>{row.value}</span></Tooltip></>}
                                    </>
                                },
                                {
                                    Header    : t("DAYS") + " " + t("CREATED"),
                                    accessor  : "daysCreated",
                                    filterable: true,
                                    width: 150,
                                    className: "justify-center",
                                    Cell: row => 
                                        <Tooltip title={t("DAYS")}>
                                        <span>{row.value}</span>
                                        </Tooltip>
                                },
                                // {
                                //     Header    : t("BOUGHT"),
                                //     // Header    : t("ORDERLIST"),
                                //     // Header    : t("STOCK"),
                                //     accessor  : "sum",
                                //     // filterable: true,
                                //     width: 100,
                                //     className : "font-bold justify-end",
                                //     Cell: row => 
                                //     <>
                                //         <Tooltip title={`${t("STOCK")}: ${row.original.sumVerdeel} + ${t("ORDERLIST")}: ${row.original.sumBestverd}`}>
                                //         <span>
                                //         {row.original.sumBestverd > 0 || row.original.sumVerdeel > 0 ? "€ " : null}
                                //         {row.original.sumBestverd > 0 || row.original.sumVerdeel > 0 ? (row.original.sumBestverd + row.original.sumVerdeel).toLocaleString('nl-NL', { minimumFractionDigits:2, maximumFractionDigits:2 }) : null}
                                //         {/* {row.original.sumBestverd  > 0 && row.original.sumVerdeel > 0 ? <Badge /*anchorOrigin={{vertical: 'top',horizontal: 'left',}}*//* color="primary" variant="dot">&nbsp;</Badge> : null} */}
                                //         </span>
                                //         </Tooltip>
                                //     </>
                                // },
                            ]}
                            defaultPageSize={100}
                            noDataText="No customers found"
                        /> 
                    </div>
                </FuseAnimate>
            : null }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.weblog.loading,
        weblog: state.weblog.weblog,
        weblogVisits: state.weblog.weblogVisits
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchWeblog: () => dispatch(actions.fetchDbWeblog()),
        onFetchWeblogVisits: () => dispatch(actions.fetchDbWeblogVisits())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Weblog));