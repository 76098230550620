import React, {Component} from 'react';
import {Doughnut} from 'react-chartjs-2';

class PieChart extends Component {
    render()
    {
        const {data} = this.props;

        return (
                <Doughnut
                    data={{
                        labels  : data.mainChart.labels,
                        datasets: data.mainChart.datasets
                    }}
                    options={data.mainChart.options}
                />
        );
    }
}

export default PieChart;

export const pieData = {
    'title'    : 'Budget Distribution',
    'mainChart': {
        labels  : [
            'Blue',
            'Bronze',
            'Silver',
            'Gold',
            'Platinum'
        ],
        datasets: [
            {
                data                : [12, 17, 28, 25, 15],
                backgroundColor     : [
                    '#f44336',
                    '#9c27b0',
                    '#03a9f4',
                    '#e91e63',
                    '#ffc107'
                ],
                hoverBackgroundColor: [
                    '#f45a4d',
                    '#a041b0',
                    '#25b6f4',
                    '#e9487f',
                    '#ffd341'
                ]
            }
        ],
        options : {
            plugins: {
                labels: false
            },
            cutoutPercentage   : 0,
            spanGaps           : false,
            legend             : {
                display : true,
                position: 'bottom',
                labels  : {
                    padding      : 16,
                    usePointStyle: true
                }
            },
            maintainAspectRatio: false
        }
    }
};