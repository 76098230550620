import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/crm/actions';
import {Button, Dialog, Icon, Typography, Toolbar, AppBar, TextField,
    IconButton, Tooltip, Card, CardHeader, Divider, CardContent, FormControlLabel, Switch} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {withTranslation} from 'react-i18next';
import {getAzureId, getUsername} from '../../../Auth/Auth';

class ValidationDialog extends Component {
    state = {
        welcomediscount: 0,
    }

    handleClose = () => {
        this.props.onClose();
        this.setState({userInput: ''});
    }

    onActivateNewCustomer = (debnr, welcomediscount) => {
        this.props.onActivateNewCustomer(debnr, welcomediscount);
        const userLog = {
            azureId: getAzureId(),
            type: 'button', 
            actionName: 'ActivateNewCustomer',
            extraInfo: 'DEBNR : ' + debnr
        };
        this.props.onCreateUserLog(userLog);
        this.handleClose();
    }

    handleSwitch = event => {
        this.setState({ welcomediscount: event.target.checked === true ? 1 : 0 })
    }

    render()
    {
        const {dialogOpen, debnrvalidation, t} = this.props;
        // console.log(debnrvalidation);
        return (
            
                <Dialog 
                    classes={{
                        paper: "m-24"
                    }}
                    onClose={this.handleClose}
                    open={dialogOpen}
                    fullWidth
                    maxWidth="sm"
                >
                <AppBar position="static" elevation={1}>
                    <Toolbar className="flex w-full">
                        <Tooltip title="Go back">
                            <IconButton aria-label="Return" size="small" color="secondary" onClick={this.handleClose}>
                                <Icon>arrow_back</Icon>
                            </IconButton>
                        </Tooltip>
                        <Typography variant="subtitle1" color="inherit" className="ml-16">
                            {t('VALIDATE')} {t('NEWCUSTOMERS')}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Card className="w-full">
                    <CardHeader
                        // style={{backgroundColor: '#3C4252'}}
                        title={debnrvalidation.debnaam}
                        subheader={debnrvalidation.debnr}
                    /> 
                    <Divider />
                    <CardContent className="p-12">
                        <div className="p-12">
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                {debnrvalidation.defkarloc === '' ? 
                                <Tooltip title={"Florisoft : debiteur > transport > locatie"}>
                                    <Icon color="action" style={{color: 'red', cursor: 'pointer'}}>clear</Icon>
                                </Tooltip>
                                :
                                <Tooltip title={"Florisoft : debiteur > transport > locatie"}>
                                    <Icon color="action" style={{color: 'green', cursor: 'pointer'}}>check</Icon>
                                </Tooltip>
                                }
                            </div>
                            <TextField
                                className="mb-24"
                                id="defkarloc"
                                name="defkarloc"
                                label={"Standaard karopbouw"}
                                value={debnrvalidation.defkarloc}
                                variant="outlined"
                                inputProps={{maxLength: 100}}
                                required
                                disabled
                                fullWidth
                            />
                        </div>
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                {debnrvalidation.dooslocatie === '' ? 
                                <Tooltip title={"Florisoft : debiteur > transport > locatie"}>
                                    <Icon color="action" style={{color: 'red', cursor: 'pointer'}}>clear</Icon>
                                </Tooltip>
                                :
                                <Tooltip title={"Florisoft : debiteur > transport > locatie"}>
                                    <Icon color="action" style={{color: 'green', cursor: 'pointer'}}>check</Icon>
                                </Tooltip>
                                }
                            </div>
                            <TextField
                                className="mb-24"
                                id="dooslocatie"
                                name="dooslocatie"
                                label={"Standaart doosinpak"}
                                value={debnrvalidation.dooslocatie}
                                variant="outlined"
                                inputProps={{maxLength: 100}}
                                required
                                disabled
                                fullWidth
                            />
                        </div>

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                {debnrvalidation.transpId === 0 ? 
                                <Tooltip title={"Florisoft : debiteur > transport"}>
                                    <Icon color="action" style={{color: 'red', cursor: 'pointer'}}>clear</Icon>
                                </Tooltip>
                                :
                                <Tooltip title={"Florisoft : debiteur > transport"}>
                                    <Icon color="action" style={{color: 'green', cursor: 'pointer'}}>check</Icon>
                                </Tooltip>
                                }
                            </div>
                            <TextField
                                className="mb-24"
                                id="transpId"
                                name="transpId"
                                label={"Transport route"}
                                value={debnrvalidation.transpId}
                                variant="outlined"
                                inputProps={{maxLength: 100}}
                                required
                                disabled
                                fullWidth
                            />
                        </div>

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                {debnrvalidation.inpakBproces === '' ? 
                                <Tooltip title={"Florisoft : debiteur > instelligen van"}>
                                    <Icon color="action" style={{color: 'red', cursor: 'pointer'}}>clear</Icon>
                                </Tooltip>
                                :
                                <Tooltip title={"Florisoft : debiteur > instelligen van"}>
                                    <Icon color="action" style={{color: 'green', cursor: 'pointer'}}>check</Icon>
                                </Tooltip>
                                }
                            </div>
                            <TextField
                                className="mb-24"
                                id="inpakBproces"
                                name="inpakBproces"
                                label={"instelligen van INPAKB"}
                                value={debnrvalidation.inpakBproces}
                                variant="outlined"
                                inputProps={{maxLength: 100}}
                                required
                                disabled
                                fullWidth
                            />
                        </div>

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                {debnrvalidation.doosvulfc === '' ? 
                                <Tooltip title={"Florisoft : debiteur > transport > Standaart fustcode"}>
                                    <Icon color="action" style={{color: 'red', cursor: 'pointer'}}>clear</Icon>
                                </Tooltip>
                                :
                                <Tooltip title={"Florisoft : debiteur > transport > Standaart fustcode"}>
                                    <Icon color="action" style={{color: 'green', cursor: 'pointer'}}>check</Icon>
                                </Tooltip>
                                }
                            </div>
                            <TextField
                                className="mb-24"
                                id="doosvulfc"
                                name="doosvulfc"
                                label={"Standaart fustcode"}
                                value={debnrvalidation.doosvulfc}
                                variant="outlined"
                                inputProps={{maxLength: 100}}
                                required
                                disabled
                                fullWidth
                            />
                        </div>

                        <div className="flex">
                            <div className="min-w-48 pb-20">
                            <Switch
                                checked={this.state.welcomediscount}
                                onChange={this.handleSwitch}
                                color="primary"
                                name="Welcome discount"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                label={'Welcome discount'}
                            />
                            </div>
                            <div className="p-12">
                                <p className="mb-24">{t("WELCOME_DISCOUNT")}</p>
                            </div>
                        </div>

                        {debnrvalidation.defkarloc === '' || debnrvalidation.transpId === 0 || debnrvalidation.inpakBproces === '' || debnrvalidation.doosvulfc === ''?
                            <div className="w-full pb-12 mb-12">
                                <Alert className="w-full" severity="warning">
                                    {debnrvalidation.defkarloc === '' ? <><b>Standaard karopbouw</b> (defkarloc) : debiteur &gt; transport &gt; locatie<br/></>: null}
                                    {/* {debnrvalidation.dooslocatie === '' ? <><b>Standaart doosinpak</b> (defdoosloc) : debiteur &gt; transport &gt; locatie<br/></>: null} */}
                                    {debnrvalidation.transpId === 0 ? <><b>Transport route</b> (transpId): debiteur &gt; transport<br/></>: null}
                                    {debnrvalidation.inpakBproces === '' ? <><b>Instelligen van</b> : debiteur &gt; instelligen van (proces : DebiteurArtikelGroepInstellingen)<br/></>: null}
                                    {debnrvalidation.doosvulfc === '' ? <><b>Standaart fustcode</b> (doosvulfc): debiteur &gt; transport (Doosvullen)<br/></>: null}
                                </Alert></div>
                            : 
                            null
                        }
                        
                        {debnrvalidation.defkarloc === '' || debnrvalidation.inpakBproces === '' || debnrvalidation.transpId === 0 ?
                            <div className="w-full pb-12 mb-12"><Alert className="w-full" severity="error">{t("NEWCUSTOMERSDIALOGINFO")}</Alert></div>
                            : 
                            null
                        }

                        {debnrvalidation.inactief === false ? 
                            <div className="w-full pb-12 mb-12"><Alert className="w-full" status="AlertSuccess">{t('CREATEDSUCCESS')}</Alert></div>
                            :
                            null
                        }
                        <div className="flex items-end">
                            <div className="p-6">
                                <Button onClick={this.handleClose} fullWidth variant="contained" color="secondary">{t('CANCEL')}</Button>
                            </div>
                            <div className="p-6">
                                <Button onClick={() => this.onActivateNewCustomer(debnrvalidation.debnr.trim(), this.state.welcomediscount)} 
                                fullWidth 
                                disabled={(!debnrvalidation.inactief && debnrvalidation.defkarloc === '' || debnrvalidation.inpakBproces === '' || debnrvalidation.transpId === 0  || debnrvalidation.doosvulfc === '')}
                                variant="contained" color="primary">{t('VALIDATE')}</Button>
                            </div>
                        </div>
                        </div>
                    </CardContent>
                </Card>
                </Dialog>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
         onActivateNewCustomer: (debnr, welcomediscount) => dispatch(actions.activateNewCustomer(debnr, welcomediscount)),
         onCreateUserLog: (userLog) => dispatch(actions.createUserLog(userLog))
    }
 }
 
 export default connect(null, mapDispatchToProps) (withTranslation()(ValidationDialog));
