import React from 'react';
import {withStyles, Icon, ListItem, ListItemText} from '@material-ui/core';
import {NavLink, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getRoles} from '../../../../app/main/Auth/Auth';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../../app/store/actions';
import FuseNavBadge from './../FuseNavBadge';

const propTypes = {
    item: PropTypes.shape(
        {
            id   : PropTypes.string.isRequired,
            title: PropTypes.string,
            icon : PropTypes.string,
            url  : PropTypes.string
        })
};

const defaultProps = {};

const styles = theme => ({
    item: {
        height                     : 40,
        width                      : 'calc(100% - 16px)',
        borderRadius               : '0 20px 20px 0',
        paddingRight               : 12,
        '&.active'                 : {
            backgroundColor            : theme.palette.secondary.main,
            color                      : theme.palette.secondary.contrastText + '!important',
            pointerEvents              : 'none',
            transition                 : 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
            '& .list-item-text-primary': {
                color: 'inherit'
            },
            '& .list-item-icon'        : {
                color: 'inherit'
            }
        },
        '&.square, &.active.square': {
            width       : '100%',
            borderRadius: '0'
        },
        '& .list-item-icon'        : {},
        '& .list-item-text'        : {padding: '0 16px'},
        '& .list-item-text:first-child'        : {paddingLeft: '0'},
        color                      : '#fff',
        textDecoration             : 'none!important'
    }
});



function FuseNavVerticalItem({item, classes, nestedLevel, navbarCloseMobile, active})
{
    const userRoles = getRoles();
    if ( item.auth && (!userRoles || !userRoles.some(role => item.auth.includes(role))))
    {
        return null;
    }
    const to = item.url;
    const renderLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} to={to} {...props} />)

    let paddingValue = 40 + (nestedLevel * 16);
    const listItemPadding = nestedLevel > 0 ? 'pl-' + (paddingValue > 80 ? 80 : paddingValue) : 'pl-24';

    return (
        <ListItem
            button
            component={renderLink}
            to={item.url}
            activeClassName="active"
            className={[classes.item, listItemPadding, active].join(' ')}
            onClick={navbarCloseMobile}
            exact={item.exact}
        >
            {item.icon && (
                <Icon className="list-item-icon text-16 flex-no-shrink" color="action">{item.icon}</Icon>
            )}
            <ListItemText className="list-item-text" primary={item.title} classes={{primary: 'text-14 list-item-text-primary'}}/>
            {item.badge && (
                <FuseNavBadge badge={item.badge}/>
            )}
        </ListItem>
    );
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        navbarCloseMobile: Actions.navbarCloseMobile
    }, dispatch);
}

FuseNavVerticalItem.propTypes = propTypes;
FuseNavVerticalItem.defaultProps = defaultProps;

const NavVerticalItem = withStyles(styles, {withTheme: true})(withRouter(connect(null, mapDispatchToProps)(FuseNavVerticalItem)));

export default NavVerticalItem;
