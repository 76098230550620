import * as jwt_decode from 'jwt-decode';
import Store from "../../store";

export async function signOut() {
  localStorage.clear();
}

export function getAccount() {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken && accessToken !== 'undefined') {
    var user = jwt_decode(accessToken);
    if (Date.now() <= user.exp * 1000) {
      return user;
    }
  }
  return false;
}

export function getAccessToken() {
  return localStorage.getItem('access_token');
}

export function getRefreshToken() {
  return localStorage.getItem('refresh_token');
}


// export async function signOut() {
//     localStorage.removeItem('access_token');
//     localStorage.removeItem('refresh_token');
//     // localStorage.clear();
// }


export function getRoles() {
  if (getAccessToken()) {
    var roles = jwt_decode(getAccessToken()).roles;
    if (Array.isArray(roles)) {
      return roles;
    } else if (typeof (roles) === 'string' && roles.length > 0) {
      return [roles];
    } else {
      return []
    }
  } else {
    return [];
  }
}

export function getUserEmail() {
  if (getAccessToken()) {
    return jwt_decode(getAccessToken()).preferred_username;
  } else {
    return '';
  }
}

export function getEmail() {
  if (getAccessToken()) {
    return jwt_decode(getAccessToken()).preferred_username;
  } else {
    return '';
  }
}

export function getUsername() {
  if (getAccessToken()) {
    return jwt_decode(getAccessToken()).userName;
  } else {
    return '';
  }
}

export function getAzureId() {
  if (getAccessToken()) {
    return jwt_decode(getAccessToken()).azureId;
  } else {
    return '';
  }
}

export function getLanguage() {
  if (localStorage.getItem('i18n')) {
    return localStorage.getItem('i18n');
  } else {
    return undefined;
  }
}

// Check Roles

export function checkRole(role) {
  return getRoles().includes(role);
}

export function getCompany() {
  return Store.getState().flortalAuth.user.companyId;
}

export function getGroupcode() {
  return jwt_decode(getAccessToken()).groupCode;
}