// export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';

export const FETCH_CREDITS_SUCCESS = 'FETCH_CREDITS_SUCCESS';

export const FETCH_PRELEVEMENTS_SUCCESS = 'FETCH_PRELEVEMENTS_SUCCESS';
export const FETCH_PRELEVEMENTS_START = 'FETCH_PRELEVEMENTS_START';

export const GENERATE_PRELEVEMENT_SUCCESS = 'GENERATE_PRELEVEMENT_SUCCESS';
// export const GENERATE_PRELEVEMENT_START = 'GENERATE_PRELEVEMENT_START';
export const GENERATE_RABOBANK_PRELEVEMENT_SUCCESS = 'GENERATE_RABOBANK_PRELEVEMENT_SUCCESS';

export const SEND_PRELEVEMENT_SUCCESS = 'SEND_PRELEVEMENT_SUCCESS';
export const PRELEVEMENT_START_LOADER = 'PRELEVEMENT_START_LOADER';
export const PRELEVEMENT_SWITCH_COMPANY = 'PRELEVEMENT_SWITCH_COMPANY';

export const FETCH_ASSIGNNEWLEADS_START = 'FETCH_ASSIGNNEWLEADS_START';
export const FETCH_ASSIGNNEWLEADS_SUCCESS = 'FETCH_ASSIGNNEWLEADS_SUCCESS';
export const FETCH_ALL_DEBTYPE_SUCCESS = 'FETCH_ALL_DEBTYPE_SUCCESS';

export const FETCH_RETURNED_NEWLEADS_SUCCESS = 'FETCH_RETURNED_NEWLEADS_SUCCESS';
export const FETCH_EXPORTNEWLEADS_SUCCESS = 'FETCH_EXPORTNEWLEADS_SUCCESS';
export const FETCH_OVERVIEWNEWLEADS_SUCCESS = 'FETCH_OVERVIEWNEWLEADS_SUCCESS';
export const ACTIVATE_NEW_LEAD_SUCCESS = 'ACTIVATE_NEW_LEAD_SUCCESS';
export const FETCH_ICRMUSERS_ASSIGNNEWLEADS_SUCCESS = 'FETCH_ICRMUSERS_ASSIGNNEWLEADS_SUCCESS';
export const FETCH_FAV_ICRMUSERS_ASSIGNNEWLEADS_SUCCESS = 'FETCH_FAV_ICRMUSERS_ASSIGNNEWLEADS_SUCCESS';
export const ADD_FAV_USERS_ASSIGNNEWLEADS_SUCCESS = 'ADD_FAV_USERS_ASSIGNNEWLEADS_SUCCESS';
export const DELETE_FAV_USERS_ASSIGNNEWLEADS_SUCCESS = 'DELETE_FAV_USERS_ASSIGNNEWLEADS_SUCCESS';
export const GENERATE_EXPORT_NEWLEADS_SUCCESS = 'GENERATE_EXPORT_NEWLEADS_SUCCESS';
export const GENERATE_EXACT_EXPORT_NEWLEADS_SUCCESS = 'GENERATE_EXACT_EXPORT_NEWLEADS_SUCCESS';
export const EDIT_NEWLEAD_SUCCESS = 'EDIT_NEWLEAD_SUCCESS';
export const CHECK_VIES_SUCCESS = 'CHECK_VIES_SUCCESS';
export const FETCH_MULTIPLE_EMAIL_NEWLEADS_SUCCESS = 'FETCH_MULTIPLE_EMAIL_NEWLEADS_SUCCESS';
export const FETCH_MULTIPLE_VAT_NEWLEADS_SUCCESS = 'FETCH_MULTIPLE_VAT_NEWLEADS_SUCCESS';
