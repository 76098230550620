import React from 'react';
import {Line} from 'react-chartjs-2';

const lineChart = ( props ) => {
    const data = {
        labels: props.labels,
        datasets: props.datasets
    }
    return (
        <Line data={data} height={props.height} options={{maintainAspectRatio: props.maintainAspectRatio}} />
    )
}

export default lineChart;