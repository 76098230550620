import React from 'react';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

function createData(name, value) {
  return { name, value };
}

const TableHeaderCell = props => <TableCell style={{color: '#ffffff'}} {...props}>{props.children}</TableCell>

const getColorFromScale = percentage => {
    if(percentage >= 80) {
        return '#a80707';
    } else if(percentage < 80 && percentage >= 70) {
        return '#e01010';
    } else if(percentage < 70 && percentage >= 60) {
        return '#e04410';
    } else {
        return '#ffa500';
    }
}

export default function TaskDetailRetentionData({ info }) {
    const { t } = useTranslation();
    if(!info) {return null;}
    const fullRows = [
        // createData(t('risk_of_leaving'), 85),
        createData(t('risk_of_leaving'), info.riskOfLeaving * 100),
        createData(t('days_since_last_order'), `${moment(info.lastOrderDate).format('YYYY-MM-DD')} (${moment().diff(moment(info.lastOrderDate), 'days')} days ago)` ),
        createData(t('amount_of_orders'), info.aantalBestellingenTotaal),
        createData(t('avg_invoice_turnover'), info.gemiddeldeFactuurhoogteTotaal.toFixed(0)),
        createData(t('customer_since'), moment(info.klantSinds).format('YYYY-MM-DD')),
    ];

    return (
        <TableContainer component={Paper} style={{borderRadius: '.8rem'}}>
            <Table aria-label="simple table">
                <TableHead style={{backgroundColor: '#3C4252', height: 64}}>
                    <TableRow style={{backgroundColor: '#3C4252', height: 64}}>
                        <TableHeaderCell colSpan={2}><Typography>{t("BIINFORMATIONS")}</Typography></TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {fullRows.map((row) => (
                    <TableRow key={row.name}>
                        <TableCell>
                            {row.name}
                        </TableCell>
                        <TableCell align="right" style={{minWidth: 75}}>                            
                            {row.name === t('risk_of_leaving') ? <Typography variant="h6" style={{color: getColorFromScale(row.value)}}>{row.value.toFixed(1)} %</Typography> : row.value}
                           </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
