import React from 'react';
import QuickPanel from '../../../../app/fuse-layouts/shared-components/quickPanel/QuickPanel';

const RightSideLayout3 = () => {
    return (
        <React.Fragment>
            <QuickPanel/>
        </React.Fragment>
    );
};

export default RightSideLayout3;
