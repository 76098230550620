import React, { Component } from 'react';
import {Checkbox} from '@material-ui/core';
import Scrollbars from 'react-custom-scrollbars';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';

class PrelevementInvoices extends Component {

    shouldComponentUpdate(nextProps) {
        if(nextProps.checkedInvoices.length !== this.props.checkedInvoices.length &&
            _.xor(this.props.checkedInvoices, nextProps.checkedInvoices).some(ci => this.props.invoices.map(invoice => invoice.invoice).includes(ci))) {
                return true;
        }
        return false;
    }

    render() {
        const {invoices, isInvoiceChecked, handleInvoiceCheck, t} = this.props;
        return (
        <Scrollbars autoHeight autoHeightMax={144}>
        {invoices.map(invoice => (
            <div className="w-full" key={invoice.invoice}>
            <Checkbox
                checked={isInvoiceChecked(invoice.invoice)}
                onChange={handleInvoiceCheck(invoice.invoice)}
                value="checkedA"
                inputProps={{
                'aria-label': 'primary checkbox',
                }}
            />
            <b>{t("INVOICE")}</b> : {invoice.sumToPay} € - n° {invoice.invoice} - <b>{t("DATE")}</b> {invoice.invoiceDate}
            </div>
        ))}
        </Scrollbars>
        )
    }
}

export default withTranslation()(PrelevementInvoices);