import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../../store/crm/actions';
import * as wholesaleActions from '../../../../store/wholesale/actions';
import { withTranslation } from 'react-i18next';
import { getCompany, getEmail, getUsername } from '../../../Auth/Auth';

import LineChart from '../../../../components/UI/Charts/RctJSCharts/LineChart/LineChart';

import {
    Card, IconButton, Paper, Button, withStyles, Icon, Tooltip,
    Table, TableBody, TableCell, TableHead, TableRow,
    Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import themesConfig from '../../../../fuse-configs/themesConfig';

import CustomerWeblogVisits from './WholesaleCharts/WholesaleWeblogVisits';

import { FuseAnimateGroup, FusePageSimple } from '../../../../../@fuse';

import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import lightBlue from '@material-ui/core/colors/lightBlue';
import WholesaleForm from './WholesaleForm/WholesaleForm';
import _ from 'lodash';
import Axios from 'axios';

const styles = theme => ({
    bgHasDay: {
        backgroundColor: '#dff0d8',
    },
    successButton: {
        color: '#fff',
        backgroundColor: green[600],
        '&:hover': {
            color: '#fff',
            backgroundColor: green[700]
        }
    },
    dangerButton: {
        color: '#fff',
        backgroundColor: '#ee3939',
        '&:hover': {
            backgroundColor: red[700]
        }
    },
    infoButton: {
        color: '#fff',
        backgroundColor: lightBlue[500],
        '&:hover': {
            backgroundColor: lightBlue[700]
        }
    },
    aColor: {
        color: '#ffffff !important'
    },
})

const initErrors = {
    companyName: null,
    mailAddresses: null,
    webshopPassword: null,
}

class Customer extends Component {

    state = {
        isEditingForm: false,
        customerForm: {
            companyName: '',
            contacts: [],
            webshopPassword: '',
            inetCheck: true,
            emails: [],
        },
        insertedCustomer: false,
        errors: {
            ...initErrors
        },
        submittedSave: false,
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.debnr !== this.props.match.params.debnr) {
            this.loadCustomerData(nextProps.match.params.debnr);
        }
    }

    componentDidMount() {
        this.loadCustomerData(this.props.match.params.debnr);
    }

    componentDidUpdate(prevProps, prevState) {
        const { customer } = this.props;
        console.log(this.state.insertedCustomer);
        if (Object.keys(customer).length > 0 && !this.state.insertedCustomer && !this.props.loadingSubcode) {
            this.setState({
                customerForm: {
                    companyName: customer.name,
                    contacts: customer.contactEmails,
                    webshopPassword: customer.password,
                    inetCheck: customer.inetCheck,
                    emails: customer.email.split(';').map(e => e.trim())
                },
                insertedCustomer: true
            });
        }
    }

    loadCustomerData = debnr => {
        this.props.onFetchCustomer(debnr);
        this.props.onFetchDeparture(debnr);
        this.props.onFetchWeblogVisits(debnr);
    }

    handleSubmitOpenOrClose = () => {
        const { debnr, levStop, finDebnr } = this.props.customer;
        this.props.onSubmitOpenOrClose(debnr, levStop, false, finDebnr, getEmail(), getUsername());
    }

    handleFormChange = event => {
        this.setState({ customerForm: { ...this.state.customerForm, [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value } });
    }

    handleContactAdd = () => {
        const contacts = [...this.state.customerForm.contacts];
        const lastContact = contacts[contacts.length - 1];
        contacts.push({
            rowNumber: lastContact ? lastContact.rowNumber + 1 : 1,
            nieuweEmail: '',
            oudeEmail: ''
        })
        console.log(contacts);
        this.setState({ customerForm: { ...this.state.customerForm, contacts } });
    }

    handleContactsChange = (rowNumber, value) => {
        const contacts = [...this.state.customerForm.contacts];
        const contactIndex = contacts.findIndex(c => c.rowNumber === rowNumber);
        if (contactIndex > -1) {
            let editedContact = { ...contacts[contactIndex] };
            editedContact.nieuweEmail = value;
            contacts[contactIndex] = editedContact;
            this.setState({ customerForm: { ...this.state.customerForm, contacts } });
        }
    }

    handleContactDelete = (rowNumber) => {
        const contacts = [...this.state.customerForm.contacts];
        const contactIndex = contacts.findIndex(c => c.rowNumber === rowNumber);
        if (contactIndex > -1) {
            let editedContact = { ...contacts[contactIndex] };
            if (editedContact.oudeEmail === '') {
                contacts.splice(contactIndex, 1);
            } else {
                editedContact.nieuweEmail = 'deleted';
                contacts[contactIndex] = editedContact;
            }
            console.log(contacts);
            this.setState({ customerForm: { ...this.state.customerForm, contacts } });
        }
    }

    handleFormSubmit = () => {
        const { customerForm } = this.state;
        const { customer: initCustomer } = this.props;
        const newErrors = { ...initErrors };
        const updatableContacts = customerForm.contacts.filter(c => c.nieuweEmail !== 'deleted');
        if (customerForm.companyName.length === 0) {
            newErrors.companyName = 'Vul een bedrijfsnaam in';
        }
        if (updatableContacts.some(c => !c.nieuweEmail.includes('@')) || updatableContacts.some(c => !c.nieuweEmail.includes('.'))) {
            newErrors.mailAddresses = 'Er is een ongeldig emailadres ingevuld';
        }
        if (customerForm.webshopPassword.length === 0) {
            newErrors.webshopPassword = 'Vul een emailadres in';
        }
        if (newErrors.companyName || newErrors.mailAddresses || newErrors.webshopPassword) {
            this.setState({ errors: newErrors });
        } else {
            const dataToSubmit = {
                debnr: initCustomer.debnr,
                ...customerForm,
            }
            this.props.onSubmitForm(dataToSubmit);
            this.setState({ isEditingForm: false, submittedSave: true, errors: { ...initErrors } });
        }
    }

    removePrimaryEmail = (index) => {
        const emails = [...this.state.customerForm.emails];
        emails.splice(index, 1);
        this.setState({ customerForm: { ...this.state.customerForm, emails } });
        const updatedObject = { newPrimaryEmail: emails.join(';'), debnr: this.props.customer.debnr };
        Axios.post('wholesale/updateprimaryemail', updatedObject);
    }

    render() {
        const { classes, customer: customerData, t } = this.props;
        const themeColors = themesConfig.default.colors;

        let departureDataset = [];
        if (this.props.departure.history) {
            departureDataset = [{
                label: 'Departure',
                fill: false,
                lineTension: 0.5,
                backgroundColor: themeColors[0],
                borderColor: themeColors[0],
                pointRadius: 4,
                pointHoverRadius: 5,
                data: this.props.departure.history.timesOrdered
            }];
        }

        let departureTable = null;
        if (this.props.departure.previousDays) {
            const departureDays = this.props.departure.previousDays;
            const availableDays = this.props.departure.availableDays;

            departureTable = (
                <div className="table-responsive">
                    <Table>
                        <TableHead>
                            <TableRow hover>
                                <TableCell>Week</TableCell>
                                <TableCell className={availableDays[2] ? classes.bgHasDay : null}>M</TableCell>
                                <TableCell className={availableDays[3] ? classes.bgHasDay : null}>T</TableCell>
                                <TableCell className={availableDays[4] ? classes.bgHasDay : null}>W</TableCell>
                                <TableCell className={availableDays[5] ? classes.bgHasDay : null}>T</TableCell>
                                <TableCell className={availableDays[6] ? classes.bgHasDay : null}>F</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <Fragment>
                                {departureDays.map((val, i) => {
                                    return (
                                        <TableRow hover key={i}>
                                            <TableCell style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{val.Week}</TableCell>
                                            <TableCell>{val.Monday ? <Icon className="text-18" style={{ color: 'green' }}>check</Icon> : null}</TableCell>
                                            <TableCell>{val.Tuesday ? <Icon className="text-18" style={{ color: 'green' }}>check</Icon> : null}</TableCell>
                                            <TableCell>{val.Wednesday ? <Icon className="text-18" style={{ color: 'green' }}>check</Icon> : null}</TableCell>
                                            <TableCell>{val.Thursday ? <Icon className="text-18" style={{ color: 'green' }}>check</Icon> : null}</TableCell>
                                            <TableCell>{val.Friday ? <Icon className="text-18" style={{ color: 'green' }}>check</Icon> : null}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </Fragment>
                        </TableBody>
                    </Table>
                </div>
            );
        }

        let OldShopButtonClass = classes.dangerButton;
        let ShopButtonClass = classes.dangerButton;
        if (customerData && customerData.inetCheck && !customerData.levStop) {
            OldShopButtonClass = classes.infoButton;
            ShopButtonClass = classes.successButton;
        }
        return (
            <FusePageSimple header={customerData ?
                <div className="flex flex-1 items-center justify-between p-8 sm:p-24">
                    <div className="w-1/2">
                        <Typography variant="h6">{customerData.name}</Typography>
                        <Typography color="textSecondary" className="text-12">{customerData.debnr} - {customerData.password}</Typography>
                        <Typography color="textSecondary" className="text-12">{customerData.address}, {customerData.zipcode} {customerData.city}</Typography>
                        <small>
                        </small>
                    </div>

                    <div className="flex flex-1 items-center justify-end px-12">
                        <Tooltip title="Webshop 3">
                            <a href={`${customerData.webshopLink}/Account/LoginByUrl?user=${customerData.debnr}&pass=${customerData.password}`}
                                className={classes.aColor + " pull-right"} target="_blank" rel="noopener noreferrer">
                                <IconButton className={ShopButtonClass + " m-8"}>
                                    {!customerData.inetCheck ? <Icon className="text-16" color="action">not_interested</Icon> : null}
                                    {customerData.inetCheck && customerData.levStop ? <Icon className="text-16" color="action">lock</Icon> : null}
                                    {customerData.inetCheck && !customerData.levStop ? <Icon className="text-16" color="action">shopping_cart</Icon> : null}
                                </IconButton>
                            </a>
                        </Tooltip>
                        {getCompany() === 1 ?
                            <Tooltip title="Webshop 2">
                                <a href={`http://shop.flowerwebshop.net/webshop2/Login.aspx?user=${customerData.debnr}&pass=${customerData.password}`}
                                    className={classes.aColor + " pull-right"} target="_blank" rel="noopener noreferrer">
                                    <IconButton className={OldShopButtonClass + " m-8"}>
                                        {!customerData.inetCheck ? <Icon className="text-16" color="action">not_interested</Icon> : null}
                                        {customerData.inetCheck && customerData.levStop ? <Icon className="text-16" color="action">lock</Icon> : null}
                                        {customerData.inetCheck && !customerData.levStop ? <Icon className="text-16" color="action">shopping_cart</Icon> : null}
                                    </IconButton>
                                </a>
                            </Tooltip> : null}

                    </div>
                </div>
                : null
            }
                content={
                    !this.props.loadingSubcode && Object.keys(this.props.customer).length !== 0 && !this.props.customer.debnr ? (
                        <Alert severity="error">De klant kan niet worden geladen of behoort niet tot je toegewezen klanten.</Alert>
                    )
                        :
                        (
                            <FuseAnimateGroup className="p-12"
                                enter={{
                                    animation: "transition.slideUpBigIn"
                                }}>
                                <div className="flex flex-wrap-reverse">
                                    <div className="w-full sm:w-full md:w-1/2 lg:w-1/2">
                                        <div className="p-12">
                                            <Paper className="w-full rounded-8 shadow-none border-1 p-12" elevation={1}>
                                                {this.props.departure.history ? <LineChart labels={this.props.departure.history.chartLabel} datasets={departureDataset} height={330} maintainAspectRatio={false} /> : null}
                                            </Paper>
                                        </div>
                                        <div className="p-12">
                                            <Paper className="w-full rounded-8 shadow-none border-1" elevation={1}>
                                                {this.props.departure.previousDays ? departureTable : null}
                                            </Paper>
                                        </div>
                                    </div>
                                    <div className="w-full sm:w-full md:w-1/2 lg:w-1/2" style={{ height: 'auto' }}>
                                        <div className="p-12">
                                            <Card className="w-full rounded-8 shadow-none border-1 p-24">
                                                {/* {customer} */}
                                                <WholesaleForm
                                                    form={this.state.customerForm}
                                                    errors={this.state.errors}
                                                    onChangeForm={this.handleFormChange}
                                                    onEmailAdd={this.handleContactAdd}
                                                    onEmailChange={this.handleContactsChange}
                                                    onEmailDelete={this.handleContactDelete}
                                                    onPrimaryEmailDelete={this.removePrimaryEmail}
                                                    isEditing={this.state.isEditingForm}
                                                />
                                                <div className="mt-8">
                                                    {this.state.isEditingForm ?
                                                        <Button color="primary" variant="contained" onClick={this.handleFormSubmit}>{t("SAVE")}</Button>
                                                        :
                                                        <Button color="secondary" variant="contained" onClick={() => this.setState({ isEditingForm: true })}>{t("EDIT")}</Button>
                                                    }
                                                </div>
                                                {
                                                    this.props.savedSubcode && this.state.submittedSave && !this.props.loading ?
                                                        <Alert className="mt-16" severity="success">{t("UPDATEDSUCCESS")}</Alert>
                                                        : null
                                                }
                                                {
                                                    !this.props.savedSubcode && this.state.submittedSave && !this.props.loading ?
                                                        <Alert className="mt-16" severity="error">{t("UPDATEDFAILED")}</Alert>
                                                        : null
                                                }
                                            </Card>
                                        </div>
                                        <div className="p-12">
                                            <Paper className="w-full rounded-8 shadow-none border-1 p-12" elevation={1}>
                                                {this.props.weblogVisits.length > 0 ? <CustomerWeblogVisits weblogVisits={this.props.weblogVisits} themeColors={themeColors} /> : null}
                                            </Paper>
                                        </div>
                                    </div>
                                </div>
                            </FuseAnimateGroup>
                        )
                }
            />
        );
    }
}

const mapStateToProps = ({ wholesale, customer }) => {
    return {
        loading: wholesale.loading,
        loadingSubcode: wholesale.loadingSubcode,
        customer: wholesale.subcode,
        savedSubcode: wholesale.savedSubcode,
        departure: customer.departure,
        weblogVisits: customer.weblogVisits,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchCustomer: (debnr) => dispatch(wholesaleActions.getSubCode(debnr)),
        onFetchDeparture: (debnr) => dispatch(actions.fetchDbCustomerDeparture(debnr)),
        onFetchWeblogVisits: (debnr) => dispatch(actions.fetchCustomerWeblogVisits(debnr)),
        onSubmitOpenOrClose: (debnr, levStop, shouldUpdateAllInFinancialGroup, finDebnr, email, name) =>
            dispatch(actions.submitOpenOrCloseCustomer(debnr, levStop, shouldUpdateAllInFinancialGroup, finDebnr, email, name)),
        onSubmitForm: (customerData) => dispatch(wholesaleActions.saveWholesaleCustomer(customerData)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(withTranslation()(Customer))));