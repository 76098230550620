import React, { Component } from 'react';
import { Paper, TextField, withStyles, Button, Icon, Tooltip, Typography, FormControl, InputLabel, Select, MenuItem,
Box, LinearProgress, Badge } from '@material-ui/core';
import { FusePageSimple, FuseAnimateGroup } from '../../../../../@fuse'
import {connect} from 'react-redux';
import * as actions from '../../../../store/crm/manager/actions';
import { createUserLog } from '../../../../store/crm/actions';
import PrelevementCard from './PrelevementCard';
import {withTranslation} from 'react-i18next';
import SpecialOfferSkeleton from './PrelevementSkeleton';
import Alert from '../../../../components/UI/Alert/Alert';
import moment from 'moment';
import { getAzureId } from '../../../Auth/Auth';
import _ from 'lodash';

const styles = theme => ({
    textField: {
      margin: '1rem',
    },
    datePicker: {
        minWidth: '155px',
    },
    floatRight: {
        float: 'right',
        marginLeft: '4px',
    },
    button: {
        color: 'rgb(225, 0, 80)',
    },
    infoButton: {
        color: '#23b7e5',
        border: '1px solid #23b7e5'
    },
    successButton: {
        color: '#27c24c !important'
    },
    secondaryButton: {
        color: '#ef6c00 !important',
        border: '1px solid #ef6c00'
    }
})

class Prelevement extends Component {

    state = {
        headerInputs: {
            // date: "",
            date: new Date().toISOString().slice(0, 10),
        },
        invoiceInputs: {
            company: 22,
            debnr: '',
            minBettermijn: 0,
            maxBettermijn: 7,
        },
        prelevements: [],
        marionettePrelevements: [],
        checkedInvoices: [],
    }

    componentDidMount() {
        const {invoiceInputs} = this.state;
        this.props.onFetchPrelevement({company: invoiceInputs.company, minBetaalTermijn: invoiceInputs.minBettermijn, maxBetaalTermijn: invoiceInputs.maxBettermijn});
        setTimeout(() => {
            this.props.onFetchPrelevement({company: 23, minBetaalTermijn: invoiceInputs.minBettermijn, maxBetaalTermijn: invoiceInputs.maxBettermijn});
        }, 4000);
        
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.invoiceInputs.debnr === '' && !_.isEqual(this.props.prelevements, this.state.prelevements)) {
            this.setState({prelevements: this.props.prelevements});
        }
        if(this.state.invoiceInputs.debnr === '' && !_.isEqual(this.props.marionettePrelevements, this.state.marionettePrelevements)) {
            this.setState({marionettePrelevements: this.props.marionettePrelevements});
        }
    }

    handleHeaderInputChange = event => {
        this.setState({headerInputs: {...this.state.headerInputs, date: event.target.value}})
    }

    handleInvoiceInputChange = name => event => {
        this.setState({invoiceInputs: {...this.state.invoiceInputs, [name]: event.target.value}});
        if(name === 'company') {
            this.setState({checkedInvoices: []});
            this.props.onCompanySwitchinPrelevement();
        }
        if(name === 'debnr') {
            if(this.state.invoiceInputs.company === 22) {
                const prelevements = this.props.prelevements
                    .filter(p => p.debnr.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1);
                this.setState({prelevements});
            } else {
                const marionettePrelevements = this.props.marionettePrelevements
                .filter(p => p.debnr.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1);
                this.setState({marionettePrelevements});
            }
        }
    }

    handleInvoiceCheck = invoice => event => {
        const checkedInvoices = [...this.state.checkedInvoices];
        if(event.target.checked)  {
            checkedInvoices.push(invoice);
        } else {
            const index = checkedInvoices.indexOf(invoice);
            checkedInvoices.splice(index, 1);
        }
        this.setState({checkedInvoices: checkedInvoices});
    }

    isInvoiceChecked = invoice => {
        return this.state.checkedInvoices.indexOf(invoice) !== -1;
    }

    handleApplyClick = () => {
        const {invoiceInputs} = this.state;
        invoiceInputs.company === 22 ? this.setState({prelevements: []}) : this.setState({marionettePrelevements: []});
        this.setState({checkedInvoices: []});
        this.props.onFetchPrelevement({company: invoiceInputs.company, minBetaalTermijn: invoiceInputs.minBettermijn, maxBetaalTermijn: invoiceInputs.maxBettermijn});
        this.setState({checkedInvoices: []});
    }

    // Button handlers
    handleSelectAllClick = () => {
        const prelevements = this.state.invoiceInputs.company === 22 ? this.state.prelevements : this.state.marionettePrelevements;
        const allInvoices = [];
        for(let i = 0; i < prelevements.length; i++) {
            for(let n = 0; n < prelevements[i].invoices.length; n++) {
                allInvoices.push(prelevements[i].invoices[n].invoice);
            }
        }
        this.setState({checkedInvoices: allInvoices})
    }

    handleUnselectAllClick = () => {
        this.setState({checkedInvoices: []});
    }

    handlePreSelectClick = () => {
        const prelevements = this.state.invoiceInputs.company === 22 ? this.state.prelevements : this.state.marionettePrelevements;
        const checkedInvoices = [];
        for(let i = 0; i < prelevements.length; i++) {
            const prelevement = prelevements[i];
            const dates = this.getDatesForPreselect(prelevement.betaalTermijn);
            for(let n = 0; n < prelevement.invoices.length; n++) {
                const invoice = prelevements[i].invoices[n];
                const invoiceDate = new Date(invoice.invoiceDate);
                // console.log(prelevements[i].invoices[n]);
                if(invoiceDate >= dates.firstday && invoiceDate <= dates.lastday) {
                    checkedInvoices.push(invoice.invoice);
                }
            }
        }
        this.setState({checkedInvoices});
    }

    getDatesForPreselect = (betaalTermijn) => {
        var curr = new Date(); // get current date
        const firstdaytest = moment(curr).add(-betaalTermijn, 'days').weekday(1);
        const lastdaytest = firstdaytest.clone().add(6, 'days');
        // console.log(firstdaytest.toDate(), lastdaytest.toDate());


        // curr.setDate(curr.getDate() - betaalTermijn)
        // var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        // var last = first + 6; // last day is the first day + 6

        // var firstday = new Date(curr.setDate(first));
        // var lastday = new Date(curr.setDate(last));
        // console.log(firstday, lastday);
        // return {firstday, lastday};
        return {firstday: firstdaytest.toDate(), lastday: lastdaytest.toDate()};
    }

    createUserLog = (actionName) => {
        this.props.onCreateUserLog({
            azureId: getAzureId(),
            type: 'button',
            actionName,
            extraInfo: 'CHOSEN DATE ' + moment(this.state.headerInputs.date).format('YYYY-MM-DD')
        });
    }

    handleGenerateClick = () => {
        if(this.state.checkedInvoices.length > 0) {
            const generatePrelevementDto = {invoices: [...this.state.checkedInvoices], company: this.state.invoiceInputs.company, datum: this.state.headerInputs.date};
            this.props.onGenerateAndDownloadPrelevementExcel(generatePrelevementDto);
        }
        this.createUserLog('GeneratePrelevement');
    }

    handleGenerateRabobankClick = () => {
        if(this.state.checkedInvoices.length > 0) {
            const generatePrelevementDto = {invoices: [...this.state.checkedInvoices], company: this.state.invoiceInputs.company, datum: this.state.headerInputs.date};
            this.props.onGenerateAndDownloadPrelevementXml(generatePrelevementDto);
        }
        this.createUserLog('GenerateRabobankPrelevement');
    }

    handleSendClick = () => {
        if(this.state.checkedInvoices.length > 0) {
            const prelevementDate = this.state.headerInputs.date
            const prelevements = this.state.invoiceInputs.company === 22 ? this.state.prelevements : this.state.marionettePrelevements
            let prelevementsToSend = prelevements.filter(p => p.invoices.some(i => this.state.checkedInvoices.includes(i.invoice)));
            prelevementsToSend = prelevementsToSend.map(p => {
                const invoices = p.invoices.filter(i => this.state.checkedInvoices.includes(i.invoice));
                return {
                    ...p,
                    prelevementDate,
                    invoices,
                    toPay: invoices.reduce((previousValue, currentValue) => (previousValue += currentValue.sumToPay), 0)
                }
            });
            // const generatePrelevementDto = {factArr: [...this.state.checkedInvoices], company: this.state.invoiceInputs.company};
            // const generatePrelevementDto = {customers: this.state.prelevements, factArr: [...this.state.checkedInvoices], company: this.state.invoiceInputs.company};
            this.props.onSendPrelevement(prelevementsToSend);
        }
        this.createUserLog('SendPrelevement');
    }

    handleFocus = (event) => event.target.select();

    render() {
        const {classes, t} = this.props;
        const prelevements = this.state.invoiceInputs.company === 22 ? this.state.prelevements : this.state.marionettePrelevements;
        // console.log(this.state.checkedInvoices.length);
        // console.log(prelevements.length !== 0 );
        // console.log(prelevements, prelevements.length !== 0 );
        // console.log(this.state.headerInputs.date);
        return (
        <FusePageSimple
            header={                
                <div className="flex flex-1 items-center justify-between p-24">
                    <div className="flex flex-col">
                        <div className="flex items-center mb-16">
                            <Typography variant="h6">{t("PRELEVEMENT")}</Typography>
                        </div>
                        <TextField
                            id="date"
                            label={t("DATE")}
                            type="date"
                            defaultValue={new Date().toISOString().slice(0, 10)}
                            onChange={this.handleHeaderInputChange}
                            className={classes.datePicker}
                            InputLabelProps={{
                                shrink: true,
                            }} 
                        />
                    </div>
                    <div className="flex items-end">
                        <Tooltip title="Select all">
                            <Button onClick={this.handleSelectAllClick}>
                                <Icon>check_box</Icon>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Unselect all">
                            <Button onClick={this.handleUnselectAllClick}>
                                <Icon>check_box_outline_blank</Icon>
                            </Button>
                        </Tooltip>
                            
                        <Tooltip title="Pre-selection">
                        <Badge badgeContent={this.state.checkedInvoices.length} color="secondary">
                            <Button onClick={this.handlePreSelectClick}>
                                <Icon>check</Icon>
                            </Button>
                        </Badge>
                        </Tooltip>
                        <Tooltip className="ml-20" title="Download Turbo Suite Excel">
                            <Button variant="outlined" className={classes.infoButton} onClick={this.handleGenerateClick}>
                                <Icon>cloud_download</Icon> <span className="ml-8">Excel</span>
                            </Button>
                        </Tooltip>
                        <Tooltip className="ml-8" title="Generate XML">
                            <Button variant="outlined" className={classes.secondaryButton} onClick={this.handleGenerateRabobankClick}>
                                <Icon>cloud_download</Icon> <span className="ml-8">XML</span>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Send mail">
                            <Button className={classes.button} onClick={this.handleSendClick}>
                                <Icon>mail_outline</Icon>
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            }
            content ={
            <FuseAnimateGroup animation="transition.slideUpIn" delay={300}>
                    <Paper className="flex flex-wrap w-full" elevation={1}>
                        <div className="flex w-full md:w-1/5 sm:w-full">
                            <FormControl className={classes.textField} fullWidth>
                                <InputLabel htmlFor="company">{t("COMPANY")}</InputLabel>
                                <Select fullWidth
                                    value={this.state.invoiceInputs.company}
                                    onChange={this.handleInvoiceInputChange('company')}
                                >
                                    <MenuItem value={22}>van der Plas</MenuItem>
                                    <MenuItem value={23}>Marionette Fleurs</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex w-full md:w-1/5 sm:w-full">
                            <TextField
                                id="debnr"
                                label="Debnr"
                                value={this.state.invoiceInputs.debnr}
                                onChange={this.handleInvoiceInputChange('debnr')}
                                className={classes.textField}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }} 
                            />
                        </div>
                        <div className="flex w-full md:w-1/5 sm:w-full">
                            <TextField
                                id="standard-number"
                                label={"Min. " + t("PAYTERM")}
                                value={this.state.invoiceInputs.minBettermijn}
                                onChange={this.handleInvoiceInputChange('minBettermijn')}
                                onFocus={this.handleFocus}
                                type="number"
                                className={classes.textField}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                            />
                        </div>
                        <div className="flex w-full md:w-1/5 sm:w-full">
                            <TextField
                                id="standard-number"
                                label={"Max. " + t("PAYTERM")}
                                value={this.state.invoiceInputs.maxBettermijn}
                                onChange={this.handleInvoiceInputChange('maxBettermijn')}
                                onFocus={this.handleFocus}
                                type="number"
                                className={classes.textField}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                            />
                        </div>
                        <div className="flex w-full md:w-1/5 sm:w-full">
                            <Button variant="contained" color="primary" fullWidth className="m-16" onClick={this.handleApplyClick}>
                                {t("APPLY")}
                            </Button>
                        </div>
                    </Paper>
                <div className="p-12">
                    {prelevements.length !== 0 ? 
                        <>
                        {!this.props.generatedPrelevements ? null : 
                            <Alert className="w-full" title={
                                <>
                                    <p>{this.state.checkedInvoices.length} invoices selected</p>
                                    {console.log(prelevements)}
                                    {/* <p>{prelevements.filter(p => p.invoices.some(i => this.state.checkedInvoices.includes(i.invoice))).length} rows have been generated for the XML import !</p> */}
                                </>
                            } status="AlertInfo" />
                        }
                        <div className="flex flex-wrap w-full p-12">
                        {/* Show loader */}
                        {this.props.loading ? <>
                        <Box width="100%" mr={1}>
                            <LinearProgress color="primary" className="mb-12" />
                        </Box>
                        </> : null}
                        {/*  Show generated success */ }
                        {/*  Show email sent success */ }
                        {!this.props.sentPrelevements ? null : 
                            <div className="flex lg:w-1/2 sm:w-full">
                            <Alert title={
                            <>
                                <p>{this.props.sentEmailCallback.length} emails have been sent !</p>
                            </>
                            } status="AlertSuccess" />
                            </div>
                        }
                        </div>
                        {this.props.sentEmailCallback.length > 0 ?
                        <>
                        {/*  Show email sent result*/ }
                        {!this.props.sentPrelevements ? null : 
                        <div className="flex flex-wrap w-full p-12">
                            <Paper className="flex flex-wrap w-full p-12" elevation={1}>
                            <>
                                {this.props.sentEmailCallback.map(s => <div key={s} className="flex w-full lg:w-1/4 md:w-1/4 sm:w-1/2">{s}</div>)}
                            </>
                            </Paper>
                        </div>}
                        </>
                            : null
                        }
                        <FuseAnimateGroup animation="transition.slideUpIn" delay={300}>
                            <PrelevementCard prelevements={prelevements} isInvoiceChecked={this.isInvoiceChecked} handleInvoiceCheck={this.handleInvoiceCheck} 
                                checkedInvoices={this.state.checkedInvoices} />
                        </FuseAnimateGroup>
                        </>
                       : 
                       (
                           this.props.loadingPrelevements ? 
                            <div className="flex flex-wrap w-full">
                                    <SpecialOfferSkeleton />
                                    <SpecialOfferSkeleton />
                                    <SpecialOfferSkeleton />
                                    <SpecialOfferSkeleton />
                            </div>
                           :
                           <Typography>No results found</Typography>
                       )
                       }
                </div>
            </FuseAnimateGroup>
            }
         />
         );
    }
}

const mapStateToProps = ({manager}) => {
    return {
        prelevements: manager.prelevements,
        marionettePrelevements: manager.marionettePrelevements,
        GeneratedCallback: manager.GeneratedCallback,
        generatedPrelevements: manager.generatedPrelevements,
        sentEmailCallback: manager.sentEmailCallback,
        sentPrelevements: manager.sentPrelevements,
        loadingPrelevements: manager.loadingPrelevements,
        loading: manager.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchPrelevement: (infoForPrelevementDto) => dispatch(actions.fetchPrelevement(infoForPrelevementDto)),
        onGenerateAndDownloadPrelevementExcel: (generatePrelevementDto) => dispatch(actions.generateAndDownloadPrelevementExcel(generatePrelevementDto)),
        onGenerateAndDownloadPrelevementXml: (generatePrelevementDto) => dispatch(actions.generateAndDownloadPrelevementXml(generatePrelevementDto)),
        onSendPrelevement: (sendPrelevementDto) => dispatch(actions.sendPrelevement(sendPrelevementDto)),
        onCompanySwitchinPrelevement: () => dispatch(actions.CompanySwitchinPrelevement()),
        onCreateUserLog: (userLog) => dispatch(createUserLog(userLog)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation()(Prelevement)));