import i18n from '../../i18n/i18n';

// Old config, gets used for search page function
// Now use this files : app\fuse-layouts\shared-components\Navigation.js
const navigationConfig = [
    {
        'id'      : 'applications',
        'title'   : 'Applications',
        'type'    : 'group',
        'icon'    : 'apps',
        'children': [
            {
                'id'      : 'icrm',
                'title'   : 'iCRM',
                'type'    : 'collapse',
                'icon'    : 'business-center',
                'auth'    : 'iCRMBasis',
                'children': [
                    {
                        'id'   : 'dashboard',
                        'title': i18n.t('DASHBOARD'),
                        'type' : 'item',
                        'url'  : '/icrm/dashboard',
                        'auth' : 'iCRMBasis'
                    },
                    {
                        'id'   : 'overview',
                        'title': i18n.t('OVERVIEW'),
                        'type' : 'item',
                        'url'  : '/icrm/overview',
                        'auth' : 'iCRMBasis'
                    },
                    {
                        'id'   : 'turnover',
                        'title': i18n.t('TURNOVER'),
                        'type' : 'item',
                        'url'  : '/icrm/turnover',
                        'auth' : 'iCRMBasis'
                    },
                    {
                        'id'   : 'weblog',
                        'title': 'Weblog',
                        'type' : 'item',
                        'url'  : '/icrm/weblog',
                        'auth' : 'iCRMBasis'
                    },
                    {
                        'id'   : 'unpaid',
                        'title': i18n.t('UNPAID'),
                        'type' : 'item',
                        'url'  : '/icrm/unpaid',
                        'auth' : 'iCRMBasis'
                    },
                    {
                        'id'   : 'customers',
                        'title': i18n.t('CUSTOMERS'),
                        'type' : 'item',
                        'url'  : '/icrm/customers',
                        'auth' : 'iCRMBasis'
                    },
                    {
                        'id'   : 'newcustomers',
                        'title': i18n.t('NEWCUSTOMERS'),
                        'type' : 'item',
                        'url'  : '/icrm/newcustomers',
                        'auth' : 'iCRMBasis'
                    },
                    {
                        'id'   : 'newleads',
                        'title': i18n.t('NEW') + " " + i18n.t('LEADS'),
                        'type' : 'item',
                        'url'  : '/icrm/newleads',
                        'auth' : 'iCRMBasis'
                    },
                ]
            },
            {
                'id'      : 'manager',
                'title'   : 'iCRM - Manager',
                'type'    : 'collapse',
                'auth'    : 'iCRMManager',
                'icon'    : 'business-center',
                'children': [
                    {
                        'id'   : 'credits',
                        'title': 'Credits',
                        'type' : 'item',
                        'url'  : '/icrm/credits',
                        'auth' : 'iCRMManager'
                    },
                    {
                        'id'   : 'prelevement',
                        'title': i18n.t('PRELEVEMENT'),
                        'type' : 'item',
                        'url'  : '/icrm/prelevement',
                        'auth' : 'iCRMManager'
                    },
                    {
                        'id'   : 'AssignNewLeads',
                        'title': i18n.t('ASSIGNNEWLEADS'),
                        'type' : 'item',
                        'url'  : '/icrm/AssignNewLeads',
                        'auth' : 'iCRMManager'
                    },
                    {
                        'id'   : 'tasksoverview',
                        'title': i18n.t('TASKS') + " " + i18n.t('OVERVIEW'),
                        'type' : 'item',
                        'url'  : '/icrm/tasksoverview',
                        'auth' : 'iCRMManager'
                    },
                ],
            },
            {
                'id'      : 'admin',
                'title'   : 'iCRM - Admin',
                'type'    : 'collapse',
                'auth'    : 'iCRMAdmin',
                'icon'    : 'business-center',
                'children': [
                    {
                        'id'   : 'users',
                        'title': 'Users',
                        'type' : 'item',
                        'url'  : '/icrm/users',
                        'auth' : 'iCRMAdmin'
                    },
                    {
                        'id'   : 'useraccess',
                        'title': 'User access',
                        'type' : 'item',
                        'url'  : '/icrm/useraccess',
                        'auth' : 'iCRMAdmin'
                    },
                    {
                        'id'   : 'openposten',
                        'title': 'Open Posten',
                        'type' : 'item',
                        'url'  : '/icrm/openposten',
                        'auth' : 'iCRMAdmin'
                    },
                ],
            },
            {
                'id'      : 'specialoffer',
                'title'   : 'Special Offer',
                'type'    : 'collapse',
                'icon'    : 'monetization_on',
                'auth' : 'iCRMSpecialOfferSend',
                'children': [
                    {
                        'id'   : 'specialoffers',
                        'title': 'Special Offers',
                        'type' : 'item',
                        'url'  : '/specialoffers',
                        'exact': true,
                        'auth' : 'iCRMSpecialOfferSend'
                    },
                    {
                        'id'   : 'manage-special-offers',
                        'title': 'Manage Special Offers',
                        'type' : 'item',
                        'url'  : '/specialoffers/manage',
                        'exact': true,
                        'auth' : 'iCRMSpecialOfferEdit'
                    },
                ]
            },
            {
                'id'      : 'visits',
                'title'   : i18n.t('VISITS'),
                'type'    : 'collapse',
                'icon'    : 'monetization_on',
                'auth' : 'iVisits',
                'children': [
                    {
                        'id'   : 'visits',
                        'title': i18n.t('VISITS'),
                        'type' : 'item',
                        'url'  : '/visits',
                        'auth' : 'iVisits'
                    },
                ]
            },
            {
                'id'      : 'apis',
                'title'   : 'APIs',
                'type'    : 'collapse',
                'icon'    : 'code',
                'children': [
                    {
                        'id'   : 'excels',
                        'title': 'Excels',
                        'type' : 'item',
                        'url'  : '/api/excel',
                        'exact': true,
                        'auth' : 'iCRMAdmin'
                    },
                    {
                        'id'   : 'enalyzer',
                        'title': 'Enalyzer',
                        'type' : 'item',
                        'url'  : '/api/enalyzer',
                        'exact': true,
                        'auth' : 'iCRMAdmin'
                    },
                    {
                        'id'   : 'callcenter',
                        'title': 'Callcenter',
                        'type' : 'item',
                        'url'  : '/api/callcenter',
                        'exact': true,
                        'auth' : 'iCRMAdmin'
                    },
                    {
                        'id'   : 'dexxcbsexport',
                        'title': 'Export DEB',
                        'type' : 'item',
                        'url'  : '/api/dexxexportCBS',
                        'exact': true,
                        'auth' : 'DexxFRAccountantExports'
                    },
                    {
                        'id'   : 'dexxC&Cexport',
                        'title': 'C&C Export CA',
                        'type' : 'item',
                        'url'  : '/api/dexxC&Cexport',
                        'exact': true,
                        'auth' : 'DexxCnCExports'
                    },
                    {
                        'id'   : 'dexxC&Cmodule',
                        'title': 'C&C Module',
                        'type' : 'item',
                        'url'  : '/api/dexxC&Cmodule',
                        'exact': true,
                        'auth' : 'DexxCnCModule'
                    },
                    {
                        'id'   : 'dexxStockExport',
                        'title': 'Stock Export',
                        'type' : 'item',
                        'url'  : '/api/dexxStockExport',
                        'exact': true,
                        'auth' : 'DexxCnCExports'
                    },
                    {
                        'id'   : 'dexxquadraexport',
                        'title': 'Quadra Export',
                        'type' : 'item',
                        'url'  : '/api/quadra-export',
                        'exact': true,
                        'auth' : 'iCRMFinancieel'
                    },
                    {
                        'id'   : 'adhoc',
                        'title': 'AdHoc',
                        'type' : 'item',
                        'url'  : '/api/adhoc',
                        'exact': true,
                        'auth' : 'MFJDFexport'
                    },
                    {
                        'id'   : 'flowerplace',
                        'title': 'FlowerPlace',
                        'type' : 'item',
                        'url'  : '/api/flowerplace',
                        'exact': true,
                        'auth' : 'iCRMAdmin'
                    },
                    {
                        'id'   : 'JDFexport',
                        'title': 'JDF Export',
                        'type' : 'item',
                        'url'  : '/api/JDFexport',
                        'exact': true,
                        'auth' : 'MFJDFexport'
                    },
                ]
            },
            {
                'id'   : 'documentation',
                'title': 'Documentation',
                'type' : 'collapse',
                'icon' : 'import_contacts',
                'auth' : 'iCRMAdmin',
                'children': [
                    {
                        'id'   : 'Fuse-theming',
                        'title': 'Fuse theming',
                        'type' : 'item',
                        'exact': true,
                        'url'  : '/documentation/fuse-theming'
                    },
                    {
                        'id'   : 'routing',
                        'title': 'Routing',
                        'type' : 'item',
                        'exact': true,
                        'url'  : '/documentation/routing'
                    },
                    {
                        'id'   : 'tailwindcss',
                        'title': 'Tailwind',
                        'type' : 'item',
                        'exact': true,
                        'url'  : '/documentation/Tailwindcss'
                    }
                ]
            }
        ]
    }
];

export default navigationConfig;
