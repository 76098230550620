import React from 'react';

const MainPage = () => {
    return (
        <div id="fuse-splash-screen">
            <span style={{color: '#2D323D'}}>Main Page</span>
        </div>
    );
};

export default MainPage;