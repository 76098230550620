import React, { useState } from 'react';
import {Dialog, DialogContent, Typography, AppBar, Tabs, Tab,
    Table, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core';
import { useQuery } from "react-query";
import axios from 'axios';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div>
            {children}
          </div>
        )}
      </div>
    );
  }

function BackorderTable (props) {
    return (
        <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Debnr</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Order nr</TableCell>
            <TableCell>Art. nr</TableCell>
            <TableCell>Art. oms.</TableCell>
            <TableCell>Aantal</TableCell>
            <TableCell>Partij prijs</TableCell>
            <TableCell>Verkoopbedrag</TableCell>
            <TableCell>Datum order</TableCell>
            <TableCell>Datum uitlevering</TableCell>
            <TableCell>Foto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.group.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {row.debiteurNummer}
              </TableCell>
              <TableCell>{row.type}</TableCell>
              <TableCell>{row.orderNummer}</TableCell>
              <TableCell>{row.artikelNummer}</TableCell>
              <TableCell>{row.artikelOmschrijving}</TableCell>
              <TableCell>{row.totaalGeleverd}</TableCell>
              <TableCell>{row.partijPrijs}</TableCell>
              <TableCell>{row.verkoopbedragTotaal}</TableCell>
              <TableCell>{moment(row.datumKlantorder).format('DD-MM-YYYY')}</TableCell>
              <TableCell>{moment(row.datumUitlevering).format('DD-MM-YYYY')}</TableCell>
              {/* <TableCell><img src="https://pictures.flowerwebshop.net/pictures//X5754953_V_1.jpg?t=344410861" alt="" /></TableCell> */}
              <TableCell><img src={row.photoUrl} alt="" style={{maxWidth: 100, maxHeight: 100}} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
}

const BackorderOverview = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    const { data, isLoading } = useQuery(
        ["backorders", props.debnr],
        async () => {
            const res = await axios.get(
                "customer/getbackorders/" + props.debnr
            );
            return res.data;
        }, {
            staleTime: Infinity
        }
    );
    
    return (
        <Dialog 
            classes={{
                paper: "m-24"
            }}
            onClose={props.handleClose}
            open
            fullWidth
            maxWidth="lg"
        >
            <AppBar position="static">                    
                {!isLoading ?
                    <Tabs 
                        value={activeTab} 
                        onChange={(_, newValue) => setActiveTab(newValue)} 
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="order-nummer-tabs">
                        {data && data.map((group, index) => (
                            <Tab key={group[0].orderNummer} label={group[0].orderNummer} {...a11yProps(index)} />
                        ))}
                    </Tabs>
                    :
                    <Typography className="m-12">Backorders van {props.debnr} aan het ophalen...</Typography>
                }
            </AppBar>

            <DialogContent classes={{root: "ph-24"}}>
            {(!isLoading && data) ? data.map((group, index) => (
                <TabPanel key={group[0].orderNummer} value={activeTab} index={index}>
                    <BackorderTable group={group} handleMailClick={props.handleMailClick} />
                </TabPanel>
            )) 
            :
                <div className="p-24">
                    <Skeleton variant="text" width={'100%'} height={40} />
                    <Skeleton variant="text" width={'100%'} height={40} />
                    <Skeleton variant="text" width={'100%'} height={40} />
                </div>
            }
            </DialogContent>
        </Dialog>
    )
}

export default BackorderOverview;