import axios from 'axios'
import * as actionTypes from './actionTypes';
import {getUserEmail} from '../../../../main/Auth/Auth';
import {fetchAllGroups} from '../../../auth/actions'
import moment from 'moment';

export const fetchUsersSuccess = (users) => {
    return {
        type: actionTypes.FETCH_USERS_SUCCESS,
        users: users
    };
};

export const fetchUsers = () => {
    return dispatch => {
        axios.get('admin/getusers')
            .then(res => {
                dispatch(fetchUsersSuccess(res.data));
            })
    };
};

export const fetchUserRolesStart = () => {
    return {
        type: actionTypes.FETCH_USER_ROLES_START,
    };
};
export const fetchUserRolesSuccess = (roles) => {
    return {
        type: actionTypes.FETCH_USER_ROLES_SUCCESS,
        roles
    };
};
export const fetchUserRolesFail = (error) => {
    return {
        type: actionTypes.FETCH_USER_ROLES_FAIL,
        error
    };
};

export const fetchUserRoles = (azureId) => {
    return dispatch => {
        dispatch(fetchUserRolesStart());
        axios.get('admin/GetRolesByAzureId?azureId=' + azureId)
            .then(res => {
                dispatch(fetchUserRolesSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchUserRolesFail(error.message));
            })
    };
};

export const fetchCompaniesSuccess = (companies) => {
    return {
        type: actionTypes.FETCH_COMPANIES_SUCCESS,
        companies: companies
    };
};

export const fetchCompanies = () => {
    return dispatch => {
        axios.get('admin/getcompanies')
            .then(res => {
                dispatch(fetchCompaniesSuccess(res.data));
            })
    };
};


export const fetchAccessableGroupsStart = () => {
    return {
        type: actionTypes.FETCH_ACCESSABLE_GROUPS_START,
    };
};

export const fetchAccessableGroupsSuccess = (accessableGroups) => {
    return {
        type: actionTypes.FETCH_ACCESSABLE_GROUPS_SUCCESS,
        accessableGroups: accessableGroups
    };
};

export const fetchAccessableGroups = (azureId) => {
    return dispatch => {
        dispatch(fetchAccessableGroupsStart());
        axios.get('groupcode/GetAccessableGroupsByAzureId?azure_id='+ azureId)
            .then(res => {
                dispatch(fetchAccessableGroupsSuccess(res.data));
            })
    };
};


export const fetchUnaccessableGroupsStart = () => {
    return {
        type: actionTypes.FETCH_UNACCESSABLE_GROUPS_START,
    };
};

export const fetchUnaccessableGroupsSuccess = (unaccessableGroups) => {
    return {
        type: actionTypes.FETCH_UNACCESSABLE_GROUPS_SUCCESS,
        unaccessableGroups: unaccessableGroups
    };
};

export const fetchUnaccessableGroups = (azureId) => {
    return dispatch => {
        dispatch(fetchUnaccessableGroupsStart());
        axios.get('groupcode/GetUnaccessableGroupsByAzureId?azure_id='+ azureId)
            .then(res => {
                dispatch(fetchUnaccessableGroupsSuccess(res.data));
            })
    };
};

export const closeUpdateUserGroupSnackbar = () => {
    return {
        type: actionTypes.CLOSE_UPDATE_USER_GROUP_SNACKBAR,
        updatedGroup: false, 
    };
};

export const updateUserGroupSuccess = (updated) => {
    return {
        type: actionTypes.UPDATE_GROUP_SUCCESS,
        updatedGroup: true, 
    };
};

export const updateUserGroup = (userAccessDto) => {
    return dispatch => {
        axios.post('groupcode/AddOrRemoveGroupFromUser', userAccessDto)
            .then(res => {
                dispatch(updateUserGroupSuccess(res.data));
                dispatch(fetchAccessableGroups(userAccessDto.azureId));
                dispatch(fetchUnaccessableGroups(userAccessDto.azureId));
            })
    };
};

export const updateUserSuccess = (updated) => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS,
        userUpdated: updated, 
    };
};

export const updateUser = (user, changedPassword) => {
    return dispatch => {
        axios.post('admin/updateuser?changedPassword=' + changedPassword, user)
            .then(res => {
                dispatch(updateUserSuccess(res.data));
                dispatch(fetchUsers());
            })
    };
};

export const createUserSuccess = (created) => {
    return {
        type: actionTypes.CREATE_USER_SUCCESS,
        userCreated: created, 
    };
};

export const createUser = (user) => {
    return dispatch => {
        axios.post('admin/createuser', user)
            .then(res => {
                dispatch(createUserSuccess(res.data));
                dispatch(fetchUsers());
            })
    };
};

export const deleteUserSuccess = (deleted) => {
    return {
        type: actionTypes.DELETE_USER_SUCCESS,
        userDeleted: deleted, 
    };
};

export const deleteUser = (azureId) => {
    return dispatch => {
        axios.post('admin/deleteuser', {azureId})
            .then(res => {
                dispatch(deleteUserSuccess(res.data));
                dispatch(fetchUsers());
            })
    };
};

export const fetchOpenPostenStart = () => {
    return {
        type: actionTypes.FETCH_OPEN_POSTEN_START
    };
};

export const fetchOpenPostenSuccess = (openPosten) => {
    return {
        type: actionTypes.FETCH_OPEN_POSTEN_SUCCESS,
        openPosten: openPosten, 
    };
};

export const fetchOpenPosten = () => {
    return dispatch => {
        dispatch(fetchOpenPostenStart());
        axios.get('admin/getOpenPosten')
            .then(res => {
                dispatch(fetchOpenPostenSuccess(res.data));
            })
    };
};

export const sendOpenPostSuccess = (response) => {
    return {
        type: actionTypes.SEND_OPEN_POST_SUCCESS,
        status: response.statusCode, 
    };
};

export const sendOpenPost = (openPost) => {
    return dispatch => {
        axios.post('admin/SendOpenPost', openPost, {headers: {email: getUserEmail()}})
            .then(res => {
                dispatch(sendOpenPostSuccess(res.data));
            })
    };
};

export const refreshDebiterGroupsStart = () => {
    return {
        type: actionTypes.REFRESH_DEBITER_GROUPS_START,
    };
}
export const refreshDebiterGroupsSuccess = () => {
    return {
        type: actionTypes.REFRESH_DEBITER_GROUPS_SUCCESS,
    };
}

export const refreshDebiterGroups = () => {
    return dispatch => {
        dispatch(refreshDebiterGroupsStart());
        axios.get('groupcode/refreshgroups')
            .then(res => {
                dispatch(refreshDebiterGroupsSuccess());
                dispatch(fetchAllGroups());
            })
    }
}

export const importUsersSIBSuccess = () => {
    return {
        type: actionTypes.IMPORT_USERS_SIB_SUCCESS
    }
}
export const importUsersSIBFail = (error) => {
    return {
        type: actionTypes.IMPORT_USERS_SIB_FAIL,
        error
    }
}

export const importUsersSIB = () => {
    // var instance = axios.default.create();
    return dispatch => {
        // dispatch(refreshDebiterGroupsStart());
        axios.get('sendinblue/ImportUsers')
            .then(res => {
                dispatch(importUsersSIBSuccess());
            })
            .catch(err => {
                dispatch(importUsersSIBFail(err.response.status));
            })
    }
}

export const fetchLatestImportSuccess = (latestImport) => {
    return {
        type: actionTypes.FETCH_LATEST_IMPORT_SUCCESS,
        latestImport
    }
}
export const fetchLatestImportFail = (error) => {
    return {
        type: actionTypes.FETCH_LATEST_IMPORT_FAIL,
        error
    }
}

export const fetchLatestImport = () => {
    return dispatch => {
        axios.get('admin/getlatestimport')
            .then(res => {
                dispatch(fetchLatestImportSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchLatestImportFail(err));
            })
    }
}

export const updateUserLanguage = (azureId, language) => {
    return dispatch => {
        axios.get('auth/UpdateUserLanguage/' + azureId + '?language=' + language)
            .then(res => {
                console.log(res.data)
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export const createUserLog = (userLog) => {
    const isMobile = /Mobi/.test(navigator.userAgent);
    const isTablet = /Android/.test(navigator.userAgent); 
    return dispatch => {
        axios.post('monitor/createuserlog', {...userLog, device: isMobile ? 'mobile' : (isTablet ? 'tablet' : 'desktop')})
        .then(res => res)
        .catch(err => console.log(err))
    }
}

// Download Excel GB Outstanding Items
export const downloadGreenAndBloomOutstandingItems = () => {
    return dispatch => {
        axios.get('GetGreenAndBloomSaldoOverzicht', {responseType: 'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${moment(new Date()).format('YYYY-MM-DD')}-outstanding-items.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.log(error);
            })
    };
}