import React, { useState } from 'react';
import {Button, Dialog, DialogContent, Icon, Typography, Toolbar, AppBar, MenuItem, TextField,
    IconButton, Tooltip} from '@material-ui/core';
import axios from 'axios';
import ReactTable from "react-table";
import {withTranslation} from 'react-i18next';
import {filterAll} from '../../../components/Functions/FilterAll'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getAzureId } from '../../Auth/Auth';
    
const renderSuggestion = (suggestion, handleClick) => {
    return (
      <MenuItem 
        key={suggestion.email}
        component="div"
        style={{
          fontWeight: 400,
        }}
        onClick={handleClick}
        className="justify-center"
      >
        {<>
        <IconButton aria-label="add" size="small" color="secondary">
            <Icon>person_add</Icon>
        </IconButton>
        &ensp;<strong>{suggestion.userName}</strong></>}
      </MenuItem>
    );
}

const UserStatsManagerAddUsers = (props) => {
    const queryClient = useQueryClient()
    const { t } = props;
    const [input, setInput] = useState('');
    const addUserMutation = useMutation(newAssignedUser => axios.post('manager/AddManagerAssignedUser', newAssignedUser), {
        onSuccess: () => {
            queryClient.invalidateQueries('managerAssignedUsers');
            queryClient.invalidateQueries('userStatsAllPages');
        }
    });
    const deleteUserMutation = useMutation(assignmentToRemove => axios.delete('manager/RemoveManagerAssignedUser', {
        data: assignmentToRemove
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries('managerAssignedUsers');
            queryClient.invalidateQueries('userStatsAllPages');
        }
    });

    const fetchUsers = useQuery(
        ["managerAssignedUsers"],
        async () => {
            const res = await axios.get(
                "manager/GetManagerAssignedUsers"
            );
            return res.data;
        },
        {
            staleTime: 300000
        }
    );

    const handleAddUser = (selectedUser) => {
        const assignedUser = {
            favoriteAzureId: getAzureId(),
            userAzureId: selectedUser.userAzureId
        };
        addUserMutation.mutate(assignedUser);
    }

    const handleDeleteUser = (selectedUser) => {
        const assignmentToRemove = {
            favoriteAzureId: getAzureId(),
            userAzureId: selectedUser.userAzureId
        };
        deleteUserMutation.mutate(assignmentToRemove);
    }

    const handleInputChange = event => {
        setInput(event.target.value);
    }

    const filterUsersByInput = () => {
        const users = fetchUsers.data.assignableUsers.map(u => {
            return {
                userName: u.userName,
                userAzureId: u.userAzureId
            }
        });

        const filteredUsers = filterAll(users, input);
        return filteredUsers;
    }

    return (
        <Dialog 
            classes={{
                paper: "m-24"
            }}
            onClose={props.handleClose}
            open
            fullWidth
            maxWidth="sm"
        >
            <AppBar position="static" elevation={1}>
                <Toolbar className="flex w-full">
                    <Tooltip title="Go back">
                        <IconButton aria-label="Delete" size="small" color="secondary" onClick={props.handleClose}>
                            <Icon>arrow_back</Icon>
                        </IconButton>
                    </Tooltip>
                    <Typography variant="subtitle1" color="inherit" className="ml-16">
                        Add users to your own favorite list
                    </Typography>
                </Toolbar>
            </AppBar>

            <DialogContent classes={{root: "p-24"}}>
                <div className="flex">
                    <TextField
                        className="mb-24"
                        name='userInput'
                        label={'Search for a user'}
                        value={input}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                    />
                </div>
                
                {fetchUsers.isLoading ? null : filterUsersByInput().slice(0, 5).map(u => renderSuggestion(u, () => handleAddUser(u)))}

                <h1>Your favorite users :</h1>
                <ReactTable
                    className="w-full -striped -highlight border-0"
                    data={fetchUsers.isLoading ? [] : fetchUsers.data.assignedUsers}
                    columns={[
                        {
                            Header    :  t('NAME'),
                            accessor  : "assignedUserName",
                            className: "justify-center",
                            width: '40%',
                        },
                        {
                            Header    : "Delete",
                            className : "justify-center",
                            width: 80,
                            Cell: row => 
                            <Button variant="outlined" color="secondary" onClick={() => handleDeleteUser(row.original)}>
                                <Icon className="text-18" color="secondary">delete</Icon>
                            </Button>,
                        },
                    ]}
                    pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                    defaultPageSize={5}
                    noDataText="No customers found"
                />
            </DialogContent>
        </Dialog>
    );
}

export default withTranslation()(UserStatsManagerAddUsers);
