import {updateObject} from '../../utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    weblog: [],
    // weblogVisits: {},
}

// Weblog //

const fetchLoginHistorySuccess = ( state, action ) => {
    return updateObject( state, {weblog: action.weblog, loading: false} );
};

const fetchLoginHistoryStart = ( state, action ) => {
    return updateObject( state, {loading: true} );
};

// const fetchDbWeblogVisitsSuccess = ( state, action ) => {
//     return updateObject( state, {weblogVisits: action.weblogVisits} );
// };

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FETCH_LOGIN_HISTORY_SUCCESS: return fetchLoginHistorySuccess( state, action );
        case actionTypes.FETCH_LOGIN_HISTORY_START: return fetchLoginHistoryStart( state, action );

        // case actionTypes.FETCH_WEBLOG_VISITS_SUCCESS: return fetchDbWeblogVisitsSuccess( state, action );

        default: return state;
    }
};

export default reducer;