import React, {Component} from 'react';
import {Button, Dialog, DialogContent, Icon, Typography, Toolbar, AppBar, MenuItem, TextField,
    IconButton, Tooltip} from '@material-ui/core';
// import _ from 'lodash';
import ReactTable from "react-table";
import {withTranslation} from 'react-i18next';
import {filterAll} from '../../../../components/Functions/FilterAll'

class NewLeadsUserDialog extends Component {

    //properties: accountManager, azureId, companyId, groupCode, email, groups, id, imageUrl, name
    state = {
        selectedUsers: [],
        favtemplates: [],
        userInput: ''
    };

    // delete this
    handleDelete = (favtemplate) => {
        this.props.onDelete(favtemplate);
    }

    handleClose = () => {
        this.props.onClose();
        this.setState({userInput: ''});
    }

    // delete as well
    onSelectUser = (selectedUser) => {
        // console.log(selectedUser);
        const users = [...this.state.selectedUsers, selectedUser];
        // this.setState({selectedUsers: users, userInput: ''});
        this.setState({selectedUsers: users});
        this.props.onSave(selectedUser);
    }
    
    renderSuggestion = (suggestion) => {
        return (
          <MenuItem 
            key={suggestion.template_debnr}
            component="div"
            style={{
              fontWeight: 400,
            }}
            onClick={() => this.onSelectUser(suggestion)}
          >
            {<>
            <IconButton aria-label="add" size="small" color="secondary">
                <Icon>person_add</Icon>
            </IconButton>
            &ensp;{suggestion.template_debnr}:&ensp;<strong>{suggestion.template_name}</strong> </>}
          </MenuItem>
        );
      }

    handleInputChange = event => {
        this.setState({userInput: event.target.value}, this.filterUsersByInput);
    }

    filterUsersByInput = () => {
        const users = this.props.users.map(u => {
            return {
                template_debnr: u.template_debnr,
                template_name: u.template_name,
                azureId: u.azure_id
            }
        });

        const filteredUsers = filterAll(users, this.state.userInput);
        return filteredUsers;

    }

    render()
    {
        const {dialogOpen, t} = this.props;
        const favtemplates = this.props.favtemplates;
        
        // console.log(favtemplates);

        return (
            Object.keys(this.state).length > 0 ? 
                <Dialog 
                    classes={{
                        paper: "m-24"
                    }}
                    onClose={this.handleClose}
                    open={dialogOpen}
                    fullWidth
                    maxWidth="lg"
                >
                    <AppBar position="static" elevation={1}>
                        <Toolbar className="flex w-full">
                            <Tooltip title="Go back">
                                <IconButton aria-label="Delete" size="small" color="secondary" onClick={this.handleClose}>
                                    <Icon>arrow_back</Icon>
                                </IconButton>
                            </Tooltip>
                            <Typography variant="subtitle1" color="inherit" className="ml-16">
                                {t('ADDTEMPLATESTOYOURLIST')}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <DialogContent classes={{root: "p-24"}}>
                        
                    <div className="flex">
                        <TextField
                            className="mb-24"
                            name='userInput'
                            label={'Search for templates'}
                            value={this.state.userInput}
                            onChange={this.handleInputChange}
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 p-12">
                            {this.filterUsersByInput().slice(0, 5).map(u => this.renderSuggestion(u))}
                        </div>
                        <div className="w-full md:w-1/2 p-12">
                            {this.filterUsersByInput().slice(5, 10).map(u => this.renderSuggestion(u))}
                        </div>
                    </div>
                    
                    
                        {/* {this.state.selectedUsers.map(user => 
                            <Tooltip title='Add to favorite'>
                            <p key={user.email}>{user.email} 
                                <IconButton aria-label="Delete" size="small" color="secondary" onClick={this.handleDelete}>
                                    <Icon>delete</Icon>
                                </IconButton>
                            </p>
                            </Tooltip>
                        )} */}
                        {/* <p>
                            {favusers.length > 0 ? 
                                favusers.map(favuser => 
                                    <MenuItem key={favuser.fav_azure_id} value={favuser.fav_azure_id}>{favuser.name} - {favuser.email} 
                                        <Tooltip title='Delete'>
                                            <IconButton aria-label="Delete" size="small" color="secondary" onClick={() => this.handleDelete(favuser)}>
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </MenuItem>)
                                : null }
                        </p> */}
                        <h1>{t('YOURFAVTEMPLATES')}</h1>
                        {/* {favtemplates.length > 0 ?  */}
                        <ReactTable
                            className="w-full -striped -highlight border-0"
                            data={favtemplates}
                            columns={[
                                {
                                    Header    : "Code",
                                    accessor  : "template_debnr",
                                    // className: "justify-center",
                                    width: '40%',
                                },
                                {
                                    Header    :  t('NAME'),
                                    accessor  : "template_name",
                                    // className: "justify-center",
                                    width: '40%',
                                },
                                {
                                    Header    :  t('DELETE'),
                                    className : "justify-center",
                                    // filterable: true,
                                    width: 80,
                                    Cell: row => 
                                    <Button variant="outlined" color="secondary" onClick={() => this.handleDelete(row.original)}>
                                        <Icon className="text-18" color="secondary">delete</Icon>
                                    </Button>,
                                },
                            ]}
                            pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                            defaultPageSize={5}
                            noDataText="No templates selected yet"
                        />
                    </DialogContent>
                </Dialog>
            : null 
        );
    }
}

export default withTranslation()(NewLeadsUserDialog);
