import React from 'react';
import {AppBar, Hidden, MuiThemeProvider, Toolbar, withStyles, LinearProgress } from '@material-ui/core';
import {FuseSearch, FuseShortcuts} from '../../../../@fuse';
import connect from 'react-redux/es/connect/connect';
import {withRouter} from 'react-router-dom';
import NavbarMobileToggleButton from '../../../../app/fuse-layouts/shared-components/NavbarMobileToggleButton';
import UserMenu from '../../../../app/fuse-layouts/shared-components/UserMenu';
import SettingMenu from '../../../../app/fuse-layouts/shared-components/SettingMenu';
import LanguageMenu from './LanguageMenu';
import { useTranslation } from 'react-i18next';
// import { getAzureId } from '../../../main/Auth/Auth';

const styles = theme => ({
    separator: {
        width          : 1,
        height         : 64,
        backgroundColor: theme.palette.divider
    }
});

const ToolbarLayout1 = ({classes, settings, toolbarTheme, user, history}) => {
    const layoutConfig = settings.layout.config;
    const { t } = useTranslation();

    return (
        <MuiThemeProvider theme={toolbarTheme}>
            <AppBar id="fuse-toolbar" className="flex relative z-10" color="default">
                <>
                <Toolbar className="p-0">

                    {layoutConfig.navbar.display && layoutConfig.navbar.position === 'left' && (
                        <Hidden lgUp>
                            <NavbarMobileToggleButton className="w-64 h-64 p-0"/>
                            <div className={classes.separator}/>
                        </Hidden>
                    )}

                    <div className="flex flex-1">
                        <Hidden mdDown>
                            <FuseShortcuts className="px-16"/>
                        </Hidden>
                    </div>

                    <div className="flex">
                        <LanguageMenu/>
                        <div className={classes.separator}/>
                        <SettingMenu/>
                        {/* <div className={classes.separator}/>
                        <Tooltip title={t('own_stats_button')}>
                            <Button className="h-64" onClick={() => history.push('/stats/user/' + getAzureId())}>
                                <Icon className="text-24 sm:flex md:flex lg:flex" variant="action" color="action">account_circle</Icon>
                            </Button>
                        </Tooltip> */}
                        <div className={classes.separator}/>
                        <UserMenu/>
                        <div className={classes.separator}/>
                        <FuseSearch/>
                    </div>

                    {layoutConfig.navbar.display && layoutConfig.navbar.position === 'right' && (
                        <Hidden lgUp>
                            <NavbarMobileToggleButton/>
                        </Hidden>
                    )}
                </Toolbar>
                {!user || Object.keys(user).length === 0 ? <LinearProgress variant="query" color="secondary" /> : null}
                </>
            </AppBar>
        </MuiThemeProvider>
    );
};

function mapStateToProps({fuse, flortalAuth})
{
    return {
        settings    : fuse.settings.current,
        toolbarTheme: fuse.settings.toolbarTheme,
        user: flortalAuth.user
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps)(ToolbarLayout1)));
