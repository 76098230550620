import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../utility'

const initialState = {
    apis: [],
    generateDexxCBSExport: [],
    generateDexxCnCExport: [],
    generateJDFexport: [],
    generateStockExport: [],
    getDexxCnCModuleVerkoopers: [],
    generateQuadraExport: [],
    generateQuadraExportFaults: [],
    loadingGeneratedDexxCBSExport: false,
    loadingGeneratedDexxCnCExport: false,
    loadingGeneratedJDFexport: false,
    loadingGeneratedStockExport: false,
    loadingGetDexxCnCModuleVerkoopers: false,
    loadingGeneratedQuadraExport: false,
    loadingGeneratedQuadraExportFaults: false,
    generateAdHocExport: [],
    loadingGeneratedAdHocExport: false,
    updatediklant: false,
    loadingGeneratedDexxCBSExportDebnr: false,
}

const fetchApisSuccess = ( state, action ) => {
    return updateObject( state, {apis: action.apis} );
};

// Generate DEXX CBS Export
const generateDexxCBSExportStart = ( state, action ) => {
    return updateObject( state, {loadingGeneratedDexxCBSExport: true} );
};

const generateDebnrDexxCBSExportStart = ( state, action ) => {
    return updateObject( state, {loadingGeneratedDexxCBSExportDebnr: true} );
};

const generateDexxCBSExportSuccess = ( state, action ) => {
    return updateObject( state, {error: null, generateDexxCBSExport: action.generateDexxCBSExport, loadingGeneratedDexxCBSExport: false} );
    // return updateObject( state, {error: null, generateDexxCBSExport: null, loadingGeneratedDexxCBSExport: false} );
};

const generateDebnrDexxCBSExportSuccess = ( state, action ) => {
    return updateObject( state, {error: null, generateDebnrDexxCBSExport: action.generateDebnrDexxCBSExport, loadingGeneratedDexxCBSExportDebnr: false} );
    // return updateObject( state, {error: null, generateDexxCBSExport: null, loadingGeneratedDexxCBSExport: false} );
};

const generateDexxCBSExportFail = ( state, action ) => {
    return updateObject( state, {error: action.error} );
};

const generateDebnrDexxCBSExportFail = ( state, action ) => {
    return updateObject( state, {errorDebnr: action.error} );
};

// Generate DEXX CnC Export
const generateDexxCnCExportStart = ( state, action ) => {
    return updateObject( state, {loadingGeneratedDexxCnCExport: true} );
};
const generateDexxCnCExportSuccess = ( state, action ) => {
    return updateObject( state, {error: null, generateDexxCnCExport: action.generateDexxCnCExport, loadingGeneratedDexxCnCExport: false} );
};
const generateDexxCnCExportFail = ( state, action ) => {
    return updateObject( state, {error: action.error} );
};


// Generate STOCK Export
const generateStockExportStart = ( state, action ) => {
    return updateObject( state, {loadingGeneratedStockExport: true} );
};
const generateStockExportSuccess = ( state, action ) => {
    return updateObject( state, {error: null, generateStockExport: action.generateStockExport, loadingGeneratedStockExport: false} );
};
const generateStockExportFail = ( state, action ) => {
    return updateObject( state, {error: action.error} );
};

// Generate DEXX CnC Module
const getDexxCnCModuleVerkoopersStart = ( state, action ) => {
    return updateObject( state, {loadingGetDexxCnCModuleVerkoopers: true} );
};
const getDexxCnCModuleVerkoopersSuccess = ( state, action ) => {
    return updateObject( state, {error: null, getDexxCnCModuleVerkoopers: action.getDexxCnCModuleVerkoopers, loadingGetDexxCnCModuleVerkoopers: false} );
};
const getDexxCnCModuleVerkoopersFail = ( state, action ) => {
    return updateObject( state, {error: action.error} );
};
// DEXX C&C MODULE Update IKLANT
const dexxCnCModuleUpdateIKLANTSuccess = ( state, action ) => {
    return updateObject( state, {updatediklant: action.updatediklant} );
};

// Generate EXPORT QUADRA
const generateQuadraExportPreviewStart = ( state, action ) => {
    return updateObject( state, {loadingGeneratedQuadraExport: true} );
};
const generateQuadraExportPreviewSuccess = ( state, action ) => {
    return updateObject( state, {error: null, generateQuadraExport: action.generateQuadraExport, loadingGeneratedQuadraExport: false} );
};
const generateQuadraExportPreviewFail = ( state, action ) => {
    return updateObject( state, {error: action.error} );
};

const generateQuadraExportFaultsStart = ( state, action ) => {
    return updateObject( state, {loadingGeneratedQuadraExportFaults: true} );
};
const generateQuadraExportFaultsSuccess = ( state, action ) => {
    return updateObject( state, {error: null, generateQuadraExportFaults: action.generateQuadraExportFaults, loadingGeneratedQuadraExportFaults: false} );
};
const generateQuadraExportFaultsFail = ( state, action ) => {
    return updateObject( state, {error: action.error} );
};

// Generate Ad Hoc Export

// const generateAdHocExportSuccess = ( state, action ) => {
//     return updateObject( state, {generatedAdHocExport: action.generatedAdHocExport, loading: false} );
// };
const generateAdHocExportStart = ( state, action ) => {
    return updateObject( state, {loadingGeneratedAdHocExport: true} );
};
const generateAdHocExportSuccess = ( state, action ) => {
    return updateObject( state, {error: null, generateAdHocExport: action.generateAdHocExport, loadingGeneratedAdHocExport: false} );
};
const generateAdHocExportFail = ( state, action ) => {
    return updateObject( state, {error: action.error} );
};

// Generate JDF Export
const generateJDFexportStart = ( state, action ) => {
    return updateObject( state, {loadingGeneratedJDFexport: true} );
};
const generateJDFexportSuccess = ( state, action ) => {
    return updateObject( state, {error: null, generateJDFexport: action.generateJDFexport, loadingGeneratedJDFexport: false} );
};
const generateJDFexportFail = ( state, action ) => {
    return updateObject( state, {error: action.error} );
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.FETCH_APIS_SUCCESS: return fetchApisSuccess( state, action );        

        case actionTypes.GENERATE_DEXX_CBS_EXPORT_START: return generateDexxCBSExportStart( state, action );
        case actionTypes.GENERATE_DEXX_CBS_EXPORT_SUCCESS: return generateDexxCBSExportSuccess( state, action );
        case actionTypes.GENERATE_DEXX_CBS_EXPORT_FAIL: return generateDexxCBSExportFail( state, action );

        case actionTypes.GENERATE_DEXX_CnC_EXPORT_START: return generateDexxCnCExportStart( state, action );
        case actionTypes.GENERATE_DEXX_CnC_EXPORT_SUCCESS: return generateDexxCnCExportSuccess( state, action );
        case actionTypes.GENERATE_DEXX_CnC_EXPORT_FAIL: return generateDexxCnCExportFail( state, action );
        
        case actionTypes.GENERATE_STOCK_EXPORT_START: return generateStockExportStart( state, action );
        case actionTypes.GENERATE_STOCK_EXPORT_SUCCESS: return generateStockExportSuccess( state, action );
        case actionTypes.GENERATE_STOCK_EXPORT_FAIL: return generateStockExportFail( state, action );

        case actionTypes.GENERATE_DEXX_CnC_MODULE_START: return getDexxCnCModuleVerkoopersStart( state, action );
        case actionTypes.GENERATE_DEXX_CnC_MODULE_SUCCESS: return getDexxCnCModuleVerkoopersSuccess( state, action );
        case actionTypes.GENERATE_DEXX_CnC_MODULE_FAIL: return getDexxCnCModuleVerkoopersFail( state, action );
        
        case actionTypes.DEXX_CnC_UPDATE_IKLANT_SUCCESS: return dexxCnCModuleUpdateIKLANTSuccess( state, action );

        case actionTypes.GENERATE_QUADRA_EXPORT_START: return generateQuadraExportPreviewStart( state, action );
        case actionTypes.GENERATE_QUADRA_EXPORT_SUCCESS: return generateQuadraExportPreviewSuccess( state, action );
        case actionTypes.GENERATE_QUADRA_EXPORT_FAIL: return generateQuadraExportPreviewFail( state, action );

        case actionTypes.GENERATE_QUADRA_EXPORT_FAULTS_START: return generateQuadraExportFaultsStart( state, action );
        case actionTypes.GENERATE_QUADRA_EXPORT_FAULTS_SUCCESS: return generateQuadraExportFaultsSuccess( state, action );
        case actionTypes.GENERATE_QUADRA_EXPORT_FAULTS_FAIL: return generateQuadraExportFaultsFail( state, action );

        case actionTypes.GENERATE_ADHOC_EXPORT_START: return generateAdHocExportStart( state, action );
        case actionTypes.GENERATE_ADHOC_EXPORT_SUCCESS: return generateAdHocExportSuccess( state, action );
        case actionTypes.GENERATE_ADHOC_EXPORT_FAIL: return generateAdHocExportFail( state, action );

        case actionTypes.GENERATE_DEBNR_DEXX_CBS_EXPORT_START: return generateDebnrDexxCBSExportStart( state, action );
        case actionTypes.GENERATE_DEBNR_DEXX_CBS_EXPORT_SUCCESS: return generateDebnrDexxCBSExportSuccess( state, action );
        case actionTypes.GENERATE_DEBNR_DEXX_CBS_EXPORT_FAIL: return generateDebnrDexxCBSExportFail( state, action );

        case actionTypes.GENERATE_JDF_EXPORT_START: return generateJDFexportStart( state, action );
        case actionTypes.GENERATE_JDF_EXPORT_SUCCESS: return generateJDFexportSuccess( state, action );
        case actionTypes.GENERATE_JDF_EXPORT_FAIL: return generateJDFexportFail( state, action );

        default: return state;
    }
};

export default reducer;