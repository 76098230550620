import React from 'react';
import axios from 'axios';
import ReactTable from "react-table";
import { FuseAnimate, FusePageSimple } from '../../../../@fuse';
import { Icon, Typography, LinearProgress, Tooltip, Button, } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query'
// import UserStatsManagerAddUsers from './UserStatsManagerAddUsers';
import Alert from '../../../components/UI/Alert/Alert'
import FavoriteUsersDialog from '../../iCRM/Admin/Users/FavoriteUsersDialog';
import AanvraagFormulier from '../../../../pdfs/iaanvraagformulier.pdf';

const UserStatsTable = () => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const { t } = useTranslation();
    const queryClient = useQueryClient()
    const { data: userStats, isLoading } = useQuery(
        "userStatsAllPages",
        async () => {
            const res = await axios.get(
                "monitor/GetUserStatsAllPages"
            );
            return res.data;
        },
        {
            staleTime: 300000
        }
    );

    return (
        <>
            {dialogOpen && <FavoriteUsersDialog handleClose={() => {
                    setDialogOpen(false); 
                    queryClient.invalidateQueries('userStatsAllPages')
                }
            } 
            />
            }
            <FusePageSimple
                header={
                    <div className="flex flex-1 items-center justify-between p-24">
                        <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                <Icon className="text-18" color="action">home</Icon>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">{t("VISITS_PER_USER")}</Typography>
                            </div>
                            <Typography variant="h6">{t("VISITS_PER_USER")}</Typography>
                        </div>
                        <div className="flex items-end items-center" >
                            <Tooltip title={t("DOWNLOAD") + ' ' +  t('user_application_form')}>
                                <a href={AanvraagFormulier} download="iaanvraagformulier" target='_blank' rel="noreferrer">
                                    <Button
                                        className=""
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<Icon>cloud_download</Icon>}
                                    >
                                        {t('user_application_form')}
                                    </Button>
                                </a>
                            </Tooltip>
                            <Tooltip title={t("EDIT") + " Favorite users"}>
                                <Button className="successButton p-24" onClick={() => setDialogOpen(true)}>
                                    <Icon>star</Icon>
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                }
                content={!isLoading && userStats.length === 0 ?
                    <Alert status="AlertWarning" title="Je hebt nog geen favoriete gebruikers toegewezen. Klik op het sterretje rechtsbovenin de pagina om te selecteren welke gebruikers je wil zien."></Alert>
                    : !isLoading ? 
                    <FuseAnimate animation="transition.slideUpIn" delay={300}>
                        <div className="w-full">
                            <ReactTable
                                className="-striped -highlight border-0"
                                data={userStats}
                                columns={[
                                    {
                                        Header: t("NAME"),
                                        accessor: "userName",
                                        filterable: true,
                                        className: "font-bold justify-center",
                                        Cell: (row) => <Link to={"/stats/user/" + row.original.azureId}>{row.value}</Link>
                                    },
                                    {
                                        Header: "Aantal bezoeken",
                                        accessor: "amountOfVisits",
                                        filterable: true,
                                        className: "justify-center",
                                    },
                                    {
                                        Header: "Gem. tijd op pagina",
                                        accessor: "avgTimeOnPage",
                                        filterable: true,
                                        className: "justify-center",
                                        width: 150,
                                        Cell: ({ value }) => new Date(value * 1000).toISOString().substr(14, 5)

                                    },
                                ]}
                                pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                                defaultPageSize={15}
                                noDataText="No customers found"
                            />
                        </div>
                    </FuseAnimate>
                    : <div className="flex h-full">
                        <div className="m-auto">
                            <LinearProgress />
                            <Typography>Fetching statistics...</Typography>
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default UserStatsTable;