import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import {withStyles, Icon, Typography, Checkbox, Snackbar, IconButton, FormControl, Select, InputLabel, MenuItem} from '@material-ui/core';
import {FuseAnimateGroup, FusePageSimple} from '../../../../../../@fuse';
import {connect} from 'react-redux';
import * as actions from '../../../../../store/crm/admin/actions';
import ReactTable from 'react-table';
import _ from 'lodash';

const styles = theme => ({
    textSuccess: {
        color: 'green'
    },
    textDanger: {
        color: 'red'
    },
    pointer: {
        cursor: 'pointer',
    },
})

class ManageUserAccess extends Component {

    state = {
        user: {},
        groups: [],
        companies: [],
        selectedCompany: {},
    }

    componentDidMount() {
        const {azureId} = this.props.location.state.user;
        this.props.onFetchAccessableGroupcodes(azureId)
        this.props.onFetchUnaccessableGroupcodes(azureId);
    }

    componentDidUpdate() {
        const {user, companies} = this.props.location.state;
        const {accessableGroups, unaccessableGroups, loadingAccessableGroups, loadingUnaccessableGroups} = this.props;
        if(Object.keys(user).length > 0 && Object.keys(this.state.user).length === 0) {
            this.setState({user, companies, selectedCompany: companies[0].id});
        }
        const groups = [...accessableGroups].concat(unaccessableGroups);
        if((!loadingAccessableGroups && !loadingUnaccessableGroups) && !_.isEqual(groups, this.state.groups)) {
            this.setState({groups});
        }
    }

    goBackToUserAccess = () => {
        this.props.history.push('/icrm/users');
    }

    handleChange = (groupCode, companyId) => event => {
        const userAccess = {azureId: this.state.user.azureId, groupCode, companyId, accessable: event.target.checked};
        this.props.onUpdateUserGroup(userAccess);
    };

    handleCompanyChange = event => {
        this.setState({selectedCompany: event.target.value});
    }

    handleSnackbarClose = () => {
        this.props.onCloseUpdateSnackbar();
    }

    render() {
        const {user, groups, companies, selectedCompany} = this.state;
        const {classes, updatedGroup} = this.props;
        const groupsByCompanyId = groups.filter(group => group.companyId === selectedCompany);

        return (
            <FusePageSimple
            header={
                <div className="flex flex-1 items-center justify-between p-24">
                    <div className="flex flex-col">
                        <div className="flex items-center mb-16">
                            <Icon className="text-18" color="action">home</Icon>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">iCRM</Typography>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">Admin</Typography>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary" className={classes.pointer} onClick={this.goBackToUserAccess}>Users</Typography>
                        </div>
                        <Typography variant="h6">Manage {user.name}</Typography>
                    </div>
                    <div className="flex items-end">
                        <FormControl className={classes.formControl}>
                            <InputLabel id="company-label">Company</InputLabel>
                            <Select
                            id="company-select"
                            value={selectedCompany}
                            onChange={this.handleCompanyChange}
                            >
                                {companies.map(company => (
                                    <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            }
            content={
                <FuseAnimateGroup animation="transition.slideUpIn" delay={300}>
                    <div className="flex flex-wrap w-full">
                    <ReactTable
                            className="w-full -striped -highlight border-0"
                            data={groupsByCompanyId}
                            columns={[
                                {
                                    Header    : "Name",
                                    accessor  : "groupName",
                                    filterable: true,
                                    className : "font-bold justify-center",
                                },
                                {
                                    Header    : "Groupcode",
                                    accessor  : "groupCode",
                                    filterable: true,
                                    className: "justify-center",
                                },
                                {
                                    Header    : "Accessable",
                                    accessor  : "accessable",
                                    filterable: true,
                                    className: "justify-center",
                                    Cell: row => 
                                        row.value ? <Icon className={classes.textSuccess + " text-16"}>check</Icon> : 
                                        <Icon className={classes.textDanger + " text-16"} color="action">close</Icon>
                                    
                                },
                                {
                                    Header    : "Switch Access",
                                    accessor  : "accessable",
                                    filterable: false,
                                    className: "justify-center",
                                    Cell: row => 
                                    <Checkbox checked={row.value} onChange={this.handleChange(row.original.groupCode, row.original.companyId)} value="accessCheck" />
                                    
                                },
                            ]}
                            pageSizeOptions={[5, 10, 20, 50]}
                            defaultPageSize={10}
                            noDataText="No users found"
                        />
                        <Snackbar
                            style={{position: 'absolute'}}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={updatedGroup}
                            autoHideDuration={3000}
                            onClose={this.handleSnackbarClose}
                            message={<span id="message-id">User access has been successfully <strong>saved</strong>!</span>}
                            action={[
                                <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.handleSnackbarClose}
                                >
                                    <Icon>close</Icon>
                                </IconButton>,
                            ]}
                        />
                    </div>
                </FuseAnimateGroup>
            }
            />
        )
    }
}

const mapStateToProps = ({admin}) => {
    return {
        accessableGroups: admin.accessableGroups,
        unaccessableGroups: admin.unaccessableGroups,
        loadingAccessableGroups: admin.loadingAccessableGroups,
        loadingUnaccessableGroups: admin.loadingUnaccessableGroups,
        updatedGroup: admin.updatedGroup,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchAccessableGroupcodes: (azureId) => dispatch(actions.fetchAccessableGroups(azureId)),
        onFetchUnaccessableGroupcodes: (azureId) => dispatch(actions.fetchUnaccessableGroups(azureId)),
        onUpdateUserGroup: (userAccess) => dispatch(actions.updateUserGroup(userAccess)),
        onCloseUpdateSnackbar: () => dispatch(actions.closeUpdateUserGroupSnackbar()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ManageUserAccess)));