export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';

export const FETCH_USER_ROLES_START = 'FETCH_USER_ROLES_START';
export const FETCH_USER_ROLES_SUCCESS = 'FETCH_USER_ROLES_SUCCESS';
export const FETCH_USER_ROLES_FAIL = 'FETCH_USER_ROLES_FAIL';

export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';

export const FETCH_ACCESSABLE_GROUPS_START = 'FETCH_ACCESSABLE_GROUPS_START';
export const FETCH_ACCESSABLE_GROUPS_SUCCESS = 'FETCH_ACCESSABLE_GROUPS_SUCCESS';

export const FETCH_UNACCESSABLE_GROUPS_START = 'FETCH_UNACCESSABLE_GROUPS_START';
export const FETCH_UNACCESSABLE_GROUPS_SUCCESS = 'FETCH_UNACCESSABLE_GROUPS_SUCCESS';

export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const CLOSE_UPDATE_USER_GROUP_SNACKBAR = 'CLOSE_UPDATE_USER_GROUP_SNACKBAR';

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

export const FETCH_OPEN_POSTEN_START = 'FETCH_OPEN_POSTEN_START';
export const FETCH_OPEN_POSTEN_SUCCESS = 'FETCH_OPEN_POSTEN_SUCCESS';
export const SEND_OPEN_POST_SUCCESS = 'SEND_OPEN_POST_SUCCESS';
export const SEND_ALL_OPEN_POSTEN_START = 'SEND_ALL_OPEN_POSTEN_START';
export const SEND_ALL_OPEN_POSTEN_SUCCESS = 'SEND_ALL_OPEN_POSTEN_SUCCESS';

export const REFRESH_DEBITER_GROUPS_START = 'REFRESH_DEBITER_GROUPS_START';
export const REFRESH_DEBITER_GROUPS_SUCCESS = 'REFRESH_DEBITER_GROUPS_SUCCESS';

export const IMPORT_USERS_SIB_SUCCESS = 'IMPORT_USERS_SIB_SUCCESS';
export const IMPORT_USERS_SIB_FAIL = 'IMPORT_USERS_SIB_FAIL';

export const FETCH_LATEST_IMPORT_SUCCESS = 'FETCH_LATEST_IMPORT_SUCCESS';
export const FETCH_LATEST_IMPORT_FAIL = 'FETCH_LATEST_IMPORT_FAIL';