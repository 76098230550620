
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const FETCH_AD_USERS_START = 'FETCH_AD_USERS_START';
export const FETCH_AD_USERS_SUCCESS = 'FETCH_AD_USERS_SUCCESS';
export const FETCH_AD_USERS_FAIL = 'FETCH_AD_USERS_FAIL';

export const FETCH_AD_USERME_START = 'FETCH_AD_USERME_START';
export const FETCH_AD_USERME_SUCCESS = 'FETCH_AD_USERME_SUCCESS';
export const FETCH_AD_USERME_FAIL = 'FETCH_AD_USERME_FAIL';

export const FETCH_USER_BYAZUREID_START = 'FETCH_USER_BYAZUREID_START';
export const FETCH_USER_BYAZUREID_SUCCESS = 'FETCH_USER_BYAZUREID_SUCCESS';
export const FETCH_USER_BYAZUREID_FAIL = 'FETCH_USER_BYAZUREID_FAIL';

export const ADD_USER_START = 'ADD_USER_START';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';
export const USER_INIT = 'USER_INIT';

// GroupCodes
export const FETCH_ALL_GROUPS_SUCCESS = 'FETCH_ALL_GROUPS_SUCCESS';
export const FETCH_ACCESSABLE_GROUPCODES_SUCCESS = 'FETCH_ACCESSABLE_GROUPCODES_SUCCESS';
export const FETCH_UNACCESSABLE_GROUPCODES_SUCCESS = 'FETCH_UNACCESSABLE_GROUPCODES_SUCCESS';
export const FETCH_CURRENT_GROUPCODE_SUCCESS = 'FETCH_CURRENT_GROUPCODE_SUCCESS';
export const FETCH_LOCAL_GROUPCODE_SUCCESS = 'FETCH_LOCAL_GROUPCODE_SUCCESS';
export const UPDATE_CURRENT_GROUPCODE_SUCCESS = 'UPDATE_CURRENT_GROUPCODE_SUCCESS';


export const FETCH_USER_BY_ID_START = 'FETCH_USER_BY_ID_START';
export const FETCH_USER_BY_ID_SUCCESS = 'FETCH_USER_BY_ID_SUCCESS';
export const FETCH_USER_BY_ID_FAIL = 'FETCH_USER_BY_ID_FAIL';

export const SEND_LOGIN_START = 'SEND_LOGIN_START';
export const SEND_LOGIN_SUCCESS = 'SEND_LOGIN_SUCCESS';
export const SEND_LOGIN_FAIL = 'SEND_LOGIN_FAIL';

export const UPDATE_USER_FIRST_LOGIN_START = 'UPDATE_USER_FIRST_LOGIN_START';
export const UPDATE_USER_FIRST_LOGIN_SUCCESS = 'UPDATE_USER_FIRST_LOGIN_SUCCESS';
export const UPDATE_USER_FIRST_LOGIN_FAIL = 'UPDATE_USER_FIRST_LOGIN_FAIL';

export const FETCH_CHECK_USER_HAS_PASSWORD_START = 'FETCH_CHECK_USER_HAS_PASSWORD_START';
export const FETCH_CHECK_USER_HAS_PASSWORD_SUCCESS = 'FETCH_CHECK_USER_HAS_PASSWORD_SUCCESS';
export const FETCH_CHECK_USER_HAS_PASSWORD_FAIL = 'FETCH_CHECK_USER_HAS_PASSWORD_FAIL';

export const REFRESH_TOKEN_START = 'REFRESH_TOKEN_START';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'REFRESH_TOKEN_FAIL';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const UPDATE_PASSWORD_START = 'UPDATE_PASSWORD_START';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';

export const SET_LOGINFAILED_FALSE = 'SET_LOGINFAILED_FALSE';

export const CHECK_USER_HAS_EMAIL_START = 'CHECK_USER_HAS_EMAIL_START';
export const CHECK_USER_HAS_EMAIL_SUCCESS = 'CHECK_USER_HAS_EMAIL_SUCCESS';
export const CHECK_USER_HAS_EMAIL_FAIL = 'CHECK_USER_HAS_EMAIL_FAIL';

export const FETCH_ROLES_START = 'FETCH_ROLES_START';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAIL = 'FETCH_ROLES_FAIL';