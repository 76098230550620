import React, { Component } from "react";
import { } from "react-router-dom";
import styled from "styled-components";
import { getGroupcode } from "../Auth/Auth";

const Iframe = styled.iframe`
    width: 100%;
    height: 90vh;
    overflow: hidden;
    @media(max-width: 480px) {
      height: 90vh;
      padding-bottom: calc(1em + env(safe-area-inset-bottom));
    }
`

const iCRMPremium = (props) => {
    let premiumUrl = process.env.REACT_APP_ICRMPREMIUMUIURL + props.location.pathname;
    premiumUrl = premiumUrl + "?groupCode=" + getGroupcode();
    return (
        <Iframe
            title="icrmpremium"
            src={premiumUrl}
        />
    );
}

export default iCRMPremium;