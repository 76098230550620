import React from 'react';
import { Icon, Typography, FormControl, Select, InputLabel, MenuItem, Tooltip, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const TaskHeader = ({ headerTitle, countries, selectedCountry, setSelectedCountry, setSelectedBusinessUnit, businessUnits, selectedBusinessUnit, setFavoriteUsersDialogOpen}) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-1 items-center justify-between p-24">
            <div className="flex flex-col">
                <div className="flex items-center mb-16">
                        <Icon className="text-18" color="action">home</Icon>
                        <Icon className="text-16" color="action">chevron_right</Icon>
                        <Typography color="textSecondary">iCRM</Typography>
                        <Icon className="text-16" color="action">chevron_right</Icon>
                        <Typography color="textSecondary">Manager</Typography>
                        <Icon className="text-16" color="action">chevron_right</Icon>
                        <Typography color="textSecondary">{headerTitle}</Typography>
                </div>
                <Typography variant="h6">{headerTitle}</Typography>
            </div>
                
            <div className="flex items-end">
                <FormControl className="p-12">
                    <InputLabel id="bunit-label">Business Unit</InputLabel>
                    <Select
                        id="bunit-select"
                        value={selectedBusinessUnit}
                        onChange={(event) => setSelectedBusinessUnit(event.target.value)}
                    >
                        {businessUnits.map(businessUnit => (
                            <MenuItem key={businessUnit.key} value={businessUnit.key}>{businessUnit.value}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className="p-12">
                    <InputLabel id="land-label">{t("COUNTRY")}</InputLabel>
                    <Select
                        id="land-select"
                        value={selectedCountry}
                        onChange={(event) => setSelectedCountry(event.target.value)}
                    >
                        {countries.map(country => (
                            <MenuItem key={country.key} value={country.key}>{country.value}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {setFavoriteUsersDialogOpen && 
                    <Tooltip title={t("EDIT") + " Favorite users"}>
                        <Button className="successButton p-24" onClick={setFavoriteUsersDialogOpen}>
                            <Icon>star</Icon>
                        </Button>
                    </Tooltip>}
            </div>
        </div>
    )
}

export default TaskHeader;