export const FETCH_SPECIALOFFERS_SUCCESS = 'FETCH_SPECIALOFFERS_SUCCESS';

export const FETCH_SPECIALOFFERS_PRODUCTS_START = 'FETCH_SPECIALOFFERS_PRODUCTS_START';
export const FETCH_SPECIALOFFERS_PRODUCTS_SUCCESS = 'FETCH_SPECIALOFFERS_PRODUCTS_SUCCESS';

export const FETCH_PRODUCTS_BY_SPECIALOFFER_START = 'FETCH_PRODUCTS_BY_SPECIALOFFER_START';
export const FETCH_PRODUCTS_BY_SPECIALOFFER_SUCCESS = 'FETCH_PRODUCTS_BY_SPECIALOFFER_SUCCESS';
export const FETCH_PRODUCTS_BY_SPECIALOFFER_FAIL = 'FETCH_PRODUCTS_BY_SPECIALOFFER_FAIL';

export const FETCH_USERS_BY_SPECIALOFFER_START = 'FETCH_USERS_BY_SPECIALOFFER_START';
export const FETCH_USERS_BY_SPECIALOFFER_SUCCESS = 'FETCH_USERS_BY_SPECIALOFFER_SUCCESS';

export const FETCH_SPECIALOFFER_LOG_START = 'FETCH_SPECIALOFFER_LOG_START';
export const FETCH_SPECIALOFFER_LOG_SUCCESS = 'FETCH_SPECIALOFFER_LOG_SUCCESS';

export const INSERT_SEPCIALOFFER_SUCCESS = 'INSERT_SEPCIALOFFER_SUCCESS';

export const DELETE_SEPCIALOFFER_SUCCESS = 'DELETE_SEPCIALOFFER_SUCCESS';

export const EDIT_SEPCIALOFFER_SUCCESS = 'EDIT_SEPCIALOFFER_SUCCESS';

export const CHANGE_USERACCESS_SUCCESS = 'CHANGE_USERACCESS_SUCCESS';

export const SEND_SPECIALOFFER_SUCCESS = 'SEND_SPECIALOFFER_SUCCESS';

export const FETCH_LATEST_SIB_STATUS_UPDATE_START = 'FETCH_LATEST_SIB_STATUS_UPDATE_START';
export const FETCH_LATEST_SIB_STATUS_UPDATE_SUCCESS = 'FETCH_LATEST_SIB_STATUS_UPDATE_SUCCESS';
export const FETCH_LATEST_SIB_STATUS_UPDATE_FAIL = 'FETCH_LATEST_SIB_STATUS_UPDATE_FAIL';

export const CLOSE_INSERT_SNACKBAR = 'CLOSE_INSERT_SNACKBAR';
export const CLOSE_DELETE_SNACKBAR = 'CLOSE_DELETE_SNACKBAR';
export const CLOSE_EDIT_SNACKBAR = 'CLOSE_EDIT_SNACKBAR';
export const CLOSE_USERACCESS_SNACKBAR = 'CLOSE_USERACCESS_SNACKBAR';