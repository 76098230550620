import React, { Component } from 'react'
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default class DatePicker extends Component {
    
    // shouldComponentUpdate(nextProps) {
    //     return nextProps.value !== this.props.value || nextProps.minDate !== this.props.minDate || nextProps.label !== this.props.label || nextProps.disabled !== this.props.disabled;
    // }

    render() {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    inputVariant="outlined"
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    {...this.props}
                />
            </MuiPickersUtilsProvider>
        )
    }
}
