import React, { PureComponent } from 'react';
import {
  ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
  Legend, ResponsiveContainer
} from 'recharts';
import {Card, Typography, IconButton, Badge, Icon } from '@material-ui/core';

export default class Example extends PureComponent {

    render() {
        const {labels, overviewCollections, TotalNewCustomers, TotalLostCustomers, onDownloadExcel} = this.props;
        
        // const labels = ['10','9','8'];
        // const LosingCustomers = [10,30,20,25,55,15,35];
        // const totals = [1694125,1394525,1994925];

        const data = labels.map((label, index) => {
            return {
                name: label,
                FirstOrder: overviewCollections[0].values[index],
                LosingCustomers: overviewCollections[1].values[index]                
                // LosingCustomers: LosingCustomers[index]
            };
        })
        // console.log(onClick);
        return (
            <Card className="w-full rounded-8 shadow-none border-1">

                <div className="relative p-24 flex flex-row items-center justify-between">

                    <div className="flex flex-col">       
                        {/* <Typography className="h3 sm:h2">{this.props.headerText}</Typography> */}
                        <Typography className="h3 sm:h2">{this.props.headerText} : 
                        {TotalNewCustomers !== undefined ? <> {TotalNewCustomers.toLocaleString()}</> : null} / {TotalLostCustomers !== undefined ? <> {TotalLostCustomers.toLocaleString()}</> : null}
                        </Typography>
                        
                    </div>
                    <div className="flex flex-row items-center">
                        <IconButton onClick={onDownloadExcel}>
                            <Badge color="secondary">
                                <Icon>save_alt</Icon>
                            </Badge>
                        </IconButton>
                    </div>
                </div>

                <Typography className="relative h-200 sm:h-320 sm:pb-16">
                    <ResponsiveContainer width="100%">
                        <ComposedChart
                            width={800}
                            height={375}
                            data={data}
                            margin={{
                            top: 20, right: 20, bottom: 20, left: 20,
                            }}
                        >
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis dataKey="name" name="Week nr" />
                            <YAxis yAxisId="left" dataKey="LosingCustomers" name="Losing Customers" orientation="left" domain={[0,'dataMax']} />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="FirstOrder" name="First Order" barSize={12} fill="#008000" yAxisId="left" />
                            <Bar dataKey="LosingCustomers" name="Lost customers" barSize={12} fill="#f44336" yAxisId="left" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </Typography>
            </Card>
        );
    }
}