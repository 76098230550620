import React from 'react';
import {Paper, withStyles, Typography, Tooltip, Icon} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {numberToLocale} from '../../../../../components/Functions/NumberToLocale';
import DashboardBlockSkeleton from './DashboardSkeletons/DashboardBlockSkeleton';
import { getCompany } from '../../../../Auth/Auth';

const styles = theme => ({
    primaryPaper: {
        backgroundColor: theme.palette.primary.main,
        color: '#ffffff'
    },
    secondaryPaper: {
        backgroundColor: theme.palette.secondary.main,
        color: '#ffffff'
    },
    vAlign: {
        verticalAlign: 'center'
    },
})

const thisYear = new Date().getFullYear();
const nextYear = new Date().getFullYear() + 1;

export default withStyles(styles)(props => {
    const {t} = useTranslation();
    const isHbxDeco = getCompany() === 4;
    const {classes, blocksData, hbxDecoBlocks, marges} = props;
    const isLoaded = isHbxDeco ? hbxDecoBlocks : Object.keys(blocksData).length > 0;

    // Get turnoverDistribution total
    let margeTotal = 0;
    let verkoopbedragTotal = 0;
    let verkoopbedragPercentage = 0;
    if(marges) {
        margeTotal = marges.reduce((tempTotal,currentValue) => tempTotal = tempTotal + currentValue.value , 0 );
        verkoopbedragTotal = marges.reduce((tempTotal,currentValue) => tempTotal = tempTotal + currentValue.verkoopbedrag , 0 );
        verkoopbedragPercentage = (margeTotal / verkoopbedragTotal * 100).toFixed(2);
    }

    // if(isHbxDeco) return null;

    // console.log(Marges);

    let firstBlock = null;
    if(isHbxDeco) {
        firstBlock = (
            <div key="firstBlock" className="widget flex w-full sm:w-1/3 md:w-1/5 p-12">
                {isLoaded && hbxDecoBlocks ? 
                <Paper className={classes.primaryPaper + " w-full p-16 text-center rounded-8 shadow-none border-1"} elevation={1}>
                    &euro; <span className={classes.vAlign + ' text-18'}> 
                    {numberToLocale(hbxDecoBlocks.realisedTurnover + hbxDecoBlocks.realisedTurnoverORDERKP)}
                    </span><br />
                    {/* <Tooltip title={`Omzet: ${hbxDecoBlocks.realisedTurnover} + Facturbak: ${hbxDecoBlocks.realisedTurnoverORDERKP}`}>
                        <span className="text-16" style={{color: '#ffffff'}}>
                            Gerealiseerde omzet
                        </span>
                    </Tooltip> */}
                    <Tooltip 
                    title={
                        <React.Fragment>
                        <Typography color="inherit">Omzet : {numberToLocale(hbxDecoBlocks.realisedTurnover)}</Typography>
                        <Typography color="inherit">Facturbak : {numberToLocale(hbxDecoBlocks.realisedTurnoverORDERKP)}</Typography>
                        {/* <em>{'Validated on : '}{moment(row.original.validateD_DATE).format('DD-MM-YYYY @ HH:mm')}</em> */}
                        </React.Fragment>
                    }>
                    <span className="text-16" style={{color: '#ffffff'}}>
                        Gerealiseerde omzet voor {thisYear}
                    </span>
                    </Tooltip>
                </Paper>
                : <DashboardBlockSkeleton classToApply={classes.primaryPaper} />}
            </div>
        )
    } else {
        firstBlock = (
            <div key="firstBlock" className="widget flex w-full sm:w-1/3 md:w-1/6 p-12">
                {isLoaded ? 
                <Paper className={classes.primaryPaper + " w-full p-16 text-center rounded-8 shadow-none border-1"} elevation={1}>
                    <span className={classes.vAlign + ' text-20'}> 
                    {numberToLocale(((blocksData.outstandingWeeks.unpaid) / (blocksData.outstandingWeeks.turnover / blocksData.difference)))}
                    </span><br />
                    <span className="text-16" style={{color: '#ffffff'}}>
                        {t("OPENSTAAND")}
                    </span>
                </Paper>
                : <DashboardBlockSkeleton classToApply={classes.primaryPaper} />}
            </div>
        )
    }

    let secondBlock = null;
    if(isHbxDeco) {
        secondBlock = (
            <div key="secondBlock" className="widget flex w-full sm:w-1/3 md:w-1/5 p-12">
                {isLoaded ? 
                <Paper className={classes.secondaryPaper + ' w-full p-16 text-center rounded-8 shadow-none border-1'} elevation={1}>
                    <span className={classes.vAlign + ' text-20'}>
                    &euro; {numberToLocale(hbxDecoBlocks.reservedTurnover)}
                    </span><br />
                    <span className="text-16">
                        Geschreven orders voor {thisYear}
                    </span>
                    <br />
                    <span className={classes.vAlign + ' text-20'}>
                    &euro; {numberToLocale(hbxDecoBlocks.reservedTurnoverNextYear)}
                    </span><br />
                    <span className="text-16">
                        Geschreven orders voor {nextYear}
                    </span>
                </Paper>
                : <DashboardBlockSkeleton classToApply={classes.secondaryPaper} />}
            </div> 
        )
    } else {
        secondBlock = (
            <div key="secondBlock" className="widget flex w-full sm:w-1/3 md:w-1/6 p-12">
                {isLoaded ? 
                <Paper className={classes.secondaryPaper + ' w-full p-16 text-center rounded-8 shadow-none border-1'} elevation={1}>
                    <span className={classes.vAlign + ' text-20'}>
                    &euro; {numberToLocale(blocksData.orderDetails.turnover / blocksData.orderDetails.invoices)}
                    </span><br />
                    <span className="text-16">
                        {t("AVGORDERGROOTTE")}
                    </span>
                </Paper>
                : <DashboardBlockSkeleton classToApply={classes.secondaryPaper} />}
            </div> 
        )
    }

    let thirdBlock = null;
    if(isHbxDeco) {
        thirdBlock = (
            <div key="thirdBlock" className="widget flex w-full sm:w-1/3 md:w-1/5 p-12">
            {isLoaded ? 
                <Paper className="w-full p-16 text-center rounded-8 shadow-none border-1" elevation={1}>
                    <span className={classes.vAlign + ' text-20'}>
                    &euro; {numberToLocale(hbxDecoBlocks.reservedTurnover + hbxDecoBlocks.reservedTurnoverNextYear)} 
                    <br /> + &euro; {numberToLocale(hbxDecoBlocks.reservedTurnoverAndBestverd)}
                    </span><br />
                    {/* <Tooltip title={`Reserved: ${hbxDecoBlocks.reservedTurnover} + Bestverd: ${hbxDecoBlocks.reservedTurnoverAndBestverd}`}>
                        <span className="text-16">
                            Totaal geschreven orders
                        </span>
                    </Tooltip> */}
                    <Tooltip 
                    title={
                        <React.Fragment>
                        {/* <Typography color="inherit">Omzet : {numberToLocale(hbxDecoBlocks.realisedTurnover)}</Typography>
                        <Typography color="inherit">Facturbak : {numberToLocale(hbxDecoBlocks.realisedTurnoverORDERKP)}</Typography> */}
                        <Typography color="inherit">Geschreven : {numberToLocale(hbxDecoBlocks.reservedTurnover + hbxDecoBlocks.reservedTurnoverNextYear)}</Typography>
                        <Typography color="inherit">Vorig jaar geschreven : {numberToLocale(hbxDecoBlocks.reservedTurnoverAndBestverd)}</Typography>
                        <Typography color="inherit">Totaal : {numberToLocale(hbxDecoBlocks.reservedTurnover + hbxDecoBlocks.reservedTurnoverNextYear + hbxDecoBlocks.reservedTurnoverAndBestverd)}</Typography>
                        {/* <em>{'Validated on : '}{moment(row.original.validateD_DATE).format('DD-MM-YYYY @ HH:mm')}</em> */}
                        </React.Fragment>
                    }>
                    <span className="text-14">
                    Totaal geschreven orders
                    </span>
                    </Tooltip>
                </Paper>
                : <DashboardBlockSkeleton />}
            </div> 
        )
    } else {
        thirdBlock = (
            <div key="thirdBlock" className="widget flex w-full sm:w-1/3 md:w-1/6 p-12">
            {isLoaded ? 
                <Paper className="w-full p-16 text-center rounded-8 shadow-none border-1" elevation={1}>
                    <span className={classes.vAlign + ' text-20'}>
                    &#37; {numberToLocale(blocksData.activeClients / blocksData.totalClients * 100)}
                    </span><br />
                    <span className="text-16">
                        {t("AMNTCLIENTACTIVE")}
                    </span>
                </Paper>
                : <DashboardBlockSkeleton />}
            </div> 
        )
    }

    let fourthBlock = null;
    if(isHbxDeco) {
        fourthBlock = (
            <div key="fourthBlock" className="widget flex w-full sm:w-1/3 md:w-1/5 p-12">
            {isLoaded ? 
                <Paper className="w-full p-16 text-center rounded-8 shadow-none border-1" elevation={1}>
                    <span className={classes.vAlign + ' text-20'}>
                    {/* &euro;{numberToLocale(hbxDecoBlocks.targetGeschrevenTurnover + hbxDecoBlocks.targetGerealiseerdeTurnover)} */}
                    &euro; {numberToLocale(hbxDecoBlocks.realisedTurnover + hbxDecoBlocks.realisedTurnoverORDERKP)}
                    {/* <br /> + &euro; {numberToLocale(hbxDecoBlocks.realisedTurnoverORDERKP)} */}
                    <br /> + &euro; {numberToLocale(hbxDecoBlocks.reservedTurnover + hbxDecoBlocks.reservedTurnoverNextYear + hbxDecoBlocks.reservedTurnoverAndBestverd)}
                    {/* <br /> + &euro; {numberToLocale(hbxDecoBlocks.reservedTurnoverAndBestverd)} */}
                    </span><br />
                    <Tooltip 
                    title={
                        <React.Fragment>
                        <Typography color="inherit">Gerealiseerde omzet : {numberToLocale(hbxDecoBlocks.realisedTurnover)}</Typography>
                        {/* <Typography color="inherit">Facturenbak : {numberToLocale(hbxDecoBlocks.realisedTurnoverORDERKP)}</Typography> */}
                        <Typography color="inherit">Geschreven : {numberToLocale(hbxDecoBlocks.reservedTurnover + hbxDecoBlocks.reservedTurnoverNextYear)}</Typography>
                        <Typography color="inherit">Vorig jaar geschreven : {numberToLocale(hbxDecoBlocks.reservedTurnoverAndBestverd)}</Typography>
                        {/* <em>{'Validated on : '}{moment(row.original.validateD_DATE).format('DD-MM-YYYY @ HH:mm')}</em> */}
                        </React.Fragment>
                    }>
                    <span className="text-14">
                        Gerealiseerde omzet
                        <br /> + Totaal geschreven orders
                    </span>
                    </Tooltip>
                    {/* <Tooltip title={`Gerealiseerde omzet: ${hbxDecoBlocks.realisedTurnover} + Facturbak: ${hbxDecoBlocks.realisedTurnoverORDERKP} + Reserved: ${hbxDecoBlocks.reservedTurnover} + Bestverd: ${hbxDecoBlocks.reservedTurnoverAndBestverd}`}>
                    </Tooltip> */}
                </Paper>
                : <DashboardBlockSkeleton />}
            </div> 
        )
    } else {
        fourthBlock = (
            <div key="fourthBlock" className="widget flex w-full sm:w-1/3 md:w-1/6 p-12">
            {isLoaded ? 
                <Paper className="w-full p-16 text-center rounded-8 shadow-none border-1" elevation={1}>
                    <span className={classes.vAlign + ' text-20'}>
                    {numberToLocale(blocksData.weeksPerOrder)}
                    </span><br />
                    <span className="text-16">
                        {t("WKPERORD")}
                    </span>
                </Paper>
                : <DashboardBlockSkeleton />}
            </div> 
        )
    }

    let fifthBlock = null;
    if(isHbxDeco) {
        // let status = null;
        // if(isLoaded) {
        //     status = getStatus((hbxDecoBlocks.realisedTurnover + hbxDecoBlocks.realisedTurnoverORDERKP + hbxDecoBlocks.reservedTurnover + hbxDecoBlocks.realisedTurnover), (hbxDecoBlocks.targetGeschrevenTurnover + hbxDecoBlocks.targetGerealiseerdeTurnover));
        // }
        // let statusIcon = <Icon fontSize="small">sentiment_dissatisfied</Icon>;
        // if(status === 1){ statusIcon = <Icon fontSize="small">sentiment_very_satisfied</Icon> }
        // else if(status === 2){ statusIcon = <Icon fontSize="small">sentiment_satisfied</Icon> }
        fifthBlock = (
            <div key="fifthBlock" className="widget flex w-full sm:w-1/3 md:w-1/5 p-12">
            {isLoaded ? 
                <Paper className={classes.secondaryPaper + ' w-full p-16 text-center rounded-8 shadow-none border-1'} elevation={1}>
                    <span className={classes.vAlign + ' text-20'}>
                    &euro; {numberToLocale(margeTotal)}<br />
                    </span>
                    {/* <Tooltip title={`Marges: ${hbxDecoBlocks.MargeTotal}`}>
                        <span className="text-16">
                        Gerealiseerde Marges
                        </span>
                    </Tooltip><br /> */}
                    <span className={classes.vAlign + ' text-16'}>
                        {verkoopbedragPercentage > hbxDecoBlocks.targetPercentMarges ? 
                        <>Gerealiseerde Marge : <Icon style={{color: 'green', lineHeight: 1.4}} fontSize="small">arrow_upward</Icon>{verkoopbedragPercentage} % <br /></>
                        :
                        <>
                        {verkoopbedragPercentage > (hbxDecoBlocks.targetPercentMarges*0.9) ?
                        <>Gerealiseerde Marge : <Icon style={{color: 'orange', lineHeight: 1.4}} fontSize="small">arrow_downward</Icon>{verkoopbedragPercentage} % <br /></>
                        :
                        <>Gerealiseerde Marge : <Icon style={{color: 'red', lineHeight: 1.4}} fontSize="small">arrow_downward</Icon>{verkoopbedragPercentage} % <br /></>
                        }
                        </>
                        }                    
                    Target marge : {hbxDecoBlocks.targetPercentMarges} %
                    </span><br />
                </Paper>

                : <DashboardBlockSkeleton classToApply={classes.secondaryPaper} />}
            </div> 
        )
    } else {
        fifthBlock = (
            <div key="fifthBlock" className="widget flex w-full sm:w-1/3 md:w-1/6 p-12">
            {isLoaded ? 
                <Paper className={classes.secondaryPaper + ' w-full p-16 text-center rounded-8 shadow-none border-1'} elevation={1}>
                    <span className={classes.vAlign + ' text-20'}>
                    {blocksData.codes.newCodes} <span className="text-18">{t("NEW")} codes</span>
                    </span>
                    <h4>
                    {blocksData.codes.orders} {t("HAVEORDERED")}
                    </h4>
                </Paper>

                : <DashboardBlockSkeleton classToApply={classes.secondaryPaper} />}
            </div> 
        )
    }

    let sixthBlock = !isHbxDeco ? (
        <div key="sixthBlock" className="widget flex w-full sm:w-1/3 md:w-1/6 p-12">
            {isLoaded ? 
                <Paper className={classes.primaryPaper + " w-full p-16 text-center rounded-8 shadow-none border-1"} elevation={1}>
                    <span className={classes.vAlign + ' text-18'}>
                    &euro; {numberToLocale(blocksData.turnover.newTurnover)} <span className="text-16">{t("NEW")}</span>
                    </span>
                    <h4>
                    &euro; {numberToLocale(blocksData.turnover.oldTurnover)} {t("OLD")}
                    </h4>
                </Paper>
                : <DashboardBlockSkeleton classToApply={classes.primaryPaper} />
            }
        </div>
    ) : null;

    return (
        <div className="flex flex-wrap">
            
            {
                // displaying all blocks with this array
                [
                    firstBlock, 
                    secondBlock, 
                    thirdBlock, 
                    fourthBlock, 
                    fifthBlock,
                    sixthBlock
                ]
            }
            
        </div>
    )
});