import React, { Component } from "react";
import Chart from "react-apexcharts";

class UserStatsHourHeatmap extends Component {
    render() {
        return (
            <Chart options={chart.options} series={this.props.series} type="heatmap" height={350} />
        );
    }
}
export default UserStatsHourHeatmap;

const chart = {
    options: {
        chart: {
            height: 350,
            type: 'heatmap',
            zoom: {
                enabled: false,
            }
        },
        plotOptions: {
            heatmap: {
                colorScale: {
                    ranges: [
                        {
                            from: 0,
                            to: 1,
                            color: '#fef1f0',
                            name: 'low',
                        },
                        {
                            from: 2,
                            to: 10,
                            color: '#f9bdbb',
                            name: 'low',
                        },
                        {
                            from: 11,
                            to: 30,
                            color: '#eb6460',
                            name: 'medium',
                        },
                        {
                            from: 31,
                            to: 100,
                            color: '#ad3532',
                            name: 'high',
                        }
                    ]
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ["#ad3532"],
        legend: {
            show: false
        }
    },
};