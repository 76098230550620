import * as actionTypes from './actionTypes';
import axios from 'axios';
import {fetchDbAllSearchCustomers} from '../../crm/actions';
import {signGroup} from '../../utility';
import { getAccessToken, getRefreshToken } from '../../../main/Auth/Auth';

export const fetchAccessableGroupcodesSuccess = (groupcodes) => {
    return {
        type: actionTypes.FETCH_ACCESSABLE_GROUPCODES_SUCCESS,
        groupcodes: groupcodes
    };
}

export const fetchAccessableGroupcodes = () => {
    return dispatch => {
        axios.get('groupcode/GetAccessableGroups')
            .then(res => {
                dispatch(fetchAccessableGroupcodesSuccess(res.data));
            })
    };
}

export const fetchAllGroupsSuccess = (allgroups) => {
    return {
        type: actionTypes.FETCH_ALL_GROUPS_SUCCESS,
        allgroups: allgroups
    };
}

export const fetchAllGroups = () => {
    return dispatch => {
        axios.get('groupcode/getallgroups')
            .then(res => {
                dispatch(fetchAllGroupsSuccess(res.data));
            })
    };
}

export const fetchUnaccessableGroupcodesSuccess = (unaccessableGroupcodes) => {
    return {
        type: actionTypes.FETCH_UNACCESSABLE_GROUPCODES_SUCCESS,
        unaccessableGroupcodes: unaccessableGroupcodes
    };
}

export const fetchUnaccessableGroupcodes = () => {
    return dispatch => {
        axios.get('groupcode/GetUnaccessableGroups')
            .then(res => {
                dispatch(fetchUnaccessableGroupcodesSuccess(res.data));
            })
    };
}

export const fetchLocalGroupcodeSuccess = (currentGroupcode) => {
    return dispatch => {
        dispatch({
            type: actionTypes.FETCH_LOCAL_GROUPCODE_SUCCESS,
            currentGroupcode: currentGroupcode
        });
        dispatch(fetchDbAllSearchCustomers(currentGroupcode));
    }
}

export const fetchCurrentGroupcodeSuccess = (currentGroupcode) => {
    return {
        type: actionTypes.FETCH_CURRENT_GROUPCODE_SUCCESS,
        currentGroupcode: currentGroupcode
    };
}

export const fetchCurrentGroupcode = (azureId) => {
    return dispatch => {
        axios.get('groupcode/GetCurrentGroup', {headers: {azure_id: azureId}})
            .then(res => {
                dispatch(fetchDbAllSearchCustomers(signGroup(res.data)));
                dispatch(fetchCurrentGroupcodeSuccess(res.data));
                dispatch(fetchAccessableGroupcodes());
            })
    };
}

export const updateCurrentGroupcodeSuccess = (currentGroupcode) => {
    return {
        type: actionTypes.UPDATE_CURRENT_GROUPCODE_SUCCESS,
        currentGroupcode: currentGroupcode
    };
}

export const updateCurrentGroupcode = (newGroupCode) => {
    return dispatch => {
        axios.post('groupcode/SetCurrentGroup', newGroupCode)
            .then(res => {
                localStorage.setItem('access_token', res.data.accessToken);
                localStorage.setItem('refresh_token', res.data.refreshToken);
                dispatch(updateCurrentGroupcodeSuccess(res.data));
            })
    };
}

export const setUser = user => {
    return dispatch => {
        dispatch(fetchUserByIdSuccess(user));
    }
}

export const fetchUserByIdSuccess = user => {
    return {
        type: actionTypes.FETCH_USER_BY_ID_SUCCESS,
        user: user,
    };
};

export const fetchUserByIdFail = error => {
    return {
        type: actionTypes.FETCH_USER_BY_ID_FAIL,
        error: error
    };
};

export const fetchUserByIdStart = () => {
    return {
        type: actionTypes.FETCH_USER_BY_ID_START
    };
};

export const fetchUserById = azureId => {
    return dispatch => {
        dispatch(fetchUserByIdStart());
        axios
            .get('auth/GetUserByAzureId/' + azureId)
            .then(res => {
                dispatch(fetchUserByIdSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchUserByIdFail(error));
            });
    };
};

export const sendLoginSuccess = (user) => {
    return {
        type: actionTypes.SEND_LOGIN_SUCCESS,
        user
    };
};

export const sendLoginFail = error => {
    return {
        type: actionTypes.SEND_LOGIN_FAIL,
        error: error,
    };
};

export const sendLoginStart = () => {
    return {
        type: actionTypes.SEND_LOGIN_START
    };
};

export const sendLogin = (loginInfo, afterLoginSuccess) => {
    return dispatch => {
        dispatch(sendLoginStart());
        axios
            .post('auth/login', loginInfo)
            .then(res => {
                localStorage.setItem('access_token', res.data.accessToken);
                localStorage.setItem('refresh_token', res.data.refreshToken);
                if(res.data.user.tosAgreement) {
                    dispatch(sendLoginSuccess(res.data.user));
                    dispatch(fetchAccessableGroupcodes());
                }
                afterLoginSuccess(res.data.user);
            })
            .catch(error => {
                dispatch(sendLoginFail(error));
            });
    };
};

export const sendLoginUserInfoAfterAgreement = (user) => {
    return dispatch => {
        dispatch(sendLoginSuccess(user));
        dispatch(fetchAccessableGroupcodes());
    }
}

export const refreshTokenSuccess = (user) => {
    return {
        type: actionTypes.REFRESH_TOKEN_SUCCESS,
        user
    };
};

export const refreshTokenFail = error => {
    return {
        type: actionTypes.REFRESH_TOKEN_FAIL,
        error: error
    };
};

export const refreshTokenStart = () => {
    return {
        type: actionTypes.REFRESH_TOKEN_START
    };
};

export const refreshToken = (afterRefreshTokenError) => {
    return dispatch => {
        dispatch(refreshTokenStart());
        axios
            .post('auth/refreshtoken', {accessToken: getAccessToken(), refreshToken: getRefreshToken()})
            .then(res => {
                localStorage.setItem('access_token', res.data.accessToken);
                localStorage.setItem('refresh_token', res.data.refreshToken);
                dispatch(refreshTokenSuccess(res.data.user));
            })
            .catch(error => {
                dispatch(refreshTokenFail(error));
                afterRefreshTokenError();
                // onRefreshTokenError();
            });
    };
};

export const resetPasswordSuccess = (resetted) => {
    return {
        type: actionTypes.RESET_PASSWORD_SUCCESS,
        resetted
    };
};

export const resetPasswordFail = error => {
    return {
        type: actionTypes.RESET_PASSWORD_FAIL,
        error: error
    };
};

export const resetPasswordStart = () => {
    return {
        type: actionTypes.RESET_PASSWORD_START
    };
};

export const resetPassword = (model, afterForgottenSuccess) => {
    return dispatch => {
        dispatch(resetPasswordStart());
        axios
            .post('auth/resetPassword', model)
            .then(res => {
                console.log(res.data.length > 0);
                dispatch(resetPasswordSuccess(res.data.length > 0));
                afterForgottenSuccess(res.data.length > 0);
            })
            .catch(error => {
                dispatch(resetPasswordFail(error.message));
            });
    };
};

export const updatePasswordSuccess = (user) => {
    return {
        type: actionTypes.UPDATE_PASSWORD_SUCCESS,
        user
    };
};

export const updatePasswordFail = error => {
    return {
        type: actionTypes.UPDATE_PASSWORD_FAIL,
        error: error
    };
};

export const updatePasswordStart = () => {
    return {
        type: actionTypes.UPDATE_PASSWORD_START
    };
};

export const updatePassword = (user, afterUpdateSuccess) => {
    return dispatch => {
        dispatch(updatePasswordStart());
        axios
            .post('auth/updatePassword', { ...user })
            .then(res => {
                localStorage.setItem('access_token', res.data.accessToken);
                localStorage.setItem('refresh_token', res.data.refreshToken);
                dispatch(updatePasswordSuccess(res.data.user));
                
                // console.log(res.status);
                afterUpdateSuccess();
            })
            .catch(error => {
                dispatch(updatePasswordFail(error));
            });
    };
};

export const fetchRolesSuccess = (roles) => {
    return {
        type: actionTypes.FETCH_ROLES_SUCCESS,
        roles
    };
};

export const fetchRolesFail = error => {
    return {
        type: actionTypes.FETCH_ROLES_FAIL,
        error: error
    };
};

export const fetchRolesStart = () => {
    return {
        type: actionTypes.FETCH_ROLES_START
    };
};

export const fetchRoles = () => {
    return dispatch => {
        dispatch(fetchRolesStart());
        axios
            .get('auth/getRoles')
            .then(res => {
                dispatch(fetchRolesSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchRolesFail(error));
            });
    };
};

export const setLoginFailedFalse = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_LOGINFAILED_FALSE
        })
    }
}