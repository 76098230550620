import React, { Component } from 'react';
import {withStyles} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import {FusePageSimple, FuseAnimateGroup} from '../../../../@fuse';
// import {Icon, Typography, Tooltip, TextField, Card, CardContent, CardHeader, Button, Badge, LinearProgress, Box} from '@material-ui/core';
import {Icon, Typography, Tooltip, TextField, Card, CardContent, CardHeader, Button, Badge, LinearProgress, Box, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import ReactTable from "react-table";
import {connect} from 'react-redux';
import * as actions from '../../../store/api/actions';
import moment from 'moment';
// import copyCodeToClipboard from '../../../components/Functions/CopyToClipboard';
import Alert from '@material-ui/lab/Alert';

const styles = theme => ({
    layoutRoot: {},
    datePicker: {
        // minWidth: '155px',
        width: '100%',
    },
    floatRight: {
        float: 'right',
        marginLeft: '4px',
    },
    cardHeader: {
        backgroundColor: '#f6f8f8',
        borderBottom: '1px solid',
        borderColor: '#dee5e7 !important;'
    },
    successButton: {
        color: '#27c24c !important'
    },
});

class DexxQuadraExport extends Component {

    state = {
        fromdate: new Date().toISOString().slice(0, 10),
        todate: new Date().toISOString().slice(0, 10),
        startLoading: false,
        finishedLoading: false,
        percentage: 0,
        // fromdate: new Date(),
        // fromdate: moment(this.getMonday(fromdate)).format('YYYY-MM-DD'),
        // todate: moment(new Date()).format('YYYY-MM-DD'),
    }

    componentDidMount() {
        // this.handleDateButtonClick('yesterday');
        this.setFilterCompany(1);
        // this.handleGenerateClick();
        // if(this.props.generatedCnCModule.length > 0) {
        //     this.handleDateButtonClick('thisweek');
        // }
    }
    
    handleFromDate = event => {
        this.setState({fromdate: event.target.value})
    }
    
    handleToDate = event => {
        this.setState({todate: event.target.value})
    }

    handleGenerateClick = () => {
        this.setState({finishedLoading: false, startLoading: true, percentage: 0});
        this.props.onGenerateQuadraExportPreview(this.state.filterCompany, this.state.fromdate, this.state.todate);
        this.props.onGenerateQuadraExportFaults(this.state.filterCompany, this.state.fromdate, this.state.todate);
    }

    finishedLoading = () => {
        this.setState({finishedLoading: true, startLoading: false, percentage: 100});
    }

    downloadPaiments = () => {
        this.props.onDownloadPaiments(this.state.filterCompany, this.state.fromdate, this.state.todate);
    }

    downloadVentes = () => {
        this.props.onDownloadVentes(this.state.filterCompany, this.state.fromdate, this.state.todate);
    }
    
    applyDates = () => {
        // const momentFormatted = moment(this.state.startDate).format('YYYY-MM-DD');
        // console.log(momentFormatted);
        const startDate = moment(this.state.startDate).format('YYYY-MM-DD');
        const endDate = moment(this.state.endDate).format('YYYY-MM-DD');
        // this.setState({fromdate: startDate, todate: endDate})
        this.setState({fromdate: startDate, todate: endDate}, () => this.handleGenerateClick())
    }

    handleDateButtonClick = buttonClicked => {
        let startDate = new Date();
        let endDate = new Date();
        switch(buttonClicked) {
            case 'yesterday':
                startDate.setDate(startDate.getDate() - 1);
                endDate.setDate(endDate.getDate() - 1);
                break;
            case 'thisweek': 
                startDate = this.getMonday(startDate);
                break;
            case 'thismonth': 
                startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
                break;
            case 'thisyear': 
                startDate = new Date(startDate.getFullYear(), 0, 1)
                break;
            case 'lastweek': 
                startDate.setDate(startDate.getDate()-7);
                startDate = this.getMonday(startDate);
                endDate = moment(startDate).add('days', 4).toDate();
                break;
            case 'lastmonth': 
                startDate = new Date(startDate.getFullYear(), startDate.getMonth()-1, 1);
                // startDate.setHours(-1);
                // startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
                // startDate.setHours(-24);
                endDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
                endDate.setHours(-4);
                break;
            case 'lastyear': 
                startDate = new Date(startDate.getFullYear() - 1, 0, 1);
                endDate = new Date(endDate.getFullYear() - 1, 11, 31);
                break;
            case 'defaultdate': 
                startDate.setDate(startDate.getDate() - 1);
                endDate.setDate(endDate.getDate() - 1);
                break;
            default: break;
        }
        this.setState({startDate, endDate}, this.applyDates);        
    }

    getMonday = date => {
        let day = date.getDay() || 7;
        if( day !== 1 ) 
            date.setHours(-24 * (day - 1)); 
        return date;
    }

    setFilterCompany = (value) => {
        this.setState({filterCompany: value});
    }

    render() {
        const {classes, t, generatedQuadraExport, generatedQuadraExportFaults} = this.props;
        // console.log(" company : " + this.state.filterCompany);
        // console.log(" finished : " + this.state.finishedLoading);
        console.log(" faults : " + generatedQuadraExportFaults);
        
        const companylist = [
            // {key: 'all', value: 'Tous'},
            {key: '1', value: 'DEXX Drive'},
            {key: '4', value: 'DEXX Lille'},
            {key: '5', value: 'DEXX Annemasse'},
            {key: '6', value: 'DEXX Mulhouse'},
            {key: '8', value: 'DEXX Lyon'},
            {key: '7', value: 'DEXX Valence'},
            {key: '9', value: 'DEXX Grenoble'},
        ]
    
        return (
            <FusePageSimple
                classes={{
                    root: classes.layoutRoot
                }}
                header={
                      <div className="flex flex-1 items-center justify-between p-24">
                         <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                  <Icon className="text-18" color="action">home</Icon>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">API's</Typography>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">DEXX Quadra Export</Typography>
                            </div>
                            <Typography variant="h6">DEXX Quadra Export</Typography>
                         </div>
                      </div>
                }
                content={
                        <div>
                            <FuseAnimateGroup
                                className="flex flex-wrap"
                                enter={{
                                    animation: "transition.slideUpBigIn"
                                }}
                            >

                            <div className="flex flex-wrap w-full p-12">
                                <div className="flex w-full p-12 md:w-1/3">
                                    <Card className="w-full">
                                        <CardHeader 
                                        className={classes.cardHeader}
                                        title={
                                            <>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Choisissez la période
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                            <b>Du</b> {this.state.fromdate} <b>au</b> {this.state.todate}<br />
                                            {generatedQuadraExport.length > 0 ? "Lignes générées : " + generatedQuadraExport.length : null}<br />
                                            {generatedQuadraExportFaults.length > 0 ? "Erreures trouvées : " + generatedQuadraExportFaults.length : null}
                                            </Typography>
                                            </>
                                        } />
                                        <CardContent>
                                            
                                
                                <FormControl className="w-full">
                                    <InputLabel id="land-label">{t("COMPANY")}</InputLabel>
                                    <Select
                                        id="land-select"
                                        value={this.state.filterCompany}
                                        defaultValue="1"
                                        onChange={(event) => this.setFilterCompany(event.target.value)}
                                    >
                                        {companylist.map(row => (
                                            <MenuItem key={row.key} value={row.key}>{row.value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            <div className="flex flex-wrap w-full justify-center p-6" elevation={1}>
                                {/* <Button variant="contained" color="primary" className="m-6" onClick={() => this.handleDateButtonClick('thismonth')}>
                                    {t("THISMT")}
                                </Button> */}
                                {/* <Button variant="contained" color="primary" className="m-6" onClick={() => this.handleDateButtonClick('lastmonth')}>
                                    {t("LASTMT")}
                                </Button> */}
                                <Button variant="contained" color="primary" className="m-6" onClick={() => this.handleDateButtonClick('yesterday')}>
                                    {t("YESTERDAY")}
                                </Button>
                                <Button variant="contained" color="primary" className="m-6" onClick={() => this.handleDateButtonClick('thisweek')}>
                                    {t("THISWK")}
                                </Button>
                                {/* <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('thisyear')}>
                                    {t("THISYR")}
                                </Button> */}
                                <Button variant="contained" color="primary" className="m-6" onClick={() => this.handleDateButtonClick('lastweek')}>
                                    {t("LASTWK")}
                                </Button>
                                {/* <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('lastyear')}>
                                    {t("LASTYR")}
                                </Button> */}
                                {/* <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('defaultdate')}>
                                    {t("DEFAULTDT")}
                                </Button> */}
                            </div>
                                            <TextField
                                                id="fromdate"
                                                label= {t("STARTDATE") + " " + t("DATE")}
                                                type="date"
                                                value={this.state.fromdate}
                                                // defaultValue={this.state.fromdate}
                                                onChange={this.handleFromDate}
                                                className={classes.datePicker}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }} 
                                            />
                                            <br />
                                            <br />
                                            <TextField
                                                id="todate"
                                                label= {t("ENDDATE") + " " + t("DATE")}
                                                type="date"
                                                value={this.state.todate}
                                                // defaultValue={this.state.todate}
                                                onChange={this.handleToDate}
                                                className={classes.datePicker}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }} 
                                            />
                                            <br />
                                            <br />
                                            <div className="w-full flex flex-wrap">
                                                <div className="w-full p-6">
                                                <Button 
                                                    variant="contained" 
                                                    color="default" 
                                                    fullWidth 
                                                    startIcon={<Icon>autorenew</Icon>}
                                                    onClick={this.handleGenerateClick}>
                                                    {/* <Icon className={"m-10"}>autorenew</Icon>  */}
                                                    {t("PREVIEW")}
                                                </Button>
                                                </div>
                                            </div>
                                            <div className="w-full flex flex-wrap">
                                                <div className="w-full xs:w-full sm:w-1/2 md:w-full lg:w-1/2 p-6">
                                                <Tooltip title={t("DOWNLOAD")}>
                                                    <Badge badgeContent={generatedQuadraExport.length} color="primary" className="w-full">
                                                        <Button 
                                                            variant="contained"
                                                            color="primary" 
                                                            // className="w-full"
                                                            fullWidth
                                                            startIcon={<Icon>save_alt</Icon>}
                                                            onClick={this.downloadPaiments}>
                                                            PAIEMENTS
                                                        </Button>
                                                    </Badge>
                                                </Tooltip>
                                                </div>
                                                <div className="w-full xs:w-full sm:w-1/2 md:w-full lg:w-1/2 p-6">
                                                <Tooltip title={t("DOWNLOAD")}>
                                                    <Badge badgeContent={generatedQuadraExport.length} color="primary" className="w-full">
                                                        <Button 
                                                            variant="contained"
                                                            color="secondary"
                                                            // className="w-full"
                                                            fullWidth
                                                            startIcon={<Icon>save_alt</Icon>}
                                                            onClick={this.downloadVentes}>
                                                            VENTES
                                                        </Button>
                                                    </Badge>
                                                </Tooltip>
                                                </div>
                                            </div>
                                    {generatedQuadraExportFaults.length > 0 ? 
                                    <div className="w-full pt-12"><Alert className="w-full" severity="warning">{generatedQuadraExportFaults.length} erreurs trouvées</Alert></div>
                                    :
                                    null}
                                            
                                    {this.props.loadingGeneratedQuadraExport ? <>
                                    <Box display="flex" alignItems="center">
                                        <Box width="100%" mr={1}>
                                            <LinearProgress color="primary" className="mb-12" />
                                        </Box>
                                        {/* <Box minWidth={35}>
                                            <Typography variant="body2" color="textSecondary">{`${Math.round(0,)}%`}</Typography>
                                        </Box> */}
                                    </Box>
                                    </> : this.props.QuadraExporterror}
                                        </CardContent>
                                    </Card>
                                </div>
                                {/* {generatedQuadraExport.length > 0 ?  */}
                                <div className="flex w-full p-12 md:w-2/3">
                                    <Card className="w-full">
                                            <ReactTable
                                                style={{border: '0px'}}
                                                className="-striped -highlight border-0"
                                                data={generatedQuadraExport}
                                                columns={[
                                                    {
                                                        Header    : "Compte",
                                                        accessor  : "compte_comptable",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center",
                                                        // width: 100,
                                                    },
                                                    {
                                                        Header    : t("DEBNR"),
                                                        accessor  : "debnr",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center",
                                                        // width: 100,
                                                    },
                                                    {
                                                        Header    : "Rayon",
                                                        accessor  : "afdeling",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center",
                                                        // width: 100,
                                                    },
                                                    {
                                                        Header    : t("INVOICE"),
                                                        accessor  : "factnr",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center",
                                                        // width: 100,
                                                    },
                                                    {
                                                        Header    : t("DATE"),
                                                        accessor  : "fctdat",
                                                        filterable: true,
                                                        className: "justify-center font-bold",
                                                        // width: 100,
                                                        Cell: row => moment(row.original.fctdat).format('DD/MM/YYYY')
                                                        // <Tooltip title={t("VALIDATE") + " : " + moment(row.original).format('DD-MM-YYYY @ HH:mm')}>
                                                        //     <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                                                        // </Tooltip>
                                                    },
                                                    {
                                                        Header    : "D / C",
                                                        accessor  : "letterDC",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center",
                                                        // width: 100,
                                                    },
                                                    {
                                                        Header    : t("total"),
                                                        accessor  : "total",
                                                        filterable: true,
                                                        className: "justify-end",
                                                        // headerStyle: {textAlign: 'right'},
                                                        // width: 100,
                                                        // Cell: row => row.original.Flowers
                                                        Cell: row => <div>
                                                            {/* <Tooltip title={t("COPY")} placement="left"> */}
                                                                € {row.original.total.toLocaleString('nl-NL', { minimumFractionDigits:2, maximumFractionDigits:2 })}&nbsp;
                                                            {/* </Tooltip> */}
                                                            </div>
                                                    },
                                                    // {
                                                    //     Header    : t("Deco"),
                                                    //     accessor  : "deco",
                                                    //     filterable: true,
                                                    //     className: "justify-end",
                                                    //     // headerStyle: {textAlign: 'right'},
                                                    //     // width: 100,
                                                    //     // Cell: row => row.original.Flowers
                                                    //     Cell: row => <div>
                                                    //         <Tooltip title={t("COPY")} placement="left">
                                                    //         <button className="text-right" onClick={() => copyCodeToClipboard(row.original.deco)}>
                                                    //             € {row.original.deco.toLocaleString('nl-NL', { minimumFractionDigits:2, maximumFractionDigits:2 })}&nbsp;
                                                    //             <Icon className="text-18">file_copy</Icon></button>
                                                    //         </Tooltip>
                                                    //         </div>
                                                    // },
                                                    // {
                                                    //     Header    : t("Divers"),
                                                    //     accessor  : "divers",
                                                    //     filterable: true,
                                                    //     className: "justify-end",
                                                    //     // headerStyle: {textAlign: 'right'},
                                                    //     // width: 100,
                                                    //     // Cell: row => row.original.Flowers
                                                    //     Cell: row => <div>
                                                    //         <Tooltip title={t("COPY")} placement="left">
                                                    //         <button className="text-right" onClick={() => copyCodeToClipboard(row.original.divers)}>
                                                    //             € {row.original.divers.toLocaleString('nl-NL', { minimumFractionDigits:2, maximumFractionDigits:2 })}&nbsp;
                                                    //             <Icon className="text-18">file_copy</Icon></button>
                                                    //         </Tooltip>
                                                    //         </div>
                                                    // },
                                                    // {
                                                    //     Header    : t("Frais Prepa"),
                                                    //     accessor  : "handling",
                                                    //     filterable: true,
                                                    //     className: "justify-end",
                                                    //     // headerStyle: {textAlign: 'right'},
                                                    //     // width: 100,
                                                    //     // Cell: row => row.original.handling
                                                    //     Cell: row => <div>
                                                    //         <Tooltip title={t("COPY")} placement="left">
                                                    //         <button className="text-right" onClick={() => copyCodeToClipboard(row.original.handling)}>
                                                    //             € {row.original.handling.toLocaleString('nl-NL', { minimumFractionDigits:2, maximumFractionDigits:2 })}&nbsp;
                                                    //             <Icon className="text-18">file_copy</Icon></button>
                                                    //         </Tooltip>
                                                    //         </div>
                                                    // },
                                                ]}
                                                pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                                                pageSize={10}
                                                noDataText="No customers found"
                                            />
                                    </Card>
                                </div>
                                {/* : null } */}
                                {generatedQuadraExportFaults.length > 0 ? 
                                <div className="flex w-full p-12">
                                {/* <div className="w-full pt-12"><Alert className="w-full" severity="warning">{generatedQuadraExportFaults.length} erreurs trouvées</Alert></div> */}
                                    <Card className="w-full">
                                            <ReactTable
                                                style={{border: '0px'}}
                                                className="-striped -highlight border-0"
                                                data={generatedQuadraExportFaults}
                                                columns={[
                                                    {
                                                        Header    : "Code VBN",
                                                        accessor  : "compte_comptable",
                                                        filterable: true,
                                                        // Style     : "color: #f00;",
                                                        className : "justify-center text-red",
                                                        // width: 100,
                                                    },
                                                    {
                                                        Header    : t("DEBNR"),
                                                        accessor  : "debnr",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center text-red",
                                                        // width: 100,
                                                    },
                                                    {
                                                        Header    : "Article",
                                                        accessor  : "afdeling",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center text-red",
                                                        // width: 100,
                                                    },
                                                    {
                                                        Header    : t("INVOICE"),
                                                        accessor  : "factnr",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center text-red",
                                                        // width: 100,
                                                    },
                                                    {
                                                        Header    : t("DATE"),
                                                        accessor  : "fctdat",
                                                        filterable: true,
                                                        className: "justify-center font-bold text-red",
                                                        // width: 100,
                                                        Cell: row => moment(row.original.fctdat).format('DD/MM/YYYY')
                                                        // <Tooltip title={t("VALIDATE") + " : " + moment(row.original).format('DD-MM-YYYY @ HH:mm')}>
                                                        //     <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                                                        // </Tooltip>
                                                    },
                                                    {
                                                        Header    : t("total"),
                                                        accessor  : "total",
                                                        filterable: true,
                                                        className: "justify-end text-red",
                                                        // headerStyle: {textAlign: 'right'},
                                                        // width: 100,
                                                        // Cell: row => row.original.Flowers
                                                        Cell: row => <div>
                                                            {/* <Tooltip title={t("COPY")} placement="left"> */}
                                                                € {row.original.total.toLocaleString('nl-NL', { minimumFractionDigits:2, maximumFractionDigits:2 })}&nbsp;
                                                            {/* </Tooltip> */}
                                                            </div>
                                                    },
                                                ]}
                                                pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                                                pageSize={10}
                                                noDataText="No customers found"
                                            />
                                    </Card>
                                </div>
                                : null }
                            </div>
                                </FuseAnimateGroup>
                            </div>
                }
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        loadingGeneratedQuadraExport : state.api.loadingGeneratedQuadraExport,
        loadingGeneratedQuadraExportFaults : state.api.loadingGeneratedQuadraExportFaults,
        generatedQuadraExport: state.api.generateQuadraExport,
        generatedQuadraExportFaults: state.api.generateQuadraExportFaults,
        QuadraExporterror: state.api.error,
        // loading: state.api.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGenerateQuadraExportFaults: (filterCompany, fromdate, todate) => dispatch(actions.generateQuadraExportFaults(filterCompany, fromdate, todate)),
        onGenerateQuadraExportPreview: (filterCompany, fromdate, todate) => dispatch(actions.generateQuadraExportPreview(filterCompany, fromdate, todate)),
        onDownloadVentes: (filterCompany, fromdate, todate) => dispatch(actions.downloadQuadraExportVentes(filterCompany, fromdate, todate)),
        onDownloadPaiments: (filterCompany, fromdate, todate) => dispatch(actions.downloadQuadraExportPaiements(filterCompany, fromdate, todate))
        // onFetchApis: () => dispatch(actions.fetchApis()),
        // onSendDailyOmk: () => dispatch(actions.sendDailyOmk())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles, {withTheme: true})(withTranslation()(DexxQuadraExport)));