import React from 'react';
import {CardHeader, Switch, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const CallListTableHeader = props => {
    const {t} = useTranslation();
    return (
    <CardHeader title={
        <div className="flex w-full">
            <div className="flex w-3/4">
                <Typography variant="body2" className="align-middle mt-8" gutterBottom style={{height: '48px'}}>
                    {props.title}
                </Typography>
            </div>
            <div className="flex w-1/4">
                <Typography variant="body2" gutterBottom>
                    {t("LOADMORE")}
                    <Switch checked={props.showAll} onChange={() => props.onShowAllClick(props.block)} value={props.block}  />
                </Typography>
            </div>
        </div>
    }>
    </CardHeader>
)}

export {CallListTableHeader};