import {updateObject} from '../../../utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    users: [],
    userRoles: [],
    userRolesLoaded: false,
    companies: [],
    accessableGroups: [],

    unaccessableGroups: [],
    openPosten: [],
    sentOpenPostenSuccessfully: false,

    loadingOpenPosten: false,
    loadingAccessableGroups: false,
    loadingUnaccessableGroups: false,
    updatedGroup: false,
    userUpdated: false,
    userCreated: false,
    userDeleted: false,

    refreshingGroups: false,

    importedUsers: {
        didImport: false,
        error: null,
    },
    latestImport: null,
};

const fetchUsersSuccess = ( state, action ) => {
    console.log('settings users');
    return updateObject( state, {users: action.users} );
};


const fetchUserRolesStart = ( state, action ) => {
    return updateObject( state, { userRoles: [], userRolesLoaded: false } );
};
const fetchUserRolesSuccess = ( state, action ) => {
    return updateObject( state, { userRolesLoaded: true, userRoles: action.roles } );
};
const fetchUserRolesFail = ( state, action ) => {
    return updateObject( state, { error: action.error, userRoles: [], userRolesLoaded: false } );
};


const fetchCompaniesSuccess = ( state, action ) => {
    return updateObject( state, {companies: action.companies} );
};


const fetchAccessableGroupsStart = ( state, action ) => {
    return updateObject( state, {loadingAccessableGroups: true} );
};
const fetchAccessableGroupsSuccess = ( state, action ) => {
    return updateObject( state, {accessableGroups: action.accessableGroups, loadingAccessableGroups: false} );
};


const fetchUnaccessableGroupsStart = ( state, action ) => {
    return updateObject( state, {loadingUnaccessableGroups: true} );
};
const fetchUnaccessableGroupsSuccess = ( state, action ) => {
    return updateObject( state, {unaccessableGroups: action.unaccessableGroups, loadingUnaccessableGroups: false} );
};

const closeUpdateUserGroupSnackbar = ( state, action ) => {
    return updateObject( state, {updatedGroup: false} );
};
const updateUserGroupSuccess = ( state, action ) => {
    return updateObject( state, {updatedGroup: true} );
};

const updateUserSuccess = ( state, action ) => {
    return updateObject( state, {userUpdated: action.userUpdated} );
};

const createUserSuccess = ( state, action ) => {
    return updateObject( state, {userCreated: true} );
};

const deleteUserSuccess = ( state, action ) => {
    return updateObject( state, {userDeleted: action.userDeleted} );
};


const fetchOpenPostenStart = ( state, action ) => {
    return updateObject( state, {loadingOpenPosten: true} );
};

const fetchOpenPostenSuccess = ( state, action ) => {
    return updateObject( state, {openPosten: action.openPosten, loadingOpenPosten: false} );
};

const sendOpenPostSuccess = ( state, action ) => {
    return updateObject( state, {sentOpenPostenSuccessfully: action.statusCode === 201} );
};

const refreshDebiterGroupsStart = ( state, action ) => {
    return updateObject( state, {refreshingGroups: true} );
}
const refreshDebiterGroupsSuccess = ( state, action ) => {
    return updateObject( state, {refreshingGroups: false} );
}

const importUsersSIBSuccess = ( state, action ) => {
    return updateObject( state, {importedUsers: {
        didImport: true,
        error: null,
    }})
}
const importUsersSIBFail = ( state, action ) => {
    return updateObject( state, {importedUsers: {
        didImport: false,
        error: action.error,
    }})
}

const fetchLatestImportSuccess = ( state, action ) => {
    return updateObject( state, { latestImport: action.latestImport });
}
const fetchLatestImportFail = ( state, action ) => {
    return updateObject( state, { error: action.error });
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.FETCH_USERS_SUCCESS: return fetchUsersSuccess( state, action );

        case actionTypes.FETCH_USER_ROLES_START: return fetchUserRolesStart( state, action );
        case actionTypes.FETCH_USER_ROLES_SUCCESS: return fetchUserRolesSuccess( state, action );
        case actionTypes.FETCH_USER_ROLES_FAIL: return fetchUserRolesFail( state, action );

        case actionTypes.FETCH_COMPANIES_SUCCESS: return fetchCompaniesSuccess( state, action );
        
        case actionTypes.FETCH_ACCESSABLE_GROUPS_START: return fetchAccessableGroupsStart( state, action );
        case actionTypes.FETCH_ACCESSABLE_GROUPS_SUCCESS: return fetchAccessableGroupsSuccess( state, action );
        
        case actionTypes.FETCH_UNACCESSABLE_GROUPS_START: return fetchUnaccessableGroupsStart( state, action );
        case actionTypes.FETCH_UNACCESSABLE_GROUPS_SUCCESS: return fetchUnaccessableGroupsSuccess( state, action );

        case actionTypes.CLOSE_UPDATE_USER_GROUP_SNACKBAR: return closeUpdateUserGroupSnackbar( state, action );
        case actionTypes.UPDATE_GROUP_SUCCESS: return updateUserGroupSuccess( state, action );

        case actionTypes.UPDATE_USER_SUCCESS: return updateUserSuccess( state, action );
        case actionTypes.CREATE_USER_SUCCESS: return createUserSuccess( state, action );
        case actionTypes.DELETE_USER_SUCCESS: return deleteUserSuccess( state, action );
        

        case actionTypes.FETCH_OPEN_POSTEN_START: return fetchOpenPostenStart( state, action );
        case actionTypes.FETCH_OPEN_POSTEN_SUCCESS: return fetchOpenPostenSuccess( state, action );

        case actionTypes.SEND_OPEN_POST_SUCCESS: return sendOpenPostSuccess( state, action );

        case actionTypes.REFRESH_DEBITER_GROUPS_START: return refreshDebiterGroupsStart( state, action );
        case actionTypes.REFRESH_DEBITER_GROUPS_SUCCESS: return refreshDebiterGroupsSuccess( state, action );

        case actionTypes.IMPORT_USERS_SIB_SUCCESS: return importUsersSIBSuccess( state, action );
        case actionTypes.IMPORT_USERS_SIB_FAIL: return importUsersSIBFail( state, action );

        case actionTypes.FETCH_LATEST_IMPORT_SUCCESS: return fetchLatestImportSuccess( state, action );
        case actionTypes.FETCH_LATEST_IMPORT_FAIL: return fetchLatestImportFail( state, action );

        default: return state;
    }
};

export default reducer;