import axios from 'axios';
import * as actionTypes from './actionTypes.js'
import moment from 'moment';

export const fetchHbxDecoDebiteurTurnoverStart = () => {
    return {
        type: actionTypes.FETCH_HBXDECO_DEBITEUR_TURNOVER_START
    };
};

export const fetchHbxDecoDebiteurTurnoverSuccess = debiteurTurnover => {
    return {
        type: actionTypes.FETCH_HBXDECO_DEBITEUR_TURNOVER_SUCCESS,
        debiteurTurnover
    };
};

export const fetchHbxDecoDebiteurTurnoverFail = error => {
    return {
        type: actionTypes.FETCH_HBXDECO_DEBITEUR_TURNOVER_FAIL,
        error: error
    };
};

export const fetchHbxDecoDebiteurTurnover = (debnr) => {
    return dispatch => {
        dispatch(fetchHbxDecoDebiteurTurnoverStart());
        axios
            .get('hbxdeco/GetTurnoverByCustomer/' + debnr)
            .then(res => {
                dispatch(fetchHbxDecoDebiteurTurnoverSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchHbxDecoDebiteurTurnoverFail(error));
            });
    };
};

export const fetchHbxDecoTurnoverFinancialGroupsStart = () => {
    return {
        type: actionTypes.FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_START
    };
};

export const fetchHbxDecoTurnoverFinancialGroupsSuccess = financialGroups => {
    return {
        type: actionTypes.FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_SUCCESS,
        financialGroups
    };
};

export const fetchHbxDecoTurnoverFinancialGroupsFail = error => {
    return {
        type: actionTypes.FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_FAIL,
        error: error
    };
};

export const fetchHbxDecoTurnoverFinancialGroups = () => {
    return dispatch => {
        dispatch(fetchHbxDecoTurnoverFinancialGroupsStart());
        axios
            .get('hbxdeco/GetFinancialGroupDistribution')
            .then(res => {
                dispatch(fetchHbxDecoTurnoverFinancialGroupsSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchHbxDecoTurnoverFinancialGroupsFail(error));
            });
    };
};

export const fetchHbxDecoTurnoverFinancialGroupsAllCustomersStart = () => {
    return {
        type: actionTypes.FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_ALL_CUSTOMERS_START
    };
};

export const fetchHbxDecoTurnoverFinancialGroupsAllCustomersSuccess = financialGroupsAllCustomers => {
    return {
        type: actionTypes.FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_ALL_CUSTOMERS_SUCCESS,
        financialGroupsAllCustomers
    };
};

export const fetchHbxDecoTurnoverFinancialGroupsAllCustomersFail = error => {
    return {
        type: actionTypes.FETCH_HBXDECO_TURNOVER_FINANCIAL_GROUPS_ALL_CUSTOMERS_FAIL,
        error: error
    };
};

export const fetchHbxDecoTurnoverFinancialGroupsAllCustomers = () => {
    return dispatch => {
        dispatch(fetchHbxDecoTurnoverFinancialGroupsAllCustomersStart());
        axios
            .get('hbxdeco/GetFinancialGroupDistributionAllCustomers')
            .then(res => {
                dispatch(fetchHbxDecoTurnoverFinancialGroupsAllCustomersSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchHbxDecoTurnoverFinancialGroupsAllCustomersFail(error));
            });
    };
};

export const fetchHbxDecoDashboardBlocksStart = () => {
    return {
        type: actionTypes.FETCH_HBXDECO_DASHBOARD_BLOCKS_START
    };
};

export const fetchHbxDecoDashboardBlocksSuccess = blocksData => {
    return {
        type: actionTypes.FETCH_HBXDECO_DASHBOARD_BLOCKS_SUCCESS,
        blocksData
    };
};

export const fetchHbxDecoDashboardBlocksFail = error => {
    return {
        type: actionTypes.FETCH_HBXDECO_DASHBOARD_BLOCKS_FAIL,
        error: error
    };
};

export const fetchHbxDecoDashboardBlocks = (startDate, endDate) => {
    return dispatch => {
        dispatch(fetchHbxDecoDashboardBlocksStart());
        axios
            .get(`hbxdeco/GetHbxDecoDashboardBlocks?startDateString=${startDate}&endDateString=${endDate}`)
            .then(res => {
                dispatch(fetchHbxDecoDashboardBlocksSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchHbxDecoDashboardBlocksFail(error));
            });
    };
};

export const fetchHbxDecoDashboardTablesStart = () => {
    return {
        type: actionTypes.FETCH_HBXDECO_DASHBOARD_TABLES_START
    };
};

export const fetchHbxDecoDashboardTablesSuccess = tablesData => {
    return {
        type: actionTypes.FETCH_HBXDECO_DASHBOARD_TABLES_SUCCESS,
        tablesData
    };
};

export const fetchHbxDecoDashboardTablesFail = error => {
    return {
        type: actionTypes.FETCH_HBXDECO_DASHBOARD_TABLES_FAIL,
        error: error
    };
};

export const fetchHbxDecoDashboardTables = (startDate, endDate) => {
    return dispatch => {
        dispatch(fetchHbxDecoDashboardTablesStart());
        axios
            .get(`hbxdeco/GetDashboardTopTables?startDateString=${startDate}&endDateString=${endDate}`)
            .then(res => {
                dispatch(fetchHbxDecoDashboardTablesSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchHbxDecoDashboardTablesFail(error));
            });
    };
};

export const fetchHbxDecoDashboardKoersenStart = () => {
    return {
        type: actionTypes.FETCH_HBXDECO_DASHBOARD_KOERSEN_START
    };
};

export const fetchHbxDecoDashboardKoersenSuccess = koersen => {
    return {
        type: actionTypes.FETCH_HBXDECO_DASHBOARD_KOERSEN_SUCCESS,
        koersen
    };
};

export const fetchHbxDecoDashboardKoersenFail = error => {
    return {
        type: actionTypes.FETCH_HBXDECO_DASHBOARD_KOERSEN_FAIL,
        error: error
    };
};

export const fetchHbxDecoDashboardKoersen = () => {
    return dispatch => {
        dispatch(fetchHbxDecoDashboardKoersenStart());
        axios
            .get(`hbxdeco/GetDashboardStijgersAndDalers`)
            .then(res => {
                dispatch(fetchHbxDecoDashboardKoersenSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchHbxDecoDashboardKoersenFail(error));
            });
    };
};

export const fetchHbxDecoTurnoverStart = () => {
    return {
        type: actionTypes.FETCH_HBXDECO_TURNOVER_START
    };
};

export const fetchHbxDecoTurnoverSuccess = turnover => {
    return {
        type: actionTypes.FETCH_HBXDECO_TURNOVER_SUCCESS,
        turnover
    };
};

export const fetchHbxDecoTurnoverFail = error => {
    return {
        type: actionTypes.FETCH_HBXDECO_TURNOVER_FAIL,
        error: error
    };
};

export const fetchHbxDecoTurnover = () => {
    return dispatch => {
        dispatch(fetchHbxDecoTurnoverStart());
        axios
            .get(`hbxdeco/GetAllTurnover`)
            .then(res => {
                dispatch(fetchHbxDecoTurnoverSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchHbxDecoTurnoverFail(error));
            });
    };
};

export const fetchHbxDecoFinancieleGroepenStart = () => {
    return {
        type: actionTypes.FETCH_HBXDECO_FINANCIELE_GROEPEN_START
    };
};

export const fetchHbxDecoFinancieleGroepenSuccess = financieleGroepen => {
    return {
        type: actionTypes.FETCH_HBXDECO_FINANCIELE_GROEPEN_SUCCESS,
        financieleGroepen
    };
};

export const fetchHbxDecoFinancieleGroepenFail = error => {
    return {
        type: actionTypes.FETCH_HBXDECO_FINANCIELE_GROEPEN_FAIL,
        error: error
    };
};

export const fetchHbxDecoFinancieleGroepen = () => {
    return dispatch => {
        dispatch(fetchHbxDecoFinancieleGroepenStart());
        axios
            .get(`hbxdeco/GetFinancieleGroepen`)
            .then(res => {
                const manipulatedFinancieleGroepen = [{ id: 0, text: 'Alle groepen' }, ...res.data];
                // console.log(res.data);
                dispatch(fetchHbxDecoFinancieleGroepenSuccess(manipulatedFinancieleGroepen));
            })
            .catch(error => {
                dispatch(fetchHbxDecoFinancieleGroepenFail(error));
            });
    };
};

export const fetchHbxDecoOrderFrequencyStart = () => {
    return {
        type: actionTypes.FETCH_HBXDECO_ORDER_FREQUENCY_START
    };
};

export const fetchHbxDecoOrderFrequencySuccess = orderFrequency => {
    return {
        type: actionTypes.FETCH_HBXDECO_ORDER_FREQUENCY_SUCCESS,
        orderFrequency
    };
};

export const fetchHbxDecoOrderFrequencyFail = error => {
    return {
        type: actionTypes.FETCH_HBXDECO_ORDER_FREQUENCY_FAIL,
        error: error
    };
};

export const fetchHbxDecoOrderFrequency = (startDate, endDate) => {
    return dispatch => {
        dispatch(fetchHbxDecoOrderFrequencyStart());
        axios
            .get(`hbxdeco/GetWeeklyOrderFrequency?startDateString=${startDate}&endDateString=${endDate}`)
            .then(res => {
                dispatch(fetchHbxDecoOrderFrequencySuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchHbxDecoOrderFrequencyFail(error));
            });
    };
};

export const fetchHbxDecoCreditCustomersStart = () => {
    return {
        type: actionTypes.FETCH_HBXDECO_CREDIT_CUSTOMERS_START
    };
};

export const fetchHbxDecoCreditCustomersSuccess = creditCustomers => {
    return {
        type: actionTypes.FETCH_HBXDECO_CREDIT_CUSTOMERS_SUCCESS,
        creditCustomers
    };
};

export const fetchHbxDecoCreditCustomersFail = error => {
    return {
        type: actionTypes.FETCH_HBXDECO_CREDIT_CUSTOMERS_FAIL,
        error: error
    };
};

export const fetchHbxDecoCreditCustomers = (sortByCreditValue) => {
    return dispatch => {
        dispatch(fetchHbxDecoCreditCustomersStart());
        axios
            .get('hbxdeco/GetTopCreditCustomers?sortByCreditValue='+ sortByCreditValue)
            .then(res => {
                dispatch(fetchHbxDecoCreditCustomersSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchHbxDecoCreditCustomersFail(error));
            });
    };
};

export const fetchHbxDecoOverviewStart = () => {
    return {
        type: actionTypes.FETCH_HBXDECO_OVERVIEW_START
    };
};

export const fetchHbxDecoOverviewSuccess = overview => {
    return {
        type: actionTypes.FETCH_HBXDECO_OVERVIEW_SUCCESS,
        overview
    };
};

export const fetchHbxDecoOverviewFail = error => {
    return {
        type: actionTypes.FETCH_HBXDECO_OVERVIEW_FAIL,
        error: error
    };
};

export const fetchHbxDecoOverview = () => {
    return dispatch => {
        dispatch(fetchHbxDecoOverviewStart());
        axios
            .get(`hbxdeco/GetOverview`)
            .then(res => {
                dispatch(fetchHbxDecoOverviewSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchHbxDecoOverviewFail(error));
            });
    };
};

export const fetchHbxDecoPastYearOverviewStart = () => {
    return {
        type: actionTypes.FETCH_HBXDECO_PASTYEAR_OVERVIEW_START
    };
};

export const fetchHbxDecoPastYearOverviewSuccess = pastYearOverview => {
    return {
        type: actionTypes.FETCH_HBXDECO_PASTYEAR_OVERVIEW_SUCCESS,
        pastYearOverview
    };
};

export const fetchHbxDecoPastYearOverviewFail = error => {
    return {
        type: actionTypes.FETCH_HBXDECO_PASTYEAR_OVERVIEW_FAIL,
        error: error
    };
};

export const fetchHbxDecoPastYearOverview = () => {
    return dispatch => {
        dispatch(fetchHbxDecoPastYearOverviewStart());
        axios
            .get(`hbxdeco/GetPastYearOverview`)
            .then(res => {
                dispatch(fetchHbxDecoPastYearOverviewSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchHbxDecoPastYearOverviewFail(error));
            });
    };
};

export const fetchHbxDecoReservationsOverviewStart = () => {
    return {
        type: actionTypes.FETCH_HBXDECO_RESERVATIONS_OVERVIEW_START
    };
};

export const fetchHbxDecoReservationsOverviewSuccess = reservationsOverview => {
    return {
        type: actionTypes.FETCH_HBXDECO_RESERVATIONS_OVERVIEW_SUCCESS,
        reservationsOverview
    };
};

export const fetchHbxDecoReservationsOverviewFail = error => {
    return {
        type: actionTypes.FETCH_HBXDECO_RESERVATIONS_OVERVIEW_FAIL,
        error: error
    };
};

export const fetchHbxDecoReservationsOverview = () => {
    return dispatch => {
        dispatch(fetchHbxDecoReservationsOverviewStart());
        axios
            .get(`hbxdeco/GetHbxDecoReservations`)
            .then(res => {
                dispatch(fetchHbxDecoReservationsOverviewSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchHbxDecoReservationsOverviewFail(error));
            });
    };
};

export const fetchHbxDecoTargetOverviewStart = () => {
    return {
        type: actionTypes.FETCH_HBXDECO_TARGET_OVERVIEW_START
    };
};

export const fetchHbxDecoTargetOverviewSuccess = targetOverview => {
    return {
        type: actionTypes.FETCH_HBXDECO_TARGET_OVERVIEW_SUCCESS,
        targetOverview
    };
};

export const fetchHbxDecoTargetOverviewFail = error => {
    return {
        type: actionTypes.FETCH_HBXDECO_TARGET_OVERVIEW_FAIL,
        error: error
    };
};

export const fetchHbxDecoTargetOverview = () => {
    return dispatch => {
        dispatch(fetchHbxDecoTargetOverviewStart());
        axios
            .get(`hbxdeco/GetTargetOverview`)
            .then(res => {
                dispatch(fetchHbxDecoTargetOverviewSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchHbxDecoTargetOverviewFail(error));
            });
    };
};

export const fetchHbxDecoMargesOverviewStart = () => {
    return {
        type: actionTypes.FETCH_HBXDECO_MARGES_OVERVIEW_START
    };
};

export const fetchHbxDecoMargesOverviewSuccess = margesOverview => {
    return {
        type: actionTypes.FETCH_HBXDECO_MARGES_OVERVIEW_SUCCESS,
        margesOverview
    };
};

export const fetchHbxDecoMargesOverviewFail = error => {
    return {
        type: actionTypes.FETCH_HBXDECO_MARGES_OVERVIEW_FAIL,
        error: error
    };
};

export const fetchHbxDecoMargesOverview = () => {
    return dispatch => {
        dispatch(fetchHbxDecoMargesOverviewStart());
        axios
            .get(`hbxdeco/GetMargesOverview`)
            .then(res => {
                dispatch(fetchHbxDecoMargesOverviewSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchHbxDecoMargesOverviewFail(error));
            });
    };
};


export const downloadHbxDecoTurnover = (info) => {
    return dispatch => {
        axios.post('hbxdeco/DownloadTurnover', info, {responseType: 'blob'})
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Turnover${moment(new Date()).format('YYYY-MM-DD')}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    };
}