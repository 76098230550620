import React from 'react';
import { ButtonGroup, Button } from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {FuseAnimateGroup} from '../../../../../@fuse';
import OverviewWidget, {lineData} from '../../../../components/Widgets/LineChart';
import _ from 'lodash';
import { withTranslation } from "react-i18next";
import useDefaultQuery from '../../../../../api/useDefaultQuery';
import BarLineChartOverview from '../../../../components/Widgets/BarLineChartOverview';
import {connect} from 'react-redux';
import * as actions from '../../../../store/crm/actions';

const styles = theme => ({
    layoutRoot: {},
});

const colors = {
    lightBlue: {
        backgroundColor: 'rgba(0,229,255, .3)',
        borderColor: 'rgb(0,229,255)',
    }, 
    orange: {
        backgroundColor: 'rgba(255,196,0, .3)',
        borderColor: 'rgb(255,196,0)'
    }, 
    purple: {
        backgroundColor: 'rgba(206, 147, 216, .3)',
        borderColor: 'rgb(206, 147, 216)'
    }, 
    red: {
        backgroundColor: 'rgba(245, 0, 87, .3)',
        borderColor: 'rgb(245, 0, 87)'
    }, 
    green: {
        backgroundColor: 'rgba(0, 230, 118, .3)',
        borderColor: 'rgb(0, 230, 118)'
    },
    greyBlue: {
        backgroundColor: 'rgba(96, 125, 140, .3)',
        borderColor: 'rgb(96, 125, 140)',
    }
};

const currentYear = new Date().getFullYear();
const lastYear = new Date().getFullYear() - 1;

const getSumOfValues = (values) => (values.reduce((a, b) => (a + b), 0))

const createDefaultDataset = (cyValues, pyValues, toFixedAmount, Target) => (
    [
        {
            label: (currentYear),
            data : cyValues,
            fill : 'start',
        },
        {
            label: (lastYear),
            data : pyValues,
            fill : 'start'
        },
        {
            label: 'Target',
            data : pyValues.map(v => (v * Target).toFixed(toFixedAmount)),
            fill : 'start'
        }
    ]
)

const stelenTypes = [
    { name: 'Totaal', turnoverAttribute: 'Turnover', amountAttribute: 'NumOfTotal', t: 'total'},
    { name: 'Bloemen', turnoverAttribute: 'FlowersTurnover', amountAttribute: 'NumOfFlowers', t: 'FLOWERS'},
    { name: 'Planten', turnoverAttribute: 'PlantsTurnover', amountAttribute: 'NumOfPlants', t: 'PLANTS'},
    { name: 'Overig', turnoverAttribute: 'RemainingTurnover', amountAttribute: 'NumOfRemaining', t: 'OTHERS'},
]

const Overview = (props) => {
    
    const [stelenType, setStelenType] = React.useState(stelenTypes[0]);
    const { t } = props;
    const cyOverviewQuery = useDefaultQuery('cyOverview', 'overview/getoverview');
    const pyOverviewQuery = useDefaultQuery('pyOverview', 'overview/getpastyearoverview');
    const newnlostcustomersQuery = useDefaultQuery('newnlostcustomers', 'overview/getnewnlostcustomers');

    const defaultHeaderLabels = [lastYear, currentYear, 'Target']
    if(cyOverviewQuery.isLoading || pyOverviewQuery.isLoading || newnlostcustomersQuery.isLoading) {
        return null;
    }
    const overview = cyOverviewQuery.data;
    const pastYearOverview = pyOverviewQuery.data;
    const NewNLostData = newnlostcustomersQuery.data;
    
    lineData.labels = Object.keys(pastYearOverview.labels).length >= Object.keys(overview.labels).length ? pastYearOverview.labels : overview.labels;
    
    const lineChartData = _.cloneDeep(lineData);
    lineChartData.options.scales.yAxes[0].ticks.stepSize = 50000;
    lineChartData.options.scales.yAxes[0].ticks.beginAtZero = true;
    lineChartData.datasets['turnover'] = createDefaultDataset(
        overview.overviewCollections.find(c => c.title === 'Turnover').values,
        pastYearOverview.overviewCollections.find(c => c.title === 'Turnover').values,
        0, (1 + (pastYearOverview.target/100))
    );
    const turnoverChart = <OverviewWidget data={lineChartData} dataset="turnover" overviewChart headerText={t('TURNOVER')} type="euro"
        currentValue={getSumOfValues(overview.overviewCollections.find(c => c.title === 'Turnover').values)} 
        targetValue={pastYearOverview.headerBlocks.totalTurnover} 
        colors={[colors.orange, colors.lightBlue, colors.red]} 
        headerLabels={defaultHeaderLabels} />
    
    const stelenChartData = _.cloneDeep(lineData);
    stelenChartData.options.scales.yAxes[0].ticks.stepSize = 10000;
    stelenChartData.options.scales.yAxes[0].ticks.beginAtZero = true;
    stelenChartData.datasets['turnover'] = createDefaultDataset(
        overview.overviewCollections.find(c => c.title === stelenType.amountAttribute).values,
        pastYearOverview.overviewCollections.find(c => c.title === stelenType.amountAttribute).values,
        0, (1 + (pastYearOverview.target/100))
    );
    const stelenChart = <OverviewWidget data={stelenChartData} dataset="turnover" overviewChart headerText={t("STELEN")} type="aantal"
        currentValue={getSumOfValues(overview.overviewCollections.find(c => c.title === stelenType.amountAttribute).values)} 
        targetValue={pastYearOverview.overviewCollections.find(c => c.title === stelenType.amountAttribute).total} 
        // targetValue={pastYearOverview.headerBlocks.totalTurnover} 
        colors={[colors.orange, colors.lightBlue, colors.red]} 
        headerLabels={defaultHeaderLabels} 
        additionalInformation={
            <ButtonGroup className="float-right" disableElevation variant="contained" color="primary">
                {stelenTypes.map(s => (
                    <Button key={s.name} color={stelenType.name === s.name ? "secondary" : "primary"} onClick={()=>setStelenType(s)}>{t(s.t)}</Button>
                ))}
            </ButtonGroup>
        }
        hideChips
        />
    

    const amountOfClientsData = _.cloneDeep(lineData);
    amountOfClientsData.options.scales.yAxes[0].ticks.stepSize = 1;
    const cyPercentages = overview.overviewCollections.find(c => c.title === 'NumOfClients').values.map(a => ((a / overview.totalAmountOfClients) * 100));
    const pyPercentages = pastYearOverview.overviewCollections.find(c => c.title === 'NumOfClients').values.map(a => ((a / overview.totalAmountOfClients) * 100));
    amountOfClientsData.datasets['activeCustomersPercent'] = createDefaultDataset(
        cyPercentages,
        pyPercentages,
        2, (1 + (pastYearOverview.target/100))
    );
    const activeCustomersPercentChart = <OverviewWidget data={amountOfClientsData} dataset="activeCustomersPercent" overviewChart headerText={'% ' + t("AMNTCLIENTACTIVE")} 
        headerTextTooltip={t("QTYORDERSTOOLTIP")} type="percentage" 
        currentValue={(getSumOfValues(cyPercentages) / cyPercentages.length).toFixed(2)} 
        targetValue={pastYearOverview.headerBlocks.totalClientsPercentage.toFixed(2)}
        headerLabels={defaultHeaderLabels}
        colors={[colors.orange, colors.lightBlue, colors.red]} />

    const numOfInvoicesData = _.cloneDeep(lineData);
    numOfInvoicesData.options.scales.yAxes[0].ticks.stepSize = 50;
    numOfInvoicesData.datasets['numOfInvoices'] = createDefaultDataset(
        overview.overviewCollections.find(c => c.title === 'NumOfInvoices').values,
        pastYearOverview.overviewCollections.find(c => c.title === 'NumOfInvoices').values,
        0, (1 + (pastYearOverview.target/100))
    );
    const amountOfOrdersChart = <OverviewWidget data={numOfInvoicesData} dataset="numOfInvoices" overviewChart headerText={t("QTYORDERS")} headerTextTooltip={t("QTYORDERSTOOLTIP")}
        currentValue={getSumOfValues(overview.overviewCollections.find(c => c.title === 'NumOfInvoices').values)} 
        targetValue={pastYearOverview.headerBlocks.totalInvoices}
        headerLabels={defaultHeaderLabels}
        colors={[colors.orange, colors.lightBlue, colors.red]} />

    const activeCustomersData = _.cloneDeep(lineData);
    activeCustomersData.options.scales.yAxes[0].ticks.stepSize = 50;
    activeCustomersData.datasets['numOfClients'] = createDefaultDataset(
        overview.overviewCollections.find(c => c.title === 'NumOfClients').values,
        pastYearOverview.overviewCollections.find(c => c.title === 'NumOfClients').values,
        0, (1 + (pastYearOverview.target/100))
    );
    const amountOfActiveCustomersChart = <OverviewWidget data={activeCustomersData} dataset="numOfClients" overviewChart headerText={t("ACTIVECUSTOMERS")}
    headerTextTooltip={t("ACTIVECUSTOMERSTOOLTIP")}
    currentValue={getSumOfValues(overview.overviewCollections.find(c => c.title === 'NumOfClients').values)} 
    targetValue={pastYearOverview.headerBlocks.totalClients}
    headerLabels={defaultHeaderLabels}
    colors={[colors.orange, colors.lightBlue, colors.red]} />

    const NewNLostBarLineChart = <BarLineChartOverview {...NewNLostData} headerText={t("NEWNLOSTCUSTOMERS")}
    TotalNewCustomers={getSumOfValues(NewNLostData.overviewCollections.find(c => c.title === 'FirstOrder').values)} 
    TotalLostCustomers={getSumOfValues(NewNLostData.overviewCollections.find(c => c.title === 'LosingCustomers').values)}
    onDownloadExcel={() => props.onDownloadExcel(NewNLostData.overviewCollections.find(c => c.title === 'LosingCustomers').values)}
    />
    
    return (
        <div className="w-full p-12">
            <FuseAnimateGroup
                enter={{
                    animation: "transition.slideUpBigIn"
                }}
            >
                <div className="flex flex-col md:flex-row p-12">
                    <div className="flex flex-1 flex-col min-w-0">
                        <div className="widget w-full">
                            {NewNLostBarLineChart}
                        </div>
                    </div>
                </div> 
                
                <div className="flex flex-col md:flex-row p-12">
                    <div className="flex flex-1 flex-col min-w-0">
                        <div className="widget w-full">
                            {stelenChart}
                        </div>
                    </div>
                </div> 
                <div className="flex flex-col md:flex-row p-12">
                    <div className="flex flex-1 flex-col min-w-0">
                        <div className="widget w-full">
                            {activeCustomersPercentChart}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row p-12">
                    <div className="flex flex-1 flex-col min-w-0">
                        <div className="widget w-full">
                            {amountOfOrdersChart}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row p-12">
                    <div className="flex flex-1 flex-col min-w-0">
                        <div className="widget w-full">
                            {amountOfActiveCustomersChart}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row p-12">
                    <div className="flex flex-1 flex-col min-w-0">
                        <div className="widget w-full">
                            {turnoverChart}
                        </div>
                    </div>
                </div> 
            </FuseAnimateGroup>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onDownloadExcel: (data) => dispatch(actions.DownloadNewNLostCustomers(data)),
    }
}

export default connect(null, mapDispatchToProps) (withStyles(styles, {withTheme: true})(withTranslation()(Overview)));