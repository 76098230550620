import React from 'react';
import {Card, CardContent, List, ListItem, ListItemText, CardHeader, Divider} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

const specialOfferSkeleton = () => {
    return (
        <div className="flex flex-wrap w-full sm:w-1/2 md:w-1/2 p-16 sm:p-12">
            <Card className="w-full w1/2">
                <CardHeader
                    title={<Skeleton width="40%" height={40} />}
                />
                {/* <Divider /> */}
                <CardHeader
                    avatar={
                            <Skeleton variant="circle" height={50} width={50} />
                    }
                    // action={}
                    title={<Skeleton width="50%" height={30} />}
                    subheader={<><Skeleton width="80%" height={20} /> <Skeleton width="35%" height={20} /></>}
                />
                <Divider />
                <CardContent className="p-24">
                <List className="p-0"> 
                    <ListItem className="p-0">
                        <Skeleton variant="rect" width={70} height={60} />
                        <ListItemText className="pl-12"
                        primary={
                            <>
                                <Skeleton width="90%" height={20} />
                                <Skeleton width="65%" height={20} />
                                <Skeleton width="65%" height={20} />
                            </>
                        } />
                    </ListItem>
                    <ListItem className="p-0">
                        <Skeleton variant="rect" width={70} height={60} />
                        <ListItemText className="pl-12"
                        primary={
                            <>
                                <Skeleton width="90%" height={20} />
                                <Skeleton width="65%" height={20} />
                                <Skeleton width="65%" height={20} />
                            </>
                        } />
                    </ListItem>
                    <ListItem className="p-0">
                        <Skeleton variant="rect" width={70} height={60} />
                        <ListItemText className="pl-12"
                        primary={
                            <>
                                <Skeleton width="90%" height={20} />
                                <Skeleton width="65%" height={20} />
                                <Skeleton width="65%" height={20} />
                            </>
                        } />
                    </ListItem>
                    <ListItem className="p-0">
                        <Skeleton variant="rect" width={70} height={60} />
                        <ListItemText className="pl-12"
                        primary={
                            <>
                                <Skeleton width="90%" height={20} />
                                <Skeleton width="65%" height={20} />
                                <Skeleton width="65%" height={20} />
                            </>
                        } />
                    </ListItem>
                    <ListItem className="p-0">
                        <Skeleton variant="rect" width={70} height={60} />
                        <ListItemText className="pl-12"
                        primary={
                            <>
                                <Skeleton width="90%" height={20} />
                                <Skeleton width="65%" height={20} />
                                <Skeleton width="65%" height={20} />
                            </>
                        } />
                    </ListItem>
                </List>
                </CardContent>
            </Card>
        </div>
    )
}

export default specialOfferSkeleton;