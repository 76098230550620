import React from 'react';
import { useQuery } from 'react-query'
import axios from 'axios';
import ReactTable from "react-table";
import { FuseAnimate, FusePageSimple } from '../../../../@fuse';
import { Icon, Typography, IconButton, Tooltip, Badge, LinearProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom'

const VisitsOverview = () => {
    const { t } = useTranslation();
    const { data: visitors, isLoading } = useQuery('visits',
        async () => {
            const res = await axios.get(
                "visits/Getallvisits"
            );
            return res.data;
        },  
        {
            staleTime: 60000
        }
    );
    return (
        <FusePageSimple
            header={
                <div className="flex flex-1 items-center justify-between p-24">
                    <div className="flex flex-col">
                        <div className="flex items-center mb-16">
                            <Icon className="text-18" color="action">home</Icon>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">{t("VISITS")}</Typography>
                        </div>
                        <Typography variant="h6">Aanstaande showroombezoeken</Typography>
                        <Typography className="text-12">van klanten uit jouw debiteurgroep en van showroombezoeken waarbij je betrokken bent</Typography>
                    </div>
                    <div className="flex flex-1 items-center justify-end px-12">
                        <Tooltip title={t('CREATE') + " " + t('NEW') + " " + t('VISITS')}>
                            <Link style={{ cursor: 'pointer' }} to={`/visit/create`}>
                                <IconButton>
                                    <Badge color="secondary">
                                        <Icon>person_add</Icon>
                                    </Badge>
                                </IconButton>
                            </Link>
                        </Tooltip>
                    </div>
                </div>
            }
            content={!isLoading ?
                <FuseAnimate animation="transition.slideUpIn" delay={300}>
                    <div className="w-full">
                        <ReactTable
                            className="-striped -highlight border-0"
                            data={visitors}
                            columns={[
                                {
                                    Header: "Bewerk",
                                    filterable: false,
                                    className: "justify-center",
                                    width: 70,
                                    Cell: row => (
                                        <Link style={{ cursor: 'pointer' }} to={`/visit/${row.original.visitId}`}>
                                            <IconButton aria-label="Edit">
                                                <Icon color="primary">edit</Icon>
                                            </IconButton>
                                        </Link>
                                    )
                                },
                                {
                                    Header: "Deb nr.",
                                    accessor: "debtorNumber",
                                    filterable: true,
                                    className: "font-bold justify-center",
                                    width: 100,
                                    Cell: row => (<Link style={{ cursor: 'pointer' }} to={`/visit/${row.original.visitId}`}>{row.value}</Link>)
                                },
                                {
                                    Header: "Naam",
                                    accessor: "debtorName",
                                    filterable: true,
                                    className: "font-bold"
                                },
                                {
                                    Header: "Arrival",
                                    width: 270,
                                    accessor: "visitDate",
                                    filterable: true,
                                    className: "justify-center",
                                    Cell: row => row.value ?
                                        row.original.status === 3 ? <Tooltip title="Customer cancelled his visit"><span style={{ color: 'red' }}><Icon className="text-18" style={{ color: 'red' }}>cancel</Icon> {moment(row.value).format("DD-MM-YYYY") + " @ " + row.original.timeOfArrival}</span></Tooltip> :
                                            row.original.status === 1 ? <Tooltip title="Customer changed his visit"><span><Icon className="text-18" style={{ color: 'orange' }}>warning</Icon> {moment(row.value).format("DD-MM-YYYY") + " @ " + row.original.timeOfArrival}</span></Tooltip> : moment(row.value).format("DD-MM-YYYY") + " @ " + row.original.timeOfArrival
                                        :
                                        <Icon className="text-16" color="error">clear</Icon>
                                },
                                {
                                    Header: "Duration",
                                    width: 130,
                                    accessor: "durationOfStay",
                                    filterable: true,
                                    className: "justify-center",
                                    Cell: row => row.value ? row.value
                                        :
                                        <Icon className="text-16" color="error">clear</Icon>
                                },
                                {
                                    Header: "Email",
                                    accessor: "debtorEmailAddress",
                                    className: "justify-center",
                                    width: 70,
                                    filterable: true,
                                    Cell: row => row.value === "" || row.value === null ?
                                        <Tooltip title={'Email'}>
                                            <Icon className="text-18" color="error">clear</Icon>
                                        </Tooltip>
                                        :
                                        <><Tooltip title={row.value}>
                                            <a style={{ cursor: 'pointer' }} href={`mailto:${row.value}`}>
                                                <IconButton aria-label="Email">
                                                    <Icon>email</Icon>
                                                </IconButton>
                                            </a>
                                        </Tooltip>
                                        </>
                                },
                                {
                                    Header: t("MOBILE"),
                                    accessor: "debtorPhone",
                                    className: "justify-center",
                                    width: 70,
                                    Cell: row => row.value === null ?
                                        <Tooltip title={'No ' + t("PHONE")}>
                                            <Icon className="text-18" color="error">clear</Icon>
                                        </Tooltip>
                                        :
                                        <Tooltip title={row.value}>
                                            <a style={{ cursor: 'pointer' }} href={`tel:${row.value}`}>
                                                <IconButton aria-label="Phone">
                                                    <Icon>phone_iphone</Icon>
                                                </IconButton>
                                            </a>
                                        </Tooltip>
                                },
                                {
                                    Header: <Icon color="action">restaurant</Icon>,
                                    width: 70,
                                    accessor: "arrangeLunch",
                                    filterable: true,
                                    className: "justify-center",
                                    Cell: row => row.value === false ? <Icon className="text-18" color="error">clear</Icon> :
                                        <Tooltip title="Arrange lunch yes">
                                            <Icon className="text-18" style={{ color: 'green' }}>check</Icon>
                                        </Tooltip>
                                },
                                {
                                    Header: <Icon color="action">hotel</Icon>,
                                    width: 70,
                                    accessor: "arrangeStay",
                                    filterable: true,
                                    className: "justify-center",
                                    Cell: row => row.value === false ? <Icon className="text-18" color="error">clear</Icon> :
                                        <Tooltip title="Arrange stay yes">
                                            <Icon className="text-18" style={{ color: 'green' }}>check</Icon>
                                        </Tooltip>
                                },
                                {
                                    Header: <Icon color="action">groups</Icon>,
                                    width: 70,
                                    accessor: "amountOfPeople",
                                    filterable: true,
                                    className: "justify-center",
                                },
                                {
                                    Header: "Note",
                                    accessor: "particularities",
                                    filterable: true,
                                    className: "justify-center",
                                    width: 70,
                                    Cell: row => row.value === '' || row.value === null ?
                                        <Icon className="text-18" color="error">clear</Icon>
                                        :
                                        (
                                            <Tooltip title={row.value}>
                                                <IconButton aria-label="Comment">
                                                    <Icon>comment</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        )
                                },
                            ]}
                            pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                            defaultPageSize={visitors.length > 0 ? visitors.length : 10}
                            noDataText="Geen bezoeken gevonden"
                        />
                    </div>
                </FuseAnimate>
                : <div className="flex h-full">
                    <div className="m-auto">
                        <LinearProgress />
                        <Typography>Loading...</Typography>
                    </div>
                </div>
            }
        />
    );
};

export default VisitsOverview;