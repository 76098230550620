import React from 'react';
import {Redirect} from 'react-router-dom';
import {FuseUtils} from '../../@fuse/index';
import { iCRMConfig } from '../main/iCRM/iCRMConfig';
import { DocumentationConfig } from '../main/documentation/DocumentationConfig';
import { SpecialOfferConfig } from '../main/SpecialOffer/SpecialOfferConfig';
import { APIConfig } from '../main/API/APIConfig';
import { AuthConfig } from '../main/Auth/AuthConfig';
import { MonitorConfig } from '../main/Monitor/MonitorConfig';
import MainPage from '../main/Auth/MainPage';

export const authRoutes = [
    ...FuseUtils.generateRoutesFromConfigs([AuthConfig]),
    {
        path     : '/',
        component: MainPage
    }
];

const routeConfigs = [
    iCRMConfig,
    SpecialOfferConfig,
    DocumentationConfig,
    APIConfig,
    MonitorConfig,
];

const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path     : '/',
        component: () => <Redirect to="/iCRM/Inbox" />
    }
]
export default routes;