import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const AgreementDialog = (props) => {
    const { t } = useTranslation();
    return (
        <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('tos_pp')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              {/* {t('tos_pp_detail')}.&nbsp; */}
            {props.agreementText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            {t('disagree')}
          </Button>
          <Button onClick={props.handleAgree} color="primary" variant="contained" autoFocus>
            {t('agree')}
          </Button>
        </DialogActions>
      </Dialog>
    );
};

export default AgreementDialog;