import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

function SimpleDialog(props) {
  const { onClose, amountInFinDebnr, handleSubmitOpenOrClose, open } = props;

  const handleYesClick = () => {
    handleSubmitOpenOrClose(true);
    onClose();
  };

  const handleNoClick = () => {
    handleSubmitOpenOrClose(false)
    onClose();
  }

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Do you want to update <strong>all {amountInFinDebnr} customers</strong> in this financial group?</DialogTitle>
      <div className="flex flex-1 items-center justify-between">
        <div className="flex w-full w-1/2 m-12">
            <Button variant="outlined" color="secondary" className="w-1/2" onClick={handleNoClick}>No</Button>
        </div>
        <div className="flex w-full w-1/2 justify-end m-12">
            <Button variant="outlined" color="default" className="w-1/2" onClick={handleYesClick}>Yes</Button>
        </div>
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function SimpleDialogDemo(props) {
  const [open, setOpen] = React.useState(false);
  const {title, icon, classes, amountInFinDebnr, handleSubmitOpenOrClose} = props;

  const handleClickOpen = () => {
    if(props.amountInFinDebnr <= 1 || props.finDebnr === '*NEW*') {
        props.handleSubmitOpenOrClose(false);
    } else {
        setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
        <Button variant="contained" className={classes} onClick={handleClickOpen}>
            {icon} &nbsp;{title}
        </Button>
      <SimpleDialog open={open} onClose={handleClose} amountInFinDebnr={amountInFinDebnr} handleSubmitOpenOrClose={handleSubmitOpenOrClose} />
    </div>
  );
}
