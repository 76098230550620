import * as actionTypes from '../actions/actionTypes';
import { updateObject, signGroup, decodeToken } from '../../utility';

const initialState = {
    users: [],
    loading: false,
    user: {},
    currentGroupcode: {},
    accessableGroupcodes: [],
    allgroups: [],
    error: null,
    loginAttempts: 0,
    resetted: false,
    resetPasswordError: null,
    updatePasswordError: null,
    roles: [],
};

export const fetchAllGroupsSuccess = (state, action) => {
    return updateObject(state, {allgroups: action.allgroups});
}

export const fetchAccessableGroupcodesSuccess = (state, action) => {
    return updateObject(state, {accessableGroupcodes: action.groupcodes});
}

export const fetchCurrentGroupcodeSuccess = (state, action) => {
    localStorage.setItem('groupcode', signGroup(action.currentGroupcode));
    //window.location.reload();
    return updateObject(state, {currentGroupcode: action.currentGroupcode});
}

export const fetchLocalGroupcodeSuccess = (state, action) => {
    return updateObject(state, {currentGroupcode: decodeToken(action.currentGroupcode)});
}

export const updateCurrentGroupcodeSuccess = (state, action) => {
    // localStorage.setItem('groupcode', signGroup(action.currentGroupcode));
    window.location.reload();
    return updateObject(state, {currentGroupcode: action.currentGroupcode});
}

export const sendLoginStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const sendLoginSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, user: action.user});
}
export const sendLoginFail = ( state, action ) => {
    return updateObject(state, {loading: false, loginAttempts: state.loginAttempts+1, error: action.error});
}

export const fetchUserByIdStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const fetchUserByIdSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, user: action.user});
}
export const fetchUserByIdFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

export const resetPasswordStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const resetPasswordSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, resetted: action.resetted, resetPasswordError: null});
}
export const resetPasswordFail = ( state, action ) => {
    return updateObject(state, {loading: false, resetted: true, resetPasswordError: action.error});
}

export const updatePasswordStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const updatePasswordSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, user: action.user});
}
export const updatePasswordFail = ( state, action ) => {
    return updateObject(state, {loading: false, updatePasswordError: action.error});
}

export const fetchRolesStart = ( state, action ) => {
    return updateObject(state, {loading: true});
}
export const fetchRolesSuccess = ( state, action ) => {
    return updateObject(state, {loading: false, roles: action.roles});
}
export const fetchRolesFail = ( state, action ) => {
    return updateObject(state, {loading: false, error: action.error});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_ALL_GROUPS_SUCCESS: return fetchAllGroupsSuccess( state, action );
        case actionTypes.FETCH_ACCESSABLE_GROUPCODES_SUCCESS: return fetchAccessableGroupcodesSuccess( state, action );
        case actionTypes.FETCH_CURRENT_GROUPCODE_SUCCESS: return fetchCurrentGroupcodeSuccess( state, action );
        case actionTypes.FETCH_LOCAL_GROUPCODE_SUCCESS: return fetchLocalGroupcodeSuccess( state, action );
        case actionTypes.UPDATE_CURRENT_GROUPCODE_SUCCESS: return updateCurrentGroupcodeSuccess( state, action );

        case actionTypes.SEND_LOGIN_START: return sendLoginStart( state, action );
        case actionTypes.SEND_LOGIN_SUCCESS: return sendLoginSuccess( state, action );
        case actionTypes.SEND_LOGIN_FAIL: return sendLoginFail( state, action );

        case actionTypes.FETCH_USER_BY_ID_START: return fetchUserByIdStart( state, action );
        case actionTypes.FETCH_USER_BY_ID_SUCCESS: return fetchUserByIdSuccess( state, action );
        case actionTypes.FETCH_USER_BY_ID_FAIL: return fetchUserByIdFail( state, action );

        case actionTypes.RESET_PASSWORD_START: return resetPasswordStart( state, action );
        case actionTypes.RESET_PASSWORD_SUCCESS: return resetPasswordSuccess( state, action );
        case actionTypes.RESET_PASSWORD_FAIL: return resetPasswordFail( state, action );

        case actionTypes.UPDATE_PASSWORD_START: return updatePasswordStart( state, action );
        case actionTypes.UPDATE_PASSWORD_SUCCESS: return updatePasswordSuccess( state, action );
        case actionTypes.UPDATE_PASSWORD_FAIL: return updatePasswordFail( state, action );

        case actionTypes.FETCH_ROLES_START: return fetchRolesStart( state, action );
        case actionTypes.FETCH_ROLES_SUCCESS: return fetchRolesSuccess( state, action );
        case actionTypes.FETCH_ROLES_FAIL: return fetchRolesFail( state, action );

        default:
            return state;
    }
};

export default reducer;