import React from 'react';
import DefaultTaskOverview from './DefaultTaskOverview';
import { useTranslation } from 'react-i18next';

const TaskArchive = ({ tasksoverview, onFetchTasksOverview }) => {
    const { t } = useTranslation();

    return (
        <DefaultTaskOverview isArchive={true} title={t("TASKS") + " " + t("archive")} />
    );
};

export default(TaskArchive);