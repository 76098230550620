import {updateObject} from '../../../utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    // notes: [],
    credits: [],
    prelevements: [],
    marionettePrelevements: [],
    generatedPrelevements: false,
    GeneratedCallback: [],
    sentPrelevements: false,
    sentEmailCallback: [],
    loading: false,
    
    assignnewleads: [],
    fetchedAssignNewLeads: false,

    exportnewleads: [],
    overviewnewleads: [],
    icrmusersnewleads: [],
    favicrmusersnewleads: [],
    getnewleads: [],
    isEditedNewLead: false,

    returnednewLeads: [],
    fetchedReturnedNewLeads: false,

    isActivated: false,
    generatedExportnewleads: false,

    loadingPrelevements: false,

    viesData: {
        viesIsValid: false,
        viesName: '',
        viesAddress: '',
        btwnrdeb: '',
    }
};

// const fetchNotesOverviewSuccess = ( state, action ) => {
//     return updateObject( state, {notes: action.notes} );
// };

const fetchCreditsSuccess = ( state, action ) => {
    return updateObject( state, {credits: action.credits} );
};

const fetchPrelevementsSuccess = ( state, action ) => {
    if(action.dto.company === 22) {
        return updateObject( state, {loadingPrelevements: false, prelevements: action.prelevements, generatedPrelevements: false, GeneratedCallback: [], sentPrelevements: false} );
    } else {
        return updateObject( state, {marionettePrelevements: action.prelevements, generatedPrelevements: false, GeneratedCallback: [], sentPrelevements: false} );
    }
};
const fetchPrelevementsStart = ( state, action ) => {
    return updateObject( state, {loadingPrelevements: true } );
};

const generatePrelevementExcelAndXml = ( state, action ) => {
    // return updateObject( state, {generatedPrelevements: action.generatedPrelevements} );
    return updateObject( state, {generatedPrelevements: true, GeneratedCallback: action.generatedPrelevements, loading: false} );
};
// const generatePrelevementExcelAndXmlStart = ( state, action ) => {
//     return updateObject( state, {loading: true} );
// };

const generateRabobankPrelevementExcelAndXml = ( state, action ) => {
    return updateObject( state, {generatedPrelevements: true, GeneratedCallback: action.generatedPrelevements, loading: false} );
};
const LoaderStart = ( state, action ) => {
    return updateObject( state, {loading: true} );
};
const CompanySwitchinPrelevement = ( state, action ) => {
    return updateObject( state, {generatedPrelevements: false, GeneratedCallback: [], sentPrelevements: false} );
};

const sendPrelevement = ( state, action ) => {
    return updateObject( state, {sentPrelevements: true, sentEmailCallback: action.sentPrelevements, loading: false} );
};

const fetchMultipleEmailDbNewLeadsSuccess = ( state, action ) => {
    return updateObject( state, {MultipleEmailLeads: action.MultipleEmailLeads} );
};

const fetchMultipleVatDbNewLeadsSuccess = ( state, action ) => {
    return updateObject( state, {MultipleVatLeads: action.MultipleVatLeads} );
};
// const sendPrelevementStart = ( state, action ) => {
//     return updateObject( state, {loading: true} );
// };

// Get Assign New Leads
const fetchAssignNewLeadsStart = ( state, action ) => {
    return updateObject( state, {assignnewleads: [], fetchedAssignNewLeads: false} );
};
const fetchAssignNewLeadsSuccess = ( state, action ) => {
    return updateObject( state, {assignnewleads: action.assignnewleads, fetchedAssignNewLeads: true} );
};
// const FetchAllDebTypeSuccess = ( state, action ) => {
//     return updateObject( state, {DebType: action.DebType} );
// };

// Get Returned NewLeads //
const fetchReturnedNewLeadsSuccess = ( state, action ) => {
    return updateObject( state, {returnednewLeads: action.returnednewLeads, fetchedReturnedNewLeads: true} );
};
const activateNewLeadSuccess = ( state, action ) => {
    return updateObject( state, {isActivated: action.isActivated} );
};
const addFaviCRMUserSuccess = ( state, action ) => {
    return updateObject( state, {favisAdded: action.favisAdded} );
};
const deleteFaviCRMUserSuccess = ( state, action ) => {
    return updateObject( state, {favisDeleted: action.favisDeleted} );
};
// Get icrm users for Assign New Leads //
const fetchiCRMusersNewLeadsSuccess = ( state, action ) => {
    return updateObject( state, {icrmusersnewleads: action.icrmusersnewleads} );
};
// Get favorite icrm users for Assign New Leads //
const fetchFaviCRMusersNewLeadsSuccess = ( state, action ) => {
    return updateObject( state, {favicrmusersnewleads: action.favicrmusersnewleads} );
};
// Get Export New Leads
const fetchExportNewLeadsSuccess = ( state, action ) => {
    return updateObject( state, {exportnewleads: action.exportnewleads} );
};
// Get Overview New Leads
const fetchOverviewNewLeadsSuccess = ( state, action ) => {
    return updateObject( state, {overviewnewleads: action.overviewnewleads} );
};
// Generate Exported New Leads to Excel
const GenerateExportNewLead = ( state, action ) => {
    return updateObject( state, {generatedExportnewleads: action.generatedExportnewleads} );
};
// Generate Exact CSV
const GenerateExactExportNewLead = ( state, action ) => {
    return updateObject( state, {generatedExportnewleads: action.generatedExportnewleads} );
};
// Edit Newlead
const editNewLeadSuccess = ( state, action ) => {
    return updateObject( state, {isEditedNewLead: action.isEditedNewLead} );
};
// Check VIES VAT
const checkViesSuccess = ( state, action ) => {
    return updateObject( state, {viesData: action.viesData} );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        // case actionTypes.FETCH_NOTES_SUCCESS: return fetchNotesOverviewSuccess( state, action );

        case actionTypes.FETCH_CREDITS_SUCCESS: return fetchCreditsSuccess( state, action );

        case actionTypes.FETCH_PRELEVEMENTS_SUCCESS: return fetchPrelevementsSuccess( state, action );
        case actionTypes.FETCH_PRELEVEMENTS_START: return fetchPrelevementsStart( state, action );

        case actionTypes.GENERATE_PRELEVEMENT_SUCCESS: return generatePrelevementExcelAndXml( state, action );
        case actionTypes.GENERATE_RABOBANK_PRELEVEMENT_SUCCESS: return generateRabobankPrelevementExcelAndXml( state, action );
        // case actionTypes.GENERATE_RABOBANK_PRELEVEMENT_START: return generateRabobankPrelevementExcelAndXmlStart( state, action );
        // case actionTypes.GENERATE_PRELEVEMENT_START: return generatePrelevementExcelAndXmlStart( state, action );
        case actionTypes.PRELEVEMENT_START_LOADER: return LoaderStart( state, action );
        case actionTypes.SEND_PRELEVEMENT_SUCCESS: return sendPrelevement( state, action );
        case actionTypes.PRELEVEMENT_SWITCH_COMPANY: return CompanySwitchinPrelevement( state, action );
        
        case actionTypes.FETCH_ASSIGNNEWLEADS_START: return fetchAssignNewLeadsStart( state, action );
        case actionTypes.FETCH_ASSIGNNEWLEADS_SUCCESS: return fetchAssignNewLeadsSuccess( state, action );

        case actionTypes.FETCH_RETURNED_NEWLEADS_SUCCESS: return fetchReturnedNewLeadsSuccess( state, action );
        case actionTypes.FETCH_EXPORTNEWLEADS_SUCCESS: return fetchExportNewLeadsSuccess( state, action );
        case actionTypes.FETCH_OVERVIEWNEWLEADS_SUCCESS: return fetchOverviewNewLeadsSuccess( state, action );
        case actionTypes.FETCH_ICRMUSERS_ASSIGNNEWLEADS_SUCCESS: return fetchiCRMusersNewLeadsSuccess( state, action );
        case actionTypes.FETCH_FAV_ICRMUSERS_ASSIGNNEWLEADS_SUCCESS: return fetchFaviCRMusersNewLeadsSuccess( state, action );
        case actionTypes.ACTIVATE_NEW_LEAD_SUCCESS: return activateNewLeadSuccess( state, action );
        case actionTypes.ADD_FAV_USERS_ASSIGNNEWLEADS_SUCCESS: return addFaviCRMUserSuccess( state, action );
        case actionTypes.DELETE_FAV_USERS_ASSIGNNEWLEADS_SUCCESS: return deleteFaviCRMUserSuccess( state, action );
        case actionTypes.GENERATE_EXPORT_NEWLEADS_SUCCESS: return GenerateExportNewLead( state, action );
        case actionTypes.GENERATE_EXACT_EXPORT_NEWLEADS_SUCCESS: return GenerateExactExportNewLead( state, action );
        case actionTypes.EDIT_NEWLEAD_SUCCESS: return editNewLeadSuccess( state, action );
        case actionTypes.CHECK_VIES_SUCCESS: return checkViesSuccess( state, action );
        case actionTypes.FETCH_MULTIPLE_EMAIL_NEWLEADS_SUCCESS: return fetchMultipleEmailDbNewLeadsSuccess( state, action );
        case actionTypes.FETCH_MULTIPLE_VAT_NEWLEADS_SUCCESS: return fetchMultipleVatDbNewLeadsSuccess( state, action );
        // case actionTypes.FETCH_ALL_DEBTYPE_SUCCESS: return FetchAllDebTypeSuccess( state, action );
        
        default: return state;
    }
};

export default reducer;