import React from 'react';
import {Typography, FormControlLabel, Switch, Tooltip, Chip, Paper, withStyles} from '@material-ui/core';
import PieChart, {pieData} from '../../../../../components/Widgets/PieChart';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';

const styles = theme => ({
    chipBlue: {
        backgroundColor: '#03a9f4',
        cursor: 'default !important'
    },
    chipBronze: {
        backgroundColor: '#ef5350',
        cursor: 'default !important'
    },
    chipSilver: {
        backgroundColor: '#c5cae9',
        cursor: 'default !important'
    },
    chipGold: {
        backgroundColor: '#ffca28',
        cursor: 'default !important'
    },
    chipPlatinum: {
        backgroundColor: '#e0e0e0',
        cursor: 'default !important'
    },
});

export default withStyles(styles)(props => {
    const {t} = useTranslation();
    const {classes, chartsData, checkedWeeklyTurnover, checkedWeeklyFrequency, handleSwitchChange} = props;

    let turnoverPieChart = null;
    if(Object.keys(chartsData).length > 0) {
        const turnoverData = _.cloneDeep(pieData);
        turnoverData['title'] = 'Weekly Turnover';
        turnoverData['mainChart'].datasets[0].data = checkedWeeklyTurnover ? _.cloneDeep(chartsData.turnoverChart.turnoverList) : 
        _.cloneDeep(chartsData.turnoverChart.invoiceList);
        turnoverData['mainChart'].datasets[0].backgroundColor = ['#03a9f4', '#ef5350', '#c5cae9', '#ffca28', '#e0e0e0'];
        turnoverData['mainChart'].datasets[0].hoverBackgroundColor = ['#6ff9ff', '#ff867c', '#f5f5f5', '#fffd61', '#f5f5f5'];
        turnoverPieChart = <PieChart data={turnoverData} />
    }

    let frequencyPieChart = null;
    if(Object.keys(chartsData).length > 0) {
        const frequencyData = _.cloneDeep(pieData);
        frequencyData['title'] = 'Weekly Order Frequency';
        frequencyData['mainChart'].datasets[0].data = checkedWeeklyFrequency ? _.cloneDeep(chartsData.orderChart.turnoverList) : 
            _.cloneDeep(chartsData.orderChart.invoiceList);
        frequencyData['mainChart'].datasets[0].backgroundColor = ['#03a9f4', '#ef5350', '#c5cae9', '#ffca28', '#e0e0e0'];
        frequencyData['mainChart'].datasets[0].hoverBackgroundColor = ['#6ff9ff', '#ff867c', '#f5f5f5', '#fffd61', '#f5f5f5'];
        frequencyPieChart = <PieChart data={frequencyData} />
    }

    const frequencyTurnoverSum = Object.keys(chartsData).length > 0 ? _.sum(chartsData.orderChart.turnoverList) : 0;
    const frequencyInvoiceSum = Object.keys(chartsData).length > 0 ? _.sum(chartsData.orderChart.invoiceList) : 0;
    const turnoverTurnoverSum = Object.keys(chartsData).length > 0 ? _.sum(chartsData.turnoverChart.turnoverList) : 0;
    const turnoverInvoiceSum = Object.keys(chartsData).length > 0 ? _.sum(chartsData.turnoverChart.invoiceList) : 0;

    return (        
        <div className="flex flex-wrap">
            <div className="widget flex w-full sm:w-full md:w-1/2 p-12">
                <Paper className="w-full rounded-8 shadow-none border-1">
                    <div className="flex items-center justify-between px-16 h-64 border-b-1">
                        <Typography className="text-16">{t("WEEKLYTURNOVER")}</Typography>
                        <div className="float-right">
                            <FormControlLabel
                            control={
                                <Switch
                                checked={checkedWeeklyTurnover}
                                onChange={handleSwitchChange('checkedWeeklyTurnover')}
                                color="primary"
                                />
                            }
                            label={checkedWeeklyTurnover ? t("TURNOVER") : t("INVOICES")}
                            />
                        </div>
                    </div>
                    <div className="h-250 w-full p-24 border-b-1">
                        {turnoverPieChart}
                    </div>
                    <div className="p-0">
                        {chartsData && Object.keys(chartsData).length > 0 && turnoverInvoiceSum !== 0 && turnoverTurnoverSum !== 0 ? 
                        <>
                        <table className={"simple"}>
                            <tbody>
                                <tr>
                                    <td><Chip label="A" className={classes.chipBlue} /></td>
                                    <td> {t("TURNOVER")} under 250</td>
                                    <td>
                                        <Typography variant="body2">
                                        {checkedWeeklyTurnover ? <>&euro; {chartsData.turnoverChart.turnoverList[0].toLocaleString('nl-NL')}</> : chartsData.turnoverChart.invoiceList[0].toLocaleString('nl-NL')}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">                                
                                        <strong>{checkedWeeklyTurnover ? (chartsData.turnoverChart.turnoverList[0] / turnoverTurnoverSum * 100).toFixed(0) : 
                                            (chartsData.turnoverChart.invoiceList[0] / turnoverInvoiceSum * 100).toFixed(0)}%</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td><Chip label="B" className={classes.chipBronze} /></td>
                                    <td>{t("TURNOVER")} between 250 and 750</td>
                                    <td>
                                        <Typography variant="body2">
                                        {checkedWeeklyTurnover ? <>&euro; {chartsData.turnoverChart.turnoverList[1].toLocaleString('nl-NL')}</> : chartsData.turnoverChart.invoiceList[1].toLocaleString('nl-NL')}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">                                
                                        <strong>{checkedWeeklyTurnover ? (chartsData.turnoverChart.turnoverList[1] / turnoverTurnoverSum * 100).toFixed(0) : 
                                        (chartsData.turnoverChart.invoiceList[1] / turnoverInvoiceSum * 100).toFixed(0)}%</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td><Chip label="C" className={classes.chipSilver} /></td>
                                    <td>{t("TURNOVER")} between 750 and 1250</td>
                                    <td>
                                        <Typography variant="body2">
                                        {checkedWeeklyTurnover ? <>&euro; {chartsData.turnoverChart.turnoverList[2].toLocaleString('nl-NL')}</> : chartsData.turnoverChart.invoiceList[2].toLocaleString('nl-NL')}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">                                
                                        <strong>{checkedWeeklyTurnover ? (chartsData.turnoverChart.turnoverList[2] / turnoverTurnoverSum * 100).toFixed(0) : 
                                        (chartsData.turnoverChart.invoiceList[2] / turnoverInvoiceSum * 100).toFixed(0)}%</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td><Chip label="D" className={classes.chipGold} /></td>
                                    <td>{t("TURNOVER")} between 1250 and 2500</td>
                                    <td>
                                        <Typography variant="body2">
                                        {checkedWeeklyTurnover ? <>&euro; {chartsData.turnoverChart.turnoverList[3].toLocaleString('nl-NL')}</> : chartsData.turnoverChart.invoiceList[3].toLocaleString('nl-NL')}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">                                
                                        <strong>{checkedWeeklyTurnover ? (chartsData.turnoverChart.turnoverList[3] / turnoverTurnoverSum * 100).toFixed(0) : 
                                        (chartsData.turnoverChart.invoiceList[3] / turnoverInvoiceSum * 100).toFixed(0)}%</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td><Chip label="E" className={classes.chipPlatinum} /></td>
                                    <td>{t("TURNOVER")} above 2500</td>
                                    <td>
                                        <Typography variant="body2">
                                        {checkedWeeklyTurnover ? <>&euro; {chartsData.turnoverChart.turnoverList[4].toLocaleString('nl-NL')}</> : chartsData.turnoverChart.invoiceList[4].toLocaleString('nl-NL')}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">                                
                                        <strong>{checkedWeeklyTurnover ? (chartsData.turnoverChart.turnoverList[4] / turnoverTurnoverSum * 100).toFixed(0) : 
                                        (chartsData.turnoverChart.invoiceList[4] / turnoverInvoiceSum * 100).toFixed(0)}%</strong>
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </>
                        : null}
                    </div>
                </Paper>
            </div>
            <div className="widget flex w-full sm:w-full md:w-1/2 p-12">
                <Paper className="w-full rounded-8 shadow-none border-1">
                    <div className="flex items-center justify-between px-16 h-64 border-b-1">
                        <Tooltip title="Turnover grouped by amount of invoices per week"><Typography className="text-16">{t("WEEKLYORDERFREQUENCY")}</Typography></Tooltip>
                        <div className="float-right">
                            <FormControlLabel
                            control={
                                <Switch
                                checked={checkedWeeklyFrequency}
                                onChange={handleSwitchChange('checkedWeeklyFrequency')}
                                color="primary"
                                />
                            }
                            label={checkedWeeklyFrequency ? t("TURNOVER") : t("INVOICES")}
                            />
                        </div>
                    </div>
                    <div className="h-250 w-full p-24 border-b-1">
                        {frequencyPieChart}
                    </div>
                    <div className="p-0">
                        {chartsData && Object.keys(chartsData).length > 0 && frequencyInvoiceSum !== 0 && frequencyTurnoverSum !== 0 ? 
                        <>
                        <table className={"simple"}>
                            <tbody>
                                <tr>
                                    <td><Chip label="Blue" className={classes.chipBlue} /></td>
                                    <td>Approximately 1 order per 4 weeks</td>
                                    <td>
                                        <Typography variant="body2">
                                        {checkedWeeklyFrequency ? <>&euro;{chartsData.orderChart.turnoverList[0].toLocaleString('nl-NL')}</> : chartsData.orderChart.invoiceList[0].toLocaleString('nl-NL')}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">                                
                                        <strong>{checkedWeeklyFrequency ? (chartsData.orderChart.turnoverList[0] / frequencyTurnoverSum * 100).toFixed(0) : 
                                        (chartsData.orderChart.invoiceList[0] / frequencyInvoiceSum * 100).toFixed(0)}%</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td><Chip label="Bronze" className={classes.chipBronze} /></td>
                                    <td>Approximately 1 order per 3 weeks</td>
                                    <td>
                                        <Typography variant="body2">
                                        {checkedWeeklyFrequency ? <>&euro;{chartsData.orderChart.turnoverList[1].toLocaleString('nl-NL')}</> : chartsData.orderChart.invoiceList[1].toLocaleString('nl-NL')}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">                                
                                        <strong>{checkedWeeklyFrequency ? (chartsData.orderChart.turnoverList[1] / frequencyTurnoverSum * 100).toFixed(0) : 
                                        (chartsData.orderChart.invoiceList[1] / frequencyInvoiceSum * 100).toFixed(0)}%</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td><Chip label="Silver" className={classes.chipSilver} /></td>
                                    <td>Approximately 1 order per 2 weeks</td>
                                    <td>
                                        <Typography variant="body2">
                                        {checkedWeeklyFrequency ? <>&euro;{chartsData.orderChart.turnoverList[2].toLocaleString('nl-NL')}</> : chartsData.orderChart.invoiceList[2].toLocaleString('nl-NL')}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">                                
                                        <strong>{checkedWeeklyFrequency ? (chartsData.orderChart.turnoverList[2] / frequencyTurnoverSum * 100).toFixed(0) : 
                                        (chartsData.orderChart.invoiceList[2] / frequencyInvoiceSum * 100).toFixed(0)}%</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td><Chip label="Gold" className={classes.chipGold} /></td>
                                    <td>Approximately 1 order per week</td>
                                    <td>
                                        <Typography variant="body2">
                                        {checkedWeeklyFrequency ? <>&euro;{chartsData.orderChart.turnoverList[3].toLocaleString('nl-NL')}</> : chartsData.orderChart.invoiceList[3].toLocaleString('nl-NL')}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">                                
                                        <strong>{checkedWeeklyFrequency ? (chartsData.orderChart.turnoverList[3] / frequencyTurnoverSum * 100).toFixed(0) : 
                                        (chartsData.orderChart.invoiceList[3] / frequencyInvoiceSum * 100).toFixed(0)}%</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td><Chip label="Platinum" className={classes.chipPlatinum} /></td>
                                    <td>more than 1 order per week</td>
                                    <td>
                                        <Typography variant="body2">
                                        {checkedWeeklyFrequency ? <>&euro;{chartsData.orderChart.turnoverList[4].toLocaleString('nl-NL')}</> : chartsData.orderChart.invoiceList[4].toLocaleString('nl-NL')}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">                                
                                        <strong>{checkedWeeklyFrequency ? (chartsData.orderChart.turnoverList[4] / frequencyTurnoverSum * 100).toFixed(0) : 
                                        (chartsData.orderChart.invoiceList[4] / frequencyInvoiceSum * 100).toFixed(0)}%</strong>
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </>
                        : null}
                    </div>
                </Paper>
            </div>
        </div>
    )
});