import { useQuery } from 'react-query';
import axios from 'axios';

export const useFavoriteUsers = () => useQuery('favoriteUsers', async () => {
    const res = await axios.get(
        "manager/GetFavoriteUsers"
    );
    return res.data;
}, {
    staleTime: Infinity
});

export const useUsersToFavorite = () => useQuery('usersToFavorite', async () => {
    const res = await axios.get(
        "manager/GetUsersToFavorite"
    );
    return res.data;
}, {
    staleTime: Infinity
});