import React from 'react';
import { TextField, Checkbox, FormControlLabel, Typography, IconButton, Icon, Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

// companyName: '',
// mailAddresses: '',
// webshopPassword: '',
// inetCheck: false,

const WholesaleForm = ({ form, onChangeForm, onEmailAdd, onEmailChange, onEmailDelete, onPrimaryEmailDelete, isEditing, errors, t }) => {
    return (
        <div>
            <div>
                <TextField
                    id="companyName"
                    name="companyName"
                    label={t("COMPANY")}
                    value={form.companyName}
                    onChange={onChangeForm}
                    className="w-full"
                    disabled={!isEditing}
                />
                {errors.companyName ? <span>{errors.companyName}</span> : null}
            </div>
            <div>
                <TextField
                    id="webshopPassword"
                    name="webshopPassword"
                    label={t("PASSWORD") + " (max 20 characters)"}
                    value={form.webshopPassword}
                    onChange={onChangeForm}
                    className="w-full mt-16"
                    disabled={!isEditing}
                    inputProps={{
                        maxLength: 20
                    }}
                />
                {errors.webshopPassword ? <span>{errors.webshopPassword}</span> : null}
            </div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="inetCheck"
                            name="inetCheck"
                            checked={form.inetCheck}
                            onChange={onChangeForm}
                        />
                    }
                    style={{ marginLeft: 0, marginTop: 4 }}
                    label={t("WEBSHOPACCESS")}
                    labelPlacement="start"
                    disabled={!isEditing}
                />
            </div>
            <div className="mt-16">
                <Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.74)' }}>Emails</Typography>

                {form.emails.map((email, index) => {
                    return (
                        <div className="w-full flex flex-wrap mt-8" key={index}>
                            <div className="w-full flex xs:w-full sm:w-1/2 md:w-4/5 lg:w-4/5">
                                <TextField
                                    name={`email${index}`}
                                    label={`Email ${index}`}
                                    value={email}
                                    className="w-full mr-8"
                                    disabled
                                    fullWidth
                                />
                                {index > 0 && <IconButton disabled={!isEditing} size="small" color="primary" onClick={() => onPrimaryEmailDelete(index)}><Icon>clear</Icon></IconButton>}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="mt-16">
                <Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.74)' }}>Contact Emails</Typography>
                {form.contacts.filter(c => c.nieuweEmail !== 'deleted').map((contact, index) => {
                    return (
                        <div className="w-full flex flex-wrap mt-8" key={contact.rowNumber}>
                            <div className="w-full flex xs:w-full sm:w-1/2 md:w-4/5 lg:w-4/5">
                                <TextField
                                    name={`email${contact.rowNumber}`}
                                    label={`Email ${index}`}
                                    value={contact.nieuweEmail}
                                    onChange={(event) => onEmailChange(contact.rowNumber, event.target.value)}
                                    className="w-full mr-8"
                                    disabled={!isEditing}
                                    fullWidth
                                />
                            </div>
                            {isEditing ?
                                <div className="flex justify-end" style={{ width: '10%' }}>
                                    <Tooltip title={t("DELETE")}>
                                        <IconButton size="small" color="primary" onClick={() => onEmailDelete(contact.rowNumber)}>
                                            <Icon>clear</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                : null
                            }
                        </div>
                    )
                })}
                {errors.mailAddresses ? <div>
                    <span style={{ color: '#ff0000' }}>{errors.mailAddresses}</span>
                </div>
                    : null
                }
                {isEditing ?
                    <Tooltip title={t("ADD")}>
                        <IconButton variant="contained" color="primary" onClick={onEmailAdd}>
                            <Icon>add</Icon>
                        </IconButton>
                    </Tooltip>
                    : null}
            </div>
        </div>
    );
};

export default withTranslation()(WholesaleForm);