import React, { Component } from 'react';
import {withStyles} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import {FusePageSimple, FuseAnimateGroup} from '../../../../@fuse';
// import {Icon, Typography, Tooltip, TextField, Card, CardContent, CardHeader, Button, Badge, LinearProgress, Box} from '@material-ui/core';
import {Icon, IconButton, Typography, Tooltip, TextField, Card, CardContent, Button, LinearProgress, Box, MenuItem} from '@material-ui/core';
import {connect} from 'react-redux';
import * as actions from '../../../store/api/actions';

// import copyCodeToClipboard from '../../../components/Functions/CopyToClipboard';
import {filterAll} from '../../../components/Functions/FilterAll'
import Alert from '@material-ui/lab/Alert';
import { getCompany, getAzureId } from '../../Auth/Auth';

const styles = theme => ({
    layoutRoot: {},
    datePicker: {
        // minWidth: '155px',
        width: '100%',
    },
    floatRight: {
        float: 'right',
        marginLeft: '4px',
    },
    cardHeader: {
        backgroundColor: '#f6f8f8',
        borderBottom: '1px solid',
        borderColor: '#dee5e7 !important;'
    },
    successButton: {
        color: '#27c24c !important'
    },
});

class DexxCnCModule extends Component {

    state = {
        webshopurl: null,
        debnr: null,
        verkooper: null,
        verkooperpwd: null,
        startLoading: false,
        finishedLoading: false,
        percentage: 0,
        selectedUsers: [],
        favtemplates: [],
        // AzureId: null,
        userInput: ''
    }

    componentDidMount() {
        // this.handleVerkooperButtonClick('DYPAT', 'DEFAULTPASS');
        // console.log(this.props.getDexxCnCModuleVerkoopers.length);
        this.props.onGetVerkoopers(getAzureId());
        switch(getCompany()) {
            case 5:
                this.state.webshopurl = "shop.greenandbloom.co.uk";
                break;
            default:
                this.state.webshopurl = "shop.dexxdrive.fr";
                break;
        }
        if(this.props.getDexxCnCModuleVerkoopers.length > 0) {
            this.handleVerkooperButtonClick(this.props.getDexxCnCModuleVerkoopers[0].debnr, this.props.getDexxCnCModuleVerkoopers[0].password);
        }
    }

    // componentDidUpdate() {
    //     if(this.props.getDexxCnCModuleVerkoopers.length > 0) {
    //         this.handleVerkooperButtonClick(this.props.getDexxCnCModuleVerkoopers[0].debnr, this.props.getDexxCnCModuleVerkoopers[0].password);
    //     }
    // }
    
    // handleFromDate = event => {
    //     this.setState({fromdate: event.target.value})
    // }
    
    // handleToDate = event => {
    //     this.setState({todate: event.target.value})
    // }

    handleGenerateClick = () => {
        this.setState({finishedLoading: false, startLoading: true, percentage: 0});
        // this.props.onGetVerkoopers(this.state.filterCompany, this.state.fromdate, this.state.todate);
    }

    finishedLoading = () => {
        this.setState({finishedLoading: true, startLoading: false, percentage: 100});
    }

    // downloadExcel = () => {
    //     this.props.onDownloadExcel(this.props.getDexxCnCModuleVerkoopers);
    // }
    
    // applyDates = () => {
    //     // const momentFormatted = moment(this.state.startDate).format('YYYY-MM-DD');
    //     // console.log(momentFormatted);
    //     const startDate = moment(this.state.startDate).format('YYYY-MM-DD');
    //     const endDate = moment(this.state.endDate).format('YYYY-MM-DD');
    //     this.setState({fromdate: startDate, todate: endDate})
    //     // this.setState({fromdate: startDate, todate: endDate}, () => this.handleGenerateClick())
    // }

    handleVerkooperButtonClick = (debnr, password) => {
        let verkooper = debnr;
        let verkooperpwd = password;
        // switch(debnr) {
        //     case 'DYPAT':
        //         verkooper = "DYPAT";
        //         verkooperpwd = "ROBERT";
        //         break;
        //     case 'DYTRIS': 
        //         verkooper = "DYTRIS";
        //         verkooperpwd = "ROBERT";
        //         break;
        //     case 'defaultdate':
        //         verkooperpwd = "ROBERT";
        //         break;
        //     default: break;
        // }
        this.setState({verkooper, verkooperpwd}, this.applyDates);        
    }

    // setFilterCompany = (value) => {
    //     this.setState({filterCompany: value});
    // }

    onSelectUser = (selectedUser) => {
        // console.log(selectedUser);
        const users = [...this.state.selectedUsers, selectedUser];
        // this.setState({selectedUsers: users, userInput: ''});
        this.setState({selectedUsers: users, debnr: selectedUser.debnr});
        // this.props.onSave(selectedUser);
    }

    renderSuggestion = (suggestion) => {
        return (
          <MenuItem 
            key={suggestion.debnr}
            component="div"
            style={{
              fontWeight: 400,
            }}
            onClick={() => this.onSelectUser(suggestion)}
          >
            {<>
            <IconButton aria-label="add" size="small" color="secondary">
                <Icon>add_shopping_cart</Icon>
            </IconButton>
            &ensp;{suggestion.debnr}:&ensp;<strong>{suggestion.debnaam}</strong> </>}
          </MenuItem>
        );
      }

    handleInputChange = event => {
        this.setState({userInput: event.target.value}, this.filterUsersByInput);
    }

    filterUsersByInput = () => {
        const customers = this.props.customers
        .sort((a, b) => a.debnr.localeCompare(b.debnr))
        .map(c => ({
            debnr: c.debnr,
            debnaam: c.name
        }))

        const filteredUsers = filterAll(customers, this.state.userInput);
        return filteredUsers;

    }

    render() {
        const {classes, t, getDexxCnCModuleVerkoopers} = this.props;
        // console.log(" company : " + this.state.filterCompany);
        // console.log(" verkooper : " + this.state.verkooper);
        // console.log(" debnr : " + this.state.debnr);
        
        // const companylist = [
        //     // {key: 'all', value: 'Tous'},
        //     // {key: 'DYPAT', value: 'Patrice'},
        //     {key: '4', value: 'DEXX Lille'},
        //     {key: '5', value: 'DEXX Annemasse'},
        //     {key: '6', value: 'DEXX Mulhouse'},
        //     {key: '8', value: 'DEXX Lyon'},
        //     {key: '7', value: 'DEXX Valence'},
        // ]
    
        return (
            <FusePageSimple
                classes={{
                    root: classes.layoutRoot
                }}
                // header={
                //       <div className="flex flex-1 items-center justify-between p-24">
                //          <div className="flex flex-col">
                //             <div className="flex items-center mb-16">
                //                   <Icon className="text-18" color="action">home</Icon>
                //                   <Icon className="text-16" color="action">chevron_right</Icon>
                //                   <Typography color="textSecondary">API's</Typography>
                //                   <Icon className="text-16" color="action">chevron_right</Icon>
                //                   <Typography color="textSecondary">C&amp;C Module</Typography>
                //             </div>
                //             {/* <Typography variant="h6">Choose a salesman &amp; customers</Typography> */}
                //          </div>
                //       </div>
                // }
                content={
                        <div>
                            <FuseAnimateGroup
                                className="flex flex-wrap"
                                enter={{
                                    animation: "transition.slideUpBigIn"
                                }}
                            >

                            <div className="flex flex-wrap w-full p-12">
                                <div className="flex w-full p-12 md:w-1/2">
                                    <Card className="w-full">
                                        {/* <CardHeader 
                                        className={classes.cardHeader}
                                        title={
                                            <>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Choisissez le vendeur et le client
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <b>Vendeur : </b> {this.state.verkooper}<br />
                                                <b>Client : </b> {this.state.debnr}
                                            </Typography>
                                            </>
                                        } /> */}
                                        <CardContent>
                                            
                                
                                                {/* <FormControl className="w-full">
                                                    <InputLabel id="land-label">{t("COMPANY")}</InputLabel>
                                                    <Select
                                                        id="land-select"
                                                        value={this.state.filterCompany}
                                                        defaultValue="DYPAT"
                                                        onChange={(event) => this.setFilterCompany(event.target.value)}
                                                    >
                                                        {companylist.map(row => (
                                                            <MenuItem key={row.key} value={row.key}>{row.value}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl> */}
                                            <div className="flex flex-wrap w-full justify-center" elevation={1}>
                                                {/* <Button variant="contained" color="primary" className="m-6" onClick={() => this.handleVerkooperButtonClick('DYPAT')}>
                                                    {t("Patrice")}
                                                </Button>
                                                <Button variant="contained" color="primary" className="m-6" onClick={() => this.handleVerkooperButtonClick('DYTRIS')}>
                                                    {t("Tristant")}
                                                </Button> */}

                                                {/* Get all verkoopers from Florisoft */}
                                                {getDexxCnCModuleVerkoopers.map(row => (
                                                    <Button variant="contained" color="primary" className="m-6" 
                                                        key={row.debnr} value={row.debnr} 
                                                        onClick={() => this.handleVerkooperButtonClick(row.debnr, row.password)}
                                                        >
                                                            {row.debnaam}
                                                    </Button>
                                                ))}
                                            </div>
                                            <br />
                                            <div className="w-full">
                                                <TextField
                                                    className="mb-6"
                                                    name='userInput'
                                                    label={'Search a customer...'}
                                                    value={this.state.userInput}
                                                    onChange={this.handleInputChange}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>

                                            <div className="w-full">
                                                {this.filterUsersByInput().slice(0, 8).map(u => this.renderSuggestion(u))}
                                            </div>

                                            <div className="w-full p-6">
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    <b>Vendeur : </b> {this.state.verkooper}<br />
                                                    <b>Client : </b> {this.state.debnr}
                                                </Typography>
                                            </div>

                                            <div className="w-full flex flex-wrap">
                                                {/* <div className="w-full xs:w-full sm:w-1/2 md:w-full lg:w-1/2 p-6">
                                                <Button 
                                                    variant="contained" 
                                                    color="primary" 
                                                    fullWidth 
                                                    startIcon={<Icon>autorenew</Icon>}
                                                    onClick={this.handleGenerateClick}>
                                                    {t("PREVIEW")}
                                                </Button>
                                                </div> */}
                                                <div className="w-full xs:w-full sm:w-1/2 md:w-full lg:w-1/2 p-6">
                                                <Tooltip title="Mettre a jour client">
                                                    <span>
                                                    {/* <Badge badgeContent={getDexxCnCModuleVerkoopers.length} color="primary" className="w-full"> */}
                                                        <Button 
                                                            variant="contained"
                                                            color="secondary"
                                                            // className="w-full"
                                                            fullWidth
                                                            startIcon={<Icon>autorenew</Icon>}
                                                            onClick={() => this.props.ondexxCnCModuleUpdateIKLANT(this.state.debnr, this.state.verkooper)}
                                                            disabled={this.state.debnr === null || this.state.verkooper === null}
                                                            >
                                                            {1 + " : " + t("VALIDATE")}
                                                        </Button>
                                                    {/* </Badge> */}
                                                    </span>
                                                </Tooltip>
                                                </div>
                                                <div className="w-full xs:w-full sm:w-1/2 md:w-full lg:w-1/2 p-6">
                                                <Tooltip title="Ouvrir Webshop">
                                                    <Button 
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth 
                                                        startIcon={<Icon>add_shopping_cart</Icon>}
                                                        onClick={this.handleGenerateClick}
                                                        target="_blank"
                                                        href={`https://${this.state.webshopurl}/Account/LoginByUrl?user=${this.state.verkooper}&pass=${this.state.verkooperpwd}&ReturnUrl=%2F${t('WEBSHOP_LANGUAGE')}`}
                                                        disabled={this.state.verkooper === null && this.state.verkooperpwd === null}
                                                        >
                                                        {/* <Icon className={"m-10"}>autorenew</Icon>  */}
                                                        {2 + " : " + t("WEBSHOP")}
                                                    </Button>
                                                </Tooltip>
                                                </div>
                                            </div>
                                    
                                    {this.props.updatediklant === true ? 
                                    <div className="w-full pt-12"><Alert className="w-full text-center" severity="success">{t("UPDATEDSUCCESS")}</Alert></div>
                                    :
                                    null}
                    
                                    {this.props.loadinggetDexxCnCModuleVerkoopers ? <>
                                    <Box display="flex" alignItems="center">
                                        <Box width="100%" mr={1}>
                                            <LinearProgress color="primary" className="mt-12 mb-12" />
                                        </Box>
                                        {/* <Box minWidth={35}>
                                            <Typography variant="body2" color="textSecondary">{`${Math.round(0,)}%`}</Typography>
                                        </Box> */}
                                    </Box>
                                    </> : this.props.DexxCnCModuleerror}
                                        </CardContent>
                                    </Card>
                                </div>
                                {/* {getDexxCnCModuleVerkoopers.length > 0 ?  */}
                                {/* <div className="flex w-full p-12 md:w-2/3">
                                    <Card className="w-full">
                                    </Card>
                                </div> */}
                                {/* : null } */}
                            </div>
                                </FuseAnimateGroup>
                            </div>
                }
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        loadingGetDexxCnCModuleVerkoopers : state.api.loadingGetDexxCnCModuleVerkoopers,
        getDexxCnCModuleVerkoopers: state.api.getDexxCnCModuleVerkoopers,
        DexxCnCModuleerror: state.api.error,
        updatediklant: state.api.updatediklant,
        customers: state.customer.allSearchableCustomers,
        // loading: state.api.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetVerkoopers: (AzureId) => dispatch(actions.getDexxCnCModuleVerkoopers(AzureId)),
        ondexxCnCModuleUpdateIKLANT: (debnr, verkooper) => dispatch(actions.dexxCnCModuleUpdateIKLANT(debnr, verkooper))
        // onDownloadExcel: (data) => dispatch(actions.downloadExcelDexxCnCModule(data))
        // onFetchApis: () => dispatch(actions.fetchApis()),
        // onSendDailyOmk: () => dispatch(actions.sendDailyOmk())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles, {withTheme: true})(withTranslation()(DexxCnCModule)));