import React, { useEffect } from 'react';
import { FusePageSimple, FuseAnimateGroup } from '../../../../../@fuse';
import {
    Icon, Typography, Tooltip, Card, CardContent, AppBar, Toolbar, Switch, FormControlLabel, Button,
    FormControl, InputLabel, Select, MenuItem, IconButton
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DefaultFuseHeader from '../../../../components/UI/FuseHeader/DefaultFuseHeader';
import TextField from '../../../../components/UI/Inputs/TextField/TextField';
import axios from 'axios';
import SearchCustomerAutoComplete from '../../../../components/UI/Inputs/AutoCompletes/SearchCustomerAutoComplete';
import DatePicker from '../../../../components/UI/Inputs/DatePicker/DatePicker';
import SearchSalesmanAutoComplete from '../../../../components/UI/Inputs/AutoCompletes/SearchSalesmanAutoComplete';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { checkRole, getAzureId } from '../../../Auth/Auth';
import { Alert } from '@material-ui/lab';
import FavoriteUsersDialog from '../../Admin/Users/FavoriteUsersDialog';
import styled from 'styled-components';
import moment from 'moment';

const StyledIcon = styled(Icon)`
    background: #f5f5f5;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 1rem;
    padding: .55rem;
    transition: transform .6s ease-in-out;
    transform: scale(1) rotate(0deg);
    &:hover {
        transform: scale(1.2) rotate(360deg);
    }
`

const VisitsForm = (props) => {
    const isCreating = props.location.pathname.includes('create');
    const [visit, setVisit] = React.useState({
        debtorNumber: '',
        selectedDebtor: null,
        debtorEmailAddress: '',
        visitDate: moment().add(1, 'days').toDate(),
        salesmanAzureId: '',
        selectedSalesman: null,
        timeOfArrival: '',
        amountOfPeople: 1,
        arrangeLunch: false,
        arrangeStay: false,
        particularities: '',
        userAzureId: getAzureId(),
        durationOfStay: '',
        status: 0,
    });
    const [errors, setErrors] = React.useState([]);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const { t } = useTranslation();

    const queryClient = useQueryClient();
    const mutateShowroomVisit = useMutation(showroomVisit => {
        if (isCreating) {
            return axios.post('visits/CreateShowroomVisit', showroomVisit);
        } else {
            return axios.put('visits/UpdateShowroomVisit', showroomVisit);
        }
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('visits');
            setTimeout(() => {
                props.history.push('/visits');
            }, 2000);
        }
    });

    const cancelShowroomVisit = useMutation(showroomVisit => {
        return axios.put('visits/CancelShowroomVisit', showroomVisit);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('visits');
            setTimeout(() => {
                props.history.push('/visits');
            }, 2000);
        }
    });

    const { data: users, isLoading: isLoadingUsers } = useQuery('accountManagers', async () => {
        const res = await axios.get(
            "task/getAccountManagers"
        );
        return res.data;
    }, {
        staleTime: Infinity,
    })

    const { data: customers, isLoading: isLoadingCustomers } = useQuery('customersToSearch', async () => {
        const res = await axios.get(
            "customer/getallcustomerstosearch"
        );
        return res.data;
    }, {
        staleTime: 30000,
    })

    const { data: visitCustomer } = useQuery(['visitCustomer', visit.debtorNumber], async ({ queryKey }) => {
        const [_, debtorNumber] = queryKey;
        if(!debtorNumber) { return { }}
        const res = await axios.get(
            "visits/GetCustomerForVisit/" + debtorNumber
        );
        return res.data;
    })

    const isVisitsManager = checkRole('iVisitsManager');

    useEffect(() => {
        if (!isCreating) {
            axios.get('visits/getVisit/' + props.match.params.id)
                .then(res => {
                    setVisit({
                        ...res.data,
                        selectedDebtor: null,
                        selectedSalesman: null,
                        durationOfStay: res.data.durationOfStay.substring(0, 5),
                        timeOfArrival: res.data.timeOfArrival.substring(0, 5)
                    });
                })
                .catch(err => console.log(err));
        }
    }, [isCreating, props.match.params.id]);

    const handleFormChange = (event) => {
        setVisit({ ...visit, [event.target.name]: event.target.value });
    }

    const handleFormSwitchChange = (event) => {
        setVisit({ ...visit, [event.target.name]: event.target.checked });
    }

    const handleAutoCompleteChange = (selectedItem, name, visitProperty, visitValue) => {
        if (!visitValue) {
            setVisit({ ...visit, [visitProperty]: '' });
        } else {
            setVisit({ ...visit, [name]: selectedItem, [visitProperty]: visitValue });
        }
    }

    const saveForm = () => {
        const localErrors = [];
        if (!visit.visitDate) {
            localErrors.push({ property: 'visitDate', message: 'Vul een geldige bezoekdatum in' });
        }
        if (!visit.timeOfArrival || visit.timeOfArrival.length !== 5) {
            localErrors.push({ property: 'timeOfArrival', message: 'Vul een geldige tijd van aankomst in' });
        }
        if (!visit.durationOfStay || visit.durationOfStay.length !== 5) {
            localErrors.push({ property: 'durationOfStay', message: 'Vul een geldige tijd van verblijf in' });
        }
        if (!visit.debtorNumber || visit.debtorNumber.length === 0) {
            localErrors.push({ property: 'selectedDebtor', message: 'Vul een geldige klant in' });
        }
        if (!visit.salesmanAzureId || visit.salesmanAzureId.length === 0) {
            localErrors.push({ property: 'selectedSalesman', message: 'Vul een geldige verkoper in' });
        }
        if (!visit.amountOfPeople || visit.amountOfPeople === '0') {
            localErrors.push({ property: 'amountOfPeople', message: 'Vul een geldige aantal personen in' });
        }
        if(cancelShowroomVisit.isSuccess || visit.status === 3) {
            localErrors.push({ property: 'cancelShowroomVisit', message: 'Het bezoek is geannuleerd' });
        }
        setErrors(localErrors);
        if (localErrors.length === 0) {
            mutateShowroomVisit.mutate(visit);
        }
    }

    const formcolor = isCreating ? "primary" : "secondary";
    const visitEnded = moment().isAfter(moment(visit.visitDate))
    const visitEditDisabled = (!isVisitsManager && moment(visit.visitDate).diff(moment(), 'days') < 14)
    const visitCancelled = visit.status === 3 || cancelShowroomVisit.isSuccess
    const saveDisabled = moment().isAfter(moment(visit.visitDate)) || visitCancelled || visit.status === 3 || (isCreating ? (mutateShowroomVisit.isSuccess) : (!isVisitsManager && moment(visit.visitDate).diff(moment(), 'days') < 14));

    return (
        <React.Fragment>
            {dialogOpen && <FavoriteUsersDialog handleClose={() => setDialogOpen(false)} />}
            <FusePageSimple
                header={
                    <DefaultFuseHeader
                        breadCrumbs={[<Typography color="textSecondary">Visits Form</Typography>]}
                        title={`${isCreating ? 'Nieuw showroombezoek vastleggen' : `Showroombezoek van ${visit.debtorNumber} aanpassen`}`}
                        // itemsEnd={
                        //     <Tooltip title={t("EDIT") + " Favorite"}>
                        //         <Button className="successButton p-24" onClick={() => setDialogOpen(true)}>
                        //             <Icon>star</Icon>
                        //         </Button>
                        //     </Tooltip>
                        // }
                    />
                }
                content={
                    <div className="w-full p-12">
                        <FuseAnimateGroup
                            className="flex flex-wrap justify-center"
                            enter={{
                                animation: "transition.slideUpBigIn"
                            }}
                        >
                            <div className="w-full sm:w-full md:w-2/3 lg:w-2/3 p-12">
                                <Card className="w-full">
                                    <AppBar position="static" color={formcolor}>
                                        <Toolbar>
                                        <IconButton style={{color: isCreating ? '#fff' : 'rgba(0, 0, 0, 0.54)'}} onClick={props.history.goBack} aria-label="delete">
                                            <Icon>arrow_back</Icon>
                                        </IconButton>
                                            <div className="flex justify-center w-full items-center">
                                                <StyledIcon color={formcolor}>{isCreating ? "event" : "edit"}</StyledIcon>
                                                <Typography>Showroombezoek</Typography>
                                            </div>
                                        </Toolbar>
                                    </AppBar>
                                    <CardContent>
                                        <div className="p-12">

                                            {(visitEnded && !isCreating && !visitCancelled) && <Alert severity="error">Het showroombezoek is voorbij. Wijzigen is niet meer mogelijk.</Alert>}
                                            {(visitCancelled) && <Alert severity="warning">Het showroombezoek is geannuleerd. Wijzigen is niet meer mogelijk.</Alert>}
                                            {(visitEditDisabled && !visitCancelled && !isCreating) && <Alert severity="warning">Je kunt het bezoek vanaf 14 dagen van tevoren niet meer aanpassen. Vraag je manager om het bezoek te wijzigen.</Alert>}

                                            <div className="flex mb-12">
                                                <div className="min-w-48 pt-20">
                                                    <Icon color="action">event</Icon>
                                                </div>
                                                <DatePicker
                                                    fullWidth
                                                    required
                                                    margin="normal"
                                                    label="Bezoekdatum"
                                                    name="visitDate"
                                                    value={visit.visitDate}
                                                    onChange={date => handleFormChange({ target: { name: 'visitDate', value: date } })}
                                                    minDate={new Date()}
                                                    error={moment().isAfter(moment(visit.visitDate))}
                                                    helperText={moment().isAfter(moment(visit.visitDate)) ? "Vul een geldige bezoekdatum in" : null}
                                                    // helperText={errors.find(error => error.property === 'visitDate')?.message}
                                                />
                                            </div>
                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Icon color="action">schedule</Icon>
                                                </div>
                                                <TextField
                                                    value={visit.timeOfArrival}
                                                    name="timeOfArrival"
                                                    fullWidth
                                                    className="w-full mb-24"
                                                    label="Aankomsttijd"
                                                    type="time"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={handleFormChange}
                                                    required
                                                    error={errors.some(error => error.property === 'timeOfArrival')}
                                                    helperText={errors.find(error => error.property === 'timeOfArrival')?.message}
                                                />
                                            </div>
                                            <div className="flex mb-24">
                                                <div className="min-w-48 pt-20">
                                                    <Icon color="action">timelapse</Icon>
                                                </div>

                                                <FormControl style={{ width: '100%' }}>
                                                    <InputLabel>Verblijfsduur (in uren)</InputLabel>
                                                    <Select
                                                        name="durationOfStay"
                                                        required
                                                        value={visit.durationOfStay}
                                                        onChange={handleFormChange}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 48 * 4.5 + 8
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <MenuItem value="00:30">00:30</MenuItem>
                                                        <MenuItem value="01:00">01:00</MenuItem>
                                                        <MenuItem value="01:30">01:30</MenuItem>
                                                        <MenuItem value="02:00">02:00</MenuItem>
                                                        <MenuItem value="02:30">02:30</MenuItem>
                                                        <MenuItem value="03:00">03:00</MenuItem>
                                                        <MenuItem value="03:30">03:30</MenuItem>
                                                        <MenuItem value="04:00">04:00</MenuItem>
                                                        <MenuItem value="04:30">04:30</MenuItem>
                                                        <MenuItem value="05:00">05:00</MenuItem>
                                                        <MenuItem value="05:30">05:30</MenuItem>
                                                        <MenuItem value="06:00">06:00</MenuItem>
                                                        <MenuItem value="06:30">06:30</MenuItem>
                                                        <MenuItem value="07:00">07:00</MenuItem>
                                                        <MenuItem value="07:30">07:30</MenuItem>
                                                        <MenuItem value="08:00">08:00</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="flex mb-24">
                                                <div className="min-w-48 pt-20">
                                                    <Icon color="action">face</Icon>
                                                </div>
                                                {customers && <SearchCustomerAutoComplete
                                                    value={customers?.find(x => x.debnr === visit.debtorNumber) ?? null}
                                                    onChange={(event, value) => handleAutoCompleteChange(value, 'selectedDebtor', 'debtorNumber', value?.debnr)}
                                                    fullWidth
                                                    disabled={!isCreating}
                                                    placeholder={t('SEARCH') + ' ' + t('CUSTOMERS')}
                                                    label="Klant"
                                                    getOptionLabel={option => `${option.name} (${option.debnr})`}
                                                    error={errors.some(error => error.property === 'selectedDebtor')}
                                                    helperText={errors.find(error => error.property === 'selectedDebtor')?.message}
                                                />}
                                            </div>
                                            <div className="flex mb-24">
                                                <div className="min-w-48 pt-20">
                                                    <Icon color="action">mail</Icon>
                                                </div>
                                                <TextField
                                                    // label="Klant mailadres"
                                                    // name="debtorEmailAddress"
                                                    value={`${visitCustomer?.debtorNumber ?? ''}\n${visitCustomer?.debtorName ?? ''}\n${visitCustomer?.debtorEmail ?? ''}`}
                                                    multiline
                                                    rows={3}
                                                    // onChange={handleFormChange}
                                                    variant="filled"
                                                    disabled
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="flex mb-24">
                                                <div className="min-w-48 pt-20">
                                                    <Icon color="action">contact_mail</Icon>
                                                </div>
                                                <TextField
                                                    label="Afwijkend klant emailadres"
                                                    name="debtorEmailAddress"
                                                    value={visit.debtorEmailAddress}
                                                    onChange={handleFormChange}
                                                    variant="outlined"
                                                    fullWidth

                                                />
                                            </div>
                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Tooltip title={t("FILLIN") + " " + t("TITLE")}>
                                                        <Icon color="action">supervisor_account</Icon>
                                                    </Tooltip>
                                                </div>
                                                {users && users.length > 0 && (<SearchSalesmanAutoComplete
                                                    className="mb-24"
                                                    value={users?.find(x => x.azureId === visit.salesmanAzureId) ?? null}
                                                    onChange={(event, value) => handleAutoCompleteChange(value, 'selectedSalesman', 'salesmanAzureId', value?.azureId)}
                                                    fullWidth
                                                    placeholder={t('SEARCH') + ' ' + t('CUSTOMERS')}
                                                    label={t('ACCOUNTMNGR')}
                                                    getOptionLabel={option => '' + option.name}
                                                    options={users}
                                                    required
                                                    error={errors.some(error => error.property === 'selectedSalesman')}
                                                    helperText={errors.find(error => error.property === 'selectedSalesman')?.message}
                                                />)}

                                            </div>
                                            <div className="flex mb-8">
                                                <div className="min-w-48 pt-20">
                                                    <Icon color="action">groups</Icon>
                                                </div>
                                                <TextField
                                                    label="Aantal personen"
                                                    name="amountOfPeople"
                                                    value={visit.amountOfPeople}
                                                    onChange={handleFormChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    type="number"
                                                    error={errors.some(error => error.property === 'amountOfPeople')}
                                                    helperText={errors.find(error => error.property === 'amountOfPeople')?.message}
                                                />
                                            </div>
                                            <div className="flex">
                                                <div className="min-w-48 pt-20">
                                                    <Icon color="action">restaurant</Icon>
                                                </div>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={visit.arrangeLunch}
                                                            onChange={handleFormSwitchChange}
                                                            name="arrangeLunch"
                                                        />}
                                                    label="Lunch regelen"
                                                />
                                            </div>
                                            <div className="flex mb-24">
                                                <div className="min-w-48 pt-20">
                                                    <Icon color="action">hotel</Icon>
                                                </div>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={visit.arrangeStay}
                                                            onChange={handleFormSwitchChange}
                                                            name="arrangeStay"
                                                        />}
                                                    label="Overnachting regelen"
                                                />
                                            </div>
                                            <div className="flex mb-12">
                                                <div className="min-w-48 pt-20">
                                                    <Icon color="action">sms</Icon>
                                                </div>
                                                <TextField
                                                    label="Bijzonderheden"
                                                    name="particularities"
                                                    value={visit.particularities}
                                                    onChange={handleFormChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    maxRows={6}
                                                />
                                            </div>

                                            {mutateShowroomVisit.isSuccess && <Alert severity="success">Het showroombezoek is succesvol vastgelegd! Je word doorgestuurd naar het overzicht</Alert>}
                                            {cancelShowroomVisit.isSuccess && <Alert severity="warning">Het showroombezoek is succesvol geannuleerd! Je word doorgestuurd naar het overzicht</Alert>}

                                            <div className="w-full flex flex-wrap">
                                                <div className="flex w-full pt-20 justify-between">
                                                    <Button
                                                        className="flex xs:w-full"
                                                        startIcon={<Icon>arrow_back</Icon>}
                                                        variant="text"
                                                        onClick={props.history.goBack}
                                                        disabled={mutateShowroomVisit.isSuccess}
                                                    >
                                                        Terug
                                                    </Button>
                                                    {!isCreating && <Button
                                                            className="flex xs:w-full"
                                                            startIcon={<Icon>cancel</Icon>}
                                                            variant="contained"
                                                            color="default"
                                                            onClick={() => cancelShowroomVisit.mutate(visit)}
                                                            disabled={visitEditDisabled || cancelShowroomVisit.isSuccess || visit.status === 3}
                                                        >
                                                            Bezoek annuleren
                                                        </Button>
                                                    }
                                                    <Button
                                                        className="flex xs:w-full"
                                                        startIcon={<Icon>save</Icon>}
                                                        variant="contained"
                                                        color={isCreating ? "primary" : "secondary"}
                                                        onClick={saveForm}
                                                        disabled={saveDisabled}
                                                    >
                                                        {isCreating ? 'Aanmaken' : 'Opslaan'}
                                                    </Button>                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </FuseAnimateGroup>
                    </div>
                }
            />
        </React.Fragment>
    )
}


export default VisitsForm;