import React, { Component } from 'react';
import { withStyles, Icon, Typography } from '@material-ui/core';
import {connect} from 'react-redux';
import {FusePageSimple, FuseAnimateGroup} from '../../../../../@fuse';
import * as actions from '../../../../store/crm/manager/actions';
import ReactTable from "react-table";
import RouteToDebnr from '../../../../components/Functions/RouteToDebnr';
import {withTranslation} from 'react-i18next';

const styles = theme => ({

});

class Credits extends Component {
    componentDidMount() {
        this.props.onFetchCredits();
    }

    render() {
        const {credits, t} = this.props;

        return (
            <FusePageSimple
                header={
                    <div className="flex flex-1 items-center justify-between p-24">
                        <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                <Icon className="text-18" color="action">home</Icon>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">iCRM</Typography>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">Manager</Typography>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">{t("CREDIT")}</Typography>
                            </div>
                            <Typography variant="h6">{t("CREDIT")}</Typography>
                        </div>
                    </div>
                }
                content={
                    <FuseAnimateGroup animation="transition.slideUpIn" delay={300}>
                        <div className="flex flex-wrap w-full">
                            <ReactTable
                                className="w-full -striped -highlight border-0"
                                data={credits}
                                columns={[
                                    {
                                        Header    : "Debnr",
                                        accessor  : "debnr",
                                        filterable: true,
                                        className : "font-bold justify-center",
                                        width     : 120,
                                        Cell: row => (<RouteToDebnr row={row} />)
                                    },
                                    {
                                        Header    : "Name",
                                        accessor  : "name",
                                        filterable: true,
                                        className : "font-bold",
                                    },
                                    {
                                        Header    : t("COUNTINVOICES"),
                                        accessor  : "amountOfInvoices",
                                        className: "justify-center",
                                    },
                                    {
                                        Header    : t("TURNOVER"),
                                        accessor  : "nettoTotal",
                                        className: "justify-center",
                                        width: 130,
                                        Cell: row => '€ ' + row.value,
                                    },
                                    {
                                        Header    : t("CREDIT"),
                                        accessor  : "totalCredits",
                                        className : "justify-center",
                                        width: 120,
                                        Cell: row => '€ ' + row.value,
                                    },
                                    {
                                        Header    : t("CREDIT") + ' %',
                                        className : "justify-center",
                                        width: 100,
                                        Cell: row => (
                                            <>{(row.original.totalCredits / row.original.nettoTotal * 100).toFixed(2) + ' %'}</>
                                        )
                                    },
                                    {
                                        Header    : "Pack",
                                        accessor  : "branche",
                                        filterable: true,
                                        className: "justify-center",
                                    },
                                ]}
                                pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                                defaultPageSize={20}
                                noDataText="No customers found"
                            />
                        </div>
                    </FuseAnimateGroup>
                }
            />
            );

    }
}

const mapStateToProps = state => {
    return {
        credits: state.manager.credits,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchCredits: () => dispatch(actions.fetchCredits()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation()(Credits)));