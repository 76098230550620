import React, { useState } from 'react';
import {useTranslation} from 'react-i18next';
import { Card, FormControlLabel, Switch } from '@material-ui/core';
import { LinkToDebnrWithChildren } from '../../../../../components/Functions/RouteToDebnr';
import { getCompany } from '../../../../Auth/Auth';

const ClickableRow = ({row}) => {
    return (
        <tr>
                <td><LinkToDebnrWithChildren debnr={row.debnr}>{row.debnr}</LinkToDebnrWithChildren></td>
                <td><LinkToDebnrWithChildren debnr={row.debnr}>{row.name}</LinkToDebnrWithChildren></td>
                <td width='100px' className="text-right"><LinkToDebnrWithChildren debnr={row.debnr}>&euro;{row.turnover.toLocaleString('nl-NL')}</LinkToDebnrWithChildren></td>
        </tr>
    )
}

const DashboardDecoTables = props => {
    const {t} = useTranslation();
    const isHbxDeco = getCompany() === 4;
    const tables = props.tables;
    const hbxDecoTables = props.hbxDecoTables;
    const loaded =  hbxDecoTables && Object.keys(tables).length > 0 && props.creditCustomers;
    const [toggled, setToggled] = useState(true);
    const [showCreditValue, setShowCreditValue] = useState(false);
    
    const toggledData = tables ? (toggled ? tables.turnoverTable : tables.reservationTable) : null;

    const handleCreditToggle = (toggled) => {
        props.handleCreditToggleChange(toggled);
        setShowCreditValue(toggled);
    }

    const firstTable = loaded && (
        <div key="firstTable" className="sm:w-full md:w-1/2 lg:w-1/2 p-12">
            <Card className="rounded-8 shadow-none border-1">
                {/* <Table data={tables.turnoverTable} title={`Top 10 ${t("TURNOVER")}`} /> */}
                <table className={"simple clickable"}>
                    <thead>
                        <tr>
                            <th colSpan={3} height={65}>
                                <div className="flex items-center justify-between">
                                    Top 10 {toggled ? (isHbxDeco ? 'Gerealiseerde omzet' : t("TURNOVER")): "Geschreven orders"}
                                    <div className="float-right">
                                        <FormControlLabel
                                        control={
                                            <Switch
                                            checked={toggled}
                                            onChange={() => setToggled(!toggled)}
                                            color="primary"
                                            />
                                        }
                                        // label={"Top 10 " + toggled ? t("TURNOVER") : "Reservation"}
                                        />
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th style={{fontWeight: 'bold'}} height={40}>Debnr</th>
                            <th style={{fontWeight: 'bold'}} height={40}>{t('NAME')}</th>
                            <th style={{fontWeight: 'bold', textAlign: 'right'}} height={40}>{toggled ? t("TURNOVER") : "Geschreven orders"}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {toggledData.map((row, index) => (
                        <ClickableRow key={index} row={row} />
                        // <tr key={index}>
                        //     <td><LinkToDebnr debnr={row.debnr} /></td>
                        //     <td>{row.name}</td>
                        //     <td>{row.turnover.toLocaleString('nl-NL')}</td>
                        // </tr>
                    ))}
                    </tbody>
                </table>
            </Card>
        </div>
    );

    const secondTable = (
        loaded && 
        <div key="secondTable" className="sm:w-full md:w-1/2 lg:w-1/2 p-12">
            <Card className="rounded-8 shadow-none border-1">
                <table className={"simple clickable"}>
                    <thead>
                        <tr>
                            <th colSpan={3} style={{textAlign: 'center'}} height={65}>Top 10 Artikelen</th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th style={{fontWeight: 'bold'}} height={40}>Artikel</th>
                            <th style={{fontWeight: 'bold', textAlign: 'right'}} height={40}>Omzet</th>
                        </tr>
                    </thead>
                    <tbody>
                    {hbxDecoTables.articleTable.map((row) => (
                        <tr key={row.artnr}>
                            {/* <td>{row.artnr}</td> */}
                            <td>{row.artTxt}</td>
                            <td width='100px' className="text-right">&euro;{row.turnover.toLocaleString('nl-NL')}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Card>
        </div>
    ) 

    const thirdTable = (
        loaded && 
        <div key="thirdTable" className="sm:w-full md:w-1/2 lg:w-1/2 p-12">
            <Card className="rounded-8 shadow-none border-1">
                <table className={"simple clickable"}>
                    <thead>
                        <tr>
                            <th colSpan={3} style={{textAlign: 'center'}} height={65}>Top 10 Artikelgroepen</th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th style={{fontWeight: 'bold'}} height={40}>Artikelgroep</th>
                            <th style={{fontWeight: 'bold'}} height={40}>Artikel {t('NAME')}</th>
                            <th style={{fontWeight: 'bold', textAlign: 'right'}} height={40}>{t("TURNOVER")}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {hbxDecoTables.articleGroupTable.map((row, index) => (
                        <tr key={index}>                                        
                            <td>{row.artGroup}</td>
                            <td>{row.artGroupTxt}</td>
                            <td width='100px' className="text-right">&euro;{row.turnover.toLocaleString('nl-NL')}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Card>
        </div>
    ) 

    const unpaidTable = (
        loaded && 
        <div key="unpaidTable" className="sm:w-full md:w-1/2 lg:w-1/2 p-12">
            <Card className="rounded-8 shadow-none border-1">
                <table className={"simple clickable"}>
                    <thead>
                        <tr>
                            <th colSpan={3} style={{textAlign: 'center'}} height={65}>
                                <div className="flex items-center justify-between">
                                    Top 10 Creditklanten
                                    <div className="float-right">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={showCreditValue}
                                                    onChange={() => handleCreditToggle(!showCreditValue)}
                                                    color="primary"
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th style={{fontWeight: 'bold'}} height={40}>Debnr</th>
                            <th style={{fontWeight: 'bold'}} height={40}>{t('NAME')}</th>
                            <th style={{fontWeight: 'bold', textAlign: 'right'}} height={40}>{showCreditValue ? 'Totaal creditbedrag' : 'Aantal credits'}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {props.creditCustomers.sort((a, b) => {
                        if(showCreditValue) {
                            return a.creditValue - b.creditValue;
                        } else {
                            return b.amountOfCredits - a.amountOfCredits;
                        }
                    }).map((row, index) => (
                        <tr key={index}>
                            <td><LinkToDebnrWithChildren debnr={row.debnr}>{row.debnr}</LinkToDebnrWithChildren></td>
                            <td><LinkToDebnrWithChildren debnr={row.debnr}>{row.name}</LinkToDebnrWithChildren></td>
                            <td width='100px' className="text-right">
                                <LinkToDebnrWithChildren debnr={row.debnr}>
                                    {showCreditValue ? '€' + row.creditValue.toLocaleString('nl-NL') : row.amountOfCredits}
                                </LinkToDebnrWithChildren>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Card>
        </div>
    ) 

    return (
        <div className="flex flex-wrap">
            {
                loaded &&
                [firstTable, secondTable, thirdTable, unpaidTable]
            }
        </div>
    )
};

export default DashboardDecoTables;