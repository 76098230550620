import React from 'react';

const LeftSideLayout1 = () => {
    return (
        <React.Fragment>

        </React.Fragment>
    );
};

export default LeftSideLayout1;
