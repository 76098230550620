import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Icon, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

class TaskDetailHeader extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.title !== this.props.title;
    }


    render() {
        const { t } = this.props;
        return (
            <div className="flex flex-1 items-center justify-between p-24">
                <div className="flex flex-col">
                    <div className="flex items-center mb-16">
                            <Icon className="text-18" color="action">home</Icon>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">iCRM</Typography>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Link
                                to={`/tasks`}
                            >
                                <Typography color="textSecondary" className="underline">{t("TASKS")}</Typography>
                            </Link>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">{this.props.title}</Typography>
                    </div>
                    <Typography variant="h6">{this.props.debnaam}</Typography>
                    {this.props.phoneNumber && this.props.phoneNumber !== '' &&
                        <a style={{color: '#fff', textDecoration: 'underline'}} href={'tel:' + this.props.phoneNumber}><Icon style={{fontSize: '1.5rem', lineHeight: '1.2'}}>phone</Icon>{this.props.phoneNumber}</a>
                    }
                </div>
            </div>
        );
    }
}

export default (withTranslation()(TaskDetailHeader));