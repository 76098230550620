import CallList from './Salesman/CallList/CallList';
import Inbox from './Salesman/Inbox/Inbox';
import Overview from './Salesman/Overview/Overview';
import Customer from './Salesman/Customer/Customer';
import Weblog from './Salesman/Weblog/Weblog';
import Turnover from './Salesman/Turnover/Turnover';
import Unpaid from './Salesman/Unpaid/Unpaid';
import Customers from './Salesman/Customers/Customers';
import Dashboard from './Salesman/Dashboard/Dashboard';
import NewCustomers from './Salesman/NewCustomers/NewCustomers';
import NewLeads from './Salesman/NewLeads/NewLeads';
import Credits from './Manager/Credits/Credits';
import ManageUserAccess from './Admin/Users/ManageUserAccess/ManageUserAccess';
import Users from './Admin/Users/Users';
import Prelevement from './Manager/Prelevement/Prelevement';
import AssignNewLeads from './Manager/AssignNewLeads/AssignNewLeads';
import ExportNewLeads from './Manager/ExportNewLeads/ExportNewLeads';
import OverviewNewLeads from './Manager/OverviewNewLeads/OverviewNewLeads';
import OpenPosten from './Salesman/OpenPosten/OpenPosten';
import WholesaleCustomer from './Wholesale/WholesaleCustomer/WholesaleCustomer';
import WholesaleCustomers from './Wholesale/WholesaleCustomers/WholesaleCustomers';
import CCDashboard from './C&C/CCDashboard';
import Tasks from './Tasks/Tasks';
import TaskDetail from './Tasks/TaskDetail/TaskDetail';
import TaskDetailCreate from './Tasks/TaskDetail/TaskDetailCreate';
import TasksOverview from './Manager/TasksOverview/TasksOverview';
import TaskArchive from './Manager/TasksOverview/TaskArchive';
import UserStats from '../Monitor/UserStats/UserStats';
import VerzendBevestigingen from './Salesman/VerzendBevestigingen/VerzendBevestigingen';
import CommonOverview from './Salesman/Overview/CommonOverview';
import OrderTaskDetail from './Tasks/TaskDetail/OrderTaskDetail';
import LoginHistory from './Salesman/Profile/LoginHistory';
import VisitsForm from './Visits/VisitsForm/VisitsForm';
import VisitsOverview from './Visits/VisitsOverview';
import iCRMPremium from '../iCRMPremium/iCRMPremium';

export const iCRMConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/icrm/inbox',
            component: Inbox,
            auth: ['iCRMBasis']
        },
        {
            path: '/icrm/dashboard',
            component: Dashboard,
            auth: ['iCRMBasis']
        },
        {
            path: '/icrm/call',
            component: CallList,
            auth: ['iCRMBasis']
        },
        {
            path: '/icrm/overview',
            component: CommonOverview,
            auth: ['iCRMBasis']
        },
        {
            path: '/icrm/weblog',
            component: Weblog,
            auth: ['iCRMBasis']
        },
        {
            path: '/icrm/customer/:debnr',
            component: Customer,
            auth: ['iCRMBasis', 'iCRMFinancieel']
        },
        {
            path: '/icrm/turnover',
            component: Turnover,
            auth: ['iCRMBasis']
        },
        {
            path: '/icrm/unpaid',
            component: Unpaid,
            auth: ['iCRMBasis', 'iCRMFinancieel']
        },
        {
            path: '/icrm/customers',
            component: Customers,
            auth: ['iCRMBasis']
        },
        {
            path: '/icrm/newcustomers',
            component: NewCustomers,
            auth: ['iCRMBasis']
        },
        {
            path: '/icrm/newleads',
            component: NewLeads,
            auth: ['iCRMBasis']
        },
        {
            path: '/profile',
            component: UserStats,
            exact: true,
            auth: ['iCRMBasis', 'iCRMFinancieel']
        },
        {
            path: '/icrm/openposten',
            component: OpenPosten,
            exact: true,
            auth: ['iCRMBasis', 'iCRMFinancieel']
        },
        {
            path: '/tasks',
            component: Tasks,
            exact: true,
            auth: ['iCRMBasis', 'iTasks']
        },
        {
            path: '/verzendbevestigingen',
            component: VerzendBevestigingen,
            exact: true,
            auth: ['iCRMBasis']
        },
        {
            path: '/profile/loginhistory',
            component: LoginHistory,
            exact: true,
            auth: ['iCRMBasis', 'iCRMFinancieel']
        },
        // MANAGERS
        {
            path: '/icrm/credits',
            component: Credits,
            auth: ['iCRMManager']
        },
        {
            path: '/icrm/prelevement',
            component: Prelevement,
            auth: ['iCRMManager']
        },
        {
            path: '/icrm/assignNewLeads',
            component: AssignNewLeads,
            auth: ['iCRMManager']
        },
        {
            path: '/icrm/exportNewLeads',
            component: ExportNewLeads,
            auth: ['iCRMManager']
        },
        {
            path: '/icrm/overviewNewLeads',
            component: OverviewNewLeads,
            auth: ['iCRMManager']
        },
        {
            path: '/icrm/tasksoverview',
            component: TasksOverview,
            auth: ['iCRMManager', 'iTasksManager']
        },
        {
            path: '/icrm/taskarchive',
            component: TaskArchive,
            auth: ['iCRMManager', 'iTasksManager']
        },
        // ADMIN
        {
            path: '/icrm/users',
            component: Users,
            exact: true,
            auth: ['iCRMAdmin']
        },
        {
            path: '/icrm/useraccess/:id',
            component: ManageUserAccess,
            exact: true,
            auth: ['iCRMAdmin']
        },
        {
            path: '/task/new',
            component: TaskDetailCreate,
            exact: true,
            auth: ['iTasks']
        },
        {
            path: '/task/:id',
            component: TaskDetail,
            exact: true,
            auth: ['iTasks']
        },
        {
            path: '/ordertask/:id',
            component: OrderTaskDetail,
            exact: true,
            auth: ['iTasks']
        },
        {
            path: '/visits/',
            component: VisitsOverview,
            auth: ['iVisits']
        },
        {
            path: '/visit/create',
            component: VisitsForm,
            exact: true,
            auth: ['iVisits']
        },
        {
            path: '/visit/:id',
            component: VisitsForm,
            exact: true,
            auth: ['iVisits']
        },
        // WHOLESALE
        {
            path: '/wholesale/customer/:debnr',
            component: WholesaleCustomer,
            exact: true,
            auth: ['iCRMWholesale']
        },
        {
            path: '/wholesale/Customers',
            component: WholesaleCustomers,
            exact: true,
            auth: ['iCRMWholesale']
        },
        // CCDashboard
        {
            path: '/CC/CCDashboard',
            component: CCDashboard,
            exact: true,
            auth: ['DexxCnCModule']
        },
        {
            path: '/customeridentities',
            component: iCRMPremium,
            exact: true,
            auth: ['iCRMBasis']
        },
    ]
};