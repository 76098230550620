import React, {Component} from 'react';
import {Button, Icon, ListItemText, Popover, MenuItem} from '@material-ui/core';
import {getLanguage} from '../../../main/Auth/Auth';
import { withTranslation } from "react-i18next";
import { updateUserLanguage } from '../../../store/crm/admin/actions'
import { connect } from 'react-redux';

const languages = [
    {
        lng: 'en',
        language: 'English'
    },
    {
        lng: 'fr',
        language: 'Français'
    },
    {
        lng: 'nl',
        language: 'Nederlands'
    },
    {
        lng: 'de',
        language: 'Deutsch'
    },
];

class LanguageMenu extends Component {

    state = {
        languageMenu: null
    };

    languageMenuClick = event => {
        this.setState({languageMenu: event.currentTarget});
    };

    languageMenuClose = () => {
        this.setState({languageMenu: null});
    };

    changeLanguage = (language) => {
        localStorage.setItem('i18n', language);
        this.props.i18n.changeLanguage(language);
        this.props.onUpdateUserLanguage(this.props.user.azureId, language);
        this.languageMenuClose();
    }

    getCurrentLanguage = () => {
        let currentLang = languages.find(l => l.lng === getLanguage());
        if(currentLang) {
            return currentLang.language;
        } else {
            localStorage.setItem('i18n', 'en');
            return 'English';
        }
    }

    render()
    {
        const {languageMenu} = this.state;

        return (
            <React.Fragment>
                    <Button className="h-64" onClick={this.languageMenuClick}>

                        {/* <div className="hidden md:flex flex-col ml-12 items-start">
                            <Typography component="span" className="normal-case font-600 flex">
                                {this.getCurrentLanguage()}
                            </Typography>
                        </div> */}

                        <Icon className="text-24 sm:flex md:flex lg:flex" variant="action" color="action">language</Icon>
                    </Button>

                <Popover
                    open={Boolean(languageMenu)}
                    anchorEl={languageMenu}
                    onClose={this.languageMenuClose}
                    anchorOrigin={{
                        vertical  : 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical  : 'top',
                        horizontal: 'center'
                    }}
                    classes={{
                        paper: "py-8"
                    }}
                >
                    <React.Fragment>
                        {languages.map(l => (
                            <MenuItem key={l.lng} onClick={() => this.changeLanguage(l.lng)}>
                                <ListItemText className="pl-0" primary={l.language} />
                            </MenuItem>
                        ))}
                    </React.Fragment>
                </Popover>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({flortalAuth}) => ({
    user: flortalAuth.user
})

const mapDispatchToProps = (dispatch) => ({
    onUpdateUserLanguage: (azureId, language) => dispatch(updateUserLanguage(azureId, language))
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LanguageMenu));
