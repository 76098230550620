import React, { useState } from 'react';
import DonutChart, { donutData } from '../../../../../components/Widgets/DonutChart';
import { Typography, Paper, withStyles, Icon, Tooltip, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BarLineChart from '../../../../../components/Widgets/BarLineChart';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';


export default withStyles(null, { withTheme: true })(props => {
    const { t } = useTranslation();
    const [showCreditLegend, setShowCreditLegend] = useState(false);
    const { turnoverDistribution, orderTurnover, verdelingen, loading } = props;

    let categoryDonut = null;
    if (turnoverDistribution && turnoverDistribution.length > 0) {
        categoryDonut = _.cloneDeep(donutData);
        const labels = [];
        const dataset = [];
        turnoverDistribution.map(t => labels.push(t.name) && dataset.push(t.value.toFixed(0)));

        categoryDonut['mainChart'].datasets['TW'][0].data = dataset;
        categoryDonut['mainChart'].labels = labels;
        categoryDonut.title = 'Turnover Distribution';
    }

    let redenVerdelingDonut = _.cloneDeep(donutData);
    if (verdelingen && verdelingen.length > 0) {
        const labels = [];
        const dataset = [];
        verdelingen.forEach(t => labels.push(t.reden) && dataset.push(-t.creditBedrag));

        redenVerdelingDonut['mainChart'].datasets['TW'][0].data = dataset;
        redenVerdelingDonut['mainChart'].labels = labels;
        // redenVerdelingDonut['mainChart'].options.respons = 'right';
        redenVerdelingDonut['mainChart'].options.legend.display = showCreditLegend;
        redenVerdelingDonut.title = 'Creditreden verdeling';
    }

    const isLoaded = orderTurnover && Object.keys(orderTurnover).length > 0;
    console.log(orderTurnover);
    const widthClass = props.isHbxDeco ? "md:w-1/2" : "md:w-1/3";

    return (
        <div className="flex flex-wrap">
            <div className={`widget flex w-full sm:w-full ${widthClass} p-12`}>
                <Paper className="w-full rounded-8 shadow-none border-1">
                    <React.Fragment>
                        <div className="flex items-center justify-between px-16 h-64 border-b-1">
                            <Typography className="text-16">{t("TURNOVERPERCATEGORIES")}</Typography>
                            {/* <Tooltip title="This chart doesn't change after changing dates">
                                            <Icon>cloud_off</Icon>
                                        </Tooltip> */}
                        </div>
                        <div className="h-400 w-full p-24">
                            {!loading && categoryDonut ?
                                <DonutChart data={categoryDonut} />
                                :
                                <>
                                    <Skeleton className="rounded m-auto" variant="rect" width={'60%'} height={250} />
                                    <Skeleton className="mt-12 m-auto" variant="text" width={240} />
                                </>
                            }
                        </div>
                    </React.Fragment>
                </Paper>
            </div>
            <div className={`widget flex w-full sm:w-full ${widthClass} p-12`}>
                <Paper className="w-full rounded-8 shadow-none border-1">
                    <div className="flex items-center justify-between px-16 h-64 border-b-1">
                        <Typography className="text-16">{t('CREDITVERDELING')}</Typography>
                        {/* <Tooltip title="This chart doesn't change after changing dates">
                                <Icon>cloud_off</Icon>
                            </Tooltip> */}
                    </div>
                    <div className={(showCreditLegend ? "h-640" : "h-400") + " w-full p-24 text-center"}>
                        {verdelingen ?
                            <>
                                {
                                    verdelingen.length > 0 ?
                                        <DonutChart data={redenVerdelingDonut} />
                                        :
                                        'No data found'
                                }
                                {showCreditLegend ?
                                    <Button color="primary" onClick={() => setShowCreditLegend(false)}>{t('HIDELEGEND')}</Button> :
                                    <Button color="primary" onClick={() => setShowCreditLegend(true)}>{t('SHOWLEGEND')}</Button>
                                }
                            </>
                            :
                            <>
                                <Skeleton className="rounded m-auto" variant="rect" width={'60%'} height={250} />
                                <Skeleton className="mt-12 m-auto" variant="text" width={240} />
                            </>
                        }
                    </div>
                </Paper>
            </div>
            <div className={`widget flex w-full sm:w-full ${widthClass} p-12`}>
                <Paper className="w-full rounded-8 shadow-none border-1">
                    <div className="flex items-center justify-between px-16 h-64 border-b-1">
                        <Typography className="text-16">{t("TURNOVER")} &amp; {t("ORDERS")}</Typography>
                        <Tooltip title="This chart doesn't change after changing dates">
                            <Icon>cloud_off</Icon>
                        </Tooltip>
                    </div>
                    <div className="h-400 w-full p-32">
                        {isLoaded ?
                            <BarLineChart {...orderTurnover} /> :
                            <>
                                <Skeleton className="rounded m-auto" variant="rect" width={'60%'} height={250} />
                                <Skeleton className="mt-12 m-auto" variant="text" width={240} />
                            </>
                        }
                    </div>
                    <div className="flex items-center p-8 border-t-1">
                        <div className="flex flex-1 flex-col items-center justify-center p-16 border-r-1">
                            {isLoaded ?
                                <>
                                    <Typography className="text-32 leading-none">
                                        {orderTurnover.invoices.reduce((a, b) => a + b, 0).toLocaleString()}
                                    </Typography>
                                    <Typography className="text-15" color="textSecondary">
                                        {t("ORDERS")}
                                    </Typography>
                                </> :
                                <>
                                    <Skeleton variant="text" width={'40%'} height={45} style={{ margin: '0 auto' }} />
                                    <Skeleton variant="text" width={'70%'} height={25} style={{ margin: '0 auto' }} />
                                </>
                            }
                        </div>
                        <div className="flex flex-1 flex-col items-center justify-center p-16">
                            {isLoaded ?
                                <>
                                    <Typography className="text-32 leading-none">
                                        &euro; {orderTurnover.totals.reduce((a, b) => a + b, 0).toLocaleString()}
                                    </Typography>
                                    <Typography className="text-15" color="textSecondary">
                                        {t("TURNOVER")}
                                    </Typography>
                                </> :
                                <>
                                    <Skeleton variant="text" width={'40%'} height={45} style={{ margin: '0 auto' }} />
                                    <Skeleton variant="text" width={'70%'} height={25} style={{ margin: '0 auto' }} />
                                </>
                            }
                        </div>
                    </div>
                </Paper>
            </div>
        </div>
    )
});