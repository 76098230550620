import * as actionTypes from './actionTypes';
import axios from 'axios'

export const fetchLoginHistorySuccess = (weblog) => {
    return {
        type: actionTypes.FETCH_LOGIN_HISTORY_SUCCESS,
        weblog: weblog
    };
};
export const fetchLoginHistoryStart = () => {
    return {
        type: actionTypes.FETCH_LOGIN_HISTORY_START,
    };
};
export const fetchLoginHistory = () => {
    return dispatch => {
        dispatch(fetchLoginHistoryStart());
        axios.get('profile/GetLoginHistory')
        .then(res => {
            dispatch(fetchLoginHistorySuccess(res.data));
        })
    };
};
