import React, { useState } from 'react';
import {FusePageSimple, FuseAnimateGroup} from '../../../../../@fuse';
import {Icon, Typography, withStyles, TextField, Button, Radio, Tooltip} from '@material-ui/core';
import Skeleton from './OpenPostenSkeleton';
import OpenPostenTable from './OpenPostenTable';
import {withTranslation} from 'react-i18next';
import {red, orange, grey} from '@material-ui/core/colors';
import { getCompany, getUserEmail } from '../../../Auth/Auth';
import useDefaultQuery, { downloadBlob } from '../../../../../api/useDefaultQuery';
import { useMutation } from 'react-query';
import axios from 'axios';
import moment from 'moment';

const styles = theme => ({
    td: {
        padding: '4px 12px 4px 12px'
    },
    chip: {
        cursor: 'default !important',
    },
    textField: {
      margin: '1rem',
    },
})

const DefaultRadio = withStyles({
    root: {
        color: grey[800],
        '&checked': {
            color: grey[900],
        },
    },
    checked: {},
  })(props => <Radio color="default" {...props} />);

const OrangeRadio = withStyles({
    root: {
        color: orange['A400'],
        '&:checked': {
            color: orange['A700'],
        },
    },
    checked: {},
  })(props => <Radio color="default" {...props} />);

const RedRadio = withStyles({
    root: {
        color: red['A700'],
        '&:checked': {
            color: orange[900],
        },
    },
    checked: {},
  })(props => <Radio color="default" {...props} />);

const OpenPosten = props => {    
    const {classes, t} = props;
    const openPostenQuery = useDefaultQuery('openposten', 'admin/getOpenPosten');
    const sendOpenPost = useMutation((data) => {
        return axios.post('admin/SendOpenPost', data,  {headers: {email: getUserEmail()}});       
    });
    const [options, setOptions] = useState({
        debnr: '',
        name: '', 
        global: '',
        selectedRadio: 'default',
        amountOfCardsToShow: 20,
        payterm: '',
    })
    const [amountOfCards, setAmountOfCards] = useState(20);

    const handleLoadAll = () => {
        setAmountOfCards(openPostenQuery.data.length);
    }

    const handleHeaderInputChange = name => event => {
        setOptions({
            ...options,
            [name]: event.target.value
        });
    }

    const handleSearchFilter = (openPosten, value) => {
        const filteredOpenPosten = openPosten.filter(openPost => 
            openPost.debnr.toLowerCase().includes(options.debnr.toLowerCase()) &&
            openPost.name.toLowerCase().includes(options.name.toLowerCase()) && 
            Object.keys(openPost).some(k => k !== 'invoices' && openPost[k] && openPost[k].toString().toLowerCase().includes(options.global.toLowerCase()))
        );
        return filteredOpenPosten;
    }

    const handleRadioChange = event => {
        const radio = event.target.value;
        setOptions({
            ...options,
            selectedRadio: radio
        });
    };

    const handleRadioFilter = (openPosten, radio) => {
        const filteredOpenPosten = [];
        if(radio !== 'default') {
            const fnToExecute = getFunctionForRadioFilter(radio);
            for(let openPost of openPosten) {
                let inserted = false;
                for(let invoice of openPost.invoices) {
                    if(!inserted && fnToExecute(invoice.days, openPost.betaalTermijn)) {
                        inserted = true;
                        filteredOpenPosten.push(openPost);
                    }
                }
            }
        } else {
            return openPosten;
        }
        return filteredOpenPosten;
    }

    const getFunctionForRadioFilter = value => {
        let fnToExecute = null;
        switch(value) {
            case 'orange': {
                fnToExecute = (days, betaalTermijn) => (days - betaalTermijn) >= 0 && (days - betaalTermijn) <= 3;
                break;
            }
            case 'red': {
                fnToExecute = (days, betaalTermijn) => (days - betaalTermijn) > 3;
                break;
            }
            default: 
                fnToExecute = (days, betaalTermijn) => true;
                break;
        }
        return fnToExecute
    }

    const getOpenPostenFilteredData = () => {
        let filteredOpenPosten = handleRadioFilter(openPostenQuery.data, options.selectedRadio);
        filteredOpenPosten = handleSearchFilter(filteredOpenPosten);
        return filteredOpenPosten
    }

    const printOpenPost = (debnr, debnaam, betaalTermijn, payterm) => {
        var printContents = document.getElementById(debnr).innerHTML; 
        let popupWin = null;
        if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
            popupWin = window.open('', '_blank', 'width=600,height=800,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
            popupWin.window.focus();
            popupWin.document.write(`<!DOCTYPE html><html><head>
            <link rel='stylesheet' type='text/css' href='https://fonts.googleapis.com/css?family=Muli' />
            <style>
            body, p, h1  {
                font-size: 14px;
                line-height: 1.4;
                font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif;
            }
            
            table.simple {
                width: 100%;
                border: none;
                border-spacing: 0;
                text-align: left;
            }
            
            table.simple thead tr th {
                padding: 12px 8px;
                font-weight: 500;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                white-space: nowrap;
            }
            
            table.simple thead tr th:first-child {
                padding-left: 12px;
            }
            
            table.simple thead tr th:last-child {
                padding-right: 12px;
            }
            
            table.simple tbody tr td {
                padding: 12px 8px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            }
            
            table.simple tbody tr td:first-child {
                padding-left: 12px;
            }
            
            table.simple tbody tr td:last-child {
                padding-right: 12px;
            }
            
            table.simple tbody tr:last-child td {
                border-bottom: none;
            }
            
            table.simple.clickable tbody tr {
                cursor: normal;
            }
            
            table.simple.clickable tbody tr:hover {
                background: rgba(0, 0, 0, 0.03);
            }
            .OpenPostenTable-red-572 .MuiChip-label-670 {
                color: #fff;
                background-color: #d50000;
                border-radius: 16px;
                padding-left: 12px;
                padding-right: 12px;
                padding-top: 8px;
                padding-bottom: 8px;
            }
            .OpenPostenTable-orange-573 .MuiChip-label-670 {
                color: #fff;
                background-color: #ff9100;
                border-radius: 16px;
                padding-left: 12px;
                padding-right: 12px;
                padding-top: 8px;
                padding-bottom: 8px;
            }
            
            </style>
                </head><body onload="window.print()">
                <div class="reward-body"><h3>${debnr} - ${debnaam}</h3>
                <p>${payterm} : ${betaalTermijn}</p>
                ${printContents}
                </div></body></html>`);
            popupWin.onbeforeunload = function (event) {
                popupWin.close();
                return '.\n';
            };
            popupWin.onabort = function (event) {
                popupWin.document.close();
                popupWin.close();
            }
        } else {
            popupWin = window.open('', '_blank', 'width=800,height=800');
            popupWin.document.open();
            popupWin.document.write(`<html><head></head><body onload="window.print()"><h3>${debnr} - ${debnaam}</h3><p>${payterm} : ${betaalTermijn}</p>${printContents}</body></html>`);
            popupWin.document.close();
        }
        popupWin.document.close();

        return true;
    }
    
    let openPostenData = [];
    if(!openPostenQuery.isLoading) {
        openPostenData = getOpenPostenFilteredData();
    }
    return (
        <FusePageSimple 
        header={
            <div className="flex flex-1 items-center justify-between p-24">
                <div className="flex flex-col">
                    <div className="flex items-center mb-16">
                        <Icon className="text-18" color="action">home</Icon>
                        <Icon className="text-16" color="action">chevron_right</Icon>
                        <Typography color="textSecondary">iCRM</Typography>
                        <Icon className="text-16" color="action">chevron_right</Icon>
                        <Typography color="textSecondary">{t('UNPAIDINVOICES')}</Typography>
                    </div>
                    <Typography variant="h6">{t('UNPAIDINVOICES')}</Typography>
                </div>
                {
                    getCompany() === 5 && <div className="flex flex-1 items-center justify-end px-12">
                        <Button variant="outlined" color="secondary" onClick={() => downloadBlob('GetGreenAndBloomSaldoOverzicht', `${moment(new Date()).format('YYYY-MM-DD')}-outstanding-items.xlsx`)}>
                            <Icon>cloud_download</Icon>
                            <Typography className="ml-12">Outstanding items</Typography>
                        </Button>
                    </div>
                }
            </div>
        }
        content={
            <>
            <FuseAnimateGroup animation="transition.slideUpIn" delay={300}>
                <div className="flex flex-wrap w-full bg-white border-b-1" elevation={1}>
                    <div className="flex w-full md:w-1/4 sm:w-full p-12">
                        <TextField
                            id="debnr"
                            label="Debnr"
                            value={options.debnr}
                            onChange={handleHeaderInputChange('debnr')}
                            className={classes.textField}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }} 
                        />
                    </div>
                    <div className="flex w-full md:w-1/4 sm:w-full p-12">
                        <TextField
                            id="name"
                            label={t('NAME')}
                            value={options.name}
                            onChange={handleHeaderInputChange('name')}
                            className={classes.textField}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div className="flex w-full md:w-1/4 sm:w-full p-12">
                        <TextField
                            id="global"
                            label={t('SEARCH')}
                            value={options.global}
                            onChange={handleHeaderInputChange('global')}
                            className={classes.textField}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }} 
                        />
                    </div>
                    <div className="flex w-full md:w-1/4 sm:w-full p-12">
                        <div className="flex w-full md:w-1/3 sm:w-full p-12">
                            <Tooltip title={`All ${t('UNPAIDINVOICES')}`}>
                                <div>
                                    <DefaultRadio
                                        className={classes.defaultRadio}
                                        checked={options.selectedRadio === 'default'}
                                        onChange={handleRadioChange}
                                        value="default"
                                    />
                                </div>
                            </Tooltip>
                        </div>
                        <div className="flex w-full md:w-1/3 sm:w-full p-12">
                            <Tooltip title={`Only ${t('CUSTOMERS')} with at least 1 orange invoice`}>
                                <div>
                                    <OrangeRadio
                                        className={classes.orangeRadio}
                                        checked={options.selectedRadio === 'orange'}
                                        onChange={handleRadioChange}
                                        value="orange"
                                    />
                                </div>
                            </Tooltip>
                        </div>
                        <div className="flex w-full md:w-1/3 sm:w-full p-12">
                            <Tooltip title={`Only ${t('CUSTOMERS')} with at least 1 red invoice`}>
                                <div>
                                    <RedRadio
                                        className={classes.redRadio}
                                        checked={options.selectedRadio === 'red'}
                                        onChange={handleRadioChange}
                                        value="red"
                                    />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap p-12">
                    {    !openPostenQuery.isLoading ?
                        openPostenData.length === 0 ? <div className="m-auto text-center">
                            <Typography>No data found</Typography>
                        </div>
                        :
                        <>
                            <OpenPostenTable 
                                openPosten={openPostenData.slice(0, amountOfCards)} 
                                printOpenPost={printOpenPost} 
                                sendOpenPost={(data) => sendOpenPost.mutate(data)}
                                amountOfOpenPosten={openPostenData.length}
                                />
                            {openPostenData.length > amountOfCards ? 
                                <div className="w-full text-center p-12">
                                    <Button variant="contained" color="primary" onClick={handleLoadAll}>
                                        {t("LOADMORE")}
                                    </Button>
                                </div> : null
                            }
                        </>
                    :  <><Skeleton /><Skeleton /><Skeleton /><Skeleton /></> 
                    }
                </div>
            </FuseAnimateGroup>
            </>
        }
        />
    )
}
export default withStyles(styles)(withTranslation()(OpenPosten));