import {updateObject} from '../../utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    pageStats: [],
    userLogsByPage: [],
    userStats: {},
};

const fetchPageStatsStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};
const fetchPageStatsSuccess = ( state, action ) => {
    return updateObject( state, { loading: false, pageStats: action.pageStats } );
};
const fetchPageStatsFail = ( state, action ) => {
    return updateObject( state, { error: action.error, loading: false } );
};

const fetchUserLogsByPageStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};
const fetchUserLogsByPageSuccess = ( state, action ) => {
    return updateObject( state, { loading: false, userLogsByPage: action.userLogsByPage } );
};
const fetchUserLogsByPageFail = ( state, action ) => {
    return updateObject( state, { error: action.error, loading: false } );
};

const fetchUserStatsStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};
const fetchUserStatsSuccess = ( state, action ) => {
    return updateObject( state, { loading: false, userStats: action.userStats } );
};
const fetchUserStatsFail = ( state, action ) => {
    return updateObject( state, { error: action.error, loading: false } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FETCH_PAGE_STATS_START: return fetchPageStatsStart( state, action );
        case actionTypes.FETCH_PAGE_STATS_SUCCESS: return fetchPageStatsSuccess( state, action );
        case actionTypes.FETCH_PAGE_STATS_FAIL: return fetchPageStatsFail( state, action );

        case actionTypes.FETCH_USER_LOGS_BY_PAGE_START: return fetchUserLogsByPageStart( state, action );
        case actionTypes.FETCH_USER_LOGS_BY_PAGE_SUCCESS: return fetchUserLogsByPageSuccess( state, action );
        case actionTypes.FETCH_USER_LOGS_BY_PAGE_FAIL: return fetchUserLogsByPageFail( state, action );

        case actionTypes.FETCH_USER_STATS_START: return fetchUserStatsStart( state, action );
        case actionTypes.FETCH_USER_STATS_SUCCESS: return fetchUserStatsSuccess( state, action );
        case actionTypes.FETCH_USER_STATS_FAIL: return fetchUserStatsFail( state, action );

        default: return state;
    }
};

export default reducer;