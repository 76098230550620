import React, {Component} from 'react';
import {withStyles, Icon, Typography} from '@material-ui/core';
import {FuseHighlight, FusePageSimple} from '../../../../@fuse';

const styles = theme => ({
    layoutRoot: {},
});

class Routing extends Component {

    render()
    {
        const {classes} = this.props;
        return (
            <FusePageSimple
            classes={{
                root: classes.layoutRoot
            }}
            header={
                <div className="flex flex-1 items-center justify-between p-24">
                    <div className="flex flex-col">
                        <div className="flex items-center mb-16">
                            <Icon className="text-18" color="action">home</Icon>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">Documentation</Typography>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            <Typography color="textSecondary">Routing</Typography>
                        </div>
                        <Typography variant="h6">Routing</Typography>
                    </div>
                </div>
            }
            // contentToolbar={
            //     <div className="px-24"><h4>Routing</h4></div>
            // }
            content={
                <div className="p-24">
                    <div className="max-w-2xl">

                        <Typography className="mb-16" component="p">
                            First edit this page <FuseHighlight component="code" className="language-bash">/fuse-configs/routesConfig.js</FuseHighlight>
                        </Typography>

                        <FuseHighlight component="pre" className="language-jsx my-16">
                            {`
                                import React from 'react';
                                import {Redirect} from 'react-router-dom';
                                import {FuseUtils} from '@fuse/index';
                                import {ExampleConfig} from '../../../../app/main/example/ExampleConfig';
                                import {ExpeditionConfig } from '../main/Dashboard/Expedition/ExpeditionConfig';
                                import {iCRMConfig} from '../../../../app/main/iCRM/iCRMConfig';
                                import {DocumentationConfig} from '../../../../app/main/documentation/DocumentationConfig';
                                
                                const routeConfigs = [
                                    ExampleConfig,
                                    ExpeditionConfig,
                                    iCRMConfig,
                                    DocumentationConfig,
                                ];
                                
                                    const routes = [
                                    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
                                    {
                                        path     : '/',
                                        component: () => <Redirect to="/example"/>
                                    }
                                ];
                                
                                    export default routes;
                                `}
                        </FuseHighlight>

                        <Typography className="mb-16" component="p">
                            Then edit your navigation file <FuseHighlight component="code" className="language-bash">fuse-configs/navigationConfig.js</FuseHighlight>
                        </Typography>

                        <Typography className="mb-16" component="p">
                            Carefull with the <code>type</code> it can be a <FuseHighlight component="code" className="language-bash">item</FuseHighlight> or a <FuseHighlight component="code" className="language-bash">collapse</FuseHighlight>
                        </Typography>

                        <FuseHighlight component="pre" className="language-jsx my-16">
                            {`
            const navigationConfig = [
                {
                    'id'      : 'applications',
                    'title'   : 'Applications',
                    'type'    : 'group',
                    'icon'    : 'apps',
                    'children': [
                        {
                            'id'   : 'example-component',
                            'title': 'Example',
                            'type' : 'item',
                            'icon' : 'whatshot',
                            'url'  : '/example'
                        },
                        {
                            'id'      : 'dashboards',
                            'title'   : 'iCRM',
                            'type'    : 'collapse',
                            'icon'    : 'dashboard',
                            'children': [
                                {
                                    'id'   : 'overview',
                                    'title': 'Overview',
                                    'type' : 'item',
                                    'url'  : '/icrm/overview'
                                },
                                {
                                    'id'   : 'weblog',
                                    'title': 'Weblog',
                                    'type' : 'item',
                                    'url'  : '/icrm/weblog'
                                },
                                ...
                    ]
                }
            ];

            export default navigationConfig;

                                `}
                        </FuseHighlight>
                        
                        <Typography className="mb-16" component="p">
                            Now create or edit the <code>config file</code> in <FuseHighlight component="code" className="language-bash">\main\iCRM\iCRMConfig.js</FuseHighlight>
                        </Typography>

                        <FuseHighlight component="pre" className="language-jsx my-16">
                            {`
            import Overview from './overview/Overview';
            import Customer from './Customer/Customer';
            import Weblog from './Weblog/Weblog';

            export const iCRMConfig = {
                settings: {
                    layout: {
                        config: {}
                    }
                },
                routes  : [
                    {
                        path     : '/iCRM/overview',
                        component: Overview
                    },
                    {
                        path     : '/iCRM/weblog',
                        component: Weblog
                    },
                    {
                        path     : '/iCRM/customer/:debnr',
                        component: Customer
                    },
                ]
            };

                                `}
                        </FuseHighlight>
                    </div>

                </div>
            }
            />
        )
    }
}

export default withStyles(styles, {withTheme: true})(Routing);
