import React, {Component} from 'react';
import {TextField, Button, Dialog, DialogActions, DialogContent, Icon, Typography, Toolbar, AppBar, 
    Avatar, Checkbox, FormControl, OutlinedInput, Select, MenuItem, Tooltip, FormControlLabel, IconButton, InputAdornment} from '@material-ui/core';
import _ from 'lodash';
import {fetchCompanies} from '../../../../store/crm/admin/actions';
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next';
import Axios from 'axios';

const newSpecialOffer = {
    id      : '0',
    title    : '',
    type    : 'BESTEL',
    devisie    : '',
    subLijst: '',
    productType    : '4',
    listId    : '',
    companyId: 1,
    groupCode: '0',
    templateId: '',
    senderEmail: '',
    emailSubject: '',
    enabled    : true,
    s_vink: false,
    sublijstProducts: false,
    maxProducts: 5,
    bestelDebiteur: '',
    connectionType: 'SQL',
    soapDebnr: '',
    soapPassword: '',
};

class AddSpecialOffer extends Component {

    state = {...newSpecialOffer};

    componentDidMount(){
        this.props.onFetchCompanies();
        const markeringen = Axios.get(
            "specialoffer/getpartijmarkeringen"
        ).then(result => {
            // console.log(result.data);
            this.setState({
            markeringen: result.data
        })});
    }

    handleChange = (event) => {
        this.setState(_.set({...this.state}, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
    };

    canBeSubmitted()
    {
        const {title, type, devisie, productType, listId, groupCode, templateId, senderEmail, emailSubject, maxProducts} = this.state;
        return (
            title.length > 0 && type.length > 0 && devisie.length > 0 && listId.length > 0 && groupCode.length > 0 
            && templateId.length > 0 && senderEmail.length > 0 && emailSubject.length > 0  && maxProducts > 0
        );
    }

    handleSave = () => {
        this.props.onSave(this.state);
        this.setState({...newSpecialOffer});
    }

    handleClose = () => {
        this.setState({...newSpecialOffer});
        this.props.onClose();
    }

    render()
    {
        const {dialogOpen, companies, t} = this.props;

        return (
            <Dialog 
                classes={{
                    paper: "m-24"
                }}
                onClose={this.handleClose}
                open={dialogOpen}
                fullWidth
                maxWidth="sm"
            >

                <AppBar position="static" elevation={1}>
                    <Toolbar className="flex w-full">
                        <Typography variant="subtitle1" color="inherit">
                            {t("NEW")} {t("SPECIALOFFER")}
                        </Typography>
                    </Toolbar>
                    <div className="flex flex-col items-center justify-center pb-24">
                        <Avatar className="w-96 h-96" alt="contact avatar">{this.state.title === '' ? '+' : this.state.title.substr(0, 1)}</Avatar>
                        <Typography variant="h6" color="inherit" className="pt-8">
                            {this.state.title}
                        </Typography>
                    </div>
                </AppBar>

                <DialogContent classes={{root: "p-24"}}>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">title</Icon>
                        </div>

                        <TextField
                            className="mb-24"
                            label={t("NAME")}
                            autoFocus
                            id="title"
                            name="title"
                            value={this.state.title}
                            onChange={this.handleChange}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div>

                    <div className="flex">
                        <Typography className="text-16 pb-20">
                            Florisoft Settings
                        </Typography>
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                        <Tooltip title="Connection">
                            <Icon color="action">business</Icon>
                        </Tooltip>
                        </div>
                        <FormControl variant="outlined" className="mb-24" fullWidth>
                            <Select
                                value={this.state.connectionType.trim()}
                                label={t("COMPANY")}
                                onChange={this.handleChange}
                                input={
                                <OutlinedInput
                                    labelWidth={0}
                                    name="connectionType"
                                    id="connectionType"
                                />
                                }
                                required
                                fullWidth
                            >
                            <MenuItem value="SQL">SQL</MenuItem>
                            <MenuItem value="SOAP">SOAP</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {this.state.connectionType == "SOAP" ? (<>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Tooltip title="Soap Debnr">
                                <Icon color="action">title</Icon>
                            </Tooltip>
                        </div>
                        <TextField
                            className="mb-24"
                            id="soapDebnr"
                            label="Soap Debnr"
                            name="soapDebnr"
                            value={this.state.soapDebnr}
                            onChange={this.handleChange}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Tooltip title="Soap Password">
                                <Icon color="action">title</Icon>
                            </Tooltip>
                        </div>
                        <TextField
                            className="mb-24"
                            id="soapPassword"
                            label="Soap Password"
                            name="soapPassword"
                            value={this.state.soapPassword}
                            onChange={this.handleChange}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div>
                    </>) : ('')}
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">business</Icon>
                        </div>
                        <FormControl variant="outlined" className="mb-24" fullWidth>
                            <Select
                                value={this.state.companyId}
                                onChange={this.handleChange}
                                input={
                                <OutlinedInput
                                    labelWidth={0}
                                    name="companyId"
                                    id="companyId"
                                />
                                }
                                required
                                fullWidth
                            >
                                {companies.length > 0 ? 
                                    companies.map(company => <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>)
                                : null }
                            </Select>
                        </FormControl>
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                        <Tooltip title="Type">
                            <Icon color="secondary">list_alt</Icon>
                        </Tooltip>
                        </div>
                        <FormControl variant="outlined" className="mb-24" fullWidth>
                            <Select
                                value={this.state.type}
                                onChange={this.handleChange}
                                input={
                                <OutlinedInput
                                    labelWidth={0}
                                    name="type"
                                    id="type"
                                />
                                }
                                required
                                fullWidth
                            >
                                <MenuItem value="BESTEL">Bestellijst</MenuItem>
                                <MenuItem value="PARTIJ">Voorraad</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">list_alt</Icon>
                        </div>
                        <TextField
                            className="mb-24"
                            label="Vooraad code / bestel code"
                            id="devisie"
                            name="devisie"
                            value={this.state.devisie}
                            onChange={this.handleChange}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div>
                    {this.state.type === 'BESTEL' ?
                    <React.Fragment>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Tooltip title="Sublijst">
                                <Icon color="action">subject</Icon>
                            </Tooltip>
                        </div>
                        <TextField
                            className="mb-24"
                            label="Sublijst"
                            id="subLijst"
                            name="subLijst"
                            value={this.state.subLijst}
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    
                    {this.state.connectionType != "SOAP" ? (
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Tooltip title="Instellingen Debiteur">
                                <Icon color="action">settings</Icon>
                            </Tooltip>
                        </div>
                        <TextField
                            className="mb-24"
                            id="bestelDebiteur"
                            label="Bestel Debiteur"
                            name="bestelDebiteur"
                            value={this.state.bestelDebiteur}
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                        />
                    </div>) : null}
                    </React.Fragment>
                    : null }

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Tooltip title="Set here a product limit to display in the Email">
                                <Icon color="secondary">people_outline</Icon>
                            </Tooltip>
                        </div>
                        <TextField  
                            className="mb-24"
                            id="maxProducts"
                            label="Max Products"
                            name="maxProducts"
                            type="number"
                            value={this.state.maxProducts}
                            onChange={this.handleChange}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div>
             
                    {this.state.type === 'BESTEL' && this.state.connectionType != "SOAP" ?
                        <div className="flex">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="sublijstProducts"
                                        label="Sublijst"
                                        name="sublijstProducts"
                                        checked={this.state.sublijstProducts}
                                        onChange={this.handleChange}
                                        required
                                    />
                                }
                                label="Markering in sublijst"
                                labelPlacement="start"
                            />
                        </div> : null
                    }
                    
                    {this.state.type === 'BESTEL' && this.state.connectionType != "SOAP" ?
                    <div className="flex">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="s_vink"
                                    label="Sublijst"
                                    name="s_vink"
                                    checked={this.state.s_vink}
                                    onChange={this.handleChange}
                                    required
                                />
                            }
                            label="Debiter S Vink"
                            labelPlacement="start"
                        />
                    </div> : null
                    }
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                        <Tooltip title="Markering">
                            <Icon color="secondary">art_track</Icon>
                        </Tooltip>
                        </div>
                            <FormControl variant="outlined" className="mb-24" fullWidth>
                                <div className="flex">
                                <Select
                                    value={this.state.productType}
                                    onChange={this.handleChange}
                                    input={
                                    <OutlinedInput
                                        labelWidth={0}
                                        name="productType"
                                        id="productType"
                                    />
                                    }
                                    required
                                    fullWidth
                                >
                                {this.state.connectionType === "SOAP" ? 
                                    [
                                        <MenuItem key="A" value="Angebot">Aanbieding</MenuItem>,
                                        <MenuItem key="E" value="EXKLUSIV">Exclusive</MenuItem>,
                                        <MenuItem key="I" value="Interessant">Interessant</MenuItem>,
                                        <MenuItem key="S" value="Superdeal">Superdeal</MenuItem>,
                                        <MenuItem key="P" value="Plant World">Plant World</MenuItem>
                                    ]
                                :
                                    this.state.type === "PARTIJ" ? 
                                    // IF VOORRAAD
                                    this.state.markeringen?.map(i => <MenuItem key={i.voorraad_id} value={i.voorraad_id}>{i.name} ({i.voorraad_id})</MenuItem>)
                                        // [
                                        //     <MenuItem key="J" value="J">Aanbieding</MenuItem>,
                                        //     <MenuItem key="N" value="N">Exclusive</MenuItem>,
                                        //     <MenuItem key="K" value="K">Interessant</MenuItem>,
                                        //     <MenuItem key="S" value="S">Superdeal</MenuItem>,
                                        //     <MenuItem key="P" value="P">Plant World</MenuItem>
                                        // ]
                                    :
                                    // IF BESTEL
                                    this.state.markeringen?.map(i => <MenuItem key={i.bestel_id} value={i.bestel_id}>{i.name} ({i.bestel_id})</MenuItem>)
                                        // [
                                        //     <MenuItem key="0" value="0">Normal</MenuItem>,
                                        //     <MenuItem key="1" value="1">Sale</MenuItem>,
                                        //     <MenuItem key="2" value="2">Exclusive</MenuItem>,
                                        //     <MenuItem key="3" value="3">Interessant</MenuItem>,
                                        //     <MenuItem key="4" value="4">Superdeal</MenuItem>,
                                        //     <MenuItem key="80" value="80">Plant World</MenuItem>
                                        // ]
                                }
                                </Select>
                                <TextField
                                    className="ml-12"
                                    id="productType"
                                    label="Product Markeren"
                                    name="productType"
                                    placeholder="Aanbieding, Superdeal, Interessant, Exclusief..."
                                    value={this.state.productType}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton aria-label="Info">
                                                <Tooltip title={"Check the Dutch Webshop markeren to find the exact word"}>
                                                    <Icon color="primary">info</Icon>
                                                </Tooltip>
                                            </IconButton>
                                        </InputAdornment>,
                                        inputProps: { maxLength: 20 }
                                    }}
                                />
                                </div>
                            </FormControl>
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Tooltip title="Group Code - Leave empty if offer has no group">
                                <Icon color="secondary">people_outline</Icon>
                            </Tooltip>
                        </div>
                        <TextField  
                            className="mb-24"
                            id="groupCode"
                            label="Group Code"
                            name="groupCode"
                            type="number"
                            value={this.state.groupCode}
                            onChange={this.handleChange}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div>

                    <div className="flex">
                        <Typography className="text-16 pb-20">
                            Sendinblue Settings
                        </Typography>
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Tooltip title="SendinBlue Email's template">
                                <Icon color="action">local_post_office</Icon>
                            </Tooltip>
                        </div>

                        <TextField
                            className="mb-24"
                            label="Template ID"
                            id="templateId"
                            name="templateId"
                            value={this.state.templateId}
                            onChange={this.handleChange}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Tooltip title="SendinBlue List ID">
                                <Icon color="action">list</Icon>
                            </Tooltip>
                        </div>
                        <TextField
                            className="mb-24"
                            id="listId"
                            label="Sendinblue List ID"
                            name="listId"
                            type="number"
                            value={this.state.listId}
                            onChange={this.handleChange}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Tooltip title="SendinBlue Sender's Email">
                                <Icon color="action">local_post_office</Icon>
                            </Tooltip>
                        </div>

                        <TextField
                            className="mb-24"
                            label="Sender's Email"
                            id="senderEmail"
                            name="senderEmail"
                            value={this.state.senderEmail}
                            onChange={this.handleChange}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Tooltip title="SendinBlue Sender's Name">
                                <Icon color="action">title</Icon>
                            </Tooltip>
                        </div>

                        <TextField
                            className="mb-24"
                            label="Sender's Name"
                            id="senderName"
                            name="senderName"
                            value={this.state.senderName}
                            onChange={this.handleChange}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">local_post_office</Icon>
                        </div>

                        <TextField
                            className="mb-24"
                            label="Email subject"
                            id="emailSubject"
                            name="emailSubject"
                            value={this.state.emailSubject}
                            onChange={this.handleChange}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div>

                    <div className="flex">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="enabled"
                                        name="enabled"
                                        checked={this.state.enabled}
                                        onChange={this.handleChange}
                                        required
                                    />
                                }
                                label="Enabled"
                                labelPlacement="start"
                            />
                    </div>
                </DialogContent>
                <DialogActions className="justify-end pl-16">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSave}
                        disabled={!this.canBeSubmitted()}
                    >
                        {t("SAVE")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = ({admin}) => {
    return {
        companies: admin.companies
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchCompanies: () => dispatch(fetchCompanies())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddSpecialOffer));
