import axios from 'axios';
import * as actionTypes from './actionTypes';

export const createUserLog = (userLog) => {
    return dispatch => {
        axios.post('monitor/createuserlog', userLog)
        .then(res => res)
        .catch(err => console.log(err.response.status))
    }
}

export const fetchPageStatsStart = () => {
    return {
        type: actionTypes.FETCH_PAGE_STATS_START
    }
}

export const fetchPageStatsSuccess = (pageStats) => {
    return {
        type: actionTypes.FETCH_PAGE_STATS_SUCCESS,
        pageStats
    }
}
export const fetchPageStatsFail = (error) => {
    return {
        type: actionTypes.FETCH_PAGE_STATS_FAIL,
        error
    }
}

export const fetchPageStats = () => {
    return dispatch => {
        axios.get('monitor/getpagelogs')
            .then(res => {
                dispatch(fetchPageStatsSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchPageStatsFail(err.response.status));
            })
    }
}

export const fetchUserLogsByPageStart = () => {
    return {
        type: actionTypes.FETCH_USER_LOGS_BY_PAGE_START
    }
}

export const fetchUserLogsByPageSuccess = (userLogsByPage) => {
    return {
        type: actionTypes.FETCH_USER_LOGS_BY_PAGE_SUCCESS,
        userLogsByPage
    }
}
export const fetchUserLogsByPageFail = (error) => {
    return {
        type: actionTypes.FETCH_USER_LOGS_BY_PAGE_FAIL,
        error
    }
}

export const fetchUserLogsByPage = (page) => {
    return dispatch => {
        axios.get('monitor/getuserlogsbypage?page=' + page)
            .then(res => {
                dispatch(fetchUserLogsByPageSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchUserLogsByPageFail(err.response.status));
            })
    }
}

export const fetchUserStatsStart = () => {
    return {
        type: actionTypes.FETCH_USER_STATS_START
    }
}

export const fetchUserStatsSuccess = (userStats) => {
    return {
        type: actionTypes.FETCH_USER_STATS_SUCCESS,
        userStats
    }
}
export const fetchUserStatsFail = (error) => {
    return {
        type: actionTypes.FETCH_USER_STATS_FAIL,
        error
    }
}

export const fetchUserStats = (userId) => {
    return dispatch => {
        axios.get('monitor/GetUserStatsByUser/' + userId)
            .then(res => {
                dispatch(fetchUserStatsSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchUserStatsFail(err.response.status));
            })
    }
}
