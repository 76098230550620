import * as actionTypes from './actionTypes';
import axios from 'axios'

const fetchTasksStart = () => {
    return {
        type: actionTypes.FETCH_TASKS_START
    };
};

const fetchTasksSuccess = (tasks) => {
    return {
        type: actionTypes.FETCH_TASKS_SUCCESS,
        tasks
    };
};

const fetchTasksFail = (error) => {
    return {
        type: actionTypes.FETCH_TASKS_FAIL,
        error
    };
};

export const fetchTasks = (afterFetchTasksSuccess) => {
    return dispatch => {
        dispatch(fetchTasksStart())
        axios.get('task/getTasks')
            .then(res => {
                dispatch(fetchTasksSuccess(res.data));
                afterFetchTasksSuccess(res.data);
            })
            .catch(error => {
                dispatch(fetchTasksFail(error.message))
            })
    };
};

const fetchTaskStart = () => {
    return {
        type: actionTypes.FETCH_TASK_START
    };
};

const fetchTaskSuccess = (task) => {
    return {
        type: actionTypes.FETCH_TASK_SUCCESS,
        task
    };
};

const fetchTaskFail = (error) => {
    return {
        type: actionTypes.FETCH_TASK_FAIL,
        error
    };
};

export const fetchTask = (id, isOrderTask) => {
    var baseUrl = 'task/' + (isOrderTask ? 'getOrderTask/' : 'gettask/');
    return dispatch => {
        dispatch(fetchTaskStart())
        axios.get(baseUrl + id)
            .then(res => {
                dispatch(fetchTaskSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchTaskFail(error.message))
            })
    };
};

const fetchTaskTimeLineStart = () => {
    return {
        type: actionTypes.FETCH_TASK_TIMELINE_START
    };
};

const fetchTaskTimeLineSuccess = (timeLine) => {
    return {
        type: actionTypes.FETCH_TASK_TIMELINE_SUCCESS,
        timeLine
    };
};

const fetchTaskTimeLineFail = (error) => {
    return {
        type: actionTypes.FETCH_TASK_TIMELINE_FAIL,
        error
    };
};

export const fetchTaskTimeLine = (id) => {
    return dispatch => {
        dispatch(fetchTaskTimeLineStart())
        axios.get('task/GetTaskEventsForTimeLine/' + id)
            .then(res => {
                dispatch(fetchTaskTimeLineSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchTaskTimeLineFail(error.message))
            })
    };
};


const fetchAccountManagersStart = () => {
    return {
        type: actionTypes.FETCH_ACCOUNTMANAGERS_START
    };
};

const fetchAccountManagersSuccess = (accountManagers) => {
    return {
        type: actionTypes.FETCH_ACCOUNTMANAGERS_SUCCESS,
        accountManagers
    };
};

const fetchAccountManagersFail = (error) => {
    return {
        type: actionTypes.FETCH_ACCOUNTMANAGERS_FAIL,
        error
    };
};

export const fetchAccountManagers = () => {
    return dispatch => {
        dispatch(fetchAccountManagersStart())
        axios.get('task/getAccountManagers')
            .then(res => {
                dispatch(fetchAccountManagersSuccess(res.data));
            })
            .catch(error => {
                dispatch(fetchAccountManagersFail(error.message))
            })
    };
};


// Create Task
const createTaskStart = () => {
    return {
        type: actionTypes.CREATE_TASK_START
    };
};
const createTaskSuccess = (createdTask) => {
    return {
        type: actionTypes.CREATE_TASK_SUCCESS,
        createdTask
    };
};
const createTaskFail = (error) => {
    return {
        type: actionTypes.CREATE_TASK_FAIL,
        error
    };
};
const createTaskReset = () => {
    return {
        type: actionTypes.CREATE_TASK_RESET        
    };
};

export const createTask = (task, afterCreateTaskSuccess) => {
    return dispatch => {
        dispatch(createTaskStart())
        axios.post('task/createtask', task)
            .then(res => {
                afterCreateTaskSuccess();
                dispatch(createTaskSuccess(res.data));
            })
            .catch(error => {
                console.log(JSON.stringify(error))
                dispatch(createTaskFail(error.message))
            })
    };
};

export const resetCreateTask = () => {
    return dispatch => {
        dispatch(createTaskReset())
    }
}

// Update Task
const saveTaskStart = () => {
    return {
        type: actionTypes.SAVE_TASK_START
    };
};
const saveTaskSuccess = (updatedTask) => {
    return {
        type: actionTypes.SAVE_TASK_SUCCESS,
        updatedTask
    };
};
const saveTaskFail = (error) => {
    return {
        type: actionTypes.SAVE_TASK_FAIL,
        error
    };
};

export const saveTask = (task, afterSaveTask) => {
    return dispatch => {
        dispatch(saveTaskStart())
        axios.post('task/UpdateTask', task)
            .then(res => {
                if(afterSaveTask) {
                    afterSaveTask(res.data);
                }
                dispatch(saveTaskSuccess(res.data));
            })
            .catch(error => {
                dispatch(saveTaskFail(error.message))
            })
    };
};

// Update Task
const saveOrderTaskStart = () => {
    return {
        type: actionTypes.SAVE_ORDERTASK_START
    };
};
const saveOrderTaskSuccess = (updatedTask) => {
    return {
        type: actionTypes.SAVE_ORDERTASK_SUCCESS,
        updatedTask
    };
};
const saveOrderTaskFail = (error) => {
    return {
        type: actionTypes.SAVE_ORDERTASK_FAIL,
        error
    };
};

export const saveOrderTask = (task, afterSaveOrderTask) => {
    return dispatch => {
        dispatch(saveOrderTaskStart())
        axios.post('task/UpdateOrderTask', task)
            .then(res => {
                if(afterSaveOrderTask) {
                    afterSaveOrderTask();
                }
                dispatch(saveOrderTaskSuccess(res.data));
            })
            .catch(error => {
                dispatch(saveOrderTaskFail(error.message))
            })
    };
};

// Conclude Task
const concludeTaskStart = () => {
    return {
        type: actionTypes.CONCLUDE_TASK_START
    };
};
const concludeTaskSuccess = (concludedTask) => {
    return {
        type: actionTypes.CONCLUDE_TASK_SUCCESS,
        concludedTask
    };
};
const concludeTaskFail = (error) => {
    return {
        type: actionTypes.CONCLUDE_TASK_FAIL,
        error
    };
};

export const concludeTask = (task) => {
    return dispatch => {
        dispatch(concludeTaskStart())
        axios.post('task/concludetask', task)
            .then(res => {
                dispatch(concludeTaskSuccess(res.data));
            })
            .catch(error => {
                dispatch(concludeTaskFail(error.message))
            })
    };
};

export const resetConcludeTask = () => {
    return dispatch => {
        dispatch(concludeTaskSuccess(false));
    }
}

// Conclude Task
const setTaskFalseAlarmStart = () => {
    return {
        type: actionTypes.SET_TASK_FALSEALARM_START
    };
};
const setTaskFalseAlarmSuccess = (setToFalseAlarm) => {
    return {
        type: actionTypes.SET_TASK_FALSEALARM_SUCCESS,
        setToFalseAlarm
    };
};
const setTaskFalseAlarmFail = (error) => {
    return {
        type: actionTypes.SET_TASK_FALSEALARM_FAIL,
        error
    };
};

export const setTaskFalseAlarm = (task) => {
    return dispatch => {
        dispatch(setTaskFalseAlarmStart())
        axios.post('task/UpdateTaskFalseAlarm', task)
            .then(res => {
                dispatch(fetchTask(task.id));
                dispatch(setTaskFalseAlarmSuccess(res.data));
            })
            .catch(error => {
                dispatch(setTaskFalseAlarmFail(error.message))
            })
    };
};

export const resetTaskFalseAlarm = () => {
    return dispatch => {
        dispatch(concludeTaskSuccess(false));
    }
}

// Get Tasks Overview
const fetchTasksOverviewStart = () => {
    return {
        type: actionTypes.FETCH_TASKSOVERVIEW_START
    };
};

const fetchTasksOverviewSuccess = (tasksoverview) => {
    return {
        type: actionTypes.FETCH_TASKSOVERVIEW_SUCCESS,
        tasksoverview
    };
};

const fetchTasksOverviewFail = (error) => {
    return {
        type: actionTypes.FETCH_TASKSOVERVIEW_FAIL,
        error
    };
};

export const fetchTasksOverview = (afterFetchTasksSuccess) => {
    return dispatch => {
        dispatch(fetchTasksOverviewStart())
        axios.get('task/getTasksOverview')
            .then(res => {
                dispatch(fetchTasksOverviewSuccess(res.data));
                afterFetchTasksSuccess(res.data);
            })
            .catch(error => {
                dispatch(fetchTasksOverviewFail(error.message))
            })
    };
};


// Conclude Task
const addCommentStart = () => {
    return {
        type: actionTypes.ADD_TASK_COMMENT_START
    };
};
const addCommentSuccess = (addedComment) => {
    return {
        type: actionTypes.ADD_TASK_COMMENT_SUCCESS,
        addedComment
    };
};
const addCommentFail = (error) => {
    return {
        type: actionTypes.ADD_TASK_COMMENT_FAIL,
        error
    };
};

export const addComment = (comment, afterSendCommentSuccess) => {
    return dispatch => {
        dispatch(addCommentStart())
        axios.post('task/createComment', comment)
            .then(res => {
                if(afterSendCommentSuccess) {
                    afterSendCommentSuccess();
                }
                dispatch(addCommentSuccess(res.data));
            })
            .catch(error => {
                dispatch(addCommentFail(error.message))
            })
    };
};

export const resetAddComment = () => {
    return dispatch => {
        dispatch(addCommentSuccess(false))
    };
};