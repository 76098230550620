import React, { PureComponent } from 'react';
import {
  ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
  Legend, ResponsiveContainer
} from 'recharts';

export default class Example extends PureComponent {

    render() {
        const {totals, invoices, labels} = this.props;
        const data = labels.map((label, index) => {
            return {
                name: label,
                turnover: totals[index],
                invoices: invoices[index]
            };
        })
        return (
            <ResponsiveContainer width="99%">
                <ComposedChart
                    width={450}
                    height={375}
                    data={data}
                    margin={{
                    top: 20, right: 20, bottom: 20, left: 20,
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" name="Months" />
                    <YAxis yAxisId="left" dataKey="turnover" name="Turnover" />
                    <YAxis yAxisId="right" orientation="right" dataKey="invoices" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="invoices" name="Orders" barSize={12} fill="#ef6c00" yAxisId="right" />
                    <Line type="monotone" name="Turnover" dataKey="turnover" stroke="#413ea0" strokeWidth={3} yAxisId="left" />
                </ComposedChart>
            </ResponsiveContainer>
        );
    }
}
