import React, {Component} from 'react';
import {withStyles, MuiThemeProvider, Typography} from '@material-ui/core';
import {FuseAnimate} from '../../../@fuse';
import {Line} from 'react-chartjs-2';
import _ from 'lodash';
import connect from 'react-redux/es/connect/connect';

const styles = theme => ({
    root: {
        background     : 'linear-gradient(to right, ' + theme.palette.primary.dark + ' 0%, ' + theme.palette.primary.main + ' 100%)',
    }
});

class DarkAreaChart extends Component {

    state = {
        dataset: '2017'
    };

    render()
    {
        // to give props: data object, dataset (line 92 for exmaple '2019'), headerText, subHeaderText
        const {classes, dataset, mainThemeDark, data: dataRaw, theme} = this.props;
        const data = _.merge({}, dataRaw);
        if(!dataset || !data.datasets[dataset]) return null;
        const dataWithColors = data.datasets[dataset].map(obj => ({
            ...obj,
            borderColor              : theme.palette.secondary.main,
            backgroundColor          : theme.palette.secondary.main,
            pointBackgroundColor     : theme.palette.secondary.dark,
            pointHoverBackgroundColor: theme.palette.secondary.main,
            pointBorderColor         : theme.palette.secondary.contrastText,
            pointHoverBorderColor    : theme.palette.secondary.contrastText
        }));

        return (
            <MuiThemeProvider theme={mainThemeDark}>
                <div className={classes.root}>
                    <div className="container relative p-16 sm:p-24 flex flex-row justify-between items-center">

                        <FuseAnimate delay={100}>
                            <div className="flex-col">
                                <Typography className="h2" style={{color: '#fff'}}>{this.props.headerText}</Typography>
                                <Typography className="h5" color="textSecondary">{this.props.subHeaderText}</Typography>
                            </div>
                        </FuseAnimate>

                        {/* <div className="flex flex-row items-center">
                            {Object.keys(data.datasets).map((key) => (
                                <Button
                                    key={key}
                                    className="py-8 px-12"
                                    size="small"
                                    onClick={() => this.setDataSet(key)}
                                    disabled={key === dataset}
                                >
                                    {key}
                                </Button>
                            ))}
                        </div> */}
                    </div>
                    <div className="container relative h-200 sm:h-256 pb-16">
                        <Line
                            data={{
                                labels  : data.labels,
                                datasets: dataWithColors
                            }}
                            options={data.options}
                        />
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

function mapStateToProps({fuse})
{
    return {
        mainThemeDark: fuse.settings.mainThemeDark
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(DarkAreaChart));

export const areaData = {
    chartType: 'line',
    datasets : {
        '2019': [{
            label: 'Visits',
            data : [17, 30, 12, 15, 13, 15, 24, 17, 33, 14, 18, 15, 16, 25, 19, 35, 12, 17, 13, 17, 24, 36, 35, 12, 17, 15, 13, 15, 24, 17, 33, 14, 18, 15, 16, 25, 19, 35, 12, 17, 13, 17, 24, 36, 35, 12, 17],
            fill : 'start'
        }]
    },
    labels   : ["March, 11", "March, 12", "March, 13", "March, 14", "March, 15", "March, 16", "March, 17", "March, 18", "March, 19", "March, 20", "March, 21", "March, 22",
     "March, 23", "March, 24", "March, 25", "March, 26", "March, 27", "March, 28", "March, 29", "March, 30", "March, 31", "April, 1", "April, 2", "April, 3","April, 4",
     "April, 5", "April, 6", "April, 7", "April, 8", "April, 9", "April, 10", "April, 11"],
    options  : {
        spanGaps           : false,
        legend             : {
            display: false
        },
        maintainAspectRatio: false,
        layout             : {
            padding: {
                top  : 32,
                left : 32,
                right: 32
            }
        },
        elements           : {
            point: {
                radius          : 4,
                borderWidth     : 2,
                hoverRadius     : 4,
                hoverBorderWidth: 2
            },
            line : {
                tension: 0.2
            }
        },
        scales             : {
            xAxes: [
                {
                    gridLines: {
                        display       : false,
                        drawBorder    : false,
                        tickMarkLength: 18
                    },
                    ticks    : {
                        fontColor: '#ffffff'
                    }
                }
            ],
            yAxes: [
                {
                    display: false,
                    ticks  : {
                        min     : 0,
                        max     : 100000,
                        stepSize: 50
                    }
                }
            ]
        },
        plugins            : {
            filler      : {
                propagate: false
            },
            xLabelsOnTop: {
                active: true
            }
        }
    }
}; 