import React, {Component} from 'react';
import ReactTable from "react-table";
import { connect } from 'react-redux';
import * as actions from '../../../../store/crm/actions';
import {FuseAnimate, FusePageSimple} from '../../../../../@fuse';
import {withStyles, Icon, Typography, IconButton, Tooltip, Button} from '@material-ui/core';
import RouteToDebnr from '../../../../components/Functions/RouteToDebnr';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import {withTranslation} from 'react-i18next';
import { getAzureId, getCompany } from '../../../Auth/Auth';
import ValidationDialog from './ValidationDialog';

const styles = theme => ({
    layoutRoot: {},
    root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    },
    table: {
    minWidth: 700,
    },
    row: {
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
    },
    },
});

class NewCustomers extends Component {
    state = {
        debnrvalidation: '',
        validationDialogOpen: false,
    }

    componentDidMount() {
        this.props.onFetchNewCustomers();
    }

    onActivateNewCustomer = (debnr) => {
        this.props.onActivateNewCustomer(debnr);
        const userLog = {
            azureId: getAzureId(),
            type: 'button',
            actionName: 'ActivateNewCustomer',
            extraInfo: 'DEBNR : ' + debnr
        };
        this.props.onCreateUserLog(userLog);
    }

    handleValidationDialogOpen = (row) => {
        this.setState({validationDialogOpen: true, debnrvalidation: row});
    }


    handleReturnDialogClose = () => {
        this.setState({validationDialogOpen: false});
    }

    render() {
        const {classes, newCustomers, fetchedNewCustomers, t} = this.props;

        return (
        <>            
        <ValidationDialog
            dialogOpen={this.state.validationDialogOpen}
            onClose={this.handleReturnDialogClose}
            debnrvalidation={this.state.debnrvalidation}
        />
        <FusePageSimple
        classes={{
            root: classes.layoutRoot
        }}
        header={
            <div className="flex flex-1 items-center justify-between p-24">
                <div className="flex flex-col">
                    <div className="flex items-center mb-16">
                        <Icon className="text-18" color="action">home</Icon>
                        <Icon className="text-16" color="action">chevron_right</Icon>
                        <Typography color="textSecondary">iCRM</Typography>
                        <Icon className="text-16" color="action">chevron_right</Icon>
                        <Typography color="textSecondary">{t("NEWCUSTOMERS")}</Typography>
                    </div>
                    <Typography variant="h6">{t("NEWCUSTOMERS")}</Typography>
                </div>
            </div>
        }
        content={
        <>
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div className="w-full">
                {newCustomers.length > 0 || fetchedNewCustomers ? 
                    <ReactTable
                        className="-striped -highlight border-0"
                        data={newCustomers}
                        columns={[
                            {
                                Header    : "Debnr",
                                accessor  : "debnr",
                                filterable: true,
                                className : "font-bold justify-center",
                                width     : 100,
                                Cell: row => (<RouteToDebnr row={row} />)
                            },
                            {
                                Header    : "Name",
                                accessor  : "debnaam",
                                filterable: true,
                                className : "font-bold"
                            },
                            {
                                Header    : t("ADDRESS"),
                                accessor  : "debadres",
                                filterable: true,
                                // className: "justify-center",
                            },
                            {
                                Header    : t("ZIPCODE"),
                                accessor  : "debpc",
                                filterable: true,
                                // className: "justify-center",
                                width     : 100
                            },
                            {
                                Header    : t("CITY"),
                                accessor  : "debplaats",
                                filterable: true,
                                // className: "justify-center",
                            },
                            {
                                Header    : "Manager",
                                accessor  : "acctmngr",
                                filterable: true,
                                className: "justify-center",
                                width: 100,
                            },
                            {
                                Header    : t("PHONE"),
                                accessor  : "debmobiel",
                                Cell: row => {
                                    return (
                                    <span>
                                    {(row.value !== undefined && row.value.toString().trim() !== '') || (row.original.DEBTFNR !== undefined && row.original.DEBTFNR.toString().trim() !== '') ? 
                                        
                                        <Tooltip title={row.value !== undefined ? row.value: null + row.value !== undefined && row.original.DEBTFNR !== undefined ?  + ' - ' : null + row.original.DEBTFNR !== undefined ? row.original.DEBTFNR: null}>
                                        <IconButton aria-label="Delete">
                                            <Icon>phone_iphone</Icon>
                                        </IconButton>
                                        </Tooltip>
                                    : <Icon className="text-18" color="error">clear</Icon> 
                                    }
                                    </span>
                                    )
                                },
                                className: "justify-center",
                                width: 80
                            },
                            {
                                Header    : "Email",
                                accessor  : "email",
                                filterable: true,
                                className: "justify-center",
                                width: 50,
                                Cell: row => (
                                    <Tooltip title={row.value !== undefined ? row.value : 'Unknown'}>
                                        <IconButton aria-label="Delete">
                                            <Icon>mail_outline</Icon>
                                        </IconButton>
                                    </Tooltip>
                                )
                            },
                            {
                                Header    : "BTW",
                                accessor  : "btwnrdeb",
                                className: "justify-center",
                                width: 50,
                                Cell: row => row.value && row.value.trim().length === 0 ? 
                                    <Icon className="text-18" color="error">clear</Icon> :
                                    <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                            },
                            {
                                Header    : t("CREATED"),
                                accessor  : "created",
                                className: "justify-center",
                                width: 100,
                                Cell: row => `${row.value} ${t("DAYS")}`
                                
                            },
                            {
                                Header    : t("NOTE"),
                                accessor  : "acctmngr_note",
                                className: "justify-center",
                                width: 50,
                                Cell: row => (row.value && row.value !== '') && (
                                    <Tooltip title={row.value !== undefined || row.value !== '' ? row.value : 'Unknown'}>
                                        <IconButton aria-label="Delete">
                                            <Icon>comment</Icon>
                                        </IconButton>
                                    </Tooltip>
                                )
                            },
                            {
                                Header    : "Temp",
                                accessor  : "template",
                                className: "justify-center",
                                filterable: true,
                                width: 50,
                                Cell: row => (row.value && row.value !== '') && (
                                    <Tooltip title={row.value !== undefined || row.value !== '' ? row.value : 'Unknown'}>
                                        <IconButton aria-label="Delete">
                                            <Icon>file_copy</Icon>
                                        </IconButton>
                                    </Tooltip>
                                ) 
                            },                         
                            {
                                Header    : t("VALIDATE"),
                                accessor  : "inactief",
                                filterable: false,
                                className: "justify-center",
                                Cell: row => (
                                    <div>{row.value === false ? <Icon className="text-18" style={{color: 'green'}}>check</Icon> 
                                        :  
                                        
                                    // IF VDP THEN OPEN MODAL
                                    getCompany() === 1 ? 
                                    <div>
                                        <Button variant="outlined" onClick={() => this.handleValidationDialogOpen(row.original)}>
                                            <Icon className="text-18" color="action">send</Icon>
                                        </Button></div>
                                    // ELSE SHOW VALIDATE BUTTON
                                        : 
                                        <Button variant="outlined" onClick={() => this.onActivateNewCustomer(row.original.debnr.trim())}>
                                            <Icon className="text-18" color="action">send</Icon>
                                        </Button>}</div>
                                ),
                                width: 80
                            },
                        ]}
                        pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                        defaultPageSize={newCustomers.length > 20 ? 20 : newCustomers.length}
                        noDataText="No customers found"
                    /> : <Spinner /> }
                </div>
            </FuseAnimate>
        </>
        }
    />
    </>);
    }
}

const mapStateToProps = ({customer}) => {
   return {
      newCustomers: customer.newCustomers,
      fetchedNewCustomers: customer.fetchedNewCustomers,
      isActivated: customer.isActivated
   }
}

const mapDispatchToProps = dispatch => {
   return {
      onFetchNewCustomers: () => dispatch(actions.fetchDbNewCustomers()),
      onActivateNewCustomer: debnr => dispatch(actions.activateNewCustomer(debnr)),
      onCreateUserLog: (userLog) => dispatch(actions.createUserLog(userLog))
   }
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles, {withTheme: true})(withTranslation()(NewCustomers)));