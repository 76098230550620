import React, { Component } from 'react';
import { Select } from '@material-ui/core';
import _ from 'lodash';

export default class TaskDetailSelect extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.value !== this.props.value || nextProps.disabled !== this.props.disabled;;
    }
        
    render() {
        return (
            <Select
                {..._.omit(this.props, ['children'])}
            >
                {this.props.children}
            </Select>
        );
    }
}
