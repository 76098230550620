import React, { useState } from 'react';
import DonutChart, {donutData, donutColors} from '../../../../../components/Widgets/DonutChart';
import {Typography, Paper, withStyles, Button} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Skeleton} from '@material-ui/lab';
import _ from 'lodash';
import { getUserEmail } from '../../../../Auth/Auth';

const DashboardDecoCharts = props => {
    const {t} = useTranslation();
    const [showFinancialGroupsLegend, setShowFinancialGroupsLegend] = useState(false);

    let financialGroupsDonutData = null;
    if(props.financialGroups) {
        financialGroupsDonutData = _.cloneDeep(donutData);
        const labels = [];
        const dataset = [];
        const financialGroupsDonutBackgroundColors = [];
        const financialGroupsDonutHoverBackgorundColors = [];
        for(let group of props.financialGroups) {
            labels.push(group.name);
            dataset.push(group.verkoopbedrag.toFixed(0));
            financialGroupsDonutBackgroundColors.push(donutColors.groupBackgroundColors[group.name]);
            financialGroupsDonutHoverBackgorundColors.push(donutColors.groupHoverBackgroundColors[group.name]);
        }
        
        financialGroupsDonutData['mainChart'].datasets['TW'][0].data = dataset;
        financialGroupsDonutData['mainChart'].datasets['TW'][0].backgroundColor = financialGroupsDonutBackgroundColors;
        financialGroupsDonutData['mainChart'].datasets['TW'][0].hoverBackgroundColor = financialGroupsDonutHoverBackgorundColors;
        financialGroupsDonutData['mainChart'].labels = labels;
        financialGroupsDonutData['mainChart'].options.legend.display = showFinancialGroupsLegend;
        financialGroupsDonutData.title = 'Turnover Distribution over financial groups';
    }

    let financialGroupsAllCustomersDonutData = null;
    if(props.financialGroupsAllCustomers) {
        financialGroupsAllCustomersDonutData = _.cloneDeep(donutData);
        const labels = [];
        const dataset = [];
        const financialGroupsDonutBackgroundColors = [];
        const financialGroupsDonutHoverBackgorundColors = [];
        if(getUserEmail() === 'office.germany@hbxdeco.com' || getUserEmail() === 'office.italy@hbxdeco.com' || getUserEmail() === 'kowalskitamara@gmail.com'
            || getUserEmail() === 'dgeyer@gmx.net' || getUserEmail() === 'robert@wvdplas.nl' || getUserEmail() === 'n.kruger@vdplas.com' || getUserEmail() === 'f.troeger@t-online.de'
            || getUserEmail() === 'hvrm.office@icloud.com'){
            const { value: total } = props.financialGroupsAllCustomers.reduce((a, b) => ({value: a.value + b.value}) );
            // console.log(total);
            // console.log(getUserEmail());
            
            for(let group of props.financialGroupsAllCustomers) {
                labels.push(group.name);
                dataset.push((group.value/total*100).toFixed(2));
                financialGroupsDonutBackgroundColors.push(donutColors.groupBackgroundColors[group.name]);
                financialGroupsDonutHoverBackgorundColors.push(donutColors.groupHoverBackgroundColors[group.name]);
                financialGroupsAllCustomersDonutData['mainChart'].options.plugins.labels.render = ({value, percentage}) => {
                    if(percentage < 5) return '';
                    return '% ' + Number(value).toLocaleString('nl-NL');
                };
            }
        }
        else{
            for(let group of props.financialGroupsAllCustomers) {
                labels.push(group.name);
                dataset.push(group.value.toFixed(0));
                financialGroupsDonutBackgroundColors.push(donutColors.groupBackgroundColors[group.name]);
                financialGroupsDonutHoverBackgorundColors.push(donutColors.groupHoverBackgroundColors[group.name]);
            }
        }
        
        financialGroupsAllCustomersDonutData['mainChart'].datasets['TW'][0].data = dataset;
        financialGroupsAllCustomersDonutData['mainChart'].datasets['TW'][0].backgroundColor = financialGroupsDonutBackgroundColors;
        financialGroupsAllCustomersDonutData['mainChart'].datasets['TW'][0].hoverBackgroundColor = financialGroupsDonutHoverBackgorundColors;
        financialGroupsAllCustomersDonutData['mainChart'].labels = labels;
        financialGroupsAllCustomersDonutData['mainChart'].options.legend.display = showFinancialGroupsLegend;
        financialGroupsAllCustomersDonutData.title = 'Turnover Distribution over financial groups';
    }

    let financialGroupsMargesDonutData = null;
    if(props.financialGroups) {
        financialGroupsMargesDonutData = _.cloneDeep(donutData);
        const labels = [];
        const dataset = [];
        const financialGroupsDonutBackgroundColors = [];
        const financialGroupsDonutHoverBackgroundColors = [];
        for(let group of props.financialGroups) {
            labels.push(group.name);
            dataset.push(group.value.toFixed(0));
            financialGroupsDonutBackgroundColors.push(donutColors.groupBackgroundColors[group.name]);
            financialGroupsDonutHoverBackgroundColors.push(donutColors.groupHoverBackgroundColors[group.name]);
        }
        
        financialGroupsMargesDonutData['mainChart'].datasets['TW'][0].data = dataset;
        financialGroupsMargesDonutData['mainChart'].datasets['TW'][0].backgroundColor = financialGroupsDonutBackgroundColors;
        financialGroupsMargesDonutData['mainChart'].datasets['TW'][0].hoverBackgroundColor = financialGroupsDonutHoverBackgroundColors;
        financialGroupsMargesDonutData['mainChart'].labels = labels;
        financialGroupsMargesDonutData['mainChart'].options.legend.display = showFinancialGroupsLegend;
        financialGroupsMargesDonutData.title = t('margin_distribution_title');
    }

    const widthClass = "md:w-1/3";

    return (        
        <div className="flex flex-wrap">
            <div className={`widget flex w-full sm:w-full ${widthClass} p-12`}>
                <Paper className="w-full rounded-8 shadow-none border-1">
                    <div className="flex items-center justify-between px-16 h-64 border-b-1">
                        <Typography className="text-16">{t('financial_groups_distribution_hbxdeco_title')}</Typography>
                        {/* <Tooltip title="This chart doesn't change after changing dates">
                            <Icon>cloud_off</Icon>
                        </Tooltip> */}
                    </div>
                    <div className={(showFinancialGroupsLegend ? "h-640" : "h-400") + " w-full p-24 text-center"}>
                    {financialGroupsDonutData ? 
                        <>
                            <DonutChart data={financialGroupsDonutData} />
                            <Button color="primary" onClick={() => setShowFinancialGroupsLegend(!showFinancialGroupsLegend)}>
                                {showFinancialGroupsLegend ? t('HIDELEGEND') : t('SHOWLEGEND')}
                            </Button>
                        </>
                    : 
                        <>
                            <Skeleton className="rounded m-auto" variant="rect" width={'60%'} height={250} />
                            <Skeleton className="mt-12 m-auto" variant="text" width={240} />
                        </>
                    }
                    </div>
                </Paper>             
            </div>
            <div className={`widget flex w-full sm:w-full ${widthClass} p-12`}>
                <Paper className="w-full rounded-8 shadow-none border-1">
                    <div className="flex items-center justify-between px-16 h-64 border-b-1">
                        <Typography className="text-16">Verdeling van marges over doelgroepen </Typography>
                        {/* <Tooltip title="This chart doesn't change after changing dates">
                            <Icon>cloud_off</Icon>
                        </Tooltip> */}
                    </div>
                        <div className={(showFinancialGroupsLegend ? "h-640" : "h-400") + " w-full p-24 text-center"}>
                        {financialGroupsMargesDonutData ? 
                            <>
                                <DonutChart data={financialGroupsMargesDonutData} />
                                <Button color="primary" onClick={() => setShowFinancialGroupsLegend(!showFinancialGroupsLegend)}>
                                    {showFinancialGroupsLegend ? t('HIDELEGEND') : t('SHOWLEGEND')}
                                </Button>
                            </>
                        : 
                            <>
                                <Skeleton className="rounded m-auto" variant="rect" width={'60%'} height={250} />
                                <Skeleton className="mt-12 m-auto" variant="text" width={240} />
                            </>
                        }
                    </div>
                </Paper>
            </div>
            <div className={`widget flex w-full sm:w-full ${widthClass} p-12`}>
                <Paper className="w-full rounded-8 shadow-none border-1">
                    <div className="flex items-center justify-between px-16 h-64 border-b-1">
                        <Typography className="text-16">{t("financial_groups_distribution_hbxdeco_title")} over alle klanten</Typography>
                        {/* <Tooltip title="This chart doesn't change after changing dates">
                            <Icon>cloud_off</Icon>
                        </Tooltip> */}
                    </div>
                        <div className={(showFinancialGroupsLegend ? "h-640" : "h-400") + " w-full p-24 text-center"}>
                        {financialGroupsAllCustomersDonutData ? 
                            <>
                                <DonutChart data={financialGroupsAllCustomersDonutData} />
                                <Button color="primary" onClick={() => setShowFinancialGroupsLegend(!showFinancialGroupsLegend)}>
                                    {showFinancialGroupsLegend ? t('HIDELEGEND') : t('SHOWLEGEND')}
                                </Button>
                            </>
                        : 
                            <>
                                <Skeleton className="rounded m-auto" variant="rect" width={'60%'} height={250} />
                                <Skeleton className="mt-12 m-auto" variant="text" width={240} />
                            </>
                        }
                    </div>
                </Paper>
            </div>
    </div>
    )
}

export default withStyles(null, {withTheme: true})(DashboardDecoCharts);