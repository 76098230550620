import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import { connect } from "react-redux";

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 200,
  });

class SearchCustomerAutoComplete extends React.Component {
    // shouldComponentUpdate(nextProps, _) {
    //     return nextProps.value !== this.props.value || nextProps.searchableCustomers !== this.props.searchableCustomers;
    // }

    render() {
        const props = this.props;
        return (
            <Autocomplete
                filterOptions={filterOptions}
                id="combo-box-account-managers"
                getOptionLabel={props.getOptionLabel}
                getOptionSelected={(option, value) => option.debnr === value.debnr}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    variant="outlined"
                    placeholder="Start met typen..."
                    error={props.error}
                    helperText={props.helperText}
                />
                )}
                options={this.props.searchableCustomers}
                {..._.omit(props, ["error", "helperText", "searchableCustomers", "dispatch"])}

                // options={props.options}
                // value={props.value}
                // onChange={props.onChange}
            />
        );
    }
}

const mapStateToProps = ({ customer }) => {
    return {
        searchableCustomers: customer.allSearchableCustomers,
    }
}

export default connect(mapStateToProps)(SearchCustomerAutoComplete);
