import {updateObject} from '../../utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    tasks: [],
    tasksoverview: [],
    currentTask: null,
    fetchingTaskError: false,
    accountManagers: [],
    creatingTask: false,
    createdTask: false,
    updatingTask: false,
    updatedTask: false,
    concludingTask: false,
    concludedTask: false,
    error: null,
    timeLine: [],
    timeLineLoaded: false,
    addedComment: false,
    setToFalseAlarm: false,
    updatingOrderTask: false, 
    updatedOrderTask: false
}

// Get tasks
const fetchTasksStart = ( state, action ) => {
    return updateObject( state, {loading: true} );
};
const fetchTasksSuccess = ( state, action ) => {
    return updateObject( state, {loading: false, createdTask: false, tasks: action.tasks} );
};
const fetchTasksFail = ( state, action ) => {
    return updateObject( state, {loading: true, error: action.error} );
};

// Get tasks Overview
const fetchTasksOverviewStart = ( state, action ) => {
    return updateObject( state, {loading: true} );
};
const fetchTasksOverviewSuccess = ( state, action ) => {
    return updateObject( state, {loading: false, tasksoverview: action.tasksoverview} );
};
const fetchTasksOverviewFail = ( state, action ) => {
    return updateObject( state, {loading: true, error: action.error} );
};

// Get Single Task
const fetchTaskStart = ( state, action ) => {
    return updateObject( state, {loading: true, currentTask: null, createdTask: false, updatedTask: false, updatedOrderTask: false, setToFalseAlarm: false, addedComment: false, fetchingTaskError: false } );
};
const fetchTaskSuccess = ( state, action ) => {
    return updateObject( state, {loading: false, error: null, currentTask: action.task, fetchingTaskError: false} );
};
const fetchTaskFail = ( state, action ) => {
    return updateObject( state, {loading: true, fetchingTaskError: `Something went wrong. Either you don't have sufficient permissions to view this task or this task is assigned to a different user.`,
     error: `An error occured. This task might be assigned to a different user. If you suspect something went wrong, please inform your manager and notify us.`} );
};

// Get Timeline
const fetchTaskTimeLineStart = ( state, action ) => {
    return updateObject( state, {loading: true} );
};
const fetchTaskTimeLineSuccess = ( state, action ) => {
    return updateObject( state, {loading: false, timeLineLoaded: true, timeLine: action.timeLine} );
};
const fetchTaskTimeLineFail = ( state, action ) => {
    return updateObject( state, {loading: true, error: action.error} );
};

// Get Account mnanager
const fetchAccountManagersStart = ( state, action ) => {
    return updateObject( state, {loading: true} );
};
const fetchAccountManagersSuccess = ( state, action ) => {
    return updateObject( state, {loading: false, accountManagers: action.accountManagers} );
};
const fetchAccountManagersFail = ( state, action ) => {
    return updateObject( state, {loading: true, error: action.error} );
};

// Create task
const createTaskStart = ( state, action ) => {
    return updateObject( state, {creatingTask: true} );
};
const createTaskSuccess = ( state, action ) => {
    return updateObject( state, {error: null, createdTask: action.createdTask} );
};
const createTaskFail = ( state, action ) => {
    return updateObject( state, {error: action.error} );
};
const createTaskReset = ( state, action ) => {
    return updateObject( state, {error: null, createdTask: false} );
};

// Update / save Task
const saveTaskStart = ( state, action ) => {
    return updateObject( state, {updatingTask: true} );
};
const saveTaskSuccess = ( state, action ) => {
    return updateObject( state, {error: null, updatedTask: true} );
};
const saveTaskFail = ( state, action ) => {
    return updateObject( state, {error: action.error} );
};

// Update / save Task
const saveOrderTaskStart = ( state, action ) => {
    return updateObject( state, {error: null, updatingOrderTask: true, updatedOrderTask: false} );
};
const saveOrderTaskSuccess = ( state, action ) => {
    return updateObject( state, {error: null, updatedOrderTask: true, updatingOrderTask: false} );
};
const saveOrderTaskFail = ( state, action ) => {
    return updateObject( state, {error: action.error, updatingOrderTask: false, updatedOrderTask: false} );
};

// Conclude Task
const concludeTaskStart = ( state, action ) => {
    return updateObject( state, {concludingTask: true} );
};
const concludeTaskSuccess = ( state, action ) => {
    return updateObject( state, {error: null, concludedTask: action.concludedTask} );
};
const concludeTaskFail = ( state, action ) => {
    return updateObject( state, {error: action.error} );
};

// False Alarm Task
const setTaskFalseAlarmStart = ( state, action ) => {
    return updateObject( state, {concludingTask: true} );
};
const setTaskFalseAlarmSuccess = ( state, action ) => {
    return updateObject( state, {error: null, setToFalseAlarm: action.setToFalseAlarm} );
};
const setTaskFalseAlarmFail = ( state, action ) => {
    return updateObject( state, {error: action.error} );
};

// Conclude Task
const addCommentStart = ( state, action ) => {
    return updateObject( state, {addedComment: false, addingComment: true} );
};
const addCommentSuccess = ( state, action ) => {
    return updateObject( state, {error: null, addedComment: action.addedComment} );
};
const addCommentFail = ( state, action ) => {
    return updateObject( state, {error: action.error} );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.FETCH_TASKS_START: return fetchTasksStart( state, action );
        case actionTypes.FETCH_TASKS_SUCCESS: return fetchTasksSuccess( state, action );
        case actionTypes.FETCH_TASKS_FAIL: return fetchTasksFail( state, action );

        case actionTypes.FETCH_TASKSOVERVIEW_START: return fetchTasksOverviewStart( state, action );
        case actionTypes.FETCH_TASKSOVERVIEW_SUCCESS: return fetchTasksOverviewSuccess( state, action );
        case actionTypes.FETCH_TASKSOVERVIEW_FAIL: return fetchTasksOverviewFail( state, action );

        case actionTypes.FETCH_TASK_START: return fetchTaskStart( state, action );
        case actionTypes.FETCH_TASK_SUCCESS: return fetchTaskSuccess( state, action );
        case actionTypes.FETCH_TASK_FAIL: return fetchTaskFail( state, action );

        case actionTypes.FETCH_TASK_TIMELINE_START: return fetchTaskTimeLineStart( state, action );
        case actionTypes.FETCH_TASK_TIMELINE_SUCCESS: return fetchTaskTimeLineSuccess( state, action );
        case actionTypes.FETCH_TASK_TIMELINE_FAIL: return fetchTaskTimeLineFail( state, action );

        case actionTypes.FETCH_ACCOUNTMANAGERS_START: return fetchAccountManagersStart( state, action );
        case actionTypes.FETCH_ACCOUNTMANAGERS_SUCCESS: return fetchAccountManagersSuccess( state, action );
        case actionTypes.FETCH_ACCOUNTMANAGERS_FAIL: return fetchAccountManagersFail( state, action );

        case actionTypes.CREATE_TASK_START: return createTaskStart( state, action );
        case actionTypes.CREATE_TASK_SUCCESS: return createTaskSuccess( state, action );
        case actionTypes.CREATE_TASK_FAIL: return createTaskFail( state, action );
        case actionTypes.CREATE_TASK_RESET: return createTaskReset( state, action );

        case actionTypes.SAVE_TASK_START: return saveTaskStart( state, action );
        case actionTypes.SAVE_TASK_SUCCESS: return saveTaskSuccess( state, action );
        case actionTypes.SAVE_TASK_FAIL: return saveTaskFail( state, action );

        case actionTypes.SAVE_ORDERTASK_START: return saveOrderTaskStart( state, action );
        case actionTypes.SAVE_ORDERTASK_SUCCESS: return saveOrderTaskSuccess( state, action );
        case actionTypes.SAVE_ORDERTASK_FAIL: return saveOrderTaskFail( state, action );

        case actionTypes.CONCLUDE_TASK_START: return concludeTaskStart( state, action );
        case actionTypes.CONCLUDE_TASK_SUCCESS: return concludeTaskSuccess( state, action );
        case actionTypes.CONCLUDE_TASK_FAIL: return concludeTaskFail( state, action );

        case actionTypes.SET_TASK_FALSEALARM_START: return setTaskFalseAlarmStart( state, action );
        case actionTypes.SET_TASK_FALSEALARM_SUCCESS: return setTaskFalseAlarmSuccess( state, action );
        case actionTypes.SET_TASK_FALSEALARM_FAIL: return setTaskFalseAlarmFail( state, action );

        case actionTypes.ADD_TASK_COMMENT_START: return addCommentStart( state, action );
        case actionTypes.ADD_TASK_COMMENT_SUCCESS: return addCommentSuccess( state, action );
        case actionTypes.ADD_TASK_COMMENT_FAIL: return addCommentFail( state, action );

        default: return state;
    }
};

export default reducer;