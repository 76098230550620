import {updateObject} from '../../utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    orderTurnover: {},
    turnoverDistribution: [],
    dashboardBlocks: {},
    dashboardTables: {},
    dashboardCharts: {},
    loadingTurnoverDistribution: false,
    verdelingen: null,
}

// OrderTurnover //

const fetchDbOrderTurnoverSuccess = ( state, action ) => {
    return updateObject( state, {orderTurnover: action.orderTurnover} );
};

// TurnoverDistribution //

const fetchDbTurnoverDistributionStart = ( state, action ) => {
    return updateObject( state, {loadingTurnoverDistribution: true} );
};

const fetchDbTurnoverDistributionSuccess = ( state, action ) => {
    return updateObject( state, {turnoverDistribution: action.turnoverDistribution, loadingTurnoverDistribution: false} );
};

// Dashboard Blocks //

const fetchDbDashboardBlocksStart = ( state, action ) => {
    return updateObject( state, {dashboardBlocks: {}} );
};
const fetchDbDashboardBlocksSuccess = ( state, action ) => {
    return updateObject( state, {dashboardBlocks: action.dashboardBlocks} );
};

// Dashboard Tables //

const fetchDbDashboardTablesSuccess = ( state, action ) => {
    return updateObject( state, {dashboardTables: action.dashboardTables} );
};

// Dashboard Piecharts //

const fetchDbDashboardChartsSuccess = ( state, action ) => {
    return updateObject( state, {dashboardCharts: action.dashboardCharts} );
};

const fetchDashboardCreditRedenVerdelingStart = ( state, action ) => {
    return updateObject( state, {loading: true, verdelingen: null} );
};

const fetchDashboardCreditRedenVerdelingSuccess = ( state, action ) => {
    return updateObject( state, {verdelingen: action.verdelingen} );
};

const fetchDashboardCreditRedenVerdelingFail = ( state, action ) => {
    return updateObject( state, {error: action.err} );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.FETCH_ORDER_TURNOVER_SUCCESS: return fetchDbOrderTurnoverSuccess( state, action );

        case actionTypes.FETCH_TURNOVER_DISTRIBUTION_START: return fetchDbTurnoverDistributionStart( state, action );
        case actionTypes.FETCH_TURNOVER_DISTRIBUTION_SUCCESS: return fetchDbTurnoverDistributionSuccess( state, action );

        case actionTypes.FETCH_DASHBOARD_BLOCKS_START: return fetchDbDashboardBlocksStart( state, action );
        case actionTypes.FETCH_DASHBOARD_BLOCKS_SUCCESS: return fetchDbDashboardBlocksSuccess( state, action );

        case actionTypes.FETCH_DASHBOARD_TABLES_SUCCESS: return fetchDbDashboardTablesSuccess( state, action );

        case actionTypes.FETCH_DASHBOARD_CHARTS_SUCCESS: return fetchDbDashboardChartsSuccess( state, action );

        case actionTypes.FETCH_DASHBOARD_CREDIT_REDEN_VERDELING_START: return fetchDashboardCreditRedenVerdelingStart( state, action );
        case actionTypes.FETCH_DASHBOARD_CREDIT_REDEN_VERDELING_SUCCESS: return fetchDashboardCreditRedenVerdelingSuccess( state, action );
        case actionTypes.FETCH_DASHBOARD_CREDIT_REDEN_VERDELING_FAIL: return fetchDashboardCreditRedenVerdelingFail( state, action );

        default: return state;
    }
};

export default reducer;