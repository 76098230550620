import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import fr from './fr';
import de from './de';
import nl from './nl';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // we init with resources
    resources: {
      en,
      nl, 
      de,
      fr,
    },
    lng: localStorage.getItem('i18n'),
    fallbackLng: 'en',
    // debug: true,

    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;