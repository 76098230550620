import {updateObject} from '../../utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    notes: [],
    customer: {},
    allCustomers: [],
    loadingAllCustomers: false,
    allSearchableCustomers: [],
    turnover: {},
    departure: {},
    invoices: [],
    todaysTurnover: 0.00,
    unpaid: 0.00,
    newCustomers: [],
    fetchedNewCustomers: false,
    newLeads: [],
    comments: [],
    weblogVisits: [],
    enalyzerData: {},
    submitted: false,
    TemplatesNewLeads: [],
    AllTemplatesNewLeads: [],
    TransportersNewLeads: [],
    createNewLeadFailed: false,
    isActivated: false,
    MultipleEmailLeads: [],
    // isActivatedIdNewLead: false,
    error: null,
    CountNewleadsBadge: [],
    isDeleted: false,
    updatedLead: false,
    topItems: [],
    loadingTopItems: false,
    viesData: {
        viesIsValid: false,
        viesName: '',
        viesAddress: '',
        btwnrdeb: '',
    },
    user: null,
    updatedPassword: false,
}

// CUSTOMER //
const fetchDbCustomerStart = ( state, action ) => {
    return updateObject( state, {loading: true, error: null} );
};
const fetchDbCustomerSuccess = ( state, action ) => {
    return updateObject( state, {loading: false, error: null, customer: action.customer} );
};
const fetchDbCustomerFail = ( state, action ) => {
    return updateObject( state, {loading: false, error: action.error, customer: {}} );
};

// ALL SEARCH CUSTOMERS //
const fetchDbAllSearchCustomersSuccess = ( state, action ) => {
    return updateObject( state, {allSearchableCustomers: action.allSearchableCustomers} );
};

// ALL CUSTOMERS //
const fetchDbAllCustomersStart = ( state, action ) => {
    return updateObject( state, { allCustomers: [], loadingAllCustomers: true } );
};
const fetchDbAllCustomersSuccess = ( state, action ) => {
    return updateObject( state, { allCustomers: action.allCustomers, loadingAllCustomers: false } );
};
const fetchDbAllCustomersFail = ( state, action ) => {
    return updateObject( state, { error: action.error, loadingAllCustomers: false } );
};

// TURNOVER //
const fetchDbCustomerTurnoverSuccess = ( state, action ) => {
    return updateObject( state, {turnover: action.turnover} );
};

// DEPARTURE //
const fetchDbCustomerDepartureSuccess = ( state, action ) => {
    return updateObject( state, {departure: action.departure} );
};

// Invoices //
const fetchDbCustomerInvoicesSuccess = ( state, action ) => {
    return updateObject( state, {invoices: action.invoices} );
};

// Todays Turnover //
const fetchDbCustomerTodaysTurnoverSuccess = ( state, action ) => {
    return updateObject( state, {todaysTurnover: action.todaysTurnover} );
};

// Unpaid //
const fetchDbCustomerUnpaidSuccess = ( state, action ) => {
    return updateObject( state, {unpaid: action.unpaid} );
};

// NewCustomers //
const fetchDbNewCustomersStart = ( state, action ) => {
    return updateObject( state, {fetchedNewCustomers: false} );
};
const fetchDbNewCustomersSuccess = ( state, action ) => {
    return updateObject( state, {newCustomers: action.newCustomers, fetchedNewCustomers: true} );
};

const activateNewCustomerSuccess = ( state, action ) => {
    return updateObject( state, {isActivated: action.isActivated} );
};

// NewLeads //
const fetchDbNewLeadsSuccess = ( state, action ) => {
    return updateObject( state, {newLeads: action.newLeads, fetchedNewLeads: true} );
};

const fetchMultipleEmailDbNewLeadsSuccess = ( state, action ) => {
    return updateObject( state, {MultipleEmailLeads: action.MultipleEmailLeads} );
};

const fetchMultipleVatDbNewLeadsSuccess = ( state, action ) => {
    return updateObject( state, {MultipleVatLeads: action.MultipleVatLeads} );
};


// Edit Newlead
const editNewLeadSuccess = ( state, action ) => {
    return updateObject( state, {updatedLead: action.updatedLead} );
};
const editNewLeadFail = ( state, action ) => {
    return updateObject( state, {error: action.error} );
};
// Check VIES VAT
const checkViesSuccess = ( state, action ) => {
    return updateObject( state, {viesData: action.viesData} );
};
// Delete Newlead
const deleteNewLeadSuccess = ( state, action ) => {
    return updateObject( state, {isDeleteNewLead: action.isDeleteNewLead, isDeleted : action.isDeleteNewLead} );
};
// create Newlead
const createNewLeadSuccess = ( state, action ) => {
    return updateObject( state, {isActivated: action.isActivated, createNewLeadFailed: false} );
};
const createNewLeadFail = ( state, action ) => {
    return updateObject( state, {createNewLeadFailed: true, error: action.error} );
};
// validate Newlead
const validateNewLeadSuccess = ( state, action ) => {
    return updateObject( state, {isActivated: action.isActivated} );
};

// Get Templates New Leads //
const FetchdbTemplatesNewLeadsSuccess = ( state, action ) => {
    return updateObject( state, {TemplatesNewLeads: action.TemplatesNewLeads, fetchedTemplatesNewLeads: true} );
};
const FetchAllDebTypeSuccess = ( state, action ) => {
    return updateObject( state, {DebType: action.DebType} );
};

// Get All Templates New Leads //
const FetchdbAllTemplatesNewLeadsSuccess = ( state, action ) => {
    return updateObject( state, {AllTemplatesNewLeads: action.AllTemplatesNewLeads, fetchedAllTemplatesNewLeads: true} );
};

// Get Transporter New Leads //
// const FetchdbTransportersNewLeadsSuccess = ( state, action ) => {
//     return updateObject( state, {TransportersNewLeads: action.TransportersNewLeads, fetchedTransportersNewLeads: true} );
// };

// Delete Fav Tempaltes //
const deleteFaviCRMUserSuccess = ( state, action ) => {
    return updateObject( state, {favisDeleted: action.favisDeleted} );
};
// Add Fav Tempaltes //
const addFaviCRMUserSuccess = ( state, action ) => {
    return updateObject( state, {favisAdded: action.favisAdded} );
};
// Get Count New Leads Badge //
const FetchGetNavCountNewleadsSuccess = ( state, action ) => {
    return updateObject( state, {CountNewleadsBadge: action.CountNewleadsBadge} );
};

// WeblogVisits //
const fetchCustomerWeblogVisitsSuccess = ( state, {weblogVisits} ) => {
    return updateObject( state, {weblogVisits} );
};

// Enalyzer //
const fetchOrderDataForEnalyzerSuccess = ( state, {orderData} ) => {
    return updateObject( state, {enalyzerData: orderData} );
};

// Comments //
const fetchDbCustomerCommentsSuccess = ( state, action ) => {
    return updateObject( state, {comments: action.comments} );
};

// Submit Comments //
const submitDbCustomerCommentSuccess = ( state, action ) => {
    return updateObject( state, {submitted: action.submitted} );
};

const submitOpenOrCloseCustomer = ( state, action ) => {
    return updateObject( state, { customer: {...state.customer, levStop: action.levStop}})
}

// Get notes inbox //
const fetchNotesInboxSuccess = ( state, action ) => {
    return updateObject( state, {notes: action.notes} );
};

// Customer Top 10 items
const fetchTopItemsByCustomerStart = ( state, action ) => {
    return updateObject( state, {loadingTopItems: true});
};
const fetchTopItemsByCustomerSuccess = ( state, action ) => {
    return updateObject( state, {topItems: action.topItems, loadingTopItems: false} );
};
const fetchTopItemsByCustomerFail = ( state, action ) => {
    return updateObject( state, {loadingTopItems: false} );
};

// User //
const fetchUserSuccess = ( state, action ) => {
    return updateObject( state, {user: action.user, fetchedNewLeads: true} );
};

// Update Password
const updateUserPasswordSuccess = ( state, action ) => {
    return updateObject( state, {updatedPassword: true} );
};
const updateUserPasswordFail = ( state, action ) => {
    return updateObject( state, {error: action.error, updatedPassword: false} );
};
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.FETCH_CUSTOMER_TOP_ITEMS_START: return fetchTopItemsByCustomerStart( state, action );
        case actionTypes.FETCH_CUSTOMER_TOP_ITEMS_SUCCESS: return fetchTopItemsByCustomerSuccess( state, action );
        case actionTypes.FETCH_CUSTOMER_TOP_ITEMS_FAIL: return fetchTopItemsByCustomerFail( state, action );

        case actionTypes.FETCH_NOTES_SUCCESS: return fetchNotesInboxSuccess( state, action );

        case actionTypes.FETCH_CUSTOMER_START: return fetchDbCustomerStart( state, action );
        case actionTypes.FETCH_CUSTOMER_SUCCESS: return fetchDbCustomerSuccess( state, action );
        case actionTypes.FETCH_CUSTOMER_FAIL: return fetchDbCustomerFail( state, action );

        case actionTypes.FETCH_ALL_SEARCHCUSTOMERS_SUCCESS: return fetchDbAllSearchCustomersSuccess( state, action );

        case actionTypes.FETCH_ALL_CUSTOMERS_START: return fetchDbAllCustomersStart( state, action );
        case actionTypes.FETCH_ALL_CUSTOMERS_SUCCESS: return fetchDbAllCustomersSuccess( state, action );
        case actionTypes.FETCH_ALL_CUSTOMERS_FAIL: return fetchDbAllCustomersFail( state, action );

        case actionTypes.FETCH_CUSTOMER_TURNOVER_SUCCESS: return fetchDbCustomerTurnoverSuccess( state, action );

        case actionTypes.FETCH_CUSTOMER_DEPARTURE_SUCCESS: return fetchDbCustomerDepartureSuccess( state, action );

        case actionTypes.FETCH_CUSTOMER_INVOICES_SUCCESS: return fetchDbCustomerInvoicesSuccess( state, action );

        case actionTypes.FETCH_CUSTOMER_TODAYS_TURNOVER_SUCCESS: return fetchDbCustomerTodaysTurnoverSuccess( state, action );

        case actionTypes.FETCH_CUSTOMER_UNPAID_SUCCESS: return fetchDbCustomerUnpaidSuccess( state, action );

        case actionTypes.FETCH_NEWCUSTOMERS_START: return fetchDbNewCustomersStart( state, action );
        case actionTypes.FETCH_NEWCUSTOMERS_SUCCESS: return fetchDbNewCustomersSuccess( state, action );

        case actionTypes.ACTIVATE_NEW_CUSTOMER_SUCCESS: return activateNewCustomerSuccess( state, action );

        case actionTypes.FETCH_CUSTOMER_WEBLOGVISITS_SUCCESS: return fetchCustomerWeblogVisitsSuccess( state, action );

        case actionTypes.FETCH_ORDERDATA_FOR_ENALYZER_SUCCESS: return fetchOrderDataForEnalyzerSuccess( state, action );

        case actionTypes.FETCH_CUSTOMER_COMMENTS_SUCCESS: return fetchDbCustomerCommentsSuccess( state, action );

        case actionTypes.SUBMIT_CUSTOMER_COMMENT_SUCCESS: return submitDbCustomerCommentSuccess( state, action );

        case actionTypes.SUBMIT_OPEN_OR_CLOSE_SUCCESS: return submitOpenOrCloseCustomer( state, action );
        
        case actionTypes.FETCH_NEWLEADS_SUCCESS: return fetchDbNewLeadsSuccess( state, action );
        case actionTypes.EDIT_NEWLEAD_SUCCESS: return editNewLeadSuccess( state, action );
        case actionTypes.EDIT_NEWLEAD_FAIL: return editNewLeadFail( state, action );
        case actionTypes.CHECK_VIES_SUCCESS: return checkViesSuccess( state, action );
        case actionTypes.DELETE_NEWLEAD_SUCCESS: return deleteNewLeadSuccess( state, action );
        case actionTypes.CREATE_NEWLEAD_SUCCESS: return createNewLeadSuccess( state, action );
        case actionTypes.CREATE_NEWLEAD_FAIL: return createNewLeadFail( state, action );
        case actionTypes.VALIDATE_NEWLEAD_SUCCESS: return validateNewLeadSuccess( state, action );
        case actionTypes.FETCH_TEMPLATES_TRANSPORTERS_NEWLEADS_SUCCESS: return FetchdbTemplatesNewLeadsSuccess( state, action );
        case actionTypes.FETCH_ALL_TEMPLATES_TRANSPORTERS_NEWLEADS_SUCCESS: return FetchdbAllTemplatesNewLeadsSuccess( state, action );
        // case actionTypes.FETCH_TRANSPORTERS_NEWLEADS_SUCCESS: return FetchdbTransportersNewLeadsSuccess( state, action );
        case actionTypes.DELETE_FAV_USERS_ASSIGNNEWLEADS_SUCCESS: return deleteFaviCRMUserSuccess( state, action );
        case actionTypes.ADD_FAV_USERS_ASSIGNNEWLEADS_SUCCESS: return addFaviCRMUserSuccess( state, action );
        case actionTypes.FETCH_NAV_COUNT_NEWLEADS_SUCCESS: return FetchGetNavCountNewleadsSuccess( state, action );

        case actionTypes.FETCH_USER_SUCCESS: return fetchUserSuccess( state, action );
        case actionTypes.EDIT_USER_PASSWORD_SUCCESS: return updateUserPasswordSuccess( state, action );
        case actionTypes.EDIT_USER_PASSWORD_FAIL: return updateUserPasswordFail( state, action );

        case actionTypes.FETCH_MULTIPLE_EMAIL_NEWLEADS_SUCCESS: return fetchMultipleEmailDbNewLeadsSuccess( state, action );
        case actionTypes.FETCH_MULTIPLE_VAT_NEWLEADS_SUCCESS: return fetchMultipleVatDbNewLeadsSuccess( state, action );
        case actionTypes.FETCH_ALL_DEBTYPE_SUCCESS: return FetchAllDebTypeSuccess( state, action );
        

        default: return state;
    }
};

export default reducer;