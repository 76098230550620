import {updateObject} from '../../utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    specialOffers: [],
    specialOffersProducts: [],
    products: [],
    productsError: null,
    productsLoading: false,
    users: [],
    log: [],
    fetchedUsers: false,
    insertedSpecialOffer: false,
    deletedSpecialOffer: false,
    editedSpecialOffer: false,
    changedUserAccess: false,
    statusUpdate: null,
}

const fetchSpecialOffersSuccess = ( state, action ) => {
    return updateObject( state, {specialOffers: action.specialOffers} );
};

// With Products
const fetchSpecialOffersProductsStart = ( state, action ) => {
    return updateObject( state, {specialOffersProducts: []} );
};
const fetchSpecialOffersProductsSuccess = ( state, action ) => {
    return updateObject( state, {specialOffersProducts: action.specialOffersProducts} );
};

// Get Products
const fetchProductsBySpecialOfferStart = ( state, action ) => {
    return updateObject( state, {products: [], productsLoading: true} );
};
const fetchProductsBySpecialOfferSuccess = ( state, action ) => {
    return updateObject( state, {products: action.products, productsLoading: false} );
};
const fetchProductsBySpecialOfferFail = ( state, action ) => {
    return updateObject( state, {productsError: action.errorMessage, productsLoading: false} );
};

// Get Users
const fetchUsersBySpecialOfferStart = ( state, action ) => {
    return updateObject( state, {users: [], fetchedUsers: false} );
};

const fetchUsersBySpecialOfferSuccess = ( state, action ) => {
    return updateObject( state, {users: action.users, fetchedUsers: true} );
};

// Get Users
const fetchSpecialOfferLogStart = ( state, action ) => {
    return updateObject( state, {loading: true} );
};

const fetchSpecialOfferLogSuccess = ( state, action ) => {
    return updateObject( state, {loading: false, log: action.log} );
};

// Add Special Offer
const insertSpecialOfferSuccess = ( state, action ) => {
    return updateObject( state, {insertedSpecialOffer: true} );
};

// Delete Special Offer
const deleteSpecialOfferSuccess = ( state, action ) => {
    return updateObject( state, {deletedSpecialOffer: true} );
};

// Edit Special Offer
const editSpecialOfferSuccess = ( state, action ) => {
    return updateObject( state, {editedSpecialOffer: true} );
};

// Edit Special Offer
const changeUserAccessSuccess = ( state, action ) => {
    return updateObject( state, {changedUserAccess: true} );
};


const fetchLatestSIBStatusUpdateStart = ( state, action ) => {
    return updateObject( state, {loading: true} );
};

const fetchLatestSIBStatusUpdateSuccess = ( state, action ) => {
    return updateObject( state, {loading: false, statusUpdate: action.statusUpdate} );
};
const fetchLatestSIBStatusUpdateFail = ( state, action ) => {
    return updateObject( state, {loading: false, error: action.error } );
};

// Close Snackbars
const closeInsertSnackbar = ( state, action ) => {
    return updateObject( state, {insertedSpecialOffer: false} );
};
const closeDeleteSnackbar = ( state, action ) => {
    return updateObject( state, {deletedSpecialOffer: false} );
};
const closeEditSnackbar = ( state, action ) => {
    return updateObject( state, {editedSpecialOffer: false} );
};
const closeUserAccessSnackbar = ( state, action ) => {
    return updateObject( state, {changedUserAccess: false} );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.FETCH_SPECIALOFFERS_SUCCESS: return fetchSpecialOffersSuccess( state, action );

        case actionTypes.FETCH_SPECIALOFFERS_PRODUCTS_START: return fetchSpecialOffersProductsStart( state, action );
        case actionTypes.FETCH_SPECIALOFFERS_PRODUCTS_SUCCESS: return fetchSpecialOffersProductsSuccess( state, action );

        case actionTypes.FETCH_PRODUCTS_BY_SPECIALOFFER_START: return fetchProductsBySpecialOfferStart( state, action );
        case actionTypes.FETCH_PRODUCTS_BY_SPECIALOFFER_SUCCESS: return fetchProductsBySpecialOfferSuccess( state, action );
        case actionTypes.FETCH_PRODUCTS_BY_SPECIALOFFER_FAIL: return fetchProductsBySpecialOfferFail( state, action );

        case actionTypes.FETCH_USERS_BY_SPECIALOFFER_START: return fetchUsersBySpecialOfferStart( state, action );
        case actionTypes.FETCH_USERS_BY_SPECIALOFFER_SUCCESS: return fetchUsersBySpecialOfferSuccess( state, action );

        case actionTypes.FETCH_SPECIALOFFER_LOG_START: return fetchSpecialOfferLogStart( state, action );
        case actionTypes.FETCH_SPECIALOFFER_LOG_SUCCESS: return fetchSpecialOfferLogSuccess( state, action );

        case actionTypes.INSERT_SEPCIALOFFER_SUCCESS: return insertSpecialOfferSuccess( state, action );

        case actionTypes.DELETE_SEPCIALOFFER_SUCCESS: return deleteSpecialOfferSuccess( state, action );

        case actionTypes.EDIT_SEPCIALOFFER_SUCCESS: return editSpecialOfferSuccess( state, action );

        case actionTypes.CHANGE_USERACCESS_SUCCESS: return changeUserAccessSuccess( state, action );
        
        case actionTypes.FETCH_LATEST_SIB_STATUS_UPDATE_START: return fetchLatestSIBStatusUpdateStart( state, action );
        case actionTypes.FETCH_LATEST_SIB_STATUS_UPDATE_SUCCESS: return fetchLatestSIBStatusUpdateSuccess( state, action );
        case actionTypes.FETCH_LATEST_SIB_STATUS_UPDATE_FAIL: return fetchLatestSIBStatusUpdateFail( state, action );

        case actionTypes.CLOSE_INSERT_SNACKBAR: return closeInsertSnackbar( state, action );
        case actionTypes.CLOSE_DELETE_SNACKBAR: return closeDeleteSnackbar( state, action );
        case actionTypes.CLOSE_EDIT_SNACKBAR: return closeEditSnackbar( state, action );
        case actionTypes.CLOSE_USERACCESS_SNACKBAR: return closeUserAccessSnackbar( state, action );

        default: return state;
    }
};

export default reducer;