import React, { Component } from 'react';
import {FusePageSimple, FuseAnimateGroup} from '../../../../../@fuse'
import {Icon, Typography} from '@material-ui/core';
import {connect} from 'react-redux';
import {fetchCallListBlock1, fetchCallListBlock2, fetchCallListBlock3, fetchCallListBlock4, fetchCallListBlock6} from '../../../../store/crm/actions';
import {withTranslation} from 'react-i18next';
import {CallListBlock1, CallListInvoiceBlock, CallListBlock6} from './CallListBlocks';
import './CallList.css';
import Skeleton from './CallListBlocks/CallListSkeleton'

class CallList extends Component {
    state = {
        block1: false,
        block2: false,
        block3: false,
        block4: false,
        block5: false,
        block6: false,
    }

    componentDidMount() {
        this.props.onFetchBlock1();
        this.props.onFetchBlock2();
        this.props.onFetchBlock3();
        this.props.onFetchBlock4();
        this.props.onFetchBlock6();
    }

    handleShowAllClick = name => {
        this.setState({[name]: !this.state[name]});
    }

    render() {
        const {t, block1, block2, block3, block4, block6} = this.props;
        return (
            <FusePageSimple
                header={
                    <div className="flex flex-1 items-center justify-between p-24">
                        <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                <Icon className="text-18" color="action">home</Icon>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">iCRM</Typography>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">{t("CALLLIST")}</Typography>
                            </div>
                            <Typography variant="h6">{t("CALLLIST")}</Typography>
                        </div>
                    </div>
                }
                content={
                    <FuseAnimateGroup
                    enter={{
                        animation: "transition.slideUpBigIn"
                    }}>
                        <div className="flex flex-wrap p-12">
                            <div className="w-full md:w-1/2 p-12">
                                {block1.loading ? 
                                    <Skeleton /> : 
                                    <CallListBlock1 data={block1} onShowAllClick={this.handleShowAllClick} showAll={this.state.block1}
                                    title={t("BLOCK1")} />
                                }
                            </div>
                            <div className="w-full md:w-1/2 p-12">
                                {block2.loading ? 
                                    <Skeleton /> : 
                                    <CallListInvoiceBlock data={block2} onShowAllClick={this.handleShowAllClick} block="block2" showAll={this.state.block2}
                                        title={t("BLOCK2")} /> 
                                }
                            </div>
                            <div className="w-full md:w-1/2 p-12">
                                {block3.loading ? 
                                    <Skeleton /> : 
                                    <CallListInvoiceBlock data={block3} onShowAllClick={this.handleShowAllClick} block="block3" showAll={this.state.block3}
                                        title={t("BLOCK3")} /> 
                                }
                            </div>
                            <div className="w-full md:w-1/2 p-12">
                                {block4.loading ? 
                                    <Skeleton /> : 
                                    <CallListInvoiceBlock data={block4} onShowAllClick={this.handleShowAllClick} block="block4" showAll={this.state.block4}
                                        title={t("BLOCK4")} /> 
                                }
                            </div>
                            {/* <div className="w-full md:w-1/2 p-12">
                                <CallListBlock5 data={block5} onShowAllClick={this.handleShowAllClick} 
                                    amountOfRows={this.state.block5} /> 
                            </div> */}
                            <div className="w-full md:w-1/2 p-12">
                                {block6.loading ? 
                                    <Skeleton /> : 
                                    <CallListBlock6 data={block6} onShowAllClick={this.handleShowAllClick} showAll={this.state.block6}
                                        title={t("BLOCK6")} /> 
                                }
                            </div>
                        </div>
                    </FuseAnimateGroup>
                }
            />
        );
    }
}

const mapStateToProps = ({callList}) => {
    return {
        block1: callList.block1,
        block2: callList.block2,
        block3: callList.block3,
        block4: callList.block4,
        block6: callList.block6,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchBlock1: () => dispatch(fetchCallListBlock1()),
        onFetchBlock2: () => dispatch(fetchCallListBlock2()),
        onFetchBlock3: () => dispatch(fetchCallListBlock3()),
        onFetchBlock4: () => dispatch(fetchCallListBlock4()),
        onFetchBlock6: () => dispatch(fetchCallListBlock6()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CallList));