import {updateObject} from '../../utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    turnover: [],
}

// Turnover //

const fetchDbTurnoverSuccess = ( state, action ) => {
    return updateObject( state, {turnover: action.turnover} );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.FETCH_TURNOVER_SUCCESS: return fetchDbTurnoverSuccess( state, action );

        default: return state;
    }
};

export default reducer;