import {updateObject} from '../../utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loadingSubcode: false,
    loading: false,
    subcode: {},
    savedSubcode: false,
    error: null,
}

const getSubCodeStart = ( state, action ) => {
    return updateObject( state, {
        loadingSubcode: true,
        subcode: {}
    });
};

const getSubCodeSuccess = ( state, action ) => {
    return updateObject( state, {
        subcode: action.subcode,
        loadingSubcode: false,
        error: null
    });
};

const getSubCodeFail = ( state, action ) => {
    return updateObject( state, {
        loadingSubcode: false,
        error: action.err
    });
};


const saveWholesaleCustomerStart = ( state, action ) => {
    return updateObject( state, {
        loading: true,
        savedSubcode: false,
        error: null
    });
};

const saveWholesaleCustomerSuccess = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        savedSubcode: action.saved,
        error: null
    });
};

const saveWholesaleCustomerFail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        savedSubcode: false,
        error: action.err
    });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.GET_SUBCODE_START: return getSubCodeStart( state, action );
        case actionTypes.GET_SUBCODE_SUCCESS: return getSubCodeSuccess( state, action );
        case actionTypes.GET_SUBCODE_FAIL: return getSubCodeFail( state, action );

        case actionTypes.SAVE_WHOLESALE_CUSTOMER_START: return saveWholesaleCustomerStart( state, action );
        case actionTypes.SAVE_WHOLESALE_CUSTOMER_SUCCESS: return saveWholesaleCustomerSuccess( state, action );
        case actionTypes.SAVE_WHOLESALE_CUSTOMER_FAIL: return saveWholesaleCustomerFail( state, action );

        default: return state;
    }
};

export default reducer;