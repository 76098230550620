export const FETCH_PAGE_STATS_START = 'FETCH_PAGE_STATS_START';
export const FETCH_PAGE_STATS_SUCCESS = 'FETCH_PAGE_STATS_SUCCESS';
export const FETCH_PAGE_STATS_FAIL = 'FETCH_PAGE_STATS_FAIL';

export const FETCH_USER_LOGS_BY_PAGE_START = 'FETCH_USER_LOGS_BY_PAGE_START';
export const FETCH_USER_LOGS_BY_PAGE_SUCCESS = 'FETCH_USER_LOGS_BY_PAGE_SUCCESS';
export const FETCH_USER_LOGS_BY_PAGE_FAIL = 'FETCH_USER_LOGS_BY_PAGE_FAIL';

export const FETCH_USER_STATS_START = 'FETCH_USER_STATS_START';
export const FETCH_USER_STATS_SUCCESS = 'FETCH_USER_STATS_SUCCESS';
export const FETCH_USER_STATS_FAIL = 'FETCH_USER_STATS_FAIL';
