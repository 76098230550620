import * as actionTypes from './actionTypes';
import axios from 'axios'

//Overview Data
export const fetchDbOverviewSuccess = (dbOverview) => {
    return {
        type: actionTypes.FETCH_OVERVIEW_SUCCESS,
        overview: dbOverview
    };
};

export const fetchDbOverview = () => {
    return dispatch => {
        axios.get('overview/getoverview')
            .then(res => {
                dispatch(fetchDbOverviewSuccess(res.data));
            })
    };
};

//Pastyear Overview Data
export const fetchDbPastYearOverviewSuccess = (dbPastYearOverview) => {
    return {
        type: actionTypes.FETCH_PASTYEAR_OVERVIEW_SUCCESS,
        pastYearOverview: dbPastYearOverview
    };
};

export const fetchDbPastYearOverview = () => {
    return dispatch => {
        axios.get('overview/getpastyearoverview')
            .then(res => {
                dispatch(fetchDbPastYearOverviewSuccess(res.data));
            })
    };
};

// Download Excel New and Lost customers
export const DownloadNewNLostCustomers = (data) => {
    return dispatch => {
        axios.post('overview/downloadnewnlostcustomers', data, {responseType: 'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `New and lost customers.xls`); //or any other extension
                // link.setAttribute('download', `AdHoc-Export-${moment(new Date()).format('YYYY-MM-DD')}.xls`); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    };
}
