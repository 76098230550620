import React from 'react';
import {Link} from 'react-router-dom'
import {fuseDark} from '../../../@fuse/fuse-colors'
import axios from 'axios';
import { getAzureId } from '../../main/Auth/Auth';

const createUserLog = (debnr) => {
    const isMobile = /Mobi/.test(navigator.userAgent);
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(navigator.userAgent);
    const userLog = {
        azureId: getAzureId(),
        type: 'button',
        actionName: 'ClickedDebnr',
        extraInfo: 'DEBNR : ' + debnr
    }
    axios.post('monitor/createuserlog', {...userLog, device: isTablet ? 'tablet' : (isMobile ? 'mobile' : 'desktop')})
    .then(res => res)
    .catch(err => console.log(err))
}

const routeToDebnr = (props) => {
    const {row} = props;

    return (
        <Link onClick={() => createUserLog(row.value)} style={{cursor: 'pointer'}} to={`/icrm/customer/${row.value}`}>{row.value}</Link>
    );
}

export const routeToWholesaleDebnr = ({row}) => {
    return (
        <Link onClick={() => createUserLog(row.value)} style={{cursor: 'pointer'}} to={`/wholesale/customer/${row.value}`}>{row.value}</Link>
    );
}

export const LinkToDebnr = (props) => <Link onClick={() => createUserLog(props.debnr)} style={{cursor: 'pointer', color: fuseDark[600]}} to={`/icrm/customer/${props.debnr}`}>{props.debnr}</Link>
export const LinkToDebnrColoredLink = (props) => <Link onClick={() => createUserLog(props.debnr)} style={{cursor: 'pointer'}} to={`/icrm/customer/${props.debnr}`}>{props.debnr}</Link>
export const LinkToDebnrWithChildren = (props) => <Link onClick={() => createUserLog(props.debnr)} style={{cursor: 'pointer', color: fuseDark[600]}} to={`/icrm/customer/${props.debnr}`} target="_blank">{props.children}</Link>
export default routeToDebnr;