import React from 'react';
import { Icon, Typography } from '@material-ui/core';

const DefaultFuseHeader = (props) => {
    return (
        <div className="flex flex-1 items-center justify-between p-24">
        <div className="flex flex-col">
            <div className="flex items-center mb-16">
                    <Icon className="text-18" color="action">home</Icon>
                    <Icon className="text-16" color="action">chevron_right</Icon>
                    <Typography color="textSecondary">iCRM</Typography>
                    {props.breadCrumbs.map((breadCrumb, index) => (
                        <React.Fragment key={index}>
                            <Icon className="text-16" color="action">chevron_right</Icon>
                            {breadCrumb}
                        </React.Fragment>
                    ))}
            </div>
            <Typography variant="h6">{props.title}</Typography>
            {props.subtitle}
        </div>
        <div className='flex items-end'>
            {props.itemsEnd}
        </div>
    </div>
    )
}

export default DefaultFuseHeader;