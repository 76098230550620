import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import _ from "lodash";

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 200,
  });

class TaskDetailAutoComplete extends React.Component {
    shouldComponentUpdate(nextProps, _) {
        return nextProps.value !== this.props.value || nextProps.options !== this.props.options;
    }

    render() {
        const props = this.props;
        return (
            <Autocomplete
                filterOptions={filterOptions}
                id="combo-box-account-managers"
                getOptionLabel={props.getOptionLabel}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    variant="outlined"
                    placeholder="Start met typen..."
                    error={props.error}
                    helperText={props.helperText}
                />
                )}
                {..._.omit(props, ["error", "helperText"])}

                // options={props.options}
                // value={props.value}
                // onChange={props.onChange}
            />
        );
    }
}

export default TaskDetailAutoComplete;
