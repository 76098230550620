import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getSubCodeStart = () => {
    return {
        type: actionTypes.GET_SUBCODE_START,
    };
};

export const getSubCodeSuccess = (subcode) => {
    return {
        type: actionTypes.GET_SUBCODE_SUCCESS,
        subcode
    };
};

export const getSubCodeFail = (err) => {
    return {
        type: actionTypes.GET_SUBCODE_FAIL,
        err
    };
};

export const getSubCode = (subcode) => {
    return dispatch => {
        dispatch(getSubCodeStart());
        axios.get('wholesale/getsubcode/' + subcode)
            .then(res => {
                dispatch(getSubCodeSuccess(res.data));
            })
            .catch(err => {
                dispatch(getSubCodeFail(err.status));
            })
    };
};

export const saveWholesaleCustomerStart = () => {
    return {
        type: actionTypes.SAVE_WHOLESALE_CUSTOMER_START,
    };
};

export const saveWholesaleCustomerSuccess = (saved) => {
    return {
        type: actionTypes.SAVE_WHOLESALE_CUSTOMER_SUCCESS,
        saved
    };
};

export const saveWholesaleCustomerFail = (err) => {
    return {
        type: actionTypes.SAVE_WHOLESALE_CUSTOMER_FAIL,
        err
    };
};

export const saveWholesaleCustomer = (subcode) => {
    return dispatch => {
        dispatch(saveWholesaleCustomerStart());
        axios.post('wholesale/savesubcode', subcode)
            .then(res => {
                dispatch(getSubCode(subcode.debnr));
                dispatch(saveWholesaleCustomerSuccess(res.data));
            })
            .catch(err => {
                dispatch(saveWholesaleCustomerFail(err.status));
            })
    };
};