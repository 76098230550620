import React, { Component } from 'react';
import {withStyles} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import {FusePageSimple, FuseAnimateGroup} from '../../../../@fuse';
import {Icon, Typography, Tooltip, Card, CardContent, CardHeader, Button } from '@material-ui/core';
import {connect} from 'react-redux';
import * as actions from '../../../store/api/actions';

const styles = theme => ({
    layoutRoot: {},
    datePicker: {
        // minWidth: '155px',
        width: '100%',
    },
    floatRight: {
        float: 'right',
        marginLeft: '4px',
    },
    cardHeader: {
        backgroundColor: '#f6f8f8',
        borderBottom: '1px solid',
        borderColor: '#dee5e7 !important;'
    },
    successButton: {
        color: '#27c24c !important'
    },
});

class FlowerPlace extends Component {

    state = {
        // fromdate: new Date().toISOString().slice(0, 10),
        // todate: new Date().toISOString().slice(0, 10),
        startLoading: false,
        finishedLoading: false,
        percentage: 0,
        // fromdate: new Date(),
        // fromdate: moment(this.getMonday(fromdate)).format('YYYY-MM-DD'),
        // todate: moment(new Date()).format('YYYY-MM-DD'),
    }

    // componentDidMount() {
    //     this.handleDateButtonClick('yesterday');
    //     // if(this.props.generatedAdHocExport.length === 0) {
    //     //     this.props.onGenerateExport(this.state.fromdate, this.state.todate);
    //     // }
    // }
    
    // handleFromDate = event => {
    //     this.setState({fromdate: event.target.value})
    // }
    
    // handleToDate = event => {
    //     this.setState({todate: event.target.value})
    // }

    // handleGenerateClick = () => {
    //     this.setState({finishedLoading: false, startLoading: true, percentage: 0});
    //     this.props.onGenerateExport(this.state.fromdate, this.state.todate);
    // }

    finishedLoading = () => {
        this.setState({finishedLoading: true, startLoading: false, percentage: 100});
    }

    // downloadExcel = () => {
    //     this.props.onDownloadExcel(this.props.generatedAdHocExport);
    // }
    
    onDownloadFlowerPlaceExcel = () => {
        this.props.onDownloadFlowerPlaceExportTable();
    }
    
    // applyDates = () => {
    //     // const momentFormatted = moment(this.state.startDate).format('YYYY-MM-DD');
    //     // console.log(momentFormatted);
    //     const startDate = moment(this.state.startDate).format('YYYY-MM-DD');
    //     const endDate = moment(this.state.endDate).format('YYYY-MM-DD');
    //     this.setState({fromdate: startDate, todate: endDate})
    // }


    render() {
        const {classes} = this.props;
        // console.log(" started : " + this.state.startLoading);
        // console.log(" finished : " + this.state.finishedLoading);
        return (
            <FusePageSimple
                classes={{
                    root: classes.layoutRoot
                }}
                header={
                      <div className="flex flex-1 items-center justify-between p-24">
                         <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                  <Icon className="text-18" color="action">home</Icon>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">API's</Typography>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">Flower Place</Typography>
                            </div>
                            <Typography variant="h6">Florisoft to Flower Place</Typography>
                         </div>
                      </div>
                }
                content={
                        <div>
                            <FuseAnimateGroup
                                className="flex flex-wrap"
                                enter={{
                                    animation: "transition.slideUpBigIn"
                                }}
                            >

                            <div className="flex flex-wrap w-full p-12">
                                <div className="flex w-full p-12 md:w-1/3">
                                    <Card className="w-full">
                                        <CardHeader 
                                        className={classes.cardHeader}
                                        title={
                                            <>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Export Bestel Lijst FPUKK
                                            </Typography>
                                            </>
                                        } />
                                        <CardContent>
                                            <div className="w-full flex flex-wrap">
                                                <div className="w-full p-6">                                            
                                                <Tooltip title="Download Flower place Excel">
                                                    <Button 
                                                        variant="contained"
                                                        color="secondary"
                                                        // className="w-full"
                                                        fullWidth
                                                        startIcon={<Icon>save_alt</Icon>}
                                                        onClick={this.onDownloadFlowerPlaceExcel}>
                                                        DOWNLOAD .CSV
                                                    </Button>
                                                </Tooltip>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                                </FuseAnimateGroup>
                            </div>
                }
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        // loadingGeneratedAdHocExport : state.api.loadingGeneratedAdHocExport,
        // generatedAdHocExport: state.api.generateAdHocExport,
        // loading: state.api.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // onGenerateExport: (fromdate, todate) => dispatch(actions.generateAdHocExport(fromdate, todate)),
        // onDownloadExcel: (data) => dispatch(actions.downloadExcelAdHoc(data)),
        onDownloadFlowerPlaceExportTable: () => dispatch(actions.downloadFlowerPlaceExportTable())
        // onFetchApis: () => dispatch(actions.fetchApis()),
        // onSendDailyOmk: () => dispatch(actions.sendDailyOmk())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles, {withTheme: true})(withTranslation()(FlowerPlace)));